<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="12">
        <v-row class="" align="center">
          <v-col cols="3">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="searchWord" 
              outlined
              dense
              hide-details
              placeholder="검색어"
              background-color="white">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <!-- <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text" 
              @click="searchBtnClicked"
              >검색
            </v-btn> -->
          </v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addScdulBtnClicked"
              >학회 일정 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1"
              :search="searchWord"
              style="cursor: pointer"
            >
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <template v-slot:[`item.scdul_ttl`]="{item}">
                <v-text-field
                  v-model="item.scdul_ttl"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="rowClicked(item)"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      // mberChkDialog: false,
      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%", sortable:false },
        { text: '제목', value: 'scdul_ttl', width: "25%" },
        { text: '카테고리', value: 'bbst_catg', width: "10%" },
        { text: '등록자', value: 'reg_mber_nm', width: "7%" },
        { text: '등록일시', value: 'reg_dtm', width: "15%" },
      ],
      gridList: [],
      // scdulList: [],

      searchWord: null,
      menuSno: this.$route.query.menuSno,
    }
  },
  computed: {

  },
  created() {
    // this.menuSno = this.$route.query.menuSno;
    console.log("scheduleList created:");
  },
  mounted(){
    this.getScdulList();
  },
  methods: {
    getScdulList() {
      const params = new FormData();

      params.append("menu_sno", this.menuSno);

      this.$http.post("/admin/getScdulList", params).then((res) => {
        // console.log("res.data.result:", res.data.result);

        res.data.result.scdulList.forEach((e) => {
          e.select_yn = false;
        });

        this.gridList = res.data.result.scdulList;
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    rowClicked(event) {
      console.log("rowClicked", event);
      this.$router.push({name:"scheduleDetail", query: { menuSno:this.menuSno, scdulSno:event.scdul_sno, newYn:'N' }});
    },
    // 게시글 추가 버튼 클릭
    addScdulBtnClicked() {
      this.$router.push({name:"scheduleDetail", query: { menuSno:this.menuSno, scdulSno:null, newYn:'Y' }});
    },
    deleteBtnClicked() {
      console.log("deletePScdulList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      deleteList = this.gridList.filter((e) => e.select_yn);
      isSelected = this.gridList.filter((e) => e.select_yn).length > 0 ? true : false;
      
      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: prod_sno[" + e.prod_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          console.log("deleteList:", deleteList);
          let params = new URLSearchParams();

          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteScdulList", params).then((res) => {
            console.log(res.data);

            if(res.data.code == 0) {
              alert("삭제되었습니다.");
              // this.getScdulList();
              this.gridList = this.gridList.filter((e) => {
                return !(e.select_yn);
              });
            } else {
              alert("삭제할 수 없습니다.");
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    // radioFilterChanged() {
    //   this.filterList();
    // },
    
  }
}
</script>
  
<style scoped>

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 13px;
  padding: 2px 16px 0px 16px;  
  max-width: 100% !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > .text-start {
  font-size: 13px;
  padding: 0px 16px 0px 16px;  
  max-width: 100% !important;
}

::v-deep .v-input__slot {
  font-size: 13px; 
  padding: 0px !important;
  max-width: 100% !important;
}

::v-deep .white > .v-text-field__slot {
  font-size: 13px;
  padding-left: 12px; 
}

</style>