<template>
	<v-container fluid class="pa-3" v-if="authYn">
    <v-row class="" align="center" dense>
          <v-col cols="12">
            <div>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                @click="soctyAddClicked"
              >학회추가</v-btn>
              <v-btn 
                small 
                color="red lighten-2" 
                class="white--text f-r" 
                @click="delBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
    <v-row>
      <v-col cols="12" >
        <div>
          <v-data-table
            :headers="header"
            class="elevation-1 dTable-1"
            :items="soctyList"
            :items-per-page="10"
            hide-default-footer
          >
            <template v-slot:[`item.select_yn`]="{ item }">
              <v-checkbox
                v-model="item.select_yn"
                class="pa-0 ma-0"
                hide-details
                dense
              ></v-checkbox>
            </template>
            <template v-slot:[`item.socty_nm`]="{ item }" >
              <v-text-field
                v-model="item.socty_nm"
                hide-details
                dense
                readonly
                @click="soctyNmColClicked(item)"
              ></v-text-field>
            </template>
            <!-- <template v-slot:[`item.socty_nm`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.socty_nm"
                hide-details
                outlined
                dense
              ></v-text-field>
            </template> -->
          </v-data-table>
        </div>
      </v-col>
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      header:[
        { text: '선택', value:'select_yn', width:"5%"},
        { text: '학회명', value:'socty_nm', width:"15%"},
        { text: '도메인명', value:'domn_nm', width:"15%"},
        { text: 'GA 추적코드', value:'ga_track_cd', width:"15%"},
        { text: '네이버클라이언트ID', value:'naver_clnt_id', width:"15%"},
        { text: '학회상태', value:'socty_stus_nm', width:"5%"},
        { text: '등록일자', value:'reg_dtm', width:"10%"},
      ],
      soctyList: [],
      domain: window.location.hostname,
      authYn: false,
    }
  },
  created() {
    if(this.domain != 'ssms-admin.bgwell.co.kr') {
      alert('권한이 없습니다.');
      this.$router.push('/');
      return
    } else {
      this.authYn = true;
    }

    this.$http.get("/admin/getSoctyList").then((res) => {
    this.soctyList = res.data.soctyList;
    const list = res.data.soctyList;
    if(list.length > 0) {
      list.forEach((e) => {
        e.select_yn = false;
      });
      this.soctyList = list;
    }
  }).catch(
    function (error) {
      console.log(error);
    }
  )
  },
  mounted(){
  },
  methods: {
    soctyNmColClicked(item) {
      console.log(item);
      this.$router.push({name:"societyMngDetail", query: { soctySno:item.socty_sno}});
    },//soctyNmColClicked end
    soctyAddClicked() {
      this.$router.push({name:"societyMngDetail"});
    },//soctyAddClicked end
    delBtnClicked() {
      const len = this.soctyList.filter((e) => e.select_yn == true);
      if(len == 0) {
        alert('선택된 항목이 없습니다.');
        return;
      }
      
      if(confirm('삭제하시겠습니까?')) {
        const formData = new FormData();
        const delList = this.soctyList.filter((e) => e.select_yn == true);
        formData.append('delList', JSON.stringify(delList));

        this.$http.post("/admin/deleteSociety",formData).then((res) => {

          if(res.data.code == 0) {
            alert('삭제되었습니다.');
            this.$router.go(0);
          }

        }).catch(
          function (error) {
            console.log(error);
          }
        )
      }
      

    },//delBtnClicked end
  }
}
</script>
  
<style>

</style>