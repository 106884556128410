<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
    <div class="wid-100">
        <v-row dense >
          <v-col cols="12">
            <!-- <div class="f-r pl-1">
              <v-btn
                small
                color="blue lighten-2"
                class="white--text"
                @click="saveBtnClicked"
              >
                저장
              </v-btn>
            </div> -->
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div >
              <v-data-table
                :headers="headers"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination>
                <template v-slot:[`item.crql_sno`]="{ item }">
                  <v-text-field
                    v-model="item.crql_sno"
                    hidden
                    hide-details
                    dense
                    outlined
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.crql_kind`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.crql_kind"
                    :items="crqlKindList"
                    item-text="cmn_cd_nm"
                    item-value="cmn_cd"
                    hide-details
                    dense
                    outlined
                    @change="gridItemChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.crql_nm`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.crql_nm"
                    hide-details
                    dense
                    outlined
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.crql_nm_en`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.crql_nm_en"
                    hide-details
                    dense
                    outlined
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.issu_org`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.issu_org"
                    hide-details
                    dense
                    outlined
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.issu_dt`]="{ item }">
                  <v-text-field
                    class=""
                    v-model="item.issu_dt"
                    hide-details
                    dense
                    outlined
                    append-icon="mdi-calendar"
                    @click:append="popupDatePicker(item)"
                    placeholder="0000-00-00"
                    @change="gridItemChanged(item)"
                  ></v-text-field>
                  <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
							  </template>
                <template v-slot:[`item.crql_proof_atch_sno`]="{ item }">
                  <v-row align="center">
                    <!-- <v-text-field
                      prepend-icon="mdi-paperclip"
                      v-model="item.orgnl_file_nm"
                      readonly
                      @click:prepend="crqlProofImgClicked"
                      style="width:180px;float:left;"
                    ></v-text-field> -->
                    <v-file-input
                      style="width:180px;float:left;"
                      ref="crqlProofImg"
                      prepend-icon="mdi-paperclip"
                      accept="image/*"
                      v-model="item.file"
                      @click:prepend="crqlProofImgClicked"
                      @change="gridItemChanged(item)"
                    ></v-file-input>
                    <v-icon style="cursor: pointer;" class="ml-2" v-if="item.crql_proof_atch_sno" @click="imgDelClicked(item)">mdi-delete</v-icon>
                    <v-icon style="cursor: pointer;" class="ml-2" v-if="item.crql_proof_atch_sno" @click="imgViewClicked(item)">mdi-download</v-icon>
                  </v-row>
							  </template>
              </v-data-table>
            </div>
            
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import datePickerPopup from '@/components/common/datePickerPopup.vue';
export default {
  components: { datePickerPopup },
  props: ["propParams"],
  data(){
    return {
      headers:[
        { text: '선택', value:'select_yn', width:"1%"},
        { text: '자격증종류', value:'crql_kind', width:"15%"},
        { text: '자격증명(한글)', value:'crql_nm', width:"20%"},
        { text: '자격증명(영문)', value:'crql_nm_en', width:"20%"},
        { text: '발행처', value:'issu_org', width:"15%"},
        { text: '발행일자', value:'issu_dt', width:"10%"},
        { text: '증빙첨부', value:'crql_proof_atch_sno', width:"19%"},
      ],
      crqlKindList: [],
      gridList: [],
      changedList: [],
      mberSno: "",
      loginMberSno: "",

      datePopup: false,
      curCrql: "",
      curDate: "",
    }
  },
  created() {
    this.mberSno = this.propParams.mberSno;
    this.loginMberSno = this.$session.get("mberSno") ? this.$session.get("mberSno") : ''
    this.getSoctyCrqlKindList();
    this.getMberCrqlList();
  },
  methods: {
    async getSoctyCrqlKindList() {
      this.crqlKindList = await this.$getSoctyCmnCdList("crql_kind");
      // console.log("crqlKindList: " + this.crqlKindList);
    },
    // 자격증 수정됨
    gridItemChanged(item) {
      this.itemChanged();
      // console.log("gridItemChanged : item.crql_sno : " + item.crql_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.crql_sno == null || item.crql_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.crql_sno == item.crql_sno) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }
    },
    getMberCrqlList() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);

			this.$http.post("/admin/getMberCrqlList", params).then((res) => {
        this.gridList = res.data.result;
        // console.log("gridList: " + this.gridList);
        // if(this.gridList) {
        //   this.gridList.forEach((e) => {
        //     e.file = null;
        //   })
        // }

        this.changedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    deleteBtnClicked() {
      console.log("deleteCrql");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });


      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: edubg_sno[" + e.edubg_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();

          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteMberCrqlList", params).then((res) => {
            console.log(res.data);
            // this.getMberCrqlList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getMberCrqlList();
          this.gridList = this.gridList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;

      this.gridList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.gridList.push({new_yn:true, select_yn:false, crql_sno:"", mber_sno:this.mberSno, odr:maxOdr, crql_kind:"", crql_nm:"", crql_nm_en:"", 
                          issu_org:"", issu_dt:"", crql_proof_atch_sno:"", file: null});
      this.changedList.push(this.gridList[this.gridList.length - 1]);
      // 객체 복사가 필요하면 아래의 방법으로 해야 함
      //this.changedList.push({...this.gridList[this.gridList.length - 1]}); 
      // 객체 안에 또 객체가 있을 경우 아래의 방법으로 해야 함
      // JSON.parse(JSON.stringify(item))

      // 데이터 검증
      // this.changedList.forEach((e) => {
      //   console.log("changedList: addr_sno[" + e.addr_sno + "]");
      // });
    },
    saveBtnClicked() {
      console.log("saveCrql");

      if(this.changedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      // true/false -> 'Y'/'N' 변환
      this.changedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
      });

      const params = new FormData();
      
      params.append("login_mber_sno", this.loginMberSno);
      // console.log("여기::");
      this.gridList.forEach((e) => {
        
        if(e.file != null) {
          // console.log('맞는데?',e.file);
          params.append("fileList", e.file);
          this.changedList.filter((f) => f.odr == e.odr)[0].file_yn = 'Y';
        }
      })

      params.append("changedList", JSON.stringify(this.changedList));

      this.$http.post("/admin/upsertMberCrqlList", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        console.log(res.data);
        // alert("저장되었습니다.");
        this.getMberCrqlList();
      }).catch(function (error) {
          console.log(error);
      });
    },
    popupDatePicker(item) {
      // console.log("popupDatePicker");
      // console.log("crql_sno: ", item.crql_sno);
      this.curCrql = item;
      this.curDate = item.issu_dt;

      this.datePopup = true;
    },
    setSelectedDate(selected) {
      this.curCrql.issu_dt = selected;
      this.gridItemChanged(this.curCrql);
      this.datePopup = false;
    },
    itemChanged() {
      this.$emit('tabChanged', "certificateTab");
    },
    crqlProofImgClicked() {
      this.$refs.crqlProofImg.$el.querySelector('input').click();
    },//crqlProofImgClicked end
    imgDelClicked(item) {
      if(confirm('첨부된 이미지 삭제하시겠습니까?')) {

        const params = new FormData();
        params.append("mber_sno", this.loginMberSno);
        params.append("crql_sno", item.crql_sno);

        this.$http.post("/admin/updateCrqlImgDel", params).then((res) => {
          console.log(res.data.code);
          if(res.data.code == 0) {
            alert('삭제되었습니다.');
            this.getMberCrqlList();
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },//imgDelClicked end
    imgViewClicked(item) {
      // window.open(url, "_blank");
      const filename = item.orgnl_file_nm;
      const element = document.createElement('a');
      element.setAttribute('href', 'data:image/*;charset=utf-8, ' +encodeURIComponent(item.file_path));
      element.setAttribute('download', filename);
      document.body.appendChild(element);
      element.click();
    },//imgViewClicked end
  },

}
</script>

<style>

</style>