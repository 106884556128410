<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="">
        <v-row dense align="center">
          <!-- 이름(한글) -->
          <v-col cols="12" v-if="mberItemSet.mber_nm.use_yn && (newYn == 'N' || mberItemSet.mber_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>이름(한글)</span><span v-if="mberItemSet.mber_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="mber_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 이름(한자) -->
          <v-col cols="12" v-if="mberItemSet.mber_nm_cn.use_yn && (newYn == 'N' || mberItemSet.mber_nm_cn.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>이름(한자)</span><span v-if="mberItemSet.mber_nm_cn.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="mber_nm_cn"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 영문이름(First) -->
          <v-col cols="12" v-if="mberItemSet.mber_nm_en_frst.use_yn && (newYn == 'N' || mberItemSet.mber_nm_en_frst.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>영문이름(First)</span><span v-if="mberItemSet.mber_nm_en_frst.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="mber_nm_en_frst"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 영문이름(Last) -->
          <v-col cols="12" v-if="mberItemSet.mber_nm_en_last.use_yn && (newYn == 'N' || mberItemSet.mber_nm_en_last.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>영문이름(Last)</span><span v-if="mberItemSet.mber_nm_en_last.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="mber_nm_en_last"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 전자우편주소 -->
          <v-col cols="12" v-if="mberItemSet.email.use_yn && (newYn == 'N' || mberItemSet.email.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>전자우편주소</span><span v-if="mberItemSet.email.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="email"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 전자우편주소(논문투고심사시스템) -->
          <v-col cols="12" v-if="mberItemSet.email_sbmt_sys.use_yn && (newYn == 'N' || mberItemSet.email_sbmt_sys.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>전자우편주소(논문투고심사시스템)</span><span v-if="mberItemSet.email_sbmt_sys.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="email_sbmt_sys"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 성별 -->
          <v-col cols="12" v-if="mberItemSet.gend.use_yn && (newYn == 'N' || mberItemSet.gend.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <span>성별</span><span v-if="mberItemSet.gend.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <div class="wid-100">
                  <v-radio-group
                    v-model="gend"
                    row
                    hide-details
                    dense
                    class="ma-0 pa-0"
                    @change="itemChanged"
                  >
                    <v-radio
                      v-for="(item, index) in gendList"
                      :key="index"
                      :label="item.cmn_cd_nm"
                      :value="item.cmn_cd"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 국적 -->
          <v-col cols="12" v-if="mberItemSet.natl.use_yn && (newYn == 'N' || mberItemSet.natl.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>국적</span><span v-if="mberItemSet.natl.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="natlList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="natl"
                  hide-details
                  dense
                  outlined
                  @change="itemChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 생년월일 -->
          <v-col cols="12" v-if="mberItemSet.birth_dt.use_yn && (newYn == 'N' || mberItemSet.birth_dt.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>생년월일</span>
                  <span v-if="mberItemSet.birth_dt.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="wid-70">
                  <v-text-field
                    class="bg-white"
                    v-model="birth_dt"
                    hide-details
                    dense
                    outlined
                    append-icon="mdi-calendar"
                    @click:append="popupDatePicker('birth_dt')"
                    placeholder="0000-00-00"
                  ></v-text-field>
                  <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                  <!-- <datePicker 
                    v-model="birth_dt"
                  ></datePicker> -->
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 회원 사진 -->
          <v-col cols="12" v-if="mberItemSet.photo_atch_sno.use_yn && (newYn == 'N' || mberItemSet.photo_atch_sno.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="">
                  <span>회원 사진</span>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="wid-100 pt-0">
                  <v-file-input
                    hide-details
                    dense
                    v-model="photoSelectedFile"
                    @change="itemChanged"
                  ></v-file-input>
                </div>
              </v-col>
              <!-- 파일 표시 text-field -->
              <v-col cols="3" v-if="photoCurrentFile.orgnl_file_nm">
                <div class="wid-100 pt-0">
                  <div class="f-l pt-2 pl-5 font-1" style="color:white;">
                    <span><a :href="photoCurrentFile.file_path">{{photoCurrentFile.orgnl_file_nm}}</a></span>
                  </div>
                  <v-btn
                    small
                    color="red lighten-2"
                    class="pa-0 ma-0 white--text f-r"
                    hide-details
                    @click="deleteFileBtnClicked('photo', photoCurrentFile.file_path, photoCurrentFile.file_atch_sno)"
                  >삭제</v-btn>
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          
          <!-- 소속/학교 -->
          <v-col cols="12" v-if="mberItemSet.blng_nm.use_yn && (newYn == 'N' || mberItemSet.blng_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>소속/학교</span><span v-if="mberItemSet.blng_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="blng_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 부서/학과 -->
          <v-col cols="12" v-if="mberItemSet.dept_nm.use_yn && (newYn == 'N' || mberItemSet.dept_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>부서/학과</span><span v-if="mberItemSet.dept_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="dept_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 직위/학년 -->
          <v-col cols="12" v-if="mberItemSet.pstn_nm.use_yn && (newYn == 'N' || mberItemSet.pstn_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>직위/학년</span><span v-if="mberItemSet.pstn_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="pstn_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 휴대전화번호 -->
          <v-col cols="12" v-if="mberItemSet.cel_no.use_yn && (newYn == 'N' || mberItemSet.cel_no.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>휴대전화번호</span><span v-if="mberItemSet.cel_no.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="cel_no"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 소속기관 유형 -->
          <v-col cols="12" v-if="mberItemSet.blng_org_type.use_yn && (newYn == 'N' || mberItemSet.blng_org_type.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>소속기관 유형</span><span v-if="mberItemSet.blng_org_type.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="blngOrgTypeList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="blng_org_type"
                  hide-details
                  dense
                  outlined
                  @change="itemChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 전공분야(선택) -->
          <v-col cols="12" v-if="mberItemSet.major_field.use_yn && (newYn == 'N' || mberItemSet.major_field.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>전공분야(선택)</span><span v-if="mberItemSet.major_field.ess_yn"> *</span>
              </v-col>
              <v-col cols="10">
                <div class="wid-100">
                  <v-row dense>
                    <v-col cols="2" v-for="(a, i) in majorFieldList.filter((e) => e.cmn_cd != '00' )" :key="i" class="pt-0">
                      <v-checkbox 
                        hide-details
                        dense
                        :label="a.cmn_cd_nm"
                        :value="a.cmn_cd"
                        v-model="majorFieldSelectedList"
                        class="pr-6"
                        @change="itemChanged"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 전공분야(입력) -->
          <v-col cols="12" v-if="mberItemSet.major_field.use_yn && (newYn == 'N' || mberItemSet.major_field.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>전공분야(입력)</span><span v-if="mberItemSet.major_field.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="major_field_input"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 직종 -->
          <v-col cols="12" v-if="mberItemSet.job_kind.use_yn && (newYn == 'N' || mberItemSet.job_kind.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>직종</span><span v-if="mberItemSet.job_kind.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="jobKindList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="job_kind"
                  hide-details
                  dense
                  outlined
                  @change="itemChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 소속 위원회 -->
          <v-col cols="12" v-if="mberItemSet.blng_cmit.use_yn && (newYn == 'N' || mberItemSet.blng_cmit.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>소속 위원회</span><span v-if="mberItemSet.blng_cmit.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="blng_cmit"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 관심분야 -->
          <v-col cols="12" v-if="mberItemSet.inter_field.use_yn && (newYn == 'N' || mberItemSet.inter_field.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>관심분야</span><span v-if="mberItemSet.inter_field.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="inter_field"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>

          <!-- 학술지 구독 여부 -->
          <v-col cols="12" v-if="mberItemSet.jrnl_subs_yn.use_yn && (newYn == 'N' || mberItemSet.jrnl_subs_yn.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <span>학술지 구독</span><span v-if="mberItemSet.jrnl_subs_yn.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-checkbox 
                  hide-details
                  dense
                  v-model="jrnl_subs_yn"
                  label="학술지 구독 여부"
                  @change="itemChanged"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>

          <!-- 회원 자격 증빙 -->
          <v-col cols="12" v-if="mberItemSet.crql_proof_atch_sno.use_yn && (newYn == 'N' || mberItemSet.crql_proof_atch_sno.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="">
                  <span>회원 자격 증빙</span>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="wid-100 pt-0">
                  <v-file-input
                    hide-details
                    dense
                    v-model="crqlProofSelectedFile"
                    @change="itemChanged"
                  ></v-file-input>
                </div>
              </v-col>
              <!-- 파일 표시 text-field -->
              <v-col cols="3" v-if="crqlProofCurrentFile.orgnl_file_nm">
                <div class="wid-100 pt-0">
                  <div class="f-l pt-2 pl-5 font-1" style="color:white;">
                    <span><a :href="crqlProofCurrentFile.file_path" :download="crqlProofCurrentFile.orgnl_file_nm">{{crqlProofCurrentFile.orgnl_file_nm}}</a></span>
                  </div>
                  <v-btn
                    small
                    color="red lighten-2"
                    class="pa-0 ma-0 white--text f-r"
                    hide-details
                    @click="deleteFileBtnClicked('bnr_img', crqlProofCurrentFile.file_path, crqlProofCurrentFile.file_atch_sno)"
                  >삭제</v-btn>
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>

        </v-row>
      </div>
    </v-layout>
  </v-container>

</template>

<script>
// import datePicker from "../../../common/datePicker.vue";
import datePickerPopup from '@/components/common/datePickerPopup.vue';

export default {
  props: ["propParams"],
  components: {datePickerPopup, },
  data(){
    return {
      mberItemSet: {
        mber_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        mber_nm_cn:{use_yn:true, join_colct_yn:true, ess_yn:false},
        mber_nm_en_frst:{use_yn:true, join_colct_yn:true, ess_yn:false},
        mber_nm_en_last:{use_yn:true, join_colct_yn:true, ess_yn:false},
        email:{use_yn:true, join_colct_yn:true, ess_yn:false},
        email_sbmt_sys:{use_yn:true, join_colct_yn:true, ess_yn:false},
        natl:{use_yn:true, join_colct_yn:true, ess_yn:false},
        gend:{use_yn:true, join_colct_yn:true, ess_yn:false},
        birth_dt:{use_yn:true, join_colct_yn:true, ess_yn:false},
        photo_atch_sno:{use_yn:true, join_colct_yn:true, ess_yn:false},
        blng_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        dept_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        pstn_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        cel_no:{use_yn:true, join_colct_yn:true, ess_yn:false},
        blng_org_type:{use_yn:true, join_colct_yn:true, ess_yn:false},
        major_field:{use_yn:true, join_colct_yn:true, ess_yn:false},
        // major_field_input:{use_yn:true, join_colct_yn:true, ess_yn:false},
        job_kind:{use_yn:true, join_colct_yn:true, ess_yn:false},
        blng_cmit:{use_yn:true, join_colct_yn:true, ess_yn:false},
        inter_field:{use_yn:true, join_colct_yn:true, ess_yn:false},
        jrnl_subs_yn:{use_yn:true, join_colct_yn:true, ess_yn:false},
        crql_proof_atch_sno:{use_yn:true, join_colct_yn:true, ess_yn:false},
      },

      mberSctnId: 'mber_indv',

      mber_nm: null,
      mber_nm_cn: null,
      mber_nm_en_frst: null,
      mber_nm_en_last: null,
      email: null,
      email_sbmt_sys: null,
      natl: null,
      gend: null,
      birth_dt: null,
      photo_atch_sno: null,
      blng_nm: null,
      dept_nm: null,
      pstn_nm: null,
      cel_no: null,
      blng_org_type: null,
      job_kind: null,
      blng_cmit: null,
      inter_field: null,
      jrnl_subs_yn: false,
      crql_proof_atch_sno: null,
      major_field: null,
      major_field_input: null,
      majorFieldSelectedList: [],   // 개인회원 > 전공분야

      photoCurrentFile: {orgnl_file_nm:null, file_atch_sno:null, file_path:null},
      photoSelectedFile: null,
      crqlProofCurrentFile: {orgnl_file_nm:null, file_atch_sno:null, file_path:null},
      crqlProofSelectedFile: null,

      gendList: [],
      natlList: [],
      blngOrgTypeList: [],
      majorFieldList: [],
      jobKindList: [],

      mberSno: '',
      mberIndvMap: {},
      // redrawCount: 0,

      newYn: 'N',
      loginMberSno: "",   // 로그인 멤버
      datePopup: false,
      curDate: "",
    }
  },
  watch: {
    birth_dt(val) {
      console.log('Message changed from ' + val);
      if(this.mberIndvMap.birth_dt != this.birth_dt) {
        this.itemChanged();
      }
    }
  },
  created() {
    this.loginMberSno = this.$session.get("mberSno"); // 로그인 멤버
    this.mberSno = this.propParams.mberSno;
    this.newYn = this.propParams.newYn;

    this.getGendList();
    this.getNatlList();
    this.getBlngOrgTypeList();
    this.getMajorFieldList();
    this.getJobKindList();

    this.getMberIndv();
  },
  methods: {
    async getGendList() {
      this.gendList = await this.$getCmnCdList("gend");
    },
    async getNatlList() {
      this.natlList = await this.$getCmnCdList("natl");
    },
    async getBlngOrgTypeList() {
      this.blngOrgTypeList = await this.$getCmnCdList("blng_org_type");
    },
    async getMajorFieldList() {
      this.majorFieldList = await this.$getSoctyCmnCdList("major_field");
    },
    async getJobKindList() {
      this.jobKindList = await this.$getSoctyCmnCdList("job_kind");
    },
    getMberIndv() {
      // 기존 파일 리스트 초기화
      this.photoSelectedFile = null;
      this.crqlProofSelectedFile = null;

      const formData = new FormData();

      formData.append("mber_sctn_id", this.mberSctnId);
      formData.append("mber_sno", this.mberSno);

      this.$http.post("/admin/getMberIndv", formData).then((res) => {
        console.log("res.data.result:", res.data.result);

        const mberItemList = res.data.result.mberItemList;

        // console.log("mberItemList:", result.mberItemList);

        let mberItemSet = {};
        mberItemList.forEach((e) => {
          // console.log(e);
          mberItemSet[e.mber_item_id] = {use_yn:e.use_yn == 'Y' ? true : false, join_colct_yn:e.join_colct_yn == 'Y' ? true : false, ess_yn:e.ess_yn == 'Y' ? true : false};
        });
        // console.log("mberItemSet:", mberItemSet);
        // console.log("mberItemSet.login_id.ess_yn:", mberItemSet.login_id.ess_yn);
        this.mberItemSet = mberItemSet;

        if(res.data.result.mberIndv == null) {
          this.newYn = 'Y';
          return;
        }

        this.mberIndvMap = res.data.result.mberIndv;
        
        const mber = res.data.result.mberIndv;
        console.log("mber:", mber);

        this.mber_nm = mber.mber_nm;
        this.mber_nm_cn = mber.mber_nm_cn;
        this.mber_nm_en_frst = mber.mber_nm_en_frst;
        this.mber_nm_en_last = mber.mber_nm_en_last;
        this.email = mber.email;
        this.email_sbmt_sys = mber.email_sbmt_sys;
        this.natl = mber.natl;
        this.gend = mber.gend;
        this.birth_dt = mber.birth_dt;
        // this.$refs.birth_dt.date = mber.birth_dt;

        this.photoCurrentFile.orgnl_file_nm = mber.photo_orgnl_file_nm;
        this.photoCurrentFile.file_atch_sno = mber.photo_atch_sno;
        this.photoCurrentFile.file_path = mber.photo_path;

        this.blng_nm = mber.blng_nm;
        this.dept_nm = mber.dept_nm;
        this.pstn_nm = mber.pstn_nm;
        this.cel_no = mber.cel_no;
        this.blng_org_type = mber.blng_org_type;
        // 개인회원 > 전공분야
        const list = mber.major_field ? mber.major_field.split(',') : [];
        list.forEach((e) => {
          this.majorFieldSelectedList.push(e);
        });
        this.major_field_input = mber.major_field_input;
        this.job_kind = mber.job_kind;
        this.blng_cmit = mber.blng_cmit;
        this.inter_field = mber.inter_field;

        this.jrnl_subs_yn = mber.jrnl_subs_yn == 'Y' ? true : false;

        this.crqlProofCurrentFile.orgnl_file_nm = mber.crql_proof_orgnl_file_nm;
        this.crqlProofCurrentFile.file_atch_sno = mber.crql_proof_atch_sno;
        this.crqlProofCurrentFile.file_path = mber.crql_proof_path;

        // this.redrawCount++;
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
      
    },
    validateInput() {
      // 이름(한글)
      if(this.mberItemSet.mber_nm.ess_yn && (this.mber_nm == null || this.mber_nm =="")) {
        alert("이름(한글)은 필수 입력값입니다.");
        return false;
      }
      // 이름(한자)
      if(this.mberItemSet.mber_nm_cn.ess_yn && (this.mber_nm_cn == null || this.mber_nm_cn =="")) {
        alert("이름(한자)은 필수 입력값입니다.");
        return false;
      }
      // 영문이름(First)
      if(this.mberItemSet.mber_nm_en_frst.ess_yn && (this.mber_nm_en_frst == null || this.mber_nm_en_frst =="")) {
        alert("영문이름(First)은 필수 입력값입니다.");
        return false;
      }
      // 영문이름(Last)
      if(this.mberItemSet.mber_nm_en_last.ess_yn && (this.mber_nm_en_last == null || this.mber_nm_en_last =="")) {
        alert("영문이름(Last)은 필수 입력값입니다.");
        return false;
      }
      // 이메일
      if(this.mberItemSet.email.ess_yn && (this.email == null || this.email =="")) {
        alert("이메일은 필수 입력값입니다.");
        return false;
      }
      if((this.email != null && this.email != "") && !this.$validateEmail(this.email)) {
        alert('이메일 형식이 아닙니다.');
        return false;
      }
      // 이메일(논문투고심사시스템)
      if(this.mberItemSet.email_sbmt_sys.ess_yn && (this.email_sbmt_sys == null || this.email_sbmt_sys =="")) {
        alert("이메일(논문투고심사시스템)은 필수 입력값입니다.");
        return false;
      }
      if((this.email_sbmt_sys != null && this.email_sbmt_sys != "") && !this.$validateEmail(this.email_sbmt_sys)) {
        alert('이메일 형식이 아닙니다.');
        return false;
      }
      // 국적
      if(this.mberItemSet.natl.ess_yn && (this.natl == null || this.natl =="")) {
        alert("국적은 필수 입력값입니다.");
        return false;
      }
      // 성별
      if(this.mberItemSet.gend.ess_yn && (this.gend == null || this.gend =="")) {
        alert("성별은 필수 입력값입니다.");
        return false;
      }
      
      // 생년월일
      // if(this.mberItemSet.birth_dt.ess_yn && (this.$refs.birth_dt.date == null || this.refs.birth_dt.date =="")) {
      if(this.mberItemSet.birth_dt.ess_yn && (this.birth_dt == null || this.birth_dt =="")) {
        alert("생년월일은 필수 입력값입니다.");
        return false;
      }
      // 회원사진
      if(this.mberItemSet.photo_atch_sno.ess_yn && (this.photoSelectedFile == null || this.photoSelectedFile =="")) {
        alert("회원사진은 필수 입력값입니다.");
        return false;
      }
      // 소속/학교
      if(this.mberItemSet.blng_nm.ess_yn && (this.blng_nm == null || this.blng_nm =="")) {
        alert("소속/학교는 필수 입력값입니다.");
        return false;
      }
      // 부서/학과
      if(this.mberItemSet.dept_nm.ess_yn && (this.dept_nm == null || this.dept_nm =="")) {
        alert("부서/학과는 필수 입력값입니다.");
        return false;
      }
      // 직위/과정
      if(this.mberItemSet.pstn_nm.ess_yn && (this.pstn_nm == null || this.pstn_nm =="")) {
        alert("직위/과정은 필수 입력값입니다.");
        return false;
      }
      // 휴대전화번호
      if(this.mberItemSet.cel_no.ess_yn && (this.cel_no == null || this.cel_no =="")) {
        alert("휴대전화번호는 필수 입력값입니다.");
        return false;
      }
      // 소속기관 유형
      if(this.mberItemSet.blng_org_type.ess_yn && (this.blng_org_type == null || this.blng_org_type =="")) {
        alert("소속기관 유형은 필수 입력값입니다.");
        return false;
      }
      // 전공분야
      if(this.mberItemSet.major_field.ess_yn && (this.majorFieldSelectedList == null || this.majorFieldSelectedList =="")) {
        alert("전공분야는 필수 입력값입니다.");
        return false;
      }
      // 직종
      if(this.mberItemSet.job_kind.ess_yn && (this.job_kind == null || this.job_kind =="")) {
        alert("직종은 필수 입력값입니다.");
        return false;
      }
      // 소속위원회
      if(this.mberItemSet.blng_cmit.ess_yn && (this.blng_cmit == null || this.blng_cmit =="")) {
        alert("소속위원회는 필수 입력값입니다.");
        return false;
      }
      // 관심분야
      if(this.mberItemSet.inter_field.ess_yn && (this.inter_field == null || this.inter_field =="")) {
        alert("관심분야는 필수 입력값입니다.");
        return false;
      }

      // 학술지 구독 여부
      if(this.mberItemSet.jrnl_subs_yn.ess_yn && !this.jrnl_subs_yn) {
        alert("학술지 구독 여부는 필수 입력값입니다.");
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      console.log("memberIndiv > saveBtnClicked");
      
      const params = new FormData();
      params.append("mber_sctn_id", this.mberSctnId);
      params.append("mber_sno", this.mberSno);
      params.append('new_yn', this.newYn);
      params.append("login_mber_sno", this.loginMberSno);

      if(this.mber_nm != null )         params.append("mber_nm", this.mber_nm);
      if(this.mber_nm_cn != null )      params.append("mber_nm_cn", this.mber_nm_cn);
      if(this.mber_nm_en_frst != null ) params.append("mber_nm_en_frst", this.mber_nm_en_frst);
      if(this.mber_nm_en_last != null ) params.append("mber_nm_en_last", this.mber_nm_en_last);
      if(this.email != null )           params.append("email", this.email);
      if(this.email_sbmt_sys != null )  params.append("email_sbmt_sys", this.email_sbmt_sys);
      if(this.natl != null )            params.append("natl", this.natl);
      if(this.gend != null )            params.append("gend", this.gend);
      if(this.birth_dt != null )        params.append("birth_dt", this.birth_dt);
      // if(this.$refs.birth_dt && this.$refs.birth_dt.date) params.append("birth_dt", this.$refs.birth_dt.date);

      if(this.blng_nm != null )         params.append("blng_nm", this.blng_nm);
      if(this.dept_nm != null )         params.append("dept_nm", this.dept_nm);
      if(this.pstn_nm != null )         params.append("pstn_nm", this.pstn_nm);
      if(this.cel_no != null )          params.append("cel_no", this.cel_no);
      if(this.blng_org_type != null )   params.append("blng_org_type", this.blng_org_type);

      // 개인회원 > 전공분야 
      // 'major_field' 값을 list -> string(콤마구분자)으로 변경
      console.log("this.majorFieldSelectedList :: ", this.majorFieldSelectedList);
      let majorFieldList = "";
      this.majorFieldSelectedList.forEach((e, idx) => {
        console.log("e", e, "idx", idx);
        if(idx == 0) {
          majorFieldList = e;
        } else {
          majorFieldList += ',' + e;
        }
      });
      // console.log("majorFieldList", majorFieldList);
      if(majorFieldList != null )         params.append("major_field", majorFieldList);
      if(this.major_field_input != null ) params.append("major_field_input", this.major_field_input);
      
      if(this.job_kind != null )          params.append("job_kind", this.job_kind);
      if(this.blng_cmit != null )         params.append("blng_cmit", this.blng_cmit);
      if(this.inter_field != null )       params.append("inter_field", this.inter_field);
      if(this.jrnl_subs_yn != null )      params.append("jrnl_subs_yn", this.jrnl_subs_yn == true ? 'Y' : 'N');

      // 첨부 파일
      const file_info_list = [];

      if(this.photoSelectedFile != null) {
        params.append('file_list', this.photoSelectedFile);
        file_info_list.push({item:"photo"});
      } 

      if(this.crqlProofSelectedFile != null) {
        params.append('file_list', this.crqlProofSelectedFile);
        file_info_list.push({item:"crql_proof"});
      } 

      params.append("file_info_list", JSON.stringify(file_info_list));
                
      this.$http.post("/admin/upsertMberIndv", params).then((res) => {
        if(res.data.code == 0) {
          // alert("저장되었습니다.");
          this.getMberIndv();
        } else {
          alert("실패");
        }
      }).catch(function(error) {
          console.log(error);
      });//axios End

    },//saveBtnClicked End
    deleteFileBtnClicked(fileItem, filePath, fileAtchSno) {
      if(confirm("삭제하시겠습니까?")) {
        // console.log("filePath:", filePath);
        // console.log("atchSno:", fileAtchSno);
        
        if(filePath == null) {
          alert("삭제할 파일이 없습니다.");
          return;
        }

        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("file_item", fileItem);
        params.append("file_path", filePath);
        params.append("file_atch_sno", fileAtchSno);

        this.$http.post("/admin/deleteMberIndvFile", params).then((res) => {
          console.log(res.data);
          alert("삭제되었습니다.");
          
          this.getMberIndv();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    itemChanged() {
      this.$emit('tabChanged', 'memberIndivTab');
    },
    setSelectedDate(selected) {
      
      if(this.dateNm == "birth_dt") {
        this.birth_dt = selected;
      }
      
      this.datePopup = false;
    },//setSelectedDate end
    popupDatePicker(dateNm) {
      this.dateNm = dateNm;
      this.datePopup = true;
    },//popupDatePicker end
  }
}
</script>

<style>
</style>