<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense>
          <v-col cols="12">
            <!-- 상단 버튼 부분 -->
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          <!-- 데이터 표시 부분 -->
          <v-col cols="12" >
            <div>
              <!-- v-data-table을 사용하여 데이터를 표시 -->
              <v-data-table
                :headers="headers"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination> 
                <!-- 각 열에 대한 템플릿 슬롯 사용 -->
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.mber_kind`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.mber_kind"
                    :items="mstMberKindList"
                    item-text="cmn_cd_nm"
                    item-value="cmn_cd"
                    hide-details
                    outlined
                    dense
                    @change="mberKindChanged(item)"
                  ></v-select>
							  </template>
                
                <template v-slot:[`item.mber_kind_nm`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.mber_kind_nm"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <!--연회비 금액-->
                <template v-slot:[`item.mfee_amt`]="{ item }">
                  <numeric-text-field
                    v-model="item.mfee_amt"
                    @input="gridItemChanged(item)"
                  ></numeric-text-field>
                  <!--<numeric-text-field 
                    v-model="prod_amt" 
                    @input="newYn == 'Y' ? itemChanged() : ''" 
                    :readonly="newYn != 'Y'" 
                    :bgColor="newYn != 'Y' ? 'grey lighten-2' : ''">
                  </-text-field> -->
                  <!-- @change="gridItemChanged(item)"
                  class="pa-0 ma-0"
                  hide-details
                  outlined
                  dense -->
							  </template>
                <template v-slot:[`item.use_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.use_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                    @change="gridItemChanged(item)"
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.join_disp_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.join_disp_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                    @change="gridItemChanged(item)"
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.indv_mber_kind_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.indv_mber_kind_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                    @change="gridItemChanged(item)"
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.grp_mber_kind_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.grp_mber_kind_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                    @change="gridItemChanged(item)"
                  ></v-checkbox>
                </template>
                <!-- ... (다른 열에 대한 템플릿 슬롯 추가) ... -->
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import numericTextField from '@/components/common/numericTextField.vue';
// Vue 컴포넌트의 기본 내보내기
export default {
  components: {numericTextField },
  // 부모 컴포넌트로부터 전달받은 속성(props) 중 "propParams"를 사용할 수 있도록 정의
  props: ["propParams"],
  // 컴포넌트의 데이터 속성을 정의. 템플릿에서 사용할 데이터와 초기값이 포함
  data(){
    return {
      // headers: 테이블의 헤더 정보를 정의한 배열
      headers:[
        { text: '선택', value:'select_yn', width:"5%"},
        { text: '회원종류', value:'mber_kind', width:"20%"},
        { text: '학회 회원종류명', value:'mber_kind_nm', width:"20%"},
        { text: '연회비 금액', value:'mfee_amt', width:"15%"},
        { text: '사용 여부', value:'use_yn', width:"10%"},
        { text: '회원가입 시 표시', value:'join_disp_yn', width:"10%"},
        { text: '개인회원 종류 여부', value:'indv_mber_kind_yn', width:"10%"},
        { text: '단체회원 종류 여부', value:'grp_mber_kind_yn', width:"10%"},
      ],
      // gridList: 테이블에 표시되는 데이터 목록을 담는 배열
      gridList: [],
      // mstMberKindList: getMstMberKindList 메서드를 통해 가져온 회원 종류 목록을 담는 배열
      mstMberKindList: [],
      // changedList: 추가 또는 수정된 데이터를 추적하는 배열
      changedList: [],    // 추가, 수정된 목록
      // mberSno: 세션에서 가져온 회원번호를 저장하는 변수
      mberSno: "", 
    }
  },
  created() {
    // 세션에서 "mberSno"를 가져와 mberSno 변수에 저장
    this.mberSno = this.$session.get("mberSno");

    // getMstMberKindList 메서드를 호출하여 회원 종류 목록을 가져옴
    this.getMstMberKindList();
    // getSoctyMberKindList 메서드를 호출하여 학회 회원 종류 목록을 가져옴
    this.getSoctyMberKindList();
  },
  // 컴포넌트에서 사용할 메서드들을 정의.
  methods: {
    // 회원 종류 목록을 가져오는 비동기 메서드. this.mstMberKindList에 저장
    async getMstMberKindList() {
      this.mstMberKindList = await this.$getCmnCdList("mber_kind");
    },
    // 주어진 회원 종류 코드에 해당하는 회원 종류 명을 반환
    getMberKindNm(mberKind) {
      // for 루프를 통해 mstMberKindList에서 
      // cmn_cd가 주어진 mberKind와 일치하는 항목을 찾아 cmn_cd_nm을 반환
      for(let j = 0; j < this.mstMberKindList.length; j++) {
        if(mberKind == this.mstMberKindList[j].cmn_cd) {
          return this.mstMberKindList[j].cmn_cd_nm;
        }
      }
    },
    // 회원종류 수정됨
    // 회원 종류가 변경될 때 호출되며, 
    // 중복 여부를 확인하고 변경된 데이터를 gridItemChanged에 전달
    mberKindChanged(item) {
      // console.log("mberKindChanged : ", item);
      // 회원 종류 중복 불가
      // 중복되면 해당 회원 종류에 대한 알림을 띄우고 함수를 종료
      for(let i = 0; i < this.gridList.length; i++) {
        // console.log("mberKindChanged : i ", i, item.mber_kind, this.gridList[i].mber_kind);
        if(item.odr != this.gridList[i].odr && item.mber_kind == this.gridList[i].mber_kind) {
          alert("선택한 회원종류(" + this.getMberKindNm(item.mber_kind) + ")는 사용 중입니다.");
          return;
        }
      }

      this.gridItemChanged(item);
    },
    // 회원종류 수정됨
    // 그리드 아이템이 변경될 때 호출되며, 추가된 목록인 경우 changedList에 추가
    gridItemChanged(item) {
      this.itemChanged();
      // console.log("gridItemChanged : item.addr_sno : " + item.addr_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.socty_mber_kind_sno == null || item.socty_mber_kind_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.socty_mber_kind_sno == item.socty_mber_kind_sno) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }
    },
    // 학회 회원 종류 목록을 서버에서 가져와 그리드에 할당하고 초기화
    // this.$http.post("/admin/getSoctyMberKindList", params): 
    // 서버 API를 호출하여 학회 회원 종류 목록을 비동기적으로 가져옴
    // API 응답을 처리하여 각 항목의 속성을 초기화하고, 
    // gridList 및 changedList를 초기화
    getSoctyMberKindList() {
			const params = new FormData();
      // params.append("page", "admin");

			this.$http.post("/admin/getSoctyMberKindList", params).then((res) => {
        // console.log("res.data: " + res.data);
        res.data.result.mberKindList.forEach((e) => {
          e.select_yn = false;
          e.new_yn = false;

          e.join_disp_yn = e.join_disp_yn == 'Y' ? true : false;
          e.use_yn = e.use_yn == 'Y' ? true : false;
          e.indv_mber_kind_yn = e.indv_mber_kind_yn == 'Y' ? true : false;
          e.grp_mber_kind_yn = e.grp_mber_kind_yn == 'Y' ? true : false;
        });

        this.gridList = JSON.parse(JSON.stringify(res.data.result.mberKindList));
        this.changedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    // 선택된 항목을 삭제하고 변경된 항목을 changedList에서 제거
    // 선택된 항목이 없으면 알림을 띄우고 함수를 종료
    // 삭제 확인 창을 띄우고 확인되면 선택된 항목을 서버에서 삭제하고, 
    // gridList 및 changedList에서 해당 항목을 제거
    deleteBtnClicked() {
      console.log("deleteSoctyMberKind");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      console.log("deleteList : ", isSelected, deleteList);
      // deleteList.forEach((e) => {
      //   console.log("deleteList: addr_sno[" + e.addr_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteSoctyMberKindList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getSoctyMberKindList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getSoctyMberKindList();
          this.gridList = this.gridList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    // 새로운 항목을 gridList 및 changedList에 추가
    // 추가될 항목의 odr을 계산하여 새로운 항목을 생성하고 
    // gridList 및 changedList에 추가
    addBtnClicked() {
      let maxOdr = 0;

      this.gridList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.gridList.push({new_yn:true, select_yn:false, socty_mber_kind_sno:null, odr:maxOdr, mber_kind:null, mber_kind_nm:null, 
                          join_disp_yn:false, use_yn:true, indv_mber_kind_yn:true, grp_mber_kind_yn:true});
      this.changedList.push(this.gridList[this.gridList.length - 1]);
      // 객체 복사가 필요하면 아래의 방법으로 해야 함
      //this.changedList.push({...this.gridList[this.gridList.length - 1]}); 
      // 객체 안에 또 객체가 있을 경우 아래의 방법으로 해야 함
      // JSON.parse(JSON.stringify(item))

      // 데이터 검증
      // this.changedList.forEach((e) => {
      //   console.log("changedList: addr_sno[" + e.addr_sno + "]");
      // });
    },
    // 그리드 아이템의 유효성을 검사
    // 그리드의 회원 종류가 중복되는지 확인하고 중복된 경우 알림을 띄우고 false를 반환
    validateInput() {
      if(this.gridList.length > 1) {
        for(let i = 0; i < this.gridList.length - 1; i++) {
          for(let j = i + 1; j < this.gridList.length; j++) {
            // console.log("validateInput i, j : ", this.gridList.length, i, j, this.gridList[i].mber_kind, this.gridList[j].mber_kind)
            if(this.gridList[i].mber_kind == this.gridList[j].mber_kind) {
              alert("선택한 회원종류(" + this.getMberKindNm(this.gridList[j].mber_kind) + ")는 사용 중입니다.");
              return false;
            }
          }
        }
      }

      return true;
    },
    // 변경된 항목을 서버에 저장하고 그리드를 갱신
    // changedList에 변경된 항목이 없으면 함수를 종료
    // 변경된 항목을 서버에 저장하고, 성공적으로 저장된 경우 그리드를 갱신
    saveBtnClicked() {
      console.log("saveSoctyMberKind");

      if(this.changedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      // if(!this.validateInput()) {
      //   return;
      // }

      const changedList = JSON.parse(JSON.stringify(this.changedList));
      // true/false -> 'Y'/'N' 변환
      changedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
        e.join_disp_yn = e.join_disp_yn == true ? 'Y' : 'N';
        e.use_yn = e.use_yn == true ? 'Y' : 'N';
        e.indv_mber_kind_yn = e.indv_mber_kind_yn == true ? 'Y' : 'N';
        e.grp_mber_kind_yn = e.grp_mber_kind_yn == true ? 'Y' : 'N';
      });

      // if(confirm("저장하시겠습니까?")) {
        let params = new URLSearchParams();

        params.append("mber_sno", this.mberSno);
        params.append("changedList", JSON.stringify(changedList));

        this.$http.post("/admin/upsertSoctyMberKindList", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getSoctyMberKindList();
        }).catch(function (error) {
            console.log(error);
        });
      // }
    },
    // 탭이 변경되었을 때 발생하는 이벤트를 부모 컴포넌트로 전달
    // this.$emit('tabChanged', "memberKindTab");: 
    // 부모 컴포넌트에게 "memberKindTab"이라는 이벤트를 발생시킴
    itemChanged() {
      this.$emit('tabChanged', "memberKindTab");
    }
  }
}
</script>

<style>

</style>