<template>
  <v-container fluid class="">
    <v-row class="pa-0">
      <v-col cols="12">
        <v-row class="pa-0" v-if="list.length > 0 && propParams.disp_cnt == 1">
          <v-col cols="12" class="pa-0 d-flex align-center">
            <h1 class="mr-3">{{propParams.disp_item_nm}}</h1>
            <!-- <div><v-icon class="mr-3">mdi-power-on</v-icon>{{gnrtnTerm}}</div> -->
          </v-col>
          <v-col cols="12" class="border-b-darkgrey-2px pt-0 mb-4"></v-col>
          <!-- 
          <v-col cols="12" class="mb-5">
            <div>
              <span>
                <v-icon class="mr-2" color="#B43104">mdi-tooltip-check</v-icon>
                회원에 대한 설명이 필요할 경우 이 영역을 노출시킵니다.
                회원에 대한 설명이 필요할 경우 이 영역을 노출시킵니다.
                회원에 대한 설명이 필요할 경우 이 영역을 노출시킵니다.
                회원에 대한 설명이 필요할 경우 이 영역을 노출시킵니다.
              </span>
            </div>
          </v-col> 
          -->
        </v-row>

        <v-row class="list-mb-80p" v-if="list.length > 0 && propParams.disp_cnt == 1 && propParams.disp_item_nm == '회장'">
          <v-col cols="12" class="min-hei-300p-auto">
            <v-card outlined flat class="hei-300p mb-5" v-for="(item,idx) in list"  :key="`list1_`+idx" v-show="windowWidth > 767">
              <v-row class="ma-0">
                <v-col 
                  cols="12" 
                  class="hei-300p d-flex">
                    <v-row class="ma-0" align="center" >
                      <v-col cols="5" sm="3" md="3" class="">
                        <div class="border-grey bg-lightgrey-2 hei-210p d-flex justify-center align-center overflow-hidden bg-white">
                          <!-- <img class="imgDf-h" src="https://dispatch.cdnser.be/wp-content/uploads/2017/02/be247503d597fc4b0c5c814ffd68a534.jpg"> -->
                          <img class="imgDf-h" :src="item.file_path" v-if="item.file_path">
                          <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon class="tborder" style="font-size:15em !important">mdi-account</v-icon></div>
                        </div>
                      </v-col>
                      <v-col cols="7" class="">
                        <div class="mb-7 hei-40p">
                          <h2 class="m mem-border-b-short">{{item.mber_nm}}</h2>
                        </div>
                        <div class="mb-7 hei-40p">
                          <div class="fontSize-2" v-show="item.blng_nm">소속: {{item.blng_nm}}</div>
                          <div class="fontSize-2" v-show="item.duty_nm">직무: {{item.duty_nm}}</div>
                        </div>
                        <div class="color-lightblue fontSize-2 hei-40p">
                          <div v-show="item.cel_no">연락처 : {{item.cel_no}}</div>
                          <div v-show="item.email">이메일 : {{item.email}}</div>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- <div class="wid-20">
                      <div class="border-grey bg-lightgrey-2 wid-210p hei-210p d-flex justify-center align-center overflow-hidden bg-white">
                        <img class="image-default-size" :src="item.file_path" v-if="item.file_path">
                        <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon style="font-size: 15em !important;">mdi-account</v-icon></div>
                      </div>
                    </div>
                    <div class="wid-80">
                      <div class="mb-7">
                        <h2 class="mb-2 mem-border-b-short">{{item.mber_nm}}</h2>
                      </div>
                      <div class="mb-7">
                        <div class="fontSize-2">소속: {{item.blng_nm}}</div>
                        <div class="fontSize-2">직무: {{item.duty_nm}}</div>
                      </div>
                      <div class="color-lightblue fontSize-2">
                        <div>연락처 : {{item.cel_no}}</div>
                        <div>이메일 : {{item.email}}</div>
                      </div>
                    </div> -->
                </v-col>
              </v-row>
            </v-card>

            <v-card outlined flat class="ma-0" v-for="(item,idx) in list"  :key="`list1_`+idx" v-show="windowWidth < 768">
              <v-row class="ma-0" >
                <v-col cols="12" class="hei-250p d-flex align-center">
                  <div class="wid-100 d-flex align-center">
                    <v-row class="ma-0" align="center">
                      <v-col cols="5" class="pa-0">
                        <div class="border-grey bg-lightgrey-2 wid-100 d-flex justify-center align-center overflow-hidden bg-white">
                          <!-- <img class="imgDf" src="https://dispatch.cdnser.be/wp-content/uploads/2017/02/be247503d597fc4b0c5c814ffd68a534.jpg"> -->
                          <img class="imgDf" :src="item.file_path" v-if="item.file_path">
                          <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon style="font-size: 13em !important;">mdi-account</v-icon></div>
                        </div>
                      </v-col>
                      <v-col cols="7" class="pa-0 pl-4">
                        <div class="hei-50p">
                          <h2 class="mem-border-b-short">{{item.mber_nm}}</h2>
                        </div>
                        <div class="hei-50p mb-2">
                          <div class="fontSize-2" v-show="item.blng_nm">소속: {{item.blng_nm}}</div>
                          <div class="fontSize-2" v-show="item.duty_nm">직무: {{item.duty_nm}}</div>
                        </div>
                        <div class="hei-70p color-lightblue">
                          <div class="emailWord" v-show="item.cel_no">연락처 : {{item.cel_no}}</div>
                          <div v-show="item.email">이메일 : </div>
                          <div class="emailWord" v-show="item.email">{{item.email}} </div>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- <div class="wid-40">
                      <div class="border-grey bg-lightgrey-2 wid-210p hei-210p d-flex justify-center align-center overflow-hidden bg-white">
                        <img class="image-default-size" :src="item.file_path" v-if="item.file_path">
                        <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon style="font-size: 15em !important;">mdi-account</v-icon></div>
                      </div>
                    </div>
                    <div class="wid-60">
                      <div class="mb-7">
                        <h2 class="mb-2 mem-border-b-short">{{item.mber_nm}}</h2>
                      </div>
                      <div class="mb-7">
                        <div class="fontSize-2">소속: {{item.blng_nm}}</div>
                        <div class="fontSize-2">직무: {{item.duty_nm}}</div>
                      </div>
                      <div class="color-lightblue fontSize-2">
                        <div>연락처 : {{item.cel_no}}</div>
                        <div>이메일 : {{item.email}}</div>
                      </div>
                    </div> -->
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- 부회장 2개-->
        <v-row class="pa-0" v-if="list.length > 0 && propParams.disp_cnt == 2">
          <v-col cols="12" class="pa-0 d-flex align-center">
            <h1 class="mr-3">{{propParams.disp_item_nm}}</h1>
          </v-col>
          <v-col cols="12" class="border-b-darkgrey-2px mb-4"></v-col>
        </v-row>
        <v-row class="list-mb-80p" v-if="list.length > 0 && propParams.disp_cnt == 2">
          <v-col 
            cols="12"
            sm="6"
            md="6"
            class="hei-250p mb-5"
            v-for="(item, idx) in list"
            :key="`list2_`+idx">
            <v-card outlined flat class="ma-0">
              <v-row class="ma-0" >
                <v-col cols="12" class="hei-250p d-flex align-center">
                  <div class="wid-100 d-flex align-center">
                    <v-row class="ma-0" align="center">
                      <v-col cols="5" class="pa-0 hei-200p">
                        <div class="border-grey bg-lightgrey-2 wid-100 hei-100 d-flex justify-center align-center overflow-hidden bg-white">
                          <!-- <img class="imgDf" src="https://dispatch.cdnser.be/wp-content/uploads/2017/02/be247503d597fc4b0c5c814ffd68a534.jpg"> -->
                          <img class="imgDf" :src="item.file_path" v-if="item.file_path">
                          <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon style="font-size: 14em !important;">mdi-account</v-icon></div>
                        </div>
                      </v-col>
                      <v-col cols="7" class="pa-0 pl-4 ">
                        <div class="hei-50p">
                          <h2 class="mem-border-b-short">{{item.mber_nm}}</h2>
                        </div>
                        <div class="hei-50p mb-2">
                          <div class="fontSize-2" v-show="item.blng_nm">소속: {{item.blng_nm}}</div>
                          <div class="fontSize-2" v-show="item.duty_nm">직무: {{item.duty_nm}}</div>
                        </div>
                        <div class="hei-70p color-lightblue">
                          <div class="emailWord" v-show="item.cel_no">연락처 : {{item.cel_no}}</div>
                          <div v-show="item.email">이메일 : </div>
                          <div class="emailWord" v-show="item.email">{{item.email}} </div>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- <div class="wid-40">
                      <div class="border-grey bg-lightgrey-2 wid-210p hei-210p d-flex justify-center align-center overflow-hidden bg-white">
                        <img class="image-default-size" :src="item.file_path" v-if="item.file_path">
                        <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon style="font-size: 15em !important;">mdi-account</v-icon></div>
                      </div>
                    </div>
                    <div class="wid-60">
                      <div class="mb-7">
                        <h2 class="mb-2 mem-border-b-short">{{item.mber_nm}}</h2>
                      </div>
                      <div class="mb-7">
                        <div class="fontSize-2">소속: {{item.blng_nm}}</div>
                        <div class="fontSize-2">직무: {{item.duty_nm}}</div>
                      </div>
                      <div class="color-lightblue fontSize-2">
                        <div>연락처 : {{item.cel_no}}</div>
                        <div>이메일 : {{item.email}}</div>
                      </div>
                    </div> -->
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- 부회장 3개-->
        <v-row class="pt-5" v-if="list.length > 0 && propParams.disp_cnt == 3">
          <v-col cols="12" class="pa-0 d-flex align-center">
            <h1 class="mr-3">{{propParams.disp_item_nm}}</h1>
          </v-col>
          <v-col cols="12" class="border-b-darkgrey-2px mb-10"></v-col>
        </v-row>
        <v-row class="list-mb-80p" v-if="list.length > 0 && propParams.disp_cnt == 3">
          <v-col 
            cols="6"
            sm="4"
            md="4"
            class=" hei-220p" 
            v-for="(item, idx) in list"
            :key="`list3_`+idx">
            <v-card outlined flat class="bg-lightgrey-2 hei-210p">
              <v-row class="ma-0" align="center">
                <v-col cols="12" class="pa-0 pl-1 pr-1 hei-210p">
                  <div class="wid-100 hei-100 d-flex align-center">
                    <div class="wid-100 pl-1 pr-1" v-show="windowWidth > 768">
                      <v-row class="ma-0">
                        <v-col cols="12" sm="5" md="5" class="pa-0">
                          <div class="circle d-flex justify-center align-center overflow-hidden bg-white">
                            <!-- <img class="imgDf pt-6" src="https://dispatch.cdnser.be/wp-content/uploads/2017/02/be247503d597fc4b0c5c814ffd68a534.jpg"> -->
                            <img class="imgDf pt-6" v-if="item.file_path">
                            <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon style="font-size: 7em !important;">mdi-account</v-icon></div>
                          </div>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="6" sm="5" class="pa-0 pl-1">
                          <div class="mt-5">
                            <div class="fontSize-3 font-weight-bold pb-1">{{item.mber_nm}}</div>
                            <div class="fontSize-0" v-show="item.blng_nm">{{item.blng_nm}}</div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pt-3" >
                        <v-col cols="12" class="pa-0">
                          <div class="color-lightblue fontSize-0 hei-50p">
                            <div class="mb-1" v-show="item.cel_no">연락처 : {{item.cel_no}}</div>
                            <div v-show="item.email">이메일 : {{item.email}}</div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                      <!-- [[[[[[ mobile ]]]]]] -->
                    <div class="wid-100 pl-1 pr-1" v-show="windowWidth < 768">
                      <v-row class="ma-0">
                        <v-col cols="12" sm="5" md="5" class="pa-0">
                          <div class="mobCircle d-flex justify-center align-center overflow-hidden bg-white">
                            <!-- <img class="imgDf pt-6" src="https://dispatch.cdnser.be/wp-content/uploads/2017/02/be247503d597fc4b0c5c814ffd68a534.jpg"> -->
                            <img class="imgDf pt-6" v-if="item.file_path">
                            <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon style="font-size: 5em !important;">mdi-account</v-icon></div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0">
                        <v-col cols="12" class="d-flex justify-center pa-0">
                          <div class="fontSize-2 font-weight-bold">{{item.mber_nm}}</div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center pa-0 hei-25p">
                          <div class="fontSize-1" v-show="item.blng_nm">{{item.blng_nm}}</div>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0">
                        <v-col cols="12" class="pa-0">
                          <div class="color-lightblue fontSize-0-2 hei-70p">
                            <div class="" v-show="item.cel_no">연락처 :</div>
                            <div class="pb-1" v-show="item.cel_no">{{item.cel_no}}010-0000-0000</div>
                            <div class="" v-show="item.email">이메일 :</div>
                            <div class="emailWord" v-show="item.email">{{item.email}}asdfas@asdfasdf.com</div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        
        <!-- 부회장 4개-->
        <v-row class="pt-5" v-if="list.length > 0 && propParams.disp_cnt == 4" >
          <v-col cols="12" class="pa-0 d-flex align-center">
            <h1 class="mr-3">{{propParams.disp_item_nm}}</h1>
          </v-col>
          <v-col cols="12" class="border-b-darkgrey-2px mb-10"></v-col>
        </v-row>
        <v-row class="list-mb-80p" v-if="list.length > 0 && propParams.disp_cnt == 4">
          <v-col 
            cols="6"
            sm="3"
            md="3" 
            class="hei-210p mb-5"  
            align="center"
            v-for="(item, idx) in list"
            :key="`list4_`+idx"
          >
            <v-card outlined class="bg-lightgrey-2 hei-210p d-flex justify-center">
              <div class="align-self-center">
                <div class="circle d-flex justify-center align-center overflow-hidden bg-white mb-4" :class="windowWidth > 767 ? 'circle' : 'mobCircle'">
                  <img class="imgDf pt-6" v-if="item.file_path">
                  <div class="hei-100 wid-100 d-flex justify-center align-center" v-if="!item.file_path"><v-icon :style="windowWidth > 767 ? 'font-size: 7em' : 'font-size: 5em '">mdi-account</v-icon></div>
                </div>
                <div class="mt-5 hei-40p">
                  <div class="fontSize-3 font-weight-bold pb-1">{{item.mber_nm}}</div>
                  <div class="fontSize-0" v-show="item.blng_nm">{{item.blng_nm}}</div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  props: ["propParams"],
  data () {
    return {
      windowWidth: window.innerWidth,
      list: [],
      tt: 0,
    }
  },
  created() { 
    this.list = this.propParams.list;
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.v-tabs-bar {
    border-radius: inherit;
    height: 70px;
}
.v-slide-group__next, .v-slide-group__prev {
  border: #D8D8D8 solid 0.5px !important;
}

.emailWord {
  width: 90%; 
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  }
</style> 