<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center" dense>
          <v-col cols="3">
            <!-- <v-text-field 
              label="검색어" 
              class="f-l pa-0 ma-0 wid-100" 
              v-model="searchWord" 
              solo 
              hide-details>
            </v-text-field> -->
          </v-col>
          <v-col cols="1">
            <!-- <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text f-r" 
              @click="searchBtnClicked">
              검색
            </v-btn> -->
          </v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                @click="addConfrBtnClicked"
              >학술대회 추가</v-btn>
              <v-btn 
                small 
                color="red lighten-2" 
                class="white--text f-r" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1"
              style="cursor: pointer"
            >
              <!-- @click:row="confrRowClicked($event)" -->
            <!-- <template v-slot:[`item.confr_check`]="{ item }">
              <v-simple-checkbox
                v-model="item.confr_check"
                hide-details>
              </v-simple-checkbox>
            </template> -->
            <template v-slot:[`item.select_yn`]="{ item }">
              <v-checkbox
                v-model="item.select_yn"
                class="pa-0 ma-0"
                hide-details
                dense
              ></v-checkbox>
            </template>
            <template v-slot:[`item.confr_nm`]="{item}">
              <v-text-field
                v-model="item.confr_nm"
                hide-details
                dense
                readonly
                flat
                solo
                class="pa-0 ma-0 text-field-transparent"
                @click="confrRowClicked(item)"
              >
              </v-text-field>
            </template>
            <template v-slot:[`item.confr_term`]="{ item }" >
              <div @click="confrRowClicked(item)">{{item.confr_term }}</div>
            </template>
            <template v-slot:[`item.abstr_reg_cnt`]="{ item }" >
              <div @click="confrRowClicked(item)">{{item.abstr_reg_cnt }}</div>
            </template>
            <template v-slot:[`item.pre_reg_cnt`]="{ item }" >
              <div @click="confrRowClicked(item)">{{item.pre_reg_cnt }}</div>
            </template>
            <template v-slot:[`item.ntce_yn`]="{ item }" >
              <div @click="confrRowClicked(item)">{{item.ntce_yn }}</div>
            </template>
          </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.conferenceList.radioNtceYn"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>홈페이지 게시로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      mberChkDialog: false,
      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%" },
        { text: '행사명', value: 'confr_nm', width: "25%" },
        { text: '기간', value: 'confr_term', width: "25%" },
        { text: '발표신청', value: 'abstr_reg_cnt', width: "15%" },
        { text: '사전등록/참가자', value: 'pre_reg_cnt', width: "15%" },
        { text: '홈페이지 게시', value: 'ntce_yn', width: "15%" }
      ],
      gridList: [],
      confrList: [],

      // radioNtceYn: "모두",
    }
  },
  created() {

    this.getConfrList();
  },
  mounted(){
    
  },
  methods: {
    getConfrList() {
      this.$http.get("/admin/getConfrList").then((res) => {
        // console.log("res.data.result:", res.data.result);
        res.data.result.forEach((e) => {
          e.select_yn = false;
        });

        // this.gridList = res.data.result;
        this.confrList = res.data.result;
        this.filterList();
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },
    confrRowClicked(event) {
      // console.log("confrRowClicked", event);
      this.$router.push({name:"conferenceDetail", query: { confrSno:event.confr_sno, newYn:'N' }});
    //   this.$router.push({path:"memberDetail"});
    },
    // 학술대회 추가 버튼 클릭
    addConfrBtnClicked() {
      this.$router.push({name:"conferenceDetail", query: { confrSno:null, newYn:'Y' }});
    },
    deleteBtnClicked() {
      console.log("deleteConferenceList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: prod_sno[" + e.prod_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteConfrList", params).then((res) => {
            if(res.data.code == 0) {
              alert("삭제되었습니다.");
              // this.getConfrList();
              this.gridList = this.gridList.filter((e) => {
                return !(e.select_yn);
              });
            } else if(res.data.code == -9003) {
              let inUseList = [];
              res.data.inUseList.forEach((e) => {
                inUseList.push(e.confr_nm);
              });
              alert("삭제할 수 없습니다. 학술대회가 사용 중입니다.\n" + inUseList.join());
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    radioFilterChanged() {
      this.filterList();
    },
    filterList() {
      this.gridList = this.confrList.filter((e) => {
        // console.log("e:", e);
        if( (this.$store.state.conferenceList.radioNtceYn == "모두" || e.ntce_yn == this.$store.state.conferenceList.radioNtceYn) 
          ) return true;
      });
    }
  }
}
</script>
  
<style>

</style>