<template >
  <v-container fluid> 
    <v-data-iterator
      :items="qnaBbsList"
      hide-default-footer
    >
      <template v-slot:header>
        <v-row
          class="mt-2"
          align="center"
          justify="center"
          >
          <v-col cols="12" class="d-flex align-center">
            <div class="wid-150p mr-2" v-show="filterList.length > 0 && windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                style="font-size: 13px;"
                class="dreamFont4"
                :items="filterList"
                v-model="defaultFilter"
                @change="filterListChanged"
              >
              </v-select>
            </div>
            <div class="wid-400p mr-2" v-show="windowWidth > 767">
              <v-text-field
                outlined
                flat
                solo
                hide-details
                style="font-size: 13px;"
                class="dreamFont4"
                prepend-inner-icon="mdi-magnify"
                label="Search"
                v-model="search"
              >
              </v-text-field>
            </div>
            <div class="wid-200p mr-2" v-show="windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                style="font-size: 13px;"
                class="dreamFont4"
                prepend-inner-icon="mdi-magnify"
                :items="sortByList"
                item-text="cmn_cd_nm"
                item-value="cmn_cd"
                v-model="sortType"
                label="sort"
                @change="sortChanged"
              >
              </v-select>
            </div>
            <div class="wid-100p mr-2" v-show="windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                style="font-size: 13px;"
                class="dreamFont4"
                v-model="itemsPerPage"
                :items="itemsPerPageArray"
                @change="itemsPerPageChanged"
              >
              </v-select>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              small
              outlined
              style="font-size: 13px;"
              class="ml-1 hei-30p dreamFont4"
              v-show="page != 1"
              @click="prePageBtnClicked"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="mr-4 ml-4 grey--text wid-100p d-flex align-center">
              <div class="wid-60p">
                <v-text-field 
                  hide-details 
                  outlined 
                  style="font-size: 13px;"
                  class="wid-50p mr-1 dreamFont4" 
                  v-model="page"> 
                </v-text-field>
              </div>
              <div class="mr-3">/</div>
              <div class="dreamFont4" style="font-size: 13px;"> {{pageMax}} </div>
            </div>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              v-show="pageMax != page"
              @click="nextPageBtnClicked"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="pa-0" style="border-top: 1px solid black;"></v-col>
        </v-row>
      </template>

      <template>
        <v-row>
          
          <v-col cols="12" class="pa-0"> 
            <v-data-table
              :headers="gridHeader"
              class="elevation-0"
              :search="search"
              :items="qnaBbsList"
              :items-per-page.sync="itemsPerPage"
              :page.sync="page"
              disable-sort
              style="cursor: pointer;"
              @click:row="listClicked($event)"
              hide-default-footer
              mobile-breakpoint="0">
              
                <template v-slot:[`item.bbst_ttl`]="{item}">
                  <v-list-item-title
                    v-model="item.bbst_ttl"
                    text
                    class="pa-0 ma-0 d-flex float-left">
                    <v-icon small color="blue" class="mr-2" v-if="item.level == 2">mdi-arrow-right-bottom</v-icon>
                    <span 
                      class="mr-2"
                      :class="windowWidth > 768 ? 'titleWord':'mobTitleWord'">{{ item.bbst_ttl }}</span>
                    <v-icon small v-if="item.atch_cnt > 0" class="ml-2" color="blue">mdi-cloud-arrow-down-outline</v-icon>
                  </v-list-item-title>
                </template>

            </v-data-table>

          </v-col>
        </v-row>
      </template>

      
      <template v-slot:no-result>
        <v-row class="pa-0">
          <v-col cols="12" class="pa-2">
            <span class="font-weight-bold fontSize-3">
              검색된 데이터가 없습니다.
            </span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center">
          <v-col cols="12" class="border-t-grey pa-0 mt-1"></v-col>
          <v-col cols="" class="d-flex align-center">
            <div class="wid-150p mr-2">
              <v-btn
                outlined
                style="font-size: 13px;"
                class="elevation-0 bg-lightgrey font-weight-bold dreamFont3"
                @click="questionBtnClicked"
                v-if="chg_perm_yn == 'Y'"
              >
                질문하기
              </v-btn> 
            </div>
            <v-spacer></v-spacer>
            <v-btn
              small
              outlined
              style="font-size: 13px;"
              class="ml-1 hei-30p dreamFont3"
              v-show="page != 1"
              @click="prePageBtnClicked"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="mr-4 ml-4 grey--text wid-100p d-flex align-center">
              <div class="wid-60p">
                <v-text-field 
                  hide-details 
                  outlined 
                  style="font-size: 13px;"
                  class="wid-50p mr-1 dreamFont3" 
                  v-model="page"
                > 
                </v-text-field>
              </div>
              <div class="mr-3">/</div>
              <div class="dreamFont3"> {{pageMax}} </div>
            </div>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              style="font-size: 13px;"
              v-show="pageMax != page"
              @click="nextPageBtnClicked"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

    </v-data-iterator>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      classAppliedCondition: true,
      windowWidth: window.innerWidth,
      gridHeader: [
        { text: '번호', align: 'center', value: 'no', width:"10%", },
        { text: '제목', align: 'center', value: 'bbst_ttl', width:"49%", },
        { text: '등록자', value: 'reg_mber_nm', width:"20%", align: 'center d-none d-sm-table-cell', },
        { text: '등록일', value: 'reg_dt', width: '11%', align: 'center d-none d-sm-table-cell', },
        { text: '조회', value: 'read_cnt', width:"10%", align: 'center d-none d-sm-table-cell', },
      ],
      qnaList: [
        {
          no:'1',
          title:'absd',
          reg_user:'absd',
          reg_date:'absd',
          reg_cnt:'11',
        },
        {
          no:'2',
          title:'absd',
          reg_user:'absd',
          reg_date:'absd',
          reg_cnt:'121',
        },

      ],
      search: '',
      gridViewYn: false,
      itemsPerPageArray: [10, 20],
      page: 1,
      itemsPerPage: 10,
      totalLen: 0,
      pageMax: 0,
      filterList: [],
      sortType: 'latest',
      sortByList: [],
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      menu_sno: '',
      emitData: {},
      page_type: '',
      defaultFilter: '전체',
      qnaBbsList: [],
      qnaBbsList_ori: [],
      basicUri: '',
      pageNo : this.$route.query.pageNo ? this.$route.query.pageNo : '',
      chg_perm_yn: 'N',
    }
  },
  watch: {
    page: function(val) {
      // alert(val);
      this.$router.push(this.$route.path+'?pageNo='+val).catch(()=>{});
    }
  },
  created() {
    if(this.pageNo) {
      this.page = Number(this.pageNo);
    }
    this.getSortByList();
    let path = this.$route.fullPath;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    async getSortByList() {
      this.sortByList = await this.$getCmnCdList("sort_by");
      this.selectBbsQnaList();
    },//getSortByList end
    selectBbsQnaList() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("sortType", this.sortType);
      params.append("non_mber_yn", this.nonMberYn);

      this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
        console.log("res.data what::",res.data);
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.$router.push("/");
        } else {
          this.menu_sno = res.data.menu_sno;
          this.chg_perm_yn = res.data.chg_perm_yn;
          this.qnaBbsList_ori = res.data.qnaBbsList;

          res.data.qnaBbsList.forEach((e) => {
            e.reg_dt = e.reg_dt.substring(0, 10)
          })

          this.qnaBbsList = res.data.qnaBbsList;

          this.totalLen = this.qnaBbsList.length;
          this.pageMax = Math.ceil(this.totalLen / this.itemsPerPage);

          this.qnaBbsList.forEach((e) => {
            if(e.bbst_catg) {
              e.bbst_ttl = '['+e.bbst_catg+'] '+e.bbst_ttl;
            }
          })

          const menuDtl = res.data.menuDetail;
          this.page_type = menuDtl.page_type;
          
          if(menuDtl.bbst_catg) {
            const arrCate = menuDtl.bbst_catg.split(',');

            this.filterList[0] = '전체';
            this.filterList = [
              ...this.filterList,
              ...arrCate
            ];
          }
          // if(this.prePageNo && this.pageMax >= this.prePageNo) {
          //   this.page = this.prePageNo;
          // }
          

          this.emitData.menu_sno = this.menu_sno;
          this.$emit('setData', this.emitData);
          
        }
        // this.gridViewYn = true;
      })
        .catch(function (error) {
          console.log(error);
        }
      );
    },//selectBbsList end
    nextPageBtnClicked() {
      this.page++;
      
    },//nextPageBtnClicked end
    prePageBtnClicked() {
      this.page--;
    },//prePageBtnClicked end
    itemsPerPageChanged() {
      if(this.totalLen < this.itemsPerPage) {
        this.pageMax = 1;
      } else {
        this.pageMax = Math.ceil(this.totalLen / this.itemsPerPage);
      }
      
    },//itemsPerPageChanged end
    filterListChanged() {
      if(this.defaultFilter == '전체') {
        this.qnaBbsList = this.qnaBbsList_ori;
      } else {
        this.qnaBbsList = this.qnaBbsList_ori.filter((e) => e.bbst_catg == this.defaultFilter);
      }
      
    },//filterListChanged end
    questionBtnClicked() {
      const moveUrl = this.basicUri+'/write?menu_sno='+this.menu_sno;
      this.$router.push(moveUrl);
    },//questionBtnClicked end
    listClicked(event) {
      console.log(event);
      let type = '';
      let sno = '';
      if(event.level == 1) {
        type = 'main';
        sno = event.bbst_sno;
      } else if(event.level == 2) {
        type = 'reply';
        sno = event.reply_sno;
      }

      const moveUrl = this.basicUri+'/detail?sno='+sno+'&con_type='+type+'&pageNo='+this.pageNo;
      this.$router.push(moveUrl);
    },//listClicked end
    sortChanged() {
      this.selectBbsQnaList();
    },
  }
}
</script>
<style scoped>
  .hoverCheck:hover {
    cursor: pointer !important;
  }

  .titleWord {
    display:block; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    word-break: break-all;
    max-width: 450px;
  }
  .mobTitleWord {
    display:block; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    word-break: break-all;
    max-width: 200px;
  }

  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 13px !important;
    /* font-weight: bold !important; */
    padding: 13px 6px 13px 6px;
    font-family: dreamFont4;
  }

  ::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 13px !important;
    padding: 13px 6px 13px 6px;
    max-width: 100% !important;
    font-family: dreamFont3;

  }

  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td > div > span {
    font-size: 13px !important; 
  }

  ::v-deep .v-list-item__title > .titleWord {
    font-family: dreamFont3 !important;
  }

  ::v-deep .v-list-item__content > .v-list-item__title {
    font-family: dreamFont4 !important;
  }

  label {
  font-size: 13px !important;
  }

  .dreamFont3 {
    font-family: dreamFont3 !important;
  }

  .dreamFont4 {
  font-family: dreamFont4 !important;
  }
</style>