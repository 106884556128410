<template>
  <v-container class="pa-3">
    <v-row class="fill-height">
      <v-col cols="12">
          <v-toolbar
            flat
          >
            <v-row align="center" class="wid-100">
              <v-col cols="3" class=""></v-col>
              <v-col cols="6" class="justify-center d-flex pa-0 ma-0 align-center" >
                <v-btn
                  fab
                  text
                  medium
                  color="grey darken-2"
                  class="mr-4"
                  @click="prev">
                  <v-icon large>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                
                <v-toolbar-title v-if="viewYn">
                <h2 class="dreamFont4"> {{ $refs.calendar.title.split(' ')[1] }}년 {{ $refs.calendar.title.split(' ')[0] }} </h2>
                </v-toolbar-title>

                <v-btn
                  fab
                  text
                  medium
                  color="grey darken-2"
                  class="ml-4"
                  @click="next"
                >
                  <v-icon large>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3" class="d-flex justify-end">
                <v-menu
                  bottom
                  right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on">
                      <span class="dreamFont4">{{ typeToLabel[type] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="dreamFont4">
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-toolbar>
        </v-col>
        <v-col cols="12" class="mb-10">
        <v-sheet height="800">
          <v-calendar
            
            :weekday-format="getDay"
            ref="calendar"
            v-model="focus"
            color="yellow"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            
            @click:day="(event)=>dateClick(event)"
          
          ></v-calendar>
          <!-- @click:event="showEvent($event)" -->
          
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            @click="handleMenuClick(selectedEvent)"
            min-width="370px"
            max-width="370px"
            class="elevation-0"
            offset-x>
            <v-card
              tile
              outlined
              color="white"
              min-width="350px"
              max-width="350px"
              class="pa-5">
              <v-toolbar
                height="40px"
                color="white"
                class="elevation-0">
                <v-toolbar-title class="toolbar_tt">{{ popDate }}</v-toolbar-title>
              </v-toolbar>
              <div class="wid-100 border-t-darkgrey">
                
                <v-row class="mt-2" align="center" v-for="(item, idx) in popList" :key="`pop_`+idx">
                  <v-col cols="12">
                    <v-icon color="primary">mdi-circle-medium</v-icon>
                    <span class="fontSize-2 font-weight-bold">{{ item.scdul_ttl }}</span>
                    <div class="pl-7 pt-1">
                      <!-- <div>{{item.scdul_bgn_dt}} - {{item.scdul_end_dt}}</div> -->
                      <div v-html="insertLineBreaks(item.scdul_desc)"></div>
                    </div>
                  </v-col>
                </v-row>

              </div>
            </v-card>
          </v-menu>
          
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data(){
      return{
        focus:'',
        type: 'month',
        typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
        nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
        emitData: {},
        clickMonth: '',
        viewYn: false,
        scdulList: [],
        popDate: '',
        popList: [],
      }
    },
    created() {
      // const formData = new FormData();
      // formData.append('mber_sno', this.mberSno);
      // this.$http.post('/sub'+this.$route.fullPath, formData).then((res) => {
      //   console.log("res :: "+res.data);
      //   if(res.data.code < 0) {
      //     alert(this.$erroCdMsg(res.data.code));
      //     this.$router.push("/");
      //     return;
      //   } else {
          
      //     console.log(res.data);
      //   }
      //   // alert(res.data.menu_sno);
      //     this.emitData.menu_sno = res.data.menu_sno;
      //     this.$emit('setData', this.emitData);

      //     this.events.push({
      //       name: '테스트',
      //       detail: 'test',
      //       start: new Date('2023-07-28 01:01:01 '),
      //       end: new Date('2023-08-01 01:01:01 '),
      //       color: 'blue',
      //     });
      //     // this.clickMonth = this.$refs.calendar.times.now.month

      // }).catch(
      //   function (error) {
      //   console.log(error);
      //   }
      // )

    },
    mounted () {
      this.$refs.calendar.checkChange()
      this.clickMonth = this.$refs.calendar.times.now.date.replace(/-/gi,"");

      const formData = new FormData();
      formData.append('mber_sno', this.mberSno);
      formData.append('clickMonth', this.clickMonth);
      formData.append("non_mber_yn", this.nonMberYn);

      this.$http.post('/sub'+this.$route.fullPath, formData).then((res) => {
        console.log(res.data);
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.$router.push("/");
          return;
        } else {
          
          this.scdulList = res.data.scdulList;
        }

        this.scdulList.forEach((e) => {

          e.scdul_bgn = e.scdul_bgn_dt.replace(/-/gi, '');
          e.scdul_end = e.scdul_end_dt.replace(/-/gi, '');

          this.events.push({
            name: e.scdul_ttl,
            scdul_bgn_dt: e.scdul_bgn_dt,
            scdul_end_dt: e.scdul_end_dt,
            start: new Date(e.scdul_bgn_dt),
            end: new Date(e.scdul_end_dt),
            color: this.colors[this.rnd(0, this.colors.length - 1)],
          });
        })

        this.emitData.menu_sno = res.data.menu_sno;
        this.$emit('setData', this.emitData);
        this.viewYn = true;
          
          // this.clickMonth = this.$refs.calendar.times.now.month

      }).catch(
        function (error) {
        console.log(error);
        }
      )
    },
    methods: {
      handleMenuClick(event) {
        this.startEndDay(event);
      },
      insertLineBreaks(text) {
        return text ? text.replace(/\n/g, '<br>') : '';
      },
      getDay({ date }) {
        const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
        let i = new Date(date).getDay(date);
        return daysOfWeek[i];
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      async prev () {
        await this.$refs.calendar.prev();
        // this.clickMonth = this.$refs.calendar.lastStart.date.replace(/-/gi,"");
        this.getClickedScdulList();
      },
      async next () {
        await this.$refs.calendar.next();
        // this.clickMonth = this.$refs.calendar.lastStart.date.replace(/-/gi,"");
        this.getClickedScdulList();
        
      },
      // showEvent ({ nativeEvent, event }) {
      showEvent (event) {
        const date = event.date.replace(/-/gi, '');
        const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
        const dateObj = new Date(event.date);
        const dayOfWeek = daysOfWeek[dateObj.getDay()]; // 요일 가져오기
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const dateOfMonth = dateObj.getDate();

        this.popDate = `${year}년 ${month}월 ${dateOfMonth}일 ${dayOfWeek}`;

        this.popList = this.scdulList.filter((e) => e.scdul_bgn <= date && e.scdul_end >= date);
        
        if(this.popList.length > 0) {
          
          const nativeEvent = event.nativeEvent;
          const open = () => {
            this.selectedElement = nativeEvent.target;
            
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
          }
          if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
          } else {
            open()
          }

          nativeEvent.stopPropagation()
        }
      },
      
      getClickedScdulList() {
        this.clickMonth = this.$refs.calendar.lastStart.date.replace(/-/gi,"");

        const formData = new FormData();

        formData.append('mber_sno', this.mberSno);
        formData.append('clickMonth', this.clickMonth);
        formData.append('menu_sno', this.emitData.menu_sno);

        console.log("getClickedScdulList : ", formData);

        this.$http.post('/sub/getClickedScdulList', formData).then((res) => {
          this.scdulList = res.data.scdulList;
          
          this.events = [];
          this.scdulList.forEach((e) => {

          e.scdul_bgn = e.scdul_bgn_dt.replace(/-/gi, '');
          e.scdul_end = e.scdul_end_dt.replace(/-/gi, '');

          this.events.push({
            name: e.scdul_ttl,
            scdul_bgn_dt: e.scdul_bgn_dt,
            scdul_end_dt: e.scdul_end_dt,
            start: new Date(e.scdul_bgn_dt),
            end: new Date(e.scdul_end_dt),
            color: this.colors[this.rnd(0, this.colors.length - 1)],
          });
        })

        }).catch(
          function (error) {
          console.log(error);
          }
        )
      },//날짜 변경시 재조회
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },//rnd end
      dateClick(event) {
        
        this.showEvent(event);
      },//dateClick end
    },

  }
</script>

<style scoped>
.v-menu__content{
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0), 0px 8px 10px 1px rgba(0, 0, 0, 0), 0px 3px 14px 2px rgba(0, 0, 0, 0);
  border: 10px solid #d8d8d8;
}

.toolbar_tt {
  font-size: 18px !important;
  font-weight: 600;
}

.dreamFont2 {
  font-family: dreamFont2 !important;
}

.dreamFont4 {
  font-family: dreamFont4 !important;
}
</style>