<template>
  <v-container class="pa-3" v-if="mberSno != '' && payNo != '' ">
    <v-row class="pa-0 mb-10" >
      <v-col cols="12" >
        결제 실패
      </v-col>
      <v-col cols="12" >
        에러 코드 : {{detailMap.pay_res_cd}}
      </v-col>
      <v-col cols="12" >
        에러 메시지 : {{detailMap.pay_res_msg}}
      </v-col>
    </v-row>
    <v-btn @click="moveListClicked">목록으로</v-btn>
  </v-container>
</template>
        

<script>
export default {
  data () {
      return {
        basicUri: '',
        mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
        payNo: this.$route.query.payNo,
        detailMap: {},
      }
    },
    created() {
      let path = this.$route.path;
      this.basicUri = path.substring(path.lastIndexOf('/'), -1);

      if(!this.payNo) {
        alert('잘못된 접근입니다.');
        this.moveListClicked();
      }

      const formData = new FormData();
      formData.append("mber_sno", this.mberSno);
      formData.append("pay_no", this.payNo);
      
      this.$http.post('/sub/getPayHist', formData).then((res) => {
        console.log("detail :: ",res.data);

        if(!res.data.detailMap) {
          alert('잘못된 접근입니다.');
          this.moveListClicked();
        } else {
          this.detailMap = res.data.detailMap;
        }
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },
    methods: {
      moveListClicked() {
        this.$router.push(this.basicUri+"/list");
      }
    }
  }

</script>

<style>
</style>