<template>
  <v-app class="bg-main">
    <header-a/>

    <v-container fluid class="hei-150p d-flex align-end bgImg-cover " style="background-image:url('https://img.freepik.com/free-vector/unfocussed-winter-background-with-snowflakes_1393-271.jpg');">
      <template>
        <v-container class=" hei-130p d-flex align-end" style="width:1200px;" >
          <!-- [[[[[[  web   ]]]]]] -->
          <v-row class="hei-130p ma-0" >
            <v-col cols="12">
              <v-row class="hei-130p pa-0" align="center" v-show="windowWidth > 1220">
                <v-col cols="4" class="d-felx align-center">
                  <div class="pb-2">
                    
                  </div>
                </v-col>
                <v-col cols="4" class="d-felx align-center justify-center">
                  <div class="pb-2 justify-center d-flex">
                    <span class="fontSize-9 font-weight-bold">회원가입</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="pb-2 float-right">
                    <span>Home / 회원 / 회원가입</span>
                  </div>
                </v-col>
              </v-row>

              <!-- [[[[[[  mobile   ]]]]]] -->
              <v-row class="hei-130p ma-0" v-show="windowWidth < 1200 && windowWidth > 350">
                <v-col cols="12" class="pa-0 ma-0">
                  <v-row class="hei-130p pa-0 pl-3 ma-0 " align="center" dense>
                    <v-col cols="12" class="pa-0 ma-0">
                      <div class="">
                        <div class="d-flex justify-center">
                          <div class="">
                            <h1 class="menuWord">회원가입</h1>
                          </div>
                        </div>
                        <div class="pt-3 d-flex justify-center">
                          <span class="pa-0 menuWord">Home / 회원 / 회원가입</span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>

    <v-container fluid class="hei-90vh-auto d-flex bg-white" >
      <template>
        <v-container class="hei-80vh-auto d-flex"  style="width:1200px;" >
          <v-row class="ma-0" v-if="disp_yn">
            <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class="mt-3">
                <!-- <div class="wid-100  d-flex justify-center mb-5">
                  <div class="wid-200p hei-130p ">
                    <img class="wid-200p hei-130p image-default-size" :src="imgMap.join_top_logo_path">
                  </div>
                </div> -->
                <v-card class="hei-80vh-auto wid-100 bg-lightgrey-2 d-flex justify-center align-center pt-10 pb-10" outlined>
                  <div class="">
                    <v-row class="d-flex justify-center pl-3 pr-3">
                      <v-col cols="12" md="8" sm="8">
                        <!-----------------------[[[[[[[[[[[[            기본정보             ]]]]]]]]]]]]------------------------->
                        <v-container class="mb-10">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <h1>{{ mberSctnList.filter((e) => e.mber_sctn_id == 'mber_basic')[0].mber_sctn_nm }}</h1>
                            </v-col>
                          </v-row>
                          <v-row align="center" dense class="">
                            <!-- 기본 > 로그인ID -->
                            <v-col cols="12" class="mb-3" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'login_id').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'login_id')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'login_id').length > 0" style="color:red;"> *</span>
                              <div class="d-flex align-center">
                                <div class="wid-80">
                                  <v-text-field
                                    class="bg-white"
                                    height="40px"
                                    hide-details
                                    outlined
                                    v-model="basic.login_id"
                                    @change="loginIdChanged">
                                  </v-text-field>
                                </div>
                                <div class="wid-20 pl-1">
                                  <v-btn
                                    outlined
                                    class="wid-100 bg-indigo white--text hei-40p"
                                    hide-details
                                    @click="loginIdDupCheckClicked"
                                  >중복체크</v-btn>
                                </div>
                              </div>
                            </v-col>
                            <!-- 기본 > 비밀번호 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'pwd').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'pwd')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'pwd').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                append-icon="mdi-eye"
                                hide-details
                                outlined
                                type="password"
                                v-model="basic.pwd">
                              </v-text-field>
                            </v-col>
                            <!-- 기본 > 비밀번호 확인 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'pwd').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'pwd')[0].mber_item_nm }} 확인</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'pwd').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                append-icon="mdi-eye"
                                hide-details
                                outlined
                                type="password"
                                v-model="basic.pwdChk">
                              </v-text-field>
                            </v-col>
                            <!-- 기본 > 회원 구분 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'mber_div').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'mber_div')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'mber_div').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                height="40px"
                                hide-details
                                outlined
                                :label="joinType == 'grp' ? '단체' : '개인'"
                                readonly
                                background-color="grey lighten-2"
                                disabled
                                >
                              </v-text-field>
                            </v-col>
                            <!-- 기본 > 회원 종류 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'mber_kind').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'mber_kind')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'mber_kind').length > 0" style="color:red;"> *</span>
                              <v-select
                                class=""
                                background-color="white"
                                hide-details
                                outlined
                                v-model="basic.mber_kind"
                                :items="filteredMberKindList"
                                item-value="mber_kind"
                                item-text="mber_kind_nm"
                              ></v-select>
                            </v-col>
                            <!-- 기본 > 학회 가입일자 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'socty_join_dt').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'socty_join_dt')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'socty_join_dt').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                v-model="basic.socty_join_dt"
                                hide-details
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                @click:append="popupDatePicker('socty_join_dt')"
                                placeholder="0000-00-00"
                              ></v-text-field>
                              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                              <!-- <datePicker
                                p_dateName="socty_join_dt" 
                                ref="socty_join_dt"
                                v-model="basic.socty_join_dt"
                              ></datePicker> -->
                            </v-col>
                            <!-- 기본 > 추천인 이름 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'rcmdr_nm').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'rcmdr_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'rcmdr_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="basic.rcmdr_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 기본 > 추천인 소속 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'rcmdr_blng').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'rcmdr_blng')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'rcmdr_blng').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="basic.rcmdr_blng">
                              </v-text-field>
                            </v-col>
                            <!-- 기본 > 추천인 직위 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'rcmdr_pstn').length > 0">
                              <span class="font-weight-bold">{{ this.mberBasicList.filter((e) => e.mber_item_id == 'rcmdr_pstn')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'rcmdr_pstn').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="basic.rcmdr_pstn">
                              </v-text-field>
                            </v-col>
                            <!-- 이메일 수신 동의 -->
                            <v-col cols="12" class="" v-if="mberBasicList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'email_recv_agree_yn').length > 0">
                              <span class="font-weight-bold">{{ mberBasicList.filter((e) => e.mber_item_id == 'email_recv_agree_yn')[0].mber_item_nm }}</span>
                              <span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'email_recv_agree_yn').length > 0" style="color:red;"> *</span>
                              <v-checkbox
                                v-model="basic.email_recv_agree_yn"
                                label="동의함"
                                hide-details>
                              </v-checkbox>
                              <span class="caption">
                                학회, 학술대회 등 학회 소식을 메일로 보내드립니다.
                              </span>
                            </v-col>
                            
                            <!-- 연회비 면제 여부는 가입 화면에 표시 안함 -->
                            <!-- <v-col cols="12" class="">
                              <span class="font-weight-bold">연회비 면제 여부</span><span v-if="mberBasicList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'anul_mfee_exmpt_yn').length > 0" style="color:red;"> *</span>
                              <v-checkbox
                                hide-details
                                dense
                                v-model="basic.anul_mfee_exmpt_yn"
                                ></v-checkbox>
                            </v-col> -->
                          </v-row>
                        </v-container>



                          <!-----------------------[[[[[[[[[[[[            개인정보             ]]]]]]]]]]]]------------------------->
                        <v-container class="mt-10 mb-10" v-if="joinType == 'indv'">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <h1>{{ mberSctnList.filter((e) => e.mber_sctn_id == 'mber_indv')[0].mber_sctn_nm }}</h1>
                            </v-col>
                          </v-row>
                          <v-row align="center" dense class="">
                            <!-- 개인 > 이름(한글) -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'mber_nm').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'mber_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'mber_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.mber_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 이름(한자) -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'mber_nm_cn').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'mber_nm_cn')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'mber_nm_cn').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.mber_nm_cn">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 이름(영문 First) -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'mber_nm_en_frst').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'mber_nm_en_frst')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'mber_nm_en_frst').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.mber_nm_en_frst">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 이름(영문 Last) -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'mber_nm_en_last').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'mber_nm_en_last')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'mber_nm_en_last').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.mber_nm_en_last">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 이메일 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'email').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'email')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'email').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.email">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 이메일(투고시스템) -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'email_sbmt_sys').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'email_sbmt_sys')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'email_sbmt_sys').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.email_sbmt_sys">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 국적 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'natl').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'natl')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'natl').length > 0" style="color:red;"> *</span>
                              <v-select
                                class="" 
                                background-color="white"
                                hide-details
                                outlined
                                v-model="indv.natl"
                                :items="cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'natl')"
                                item-text="cmn_cd_nm"
                                item-value="cmn_cd"
                                ></v-select>
                            </v-col>
                            <!-- 개인 > 성별 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'gend').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'gend')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'gend').length > 0" style="color:red;"> *</span>
                              <v-radio-group
                                v-model="indv.gend"
                                row
                                hide-details
                                dense
                                class="ma-0 pa-0"
                              >
                                <v-radio
                                v-for="(item, index) in cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'gend')"
                                :key="`gend_`+index"
                                :label="item.cmn_cd_nm"
                                :value="item.cmn_cd"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <!-- 개인 > 생년월일 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'birth_dt').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'birth_dt')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'birth_dt').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                v-model="indv.birth_dt"
                                hide-details
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                @click:append="popupDatePicker('birth_dt')"
                                placeholder="0000-00-00"
                              ></v-text-field>
                              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                              <!-- <datePicker 
                                p_dateName="birth_dt" 
                                ref="birth_dt"
                                v-model="indv.birth_dt"
                              ></datePicker> -->
                            </v-col>
                            <!-- 개인 > 회원사진 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'photo_atch_sno').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'photo_atch_sno')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'photo_atch_sno').length > 0" style="color:red;"> *</span>
                              <v-file-input
                                ref="usrImg"
                                hide-details
                                dense
                                outlined
                                prepend-icon=""
                                append-icon="mdi-paperclip"
                                @click:append="usrImgClicked"
                                background-color="white"
                                height="40px"
                                v-model="indv.photo_atch_file"
                              ></v-file-input>
                            </v-col>
                            <!-- 개인 > 소속/학교 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'blng_nm').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'blng_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'blng_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.blng_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 부서/학과 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'dept_nm').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'dept_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'dept_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.dept_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 직위/과정 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'pstn_nm').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'pstn_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'pstn_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.pstn_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 휴대전화번호 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'cel_no').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'cel_no')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'cel_no').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.cel_no">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 소속기관유형 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'blng_org_type').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'blng_org_type')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'blng_org_type').length > 0" style="color:red;"> *</span>
                              <v-select
                                class="" 
                                background-color="white"
                                hide-details
                                outlined
                                v-model="indv.blng_org_type"
                                :items="cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'blng_org_type')"
                                item-text="cmn_cd_nm"
                                item-value="cmn_cd"
                                ></v-select>
                            </v-col>
                            <!-- 개인 > 전공분야 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'major_field').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'major_field')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'major_field').length > 0" style="color:red;"> *</span>
                              <div>
                                <v-checkbox 
                                  hide-details
                                  dense
                                  class="pr-6"
                                  style="float:left;"
                                  v-for="(a, i) in cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'major_field')"
                                  :label="a.cmn_cd_nm"
                                  :key="`major_`+i"
                                  v-model="majorFieldSelectedList"
                                  :value="a.cmn_cd"
                                ></v-checkbox>
                              </div>
                            </v-col>
                            <!-- 개인 > 직종 -->
                            <v-col cols="12" class="mb-2" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'job_kind').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'job_kind')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'job_kind').length > 0" style="color:red;"> *</span>
                              <v-select
                                class="f-l pa-0 ma-0 wid-100 input-hei-d" 
                                background-color="white"
                                hide-details
                                dense
                                outlined
                                v-model="indv.job_kind"
                                :items="cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'job_kind')"
                                item-text="cmn_cd_nm"
                                item-value="cmn_cd"
                                ></v-select>
                            </v-col>
                            <!-- 개인 > 소속위원회 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'blng_cmit').length > 0">
                              <span class="font-weight-bold">{{ this.mberIndvList.filter((e) => e.mber_item_id == 'blng_cmit')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'blng_cmit').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.blng_cmit">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 관심분야 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'inter_field').length > 0">
                              <span class="font-weight-bold">{{ mberIndvList.filter((e) => e.mber_item_id == 'inter_field')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'inter_field').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="indv.inter_field">
                              </v-text-field>
                            </v-col>
                            <!-- 개인 > 학술지 구독 여부 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'jrnl_subs_yn').length > 0">
                              <span class="font-weight-bold">{{ mberIndvList.filter((e) => e.mber_item_id == 'jrnl_subs_yn')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'jrnl_subs_yn').length > 0" style="color:red;"> *</span>
                              <v-checkbox
                                v-model="indv.jrnl_subs_yn"
                                label="구독"
                                hide-details>
                              </v-checkbox>
                            </v-col>
                            <!-- 개인 > 회원 자격 증빙 -->
                            <v-col cols="12" class="" v-if="mberIndvList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'crql_proof_atch_sno').length > 0">
                              <span class="font-weight-bold">{{ mberIndvList.filter((e) => e.mber_item_id == 'crql_proof_atch_sno')[0].mber_item_nm }}</span>
                              <span v-if="mberIndvList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'crql_proof_atch_sno').length > 0" style="color:red;"> *</span>
                              <v-file-input
                                hide-details
                                dense
                                outlined
                                prepend-icon=""
                                ref="crqlProofImg"
                                @click:append="crqlProofImgClicked"
                                append-icon="mdi-paperclip"
                                background-color="white"
                                height="40px"
                                v-model="indv.crql_proof_atch_file"
                              ></v-file-input>
                            </v-col>
                          </v-row>
                        </v-container>

                        
                        <!-----------------------[[[[[[[[[[[[            단체정보             ]]]]]]]]]]]]------------------------->
                        <v-container v-if="joinType == 'grp'" class="mb-10">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <h1>{{ mberSctnList.filter((e) => e.mber_sctn_id == 'mber_grp')[0].mber_sctn_nm }}</h1>
                            </v-col>
                          </v-row>
                          <v-row align="center" dense class="">
                            <!-- 단체 > 단체명 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'grp_nm').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'grp_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'grp_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.grp_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 단체명(영문) -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'grp_nm_en').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'grp_nm_en')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'grp_nm_en').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.grp_nm_en">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 대표자명 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'repr_nm').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'repr_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'repr_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.repr_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 소재지 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'ploc_cd').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'ploc_cd')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'ploc_cd').length > 0" style="color:red;"> *</span>
                              <v-select
                                class="" 
                                background-color="white"
                                hide-details
                                outlined
                                v-model="grp.ploc_cd"
                                :items="cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'ploc_cd')"
                                item-text="cmn_cd_nm"
                                item-value="cmn_cd"
                                ></v-select>
                            </v-col>
                            <!-- 단체 > 법인등록번호 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'corp_rno').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'corp_rno')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'corp_rno').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.corp_rno">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 사업자등록번호 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'biz_rno').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'biz_rno')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'biz_rno').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.biz_rno">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 홈페이지 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'homepage').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'homepage')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'homepage').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.homepage">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 우편번호 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'zpcd').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'zpcd')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'zpcd').length > 0" style="color:red;"> *</span>
                              <div class="d-flex align-center">
                                <div class="wid-80">
                                  <v-text-field
                                    class="bg-white"
                                    height="40px"
                                    hide-details
                                    outlined
                                    v-model="grp.zpcd">
                                  </v-text-field>
                                </div>
                                <div class="wid-20 pl-1">
                                  <v-btn
                                    outlined
                                    class="wid-100 bg-indigo white--text hei-40p"
                                    hide-details
                                    @click="findZipcodeBtnClicked('grp')"
                                  >주소찾기</v-btn>
                                </div>
                              </div>
                            </v-col>
                            <!-- 단체 > 주소 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'addr').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'addr')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'addr').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.addr">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 주소 상세 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'addr_dtl').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'addr_dtl')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'addr_dtl').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.addr_dtl">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 주소 참고항목 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'addr_ref').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'addr_ref')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'addr_ref').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.addr_ref">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 우편물 수령 여부 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'recv_postm_yn').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'recv_postm_yn')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'recv_postm_yn').length > 0" style="color:red;"> *</span>
                              <v-checkbox 
                                hide-details
                                dense
                                v-model="grp.recv_postm_yn"
                                label="수령"
                                ></v-checkbox>
                            </v-col>
                            <!-- 단체 > 담당자이름 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'chrgr_nm').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'chrgr_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'chrgr_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.chrgr_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 담당자영문이름(First) -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'chrgr_nm_en_frst').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'chrgr_nm_en_frst')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'chrgr_nm_en_frst').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.chrgr_nm_en_frst">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 담당자영문이름(Last) -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'chrgr_nm_en_last').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'chrgr_nm_en_last')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'chrgr_nm_en_last').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.chrgr_nm_en_last">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 담당자 부서 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'chrgr_dept_nm').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'chrgr_dept_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'chrgr_dept_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.chrgr_dept_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 담당자 직위 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'chrgr_pstn_nm').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'chrgr_pstn_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'chrgr_pstn_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.chrgr_pstn_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 담당자 이메일 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'chrgr_email').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'chrgr_email')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'chrgr_email').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.chrgr_email">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 담당자 전화번호 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'chrgr_tel_no').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'chrgr_tel_no')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'chrgr_tel_no').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.chrgr_tel_no">
                              </v-text-field>
                            </v-col>
                            <!-- 단체 > 담당자 팩스번호 -->
                            <v-col cols="12" class="" v-if="mberGrpList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'chrgr_fax_no').length > 0">
                              <span class="font-weight-bold">{{ mberGrpList.filter((e) => e.mber_item_id == 'chrgr_fax_no')[0].mber_item_nm }}</span>
                              <span v-if="mberGrpList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'chrgr_fax_no').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="grp.chrgr_fax_no">
                              </v-text-field>
                            </v-col>
                            </v-row>
                          </v-container>

                        <!-----------------------[[[[[[[[[[[[            우편물 수령 정보             ]]]]]]]]]]]]------------------------->
                        <v-container class="mb-10" v-if="joinType == 'indv' && mberAddrList.filter((e) => e.join_colct_yn == 'Y').length > 0">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <h1>{{ mberSctnList.filter((e) => e.mber_sctn_id == 'mber_addr')[0].mber_sctn_nm }}</h1>
                            </v-col>
                          </v-row>
                          <v-row align="center" dense class="" v-for="(item, idx) in addrList" :key="`addrList`+idx">
                            <!-- 주소 > 우편번호 -->
                            <v-col cols="12">
                              <h2 style="float:left;">주소 {{idx+1}}</h2>
                              <v-btn
                                style="float:right;"
                                v-if="idx > 0"
                                @click="addrDelBtnClicked(item)"
                              >
                              삭제
                              </v-btn>
                            </v-col>
                            <v-col cols="12" class="" v-if="mberAddrList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'addr_type').length > 0">
                              <span class="font-weight-bold">{{ mberAddrList.filter((e) => e.mber_item_id == 'addr_type')[0].mber_item_nm }}</span>
                              <span v-if="mberAddrList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'addr_type').length > 0" style="color:red;"> *</span>
                              <v-select
                                class=""
                                background-color="white"
                                hide-details
                                outlined
                                v-model="item.addr_type"
                                :items="cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'addr_type')"
                                item-text="cmn_cd_nm"
                                item-value="cmn_cd"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" class="" v-if="mberAddrList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'zpcd').length > 0">
                              <span class="font-weight-bold">{{ mberAddrList.filter((e) => e.mber_item_id == 'zpcd')[0].mber_item_nm }}</span>
                              <span v-if="mberAddrList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'zpcd').length > 0" style="color:red;"> *</span>
                              <div class="d-flex align-center">
                                <div class="wid-80">
                                  <v-text-field
                                    class="bg-white"
                                    height="40px"
                                    hide-details
                                    outlined
                                    v-model="item.zpcd">
                                  </v-text-field>
                                </div>
                                <div class="wid-20 pl-1">
                                  <v-btn
                                    outlined
                                    class="wid-100 bg-indigo white--text hei-40p"
                                    hide-details
                                    @click="findZipcodeBtnClicked('addr', item.odr)"
                                  >주소찾기</v-btn>
                                </div>
                              </div>
                            </v-col>
                            <!-- 주소 > 주소 -->
                            <v-col cols="12" class="" v-if="mberAddrList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'addr').length > 0">
                              <span class="font-weight-bold">{{ mberAddrList.filter((e) => e.mber_item_id == 'addr')[0].mber_item_nm }}</span>
                              <span v-if="mberAddrList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'addr').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.addr">
                              </v-text-field>
                            </v-col>
                            <!-- 주소 > 주소 상세 -->
                            <v-col cols="12" class="" v-if="mberAddrList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'addr_dtl').length > 0">
                              <span class="font-weight-bold">{{ mberAddrList.filter((e) => e.mber_item_id == 'addr_dtl')[0].mber_item_nm }}</span>
                              <span v-if="mberAddrList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'addr_dtl').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.addr_dtl">
                              </v-text-field>
                            </v-col>
                            <!-- 주소 > 주소 참고항목 -->
                            <v-col cols="12" class="" v-if="mberAddrList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'addr_ref').length > 0">
                              <span class="font-weight-bold">{{ mberAddrList.filter((e) => e.mber_item_id == 'addr_ref')[0].mber_item_nm }}</span>
                              <span v-if="mberAddrList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'addr_ref').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.addr_ref">
                              </v-text-field>
                            </v-col>
                            <!-- 주소 > 우편물 수령 여부 -->
                            <v-col cols="12" class="" v-if="mberAddrList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'recv_postm_yn').length > 0">
                              <span class="font-weight-bold">{{ mberAddrList.filter((e) => e.mber_item_id == 'recv_postm_yn')[0].mber_item_nm }}</span>
                              <span v-if="mberAddrList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'recv_postm_yn').length > 0" style="color:red;"> *</span>
                              <v-checkbox 
                                hide-details
                                dense
                                class="text-black"
                                label="수령"
                                v-model="item.recv_postm_yn"
                                ></v-checkbox>
                            </v-col>
                            <!-- 주소 > 전화번호 -->
                            <v-col cols="12" class="" v-if="mberAddrList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'tel_no').length > 0">
                              <span class="font-weight-bold">{{ mberAddrList.filter((e) => e.mber_item_id == 'tel_no')[0].mber_item_nm }}</span>
                              <span v-if="mberAddrList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'tel_no').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.tel_no">
                              </v-text-field>
                            </v-col>
                            <v-col cols=3>
                              <v-btn
                                block
                                tile
                                outlined
                                height="40px"
                                class="theme-color-1 white--text"
                                v-if="addrList.length == idx+1"
                                @click="addrAddBtnClicked"
                              >추가</v-btn>
                            </v-col>
                          </v-row>
                        </v-container>

                        <!-----------------------[[[[[[[[[[[[           학력 정보             ]]]]]]]]]]]]------------------------->
                        <v-container v-if="joinType == 'indv' && mberEdubgList.filter((e) => e.join_colct_yn == 'Y').length > 0">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <h1>{{ mberSctnList.filter((e) => e.mber_sctn_id == 'mber_edubg')[0].mber_sctn_nm }}</h1>
                            </v-col>
                          </v-row>
                          <v-row align="center" dense v-if="this.siteOptn.ba_dgree_ess_yn == 'Y'">
                            <h3 style="color:red;">학사 학위 정보는 필수입니다.</h3>
                          </v-row>
                          <v-row align="center" dense class="" v-for="(item, idx) in edubgList" :key="`edubg_`+idx">
                            <!-- 학력 > 학위 구분 -->
                            <v-col cols="12">
                              <h2 style="float:left;">학력 {{idx+1}}</h2>
                              <v-btn
                                style="float:right;"
                                v-if="idx > 0"
                                @click="edubgDelBtnClicked(item)"
                              >
                              삭제
                              </v-btn>
                            </v-col>
                            <v-col cols="12" class="" v-if="mberEdubgList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'dgree_div').length > 0">
                              <span class="font-weight-bold">{{ mberEdubgList.filter((e) => e.mber_item_id == 'dgree_div')[0].mber_item_nm }}</span>
                              <span v-if="mberEdubgList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'dgree_div').length > 0" style="color:red;"> *</span>
                              <v-select
                                class=""
                                background-color="white"
                                hide-details
                                outlined
                                v-model="item.dgree_div"
                                :items="cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'dgree_div')"
                                item-text="cmn_cd_nm"
                                item-value="cmn_cd"
                              ></v-select>
                            </v-col>
                            <!-- 학력 > 학교명 -->
                            <v-col cols="12" class="" v-if="mberEdubgList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'schl_nm').length > 0">
                              <span class="font-weight-bold">{{ mberEdubgList.filter((e) => e.mber_item_id == 'schl_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberEdubgList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'schl_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.schl_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 학력 > 학교명(영문) -->
                            <v-col cols="12" class="" v-if="mberEdubgList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'schl_nm_en').length > 0">
                              <span class="font-weight-bold">{{ mberEdubgList.filter((e) => e.mber_item_id == 'schl_nm_en')[0].mber_item_nm }}</span>
                              <span v-if="mberEdubgList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'schl_nm_en').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.schl_nm_en">
                              </v-text-field>
                            </v-col>
                            <!-- 학력 > 전공명 -->
                            <v-col cols="12" class="" v-if="mberEdubgList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'major_nm').length > 0">
                              <span class="font-weight-bold">{{ mberEdubgList.filter((e) => e.mber_item_id == 'major_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberEdubgList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'major_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.major_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 학력 > 전공명(영문) -->
                            <v-col cols="12" class="" v-if="mberEdubgList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'major_nm_en').length > 0">
                              <span class="font-weight-bold">{{ mberEdubgList.filter((e) => e.mber_item_id == 'major_nm_en')[0].mber_item_nm }}</span>
                              <span v-if="mberEdubgList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'major_nm_en').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.major_nm_en">
                              </v-text-field>
                            </v-col>
                            <!-- 학력 > 졸업년도 -->
                            <v-col cols="12" class="" v-if="mberEdubgList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'grdtn_year').length > 0">
                              <span class="font-weight-bold">{{ mberEdubgList.filter((e) => e.mber_item_id == 'grdtn_year')[0].mber_item_nm }}</span>
                              <span v-if="mberEdubgList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'grdtn_year').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.grdtn_year">
                              </v-text-field>
                            </v-col>
                            <v-col cols=3>
                              <v-btn
                                block
                                tile
                                outlined
                                height="40px"
                                class="theme-color-1 white--text"
                                v-if="edubgList.length == idx+1"
                                @click="edubgAddBtnClicked"
                              >추가</v-btn>
                            </v-col>
                          </v-row>
                        </v-container>

                        <!-----------------------[[[[[[[[[[[[           자격증 정보             ]]]]]]]]]]]]------------------------->
                        <v-container v-if="joinType == 'indv' && mberCrqlList.filter((e) => e.join_colct_yn == 'Y').length > 0">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <h1>{{ mberSctnList.filter((e) => e.mber_sctn_id == 'mber_crql')[0].mber_sctn_nm }}</h1>
                            </v-col>
                          </v-row>
                          <v-row align="center" dense class="" v-for="(item, idx) in crqlList" :key="`crql_`+idx">
                            <!-- 자격증 > 자격증종류 -->
                            <v-col cols="12">
                              <h2 style="float:left;">자격증 {{idx+1}}</h2>
                              <v-btn
                                style="float:right;"
                                v-if="idx > 0"
                                @click="crqlDelBtnClicked(item)"
                              >
                              삭제
                              </v-btn>
                            </v-col>
                            <v-col cols="12" class="" v-if="mberCrqlList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'crql_kind').length > 0">
                              <span class="font-weight-bold">{{ mberCrqlList.filter((e) => e.mber_item_id == 'crql_kind')[0].mber_item_nm }}</span>
                              <span v-if="mberCrqlList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'crql_kind').length > 0" style="color:red;"> *</span>
                              <v-select
                                class=""
                                background-color="white"
                                hide-details
                                outlined
                                v-model="item.crql_kind"
                                :items="cmnCdList.filter((e) => e.cmn_cd != '00' && e.cls_cd == 'crql_kind')"
                                item-text="cmn_cd_nm"
                                item-value="cmn_cd"
                              ></v-select>
                            </v-col>
                            <!-- 자격증 > 자격증명(한글)-->
                            <v-col cols="12" class="" v-if="mberCrqlList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'crql_nm').length > 0">
                              <span class="font-weight-bold">{{ mberCrqlList.filter((e) => e.mber_item_id == 'crql_nm')[0].mber_item_nm }}</span>
                              <span v-if="mberCrqlList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'crql_nm').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.crql_nm">
                              </v-text-field>
                            </v-col>
                            <!-- 자격증 > 자격증명(영문) -->
                            <v-col cols="12" class="" v-if="mberCrqlList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'crql_nm_en').length > 0">
                              <span class="font-weight-bold">{{ mberCrqlList.filter((e) => e.mber_item_id == 'crql_nm_en')[0].mber_item_nm }}</span>
                              <span v-if="mberCrqlList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'crql_nm_en').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.crql_nm_en">
                              </v-text-field>
                            </v-col>
                            <!-- 자격증 > 발행처 -->
                            <v-col cols="12" class="" v-if="mberCrqlList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'issu_org').length > 0">
                              <span class="font-weight-bold">{{ mberCrqlList.filter((e) => e.mber_item_id == 'issu_org')[0].mber_item_nm }}</span>
                              <span v-if="mberCrqlList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'issu_org').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                height="40px"
                                hide-details
                                outlined
                                v-model="item.issu_org">
                              </v-text-field>
                            </v-col>
                            <!-- 자격증 > 발행일자 -->
                            <v-col cols="12" class="" v-if="mberCrqlList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'issu_dt').length > 0">
                              <span class="font-weight-bold">{{ mberCrqlList.filter((e) => e.mber_item_id == 'issu_dt')[0].mber_item_nm }}</span>
                              <span v-if="mberCrqlList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'issu_dt').length > 0" style="color:red;"> *</span>
                              <v-text-field
                                class="bg-white"
                                v-model="item.issu_dt"
                                hide-details
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                @click:append="popupDatePickerItem(item,'issu_dt')"
                                placeholder="0000-00-00"
                              ></v-text-field>
                              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                              <!-- <datePicker
                                p_dateName="issu_dt" 
                                ref="issu_dt"
                                v-model="item.issu_dt"
                              ></datePicker> -->
                            </v-col>
                            <!-- 자격증 > 증빙 첨부 -->
                            <v-col cols="12" class="" v-if="mberCrqlList.filter((e) => e.join_colct_yn == 'Y' && e.mber_item_id == 'crql_proof_atch_sno').length > 0">
                              <span class="font-weight-bold">{{ mberCrqlList.filter((e) => e.mber_item_id == 'crql_proof_atch_sno')[0].mber_item_nm }}</span>
                              <span v-if="mberCrqlList.filter((e) => e.ess_yn == 'Y' && e.mber_item_id == 'crql_proof_atch_sno').length > 0" style="color:red;"> *</span>
                              <v-file-input
                                ref="crqlImg"
                                hide-details
                                dense
                                outlined
                                prepend-icon=""
                                append-icon="mdi-paperclip"
                                @click:append="crqlImgClicked(idx)"
                                background-color="white"
                                height="40px"
                                v-model="item.file"
                                accept="image/*"
                              ></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="item.orgnl_file_nm" :key="`crql_`+photoRedraw">
                              <div class="wid-100 pt-0">
                                <div class="f-l pt-2 pl-5 font-1" style="color:white;">
                                  <span><a :href="item.file_path" target="_blank">{{item.orgnl_file_nm}}</a></span>
                                </div>
                                <v-btn
                                  small
                                  color="red lighten-2"
                                  class="pa-0 ma-0 white--text f-r"
                                  hide-
                                  @click="crqlImgDelBtnClicked(item)"
                                >삭제</v-btn>
                              </div>
                            </v-col>
                            <v-col cols="3">
                              <v-btn
                                block
                                tile
                                outlined
                                height="40px"
                                class="theme-color-1 white--text"
                                v-if="crqlList.length == idx+1"
                                @click="crqlAddBtnClicked"
                              >추가</v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                          
                        <v-container>
                          <v-row class="mt-10">
                            <v-col cols="12" class="mt-3 mb-5 border-b-darkgrey"></v-col>

                            <!-- <v-col cols="12" class="">
                              <v-checkbox
                                v-model="basic.email_recv_agree_yn"
                                label="이메일수신 동의"
                                hide-details>
                              </v-checkbox>
                            </v-col>
                            <v-col cols="12" class="mb-5">
                              <span class="caption">
                                학회, 학술대회 등 학회 소식을 메일로 보내드립니다.
                              </span>
                            </v-col>
                            <v-col cols="12" class="mb-10 border-b-darkgrey"></v-col> -->

                            <v-col cols="12" class="bg-grey mb-5 pt-2 pb-3 pl-1 pr-1">
                              <v-row dense>
                                <!-- <v-col cols="12">
                                  <v-checkbox
                                    label="전체 동의 합니다."
                                    hide-details>
                                  </v-checkbox>
                                </v-col> -->
                                <v-col cols="12" class="d-flex">
                                  <v-checkbox
                                    v-model="basic.terms_agree_yn"
                                    label="학회 이용약관 동의(필수)"
                                    hide-details>
                                  </v-checkbox>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    tile
                                    outlined
                                    small
                                    @click="termsPrivacyOn('terms')"
                                    class="bg-white ">
                                    내용보기
                                  </v-btn>
                                </v-col>
                                <v-col cols="12" class="d-flex">
                                  <v-checkbox
                                    label="개인정보 수집 및 이용 동의(필수)"
                                    v-model="basic.prvcy_polcy_agree_yn"
                                    hide-details>
                                  </v-checkbox>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    tile
                                    outlined
                                    small
                                    @click="termsPrivacyOn('privacy')"
                                    class="bg-white ">
                                    내용보기
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="hei-40p mb-3 pa-0">
                              <v-btn
                                block
                                tile
                                outlined
                                height="40px"
                                class="theme-color-1 white--text"
                                @click="joinBtnClicked">
                                가입하기
                              </v-btn>
                              <div v-if="isLoading" class="loading-container">
                                <div class="spinner-loading">
                                  <Fade-loader />
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>


      <template>
        <div class="text-center">
          <v-dialog
            v-model="termsPrivacyDialog"
            width="700"
          > 
            <!-- [[[[[[[[[    학회 이용약관    ]]]]]]]]] -->
            <v-card v-if="this.termsPrivacyTypeCode == 'terms'">
              <v-card-title class="text-h5 grey lighten-2">
                <div class="font-weight-bold" >학회 이용약관</div>
              </v-card-title>
              
              <v-card-text class="hei-600p wid-100 pt-3" v-if="joinType == 'indv'">
                <div class="pl-5 pr-5" style="overflow:auto; height:580px;">
                  <div class="tiny" v-html="this.terms_join_indv"></div>
                </div>
              </v-card-text>
              
              <v-card-text class="hei-600p wid-100 pt-3" v-if="joinType == 'grp'">
                <div class="pl-5 pr-5" style="overflow:auto; height:580px;">
                  <div class="tiny" v-html="this.terms_join_grp"></div>
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="termsDialogAccept('terms')"
                >
                  동의 (필수)
                </v-btn>
              </v-card-actions>
            </v-card>

            <!-- [[[[[[[[[    개인정보 수집 및 이용 동의    ]]]]]]]]] -->
            <v-card v-if="this.termsPrivacyTypeCode == 'privacy'">
              <v-card-title class="text-h5 grey lighten-2">
                <div class="font-weight-bold" >개인정보 수집 및 이용 동의</div>
              </v-card-title>
              
              <v-card-text class="hei-600p wid-100 pt-3" v-if="joinType == 'indv'">
                <div class="pl-5 pr-5" style="overflow:auto; height:580px;">
                  <div class="tiny" v-html="this.prvcy_polcy_join_indv"></div>
                </div>
              </v-card-text>
              
              <v-card-text class="hei-600p wid-100 pt-3" v-if="joinType == 'grp'">
                <div class="pl-5 pr-5" style="overflow:auto; height:580px;">
                  <div class="tiny" v-html="this.prvcy_polcy_join_grp"></div>
                </div>
              </v-card-text>
              

              <!-- <v-card-text class="hei-100p">
                <div class="" v-html="this.prvcy_polcy_join_indv"></div>
              </v-card-text> -->

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="termsDialogAccept('privacy')"
                >
                  동의 (필수)
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>
        </div>
      </template>
    </v-container>


   <main-footer/>
   </v-app>
</template>

<script>
import mainFooter from '@/components/main/footer/footer';
import headerA from '@/components/main/header/headerA.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';
// import datePicker from "@/components/common/datePicker.vue";
import datePickerPopup from '@/components/common/datePickerPopup.vue';

export default {
  components: {datePickerPopup, FadeLoader, headerA, mainFooter, },
  data() {
    return {
      windowWidth: window.innerWidth,
      isLoading: false,
      //기본
      basic: {
        login_id: '',                    //로그인 아이디
        pwd: '',                        //비밀번호
        pwdChk: '',                     //비밀번호 확인
        socty_join_dt: '',              //학회가입일자
        rcmdr_nm: '',                   //추천인 이름
        rcmdr_blng: '',                 //추천인 소속
        rcmdr_pstn: '',                 //추천인 직위
        mber_kind:'',
        mber_div:'',
        // anul_mfee_exmpt_yn: '',         //연회비면제여부
        terms_agree_yn: false,                 //이용약관 동의
        prvcy_polcy_agree_yn: false,           //개인정보처리방침 동의
        email_recv_agree_yn: false,           //이메일수신 동의
      },
      
      //개인
      indv:{
        mber_nm: '',                    //이름(한글)
        mber_nm_cn: '',                 //이름(한자)
        mber_nm_en_frst: '',            //영문이름(First)
        mber_nm_en_last: '',            //영문이름(Last)
        email: '',                      //이메일
        email_sbmt_sys: '',             //이메일(논문투고심사시스템)
        natl: '',                       //국적
        gend: '',                       //성별
        birth_dt: '',                   //생년월일
        photo_atch_sno: '',             //회원사진
        photo_atch_file: null,          //회원사진
        blng_nm: '',                    //소속,학교
        dept_nm: '',                    //부서,학과
        pstn_nm: '',                    //직위,과정
        cel_no: '',                     //휴대전화번호
        blng_org_type: '',              //소속기관유형
        major_field: '',                //전공분야
        job_kind: '',                   //직종
        blng_cmit: '',                  //소속위원회
        inter_field: '',                //관심분야
        jrnl_subs_yn: '',               //학술지구독여부
        crql_proof_atch_sno: '',      //회원자격증빙
        crql_proof_atch_file: null,     //회원자격증빙
      },
      //단체
      grp: {
        // natl: '',                       //국적
        grp_nm: '',                     //단체명
        grp_nm_en: '',                  //단체명(영문)
        repr_nm: '',                    //대표자명
        ploc_cd: '',                    //소재지
        corp_rno: '',                   //법인등록번호
        biz_rno: '',                    //사업자등록번호
        homepage: '',                   //홈페이지
        zpcd: '',                       //우편번호
        addr: '',                       //주소
        addr_dtl: '',                   //주소상세
        addr_ref: '',                   //주소참고항목
        recv_postm_yn: '',              //우편물수령여부
        chrgr_nm: '',                   //담당자이름
        chrgr_nm_en_frst: '',           //담당자영문이름(First)
        chrgr_nm_en_last: '',           //담당자영문이름(Last)
        chrgr_dept_nm: '',              //담당자부서
        chrgr_pstn_nm: '',              //담당자직위
        chrgr_email: '',                //담당자전자우편주소
        chrgr_tel_no: '',               //담당자전화번호
        chrgr_fax_no: '',               //담당자팩스번호
      },
      //주소
      addr: {
        zpcd: '',                       //우편번호
        addr: '',                       //주소
        addr_dtl: '',                   //주소상세
        addr_ref: '',                   //주소참고항목
        recv_postm_yn: '',              //우편물수령여부
        addr_type:'',
      },
      edubg:{
        dgree_div: '',                  //학위
        schl_nm: '',                    //출신학교
        schl_nm_en: '',                 //출신학교(영문)
        major_nm: '',                   //전공
        major_nm_en: '',                //전공(영문)
        grdtn_year: '',                 //졸업년도
      },
      crql: {
        crql_sno: '',
        odr: '',
        crql_kind: '',
        crql_nm: '',
        crql_nm_en: '',
        issu_org: '',
        issu_dt: '',
        crql_proof_atch_sno: '',
        file_path: '',
        orgnl_file_nm: ''
      },
      siteOptn: {},

      joinType: this.$route.query.joinType,   //개인,단체 종류
      cmnCdList: [],                      //공통코드 리스트
      
      mberSctnList: [],                  // 섹션 리스트
      mberBasicList: [],                  //기본항목 리스트
      mberIndvList: [],                   //개인항목 리스트
      mberGrpList: [],                    //단체항목 리스트
      mberAddrList: [],                   //주소항목 리스트
      mberEdubgList: [],                  //학력항목 리스트
      mberKindList: [],                   //회원종류 리스트
      filteredMberKindList: [],     // 필터된 회원종류 리스트
      majorFieldSelectedList: [],
      fileUploadList: null,
      isValid: true,
      loginIdDupYn: false,
      acct_type: '',
      
      termsPrivacyDialog: false,
      termsPrivacyTypeCode: '',
      terms_join_indv:'',
      terms_join_indv_en:'',
      terms_join_grp:'',
      terms_join_grp_en:'',
      prvcy_polcy_join_indv:'',
      prvcy_polcy_join_indv_en:'',
      prvcy_polcy_join_grp:'',
      prvcy_polcy_join_grp_en:'',
      imgMap: {},
      addrList: [
        {addr_sno: '', addr_type:'', zpcd:'', addr:'', addr_dtl:'', addr_ref:'',recv_postm_yn:'', tel_no:'', odr: 1},
      ],
      disp_yn: false,
      edubgList: [
        {edubg_sno: '', dgree_div:'', schl_nm:'', schl_nm_en:'', major_nm:'', major_nm_en:'',grdtn_year:'', last_dgree_yn:'', odr: 1},
      ],
      mberCrqlList: [],
      crqlList: [
        {crql_sno: '',odr: 1, crql_kind: '', crql_nm: '', crql_nm_en: '', issu_org: '', issu_dt: '', crql_proof_atch_sno: '', orgnl_file_nm: '', file_path: '', file: null }
      ],
      datePopup: false,
      curDate: "",
      popItem: '',
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  computed: {
    // mberBasicLoginIdTtl() {
    //   console.log("mberBasicLoginIdTtl : ", this.mberBasicList.filter((e) => e.mber_item_id == 'login_id'));
    //   console.log("mberBasicLoginIdTtl : ", this.mberBasicList.filter((e) => e.mber_item_id == 'login_id')[0].mber_item_nm);
    //   return this.mberBasicList.filter((e) => e.mber_item_id == 'login_id')[0].mber_item_nm;
    // }
  },
  created() {
    if(!this.joinType) {
      alert('잘못된 접근입니다.');
      this.$router.push("/login");
    }


    this.$http.get("/mber/getJoinData").then((res) => {
      this.cmnCdList = res.data.cmnCdList;
      this.mberSctnList = res.data.mberSctnList;
      this.mberBasicList = res.data.mberBasicList;
      this.mberIndvList = res.data.mberIndvList;
      this.mberGrpList = res.data.mberGrpList;
      this.mberAddrList = res.data.mberAddrList;
      this.mberEdubgList = res.data.mberEdubgList;
      this.mberCrqlList = res.data.mberCrqlList;

      // this.acct_type = res.data.acct_type;
      this.imgMap = res.data.imgMap;

      this.siteOptn = res.data.siteOptn;
      this.acct_type = res.data.siteOptn.acct_type;
      // console.log("mberAddrList List ::::::::::::::::::: " ,res.data.mberAddrList);

      // console.log("this.mberBasicList::: ",this.mberBasicList);
      this.mberBasicList = this.mberBasicList.filter((e) => {
        if(this.basic[e.mber_item_id] != undefined) {
          return e;
        }
      })

      this.mberIndvList = this.mberIndvList.filter((e) => {
        if(this.indv[e.mber_item_id] != undefined) {
          return e;
        }
      })

      this.mberGrpList = this.mberGrpList.filter((e) => {
        if(this.grp[e.mber_item_id] != undefined) {
          return e;
        }
      })
      this.mberAddrList = this.mberAddrList.filter((e) => {
        if(this.addr[e.mber_item_id] != undefined) {
          return e;
        }
      })
      this.mberEdubgList = this.mberEdubgList.filter((e) => {
        if(this.edubg[e.mber_item_id] != undefined) {
          return e;
        }
      })

      this.mberCrqlList = this.mberCrqlList.filter((e) => {
        if(this.crql[e.mber_item_id] != undefined) {
          return e;
        }
      })

      this.disp_yn = true;
    })
    .catch(function (error) {
        console.log(error);
      }
    );
    //이용약관,개인정보취급
    this.getSoctyJoinPolcy();
    //회원종류
    this.getMberKindList();
  },
  methods: {
    joinBtnClicked() {
      this.validationCheck();
      if(this.isValid) {

        this.majorFieldSelectedList.forEach((e, idx) => {
          if(idx == 0) {
            this.indv.major_field = e;
          } else {
            this.indv.major_field += ',' + e;
          }
        });
        
        const formData = new FormData();
        this.basic.mber_div = this.joinType;
        formData.append("basic", JSON.stringify(this.basic));
        
        if(this.joinType == 'indv') {
          formData.append("indv", JSON.stringify(this.indv));
          formData.append("photo_atch_file", this.indv.photo_atch_file);
          formData.append("crql_proof_atch_file", this.indv.crql_proof_atch_file);
        } else {
          formData.append("grp", JSON.stringify(this.grp));
        }
        
        formData.append("addrList", JSON.stringify(this.addrList));
        formData.append("edubgList", JSON.stringify(this.edubgList));

        this.crqlList.forEach((e) => {
          if(e.file != null) {
            formData.append("crql_atch_file", e.file);
            e.fileYn = 'Y';
          }
        })

        formData.append("crqlList", JSON.stringify(this.crqlList));

        this.isLoading = true;
        this.$http.post("/mber/join", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
        }).then((res) => {
          console.log(res.data);
          if(res.data.code == 0) {
            this.isLoading = false;
            alert('가입 완료되었습니다.');
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },//joinBtnClicked end
    validationCheck() {
        this.isValid = true;
        if(!this.loginIdDupYn) {
            alert('로그인ID 중복체크 해주세요.');
            this.isValid = false;
            return;
        }

        this.mberBasicList.some((e) => {
            if(e.ess_yn == 'Y' && !this.basic[e.mber_item_id] && e.mber_item_id != 'terms_agree_yn' && e.mber_item_id != 'prvcy_polcy_agree_yn') {
                alert('필수값 입력되지 않았음. (' + e.mber_item_nm + ")");
                this.isValid = false;
                return true;
            }
        })

        if(this.isValid) {
            if(this.joinType == 'indv') {
                this.mberIndvList.some((e) => {
                    if(e.ess_yn == 'Y' && !this.indv[e.mber_item_id] ) {
                        alert('필수값 입력되지 않았음. (' + e.mber_item_nm + ")");
                        this.isValid = false;
                        return true;
                    }
                })
            } else {
                this.mberGrpList.some((e) => {
                    if(e.ess_yn == 'Y' && !this.grp[e.mber_item_id] ) {
                        alert('필수값 입력되지 않았음. (' + e.mber_item_nm + ")");
                        this.isValid = false;
                        return true;
                    }
                })
            }
            if(!this.isValid) return;
        }

        // 출신학교(학사) 필수 수집인 경우 학위 구분(dgree_div)이 학사(bachl)인 학력이 필수임
        // if(this.siteOptn.ba_dgree_ess_yn == 'Y') {

        // }

        if(this.isValid) {
            if(this.basic.pwd != this.basic.pwdChk) {
                alert('비밀번호와 비밀번호 확인 값이 일치하지 않습니다.');
                this.isValid = false;
                return;
            }
        }

        if(this.isValid && this.joinType == 'indv') {
        
          this.mberAddrList.filter((e) => e.join_colct_yn == 'Y').some((e) => {
            if(e.ess_yn == 'Y') {
              this.addrList.some((f) => {
                if(!f[e.mber_item_id]) {
                  alert('필수값 입력되지 않았음. (' + e.mber_item_nm + ")");
                  this.isValid = false;
                  return true;
                }
              })
              if(!this.isValid) {
                return true;
              }
            }
          })

          if(this.isValid) {
            this.mberEdubgList.some((e) => {
              if(e.ess_yn == 'Y') {
                this.edubgList.some((f) => {
                  if(!f[e.mber_item_id]) {
                    alert('필수값 입력되지 않았음. (' + e.mber_item_nm + ")");
                    this.isValid = false;
                    return true;
                  }
                })
                if(!this.isValid) {
                  return true;
                }
              }
            })
          }
          
          if(this.isValid) {
            if(this.siteOptn.ba_dgree_ess_yn == 'Y' && this.edubgList.filter((e) => e.dgree_div == 'bachl').length == 0) {
            
              alert('학사 학위 정보는 필수입니다.');
              this.isValid = false;
              return;

            }
          }

          if(this.isValid) {
            this.mberCrqlList.some((e) => {
              if(e.ess_yn == 'Y') {
                this.crqlList.some((f) => {
                  if(e.mber_item_id == "crql_proof_atch_sno") {
                    // console.log("자격증빙 첨부 검사");
                    if(!f['file']) {
                      alert('필수값 입력되지 않았음. (' + e.mber_item_nm + ")");
                      this.isValid = false;
                      return true;
                    }
                  } else {
                    if(!f[e.mber_item_id]) {
                      alert('필수값 입력되지 않았음. (' + e.mber_item_nm + ")");
                      this.isValid = false;
                      return true;
                    }
                  }
                })
                if(!this.isValid) {
                  return true;
                }
              }
            })
          }
          
        }


        if(this.isValid) {
            if(this.basic.terms_agree_yn == null || this.basic.terms_agree_yn == false) {
                alert('학회 이용약관에 동의해주세요.');
                this.isValid = false;
                return;
            }
        }

        if(this.isValid) {
            if(this.basic.prvcy_polcy_agree_yn == null || this.basic.prvcy_polcy_agree_yn == false) {
                alert('개인정보 수집 및 이용에 동의해주세요.');
                this.isValid = false;
                return;
            }
        }

        // if(this.isValid) {
        //     if(this.basic.email_recv_agree_yn == null || this.basic.email_recv_agree_yn == false) {
        //         alert('이메일 수신에 동의해주세요.');
        //         this.isValid = false;
        //         return;
        //     }
        // }


    },//validationCheck end
    findZipcodeBtnClicked(zipCode , odr) {
      new window.daum.Postcode({
        oncomplete: (data) => {
          if(zipCode=="grp"){
            this.grp.zpcd = data.zonecode;
            this.grp.addr = data.roadAddress;
          }else if(zipCode=="addr"){
            if(odr) {
              const list = this.addrList.filter((e) => e.odr == odr)[0];
              list.zpcd = data.zonecode;
              list.addr = data.roadAddress;
            }
          }
          
        },
      }).open();

    },//findZipcodeBtnClicked end
    loginIdDupCheckClicked() {
      if(!this.basic.login_id ||  this.basic.login_id.trim() == '') {
        alert('로그인 아이디 입력해주세요.');
        return;
      }

      if(this.acct_type == "email"){
        if(this.basic.login_id){
          if(!this.$validateEmail(this.basic.login_id)){
            alert("이메일 형식이 올바르지 않습니다.");
            return;
          }
        }
      }else if(this.acct_type == "id"){
        if(this.basic.login_id){
          if(this.$validateId(this.basic.login_id)){
            alert('아이디는 영문 대소문자와 숫자만 입력 가능합니다.')
            return;
          }
        }
      }
      
      const formData = new FormData();
            
      formData.append("login_id", this.basic.login_id);
      this.$http.post("/common/getLoginIdDupChk", formData).then( (res) => {
        if(res.data.code < 0)
        {
          alert(this.$erroCdMsg(res.data.code));
          return;
        }else
        {
          alert('사용가능합니다.');
        }
        this.loginIdDupYn = true;
      }).catch(
        function(error) {
          console.log(error);
        }.bind(this)
      );	
    },//loginIdDupCheckClicked end
    loginIdChanged() {
      this.loginIdDupYn = false;
    },//loginIdChanged end

    getSoctyJoinPolcy() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyJoinPolcy", params).then((res) => {
        console.log("res.data: ", res.data.result.length);
        // if(res.data.result.length == 0) {
        //   this.isNew = true;
        //   // alert("조회된 정보가 없습니다.");
        //   return;
        // } else {
        //   this.isNew = false;
        // }

        const termsPolicy = res.data.result[0];
        this.terms_join_indv = termsPolicy.terms_join_indv;
        this.terms_join_indv_en = termsPolicy.terms_join_indv_en;
        this.terms_join_grp = termsPolicy.terms_join_grp;
        this.terms_join_grp_en = termsPolicy.terms_join_grp_en;
        this.prvcy_polcy_join_indv = termsPolicy.prvcy_polcy_join_indv;
        this.prvcy_polcy_join_indv_en = termsPolicy.prvcy_polcy_join_indv_en;
        this.prvcy_polcy_join_grp = termsPolicy.prvcy_polcy_join_grp;
        this.prvcy_polcy_join_grp_en = termsPolicy.prvcy_polcy_join_grp_en;
        
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    termsPrivacyOn(value){
      if(value == 'terms'){
        this.termsPrivacyTypeCode = value;
      } else if(value == 'privacy'){
        this.termsPrivacyTypeCode = value;
      }

      this.termsPrivacyDialog = true;
    },
    termsDialogAccept(value){
      if(value == 'terms'){
        console.log(":::::::::::::" + value);
        console.log(":::::::::::::}}}}}}}}}}}}" + this.basic.terms_agree_yn);
        this.basic.terms_agree_yn = true; 
      } else if(value == 'privacy'){
        this.basic.prvcy_polcy_agree_yn = true;
      }

      this.termsPrivacyDialog = false;
    },
    getMberKindList() {
      this.$http.get("/admin/getSoctyMberKindList").then((res) => {        
        this.mberKindList = res.data.result.mberKindList;
        this.filteredMberKindList = this.mberKindList.filter((e) => {
          return e.join_disp_yn == 'Y' && ((this.joinType == 'indv' && e.indv_mber_kind_yn == 'Y') || (this.joinType == 'grp' && e.grp_mber_kind_yn == 'Y'));
        });
      }).catch((error) => {
          console.log(error);
        }
      )
    },
    crqlImgClicked(idx) {
      this.$refs.crqlImg[idx].$el.querySelector('input').click();
    },
    usrImgClicked() {
      this.$refs.usrImg.$el.querySelector('input').click();
    },
    crqlProofImgClicked() {
      this.$refs.crqlProofImg.$el.querySelector('input').click();
    },
    addrAddBtnClicked() {
      let maxOdr = 0;
      this.addrList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });
      maxOdr++;
      this.addrList.push({addr_sno: '', addr_type:'', zpcd:'', addr:'', addr_dtl:'', addr_ref:'',recv_postm_yn:'', tel_no:'', odr: maxOdr})
      
    },//addrAddBtnClicked end
    addrDelBtnClicked(item) {
      
      this.addrList = this.addrList.filter((e) => e.odr != item.odr)
      let addrOdr = 1;
      this.addrList.forEach((e) => {
        e.odr = addrOdr;
        addrOdr++;
      })

    },//addrDelBtnClicked end
    edubgAddBtnClicked() {
      let maxOdr = 0;
      this.edubgList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });
      maxOdr++;
      this.edubgList.push({edubg_sno: '', dgree_div:'', schl_nm:'', schl_nm_en:'', major_nm:'', major_nm_en:'',grdtn_year:'', last_dgree_yn:'', odr: maxOdr})
      
    },//edubgAddBtnClicked end
    edubgDelBtnClicked(item) {

      this.edubgList = this.edubgList.filter((e) => e.odr != item.odr)
      let edubgOdr = 1;
      this.edubgList.forEach((e) => {
        e.odr = edubgOdr;
        edubgOdr++;
      })
    },//edubgDelBtnClicked end
    crqlDelBtnClicked(item) {

      this.crqlList = this.crqlList.filter((e) => e.odr != item.odr)
      let crqlOdr = 1;
      this.crqlList.forEach((e) => {
        e.odr = crqlOdr;
        crqlOdr++;
      })
    },//crqlDelBtnClicked end
    crqlImgDelBtnClicked(item) {
      if(confirm('자격증 첨부된 이미지 삭제하시겠습니까?')) {
        item.imgDelYn = 'Y';
        item.crql_proof_atch_sno = '';
        item.file_path = '';
        item.orgnl_file_nm = '';
        this.photoRedraw++;
      }

    },
    crqlAddBtnClicked() {
      let maxOdr = 0;
      this.crqlList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });
      maxOdr++;
      this.crqlList.push({crql_sno: '',odr: maxOdr, crql_kind: '', crql_nm: '', crql_nm_en: '', issu_org: '', issu_dt: '', crql_proof_atch_sno: '', orgnl_file_nm: '', file_path: '', file: null })
      
    },//crqlAddBtnClicked end
    setSelectedDate(selected) {
      
      if(this.dateNm == "socty_join_dt") {
        this.basic.socty_join_dt = selected;
      } else if(this.dateNm == "birth_dt") {
        this.indv.birth_dt = selected;
      } else if(this.dateNm == "issu_dt") {
        this.popItem.issu_dt = selected;
      }
      
      this.datePopup = false;
    },//setSelectedDate end
    popupDatePicker(dateNm) {
      this.dateNm = dateNm;
      this.datePopup = true;
    },//popupDatePicker end
    popupDatePickerItem(item, dateNm) {
      this.popItem = item;
      this.dateNm = dateNm;
      this.datePopup = true;
    },//popupDatePicker end
    // onTermsService(){
    //   const routeData = this.$router.resolve({name: 'termsService', query: {joinType: this.joinType}});
    //   window.open(routeData.href, "_blank"); 
    // },
    // onPrivacyPolicy(){
    //   const routeData = this.$router.resolve({name: 'privacyPolicy', query: {joinType: this.joinType}});
    //   window.open(routeData.href, "_blank"); 
    // }

  },
    
}
</script>
<style scoped>
  .spinner-loading {
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
  }
  .v-text-field--enclosed /deep/ .v-input__prepend-inner{
    margin-top: 7px;
  }
  .v-text-field--enclosed /deep/ .v-input__append-inner{
    margin-top: 5px;
  }
  .v-input--selection-controls /deep/ {
    margin-top:0px;
  }
  /deep/ .v-select__slot {
    height:40px !important;
  }
  /deep/ .v-input__icon{
    margin-top:3px
  }
  /deep/ .theme--light.v-label {
    color: rgba(0, 0, 0, 1)
  }
  /deep/ .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
    margin-top:3px;
  }
  
.menuWord {
  max-width: 340px; 
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  }
</style>