<template>
  <v-container fluid class="" v-if="viewYn">
    <v-row class="pa-0 mb-10 ma-0">
      <!-- <v-col cols="12" class="pa-0 d-flex align-center">
        <h1 class="mr-3">{{resultMap.menu_nm}}</h1>
      </v-col> -->
      <!-- <v-col cols="12" class="border-b-darkgrey-2px"></v-col> -->
      <!-- <v-col cols="12" class="tborder hei-700p"> -->
      <v-col cols="12" class="pa-0 ma-0">
        <!-- 지도 영역 -->
        <naver-maps class="wid-100 hei-500p" :mapOptions="mapOptions" v-if="mapYn">
          <naver-marker :lat="mapOptions.lat" :lng="mapOptions.lng" v-if="markerYn"></naver-marker>
        </naver-maps>
      </v-col>
    </v-row>
    <!-- <v-row class="pa-0 mb-10">
      <v-col cols="6" class="pa-5">
        <v-row align="center">
          <v-col cols="12" class="pa-0 d-flex align-center" align="center">
            <v-icon class="mr-3" size="30" color="blue lighten-2">mdi-map-marker</v-icon>
            <h2 class="mr-3">주소</h2>
          </v-col>
          <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
          <v-col cols="12" class=" hei-300p fontSize-2">
            <span>{{footrMap.addr}} {{footrMap.addr_dtl}}</span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" class="pa-5">
        <v-row align="center">
          <v-col cols="12" class="pa-0 d-flex align-center" align="center">
            <v-icon class="mr-3" size="30" color="blue lighten-2">mdi-phone</v-icon>
            <h2 class="mr-3">연락처</h2>
          </v-col>
          <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
          <v-col cols="12" class=" hei-300p fontSize-2">
            <v-row class="pa-3">
              <v-col cols="12" class="">
                <v-row class="mb-2" dense>
                  <v-col cols="2">
                    <div class="wid-100">
                      <span>전화번호</span>
                      <v-icon class="float-right">mdi-power-on</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <span>{{footrMap.tel_no}}</span>
                  </v-col>
                </v-row>
                <v-row class="mb-2" dense>
                  <v-col cols="2">
                    <div class="wid-100">
                      <span>팩스번호</span>
                      <v-icon class="float-right">mdi-power-on</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <span>{{footrMap.fax_no}}</span>
                  </v-col>
                </v-row>
                <v-row class="mb-2" dense>
                  <v-col cols="2">
                    <div class="wid-100">
                      <span>E-mail</span>
                      <v-icon class="float-right">mdi-power-on</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <span>{{footrMap.email}}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
    <v-row class="pa-0 mb-10 ma-0">
      <v-col cols="12" class="pa-0 d-flex align-center">
        <v-icon class="mr-3" size="30" color="blue lighten-2">mdi-bus</v-icon>
        <h2 class="mr-3">Contact Us</h2>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
        <div v-if="cnttUsMap" v-html="cnttUsMap.body_cn" class="mt-5 wid-100 tiny">
        </div>
      <!-- <v-col cols="12" class="">
        버스 : 버스를 이용해서 이동하는 정보를 적어줍니다.
      </v-col>
      <v-col cols="12" class="">
        기차 : 기차를 이용해서 이동하는 정보를 적어줍니다.
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import naver from 'vue-naver-maps';



export default {
  data() {
    return {
      mapOptions: {
        zoom: 18,
        lat: 37.5666612,
        lng: 126.9783785,
        zoomControl: false,
        // pinchZoom: true,
        // scrollWheel: false,
      },
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      footrMap: {},
      cnttUsMap: {},
      viewYn: false,
      markerYn: false,
      mapYn: false,
      emitData: {},
      apiCdMap: {},
    }
  },
  created() {
    const formData = new FormData();
    formData.append('mber_sno', this.mberSno);
    formData.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath,formData).then((res) => {
      console.log(res.data);
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push("/");
        return;
      } else {
        this.footrMap = res.data.footrMap;
        this.cnttUsMap = res.data.cnttUsMap;
        this.apiCdMap = res.data.apiCdMap;
        if(this.apiCdMap) {
          Vue.use(naver, {
            clientID: this.apiCdMap.naver_clnt_id,
            useGovAPI: false, //공공 클라우드 API 사용 (선택)
            subModules:'' // 서브모듈 (선택)
          });
          this.mapYn = true;
        }
        

        if(this.cnttUsMap) {
          const lati = this.cnttUsMap.lati;
          // alert(lati);
          const longi = this.cnttUsMap.longi;
          // alert(this.mapOptions.lat);
          if(lati && longi) {
            this.mapOptions.lat = Number(lati);
            this.mapOptions.lng = Number(longi);
            this.markerYn = true;
          }
        }
        
        this.viewYn = true;

        const resultMap = res.data.resultMap;
        this.emitData.menu_sno = resultMap.menu_sno;
        this.$emit('setData', this.emitData);

      }
        

    }).catch(
      function (error) {
      console.log(error);
      }
    )
  },
}
</script>

<style>

</style>