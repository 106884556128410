<template>
   <v-app class="bg-main">
    <header-a/>
    <v-container fluid class=" hei-150p d-flex align-end bgImg-cover " style="background-image:url('https://img.freepik.com/free-vector/unfocussed-winter-background-with-snowflakes_1393-271.jpg');">
      <template>
        <v-container class=" hei-130p d-flex align-end" >
          <v-row class="hei-130p">
            <v-col cols="2" class="d-flex justify-center align-center">
              <div class="pb-2">
                <span><h1></h1></span>
              </div>
            </v-col>
            <v-col cols="10">
              <v-row class="hei-130p pa-0" align="center">
                <v-col cols="4" class="d-felx align-center">
                  <div class="pb-2">
                    
                  </div>
                </v-col>
                <v-col cols="4" class="d-felx align-center justify-center">
                  <div class="pb-2 justify-center d-flex">
                    <span class="fontSize-9 font-weight-bold"></span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <!--<div class="pb-2 float-right">
                    
                  </div> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>
    <v-container fluid class=" hei-60vh-auto d-flex bg-white">
      <template>
        <v-container class="hei-60vh-auto d-flex mb-10">
          <v-row class="ma-0">
            <v-col cols="12" class="d-flex justify-center" style="align-content: center;">
              <div class="wid-40 ">
                <div class="wid-100 d-flex justify-center">
                  <span style="font-size: 250px; font-weight: 200;" class="title-gra-color">404</span>
                </div>
                <div class="wid-100 d-flex justify-center">
                  <span style="font-size:30px;">페이지 정보를 찾을 수 없습니다.</span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center" style="align-content: center;">
              <div class="wid-40">
                <v-row class="ma-0">
                  <v-col cols="6" class="pa-0 pr-1">
                    <v-btn block x-large outlined tile @click="goBack">이전 페이지로 가기</v-btn>
                  </v-col>
                  <v-col cols="6" class="pa-0 pl-1">
                    <v-btn block x-large outlined tile @click="mainPageMove" class="text-white bg-indigo">홈으로 가기</v-btn>
                  </v-col>
                </v-row>
              </div>
              
              
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>
    <main-footer/>
    </v-app>
</template> 

<script>
import headerA from '@/components/main/header/headerA.vue'
import mainFooter from '@/components/main/footer/footer';

export default {
  components:{
    headerA, mainFooter
    
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },//goBack end
    mainPageMove() {
      this.$router.push("/");
    },//mainPageMove end
  }
}
</script>


<style scoped>
.title-gra-color{
    background: linear-gradient(to right, #5858FA, #01DFD7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>