<template>
  <v-container fluid>
    <v-row class="ma-0 pa-0 mb-5">
      <v-col cols="12">
        <div class="wid-100">
          <img :src="ongoingConfrInfo.url_path" class="imgDf">
        </div>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <!-- <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="movePage"
          >목록</v-btn>
        </div> -->
        <div style="float:right;">
          <v-btn
            small
            color="red lighten-2"
            class="white--text mr-2"
            v-if="preRegSno && ongoingConfrInfo.pre_reg_psbl_yn == 'Y' && preRegPayInfo.pay_yn == 'N'"
            @click="deleteBtnClicked"
          >사전등록 삭제</v-btn>
        </div>
      </v-col>

      <v-col cols="12" class="pa-0">
        <div class="d-flex justify-center">
          <v-card class="hei-80vh-auto wid-100 bg-lightgrey-2 d-flex justify-center align-center pt-10 pb-10" outlined>
            <v-row dense class="ma-0 pa-2" v-if="dispYn">
              <v-col cols="12" class="d-flex align-center border-b-darkgrey">
                <span class="fontSize-4 font-weight-bold">신청자</span>
              </v-col>
              <v-col cols="12">
                <v-row dense class="ma-0" align="center">
                  <v-col cols="6" class="d-flex align-center" v-if="regItemSet.rqstr_email.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">이메일</span><span style="color:red;" v-if="regItemSet.rqstr_email.ess_yn"> *</span>
                        <v-text-field
                          class="bg-white"
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_email"
                          readonly
                          background-color="grey lighten-2"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center" v-if="regItemSet.rqstr_nm.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">성명</span><span style="color:red;" v-if="regItemSet.rqstr_nm.ess_yn"> *</span>
                        <v-text-field
                          class="bg-white"
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_nm"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2' : 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center" v-if="regItemSet.rqstr_blng.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">소속</span><span style="color:red;" v-if="regItemSet.rqstr_blng.ess_yn"> *</span>
                        <v-text-field
                          class="bg-white"
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_blng"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2' : 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center" v-if="regItemSet.rqstr_dept.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">부서/학과</span><span style="color:red;" v-if="regItemSet.rqstr_dept.ess_yn"> *</span>
                        <v-text-field
                          class="bg-white"
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_dept"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2' : 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center" v-if="regItemSet.rqstr_cel_no.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">핸드폰번호</span><span style="color:red;" v-if="regItemSet.rqstr_cel_no.ess_yn"> *</span>
                        <v-text-field
                          class="bg-white"
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_cel_no"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2' : 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer/>
                  <v-col cols="12" v-if="mberSno && nonMberYn == 'N'">
                    <div style="color:#DEA75C;">상기 정보는 회원 프로필 정보에서 가져옵니다.</div>
                    <div style="color:#DEA75C;">정보 변경 및 입력은 <a>마이페이지>프로필</a> 페이지에서 진행해주시기 바랍니다.</div>
                  </v-col>
                </v-row>
              </v-col>

              <!-- 사전 등록 참가자 -->
              <v-col cols="12" class="d-flex align-center border-b-darkgrey">
                <span class="fontSize-4 font-weight-bold">사전등록 참가자 명단</span>
              </v-col>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12" v-for="(item,idx) in ptcpProdList" :key="`confr_`+idx">
                    - {{item.prod_nm}} : {{ item.prod_amt }} 원
                  </v-col>
                  <v-col cols="12">
                    <div class="f-r pl-1">
                      <v-btn
                        small
                        color="blue lighten-2"
                        class="white--text elevation-0"
                        @click="addBtnConfrGridClicked"
                        v-if="ongoingConfrInfo.pre_reg_psbl_yn == 'Y' && preRegPayInfo.pay_yn == 'N'"
                        >
                        추가
                      </v-btn>
                    </div>
                    <div class="f-r">
                      <v-btn
                        small
                        color="red lighten-2"
                        class="white--text elevation-0"
                        @click="ptcpDelClicked"
                        v-if="ongoingConfrInfo.pre_reg_psbl_yn == 'Y' && preRegPayInfo.pay_yn == 'N'"
                      >
                        선택 삭제
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <v-data-table
                        :headers="ptcpHeader"
                        :items="ptcpGridList"
                        class="elevation-0 dTable-1"
                        :items-per-page="20"
                        hide-default-footer
                        disable-pagination>
                        <template v-slot:[`item.select_yn`]="{ item }">
                          <v-checkbox
                            class="pa-0 ma-0"
                            hide-details
                            dense
                            v-model="item.select_yn"
                            :readonly="ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          ></v-checkbox>
                        </template>
                        <template v-slot:[`item.prod_sno`]="{ item }">
                          <v-select
                            class="pa-0 ma-0"
                            item-text="prod_nm"
                            item-value="prod_sno"
                            :items="ptcpProdList"
                            hide-details
                            outlined
                            dense
                            v-model="item.prod_sno"
                            @change="ptcpProdChanged(item)"
                            :readonly="ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          ></v-select>
                        </template>
                        <template v-slot:[`item.ptcpr_nm`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            hide-details
                            outlined
                            dense
                            v-model="item.ptcpr_nm"
                            :readonly="ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.ptcpr_blng`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            hide-details
                            outlined
                            dense
                            v-model="item.ptcpr_blng"
                            :readonly="ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.ptcpr_email`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            hide-details
                            outlined
                            dense
                            v-model="item.ptcpr_email"
                            :readonly="ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.ptcp_amt`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            hide-details
                            readonly
                            background-color="grey lighten-2"
                            outlined
                            dense
                            v-model="item.ptcp_amt"
                          ></v-text-field>
                        </template>
                      </v-data-table>
                    </div>
                  </v-col>

                  <v-col cols="12" class="mt-5 mb-5">
                    <div class="border-darkgrey-3px pa-4">
                      <div class="d-flex justify-center"><span class="fontSize-7 font-weight-bold">결제 예정 금액</span></div>
                      <div class="d-flex justify-center"><span class="text-red-2 fontSize-6 font-weight-bold">₩ {{ ptcpTotalAmt.toLocaleString('ko-KR') }}</span></div>
                    </div>
                    <div><span style="color:#DEA75C;">결제는 <a>마이페이지 > 결제</a> 페이지에서 진행해주시기 바랍니다.</span></div>
                  </v-col>
                  <v-col cols="12" class="">
                  </v-col>
                </v-row>
              </v-col>
              <!-- 사전 특별행사 참가자 -->
              <v-col cols="12" v-if="eventProdList.length > 0">
                <v-row dense align="center">
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey">
                    <span class="fontSize-4 font-weight-bold">사전등록 특별행사 참가자 명단</span>
                  </v-col>
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="12" v-for="(item,idx) in eventProdList" :key="`event_`+idx">
                        - {{item.prod_nm}} : {{ item.prod_amt }} 원
                      </v-col>
                      <v-col cols="12">
                        <div class="f-r pl-1">
                          <v-btn
                            small
                            color="blue lighten-2"
                            class="white--text elevation-0"
                            @click="addBtnEventGridClicked"
                            v-if="ongoingConfrInfo.pre_reg_psbl_yn == 'Y' && preRegPayInfo.pay_yn == 'N'"
                          >
                            추가
                          </v-btn>
                        </div>
                        <div class="f-r">
                          <v-btn
                            small
                            color="red lighten-2"
                            class="white--text elevation-0"
                            @click="eventDelClicked"
                            v-if="ongoingConfrInfo.pre_reg_psbl_yn == 'Y' && preRegPayInfo.pay_yn == 'N'"
                          >
                            선택 삭제
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div>
                          <v-data-table
                            :headers="eventHeader"
                            :items="eventGridList"
                            class="elevation-0 dTable-1"
                            :items-per-page="20"
                            hide-default-footer
                            disable-pagination
                            :readonly="ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          >
                            <template v-slot:[`item.select_yn`]="{ item }">
                              <v-checkbox
                                class="pa-0 ma-0"
                                hide-details
                                dense
                                v-model="item.select_yn"
                              ></v-checkbox>
                            </template>
                            <template v-slot:[`item.prod_sno`]="{ item }">
                              <v-select
                                class="pa-0 ma-0"
                                item-text="prod_nm"
                                item-value="prod_sno"
                                :items="eventProdList"
                                hide-details
                                outlined
                                dense
                                v-model="item.prod_sno"
                                @change="eventProdChanged(item)"
                              ></v-select>
                            </template>
                            <template v-slot:[`item.ptcpr_cnt`]="{ item }">
                              <v-text-field
                                class="pa-0 ma-0"
                                hide-details
                                outlined
                                dense
                                type="number"
                                v-model="item.ptcpr_cnt"
                                @change="eventPtcprCntChanged(item)"
                              ></v-text-field>
                            </template>
                            <template v-slot:[`item.ptcp_amt`]="{ item }">
                              <v-text-field
                                class="pa-0 ma-0"
                                hide-details
                                outlined
                                dense
                                v-model="item.ptcp_amt"
                                readonly
                                background-color="grey lighten-2"
                              ></v-text-field>
                            </template>
                          </v-data-table>
                        </div>

                      </v-col>
                      <v-col cols="12" class="mt-5 mb-5">
                        <div class="border-darkgrey-3px pa-4">
                          <div class="d-flex justify-center"><span class="fontSize-7 font-weight-bold">결제 예정 금액</span></div>
                          <div class="d-flex justify-center"><span class="text-red-2 fontSize-6 font-weight-bold">₩ {{ eventTotalAmt.toLocaleString('ko-KR') }}</span></div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- 참석형태 -->
              <v-col cols="12">
                <v-row dense v-if="regItemSet.ptcp_type.use_yn">
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey">
                    <span class="fontSize-4 font-weight-bold">참석 형태</span>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">참석 형태</span><span style="color:red;" v-if="regItemSet.ptcp_type.ess_yn"> *</span>
                        <v-select
                          class="wid-100 bg-white" 
                          :items="ptcpTypeList"
                          item-value="cmn_cd"
                          item-text="cmn_cd_nm"
                          v-model="reqParam.ptcp_type"
                          hide-details
                          dense
                          outlined
                          :readonly="(preRegSno != null && preRegSno != '' && regOptn.ptcp_type_upd_psbl_yn == 'N') || ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                          :background-color="(preRegSno != null && preRegSno != '' && regOptn.ptcp_type_upd_psbl_yn == 'N') || ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y' ? 'grey lighten-2' : ''"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- 메모 -->
              <v-col cols="12">
                <v-row dense v-if="regItemSet.rqstr_memo.use_yn">
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey">
                    <span class="fontSize-4 font-weight-bold">메모</span>
                    <span class="fontSize-4 font-weight-bold" style="color:red;" v-if="regItemSet.rqstr_memo.ess_yn">*</span>
                  </v-col>
                  <v-col cols="12" class="">
                    <v-textarea
                      v-model="reqParam.rqstr_memo"
                      class="wid-100 pt-0 bg-white"
                      hide-details
                      outlined
                      :readonly="ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="d-flex align-center border-b-darkgrey">
                <span class="fontSize-4 font-weight-bold">개인정보 처리방침</span>
                <span class="fontSize-4 font-weight-bold" style="color:red;" v-if="regItemSet.prvcy_polcy_agree.ess_yn">&nbsp;*</span>
              </v-col>
              <v-col cols="12">
                <div class="wid-100 hei-200p bg-white tiny" style="overflow:auto;" v-html="confrPolcy.prvcy_polcy"></div>
              </v-col>
              <v-col cols="12" class="d-flex justify-start">
                <v-checkbox hide-details class="" label="개인정보처리방침에 동의합니다." v-model="agreeYn" :readonly="ongoingConfrInfo.pre_reg_psbl_yn == 'N' || preRegPayInfo.pay_yn == 'Y'">
                </v-checkbox>
              </v-col>
              

            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row dense class="ma-0 pa-0 mt-4">
      <v-col cols="12" class="d-flex justify-center" >
        <v-btn 
          large class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" 
          outlined
          @click="regBtnClicked"
          v-if="!preRegSno && ongoingConfrInfo.pre_reg_psbl_yn == 'Y' && preRegPayInfo.pay_yn == 'N'"
        >
          사전등록
        </v-btn>
        <v-btn 
          large class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" 
          outlined
          v-if="preRegSno && ongoingConfrInfo.pre_reg_psbl_yn == 'Y' && preRegPayInfo.pay_yn == 'N'"
          @click="modifyBtnClicked"
        >
          수정
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name:'preWrite',
  data() {
    return {
      ptcpHeader: [
        { text: '선택', value:'select_yn', width:"6%", sortable: false},
        { text: '회원구분 *', value:'prod_sno', width:"35%", sortable: false},
        { text: '이름 *', value:'ptcpr_nm', width:"12%", sortable: false},
        { text: '소속 *', value:'ptcpr_blng', width:"15%", sortable: false},
        { text: '이메일 *', value:'ptcpr_email', width:"20%", sortable: false},
        { text: '참가비', value:'ptcp_amt', width:"12%", sortable: false},
      ],
      eventHeader: [
        { text: '선택', value:'select_yn', width:"7%", sortable: false},
        { text: '회원구분 *', value:'prod_sno', width:"50%", sortable: false},
        { text: '참가인원수 *', value:'ptcpr_cnt', width:"15%", sortable: false},
        { text: '참가비', value:'ptcp_amt', width:"18%", sortable: false},
      ],
      ptcpGridList:[],
      eventGridList:[],
      abstrList: [],
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      basicUri: '',
      confrSno: this.$route.query.confr_sno ? this.$route.query.confr_sno : '',
      // regItemList: [],
      regItemSet: {},
      dispYn: false,
      mberMap: {},
      ptcpProdList: [],
      eventProdList: [],
      // prvcy_polcy: '',
      ptcpTotalAmt: 0,
      eventTotalAmt: 0,
      agreeYn: false,
      regItemList: [],
      ongoingConfrInfo: {},
      reqParam: {},
      preRegSno: this.$route.query.pre_reg_sno ? this.$route.query.pre_reg_sno : '',

      regOptn: {},
      confrPolcy: {},

      ptcpTypeList: [],
      preRegPayInfo: {pay_yn: 'N'},
    }
  },
  created() {
    
    let path = this.$route.fullPath;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);

    if(!this.confrSno) {
      alert('잘못된 접근입니다');
      this.$router.push(this.basicUri+'/list');
    }

    this.getPtcpTypeList();
    this.getPreRegPage();
  },
  methods: {
    async getPtcpTypeList() {
      this.ptcpTypeList = await this.$getCmnCdList('ptcp_type');
    },
    getPreRegPage() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("non_mber_yn", this.nonMberYn);
      
      // params.append("confr_sno", this.confrSno);

      this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
        console.log("getPreRegPage : ", res.data);
        // console.log("res.data.result.regItemList",res.data.result.regItemList)

        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.movePage();
        }
        
        let regItemSet = {};
        this.regItemList = res.data.result.regItemList;
        res.data.result.regItemList.forEach((e) => {
          regItemSet[e.reg_item_id] = {use_yn:e.use_yn, ess_yn:e.ess_yn};
        });
        this.regItemSet = regItemSet;

        // console.log('this.regItemSet' ,this.regItemSet);

        this.mberMap = res.data.result.mberMap;

        // console.log('this.mberMap' ,this.mberMap);
        
        if(!this.preRegSno && this.mberMap && this.nonMberYn == 'Y') {
          this.reqParam['rqstr_email'] = this.mberMap.email;
        } else if(!this.preRegSno && this.mberMap && this.nonMberYn == 'N') {
          for(let a in regItemSet) {
            if(a == 'rqstr_email') {
              this.reqParam[a] = this.mberMap.email;
            } else if(a == 'rqstr_nm') {
              this.reqParam[a] = this.mberMap.mber_nm;
            } else if(a == 'rqstr_blng') {
              this.reqParam[a] = this.mberMap.blng_nm;
            } else if(a == 'rqstr_dept') {
              this.reqParam[a] = this.mberMap.dept_nm;
            } else if(a == 'rqstr_cel_no') {
              this.reqParam[a] = this.mberMap.cel_no;
            } else if(a == 'prvcy_polcy_agree_yn') {
              if(this.agreeYn) {
                this.reqParam[a] = 'Y';
              }
            }
          }
        } else {
          this.reqParam = this.mberMap;
          if(res.data.result.ptcpGridList) {
            this.ptcpGridList = res.data.result.ptcpGridList;
            this.calcTotPtcpAmt();
          }
          
          if(res.data.result.eventGridList) {
            this.eventGridList = res.data.result.eventGridList;
            this.calcTotEventAmt();
          }

          if(this.mberMap.prvcy_polcy_agree_yn == 'Y') {
            this.agreeYn = true;
          }
        }
        
        this.confrPolcy = res.data.result.confrPolcy;

        // 사전등록 옵션
        this.regOptn = res.data.result.regOptn;

        //참가자 상품 리스트
        this.ptcpProdList = res.data.result.ptcpProdList;
        //특별행사 참가자 상품 리스트
        this.eventProdList = res.data.result.eventProdList;

        // 진행 중인 학술대회 정보
        this.ongoingConfrInfo = res.data.result.ongoingConfrInfo;

        // 사전등록 참가비 결제 정보
        if(res.data.result.preRegPayInfo) {
          this.preRegPayInfo = res.data.result.preRegPayInfo;
        }

        console.log("this.ongoingConfrInfo",this.ongoingConfrInfo);

        this.dispYn = true;
        
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//getPreRegPage end
    addBtnConfrGridClicked() {
      // alert('추가 클릭');
      this.ptcpGridList.push({select_yn: false, prod_sno: '', ptcpr_nm: '', ptcpr_blng: '', ptcpr_email: '', ptcp_amt:'', new_Yn: 'Y'})
    },//addBtnConfrGridClicked end
    addBtnEventGridClicked() {
      // alert('추가 클릭');
      this.eventGridList.push({select_yn: false, prod_sno: '', ptcpr_cnt: '', ptcp_amt: '', new_Yn: 'Y'})
    },//addBtnEventGridClicked end
    ptcpProdChanged(item) {
      // item.ptcp_amt = this.ptcpProdList.filter((e) => e.prod_sno == item.prod_sno)
      
      item.prod_amt = this.ptcpProdList.filter((e) => e.prod_sno == item.prod_sno)[0].prod_amt_num;
      item.ptcp_amt = item.prod_amt.toLocaleString('ko-KR');

      this.calcTotPtcpAmt();

    },//ptcpProdChanged end
    eventProdChanged(item) {
      // item.ptcp_amt = this.ptcpProdList.filter((e) => e.prod_sno == item.prod_sno)
      
      item.prod_amt = this.eventProdList.filter((e) => e.prod_sno == item.prod_sno)[0].prod_amt_num;
      item.ptcp_amt = (item.prod_amt * item.ptcpr_cnt).toLocaleString('ko-KR');
      // item.ptcpr_cnt = 1;
      this.calcTotEventAmt();

    },//eventProdChanged end
    eventPtcprCntChanged(item) {
      if(item.ptcpr_cnt == 0) {
        alert('(특별행사 참가자)참가인원수는 0 이상만 가능합니다.');
        item.ptcpr_cnt = 1;
      }
      item.prod_amt = this.eventProdList.filter((e) => e.prod_sno == item.prod_sno)[0].prod_amt_num;
      // item.prod_amt = item.prod_amt * item.ptcpr_cnt;
      item.ptcp_amt = (item.prod_amt * item.ptcpr_cnt).toLocaleString('ko-KR');
      this.calcTotEventAmt();

    },//eventPtcprCntChanged end
    ptcpDelClicked() {
      if(this.ptcpGridList.filter((e) => e.select_yn).length == 0) {
        alert('삭제할 항목이 선택되지 않았습니다. 다시 선택해주세요.');
      } else {
        if(confirm('선택하신 항목 삭제하시겠습니까?')) {
          this.ptcpGridList = this.ptcpGridList.filter((e) => !e.select_yn);
          this.calcTotPtcpAmt();
        }
        
      }
      
    },//eventDelClicked end
    eventDelClicked() {
      if(this.eventGridList.filter((e) => e.select_yn).length == 0) {
        alert('삭제할 항목이 선택되지 않았습니다. 다시 선택해주세요.');
      } else {
        if(confirm('선택하신 항목 삭제하시겠습니까?')) {
          this.eventGridList = this.eventGridList.filter((e) => !e.select_yn);
          this.calcTotEventAmt();
        }
      }
    },//eventDelClicked end
    regBtnClicked() {
      if(confirm('사전등록 하시겠습니까?')) {
        this.savePreRegPage('regist');
      }
      
      // alert(this.areeYn);
    },//regBtnClicked end
    validateInput() {
      if(this.regItemSet.rqstr_email.ess_yn && !this.reqParam.rqstr_email) {
        alert('이메일은 필수 입력값입니다.');
        return false;
      } 
      if(this.regItemSet.rqstr_nm.ess_yn && !this.reqParam.rqstr_nm) {
        alert('성명은 필수 입력값입니다.');
        return false;
      } 
      if(this.regItemSet.rqstr_blng.ess_yn && !this.reqParam.rqstr_blng) {
        alert('소속은 필수 입력값입니다.');
        return false;
      } 
      if(this.regItemSet.rqstr_dept.ess_yn && !this.reqParam.rqstr_dept) {
        alert('부서/학과는 필수 입력값입니다.');
        return false;
      } 
      if(this.regItemSet.rqstr_cel_no.ess_yn && !this.reqParam.rqstr_cel_no) {
        alert('핸드폰번호는 필수 입력값입니다.');
        return false;
      } 
      if(this.mberMap.email && !this.$validateEmail(this.reqParam.rqstr_email)) {
        alert('이메일 형식이 아닙니다.');
        return false;
      }

      let isValid = true;

      this.ptcpGridList.some((e) => {
        if(!e.prod_sno) {
          alert('(참가자)사전등록상품은 필수 입력값입니다.');
          isValid = false;
        } else if(!e.ptcpr_nm) {
          alert('(참가자)이름 필수 입력값입니다.');
          isValid = false;
        } else if(!e.ptcpr_blng) {
          alert('(참가자)소속은 필수 입력값입니다.');
          isValid = false;
        } else if(!e.ptcpr_email) {
          alert('(참가자)이메일은 필수 입력값입니다.');
          isValid = false;
        }  else if(e.ptcpr_email && !this.$validateEmail(e.ptcpr_email)) {
          alert('(참가자)이메일 형식이 아닙니다.');
          isValid = false;
        }
      })

      if(!isValid) {
        return false;
      }

      this.eventGridList.some((e) => {
        if(!e.prod_sno) {
          alert('(특별행사 참가자)사전등록상품은 필수 입력값입니다.');
          isValid = false;
        } else if(!e.ptcpr_cnt) {
          alert('(특별행사 참가자)참가인원수는 필수 입력값입니다.');
          isValid = false;
        }
      })

      if(!isValid) {
        return false;
      }

      // 참석 형태
      if(this.regItemSet.ptcp_type.ess_yn && !this.reqParam.ptcp_type) {
        alert('참석 형태는 필수 입력값입니다.');
        return false;
      }

      // 메모
      if(this.regItemSet.rqstr_memo.ess_yn && (this.reqParam.rqstr_memo == null || this.reqParam.rqstr_memo == "")) {
        alert('메모는 필수 입력값입니다.');
        return false;
      }

      if(this.regItemSet.prvcy_polcy_agree.ess_yn && !this.agreeYn) {
        alert('개인정보 처리방침 동의는 필수입니다.');
        return false;
      }

      if(!this.checkRegistOptn()) {
        return false;
      }

      return true;
    },//validateInput end
    // 사전등록 옵션 체크
    checkRegistOptn() {
      // 사전등록 최대 인원수
      if(this.ptcpGridList.length > this.regOptn.pre_reg_max_mber_cnt) {
        alert(`사전등록 등록 가능 인원(${this.regOptn.pre_reg_max_mber_cnt}명)을 초과하여 등록할 수 없습니다.`);
        return false;
      }

      // 특별행사 참가자 최대 인원수
      let mberCnt = 0;

      this.eventGridList.forEach((e) => {
        mberCnt += Number(e.ptcpr_cnt);
      });

      if(mberCnt > this.regOptn.event_max_mber_cnt) {
        alert(`특별행사 등록 가능 인원(${this.regOptn.event_max_mber_cnt}명)을 초과하여 등록할 수 없습니다.`);
        return false;
      }

      // 연회비 납부 검사 : 결제까지 완료되어야 함

      return true;
    },
    calcTotPtcpAmt() {
      this.ptcpTotalAmt = 0;
      console.log(this.ptcpGridList);
      this.ptcpGridList.forEach((e) => {
        if(e.prod_amt) {
          this.ptcpTotalAmt += e.prod_amt;
        }
        
      })
    },//calcTotPtcpAmt end
    calcTotEventAmt() {
      this.eventTotalAmt = 0;
      this.eventGridList.forEach((e) => {
        if(e.prod_amt) {
          e.ptcp_amt = (e.prod_amt * e.ptcpr_cnt).toLocaleString('ko-KR');
          this.eventTotalAmt += e.prod_amt * e.ptcpr_cnt;
        }
      })
    },//calcTotEventAmt end
    modifyBtnClicked() {
      if(!this.preRegSno) {
        alert('필수값이 존재 하지않습니다.');
        this.$router.go(0);
      }
      if(confirm('수정하시겠습니까?')) {
        console.log(this.mberMap);
        this.savePreRegPage("modify");
      }
    },//modifyBtnClicked end
    movePage() {
      this.$router.push(this.basicUri+'/list');
    },//movePage end
    deleteBtnClicked() {

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();
        params.append("pre_reg_sno", this.preRegSno);

        this.$http.post("/admin/deleteConfrPreReg", params).then((res) => {
          console.log(res.data);
          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.movePage();
          }
          
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },

    savePreRegPage(mode) {
      if(this.validateInput()) {
        const params = new FormData();
        params.append("mber_sno", this.mberSno);
        params.append("rqst_mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);

        if(this.nonMberYn == 'Y') {
          this.reqParam.mber_yn = 'N';
        }

        if(mode == "modify") {
          params.append("pre_reg_sno", this.preRegSno);
        }

        //신청자 정보
        this.reqParam.prvcy_polcy_agree_yn = this.agreeYn ? 'Y' : 'N';
        params.append("reqParam", JSON.stringify(this.reqParam));
        
        //참가자 정보
        let ptcpOdr = 1;
        this.ptcpGridList.forEach((e) => {
          e.odr = ptcpOdr++;
        })

        params.append("ptcpGridList", JSON.stringify(this.ptcpGridList));
        //특별행사 참가자 정보
        let eventOdr = 1;
        this.eventGridList.forEach((e) => {
          e.odr = eventOdr++;
        })

        params.append("eventGridList", JSON.stringify(this.eventGridList));

        this.$http.post('/sub/upsertConfrPreReg', params).then((res) => {
          console.log(res.data);
          if(res.data.code == 0) {
            alert('저장되었습니다..');
            this.$router.push(this.basicUri+'/list');

          } else {
            alert('저장 실패');
          }
          
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    }
  }
}
</script>
<style scoped>
 .hoverCheck:hover {
  cursor: pointer !important;
 }
</style>