<template>
  <v-app>
    <v-container fluid class=" hei-150p d-flex align-end bgImg-cover " style="background-image:url('https://img.freepik.com/free-vector/unfocussed-winter-background-with-snowflakes_1393-271.jpg');">
      <template>
        <v-container class=" hei-130p d-flex align-end" >
          <v-row class="hei-130p">
            <v-col cols="2" class="d-flex justify-center align-center">
              <div class="pb-2">
                <span><h1>이메일 무단수집 거부</h1></span>
              </div>
            </v-col>
            <v-col cols="10">
              <v-row class="hei-130p pa-0 pl-5" align="center">
                <v-col cols="4" class="d-felx align-center ">
                  <div class="pb-2">
                    
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <div class="pb-2 float-right">
                    <span>Home / 회원 / 이메일 무단수집 거부</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>

    <v-container fluid class=" hei-90vh-auto d-flex bg-white">
      <template>
        <v-container class="hei-80vh-auto d-flex">
          <v-row class="ma-0">
            <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-3">
                <div class="wid-100 d-flex justify-center mb-5">
                  <h1>이메일 무단수집 거부</h1>
                </div>
                <v-card class="hei-80vh-auto wid-100 bg-lightgrey-2 align-center pa-3 mb-10" outlined>
                  <div class="wid-100">
                    <v-container class="mb-10 pa-5">
                      <v-row class="">
                        <v-col cols="12" class="d-flex justify-center mb-5">
                          <h2>
                            이메일 무단수집 거부
                          </h2>
                        </v-col>
                        <v-col cols="12" class="">
                          <div class="bg-white hei-80vh-auto tiny" v-html="this.email_colct_deny" >
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>

    

  </v-app>
</template>

<script>
export default {
  data(){
    return{
      joinType: this.$route.query.joinType,         //개인, 단체 타입
      email_colct_deny:'',
    }
  },
  mounted() {
    this.getSoctyJoinPolcy();
    console.log("hellooo ::::: ");
    
  },
  methods: {
    getSoctyJoinPolcy() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyGenPolcy", params).then((res) => {
        console.log("res.data: ", res.data.result.length);
        // if(res.data.result.length == 0) {
        //   this.isNew = true;
        //   // alert("조회된 정보가 없습니다.");
        //   return;
        // } else {
        //   this.isNew = false;
        // }

        const emailDeny = res.data.result[0];
        
        this.email_colct_deny = emailDeny.email_colct_deny;
        
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
  }
}
</script>

<style>

</style>