<template>
	<v-container fluid class="pa-3">
		<v-row  class="" align="center">
			<v-col cols="5">
				<div class="f-l pt-1">
					<h3>퍼미션 그룹</h3>
				</div>
				<div>
          <div class="f-r pl-1">
            <v-btn 
              small 
              color="blue lighten-1 white--text" 
              @click="savePermGrpBtnClicked"
            >저장 후 유지</v-btn>
          </div>
          <div class="f-r pl-1">
            <v-btn
              small
              color="green lighten-2"
              class="white--text"
              @click="addPermGrpBtnClicked"
            >추가</v-btn>
          </div>
          <div class="f-r">
            <v-btn
              small
              color="red lighten-2"
              class="white--text"
              @click="deletePermGrpBtnClicked"
            >선택 삭제</v-btn>
          </div>
				</div>
			</v-col>
			<v-col cols="7" v-show="showSub">
				<div class="f-l pt-1">
					<h3>메뉴 퍼미션 {{ permGrpNm }} {{ isPermSet }}</h3>
				</div> 
				<div>
					<v-btn 
						small
						class="ml-2 f-r"
						color="blue lighten-1 white--text" 
						@click="saveMenuPermBtnClicked"
          >저장 후 유지</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-row class="">
			<v-col cols="5">
				<v-data-table
					:headers="permGrpHeader"
					:items="permGrpList"
					class="elevation-1 wid-100"
					item-key="no"
          @click:row="permGrpRowClicked"
					hide-default-footer
          style="cursor: pointer"
        >
          <template v-slot:[`item.select_yn`]="{item}">
            <v-checkbox 
              v-model="item.select_yn" 
              class="ma-0 pa-0"
              hide-details
              solo 
              flat
              :disabled="item.readonly_yn == 'Y'"
            ></v-checkbox>
          </template>
          <template v-slot:[`item.perm_grp_nm`]="{item}">
            <v-text-field
              v-model="item.perm_grp_nm"
              hide-details
              outlined
              dense
              @change="permGrpGridItemChanged(item)"
              :readonly="!item.new_yn && (item.perm_grp_nm == '비회원' || item.perm_grp_nm == '미승인회원' || item.perm_grp_nm == '연회비미납회원' || item.perm_grp_nm == '일반승인회원')"
              :background-color="!item.new_yn && (item.perm_grp_nm == '비회원' || item.perm_grp_nm == '미승인회원' || item.perm_grp_nm == '연회비미납회원' || item.perm_grp_nm == '일반승인회원') ? 'grey lighten-2' : ''"
            ></v-text-field>
          </template>
          <template v-slot:[`item.perm_grp_desc`]="{item}">
            <v-text-field
              v-model="item.perm_grp_desc"
              hide-details
              outlined
              dense
              @change="permGrpGridItemChanged(item)"
              :readonly="!item.new_yn && (item.perm_grp_nm == '비회원' || item.perm_grp_nm == '미승인회원' || item.perm_grp_nm == '연회비미납회원' || item.perm_grp_nm == '일반승인회원')"
              :background-color="!item.new_yn && (item.perm_grp_nm == '비회원' || item.perm_grp_nm == '미승인회원' || item.perm_grp_nm == '연회비미납회원' || item.perm_grp_nm == '일반승인회원') ? 'grey lighten-2' : ''"
            ></v-text-field>
          </template>
				</v-data-table>
        <div class="mt-2">* "비회원", "미승인회원", "연회비미납회원", "일반승인회원" 그룹을 이용하여 퍼미션을 제한할 수 있음</div>
        <div class="mt-1">* 위 4가지 그룹은 학회 생성 시 자동으로 생성되며 수정 및 삭제 불가함</div>
        <div class="mt-1">* 비회원 - 로그인하지 않음</div>
        <div class="mt-1">* 미승인회원 - 로그인은 했지만 승인되지 않음</div>
        <div class="mt-1">* 연회비미납회원 - 당해년도 연회비 미납 회원. 종신회원, 연회비 면제 회원 제외</div>
        <div class="mt-1">* 일반승인회원 - 가입 후 승인 완료된 회원. 자동으로 기본 권한을 부여하기 위함</div>
			</v-col>
			<v-col cols="7" v-show="showSub">
				<v-data-table
					:headers="menuPermHeader"
					:items="menuPermList"
					class="elevation-1"
					hide-default-footer
          disable-pagination
          style="cursor: pointer"
          height="750px"
        >	
          <template v-slot:[`header.get_perm_yn_scr`]="{ header }">
            <span>{{ header.text }}<v-checkbox v-model="viewableYn" hide-details class="ma-0 pa-0" dense @click="viewableClicked"/></span>
          </template>
          <template v-slot:[`header.chg_perm_yn_scr`]="{ header }">
            <span>{{ header.text }}<v-checkbox v-model="changeableYn" hide-details class="ma-0 pa-0" dense @click="changeableClicked"/></span>
          </template>

					<template v-slot:[`item.menu_nm`]="{item}">
            <div 
              style="float:left; line-height: 250%"
              v-if="item.menu_lvl > 1"
              :class='item.menu_lvl == 2 ? "pa-0 ma-0 ml-6" : "pa-0 ma-0 ml-12"'
            >ㄴ</div>
						<v-text-field
							v-model="item.menu_nm"
							class="ma-0 pa-0 text-field-transparent"
							hide-details
              readonly
              solo
              flat
							dense
            ></v-text-field>
					</template>
          <template v-slot:[`item.menu_nm_en`]="{item}">
            <div 
              style="float:left; line-height: 250%"
              v-if="item.menu_lvl > 1"
              :class='item.menu_lvl == 2 ? "pa-0 ma-0 ml-6" : "pa-0 ma-0 ml-12"'
            >ㄴ</div>
						<v-text-field
							v-model="item.menu_nm_en"
							class="ma-0 pa-0 text-field-transparent"
							hide-details
              readonly
              solo
              flat
							dense
            ></v-text-field>
					</template>
          <template v-slot:[`item.get_perm_yn_scr`]="{item}">
						<v-checkbox 
							v-model="item.get_perm_yn_scr" 
							class="ma-0 pa-0"
							hide-details
							dense
              @click="menuPermGridItemClicked($event, item, 'get')"
              @change="menuPermGridItemChanged(item)"
            ></v-checkbox>
					</template>
					<template v-slot:[`item.chg_perm_yn_scr`]="{item}">
						<v-checkbox 
							v-model="item.chg_perm_yn_scr" 
							class="ma-0 pa-0"
							hide-details
							dense
              @click="menuPermGridItemClicked($event, item, 'chg')"
              @change="menuPermGridItemChanged(item)"
            ></v-checkbox>
					</template>
          
				</v-data-table>
			</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	data() {
		return {
      mberSno: "",
			redrawCount : 0,
			singleSelect: false,
			pageCount:0,
			page:1,
			itemsPerPage : -1,
      showSub: false,
      viewableYn: false,
      changeableYn: false,
			permGrpHeader: [
        { text: '선택', value: 'select_yn', width: "10%", sortable:false },
        { text: '퍼미션 그룹명', value: 'perm_grp_nm', width: "35%" },
        { text: '퍼미션 그룹설명', value: 'perm_grp_desc', width: "55%" },
			],
      permGrpList: [],
      permGrpChangedList: [],

      permGrpSno: null,
      permGrpNm: '',
      isPermSet: '',

      menuPermHeader: [
				{ text: '메뉴명', value: 'menu_nm', width: "25%", sortable:false },
        { text: '메뉴영문명', value: 'menu_nm_en', width: "25%", sortable:false },
				{ text: '조회 가능', value: 'get_perm_yn_scr', width: "10%", sortable:false },
        { text: '변경 가능', value: 'chg_perm_yn_scr', width: "10%", sortable:false },
			],
      menuPermList: [],
      menuPermChangedList: [],
		};
	},
  created() {
    this.mberSno = this.$session.get('mberSno');

		this.getPermGrpList();
    this.showSub = true;
  },
  mounted() {

  },
  methods: {
		getPermGrpList() {
			const params = new FormData();

			this.$http.post("/admin/getPermGrpList", params).then((res) => {
        console.log("res.data:", res.data);
				this.permGrpList = res.data.permGrpList;
        this.permGrpChangedList = [];
			}).catch(function (error) {
					console.log(error);
				}.bind(this)
			);
		},
    permGrpRowClicked(item) {			
      console.log("permGrpRowClicked");
      this.viewableYn = false;
      this.changeableYn = false;
      
      if(item.new_yn == true) {
        this.permGrpSno = null;
        this.permGrpNm = ' > ' + "";
      } else {
        this.permGrpSno = item.perm_grp_sno;
        this.permGrpNm = ' > ' + item.perm_grp_nm;
        this.getMenuPermList();
      }
		},
    // 퍼미션그룹 수정됨
    permGrpGridItemChanged(item) {
      // console.log("gridItemChanged : item.addr_sno : " + item.addr_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.perm_grp_sno == null || item.perm_grp_sno == "") {
        return;
      }

      let found = false;
      this.permGrpChangedList.forEach((e) => {
        if(e.perm_grp_sno == item.perm_grp_sno) {
          found = true;
        }
      });

      if(!found) {
        this.permGrpChangedList.push(item);
      }
    },
		savePermGrpBtnClicked() {
			console.log("savePermGrpBtnClicked");

      if(this.permGrpChangedList.length == 0) {
        alert("수정된 내용이 없습니다.");
        return;
      }

      if(confirm("저장하시겠습니까?")) {
        let params = new URLSearchParams();

        // true/false -> 'Y'/'N' 변환
        this.permGrpChangedList.forEach((e) => {
          e.new_yn = e.new_yn == true ? 'Y' : 'N';
        });

        params.append("mber_sno", this.mberSno);
        params.append("changedList", JSON.stringify(this.permGrpChangedList));

        this.$http.post("/admin/upsertPermGrpList", params).then((res) => {
          console.log(res.data);
          alert("저장되었습니다.");
          
          this.getPermGrpList();
        }).catch(function (error) {
            console.log(error);
        });
      }
		},
    // 퍼미션그룹 추가
    addPermGrpBtnClicked() {
      this.permGrpList.push({new_yn:true, select_yn:false, socty_sno:null, perm_grp_sno:null, perm_grp_nm:null, perm_grp_desc:null});
      this.permGrpChangedList.push(this.permGrpList[this.permGrpList.length - 1]);
    },
    // 퍼미션그룹 삭제
    deletePermGrpBtnClicked() {
      console.log("deletePermGrpBtnClicked");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.permGrpList.length; i++) {
      //   if(this.permGrpList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.permGrpList[i].new_yn) {
      //       deleteList.push(this.permGrpList[i]);
      //     }
      //   }
      // }
      deleteList = this.permGrpList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList:", e);
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deletePermGrpList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getPermGrpList();
            this.permGrpList = this.permGrpList.filter((e) => {
              return !(e.select_yn);
            });

            this.permGrpChangedList = this.permGrpChangedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getPermGrpList();
          this.permGrpList = this.permGrpList.filter((e) => {
            return !(e.select_yn);
          });

          this.permGrpChangedList = this.permGrpChangedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    // 메뉴 퍼미션
		getMenuPermList() {
      this.isPermSet = "";

      const params = new FormData();
      params.append('perm_grp_sno', this.permGrpSno);

			this.$http.post("/admin/getMenuPermList", params).then((res) => {
        console.log("getMenuPermList > res.data", res.data);
        // 기 설정된 퍼미션 정보가 없으면 전체 체크 기본값 설정
        if(res.data.menuPermList.every((e) => e.new_yn == 'Y')) {
          this.isPermSet = "(퍼미션 미설정)";
          res.data.menuPermList.forEach((e) => {
            e.get_perm_yn_scr = true;
            e.chg_perm_yn_scr = true;
          });
        } else { 
          res.data.menuPermList.forEach((e) => {
            e.get_perm_yn_scr = e.get_perm_yn == 'Y' ? true : false;
            e.chg_perm_yn_scr = e.chg_perm_yn == 'Y' ? true : false;
          });
        }

        this.menuPermList = res.data.menuPermList;
        this.menuPermChangedList = [];

        this.checkBoxchanged();

			}).catch(function (error) {
					console.log(error);
				}.bind(this)
			);
		},
    // 메뉴퍼미션 수정됨
    menuPermGridItemChanged(item) {
      // console.log("menuPermGridItemChanged:", item);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      // if(item.menu_sno == null || item.menu_sno == "") {
      //   return;
      // }

      let found = false;
      this.menuPermChangedList.forEach((e) => {
        if(e.menu_sno == item.menu_sno) {
          found = true;
        }
      });
      
      if(!found) {
        this.menuPermChangedList.push(item);
      }
      this.checkBoxchanged();
    },
		saveMenuPermBtnClicked() {         
      if(this.menuPermChangedList.length == 0) {
        alert("수정된 내용이 없습니다.");
        return;
      }

      if(confirm("저장하시겠습니까?")) {
        let params = new URLSearchParams();

        // true/false -> 'Y'/'N' 변환
        this.menuPermChangedList.forEach((e) => {
          e.perm_grp_sno = this.permGrpSno;
          e.get_perm_yn = e.get_perm_yn_scr == true ? 'Y' : 'N';
          e.chg_perm_yn = e.chg_perm_yn_scr == true ? 'Y' : 'N';
        });

        params.append("mber_sno", this.mberSno);
        params.append("changedList", JSON.stringify(this.menuPermChangedList));

        this.$http.post("/admin/upsertMenuPermList", params).then((res) => {
          console.log(res.data);
          alert("저장되었습니다.");
          this.getMenuPermList();
        }).catch(function (error) {
            console.log(error);
          }.bind(this)
        );
      }
		},
    menuPermGridItemClicked(event, item, permType) {
      // console.log("menuPermGridItemClicked:", event, item, item.menu_sno);
      if(event.ctrlKey == true) {
        // console.log("ctrlKey is true");
        this.menuPermList.forEach((e) => {
          if(e.upper_menu_sno == item.menu_sno) {
            // console.log("e:", e, e.upper_menu_sno);
            if(permType == 'get')       e.get_perm_yn_scr = item.get_perm_yn_scr;
            else if(permType == 'chg')  e.chg_perm_yn_scr = item.chg_perm_yn_scr;

            this.menuPermGridItemChanged(e);

            if(e.menu_lvl == 2) {
              this.menuPermList.forEach((f) => {
                if(f.upper_menu_sno == e.menu_sno) {
                  // console.log("f:", f, f.upper_menu_sno);
                  if(permType == 'get')       f.get_perm_yn_scr = e.get_perm_yn_scr;
                  else if(permType == 'chg')  f.chg_perm_yn_scr = e.chg_perm_yn_scr;

                  this.menuPermGridItemChanged(f);
                }
              });
            }
          }
        });
      }
    },
    viewableClicked() {
      const yn = this.viewableYn;

      this.menuPermList.forEach((e) => {
        if(e.get_perm_yn_scr != yn) {
          e.get_perm_yn_scr = yn;
          this.menuPermGridItemChanged(e);
        }
        
      })
      
    },//viewableClicked end
    changeableClicked() {
      const yn = this.changeableYn;
      this.menuPermList.forEach((e) => {
        if(e.chg_perm_yn_scr != yn) {
          e.chg_perm_yn_scr = yn;
          this.menuPermGridItemChanged(e);
        }
      })
    },//changeableClicked end
    checkBoxchanged() {
      if(this.menuPermList.filter((e) => e.get_perm_yn_scr).length == this.menuPermList.length) {
        this.viewableYn = true;
      }else {
        this.viewableYn = false;
      }
      if(this.menuPermList.filter((e) => e.chg_perm_yn_scr).length == this.menuPermList.length) {
        this.changeableYn = true;
      }else {
        this.changeableYn = false;
      }
    }//checkBoxchanged end
  },
}
</script>


<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 13px;
  padding: 2px 16px 0px 16px;  
  max-width: 100% !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td > .v-input {
  font-size: 13px;
  max-width: 100% !important;
}

/* 
/deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td > .v-input > .v-input__control > .v-input__slot {
  font-size: 13px;
  padding: 2px 16px 0px 16px; 
  height: 100%;
} 
} */

</style>
