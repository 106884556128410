<template>
  <v-container>
    <v-row class="mb-3" v-if="menuMap.outl">
      <v-col cols="12">
        <div class="tiny" v-html="menuMap.outl"></div>
      </v-col>
    </v-row>
    <v-row class="pa-0">
      <v-col cols="12">
        <v-list expand class="">
          <v-list-group
            v-for="(item, idx) in list"
            :key="`list_`+idx"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
            class="listGrid"
          >
            <template v-slot:activator>
              <v-list-item-content class="hei-70p pl-5">
                <v-list-item-title class="size_yy">{{ item.yy_nm }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, idx2) in item.children"
              :key="`child_`+idx2"
              no-action
              class="subList ml-3 mr-3"
            >
              <v-list-item-content class="hei-70p">
                <v-list-item-title class="subList pb-1 pt-1" >
                  <span  @click="subListClicked(child)" class="subListClick">{{child.book_ttl +` `+child.vol }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data(){
    return{
      groupOpend :false,
      items: [
        {
          title: 'Lv1 test1',
          items: [{ title: 'child item1' }],
        },
        {
          title: 'Lv1 test2',
          active: true,
          items: [
            { title: 'child item2' },
            { title: 'child item3' },
            { title: 'child item4' },
          ],
        },
        {
          items: [{ title: 'child item5' }],
          title: 'Lv1 test3',
        },
        {
          items: [{ title: 'child item6' }],
          title: 'Lv1 test4',
        },
        
      ],
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      lang: this.$session.get("lang") ? this.$session.get("lang") : '',
      emitData: {},
      menuMap: {},
      yearList: [],
      pubList: [],
      list: [],
      basicUri: '',
    }
  },
  created() {
    let path = this.$route.fullPath;
    this.basicUri = path;

    const params = new FormData();
    params.append("mber_sno", this.mberSno);
    params.append("non_mber_yn", this.nonMberYn);
    params.append("view_type", 'main');

    this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
      // console.log(res.data);
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push("/");
      } else {
          let menuMap = res.data.menuMap;

          if(this.lang == 'en' && menuMap) {
            menuMap.outl = menuMap.menu_outl_en; 
          } else if((this.lang == 'ko' || this.lang == '') && menuMap ) {
            menuMap.outl = menuMap.menu_outl; 
          }

          this.menuMap = menuMap;
          this.yearList = res.data.yearList;
          this.pubList = res.data.pubList;

          const list = this.yearList;

          list.forEach((e) => {
            e.active = false;
            e.children = [];
            e.yy_nm = e.yy+'년도';
            this.pubList.forEach((f) => {
              
              if(e.yy == f.yy) {
                e.children.push(f);
              }
            })
          })
          
          this.list = list;


          this.emitData.menu_sno = res.data.menu_sno;
          this.$emit('setData', this.emitData);
        
      }
    })
      .catch(function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    subListClicked(item) {
      console.log("hello",item);
      const yy = item.yy;
      const vol = item.vol;
      const ttl = item.book_ttl;
      this.$router.push(this.basicUri+'/list?yy='+yy+"&vol="+vol+"&ttl="+ttl);
    }
    
  }
}
</script>

<style scoped>

.size_yy {
  font-size: 20px;
  font-weight: bold;
}
.listGrid{
  border-top : 1px solid #A4A4A4;
  border-left: 1px solid #A4A4A4;
  border-right: 1px solid #A4A4A4;
}

.listGrid:first-child {
  border-top: 2px solid #000000;
}

.listGrid:last-child {
  border: 1px solid #A4A4A4;
}

.subListClick {
  cursor: pointer;
  width: fit-content !important;
}

.subListClick:hover {
  border-bottom: 0.5px solid #000000;
  
}

.subList {
  border-bottom: 1px solid #A4A4A4;
  border-width: 80% !important;
}
.subList:last-child {
  border-bottom: 0;
}

</style>