<template>
  <div>
    <v-container>
      <v-layout justify-center align-center>
        <v-flex style="max-width: 800px" >
          <v-card>
            <v-row class="pa-4">
              <v-col cols="12" class="list-20">
                <h3 style="text-align: left" class="mb-5">Test Input form 1</h3>
              </v-col>
              <v-col cols="12" class="list-20">
                <v-text-field label="test text field" required/>
              </v-col>	
              <v-col cols="12" class="list-20">
                <v-file-input
                  accept="image/*"
                  label="file input"
                ></v-file-input>
              </v-col>
              <v-col cols="12" class="list-20">
                <v-select
                  label="select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="list-20 mt-3 ">
                <div>테스트 라디오버튼</div>
              </v-col>
              <v-col cols="8" class="list-20">
                <v-radio-group
                  class="ma-0 pt-3 pb-3"
                  v-model="row"
                  row
                  hide-details
                >
                  <v-radio
                    label="Option 1"
                    value="radio-1"
                  ></v-radio>
                  <v-radio
                    label="Option 2"
                    value="radio-2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout justify-center align-center class="mt-10">
        <v-flex style="max-width: 800px" >
          <v-card>
            <v-row class="pa-4">
              <v-col cols="12" style="height:10px;">
                <h3 style="text-align: left" class="mb-5">test Input form 2</h3>
              </v-col>
              <v-col cols="6" class="list-20">
                <v-text-field label="test text field" required hide-details/>
              </v-col>	
              <v-col cols="6" class="list-20">
                <v-file-input
                  accept="image/*"
                  label="file input"
                  hide-details
                ></v-file-input>
              </v-col>
              <v-col cols="6" class="list-20">
                <v-select
                  label="select"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" class="list-20">
                <v-radio-group
										v-model="row"
										row
                    hide-details
									>
										<v-radio
											label="Option 1"
											value="radio-1"
										></v-radio>
										<v-radio
											label="Option 2"
											value="radio-2"
										></v-radio>
									</v-radio-group>
              </v-col>
              <v-col cols="4" class="list-20">
                <v-select
                  label="select"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="4" class="list-20">
                <v-select
                  label="select"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="4" class="list-20">
                <v-select
                  label="select"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="3" class="list-20">
                <v-text-field label="test text field" required hide-details/>
              </v-col>
              <v-col cols="3" class="list-20">
                <v-text-field label="test text field" required hide-details/>
              </v-col>
              <v-col cols="3" class="list-20">
                <v-text-field label="test text field" required hide-details/>
              </v-col>
              <v-col cols="3" class="list-20">
                <v-text-field label="test text field" required hide-details/>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout justify-center align-center class="mt-10">
        <v-flex style="max-width: 800px" >
          <v-card color="blue lighten-5">
            <v-row class="pa-4">
              <v-col cols="12" style="height:40px;">
                <h3 style="text-align: left" class="mb-5">test Input form 3</h3>
              </v-col>
              <v-col cols="6" class="list-20">
                <v-text-field label="test text field" required hide-details flat solo/>
              </v-col>	
              <v-col cols="6" class="list-20">
                <v-file-input
                  accept="image/*"
                  label="file input"
                  hide-details
                  flat 
                  solo
                ></v-file-input>
              </v-col>
              <v-col cols="6" class="list-20">
                <v-select
                  label="select"
                  hide-details
                  flat 
                  solo
                ></v-select>
              </v-col>
              <v-col cols="6" class="list-20">
                <v-radio-group
										v-model="row"
										row
                    hide-details
                    flat 
                    solo
									>
										<v-radio
											label="Option 1"
											value="radio-1"
										></v-radio>
										<v-radio
											label="Option 2"
											value="radio-2"
										></v-radio>
									</v-radio-group>
              </v-col>
              <v-col cols="4" class="list-20">
                <v-select
                  label="select"
                  hide-details
                  flat 
                  solo
                ></v-select>
              </v-col>
              <v-col cols="4" class="list-20">
                <v-select
                  label="select"
                  hide-details
                  flat 
                  solo
                ></v-select>
              </v-col>
              <v-col cols="4" class="list-20">
                <v-select
                  label="select"
                  hide-details
                  flat 
                  solo
                ></v-select>
              </v-col>
              <v-col cols="3" class="list-20">
                <v-text-field label="test text field" required hide-details flat solo/>
              </v-col>
              <v-col cols="3" class="list-20">
                <v-text-field label="test text field" required hide-details flat solo/>
              </v-col>
              <v-col cols="3" class="list-20">
                <v-text-field label="test text field" required hide-details flat solo/>
              </v-col>
              <v-col cols="3" class="list-20">
                <v-text-field label="test text field" required hide-details flat solo/>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>