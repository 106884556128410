<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="12">
        <v-row dense class="" align="center">
          <v-col cols="12">
            <div>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                :disabled="!isItemChanged"
                @click="saveNStayBtnClicked"
                >저장 후 유지
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

          <v-col cols="2" >
            <div class="pt-0">
              <span>연혁 유형</span>
            </div>
          </v-col>
          <v-col cols="2">
            <v-select
              class="wid-100 pt-0" 
              :items="soctyHistTypeList"
              item-value="cmn_cd"
              item-text="cmn_cd_nm"
              v-model="socty_hist_type"
              hide-details
              dense
              outlined
              @change="itemChanged"
            ></v-select>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        
        <v-row class="" align="center">
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addHistTermBtnClicked"
              >연혁 구간 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteHistTermListBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1"
              style="cursor: pointer"
              @click:row="rowClicked"
            >
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <!-- <template v-slot:[`item.term_nm`]="{item}">
                <v-text-field
                  v-model="item.term_nm"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="rowClicked(item)"
                ></v-text-field>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      gridHeader: [
        { text: '선택', value: 'select_yn', width: "10%", sortable:false },
        { text: '연혁 구간명', value: 'term_nm', width: "70%" },
        { text: '연혁 목록 수', value: 'hist_cnt', width: "10%" },
        { text: '표시 순서', value: 'disp_odr', width: "10%" },
      ],
      gridList: [],

      soctyHistTypeList: [],
      socty_hist_type: '',
      newSiteOptnYn: 'N',

      isItemChanged: false,
    }
  },
  created() {
    this.getSoctyHistTypeList();
  },
  mounted(){
    this.getHistTermList();
  },
  methods: {
    async getSoctyHistTypeList() {
      this.soctyHistTypeList = await this.$getCmnCdList("socty_hist_type");
    },
    getHistTermList() {
      const params = new FormData();

      this.$http.post("/admin/getSoctyHistTermList", params).then((res) => {
        console.log("res.data.result:", res.data.result);

        res.data.result.histTermList.forEach((e) => {
          e.select_yn = false;
        });

        this.gridList = res.data.result.histTermList;

        // 연혁 유형
        if(res.data.result.siteOptn) {
          this.socty_hist_type = res.data.result.siteOptn.socty_hist_type;
        } else {
          this.newSiteOptnYn = 'Y';
        }
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    rowClicked(event) {
      console.log("rowClicked", event);
      this.$router.push({name:"historyTermDetail", query: { termSno:event.term_sno, newYn:'N' }});
    },
    // 연혁구간 추가 버튼 클릭
    addHistTermBtnClicked() {
      this.$router.push({name:"historyTermDetail", query: { termSno:null, newYn:'Y' }});
    },
    deleteHistTermListBtnClicked() {
      console.log("deleteHistTermist");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: prod_sno[" + e.prod_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteSoctyHistTermList", params).then((res) => {
            console.log(res.data);

            if(res.data.code == 0) {
              alert("삭제되었습니다.");
              // this.getHistTermList();
              this.gridList = this.gridList.filter((e) => {
                return !(e.select_yn);
              });
            } else {
              alert("삭제할 수 없습니다.");
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    validateInput() {
      return true;
    },
    saveNStayBtnClicked() {
      this.saveSoctyHistType("stay");
      // console.log("out of save");
    },
    saveSoctyHistType(action) {
      // console.log("saveSoctyHistType:");

      // if(confirm("저장하시겠습니까?")) {
        const isValid = this.validateInput();

        if(isValid) {
          const params = new FormData();

          if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
          params.append('new_site_optn_yn', this.newSiteOptnYn);
          params.append("socty_hist_type", this.socty_hist_type);

          this.$http.post("/admin/upsertSoctyHistType", params).then((res) => {
            console.log(res.data);
            alert("저장되었습니다.");
            
            if(action == "stay") {
              this.getExctvGnrtnList();
            } 
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      // }
    },
    itemChanged() {
      this.isItemChanged = true;
    }
  }
}
</script>
  
<style>

</style>