<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="listPageClicked"
          >
            목록
          </v-btn>
        </div>
        <div style="float:right;">
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveBtnClicked(1)"
          >
            저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveBtnClicked(2)"
          >
            저장 후 유지
          </v-btn>
        </div>
      </v-col>
    </v-row>

    

    <v-row dense align="center">
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- start -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>이름</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.mber_nm"
          readonly
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      <!-- start -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>문서번호</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.doc_no"
          readonly
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      <!-- start -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>유형</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.type_nm"
          readonly
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      <!-- start -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>종류</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.doc_kind_nm"
          readonly
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>다운로드 파일</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            @change="itemChanged"
            accept=".pdf"
            v-model="issuanceFile"
          >
          </v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-show="issuDocMap.doc_atch_sno">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1">
            <span><a :href="issuDocMap.file_url" :download="issuDocMap.orgnl_file_nm">{{issuDocMap.orgnl_file_nm}}</a></span>
          </div>
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      <!-- start -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>파일명</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.file_nm"
          @change="itemChanged"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      <!-- start -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>승인상태</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          class="wid-100 pt-1" 
          hide-details
          outlined
          dense
          v-model="issuDocMap.doc_stus"
          :items="docStusList.filter((e) => e.cmn_cd != '00')"
          item-value="cmn_cd"
          item-text="cmn_cd_nm"
          @change="itemChanged"
        >
        </v-select>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      <!-- start -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>승인시간</span>
        </div>
      </v-col>
      <v-col cols="1">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.aprv_date"
          readonly
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.aprv_time"
          readonly
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      <!-- start -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>최종 다운로드 시간</span>
        </div>
      </v-col>
      <v-col cols="1">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.dnld_date"
          readonly
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="1">
        <v-text-field
          class="wid-100 pt-0"
          hide-details
          outlined
          v-model="issuDocMap.dnld_time"
          readonly
          background-color="grey lighten-2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
    </v-row>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      doc_no: this.$route.query.doc_no ? this.$route.query.doc_no : '',
      issuDocMap: {},
      docStusList: [],
      saveBtnEnabled: false,
      issuanceFile: null,
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
    }
  },
  created() {
    if(!this.doc_no) {
      alert('잘못된 접근입니다.');
      this.$router.push({name: "issuanceList"});
    }

    this.getIssuDocDetail();

  },
  methods: {
    listPageClicked() {
      this.$router.push({name: "issuanceList"});
    },
    itemChanged() {
      this.saveBtnEnabled = true;
    },//itemChanged end
    saveBtnClicked(type) {
      
      const params = new FormData();
      params.append('issuDocMap', JSON.stringify(this.issuDocMap));
      params.append('mber_sno', this.mberSno);
      if(this.issuanceFile != null) {
        params.append('issuanceFile', this.issuanceFile);
      }

      this.$http.post("/admin/updateIssuance", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        console.log("<<<<<:",res.data);
        alert("저장되었습니다.");
        if(type == 1) {
          this.$router.push({name: "issuanceList"});
        } else {
          this.saveBtnEnabled = false;
          this.$router.go(0);
        }
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },
    // saveNStayBtnClicked() {
    //   console.log("saveNStayBtnClicked");
      // this.saveBtnEnabled = false;
      // this.getIssuDocDetail();
    // },
    getIssuDocDetail() {
      const params = new FormData();
      params.append('doc_no', this.doc_no);

      this.$http.post("/admin/getIssuDocDetail",params).then((res) => {
        console.log(res.data);
        this.docStusList = res.data.docStusList;
        const issuDocMap = res.data.issuDocMap; 
        if(issuDocMap) {
          if(issuDocMap.aprv_dtm) {
            const dtArr = issuDocMap.aprv_dtm.split(' ');
            issuDocMap.aprv_date = dtArr[0];
            issuDocMap.aprv_time = dtArr[1];
          }
          if(issuDocMap.dnld_dtm) {
            const dtArr = issuDocMap.dnld_dtm.split(' ');
            issuDocMap.dnld_date = dtArr[0];
            issuDocMap.dnld_time = dtArr[1];
          }

          if(issuDocMap.orgnl_file_nm) {
            const split_file_nm = issuDocMap.orgnl_file_nm.split('.');
            issuDocMap.file_nm = split_file_nm[0];
            issuDocMap.ext = split_file_nm[1];
          }
        }
        this.issuDocMap = issuDocMap;
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//getIssuDocDetail end
  }
}
</script>

<!-- if(this.topMainLogoSelectedFile != null) {
  params.append('file_list', this.topMainLogoSelectedFile);
  file_info_list.push({item:"top_main_logo"});
}  -->

<style>

</style>