<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
    <div class="wid-100">
        <v-row dense >
          <v-col cols="12">
            <!-- <div class="f-r pl-1">
              <v-btn
                small
                color="blue lighten-2"
                class="white--text"
                @click="saveBtnClicked"
              >저장</v-btn>
            </div> -->
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div >
              <v-data-table
                :headers="headers"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination>
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.crtf_form`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.crtf_form"
                    :items="crtfKindList"
                    item-text="crtf_disp_nm"
                    item-value="crtf_form"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.jrnl_sno`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.jrnl_sno"
                    :items="jrnlList"
                    item-text="jrnl_nm"
                    item-value="jrnl_sno"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.role`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.role"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.hist_ref`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.hist_ref"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.bgn_dt`]="{ item }">
                  <v-text-field
                  class=""
                  v-model="item.bgn_dt"
                  hide-details
                  dense
                  outlined
                  append-icon="mdi-calendar"
                  @click:append="popupDatePicker(item, item.bgn_dt, 'bgn_dt')"
                  placeholder="0000-00-00"
                  ></v-text-field>
                  <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                </template>
                <template v-slot:[`item.end_dt`]="{ item }">
                  <v-text-field
                  class=""
                  v-model="item.end_dt"
                  hide-details
                  dense
                  outlined
                  append-icon="mdi-calendar"
                  @click:append="popupDatePicker(item, item.end_dt, 'end_dt')"
                  placeholder="0000-00-00"
                  ></v-text-field>
                  <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import datePickerPopup from '@/components/common/datePickerPopup.vue';
export default {
  components: { datePickerPopup },
  props: ["propParams"],
  data(){
    return {
      headers:[
        { text: '선택', value:'select_yn', width:"5%"},
        { text: '자동발급 증명서*', value:'crtf_form', width:"20%"},
        { text: '학술지', value:'jrnl_sno', width:"15%"},
        { text: '역할', value:'role', width:"10%"},
        { text: '시작일자', value:'bgn_dt', width:"15%"},
        { text: '종료일자', value:'end_dt', width:"15%"},
        { text: '이력참조', value:'hist_ref', width:"15%"},
      ],
      gridList: [],
      crtfFormList: [],
      changedList: [],    // 추가, 수정된 목록
      mberSno: "",
      loginMberSno: "",
      
      datePopup: false,
      curHist: null,
      curDate: "",
      dateNm: "", 
      
      jrnlList: [],
      crtfKindList: [],
    }
  },
  created() {
    this.mberSno = this.propParams.mberSno;
    this.loginMberSno = this.$session.get("mberSno");

    this.getCrtfFormList();
    this.getSoctyJrnlList();
    this.getMberHistList();
  },
  methods: {
    async getCrtfFormList() {
      this.crtfFormList = await this.$getCmnCdList("crtf_form");
    },
    // 학술지 목록 조회
    getSoctyJrnlList() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyJrnlList", params).then((res) => {
        console.log("/admin/getSoctyJrnlList res.data: ", res.data);
        this.jrnlList = res.data.result.jrnlList;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    // 경력 수정됨
    gridItemChanged(item) {
      this.itemChanged();
      // console.log("gridItemChanged : item.hist_sno : " + item.hist_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.hist_sno == null || item.hist_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.hist_sno == item.hist_sno) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }
    },
    getMberHistList() {
			const params = new FormData();
      params.append("mber_sno", this.mberSno);

			this.$http.post("/admin/getMberHistList", params).then((res) => {
        // console.log("gridList: " , res.data.result);
        this.gridList = res.data.result;
        this.crtfKindList = res.data.crtfKindList;
        this.gridList.forEach((e) => {
          e.select_yn = false;
          e.new_yn = false;
        });

        this.changedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    deleteBtnClicked() {
      console.log("deleteHist");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });


      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: hist_sno[" + e.hist_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();

          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteMberHistList", params).then((res) => {
            console.log(res.data);
            // this.getMberHistList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getMberHistList();
          this.gridList = this.gridList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;

      this.gridList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.gridList.push({new_yn:true, select_yn:false, hist_sno:"", mber_sno:this.mberSno, odr:maxOdr, crtf_form:"", jrnl_nm:"", role:"", 
                          bgn_dt:"", end_dt:"", hist_ref:"", use_yn:false});
      this.changedList.push(this.gridList[this.gridList.length - 1]);
      // 객체 복사가 필요하면 아래의 방법으로 해야 함
      //this.changedList.push({...this.gridList[this.gridList.length - 1]}); 
      // 객체 안에 또 객체가 있을 경우 아래의 방법으로 해야 함
      // JSON.parse(JSON.stringify(item))

      // 데이터 검증
      // this.changedList.forEach((e) => {
      //   console.log("changedList: hist_sno[" + e.hist_sno + "]");
      // });
    },
    saveBtnClicked() {
      console.log("saveHist");

      if(this.changedList.length == 0) {
        alert("수정된 내용이 없습니다.");
        return;
      }

      // true/false -> 'Y'/'N' 변환
      let valid = true;
      this.changedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
        e.use_yn = e.use_yn == true ? 'Y' : 'N';
        if(!e.crtf_form) {
          alert('자동발급 증명서 항목은 필수값입니다.');
          valid = false;
          return;
        }
      });

      if(!valid) {
        return;
      }
      // if(confirm("저장하시겠습니까?")) {
      let params = new URLSearchParams();

      params.append("login_mber_sno", this.loginMberSno);
      params.append("mber_sno", this.mberSno);
      params.append("changedList", JSON.stringify(this.changedList));

      this.$http.post("/admin/upsertMberHistList", params).then((res) => {
        console.log(res.data);
        alert("저장되었습니다.");
        this.getMberHistList();
      }).catch(function (error) {
          console.log(error);
      });
      // }
    },
    popupDatePicker(item, curDate, dateNm) {
      // console.log("popupDatePicker");
      // console.log("hist_sno: ", item.hist_sno);
      this.curHist = item;
      this.dateNm = dateNm;
      this.curDate = curDate;

      this.datePopup = true;
    },
    setSelectedDate(selected) {
      if(this.dateNm == "bgn_dt") {
        this.curHist.bgn_dt = selected;
      } else if(this.dateNm == "end_dt") {
        this.curHist.end_dt = selected;
      }
      
      this.gridItemChanged(this.curHist);
      this.datePopup = false;
    },
    itemChanged() {
      this.$emit('tabChanged', "memberHistoryTab");
    }
  }
}
</script>

<style>

</style>