<template>
  <v-container fluid class="pl-3 pr-3 pt-0">
    <v-row class="pa-0 pt-2">
      <v-spacer></v-spacer>
      <v-col cols="4" align="right" class="pa-0" v-show="windowWidth > 767">
        <v-btn
          small
          outlined
          class="elevation-0 bg-lightgrey font-weight-bold"
          @click="listPageClicked">
          목록
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col cols="12" class="pa-0 pt-1">
        <span class="fontSize-7 font-weight-bold " :class="windowWidth>768?'detailTitleWord':'mobDetailTitleWord'">{{gllyBbsMap.bbst_ttl}}</span>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px pa-0"></v-col>
    </v-row>

    <v-row class="mb-3  ">
      <v-col cols="12" md="4" sm="4" class="pt-2 pb-2">
        <span class="mr-2">등록인 :</span><span>{{gllyBbsMap.reg_mber_nm}}</span>
      </v-col>
      <v-col cols="12" md="5" sm="5" class="pt-2 pb-2">
        <span class="mr-2">등록일시 :</span><span>{{gllyBbsMap.reg_dtm}}</span>
      </v-col>
      <v-col cols="12" md="3" sm="3" class="pt-2 pb-2">
        <span class="mr-2">조회수 :</span><span>{{gllyBbsMap.read_cnt}}</span>
      </v-col>
      <div class="wid-100 d-flex border-b-grey"></div>
    </v-row>

    <v-row class="mb-3">
      <!-- <v-col cols="12" class="d-flex justify-center">
        <div>
          <h3><span>{{gllyBbsMap.bbst_ttl}}</span></h3>
        </div>
      </v-col> -->
      <v-col cols="12" class="d-flex justify-center">
        <div>
          <h3><span class="tiny" v-html="gllyBbsMap.bbst_cn"></span></h3>
        </div>
      </v-col>
      <v-col cols="12" v-if="imgList.length > 0">
        <v-carousel hide-delimiters v-model="imgOdr">
          <v-carousel-item
            v-for="(item, i) in imgList"
            :key="`imgList_`+i"
            :src="item.file_url_path">
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" class="d-flex justify-center" v-if="imgList.length > 0">
        <div>
          <span>{{img_desc}}</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="" v-if="imgList.length > 0">
      <v-col cols="12" class="border-b-grey pb-8 mb-5">
        <!-- <v-card class="" outlined>
          <v-slide-group
            v-model="model"
            class="pa-4 hei-200p"
            active-class="blue lighten-5"
            show-arrows>
            <v-slide-item
              class="pa-4"
              v-for="(item, i) in imgList"
              :key="`thumb_`+i"
              v-slot="{ active }"
            >
              <v-card
                :color="active ? undefined : 'grey lighten-3'"
                outlined
                class="ma-4"
                height="140"
                width="115"
                @click="thumbClicked(item.idx)"
              >
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-scale-transition >
                    <div>
                      <img class="image-default-size" :src="item.file_url_path">
                    </div>
                    <v-icon
                      v-if="active"
                      color="white"
                      size="48"
                    ></v-icon>
                  </v-scale-transition>
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card> -->

        <swiper 
          ref="filterSwiper" 
          :options="swiperOption" 
          role="tablist"
          v-model="model"
          class="pa-4"
          active-class="blue lighten-5"
          show-arrows>
          <swiper-slide
            class="pa-1"
            v-for="(item, i) in imgList"
            :key="`thumb_`+i"
          >
            <div class="pa-2">
              <img 
                class="image-default-size" 
                :src="item.file_url_path" 
                @click="thumbClicked(item.idx)"
              >
            </div>
          </swiper-slide>
        </swiper>

      </v-col>
    </v-row>

    <v-row class="pa-0">
      <v-col cols="12" class="pt-2">
        <div class="wid-100 d-flex justify-end">
          <v-btn 
            small
            outlined
            class="bg-lightgrey"
            @click="listPageClicked">
            목록
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'


export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      preUrl: '',
      basicUri: '',
      bbst_sno: this.$route.query.bbst_sno,
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      imgList: [],
      gllyBbsMap: {},
      model: null,
      img_desc: '',
      imgOdr: 0,
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 15, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
      },
      pageNo: this.$route.query.pageNo ? this.$route.query.pageNo : '',
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created(){
    let path = this.$route.path;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    this.preUrl = this.basicUri+'/list?pageNo='+this.pageNo;

    const params = new FormData();
    params.append("mber_sno", this.mberSno);
    params.append("bbst_sno", this.bbst_sno);
    params.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
      console.log(res.data);
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push(this.preUrl);
      } else {
        console.log(res.data);
        this.gllyBbsMap = res.data.gllyBbsMap;
        this.imgList = res.data.imgList;

        let idx = 0;
        this.imgList.forEach((e) => {
          e.idx = idx;
          idx++;
        })

        if(this.imgList.length > 0) {
          this.img_desc = this.imgList[0].img_desc;
        }
        
      }
      
    })
    .catch(function (error) {
        console.log(error);
      }
    );
  },
  methods:{
    listPageClicked() {
      this.$router.push(this.preUrl);
    },//listPageClicked end
    thumbClicked(idx) {
      this.imgOdr = idx;
      this.img_desc = this.imgList.filter((e) => e.idx == idx)[0].img_desc;
      
      // this.imgOdr = 2;
    },//thumbClicked end
  }
}
</script>
<style scoped>
.arrow-prev,
.arrow-next {
    position: relative;
    float:left;
    border:1px solid #000;
    width:100px;
    height:100px;
    margin-right:5px;
}

.arrow-prev::after {
    position: absolute;
    left: 35px; 
    top: 20px;
    content: '';
    width: 50px; /* 사이즈 */
    height: 50px; /* 사이즈 */
    border-top: 5px solid #000; /* 선 두께 */
    border-right: 5px solid #000; /* 선 두께 */
    transform: rotate(225deg); /* 각도 */
}

.arrow-next::after {
    position: absolute;
    left: 10px; 
    top: 20px; 
    content: '';
    width: 50px; /* 사이즈 */
    height: 50px; /* 사이즈 */
    border-top: 5px solid #000; /* 선 두께 */
    border-right: 5px solid #000; /* 선 두께 */
    transform: rotate(45deg); /* 각도 */
}

.detailTitleWord {
  display:inline-block; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  word-break: break-all;
  max-width:850px;
  }

  .mobDetailTitleWord {
  display:inline-block; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  word-break: break-all;
  max-width:440px;
  }
</style>