<template >
  <v-app class="bg-main">
    <header-a/>

    <v-container fluid class=" hei-150p d-flex align-end bgImg-cover " style="background-image:url('https://img.freepik.com/free-vector/unfocussed-winter-background-with-snowflakes_1393-271.jpg');">
      <template>
        <v-container class=" hei-130p d-flex align-end" >
          <v-row class="hei-130p">
            <v-col cols="2" class="d-flex justify-center align-center">
              <div class="pb-2">
                <span><h1>회원가입</h1></span>
              </div>
            </v-col>
            <v-col cols="10">
              <v-row class="hei-130p pa-0 pl-5" align="center">
                <v-col cols="4" class="d-felx align-center ">
                  <div class="pb-2">
                    
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <div class="pb-2 float-right">
                    <span>Home / 회원 / 회원가입</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>

    <v-container fluid class=" hei-90vh-auto d-flex bg-white">
      <template>
        <v-container class="hei-80vh-auto d-flex">
          <v-row class="ma-0">
            <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-10 pt-10">
                <div class="wid-100  d-flex justify-center">
                  <div class="wid-200p hei-130p ">
                    <img class="wid-200p hei-130p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
                  </div>
                </div>
                <v-card class="hei-1000p wid-100 bg-lightgrey-2 d-flex justify-center align-center" outlined>
                  <div class="wid-60">
                    <v-row align="center" dense class="">
                      <v-col cols="12" class="mb-3">
                        <h4>이메일</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>비밀번호</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          append-icon="mdi-eye"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>비밀번호 확인</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          append-icon="mdi-eye"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>이름</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>생년월일</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>직업</h4>
                        <v-select
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-select>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>관심분야</h4>
                        <v-row>
                          <v-col cols="3">
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="mt-3 mb-5 border-b-darkgrey"></v-col>

                      <v-col cols="12" class="">
                        <h4>이메일 수신</h4>
                        <v-radio-group
                          row
                          hide-details>
                          <v-radio
                            label="수신동의">
                          </v-radio>
                          <v-radio
                            label="수신거부">
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" class="mb-5">
                        <span class="caption">
                          학회, 학술대회 등 학회 소식을 메일로 보내드립니다.
                        </span>
                      </v-col>
                      <v-col cols="12" class="mb-5 border-b-darkgrey"></v-col>

                      <v-col cols="12" class="bg-grey mb-3">
                        <v-row dense>
                          <v-col cols="12">
                            <v-checkbox
                              label="전체 동의 합니다."
                              hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="12" class="d-flex">
                            <v-checkbox
                              label="학회 이용약관 동의(필수)"
                              hide-details>
                            </v-checkbox>
                            <v-spacer></v-spacer>
                            <v-btn
                              tile
                              outlined
                              small
                              class="bg-white ">
                              내용보기
                            </v-btn>
                          </v-col>
                          <v-col cols="12" class="d-flex">
                            <v-checkbox
                              label="개인정보 수집 및 이용 동의(필수)"
                              hide-details>
                            </v-checkbox>
                            <v-spacer></v-spacer>
                            <v-btn
                              tile
                              outlined
                              small
                              class="bg-white ">
                              내용보기
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="theme-color-1 white--text">
                          가입하기
                        </v-btn>
                      </v-col>
                      
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col>


            <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-10 pt-10">
                <v-card class="hei-500p wid-100 bg-lightgrey-2 d-flex justify-center align-center" outlined>
                  <div class="wid-60">
                    <v-row align="center" dense class="">
                      <v-col cols="12" class="mb-8 d-flex justify-center">
                        <div class="wid-100  d-flex justify-center">
                          <div class="wid-200p hei-130p ">
                            <img class="wid-200p hei-130p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center">
                        <span class="font-weight-bold">
                          원예학회 회원가입을 축하드립니다.
                        </span>
                      </v-col>
                      <v-col cols="12" class="mb-8 d-flex justify-center">
                        <span class="font-weight-bold">
                          본 사이트에서 제공하는 모든 서비스를 이용하실 수 있습니다.
                        </span>
                      </v-col>
                      
                      <v-col cols="6" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="theme-color-1 white--text">
                          로그인
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="theme-color-1 white--text">
                          메인 페이지로 이동
                        </v-btn>
                      </v-col>
                      
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col>




            <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-10 pt-10">
                <div class="wid-100  d-flex justify-center">
                  <div class="wid-200p hei-130p ">
                    <img class="wid-200p hei-130p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
                  </div>
                </div>
                <v-card class="hei-900p wid-100 bg-lightgrey-2 d-flex justify-center align-center" outlined>
                  <div class="wid-60">
                    <v-row align="center" dense class="">
                      <v-col cols="12" class="mb-3">
                        <h4>이메일</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>비밀번호</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          append-icon="mdi-eye"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>비밀번호 확인</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          append-icon="mdi-eye"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>이름</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>생년월일</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>직업</h4>
                        <v-select
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-select>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>관심분야</h4>
                        <v-row>
                          <v-col cols="3">
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                            <v-checkbox
                              label="test"
                              hide-details>
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="mt-3 mb-5 border-b-darkgrey"></v-col>

                      <v-col cols="12" class="">
                        <h4>이메일 수신</h4>
                        <v-radio-group
                          row
                          hide-details>
                          <v-radio
                            label="수신동의">
                          </v-radio>
                          <v-radio
                            label="수신거부">
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" class="mb-5">
                        <span class="caption">
                          학회, 학술대회 등 학회 소식을 메일로 보내드립니다.
                        </span>
                      </v-col>

                      <v-col cols="12" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="theme-color-1 white--text">
                          가입하기
                        </v-btn>
                      </v-col>
                      
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col>


          </v-row>
        </v-container>
      </template>
    </v-container>

    <main-footer/>
  </v-app>
</template>

<script>
import mainFooter from '@/components/main/footer/footer'
import headerA from '@/components/main/header/headerA.vue'

export default {
  name: 'App',
  components:{
    headerA, 
    mainFooter,
  },
  data(){
    return{
    }
  },

}
</script>

<style scoped>
.v-input .v-label{
  font-size:12px;
  font-weight: bold;
}
/* .v-input--selection-controls {
  margin-top : 0px;
} */
.v-text-field--enclosed .v-input__prepend-inner{
  margin-top: 10px;
}
.v-text-field--enclosed .v-input__append-inner{
  margin-top: 10px;
}
</style>