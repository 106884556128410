<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="goBack"
          >목록</v-btn>
        </div>
        <div style="float:right;">
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn 
            small 
            color="red lighten-2" 
            class="white--text f-r" 
            @click="deleteBtnClicked"
            v-if="newYn == 'N'"
            >상품 삭제
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense align="center">
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>상품 유형 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          class="wid-100 pt-1" 
          v-model="prod_type"
          :items="prodTypeList"
          item-text="cmn_cd_nm"
          item-value="cmn_cd"
          hide-details
          outlined
          dense
          @change="itemChanged"
        ></v-select>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>상품명 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="prod_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
          @change="itemChanged"
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>프론트 상품명</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="front_prod_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
          @change="itemChanged"
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>상품 금액 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <!-- <v-text-field
          v-model="prod_amt"
          class="wid-100 pt-0"
          hide-details
          outlined
          @change="itemChanged"
        ></v-text-field> -->
        <numeric-text-field v-model="prod_amt" @input="newYn == 'Y' ? itemChanged() : ''" :readonly="newYn != 'Y'" :bgColor="newYn != 'Y' ? 'grey lighten-2' : ''"></numeric-text-field>
      </v-col>
      <v-col cols="5"><span>* 상품 금액은 상품 등록 후 수정할 수 없습니다.</span></v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>통화 구분 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          class="wid-100 pt-1" 
          v-model="cur_div"
          :items="curDivList"
          item-text="cmn_cd_nm"
          item-value="cmn_cd"
          hide-details
          outlined
          dense
          @change="itemChanged"
        ></v-select>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>회원 종류</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          class="wid-100 pt-1" 
          v-model="mber_kind"
          :items="mberKindList"
          item-text="mber_kind_nm"
          item-value="mber_kind"
          hide-details
          outlined
          dense
          @change="itemChanged"
        ></v-select>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>상품 사용</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="use_yn"
          hide-details
          dense
          label="상품 사용함"
          @change="itemChanged"
        ></v-checkbox>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>상품 설명</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor v-model="prod_desc" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="prod_desc"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
    </v-row>  
  </v-container>  
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';
import numericTextField from '@/components/common/numericTextField.vue';

export default {
  components: { tinyEditor, numericTextField },
  data(){
    return{
      mberSno: "",

      newYn: this.$route.query.newYn,
      prodSno: this.$route.query.prodSno,

      prod_nm: "",
      front_prod_nm: "",
      prod_amt: "",
      cur_div: "",
      prod_type: "",
      mber_kind: "",
      use_yn: "",
      prod_desc: "",

      prodTypeList: [],
      curDivList: [],
      mberKindList: [],

      prodListInUse: [],
      isLoading: false,

      saveBtnEnabled: false,
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

    console.log("prodSno:", this.prodSno);

    this.getProdTypeList();
    this.getCurDivList();
    this.getMberKindList();

    this.getSoctyProdListInUse();

    if(this.newYn == 'N') {
      this.getSoctyProd();
    }
  },
  watch: {
    prod_type(/*newVal*/) {
      // console.log('prod_type changed : ', newVal);
      if(this.isLoading) {
        return;
      }
      
      if(this.prod_type && this.mber_kind) {
        this.checkIsUsedSameTypeProd();
      }
    },
    mber_kind(/*newVal*/) {
      // console.log('mber_kind changed : ', newVal);
      if(this.isLoading) {
        return;
      }

      if(this.mber_kind && this.prod_type) {
        this.checkIsUsedSameTypeProd();
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },//goBack end
    async getProdTypeList() {
      this.prodTypeList = await this.$getCmnCdList('prod_type');
      // console.log("prodTypeList:", this.prodTypeList);
    },
    async getCurDivList() {
      this.curDivList = await this.$getCmnCdList('cur_div');
      // console.log("curDivList:", this.curDivList);
    },
    getMberKindList() {
      this.$http.get("/admin/getSoctyMberKindList").then((res) => {        
        this.mberKindList = res.data.result.mberKindList;
      }).catch((error) => {
          console.log(error);
        }
      )
    },
    getSoctyProdListInUse() {
      const params = new FormData();

      params.append("use_yn", "Y");

			this.$http.post("/admin/getSoctyProdList", params).then((res) => {
        console.log("getSoctyProdListInUse > res.data: ", res.data);
        this.prodListInUse = res.data.result.prodList;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    getSoctyProd() {
      // console.log("this.prodSno(in getSoctyProd):", this.prodSno);
      this.isLoading = true;

      const params = new FormData();
      params.append("prod_sno", this.prodSno);

			this.$http.post("/admin/getSoctyProd", params).then((res) => {
        // console.log("res.data: ", res.data);
        const prod = res.data.result.prod;

        this.prod_nm = prod.prod_nm;
        this.front_prod_nm = prod.front_prod_nm;
        this.prod_amt = prod.prod_amt;
        // this.$refs.prod_amt.setValue(prod.prod_amt);
        this.cur_div = prod.cur_div;
        this.prod_type = prod.prod_type;
        this.mber_kind = prod.mber_kind;
        this.use_yn = prod.use_yn == 'Y' ? true : false;
        this.prod_desc = prod.prod_desc;

        this.isLoading = false;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 상품 유형
      if(this.prod_type == null || this.prod_type == "") {
        alert("상품 유형은 필수 입력값입니다.");
        return false;
      }
      // 상품명
      if(this.prod_nm == null || this.prod_nm == "") {
        alert('상품명은 필수 입력값입니다.');
        return false;
      }
      // 상품 금액
      // this.prod_amt = this.$refs.prod_amt.getValue();
      if(this.prod_amt == null || this.prod_amt == "") {
        alert('상품 금액은 필수 입력값입니다.');
        return false;
      }
      if(!this.$includeHangle(this.prod_amt) || !this.$includeAlphaChar(this.prod_amt)) {
        alert("상품 금액은 숫자만 입력 가능합니다.");
        return false;
      }
      // 통화 구분
      if(this.cur_div == null || this.cur_div == "") {
        alert('통화 구분은 필수 입력값입니다.');
        return false;
      }
      // 같은 상품 유형 & 회원 종류
      if(this.checkIsUsedSameTypeProd()) {
        return false;
      }

      return true;
    },
    saveNStayBtnClicked() {
      this.saveSoctyProd("stay");
      // console.log("out of save");
    },
    saveNMoveBtnClicked() {
      this.saveSoctyProd("move");
      // console.log("out of save");
    },
    saveSoctyProd(action) {
      // console.log("saveSoctyProd");
      const isValid = this.validateInput();

      if(isValid && confirm("저장하시겠습니까?")) { 
        // if(isValid) {
          const params = new FormData();

          params.append("mber_sno", this.mberSno);
          params.append("prod_sno", this.prodSno);
          params.append('new_yn', this.newYn);

          if(this.prod_nm != null)        params.append("prod_nm", this.prod_nm);
          if(this.front_prod_nm != null)  params.append("front_prod_nm", this.front_prod_nm);
          if(this.prod_amt != null && this.prod_amt != "")       params.append("prod_amt", this.prod_amt);
          if(this.cur_div != null)        params.append("cur_div", this.cur_div);        
          if(this.prod_type != null)      params.append("prod_type", this.prod_type);
          if(this.mber_kind != null)      params.append("mber_kind", this.mber_kind);
          if(this.use_yn != null)         params.append("use_yn", this.use_yn ? 'Y' : 'N');
          if(this.prod_desc != null)      params.append("prod_desc", this.prod_desc);

          this.$http.post("/admin/upsertSoctyProd", params).then((res) => {
            console.log(res.data);
            alert("저장되었습니다.");

            this.saveBtnEnabled = false;
            
            if(this.newYn == 'Y') {
              this.prodSno = res.data.prod_sno;
              this.newYn = 'N';
              // console.log("this.prodSno(in save):", this.prodSno);
            }

            // console.log("in save: after alert 1");
            if(action == "stay") {
              this.getSoctyProd();
            } else if(action == "move") {
              this.$router.push({path:"productList"})
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        // }
      }
    },
    deleteBtnClicked() {
      console.log("deleteSoctyProd");

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("prod_sno", this.prodSno);

        this.$http.post("/admin/deleteSoctyProd", params).then((res) => {
          console.log(res.data);

          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.$router.push({path:"productList"});
          } else if(res.data.code == -9003) {
            alert("삭제할 수 없습니다. 상품이 사용 중입니다.");
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    itemChanged() {
      this.saveBtnEnabled = true;
    },
    checkIsUsedSameTypeProd() {
      if(this.prod_type && this.mber_kind) {
        if(this.prodListInUse.filter(e => {
          return e.prod_sno != this.prodSno && e.prod_type == this.prod_type && e.mber_kind == this.mber_kind && this.use_yn;
        }).length > 0) {
          alert("동일한 상품유형과 회원종류의 상품이 사용 중입니다.");
          return true;
        }
      }

      return false;
    }
  },
}
</script>

<style>

</style>