<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center">
          <v-col cols="3">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              outlined
              dense
              hide-details
              placeholder="검색어"
              background-color="white"
              v-model="searchWord"
              @keyup.enter="searchBtnClicked"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text"
              @click="searchBtnClicked"
            >
              검색
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="mainHeader"
              class="elevation-1"
              :items-per-page="20"
              style="cursor: pointer"
              :items="issuDocList"
              :search="search"
            >
            
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  class="pa-0 ma-0 wid-40p"
                  hide-details
                  dense
                  v-model="item.select_yn"
                >
                </v-checkbox>
              </template>

              <template v-slot:[`item.orgnl_file_nm`]="{ item }">
                <div @click="rowClicked(item)">{{ item.orgnl_file_nm}}</div>
              </template>

              <template v-slot:[`item.doc_no`]="{ item }">
                <div @click="rowClicked(item)">{{ item.doc_no}}</div>
              </template>

              <template v-slot:[`item.doc_kind_nm`]="{ item }">
                <div @click="rowClicked(item)">{{ item.doc_kind_nm}}</div>
              </template>

              <template v-slot:[`item.mber_nm`]="{ item }">
                <div @click="rowClicked(item)">{{ item.mber_nm}}</div>
              </template>

              <template v-slot:[`item.con`]="{ item }">
                <div @click="rowClicked(item)">{{ item.con}}</div>
              </template>

              <template v-slot:[`item.doc_stus_nm`]="{ item }">
                <div @click="rowClicked(item)">{{ item.doc_stus_nm}}</div>
              </template>

              <template v-slot:[`item.reg_dt`]="{ item }">
                <div @click="rowClicked(item)">{{ item.reg_dt}}</div>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group
                v-model="kindFilterVal"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>종류로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="all"
                >
                </v-radio>
                <v-radio
                  v-for="(item, index) in docKindList"
                  :key="`kind_`+index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group
                v-model="stusFilterVal"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>승인 상태로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="all"
                >
                </v-radio>
                <v-radio
                  v-for="(item, index) in docStusList"
                  :key="`aprv_`+index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group
                v-model="dateFilterVal"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>생성일시로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="all">
                </v-radio>
                <v-radio
                  label="오늘"
                  value="today">
                </v-radio>
                <v-radio
                  label="지난 7일"
                  value="past7year">
                </v-radio>
                <v-radio
                  label="이번달"
                  value="month">
                </v-radio>
                <v-radio
                  label="이번해"
                  value="year">
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

		
	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      mainHeader: [
        { text: '선택', value: 'select_yn',},
        { text: '파일명', value: 'orgnl_file_nm',},
        { text: '문서번호', value: 'doc_no',},
        { text: '종류', value: 'doc_kind_nm',},
        { text: '회원명', value: 'mber_nm',},
        { text: '발급내용', value: 'con',},
        { text: '승인상태', value: 'doc_stus_nm',},
        { text: '생성일시', value: 'reg_dt',},
      ],
      issuDocList: [],
      pendIssuDocList: [],
      searchWord: '',	  // 검색어 내용
      search: '',	      // 검색어버튼 클릭시 저장되는곳
      docKindList: [],  //종류 필터 리스트
      docStusList: [],  //승인 상태 필터 리스트
      dateFilterVal: '',
      stusFilterVal: '',
      kindFilterVal: '',
      preDate: '',
      nowDate: '',
      now_year: '',
      now_month: '',
    }
  },
  created() {
    this.kindFilterVal = 'all';
    this.stusFilterVal = 'all';
    this.dateFilterVal = 'all';

    this.getDocKindList();
    this.getDocStusList();
    this.getIssuDocList();

    const today = new Date();
    let year = today.getFullYear(); // 2023
    this.now_year = year;
    let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 06
    
    let day = today.getDate().toString().padStart(2, '0'); // 18
    this.nowDate = year + '-' + month + '-' + day; // 2023-06-18
    this.now_month = year + '-' + month;
    // alert(this.month);

    let preDate = new Date(this.nowDate);
    preDate.setDate(preDate.getDate() - 10);
    
    year = preDate.getFullYear(); // 2023
    month = (preDate.getMonth() + 1).toString().padStart(2, '0'); // 06
    day = preDate.getDate().toString().padStart(2, '0'); // 18
    this.preDate = year + '-' + month + '-' + day; // 2023-06-18

  },
  mounted(){
    
  },
  methods: {
    async getDocKindList() {
      this.docKindList = await this.$getCmnCdList("doc_kind");
    },
    async getDocStusList() {
      this.docStusList = await this.$getCmnCdList("doc_stus");
    },
    getIssuDocList() {
      this.$http.get("/admin/getIssuDocList").then((res) => {
        console.log(res.data);
        this.issuDocList = res.data.issuDocList;
        const pendIssuDocList = res.data.issuDocList;

        pendIssuDocList.forEach((e) => {
          const year = e.reg_dt.substr(0, 4);
          const today = e.reg_dt.substr(0, 10);
          const month = e.reg_dt.substr(0, 7);
          e.year = year;
          e.today = today;
          e.month = month;
        });
        console.log(pendIssuDocList);
        this.pendIssuDocList = pendIssuDocList;

      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },
    rowClicked(item) {
      this.$router.push({name:"issuanceDetail", query: { doc_no: item.doc_no }});
    },//rowClicked end
    searchBtnClicked() {
      this.search = this.searchWord;
    },//searchBtnClicked end
    radioFilterChanged() {
      this.filterList();
    },
    filterList() {
      this.issuDocList = this.pendIssuDocList.filter((e) => {
        if( (this.kindFilterVal == "all" || e.doc_kind == this.kindFilterVal) &&
            (this.stusFilterVal == "all" || e.doc_stus == this.stusFilterVal) &&
            (this.dateFilterVal == "all" 
            || this.dateFilterVal == "today" && e.today == this.nowDate 
            || this.dateFilterVal == "year" && e.year == this.now_year 
            || this.dateFilterVal == "past7year" && e.today <= this.nowDate && e.today >= this.preDate 
            || this.dateFilterVal == "month" && e.month == this.now_month
            ) 
          ) return true;
      });
    },//filterList end
  }
}
</script>
  
<style>
/* .v-text-field input[readonly="readonly"] {
    background-color: yellowgreen
} */
</style>