<template>
    <div></div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    created() {
        const fullPath = this.$route.fullPath;
        this.$http.get(fullPath).then((res) => { 
            console.log(res.data)
        
        }).catch(function (error) {
                console.log(error);
            }
        );
    }
}
</script>