<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense >
          <v-col cols="12">
            <!-- <div class="f-r pl-1">
              <v-btn
                small
                color="blue lighten-2"
                class="white--text"
                @click="saveBtnClicked"
              >
                저장
              </v-btn>
            </div> -->
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >
                추가
              </v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >
                선택 삭제
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div>
              <v-data-table
                :headers="gridHeaders"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination>
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.prod_sno`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.prod_sno"
                    :items="prodSelList"
                    item-text="prod_nm"
                    item-value="prod_sno"
                    hide-details
                    outlined
                    dense
                    @change="prodChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.ptcpr_nm`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.ptcpr_nm"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.ptcpr_blng`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.ptcpr_blng"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.ptcpr_email`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.ptcpr_email"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.ptcp_amt`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.ptcp_amt"
                    hide-details
                    readonly
                    background-color="grey lighten-2"
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ["propParams"],
  data(){
    return {
      mberSno: null,
      confrSno: null,
      preRegSno: null,
      // newYn: 'N',

      gridHeaders:[
        { text: '선택', value:'select_yn', width:"5%"},
        { text: '사전등록상품 *', value:'prod_sno', width:"25%"},
        { text: '이름 *', value:'ptcpr_nm', width:"15%"},
        { text: '소속 *', value:'ptcpr_blng', width:"10%"},
        { text: '이메일 *', value:'ptcpr_email', width:"15%"},
        { text: '참가비', value:'ptcp_amt', width:"10%"},
      ],
      prodSelList: [],
      gridList: [],
      changedList: [],
    }
  },
  created() {
    this.mberSno = this.propParams.mberSno;
    this.confrSno = this.propParams.confrSno; 
    this.preRegSno = this.propParams.preRegSno; 

    // this.getConfrProdSelList();
    this.getConfrPreRegPtcprList();
  },
  methods: {
    // getConfrPreRegProdList() {
    //   const params = new FormData();
    //   params.append("confr_sno", this.confrSno);

		// 	this.$http.post("/admin/getConfrProdSelList", params).then((res) => {
    //     this.preRegProdList = res.data.result.preRegProdList;
		// 	}).catch(function (error) {
		// 			console.log(error);
		// 		}
		// 	);
    // },
    // 그리드 수정됨
    gridItemChanged(item) {
      console.log("gridItemChanged : item.pre_reg_sno : " + item.pre_reg_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.pre_reg_sno == null || item.pre_reg_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.pre_reg_sno == item.pre_reg_sno && e.odr == item.odr) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }

      this.itemChanged();
    },
    getConfrPreRegPtcprList() {
      const params = new FormData();
      params.append("confr_sno", this.confrSno);
      params.append("pre_reg_sno", this.preRegSno);
      params.append("prod_type", 'confr');

			this.$http.post("/admin/getConfrPreRegPtcprList", params).then((res) => {
        console.log("res.data: ", res.data);
        this.prodSelList = res.data.result.prodSelList;
        this.gridList = res.data.result.preRegPtcprList;
        this.changedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    deleteBtnClicked() {
      console.log("deleteConfrPreRegPtcpr");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: pre_reg_sno[" + e.pre_reg_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteConfrPreRegPtcprList", params).then((res) => {
            console.log(res.data);
            // this.getConfrPreRegPtcprList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getConfrPreRegPtcprList();
          this.gridList = this.gridList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;

      this.gridList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.gridList.push({new_yn:true, select_yn:false, odr:maxOdr, pre_reg_sno:this.preRegSno, prod_sno:"", ptcpr_nm:null, ptcpr_blng:null, ptcpr_email:null, prod_amt:null});
      this.changedList.push(this.gridList[this.gridList.length - 1]);
    },
    validateInput() {
      for(let i = 0; i < this.changedList.length; i++) {
        let e = this.changedList[i];
        // console.log("e:", e);

        // 사전등록 상품
        if(e.prod_sno == null || this.prod_sno == "") {
          alert("사전등록 상품은 필수 입력값입니다.");
          return false;
        }
        // 이름
        if(e.ptcpr_nm == null || e.ptcpr_nm == "") {
          alert('이름은 필수 입력값입니다.');
          return false;
        }
        // 소속
        if(e.ptcpr_blng == null || e.ptcpr_blng == "") {
          alert('소속은 필수 입력값입니다.');
          return false;
        }
        // 이메일
        if(e.ptcpr_email == null || e.ptcpr_email == "") {
          alert('이메일은 필수 입력값입니다.');
          return false;
        }
        if(!this.$validateEmail(e.ptcpr_email)) {
          alert('이메일 형식이 아닙니다.');
          return false;
        }
      }

      return true;
    },
    saveBtnClicked() {
      console.log("saveConfrPreRegPtcpr");

      if(this.changedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      // true/false -> 'Y'/'N' 변환
      this.changedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
      });

      // if(confirm("저장하시겠습니까?")) {
        let params = new URLSearchParams();

        params.append("changedList", JSON.stringify(this.changedList));

        this.$http.post("/admin/upsertConfrPreRegPtcprList", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          this.getConfrPreRegPtcprList();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'preRegistParticipantTab');
    },
    prodChanged(item) {
      console.log("prodChanged : ", item);
      item.ptcp_amt = this.prodSelList.filter((e) => e.prod_sno == item.prod_sno)[0].prod_amt;

      this.itemChanged();
    },
  }
}
</script>

<style>

</style>