<template>
  <v-container fluid class="pa-3" style="margin-bottom:300px;">
    <v-row>
      <v-col cols="3">
        <h1>질문하기</h1>
      </v-col>
      <v-col cols="9" align="right">
        <v-btn
          outlined
          class="elevation-0 bg-lightgrey font-weight-bold"
          @click="listPageClicked"
        >
          목록
        </v-btn>
      </v-col>
    </v-row>
    <v-col cols="12" class="" style="border-bottom: 2px solid #585858;"></v-col>
    <v-col cols="12" class="pl-0">
      <!-- <span><v-icon class="mr-2" color="#B43104">mdi-tooltip-check</v-icon><span>글쓰기 할 때 주의할 내용이 있으면 이 곳에 내용이 들어갑니다.</span></span> -->
    </v-col>
    <v-row class="mb-3">
      <v-col cols="6">
        <v-row dense align="center" v-if="cateList.length > 0">
          <v-col cols="3">카테고리</v-col>
          <v-col cols="6">
            <v-select 
              class="pa-0 ma-0 wid-100"
              hide-details
              outlined
              dense
              v-model="bbsMap.bbst_catg"
              :items="cateList"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>     
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field 
          class="f-l pa-0 ma-0 wid-100 input-hei-d bg-lightblue" 
          outlined
          dense
          hide-details
          placeholder="제목 *"
          v-model="bbsMap.bbst_ttl"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <!-- <v-row v-if="!mberSno">
      <v-col cols="12">
        <v-text-field 
          class="f-l pa-0 ma-0 wid-100 input-hei-d bg-lightblue" 
          outlined
          dense
          hide-details
          placeholder="이름 *">
        </v-text-field>
      </v-col>
    </v-row> -->
    
    <v-row class="mb-3"> 
      <v-col cols="12">
        <tiny-editor box_height="600" ref="tiny"></tiny-editor>
      </v-col>
    </v-row>
    <v-row v-if="type == 'write' || con_type == 'main'"> 
      <v-col cols="12">
        <v-file-input
          outlined
          hide-details
          dense
          multiple
          v-model="atchFile"
          @change="fileListChanged($event)"
        >
        </v-file-input>
      </v-col>
    </v-row>

    <v-row align="center" v-for="(item,idx) in fileList" :key="`atch_`+idx" > 
      <v-col cols="auto">
        {{item.file.name}}
      </v-col>
      <v-col cols="auto">
        <v-btn
          small
          :color="item.newYn == 'Y' ? 'blue lighten-2' :'red lighten-2'"
          class="white--text mr-5"
          @click="atchDelClicked(item)"
        >X</v-btn>
      </v-col>
    </v-row>

    <v-row align="center"> 
      <v-col cols="6">
        <v-btn
          class="white--text wid-100 elevation-0 theme-color-1 font-weight-bold"
          @click="upsertQnaBbs"
          v-if="type == 'write'"
        >
          글 올리기
        </v-btn>
        <v-btn
          class="white--text wid-100 elevation-0 theme-color-1 font-weight-bold"
          v-if="type == 'modify'"
          @click="modifyQnaBbs"
        >
          수정하기
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          class="wid-100 elevation-0 bg-grey-2 white--text font-weight-bold"
          @click="listPageClicked"
        >
          취소
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';
export default {
    components: {
      tinyEditor
    },
    data() {
      return {
        preUrl: '',
        type: '',
        mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
        nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
        menu_sno: this.$route.query.menu_sno,
        cateList: [],
        bbsMap: {
          bbst_ttl: '',
          reg_nmber_nm: '',
          reg_nmber_email: '',
          bbst_cn: '',
        },
        atchFile: [],
        fileList: [],
        fileDataList: [],
        fileIdx: 0,
        file_atch_sno: '',
        isValid: true,
        con_type: this.$route.query.con_type ? this.$route.query.con_type : '',
        sno: this.$route.query.sno ? this.$route.query.sno : '',
        emitData: {},
      }
    },
    created() {
      let path = this.$route.path;
      let basicUri = path.substring(path.lastIndexOf('/'), -1);
      this.preUrl = basicUri+'/list';

      this.type = path.substring(path.lastIndexOf('/')+1, path.length);
      // if(!this.menu_sno || this.nonMberYn != 'N') {
      //   alert('잘못된 접근입니다.');
      //   this.$router.push(this.preUrl);
      // }

      if(this.type == 'write') {
        if(!this.menu_sno || this.nonMberYn != 'N') {
          alert('잘못된 접근입니다.');
          this.$router.push(this.preUrl);
        }
      
        this.selectBoardDetail();
      } else if(this.type == 'modify'){
        if(!this.con_type || !this.sno) {
          alert('잘못된 접근입니다.');
          this.$router.push(this.preUrl);
        }
        this.selectBoardDetail();
      }
    },
    methods: {
      selectBoardDetail() {
        const params = new FormData();
        params.append("mber_sno", this.mberSno);
        params.append("non_mber_yn", this.nonMberYn);
        if(this.type == 'modify') {
          params.append("sno", this.sno);
          params.append("con_type", this.con_type);
        }

        this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
          console.log(res.data);
          // if(res.data.code < 0) {
          //   alert(this.$erroCdMsg(res.data.code));
          //   this.$router.push(this.preUrl);
          // } else {
            const chg_perm_yn = res.data.chg_perm_yn;
            if(chg_perm_yn != 'Y') {
              alert('등록 및 수정 권한이 없습니다.');
              this.$router.push(this.preUrl);
            }
            
          //   if(this.type == 'modify') {
          //     this.bbsMap = res.data.notiBbsMap;
          //     this.$refs.tiny.content = this.bbsMap.bbst_cn;
          //     this.file_atch_sno = res.data.file_atch_sno;

          //     const fileData = res.data.fileAtchList;
          //     fileData.forEach((e) => {
          //       this.fileList.push({file: {name: e.orgnl_file_nm}, newYn: 'N', idx: this.fileIdx++, atch_odr: e.atch_odr, filePath: e.save_path+e.save_file_nm});
          //     })

          //     this.cateList = this.bbsMap.menu_catg ? this.bbsMap.menu_catg.split(',') : [];

          //     if(!this.bbsMap.bbst_catg) {
          //       if(this.cateList.length > 0) {
          //         // this.defaultCate = this.cateList[0];
          //         this.bbsMap.bbst_catg = this.cateList[0];
          //       }
          //     }
          //   } else 
            if(this.type == 'write') {
              const menuDtlMap = res.data.menuDtlMap;
              this.cateList = menuDtlMap.bbst_catg ? menuDtlMap.bbst_catg.split(',') : [];

              if(menuDtlMap.bbst_catg) {
                if(this.cateList.length > 0) {
                  this.bbsMap.bbst_catg = this.cateList[0];
                }
              }
            } else if(this.type == 'modify') {
              this.bbsMap = res.data.qnaBbsMap;
              this.$refs.tiny.content = this.bbsMap.bbst_cn;

              if(this.con_type == 'main') {
                this.file_atch_sno = res.data.file_atch_sno;

                const fileData = res.data.fileAtchList;
                fileData.forEach((e) => {
                  this.fileList.push({file: {name: e.orgnl_file_nm}, newYn: 'N', idx: this.fileIdx++, atch_odr: e.atch_odr, filePath: e.save_path+e.save_file_nm});
                })
              }

              this.cateList = this.bbsMap.menu_catg ? this.bbsMap.menu_catg.split(',') : [];

              if(!this.bbsMap.bbst_catg) {
                if(this.cateList.length > 0) {
                  // this.defaultCate = this.cateList[0];
                  this.bbsMap.bbst_catg = this.cateList[0];
                }
              }
            }

          // }
            
          this.emitData.menu_sno = res.data.menu_sno;
          this.$emit('setData', this.emitData);
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      },//selectBoardDetail end
      listPageClicked() {
        this.$router.push(this.preUrl);
      },//listPageClicked end
      fileListChanged(event) {
        
        for(const a in event) {
          this.fileList.push({file: event[a], newYn: 'Y', idx: this.fileIdx++});
        }
        this.atchFile = [];

      },//fileListChanged end
      atchDelClicked(item) {
        if(confirm('삭제하시겠습니까?')) {
          console.log(item);
          if(item.newYn == 'Y') {
            this.fileList = this.fileList.filter((e) => e.idx != item.idx);
          } else {
            this.deleteAtchFile(item.atch_odr);
          }
        }
          
      },//atchDelClicked end
      validationCheck() {
        if(this.mberSno && this.nonMberYn == 'N') {
          if(this.bbsMap.bbst_ttl.trim() == '') {
            alert('제목 필수입니다.');
            this.isValid = false;
          } 
        } else {
          alert('비회원은 작성할 수 없습니다');
          this.isValid = false;
        }

      },//validationCheck end
      upsertQnaBbs() {
        this.validationCheck();

        if(this.isValid)
        {
          console.log(this.bbsMap);
          this.bbsMap.bbst_cn = this.$refs.tiny.content;

          let params = new FormData();
          this.bbsMap.bbst_sno = this.sno;
          this.fileList.filter((e) => e.newYn == 'Y').forEach((e) => {
            console.log(e.file);
            params.append('file_list', e.file);
          });
          params.append("bbsMap", JSON.stringify(this.bbsMap));
          params.append("mber_sno", this.mberSno);
          params.append("menu_sno", this.emitData.menu_sno);
          params.append("file_atch_sno", this.file_atch_sno);

          // if(this.type == 'modify') {
          //   params.append("bbst_sno", this.bbst_sno);
          // }

          this.$http.post("/bbs/upsertNotiBbs", params, {
            headers: 
            {
              'Content-Type': 'multipart/form-data'
            }
          }).then((res) => {

            if(res.data.code < 0) {
                alert(this.$erroCdMsg(res.data.code));
                this.$router.push(this.preUrl);  
            } else 
            {
                alert('저장되었습니다.');
                this.$router.push(this.preUrl);
            }
          })
          .catch(function (error) {
              console.log(error);
              }
          );
        }
          
          
      },//upsertNotiBbs end
      modifyQnaBbs() {
        if(this.con_type == 'main') {
          this.upsertQnaBbs();
        } else if(this.con_type == 'reply') {

          if(!this.bbsMap.bbst_ttl) {
            alert('답변 제목은 필수 입력값입니다.');
            return;
          } else if(!this.$refs.tiny.content) {
            alert('답변 내용은 필수 입력값입니다.');
            return;
          }

          const params = new FormData();

          const replyMap = {};
          replyMap.reply_sno  = this.sno;
          replyMap.mber_sno  = this.mberSno;
          replyMap.upper_reply_sno  = 0;
          replyMap.reply_cn = this.$refs.tiny.content;
          replyMap.reply_ttl = this.bbsMap.bbst_ttl;

          params.append("replyMap", JSON.stringify(replyMap));

          this.$http.post('/bbs/updateQnaReply', params).then((res) => {
            console.log(res.data);
            if(res.data.code < 0) {
              alert(this.$erroCdMsg(res.data.code));
              this.$router.push(this.preUrl);
            } else {
              alert('수정되었습니다.');
              this.$router.push(this.preUrl);
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      },//modifyQnaBbs end
      deleteAtchFile(atch_odr) {
        const params = new FormData();
        params.append("file_atch_sno",this.file_atch_sno);
        params.append("atch_odr", atch_odr);
        this.$http.post("/common/deleteAtchFile", params).then((res) => {
            if(res.data.code == 0) {
              alert('삭제되었습니다.');
              this.selectAtchFileList();
            }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      },//deleteAtchFile end
      selectAtchFileList() {
        const params = new FormData();
        params.append("file_atch_sno",this.file_atch_sno);
        this.$http.post("/common/selectAtchFileList", params).then((res) => {
            console.log(res.data);
            const fileData = res.data.atchFileList;
            this.fileList = [];
            fileData.forEach((e) => {
              this.fileList.push({file: {name: e.orgnl_file_nm}, newYn: 'N', idx: this.fileIdx++, atch_odr: e.atch_odr, filePath: e.save_path+e.save_file_nm});
              // this.file_atch_sno = 
            })
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      },//selectAtchFileList end

    }
}
</script>
