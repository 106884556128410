<template>
  <v-container>
    <v-text-field
      v-model="number"
      hide-details
      dense
      outlined
      :disabled="disabled" 
      :readonly="readonly"
      :background-color="bgColor != null ? bgColor : disabled ? 'grey lighten-2' : 'white'"
      @focus="onFocus($event)"
      @keyup.enter="$event.target.blur()"
      @blur="onBlur($event)" 
      @keyup="onKeyUp($event)"
    ></v-text-field>
  </v-container>
</template>

<script>
export default {
  props: ['value', 'disabled', 'readonly', 'bgColor', 'precision'],
  data() {
    return {
      showDialog: true,
      number: this.setNumberComma(this.value),
      prevNumber: null,
      isNumberMode: false,
      precision_: Number(this.precision || 0),
      // disabled: this.pDisabled == null ? 'N' : this.pDisabled,
      // readOnly: this.pReadOnly == null ? 'N' : this.pReadOnly,
      // bgColor: this.pBgColor == null ? "white" : this.pBgColor,
    };
  },
  created() {
    console.log("numericTextField created.", this.disabled, this.bgColor, this.precision);
    
  },
  watch: {
    value(newValue) {
      if(this.isNumberMode) {
        this.number = newValue;
      } else {
        this.number = this.setNumberComma(newValue);
      }
    },
  },
  methods: {
    onFocus(event) {
      // console.log("onFocus", event);
      this.enterNumberMode(event);
    }, 
    onBlur(event) {
      this.exitNumberMode(event);
    },
    onKeyUp(/*event*/) {
      this.$emit("input", this.number);
    },
    enterNumberMode(e) {
      console.log("enterNumberMode e:", e, this.number);
      if(this.number == null) {
        return;
      }

      this.prevNumber = this.number;
      this.isNumberMode = true;
      this.removeNumberComma(e);
    },
    exitNumberMode(/*e*/) {
      // console.log("exitNumberMode e:", e.target.value, this.number);
      // console.log(e.target.value === this.number);
      // console.log("exitNumberMode:", this.number, this.prevNumber);

      if(this.number == null) {
        return;
      }

      this.isNumberMode = false;
      // const precision_ = this.precision_;
      const regex = new RegExp('^[-]?\\d*(\\.?\\d{0,' + this.precision_ + '})$');   // -, 숫자, .(소수점) 허용. 소수점 자리수 변수 지정
      // const regex = /^[-]?\d*(\.?\d{0,2})$/;   // -, 숫자, .(소수점) 허용
      // const regex = /^[-]?\d*$/;   // -, 숫자 허용

      const numberType = this.precision_ > 1 ? `소수점을 포함하여 소수점 아래 ${this.precision_}자리까지의 숫자` : "정수";

      if(!regex.test(this.number)) {
        // console.log("false");
        alert(`${numberType}만 입력 가능합니다.`);
        this.number = this.prevNumber;
      } else {
        // console.log("true");
      }

      // 소수점 앞이 아닌 첫 자리 0 제거
      this.number = this.number.replace(/^0+(?!\.|\b)/, "");

      console.log("this.number:", this.number);
      // blur -> 부모 컴포넌트에 값(value) 전달
      this.$emit("input", this.number);

      // 콤마 설정
      let value = this.setNumberComma(this.number);
      // let value = e.target.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // console.log(value);

      this.number = value;
    },
    setNumberComma(value) {
      // console.log("setNumberComma called.", value);
      if(value == null) {
        return null;
      }

      // split(.) -> join
      let splitVal = String(value).split('.');
      splitVal[0] = splitVal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      if(splitVal[1] == "") {
        return splitVal[0];
      } else {
        return splitVal.join(".");
      }

      // return value.toLocaleString();   // 동작 안함

      // return String(value)
      //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","); // 소수점 뒤 콤마 제거 -> Safari 에서 SyntaxError 발생할 수 있음. 룩비하인드 패턴 지원 안함.
    },
    removeNumberComma(e) {
      console.log("removeNumberComma e:", this.isNumberMode, this.number, e);
      if (this.isNumberMode) {
        // let regex = /[^0-9]/g;
        // let value = e.target.value.replace(regex, "");
        // // console.log(value);
        // e.target.value = value;
        this.number = this.number.replaceAll(",", "");
      }
    },
  },
};
</script>

<style scoped>
.v-text-field /deep/ input{
  text-align: right !important;
 }
</style>