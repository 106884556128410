<template>
  <v-container fluid>
    <v-row class="ma-0 pa-0 mb-5">
      <v-col cols="12">
        <div class="wid-100">
          <img :src="ongoingConfrInfo.url_path" class="imgDf">
        </div>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <!-- <div style="float:left;">
          <v-btn
            color="grey lighten-2"
            class="mr-2 elevation-0"
            @click="movePage"
          >목록</v-btn>
        </div> -->
        <div style="float:right;">
          <v-btn
            color="red lighten-2"
            class="white--text elevation-0"
            v-if="abstrRegSno && ongoingConfrInfo.abstr_reg_psbl_yn == 'Y'"
            @click="abstrDeleteBtnClicked"
          >초록등록 삭제</v-btn>
        </div>
      </v-col>


      <v-col cols="12" class="pa-0">
        <div class="d-flex justify-center">
          <v-card class="hei-80vh-auto wid-100 bg-lightgrey-2 d-flex justify-center align-center pt-10 pb-10" outlined>
            <div class="wid-100 pl-5 pr-5">
              <!-----------------------[[[[[[[[[[[[            초록등록 정보             ]]]]]]]]]]]]------------------------->
              <v-container class="mb-10">
                <!-- <v-row class="mb-2">
                  <v-col cols="12">
                    <h1>초록등록 정보</h1>
                  </v-col>
                </v-row> -->
                <v-row align="center" dense class="wid-100">
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey mb-7">
                    <span class="fontSize-4 font-weight-bold">발표자</span>
                    <span class="fontSize-4 font-weight-bold"
                      style="color:red;" 
                      v-if="regItemSet.rqstr_email.ess_yn || regItemSet.rqstr_nm.ess_yn || regItemSet.rqstr_blng.ess_yn || regItemSet.rqstr_blng_en.ess_yn || 
                        regItemSet.rqstr_dept.ess_yn || regItemSet.rqstr_pstn.ess_yn || regItemSet.rqstr_cel_no.ess_yn">&nbsp;*</span>
                  </v-col>
                  <v-col cols="6" class="" v-if="regItemSet.rqstr_email.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">이메일</span><span style="color:red;" v-if="regItemSet.rqstr_email.ess_yn"> *</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_email"
                          readonly
                          background-color="grey lighten-2"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="" v-if="regItemSet.rqstr_nm.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">이름</span><span style="color:red;" v-if="regItemSet.rqstr_nm.ess_yn"> *</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_nm"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2': 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="" v-if="regItemSet.rqstr_blng.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">소속</span><span style="color:red;" v-if="regItemSet.rqstr_blng.ess_yn"> *</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_blng"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2': 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="" v-if="regItemSet.rqstr_blng_en.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">소속(영문)</span><span style="color:red;" v-if="regItemSet.rqstr_blng_en.ess_yn"> *</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_blng_en"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2': 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="" v-if="regItemSet.rqstr_dept.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">부서/학과</span><span style="color:red;" v-if="regItemSet.rqstr_dept.ess_yn"> *</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_dept"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2': 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="" v-if="regItemSet.rqstr_pstn.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">직위/학년</span><span style="color:red;" v-if="regItemSet.rqstr_pstn.ess_yn"> *</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_pstn"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2': 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="" v-if="regItemSet.rqstr_cel_no.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">휴대전화번호</span><span style="color:red;" v-if="regItemSet.rqstr_cel_no.ess_yn"> *</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.rqstr_cel_no"
                          :readonly="nonMberYn == 'N' || ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          :background-color="nonMberYn == 'N' ? 'grey lighten-2': 'white'"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="mberSno && nonMberYn == 'N'">
                    <div><span style="color:#DEA75C;">상기 정보는 회원 프로필 정보에서 가져옵니다.</span></div>
                    <div class="mb-7" style="color:#DEA75C;"><span>정보 변경 및 입력은 <a>마이페이지>프로필</a> 페이지에서 진행해 주시기 바랍니다.</span></div>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center border-b-darkgrey mb-7">
                    <span class="fontSize-4 font-weight-bold">발표방식/분야</span>
                    <span class="fontSize-4 font-weight-bold"
                      style="color:red;" 
                      v-if="regItemSet.pt_mthd.ess_yn || regItemSet.pt_field.ess_yn || regItemSet.pt_field_2nd.ess_yn">&nbsp;*</span>
                  </v-col>
                  <v-col cols="6" class="" v-if="regItemSet.pt_mthd.use_yn">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">발표 방식</span><span style="color:red;" v-if="regItemSet.pt_mthd.ess_yn"> *</span>
                        <v-select
                        class=""
                          background-color="white"
                          hide-details
                          outlined
                          :items="ptMthdList"
                          item-value="pt_mthd_no"
                          item-text="pt_mthd_nm"
                          v-model="reqParam.pt_mthd_no"
                          :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="" v-if="regItemSet.pt_field.use_yn">
                    <span class="font-weight-bold">발표 분야</span><span style="color:red;" v-if="regItemSet.pt_field.ess_yn"> *</span>
                    <v-select
                      class=""
                      background-color="white"
                      hide-details
                      outlined
                      :items="filteredPtFieldList"
                      item-value="pt_field_no"
                      item-text="pt_field_nm"
                      v-model="reqParam.pt_field_no"
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                      >
                    </v-select>
                  </v-col>
                  <v-col cols="12" class="" v-if="regItemSet.pt_field_2nd.use_yn">
                    <span class="font-weight-bold">발표 분야(2순위)</span><span style="color:red;" v-if="regItemSet.pt_field_2nd.ess_yn"> *</span>
                    <v-select
                      class=""
                      background-color="white"
                      hide-details
                      outlined
                      :items="filteredPtFieldList"
                      item-value="pt_field_no"
                      item-text="pt_field_nm"
                      v-model="reqParam.pt_field_no_2nd"
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                      >
                    </v-select>
                  </v-col>
                  <v-col>
                    <div class="mb-7"></div>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center border-b-darkgrey mb-7">
                    <span class="fontSize-4 font-weight-bold">언어/제목</span>
                    <span class="fontSize-4 font-weight-bold"
                      style="color:red;" 
                      v-if="regItemSet.abstr_lang.ess_yn || regItemSet.abstr_ttl.ess_yn || regItemSet.abstr_ttl_en.ess_yn">&nbsp;*</span>
                  </v-col>
                  <v-col cols="12" class="" v-if="regItemSet.abstr_lang.use_yn">
                    <span class="font-weight-bold">초록 언어</span><span style="color:red;" v-if="regItemSet.abstr_lang.ess_yn"> *</span>
                    <v-radio-group 
                      v-model="reqParam.abstr_lang"
                      row
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                    >
                      <v-radio
                        v-for="(item, index) in abstrLangList"
                        :key="index"
                        :label="item.cmn_cd_nm"
                        :value="item.cmn_cd"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="" v-if="regItemSet.abstr_ttl.use_yn && !isEnPtHnglImps">
                    <span class="font-weight-bold">초록 제목(국문)</span><span style="color:red;" v-if="regItemSet.abstr_ttl.ess_yn"> *</span>
                    <tiny-editor v-model="reqParam.abstr_ttl" box_height="120" :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"></tiny-editor>
                  </v-col>
                  <v-col cols="12" class="" v-if="regItemSet.abstr_ttl_en.use_yn">
                    <span class="font-weight-bold">초록 제목(영문)</span><span style="color:red;" v-if="regItemSet.abstr_ttl_en.ess_yn"> *</span>
                    <tiny-editor v-model="reqParam.abstr_ttl_en" box_height="120" :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"></tiny-editor>
                  </v-col>
                  <v-col>
                    <div class="mb-7"></div>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center border-b-darkgrey mb-7">
                    <span class="fontSize-4 font-weight-bold">초록</span>
                    <span class="fontSize-4 font-weight-bold" style="color:red;" v-if="regOptn.abstr_file_edtr_ess_yn == 'Y'">&nbsp;*</span>
                  </v-col>
                  <v-col cols="12" class="" v-if="regOptn.abstr_sbmt_mthd == 'file'">
                    <span class="font-weight-bold">파일 첨부</span><span style="color:red;" v-if="regOptn.abstr_file_edtr_ess_yn == 'Y'"> *</span>
                    <v-file-input
                      hide-details
                      dense
                      outlined
                      background-color="white"
                      v-model="reqParam.abstrFile"
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" class="" v-if="regOptn.abstr_sbmt_mthd == 'file' && reqParam.abstr_atch_sno">
                    <v-list dense class="pa-0">
                      <template>
                        <v-list-item class="pl-2 pr-1">
                          <v-row align="center" class="ma-0 pa-0">
                            <v-col cols="11" class="pa-0">
                              <span class="fontSize-1">{{ reqParam.abstr_orgnl_file_nm }}</span>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-end pa-0">
                              <v-btn icon tile @click="fileDelClicked" :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-col>
                  
                  <v-col cols="12" class="" v-if="regOptn.abstr_sbmt_mthd == 'edtr'">
                    <span class="font-weight-bold">초록 에디터</span><span style="color:red;" v-if="regOptn.abstr_file_edtr_ess_yn == 'Y'"> *</span>
                    <tiny-editor v-model="reqParam.abstr_edtr" :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"></tiny-editor>
                  </v-col>

                  <v-col v-if="regItemSet.abstr_use_agree.use_yn">
                    <span class="font-weight-bold">초록 활용 동의</span><span style="color:red;" v-if="regItemSet.abstr_use_agree.ess_yn"> *</span>
                    <v-checkbox
                      v-model="abstr_use_agree_yn"
                      hide-details
                      dense
                      label="초록 활용에 동의합니다."
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <!-- 발표정보 -->
                <v-row dense v-if="abstrRegSno != ''">
                  <v-col>
                    <div class="mb-7"></div>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey mb-7">
                    <span class="fontSize-4 font-weight-bold">발표 정보</span>
                  </v-col>

                  <v-col cols="5" class="">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">발표번호</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.pt_no"
                          readonly
                          background-color="grey lighten-2"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class=""></v-col>
                  <v-col cols="5" class="">
                    <v-row dense align="center">
                      <v-col cols="11">
                        <span class="font-weight-bold">발표일자</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.pt_dt"
                          readonly
                          background-color="grey lighten-2"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class=""></v-col>
                  <v-col cols="5" class="">
                    <v-row dense align="center">
                      <v-col cols="5">
                        <span class="font-weight-bold">발표시작 시각</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.pt_bgn_tm"
                          readonly
                          background-color="grey lighten-2"
                          >
                        </v-text-field>
                      </v-col>
                      <v-col cols="1" class="">
                        <div class="pt-0 pl-3">
                          <span>~</span>
                        </div>
                      </v-col>
                      <v-col cols="5">
                        <span class="font-weight-bold">발표종료 시각</span>
                        <v-text-field
                          hide-details
                          outlined
                          v-model="reqParam.pt_end_tm"
                          readonly
                          background-color="grey lighten-2"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <!-- 저자 정보 -->
                <v-row dense >
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey mb-7">
                    <span class="fontSize-4 font-weight-bold">저자 정보</span><span style="color:red;">&nbsp;*</span>
                  </v-col>
                </v-row>
                <!-- 저자소속 -->
                <v-row dense class="mt-3">
                  <v-col cols="2" class="">
                    <span class="font-weight-bold">저자소속</span><span style="color:red;">&nbsp;*</span>
                  </v-col>
                  <v-col cols="10">
                    <div class="f-r pl-1">
                      <v-btn
                        small
                        color="green lighten-2"
                        class="white--text elevation-0"
                        @click="addBelongBtnClicked"
                        v-if="ongoingConfrInfo.abstr_reg_psbl_yn == 'Y'"
                      >추가</v-btn>
                    </div>
                    <div class="f-r">
                      <v-btn
                        small
                        color="red lighten-2"
                        class="white--text elevation-0"
                        @click="deleteBelongBtnClicked"
                        v-if="ongoingConfrInfo.abstr_reg_psbl_yn == 'Y'"
                      >선택 삭제</v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" >
                    <div>
                      <v-data-table
                        :headers="belongGridHeaders"
                        :items="pendingBelongList"
                        class="elevation-0 dTable-1"
                        :items-per-page="20"
                        hide-default-footer
                        fixed-header
                        disable-pagination>
                        <template v-slot:[`item.select_yn`]="{ item }">
                          <v-checkbox
                            v-model="item.select_yn"
                            class="pa-0 ma-0"
                            hide-details
                            dense
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-checkbox>
                        </template>
                        <template v-slot:[`item.blng_no`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.blng_no"
                            hide-details
                            outlined
                            dense
                            @change="belongGridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.blng_nm`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.blng_nm"
                            hide-details
                            outlined
                            dense
                            @change="belongGridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.blng_nm_en`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.blng_nm_en"
                            hide-details
                            outlined
                            dense
                            @change="belongGridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
                <!-- 저자 -->
                <v-row dense class="mt-3">
                  <v-col cols="2" class="">
                    <span class="font-weight-bold">저자정보</span><span style="color:red;">&nbsp;*</span>
                  </v-col>
                  <v-col cols="10">
                    <div class="f-r pl-1">
                      <v-btn
                        small
                        color="green lighten-2"
                        class="white--text elevation-0"
                        @click="addBtnClicked"
                        v-if="ongoingConfrInfo.abstr_reg_psbl_yn == 'Y'"
                      >
                        추가
                      </v-btn>
                    </div>
                    <div class="f-r">
                      <v-btn
                        small
                        color="red lighten-2"
                        class="white--text elevation-0"
                        @click="deleteBtnClicked"
                        v-if="ongoingConfrInfo.abstr_reg_psbl_yn == 'Y'"
                      >
                        선택 삭제
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" >
                    <div>
                      <v-data-table
                        :headers="authorGridHeaders"
                        :items="pendingAuthorList"
                        class="elevation-0 dTable-1"
                        :items-per-page="20"
                        hide-default-footer
                        disable-pagination
                        >
                        <template v-slot:[`item.select_yn`]="{ item }">
                          <v-checkbox
                            v-model="item.select_yn"
                            class="pa-0 ma-0"
                            hide-details
                            dense
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-checkbox>
                        </template>
                        <template v-slot:[`item.athor_nm`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.athor_nm"
                            hide-details
                            outlined
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.athor_nm_en_frst`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.athor_nm_en_frst"
                            hide-details
                            outlined
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.athor_nm_en_last`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.athor_nm_en_last"
                            hide-details
                            outlined
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.athor_email`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.athor_email"
                            hide-details
                            outlined
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.athor_tel_no`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.athor_tel_no"
                            hide-details
                            outlined
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.athor_role`]="{ item }">
                          <v-select
                            class="pa-0 ma-0"
                            v-model="item.athor_role"
                            :items="athorRoleList"
                            item-text="cmn_cd_nm"
                            item-value="cmn_cd"
                            hide-details
                            outlined
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-select>
                        </template>
                        <template v-slot:[`item.ptr_yn`]="{ item }">
                          <v-checkbox
                            v-model="item.ptr_yn"
                            class="pa-0 ma-0"
                            hide-details
                            dense
                            @change="gridPtrYnChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-checkbox>
                        </template>
                        <template v-slot:[`item.corr_athor_yn`]="{ item }">
                          <v-checkbox
                            v-model="item.corr_athor_yn"
                            class="pa-0 ma-0"
                            hide-details
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-checkbox>
                        </template>
                        <template v-slot:[`item.disp_odr`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.disp_odr"
                            hide-details
                            outlined
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                        <template v-slot:[`item.blng_nos`]="{ item }">
                          <v-text-field
                            class="pa-0 ma-0"
                            v-model="item.blng_nos"
                            hide-details
                            outlined
                            dense
                            @change="gridItemChanged(item)"
                            :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                          ></v-text-field>
                        </template>
                      </v-data-table>
                    </div>
                    <div class="mt-2">* 소속번호는 '저자소속' 테이블에 등록한 소속번호를 콤마(,) 구분자를 이용하여 복수 개 입력 가능합니다.</div>
                  </v-col>
                </v-row>
                <!-- 발표상 -->
                <v-row dense v-if="regOptn.pt_awad_yn == 'Y'">
                  <v-col>
                    <div class="mb-7"></div>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey mb-7">
                    <span class="fontSize-4 font-weight-bold">{{ regOptn.pt_awad_nm }}</span>
                  </v-col>

                  <v-col cols="12" class="">
                    <span class="font-weight-bold">{{ regOptn.pt_awad_nm }}에 지원하시겠습니까?</span>
                    <v-radio-group 
                      v-model="reqParam.pt_awad_apply_yn"
                      row
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                      >
                      <v-radio
                        label="예"
                        value="Y"
                      ></v-radio>
                      <v-radio
                        label="아니오"
                        value="N"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <!-- 메모 -->
                <v-row dense v-if="regItemSet.rqstr_memo.use_yn">
                  <v-col>
                    <div class="mb-7"></div>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey mb-7">
                    <span class="fontSize-4 font-weight-bold">메모</span>
                    <span class="fontSize-4 font-weight-bold" style="color:red;" v-if="regItemSet.rqstr_memo.ess_yn">&nbsp;*</span>
                  </v-col>

                  <v-col cols="12" class="">
                    <v-textarea
                      v-model="reqParam.rqstr_memo"
                      class="wid-100 pt-0 bg-white"
                      hide-details
                      outlined
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!-- 저작권 규정 -->
                <v-row dense v-if="regItemSet.cprgt_rule_agree.use_yn">
                  <v-col>
                    <div class="mb-7"></div>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey">
                    <span class="fontSize-4 font-weight-bold">저작권 규정</span>
                    <span class="fontSize-4 font-weight-bold" style="color:red;" v-if="regItemSet.cprgt_rule_agree.ess_yn">&nbsp;*</span>
                  </v-col>
                  <v-col cols="12">
                    <div class="wid-100 hei-200p bg-white tiny" style="overflow:auto;" v-html="confrPolcy.cprgt_rule"></div>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-start">
                    <v-checkbox 
                      v-model="cprgt_rule_agree_yn"
                      hide-details 
                      class="" 
                      label="개인정보 처리방침에 동의합니다."
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                      >
                    </v-checkbox>
                  </v-col>

                </v-row>
                <!-- 개인정보 처리방침 -->
                <v-row dense v-if="regItemSet.prvcy_polcy_agree.use_yn">
                  <v-col>
                    <div class="mb-7"></div>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center border-b-darkgrey">
                    <span class="fontSize-4 font-weight-bold">개인정보 처리방침</span>
                    <span class="fontSize-4 font-weight-bold" style="color:red;" v-if="regItemSet.prvcy_polcy_agree.ess_yn">&nbsp;*</span>
                  </v-col>
                  <v-col cols="12">
                    <div class="wid-100 hei-200p bg-white tiny" style="overflow:auto;" v-html="confrPolcy.prvcy_polcy"></div>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-start">
                    <v-checkbox 
                      v-model="prvcy_polcy_agree_yn"
                      hide-details 
                      class="" 
                      label="개인정보 처리방침에 동의합니다."
                      :readonly="ongoingConfrInfo.abstr_reg_psbl_yn == 'N'"
                      >
                    </v-checkbox>
                  </v-col>
                  
                </v-row>
              </v-container>
              <v-container>
                <v-row class="mt-10">

                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn 
                      large 
                      class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" 
                      outlined 
                      @click="regBtnClicked"
                      v-if="!abstrRegSno && ongoingConfrInfo.abstr_reg_psbl_yn == 'Y'"
                      >등록하기
                    </v-btn>
                    <v-btn 
                      large 
                      class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" 
                      outlined 
                      @click="regBtnClicked"
                      v-if="abstrRegSno && ongoingConfrInfo.abstr_reg_psbl_yn == 'Y'"
                      >수정하기
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';

const genEmptyReqParam = ({
  rqst_mber_sno,
  rqstr_email,
  rqstr_nm = null,
  rqstr_blng = null,
  rqstr_blng_en = null,
  rqstr_dept = null,
  rqstr_pstn = null,
  rqstr_cel_no = null
}) => ({
  rqst_mber_sno: rqst_mber_sno,
  rqstr_email: rqstr_email,
  rqstr_nm: rqstr_nm,
  rqstr_blng: rqstr_blng,
  rqstr_blng_en: rqstr_blng_en,
  rqstr_dept: rqstr_dept,
  rqstr_pstn: rqstr_pstn,
  rqstr_cel_no: rqstr_cel_no,
  pt_mthd_no: null,
  pt_field_no: null,
  pt_field_no_2nd: null,
  abstr_lang: "ko",
  abstr_atch_sno: null,
  abstr_orgnl_file_nm: null,
  abstr_use_agree_yn: false,
  cprgt_rule_agree_yn: false,
  prvcy_polcy_agree_yn: false,
  abstr_ttl: null,
  abstr_ttl_en: null,
});

export default {
  components: {
    tinyEditor,
  },
  props: ["propParams"],  
  data() {
    return {
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      confrList: [],
      confrSno: '',
      ptMthdList: [],
      regItemList: [],
      regItemSet: {
        // confr_sno: {use_yn:true, ess_yn:true},
        // rqst_mber_sno: {use_yn:true, ess_yn:false},
        rqstr_email: {use_yn:true, ess_yn:false},
        rqstr_nm: {use_yn:true, ess_yn:false},
        rqstr_blng: {use_yn:true, ess_yn:false},
        rqstr_blng_en: {use_yn:true, ess_yn:false},
        rqstr_dept: {use_yn:true, ess_yn:false},
        rqstr_pstn: {use_yn:true, ess_yn:false},
        rqstr_cel_no: {use_yn:true, ess_yn:false},
        pt_mthd: {use_yn:true, ess_yn:false},
        pt_field: {use_yn:true, ess_yn:false},
        pt_field_2nd: {use_yn:true, ess_yn:false},
        // pt_awad_apply: {use_yn:true, ess_yn:false},
        abstr_lang: {use_yn:true, ess_yn:false},
        abstr_ttl: {use_yn:true, ess_yn:false},
        abstr_ttl_en: {use_yn:true, ess_yn:false},
        // abstr_atch_sno: {use_yn:true, ess_yn:false},
        // abstr_edtr: {use_yn:true, ess_yn:false},
        rqstr_memo: {use_yn:true, ess_yn:false},
        admin_memo: {use_yn:true, ess_yn:false},
        // abstr_upd_psbl: {use_yn:true, ess_yn:false},
        abstr_use_agree: {use_yn:true, ess_yn:false},
        cprgt_rule_agree: {use_yn:true, ess_yn:false},
        prvcy_polcy_agree: {use_yn:true, ess_yn:false},
        pt_no: {use_yn:true, ess_yn:false},
        pt_dt: {use_yn:true, ess_yn:false},
        pt_bgn_tm: {use_yn:true, ess_yn:false},
        pt_end_tm: {use_yn:true, ess_yn:false},
      },
      ptFieldList: [],
      reqParam: {},
      abstrLangList: [],
      basicUri: '',
      menuSno: this.$route.query.menuSno ? this.$route.query.menuSno : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      emitData: {},

      abstrRegSno: this.$route.query.abstr_reg_sno ? this.$route.query.abstr_reg_sno : '',
      belongGridHeaders:[
        { text: '선택', value:'select_yn', sortable: false},
        { text: '소속 번호', value:'blng_no', sortable: false},
        { text: '소속(한글)', value:'blng_nm', sortable: false},
        { text: '소속(영문)', value:'blng_nm_en', sortable: false},
      ],
      belongList: [],
      pendingBelongList: [],
      belongChangedList: [],
      belongListParam:{},

      authorGridHeaders:[
        { text: '선택', value:'select_yn', width: '5%', sortable: false},
        { text: '이름', value:'athor_nm', width: '8%', sortable: false},
        { text: '영문(First)', value:'athor_nm_en_frst', width: '8%', sortable: false},
        { text: '영문(Last)', value:'athor_nm_en_last', width: '8%', sortable: false},
        { text: '이메일', value:'athor_email', sortable: false},
        { text: '전화번호', value:'athor_tel_no', width: '12%', sortable: false},
        { text: '저자 역할', value:'athor_role', width: '10%', sortable: false},
        { text: '발표자', value:'ptr_yn', width: '8%', sortable: false},
        { text: '교신저자', value:'corr_athor_yn', width: '8%', sortable: false},
        { text: '표시 순서', value:'disp_odr', width: '8%', sortable: false},
        { text: '소속', value:'blng_nos', width: '10%', sortable: false},
      ],
      
      authorList: [],
      pendingAuthorList: [],
      changedList: [],
      athorRoleList: [],
      athorBlngList: [],
      ongoingConfrInfo: {},

      regOptn: {},
      confrPolcy: {},

      // 체크박스 처리를 위한 로컬 변수
      abstr_use_agree_yn: false,
      cprgt_rule_agree_yn: false,
      prvcy_polcy_agree_yn: false,
    }
  },
  computed: {
    isEnPtHnglImps() {
      return this.regOptn.en_pt_hngl_ttl_imps_yn == 'Y' && this.reqParam.abstr_lang == 'en';
    },
    filteredPtFieldList() {
      console.log("filteredPtFieldList : ", this.reqParam.pt_mthd_no);
      return this.ptFieldList.filter(e => e.pt_mthd_no == this.reqParam.pt_mthd_no);
    }
  },
  watch: {
    
  },
  created() {
    
    let path = this.$route.fullPath;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    this.getAbstrRegPage();
    this.emitData.menu_sno = this.menuSno;
  },
  mounted() {
  },
  methods :{
    getAbstrRegPage() {
      let url = '/sub' + this.$route.fullPath;
      console.log("getAbstrRegPage url : ", url);

      const params = new FormData();
      
      params.append("mber_sno", this.mberSno);
      params.append("non_mber_yn", this.nonMberYn);
      params.append("reg_div", 'abstr');

      this.$http.post(url, params).then((res) => {
        console.log("getAbstrRegPage : ", res.data);
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.movePage();
        }
        // console.log("res.data.result.reqParamMap : ", res.data.result.reqParamMap);
        if(res.data.result.reqParamMap) {
          this.reqParam = res.data.result.reqParamMap;

          this.abstr_use_agree_yn = this.reqParam.abstr_use_agree_yn == "Y" ? true : false;
          this.cprgt_rule_agree_yn = this.reqParam.cprgt_rule_agree_yn == "Y" ? true : false;
          this.prvcy_polcy_agree_yn = this.reqParam.prvcy_polcy_agree_yn == "Y" ? true : false;
        } else {
          const confrRegMber = res.data.result.confrRegMber;
          // console.log("const confrRegMber : ", confrRegMber);
          this.reqParam = genEmptyReqParam({rqst_mber_sno: confrRegMber.mber_sno, rqstr_email: confrRegMber.email, rqstr_nm: confrRegMber.mber_nm, 
            rqstr_blng: confrRegMber.blng_nm, rqstr_blng_en: null, rqstr_dept: confrRegMber.dept_nm, rqstr_pstn: confrRegMber.pstn_nm, rqstr_cel_no: confrRegMber.cel_no});

        }
        // console.log("this.reqParam : ", this.reqParam);

        this.confrPolcy = res.data.result.confrPolcy;

        // 초록등록 옵션
        this.regOptn = res.data.result.regOptn;

        //학술대회 리스트
        // this.getConfrList();
        this.getAthorRoleList();
        this.getAbstrLangList();

        this.ptMthdList = res.data.result.ptMthdList;
        this.ptFieldList = res.data.result.ptFieldList;
        this.regItemList = res.data.result.regItemList;
        let regItemSet = {};

        this.belongGridHeaders = [{text: '선택', value: 'select_yn', width: '20px', fixed: true, sortable: false}];
        this.authorGridHeaders = [{text: '선택', value: 'select_yn', width: '30px', sortable: false}];

        res.data.result.regItemList.forEach((e) => {
          // console.log(e);
          regItemSet[e.reg_item_id] = {reg_item_nm: e.reg_item_nm, reg_sctn_nm: e.reg_sctn_nm, use_yn: e.use_yn, ess_yn: e.ess_yn};
          // console.log(e.reg_item_id, e.ess_yn);

          // 저자소속 그리드 헤더 설정
          if(e.reg_sctn_nm == "저자소속") {
            if(e.use_yn) {
              let essStr = e.ess_yn ? " *" : "";
              let width = 10;

              switch(e.reg_item_id) {
                case 'blng_no' :
                  width = '100px';
                  break;
                case 'blng_nm' :
                  width = '300px';
                  break;
                case 'blng_nm_en' :
                  width = '500px';
                  break;
              }  

              this.belongGridHeaders.push({text: e.reg_item_nm + essStr, value: e.reg_item_id, width: width, fixed: true, sortable: false});
            }
          }

          // 저자정보 그리드 헤더 설정
          if(e.reg_sctn_nm == "저자정보") {
            if(e.use_yn) {
              let essStr = e.ess_yn ? " *" : "";
              let width = 10;

              switch(e.reg_item_id) {
                case 'athor_nm' :
                  width = '100px';
                  break;
                case 'athor_nm_en_frst' :
                  width = '120px';
                  break;
                case 'athor_nm_en_last' :
                  width = '110px';
                  break;
                case 'athor_email' :
                  width = '200px';
                  break;
                case 'athor_tel_no' :
                  width = '150px';
                  break;
                case 'athor_role' :
                  width = '140px';
                  break;
                case 'ptr_yn' :
                  width = '65px';
                  break;
                case 'corr_athor_yn' :
                  width = '60px';
                  break;
                case 'disp_odr' :
                  width = '50px';
                  break;
                case 'blng_nos' :
                  width = '80px';
                  break;
              }  

              this.authorGridHeaders.push({text: e.reg_item_nm + essStr, value: e.reg_item_id, width: width, fixed: true, sortable: false});
            }
          }
        });
        // console.log("regItemSet : ", regItemSet);
        this.regItemSet = regItemSet;
        this.ongoingConfrInfo = res.data.result.ongoingConfrInfo;
        this.confrSno = this.ongoingConfrInfo.confr_sno;

        // this.pendingBelongList = res.data.result.belongList;
        // this.pendingAuthorList = res.data.result.authorList;

        if(res.data.result.belongList && res.data.result.belongList.length > 0) {
          this.belongList = JSON.parse(JSON.stringify(res.data.result.belongList, function(k, v) {return v == null ? "" : v}));
          this.pendingBelongList = JSON.parse(JSON.stringify(this.belongList));
        }
        if(res.data.result.authorList && res.data.result.authorList.length > 0) {
          this.authorList = JSON.parse(JSON.stringify(res.data.result.authorList, function(k, v) {return v == null ? "" : v}));
          this.authorList.forEach((e) => {
            e.corr_athor_yn = e.corr_athor_yn == 'Y' ? true : false; 
            e.ptr_yn = e.ptr_yn == 'Y' ? true : false;
          })
          this.pendingAuthorList = JSON.parse(JSON.stringify(this.authorList));
        }

        // console.log("pendingBelongList : ", this.pendingBelongList);
        // console.log("pendingAuthorList : ", this.pendingAuthorList);

        // this.belongChangedList = this.pendingBelongList;
        // this.changedList = this.pendingAuthorList;
        
        // const authorList = res.data.result.authorList;
        // if(authorList) {
        //   authorList.forEach((e) => {
        //     e.corr_athor_yn = e.corr_athor_yn == 'Y' ? true : false; 
        //     e.ptr_yn = e.ptr_yn == 'Y' ? true : false;
        //   })
        // }
        // this.pendingAuthorList = authorList
        
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//getAbstrRegPage end
    // getConfrList() {
    //   this.$http.get("/admin/getConfrList").then((res) => {
    //     console.log("rgetConfrList", res.data.result);
    //     this.confrList = res.data.result;
    //     this.getAbstrLangList();
    //   }).catch(
    //     function (error) {
    //       console.log(error);
    //     }
    //   )
    // },//getConfrList end
    // confrSnoChanged() {
    //   this.getConfrAbstrRegOptn();
    // },
    // getConfrAbstrRegOptn() {
    //   const params = new FormData();

    //   params.append("confr_sno", this.confrSno);
    //   params.append("reg_div", 'abstr');

		// 	this.$http.post("/admin/getConfrAbstrRegOptn", params).then((res) => {
    //     console.log("getConfrAbstrRegOptn res.data: ", res.data);
    //     this.ptMthdList = res.data.result.ptMthdList.filter((e) => e.use_yn);
    //     this.ptFieldList = res.data.result.ptFieldList.filter((e) => e.use_yn);
    //     this.regItemList = res.data.result.regItemList;
    //     let regItemSet = {};
    //     res.data.result.regItemList.forEach((e) => {
    //       // console.log(e);
    //       regItemSet[e.reg_item_id] = {use_yn:e.use_yn, ess_yn:e.ess_yn};
    //       // console.log(e.reg_item_id, e.ess_yn);
    //     });
    //     // console.log(regItemSet);
    //     this.regItemSet = regItemSet;

		// 	}).catch(function (error) {
		// 			console.log(error);
		// 		}
		// 	);
    // },//getConfrAbstrRegOptn end
    validateInput() {
      if(!this.confrSno) {
        alert('학술대회는 필수 입력값입니다.');
        return false;
      }
      this.reqParam.confr_sno = this.confrSno;

      // console.log(this.regItemList);
      console.log("reqParam : ", this.reqParam);
      console.log("pendingBelongList : ", this.pendingBelongList);
      console.log("pendingAuthorList : ", this.pendingAuthorList);

      // 발표자, 초록 등
      for(let e in this.regItemSet) {
        if(this.regItemSet[e].ess_yn && this.regItemSet[e].reg_sctn_nm != "저자소속" && this.regItemSet[e].reg_sctn_nm != "저자정보") {
          console.log("validateInput : ", e);
          if(e == 'cprgt_rule_agree') {
            if(!this.cprgt_rule_agree_yn) {
              alert('저작권 규정 동의는 필수 입력값입니다.');
              return false;
            }
          } else if(e == 'prvcy_polcy_agree') {
            if(!this.prvcy_polcy_agree_yn) {
              alert('개인정보 처리방침 동의는 필수 입력값입니다.');
              return false;
            }
          } else if(e == 'abstr_use_agree') {
            if(!this.abstr_use_agree_yn) {
              alert('초록 활용 동의는 필수 입력값입니다.');
              return false;
            }
          } else if(e == 'pt_mthd') {
            if(!this.reqParam.pt_mthd_no) {
              alert('발표방식은 필수 입력값입니다.');
              return false;
            }
          } else if(e == 'pt_field') {
            if(!this.reqParam.pt_field_no) {
              alert('발표분야는 필수 입력값입니다.');
              return false;
            }
          } else if(e == 'pt_field_2nd') {
            if(!this.reqParam.pt_field_no_2nd) {
              alert('발표분야(2순위)는 필수 입력값입니다.');
              return false;
            }
          } else {
            if(!this.reqParam[e] || this.reqParam[e] == "") {
              // alert(e);
              const reg_item_nm = this.regItemList.filter((m) => m.reg_item_id == e)[0].reg_item_nm;
              alert(reg_item_nm + '은(는) 필수 입력값입니다.');
              return false;
            }
          }
        }
      }

      if(this.reqParam.rqstr_email && !this.$validateEmail(this.reqParam.rqstr_email)) {
        alert('이메일 형식이 아닙니다.');
        return false;
      }

      // console.log("첨부 파일 : ", this.reqParam.abstrFile, this.reqParam.abstr_atch_sno);
      if(this.regOptn.abstr_file_edtr_ess_yn == 'Y') {
        if(this.regOptn.abstr_sbmt_mthd == 'file' && ((!this.reqParam.abstrFile || this.reqParam.abstrFile == undefined) && (this.reqParam.abstr_atch_sno == null || this.reqParam.abstr_atch_sno == ""))) {
          alert('파일 첨부는 필수 입력값입니다.');
          return false;
        }        

        if(this.regOptn.abstr_sbmt_mthd == 'edtr' && !this.reqParam.abstr_edtr) {
          alert('초록 에디터는 필수 입력값입니다.');
          return false;
        }     
      }

      // console.log("pendingBelongList : ", this.pendingBelongList);
      // 저자 소속 그리드 필수 입력값
      if(this.pendingBelongList.length <= 0) {
        alert("저자소속은 필수 입력값입니다.");
        return false;
      }

      for(let i = 0; i < this.pendingBelongList.length; i++) {
        const blng = this.pendingBelongList[i];

        for(let e in blng) {
          if(this.regItemSet[e] && this.regItemSet[e].reg_sctn_nm == "저자소속" && this.regItemSet[e].ess_yn) {
            if(!blng[e] || blng[e] == "") {
              // alert("blng : " + e);
              const reg_item_nm = this.regItemList.filter((m) => m.reg_item_id == e)[0].reg_item_nm;
              alert(reg_item_nm + '은(는) 필수 입력값입니다.');
              return false;
            }
          }
        }
      }

      // 소속 그리드 입력 데이터 검증
      if(!this.validateBelongInput()) {
        return false;
      }

      // console.log("pendingAuthorList : ", this.pendingAuthorList);
      // 저자 그리드
      if(this.pendingAuthorList.length <= 0) {
        alert("저자정보는 필수 입력값입니다.");
        return false;
      }

      let ptr_cnt = 0;
      let corr_athor_cnt = 0;

      for(let j = 0; j < this.pendingAuthorList.length; j++) {
        const athor = this.pendingAuthorList[j];
        for(let e in athor) {
          if(this.regItemSet[e] && this.regItemSet[e].reg_sctn_nm == "저자정보" && this.regItemSet[e].ess_yn &&
            (e != 'ptr_yn' && e != 'corr_athor_yn')) {
            if(!athor[e] || athor[e] == "") {
              // alert("athor : " + e);
              const reg_item_nm = this.regItemList.filter((m) => m.reg_item_id == e)[0].reg_item_nm;
              alert(reg_item_nm + '은(는) 필수 입력값입니다.');
              return false;
            }
          }

          if(e == "athor_email") {
            if(athor[e] && athor[e] != "" && !this.$validateEmail(athor[e])) {
              alert('저자 이메일 형식이 아닙니다.');
              return false;
            }
          }
        }
          
        console.log("athor : ", athor);
        if(athor.ptr_yn)         ptr_cnt++;
        if(athor.corr_athor_yn)  corr_athor_cnt++;
      }

      if(this.regItemSet['ptr_yn'].ess_yn == true && ptr_cnt <= 0) {
        alert("발표자는 필수 입력값입니다.");
        return false;
      }

      if(this.regItemSet['corr_athor_yn'].ess_yn == true && corr_athor_cnt <= 0) {
        alert("교신저자는 필수 입력값입니다.");
        return false;
      }

      // 저자 그리드 입력 데이터 검증
      if(!this.validateAthorInput()) {
        return false;
      }    

      return true;
    },//validateInput end
    regBtnClicked() {

      if(this.validateInput()) {
        // console.log(this.reqParam);
        const formData = new FormData();

        this.reqParam.rqst_mber_sno = this.mberSno;
        this.reqParam.abstr_use_agree_yn = this.abstr_use_agree_yn == true ? 'Y' : 'N';
        this.reqParam.cprgt_rule_agree_yn = this.cprgt_rule_agree_yn == true ? 'Y' : 'N';
        this.reqParam.prvcy_polcy_agree_yn = this.prvcy_polcy_agree_yn == true ? 'Y' : 'N';

        if(this.nonMberYn == 'Y') {
          this.reqParam.mber_yn = 'N';
        } else {
          this.reqParam.mber_yn = 'Y';
        }
        formData.append('reqParam',JSON.stringify(this.reqParam));
        if(this.abstrRegSno) {
          formData.append('abstr_reg_sno',this.abstrRegSno);
        }
        
        this.belongChangedList = this.pendingBelongList;
        this.changedList = this.pendingAuthorList;
        this.changedList.forEach((e) => {
          e.ptr_yn = e.ptr_yn == true ? 'Y' : 'N';
          e.corr_athor_yn = e.corr_athor_yn == true ? 'Y' : 'N';
          e.disp_odr = e.disp_odr == '' ? null : e.disp_odr;
        });

        // console.log(this.belongChangedList);
        // console.log(this.changedList);

        formData.append('belongListParam', JSON.stringify(this.belongChangedList));
        formData.append('athorListParam', JSON.stringify(this.changedList));
        if(this.reqParam.abstrFile) {
          formData.append('abstrFile', this.reqParam.abstrFile);
        }

        this.$http.post("/sub/upsertAbstrReg", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          console.log(res.data);
          
          if(this.abstrRegSno) {
            alert('수정되었습니다.');
          } else {
            alert('저장되었습니다.');
          }
          
          this.$router.push(this.basicUri+'/list');
        })
        .catch(function (error) {
          console.log(error);
        });
        
      }

    },//regBtnClicked end
    async getAbstrLangList() {
      this.abstrLangList = await this.$getCmnCdList('abstr_lang');
    },

    async getAthorRoleList() {
      this.athorRoleList = await this.$getCmnCdList('athor_role');
    },
    getAthorBlngList() {
      console.log("abstrRegistAuthorTab > getAthorBlngList");
      const params = new FormData();
      params.append("confr_sno", this.confrSno);
      params.append("abstr_reg_sno", this.abstrRegSno);

			this.$http.post("/admin/getConfrAbstrRegAthorBlngList", params).then((res) => {
        // console.log("res.data: ", res.data);
        this.athorBlngList = res.data.result.abstrRegAthorBlngList;
        console.log("athorBlngList:" , this.athorBlngList);
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    // 소속 그리드 수정됨
    belongGridItemChanged(item) {
      console.log("belongGridItemChanged : item.abstr_reg_sno : " + item.abstr_reg_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.abstr_reg_sno == null || item.abstr_reg_sno == "") {
        return;
      }

      let found = false;
      this.belongChangedList.forEach((e) => {
        if(e.abstr_reg_sno == item.abstr_reg_sno && e.odr == item.odr) {
          found = true;
        }
      });

      if(!found) {
        this.belongChangedList.push(item);
      }

      // this.itemChanged();
    },
    // 소속 삭제 버튼 클릭
    deleteBelongBtnClicked() {
      console.log("deleteConfrAbstrRegAthorBlng");


      this.pendingBelongList = this.pendingBelongList.filter((e) => !e.select_yn)

      // 선택된 항목이 없는 경우
      if(this.pendingBelongList.filter((e) => e.select_yn).length > 0) {
        alert("선택된 목록이 없습니다.");
        return;
      }
    },
    // 소속 추가 버튼 클릭
    addBelongBtnClicked() {
      if(this.pendingBelongList.length >= this.regOptn.athor_blng_max_cnt) {
        alert(`소속 등록 가능 수(${this.regOptn.athor_blng_max_cnt})를 초과하여 추가할 수 없습니다.`);
        return;
      }

      console.log("기존 리스트 ::::: " + this.belongChangedList);
      let maxOdr = 0;
      let maxBlngNo = 0;

      this.pendingBelongList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }

        if(e.blng_no > maxBlngNo) {
          maxBlngNo = e.odr;
        }
      });

      maxOdr++;
      maxBlngNo++;

      this.pendingBelongList.push({new_yn:true, select_yn:false, odr:maxOdr, abstr_reg_sno:this.abstrRegSno, blng_no:maxBlngNo, blng_nm:"", blng_nm_en:""});
      this.belongChangedList.push(this.pendingBelongList[this.pendingBelongList.length - 1]);
    },
    // 소속 그리드 조회
    getConfrAbstrRegAthorBlngList() {
      const params = new FormData();
      params.append("confr_sno", this.confrSno);
      params.append("abstr_reg_sno", this.abstrRegSno);

			this.$http.post("/admin/getConfrAbstrRegAthorBlngList", params).then((res) => {
        console.log("res.data: ", res.data);

        this.belongList = JSON.parse(JSON.stringify(res.data.result.abstrRegAthorBlngList, function(k, v) {return v == null ? "" : v}));
        this.pendingBelongList = JSON.parse(JSON.stringify(this.belongList));
        this.belongChangedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    // 소속 그리드 입력 데이터 검증
    validateBelongInput() {
      for(let i = 0; i < this.belongChangedList.length; i++) {
        let e = this.belongChangedList[i];
        // console.log("e:", e);

        // 소속번호
        // if(e.blng_no == null || e.blng_no == "") {
        //   alert('소속번호는 필수 입력값입니다.');
        //   return false;
        // }
        if(!this.$includeHangle(e.blng_no) || !this.$includeAlphaChar(e.blng_no)) {
          alert("소속번호는 숫자만 입력 가능합니다.");
          return false;
        }
        // 소속명
        // if(e.blng_nm == null || e.blng_nm == "") {
        //   alert('소속명은 필수 입력값입니다.');
        //   return false;
        // }
      }

      return true;
    },
    // 저자 그리드 수정됨
    gridItemChanged(item) {
      console.log("gridItemChanged : item.abstr_reg_sno : " + item.abstr_reg_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.abstr_reg_sno == null || item.abstr_reg_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.abstr_reg_sno == item.abstr_reg_sno && e.odr == item.odr) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }

      // this.itemChanged();
    },
    // 저자정보 > 발표자 여부 수정됨 -> isChecked 이면 다른 item.ptr_yn = false 업데이트
    gridPtrYnChanged(item) {
      // console.log("gridPtrYnChanged item : ", item);
      if(item.ptr_yn) {
        this.pendingAuthorList.forEach(e => {
          // console.log("gridPtrYnChanged e : ", e);
          if(e.odr != item.odr && e.ptr_yn) {
            e.ptr_yn = false;
            this.gridItemChanged(e);
          }
        })
      }

      this.gridItemChanged(item);
    },
    deleteBtnClicked() {
      console.log("deleteConfrAbstrRegAthor");

      this.pendingAuthorList = this.pendingAuthorList.filter((e) => !e.select_yn)

      // 선택된 항목이 없는 경우
      if(this.pendingAuthorList.filter((e) => e.select_yn).length > 0) {
        alert("선택된 목록이 없습니다.");
        return;
      }

    },
    //추가버튼
    addBtnClicked() {
      let maxOdr = 0;

      this.pendingAuthorList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.pendingAuthorList.push({new_yn:true, select_yn:false, odr:maxOdr, abstr_reg_sno:this.abstrRegSno, athor_nm:"", athor_nm_en_frst:"", athor_nm_en_last:"",
                          athor_email:"", athor_tel_no:"", athor_role:"", ptr_yn:false, corr_athor_yn:false, disp_odr:"", blng_nos:"", blng_nm:""});
      this.changedList.push(this.pendingAuthorList[this.pendingAuthorList.length - 1]);
    },
    //상세>조회
    getConfrAbstrRegAthorList() {
      const params = new FormData();
      params.append("confr_sno", this.confrSno);
      params.append("abstr_reg_sno", this.abstrRegSno);

			this.$http.post("/admin/getConfrAbstrRegAthorList", params).then((res) => {
        console.log("res.data: ", res.data);

        res.data.result.abstrRegAthorList.forEach((e) => {
          e.ptr_yn = e.ptr_yn == 'Y' ? true : false;
          e.corr_athor_yn = e.corr_athor_yn == 'Y' ? true : false;
        });

        this.authorList = JSON.parse(JSON.stringify(res.data.result.abstrRegAthorList, function(k, v) {return v == null ? "" : v}));
        this.pendingAuthorList = JSON.parse(JSON.stringify(this.authorList));
        this.changedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateAthorInput() {
      for(let i = 0; i < this.changedList.length; i++) {
        let e = this.changedList[i];
        // console.log("e:", e);

        // 이름
        // if(e.athor_nm == null || e.athor_nm == "") {
        //   alert('이름은 필수 입력값입니다.');
        //   return false;
        // }
        // 이메일
        // if(e.athor_email == null || e.athor_email == "") {
        //   alert('이메일은 필수 입력값입니다.');
        //   return false;
        // }
        // if(!this.$validateEmail(e.athor_email)) {
        //   alert('이메일 형식이 아닙니다.');
        //   return false;
        // }
        // 저자 역할
        // if(e.athor_role == null || e.athor_role == "") {
        //   alert('저자 역할은 필수 입력값입니다.');
        //   return false;
        // }
        // 소속
        if(e.blng_nos != null && e.blng_nos != "") {
          let blngNoList = e.blng_nos.split(",");
          let errorList = [];
          // console.log("blngNoList:", blngNoList);
          
          blngNoList.forEach((blng_nos) => {
            let isValid = false;
            this.pendingBelongList.forEach((e) => {
              if(Number(blng_nos) == e.blng_no) {
                isValid = true;
              }
            });

            if(!isValid) {
              errorList.push(blng_nos);
            }
          });

          // console.log("errorList:", errorList);
          if(errorList.length > 0) {
            alert('저자 소속에 유효하지 않은 소속번호(' + errorList.join() + ')가 있습니다.');
            return false;
          }
        }
      }

      return true;
    },//validateInput end
    movePage() {
      this.$router.push(this.basicUri+'/list');
    },//movePage end
    abstrDeleteBtnClicked() {

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();
        params.append("abstr_reg_sno", this.abstrRegSno);

        this.$http.post("/admin/deleteConfrAbstrReg", params).then((res) => {
          console.log(res.data);
          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.movePage();
          }
          
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },//abstrDeleteBtnClicked end
    fileDelClicked() {
      if(confirm('첨부된 파일 삭제하시겠습니까?')) {
        this.reqParam.abstr_atch_sno = '';
        this.reqParam.abstr_orgnl_file_nm = '';
        this.reqParam.fileDelYn = 'Y';
      }
    },//fileDelClicked end
  }
}
</script>

<style scoped>
/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px;
}
/deep/.v-text-field--enclosed .v-input__append-inner{
  margin-top: 10px;
}
/* .v-text-field--enclosed .v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
  padding-top: 10px !important;
} */
</style>