import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import axios from 'axios';
// if (document.location.hostname == 'localhost') {
//   axios.defaults.baseURL = 'http://localhost:8087';
// } else {
//   axios.defaults.baseURL = document.location.origin;
// }

// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


import store from '@/store/store'

import Home from "@/components/Home"

import defaultLayout from "@/layout/admin/index"
import join from "@/views/admin/join"
import memberSectionItem from "@/views/admin/memberSectionItem"
import memberList from "@/views/admin/memberList"
import society from "@/views/admin/society"
import memberDetail from "@/views/admin/memberDetail"
import conferenceList from "@/views/admin/conferenceList"
import conferenceDetail from "@/views/admin/conferenceDetail"
import preRegistList from "@/views/admin/preRegistList"
import preRegistDetail from "@/views/admin/preRegistDetail"
import abstrRegistList from "@/views/admin/abstrRegistList"
import abstrRegistDetail from "@/views/admin/abstrRegistDetail"
import productList from "@/views/admin/productList"
import productDetail from "@/views/admin/productDetail"
import paymentList from "@/views/admin/paymentList"
import paymentDetail from "@/views/admin/paymentDetail"
// import paymentPage from "@/components/subPage/paymentPage"
import testDataTable from "@/components/admin/testDataTable"
import testInputForm from "@/components/admin/testInputForm"
import menuList from "@/views/admin/menuList"
import menuDetail from "@/views/admin/menuDetail"
import groupNPermission from "@/views/admin/groupNPermission"
import mainPageMng from "@/views/admin/mainPageMng"
import boardList from "@/views/admin/boardList"
import postList from "@/views/admin/postList"
import postDetail from "@/views/admin/postDetail"
import publcMenuList from "@/views/admin/publcMenuList"
import publcArtclList from "@/views/admin/publcArtclList"
import publcArtclDetail from "@/views/admin/publcArtclDetail"
import scheduleList from "@/views/admin/scheduleList"
import scheduleDetail from "@/views/admin/scheduleDetail"
import executiveGenerationList from "@/views/admin/executiveGenerationList"
import executiveGenerationDetail from "@/views/admin/executiveGenerationDetail"
import historyTermList from "@/views/admin/historyTermList"
import historyTermDetail from "@/views/admin/historyTermDetail"
import societyMngList from "@/views/admin/societyMngList"
import societyMngDetail from "@/views/admin/societyMngDetail"
import pdfDownload from "@/views/admin/pdfDownload"
import certificationLetter from "@/views/admin/certificationLetter"
import issuanceList from "@/views/admin/issuanceList"
import issuanceDetail from "@/views/admin/issuanceDetail"

import main from "@/layout/main/main"
import mainTest from "@/views/main/mainTest"
import login from "@/views/member/login"
import adminLogin from "@/views/member/adminLogin"
import memJoin from "@/views/member/join"
import findId from "@/views/member/findId"
import findPwd from "@/views/member/findPwd"
import pwdReset from "@/views/member/pwdReset"
import subPage from "@/layout/subPage/subPage"
import boardListPage from "@/components/subPage/boardListPage"
import boardThumbnailPage from "@/components/subPage/boardThumbnailPage"
import boardGalleryPage from "@/components/subPage/boardGalleryPage"
import boardGalleryDetailPage from "@/components/subPage/boardGalleryDetailPage"
import boardDetailPage from "@/components/subPage/boardDetailPage"
import boardUpsertPage from "@/components/subPage/boardUpsertPage"
import joinPage from "@/components/subPage/joinPage"
import termsService from "@/components/subPage/termsServicePage"
import privacyPolicy from "@/components/subPage/privacyPolicyPage"
import emailColctDeny from "@/components/subPage/emailColctDenyPage"
import notFound from "@/views/notFound"

import paymentPage from "@/components/subPage/paymentPage"
import paymentPageDetail from "@/components/subPage/paymentPageDetail"
import paymentFail from "@/components/subPage/paymentFail"


import abstrRegistPage from "@/components/subPage/abstrRegistPage"
import abstrRegistListPage from "@/components/subPage/abstrRegistListPage"

import preRegistPage from "@/components/subPage/preRegistPage"
import preRegistListPage from "@/components/subPage/preRegistListPage"
import nonMembersLoginPage from "@/components/subPage/nonMembersLoginPage"


// import searchMembersPage from "@/components/subPage/searchMembersPage"
// import searchLiteraturePage from "@/components/subPage/searchLiteraturePage"
import qnaListPage from "@/components/subPage/qnaListPage"
import qnaRegistPage from "@/components/subPage/qnaRegistPage"
import qnaDetailPage from "@/components/subPage/qnaDetailPage"

import publicationPage from "@/components/subPage/publicationPage"
import publicationListPage from "@/components/subPage/publicationListPage"
import publicationDetailPage from "@/components/subPage/publicationDetailPage"

import subMyPage from "@/layout/subMyPage/subMyPage"
import profile from "@/views/mypage/profile"
import certificate from "@/views/mypage/certificate"
import payList from "@/views/mypage/payList"
import payDetail from "@/views/mypage/payDetail"
import payFail from "@/views/mypage/payFail"

//notipopup
import notiPopup from "@/components/common/notiPopup"


//PAY
// import payMain from '@/views/pay/payMain.vue'
// import pay from '@/views/pay/pay.vue'
// import payComplete from '@/views/pay/payComplete.vue'
// import returns from '@/views/pay/return.vue'
// import close from '@/views/pay/close.vue'

const router =  new Router({
  mode : 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "/adminLogin",
      name: 'adminLogin',
      component: adminLogin,
      beforeEnter: (to, from, next) => {
        console.log('to:: ',to);
        console.log('from:: ',from);
        if(from.name) {
          store.state.goUrl = from.path;
        } else {
          store.state.goUrl = '/admin';
        }

        const mberSno = Vue.prototype.$session.get("mberSno");
        if(mberSno) {
          alert('이미 로그인되어있습니다.');
          next("/")
        } else {
          next();
        }
      }
    },
    // {
    //   path: '/pay/**',
    //   name: 'paymentPage',
    //   component: subPage
    // },
    // {
    //   path: '/payMain',
    //   component: payMain,
    //   name: 'payMain'
    // },
    // {
    //   path: '/close',
    //   component: close,
    //   name: 'close'
    // },
    // {
    //   path: '/return',
    //   component: returns,
    //   name: 'returns'
    // },
    // {
    //   path: '/pay',
    //   component: pay,
    //   name: 'pay'
    // },
    // {
    //   path: '/payComplete',
    //   component: payComplete,
    //   name: 'payComplete'
    // } ,
    {
      path: '/mypage',
      name: 'mypage',
      component: subMyPage,
      children:[
        {
          path: '/mypage/profile',
          name: 'profile',
          component: profile
        },
        {
          path: '/mypage/payDetail',
          name: 'payDetail',
          component: payDetail
        },
        {
          path: '/mypage/payList',
          name: 'payList',
          component: payList
        },
        {
          path: '/mypage/payFail',
          name: 'payFail',
          component: payFail
        },
        {
          path: '/mypage/certificate',
          name: 'certificate',
          component: certificate
        },
      ]
    },
    {
      path: "/404",
      name: "notFound",
      component: notFound
    },
    {
      path: '/joinPage/**',
      name: 'joinPage',
      component: joinPage
    },
    {
      path: "/static/**",
      name: "static",
      component: subPage,
    },
    {
      path: "/nonMember/**",
      name: "nonMemberLogin",
      component: subPage,
    },
    {
      path: "/confrOutl/**",
      alias: ['/confrScdul/**','/confrScdul/**','/confrEventTour/**','/confrTrfcAccom/**','/confrAdDisp/**'],
      name: "confr",
      component: subPage,
    },
    {
      path: "/executive/**",
      name: "executive",
      component: subPage,
    },
    {
      path: '/sponsor/**',
      name: 'sponsorPage',
      component: subPage
    },
    {
      path: '/history/**',
      name: 'historyPage',
      component: subPage
    },
    {
      path: '/schedule/**',
      name: 'calendar',
      component: subPage
    },
    {
      path: '/contactus/**',
      name: 'contactus',
      component: subPage
    },
    {
      path: '/memberSearch/**',
      name: 'memberSearch',
      component: subPage
    },
    {
      path: '/docSearch/**',
      name: 'docSearch',
      component: subPage
    },
    // {
    //   path: '/payment/**',
    //   name: 'payment',
    //   component: subPage
    // },
    {
      path: '/public',
      name: 'public',
      component: subPage,
      children: [
        {
          path: '/public/**/**/list',
          name: 'publicationListPage',
          component: publicationListPage,
        },
        {
          path: '/public/**/**/detail',
          name: 'publicationDetailPage',
          component: publicationDetailPage,
        },
        {
          path: '/public/**/*',
          name: 'publicationPage',
          component: publicationPage,
        }
      ]
    },
    {
      path: "/board",
      name: "board",
      component: subPage,
      children:[
        {
          path: '/board/free/**/list',
          name: 'boardListPage',
          component: boardListPage
        },
        {
          path: '/board/free/**/detail',
          name: 'boardDetailPage',
          component: boardDetailPage
        },
        {
          path: '/board/faq/**/list',
          name: 'boardListPage',
          component: boardListPage
        },
        {
          path: '/board/faq/**/detail',
          name: 'boardDetailPage',
          component: boardDetailPage
        },
        {
          path: '/board/thumbnail/**/list',
          name: 'boardThumbnailPage',
          component: boardThumbnailPage
        },
        {
          path: '/board/gallery/**/list',
          name: 'boardGalleryPage',
          component: boardGalleryPage
        },
        {
          path: '/board/gallery/**/detail',
          name: 'boardGalleryDetailPage',
          component: boardGalleryDetailPage
        },
        {
          path: "/board/notice/**/detail",
          name: "boardDetailPage",
          component: boardDetailPage,
        },
        {
          path: '/board/notice/**/list',
          name: 'boardListPage',
          component: boardListPage
        },
        {
          path: "/board/free/**/write",
          name: "boardUpsertPage",
          component: boardUpsertPage,
        },
        {
          path: "/board/free/**/modify",
          name: "boardUpsertPage",
          component: boardUpsertPage,
        },
        {
          path: "/board/qna/**/list",
          name: "qnaListPage",
          component: qnaListPage,
        },
        {
          path: "/board/qna/**/detail",
          name: "qnaDetailPage",
          component: qnaDetailPage,
        },
        {
          path: "/board/qna/**/write",
          name: "qnaRegistPage",
          component: qnaRegistPage,
        },
        {
          path: "/board/qna/**/modify",
          name: "qnaRegistPage",
          component: qnaRegistPage,
        },
      ]
        // qnaRegistPage
    },
    {
      path: "/pay",
      name: "pay",
      component: subPage,
      children:[
        {
          path: '/pay/**/**/list',
          name: 'paymentPage',
          component: paymentPage
        },
        {
          path: '/pay/**/**/detail',
          name: 'paymentPageDetail',
          component: paymentPageDetail,
        },
        {
          path: '/pay/**/**/fail',
          name: 'paymentFail',
          component: paymentFail,
        },
      ]
    },
    {
      path: "/abstrReg",
      name: "abstrReg",
      component: subPage,
      children:[
        {
          path: '/abstrReg/**/**/list',
          name: 'abstrRegistListPage',
          component: abstrRegistListPage
        },
        {
          path: '/abstrReg/**/**/write',
          name: 'abstrRegistPage',
          component: abstrRegistPage
        },
        {
          path: '/abstrReg/**/**/nonMberLogin',
          name: 'nonMembersLoginPage',
          component: nonMembersLoginPage
        },
      ]
    },
    {
      path: "/preReg",
      name: "preReg",
      component: subPage,
      children:[
        {
          path: '/preReg/**/**/list',
          name: 'preRegistListPage',
          component: preRegistListPage
        },
        {
          path: '/preReg/**/**/detail',
          name: 'preRegistPage',
          component: preRegistPage
        },
        {
          path: '/preReg/**/**/write',
          name: 'preRegistPage',
          component: preRegistPage
        },
        {
          path: '/preReg/**/**/nonMberLogin',
          name: 'nonMembersLoginPage',
          component: nonMembersLoginPage
        },
      ]
    },
    {
      path: "/notiPopup",
      name: "notiPopup",
      component: notiPopup,
    },
    {
      path: "/testHome",
      name: "testHome",
      component: Home,
    },
    {
      path: '*',
      redirect: "/404"
    },
    {
      path: "/scdul/**",
      name: 'mainTest',
      component: mainTest
    },
    {
      path: "/",
      name: "main",
      meta:{
        title:'학회명'
      },
      component: main,
    },
    {
      path: "/termsService",
      name: "termsService",
      component: termsService,
    },
    {
      path: "/privacyPolicy",
      name: "privacyPolicy",
      component: privacyPolicy,
    },
    {
      path: "/emailColctDeny",
      name: "emailColctDeny",
      component: emailColctDeny,
    },
    // {
    //   path: "/abstrRegistPage",
    //   name: "abstrRegistPage",
    //   component: abstrRegistPage,
    // },
    {
      path: "/abstrRegistListPage",
      name: "abstrRegistListPage",
      component: abstrRegistListPage,
    },
    {
      path: "/login",
      name: 'login',
      component: login,
      beforeEnter: (to, from, next) => {
        console.log('to:: ',to);
        console.log('from:: ',from);
        if(from.name) {
          store.state.goUrl = from.path;
        } else {
          store.state.goUrl = '/admin';
        }

        const mberSno = Vue.prototype.$session.get("mberSno");
        if(mberSno) {
          alert('이미 로그인되어있습니다.');
          next("/")
        } else {
          next();
        }
      }
    },
    {
      path: "/join",
      name: 'memJoin',
      component: memJoin,
      beforeEnter: (to, from, next) => {
        console.log(to);
        console.log(from);
        const mberSno = Vue.prototype.$session.get("mberSno");
        if(mberSno) {
          alert('이미 로그인되어있습니다.');
          next("/")
        } else {
          next();
        }
      }
    },
    {
      path: "/pwdReset",
      name: 'pwdReset',
      component: pwdReset,
      beforeEnter: (to, from, next) => {
        console.log(to);
        console.log(from);
        const mberSno = Vue.prototype.$session.get("mberSno");
        const tempPwdYn = Vue.prototype.$session.get("tempPwdYn");
        if(mberSno && tempPwdYn == 'Y') {
          next();
        } else {
          alert('잘못된 접근입니다.');
          next("/");
        }
      }
    },
    
    {
      path: "/findId",
      name: 'findId',
      component: findId,
    },
    {
      path: "/findPwd",
      name: 'findPwd',
      component: findPwd,
    },
    {
      path: "/qnaRegistPage",
      name: 'qnaRegistPage',
      component: qnaRegistPage,
    },
    {
      path: '/admin',
      meta:{
        title:'admin'
      },
      name: 'admin',
        component: defaultLayout,
        beforeEnter: (to, from, next) => {
          console.log(to);
          console.log(from);
          // store.state.goUrl = '/admin';
          const mberSno = Vue.prototype.$session.get("mberSno");
          if(mberSno) {

            const formData = new FormData();
            formData.append('mber_sno', mberSno);
            const nonMberYn = Vue.prototype.$session.get("nonMberYn");
            formData.append('non_mber_yn', nonMberYn);
            
            axios.post('/mber/getDomnLginChk',formData).then((res) => {
              if(res.data.code < 0) {
                Vue.prototype.$session.clear();
                next('/');
              } else {
                const mberInfo = res.data.mberInfo;
                if(!mberInfo) {
                  alert('권한이 없습니다.');
                  next('/');
                }
                if(mberInfo.mber_priv == 'socty_admin' || mberInfo.mber_priv == 'sys_admin') {
                  next();
                } else {
                  alert('권한이 없습니다.');
                  next('/');
                }
                
              }
                
            }).catch(
              function (error) {
              console.log(error);
              }
            )

          } else {
            next("/adminLogin")
          }
          
        },
        children:[
          {
            path: 'memberSectionItem',
            name: 'memberSectionItem',
            meta:{
              title:'회원정보 항목 관리'
            },
            component: memberSectionItem
          },
          {
            path: 'join',
            name: 'join',
            meta:{
              title:'회원등록'
            },
            component: join
          },
          {
            path: 'memberList',
            name: 'memberList',
            meta:{
              title:'회원정보 관리'
            },
            component: memberList
          },
          {
            path: 'society',
            name: 'society',
            component: society
          },
          {
            path: 'memberDetail',
            name: 'memberDetail',
            component: memberDetail
          },
          {
            path: 'conferenceList',
            name: 'conferenceList',
            component: conferenceList
          },
          {
            path: 'conferenceDetail',
            name: 'conferenceDetail',
            component: conferenceDetail
          },
          {
            path: 'preRegistList',
            name: 'preRegistList',
            component: preRegistList
          },
          {
            path: 'preRegistDetail',
            name: 'preRegistDetail',
            component: preRegistDetail
          },
          {
            path: 'abstrRegistList',
            name: 'abstrRegistList',
            component: abstrRegistList
          },
          {
            path: 'abstrRegistDetail',
            name: 'abstrRegistDetail',
            component: abstrRegistDetail
          },
          {
            path: 'productList',
            name: 'productList',
            component: productList
          },
          {
            path: 'productDetail',
            name: 'productDetail',
            component: productDetail
          },
          {
            path: 'paymentList',
            name: 'paymentList',
            component: paymentList
          },
          {
            path: 'paymentDetail',
            name: 'paymentDetail',
            component: paymentDetail
          },
          {
            path: 'testDataTable',
            name: 'testDataTable',
            component: testDataTable
          },
          {
            path: 'testInputForm',
            name: 'testInputForm',
            component: testInputForm
          },
          {
            path: 'menuList',
            name: 'menuList',
            component: menuList
          },
          {
            path: 'menuDetail',
            name: 'menuDetail',
            component: menuDetail
          },
          {
            path: 'groupNPermission',
            name: 'groupNPermission',
            component: groupNPermission
          },
          {
            path: 'mainPageMng',
            name: 'mainPageMng',
            component: mainPageMng
          },
          {
            path: 'boardList',
            name: 'boardList',
            component: boardList
          },
          {
            path: 'postList',
            name: 'postList',
            component: postList
          },
          {
            path: 'postDetail',
            name: 'postDetail',
            component: postDetail
          },
          {
            path: 'publcMenuList',
            name: 'publcMenuList',
            component: publcMenuList
          },
          {
            path: 'publcArtclList',
            name: 'publcArtclList',
            component: publcArtclList
          },
          {
            path: 'publcArtclDetail',
            name: 'publcArtclDetail',
            component: publcArtclDetail
          },
          {
            path: 'scheduleList',
            name: 'scheduleList',
            component: scheduleList
          },
          {
            path: 'scheduleDetail',
            name: 'scheduleDetail',
            component: scheduleDetail
          },
          {
            path: 'executiveGenerationList',
            name: 'executiveGenerationList',
            component: executiveGenerationList
          },
          {
            path: 'executiveGenerationDetail',
            name: 'executiveGenerationDetail',
            component: executiveGenerationDetail
          },
          {
            path: 'historyTermList',
            name: 'historyTermList',
            component: historyTermList
          },
          {
            path: 'historyTermDetail',
            name: 'historyTermDetail',
            component: historyTermDetail
          },
          {
            path: 'societyMngList',
            name: 'societyMngList',
            component: societyMngList,
            beforeEnter: (to, from, next) => {
              console.log(to);
              console.log(from);

              const mberSno = Vue.prototype.$session.get("mberSno");
              if(mberSno) {
    
                const formData = new FormData();
                formData.append('mber_sno', mberSno);

                const nonMberYn = Vue.prototype.$session.get("nonMberYn");
                formData.append('non_mber_yn', nonMberYn);
    
                axios.post('/mber/getDomnLginChk',formData).then((res) => {
                  if(res.data.code < 0) {
                    Vue.prototype.$session.clear();
                    next('/adminLogin');
                  } else {
                    const mberInfo = res.data.mberInfo;
                    if(mberInfo.mber_priv == 'sys_admin') {
                      next();
                    } else {
                      alert('권한이 없습니다.');
                      next('/admin');
                    }
                    
                  }
                    
                }).catch(
                  function (error) {
                  console.log(error);
                  }
                )
    
              } else {
                next("/adminLogin")
              }
              
            },
            
          },
          {
            path: 'societyMngDetail',
            name: 'societyMngDetail',
            component: societyMngDetail,
            beforeEnter: (to, from, next) => {
              console.log(to);
              console.log(from);
              
              const mberSno = Vue.prototype.$session.get("mberSno");
              if(mberSno) {
    
                const formData = new FormData();
                formData.append('mber_sno', mberSno);
    
                axios.post('/mber/getDomnLginChk',formData).then((res) => {
                  if(res.data.code < 0) {
                    Vue.prototype.$session.clear();
                    next('/adminLogin');
                  } else {
                    const mberInfo = res.data.mberInfo;
                    if(mberInfo.mber_priv == 'sys_admin') {
                      next();
                    } else {
                      alert('권한이 없습니다.');
                      next('/admin');
                    }
                    
                  }
                    
                }).catch(
                  function (error) {
                  console.log(error);
                  }
                )
    
              } else {
                next("/adminLogin")
              }
              
            },
          },
          {
            path: 'pdfDownload',
            name: 'pdfDownload',
            component: pdfDownload
          },
          {
            path: 'certificationLetter',
            name: 'certificationLetter',
            component: certificationLetter
          },
          {
            path: 'issuanceList',
            name: 'issuanceList',
            component: issuanceList
          },
          {
            path: 'issuanceDetail',
            name: 'issuanceDetail',
            component: issuanceDetail
          },
        ]
    },
    {
      path: "/publicationPage",
      name: 'publicationPage',
      component: publicationPage,
    },
    {
      path: "/publicationListPage",
      name: 'publicationListPage',
      component: publicationListPage,
    },
    {
      path: "/publicationDetailPage",
      name: 'publicationDetailPage',
      component: publicationDetailPage,
    },
    
  ]

  
})

const makeTitle = (title) =>
  title ? `${title}`   : "notitle111";

router.afterEach((to) => {
  console.log("to",to);
  Vue.nextTick(() => {
    console.log(window.location);
    const domn_nm = Vue.prototype.$session.get('domn_nm') ?? '';
    const sess_hostNm = Vue.prototype.$session.get('hostNm') ?? '';
    const hostNm = window.location.hostname;
    if(!domn_nm || sess_hostNm != hostNm) {

      const params = new FormData();
      axios.post("/admin/getSoctyNm", params).then((res) => {
        const socty_nm = res.data.result.socty_nm;
        if(socty_nm) {
          Vue.prototype.$session.set('domn_nm',socty_nm);
          Vue.prototype.$session.set('hostNm',hostNm);

          document.title = makeTitle(res.data.result.socty_nm);
        } else {
          document.title = makeTitle('notitle');
        }
        
      }).catch(function (error) {
          console.log(error);
        }.bind(this)
      );
    } else {
      const to_path = to.matched[0].path
      console.log("toes",to.matched[0].path);
      if(to_path == '/admin') {
        document.title = makeTitle(domn_nm + ' 관리자페이지');
      } else {
        document.title = makeTitle(domn_nm);
      }
      
    }
    
    
  });
});

router.beforeEach((to, from, next) => {
  if(from.name != 'login') {
    // console.log("router.beforeEach :: ",to);
    // console.log("router.beforeEach :: ",from);
    
    const mberSno = Vue.prototype.$session.get("mberSno") ? Vue.prototype.$session.get("mberSno") : '';
    if(mberSno != '') {
      const nonMberYn = Vue.prototype.$session.get("nonMberYn") ? Vue.prototype.$session.get("nonMberYn") : '';
      const formData = new FormData();

      formData.append('mber_sno', mberSno);
      formData.append('non_mber_yn', nonMberYn);

      axios.post('/mber/getDomnLginChk',formData).then((res) => {
        if(res.data.code < 0) {
          Vue.prototype.$session.clear();
          next('/');
        } else {
          next();
        }
          
      }).catch(
        function (error) {
        console.log(error);
        }
      )
    } else {
      next();
    }
  } else {
    next();
  }
  
  
})


export default router;