<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="gotoList"
          >목록</v-btn>
        </div>
        <div>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn 
            small 
            color="red lighten-2" 
            class="white--text f-r" 
            @click="deleteHistTermBtnClicked"
            v-if="newYn == 'N'"
            >연혁 구간 삭제
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense align="center">
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="1" >
        <div class="pt-0">
          <span>연혁 구간명 *</span>
        </div>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="pendingHistTerm.term_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="3" ><span>한 줄에 한글 7자, 숫자/영문자 14자까지 표시됩니다.</span></v-col>
      <v-col cols="1" >
        <div class="pt-0">
          <span>표시 순서</span>
        </div>
      </v-col>
      <v-col cols="1">
        <v-text-field
          v-model="pendingHistTerm.disp_odr"
          hide-details
          dense
          outlined
          class="pa-0 ma-0 text-field-transparent"
        ></v-text-field>
      </v-col>
      <v-col cols="1" >
        <div class="pt-0">
          <span>정렬 방법</span>
        </div>
      </v-col>
      <v-col cols="1">
        <v-select
          class="pa-0 ma-0"
          v-model="pendingHistTerm.sort_mthd"
          :items="sortMthdList"
          item-text="cmn_cd_nm"
          item-value="cmn_cd"
          hide-details
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="2">
        <div class="pt-0">
          <span>연혁 목록</span>
        </div>
      </v-col>
      <v-col cols="10">
        <div class="f-r pl-1">
          <v-btn
            small
            color="green lighten-2"
            class="white--text"
            @click="addHistBtnClicked"
          >추가</v-btn>
        </div>
        <div class="f-r">
          <v-btn
            small
            color="red lighten-2"
            class="white--text"
            @click="deleteHistListBtnClicked"
          >선택 삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="12" >
        <div>
          <v-data-table
            :headers="gridHeader"
            :items="pendingHistList"
            class="elevation-1 dTable-1"
            :items-per-page="20"
            hide-default-footer
            disable-pagination>
            <template v-slot:[`item.select_yn`]="{ item }">
              <v-checkbox
                v-model="item.select_yn"
                class="pa-0 ma-0"
                hide-details
                dense
              ></v-checkbox>
            </template>
            <template v-slot:[`item.hist_bgn_dt`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.hist_bgn_dt"
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>
            <template v-slot:[`item.hist_end_dt`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.hist_end_dt"
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>
            <template v-slot:[`item.hist_cn`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.hist_cn"
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>
            <template v-slot:[`item.odr`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.odr"
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>
          </v-data-table>
        </div>
      </v-col>

    </v-row>  

  </v-container>  
</template>

<script>

const genEmptyHistTerm = () => ({
  term_sno: null,
  term: "",
});

export default {
  data(){
    return{
      mberSno: null,

      newYn: this.$route.query.newYn,
      termSno: this.$route.query.termSno,

      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%", sortable:false },
        { text: '연혁 시작일', value: 'hist_bgn_dt', width: "10%" },
        { text: '연혁 종료일', value: 'hist_end_dt', width: "10%" },
        { text: '연혁 내용', value: 'hist_cn', width: "65%" },
        { text: '표시 순서', value: 'odr', width: "10%" },
      ],
      // gridList: [],
      
      histTerm: {},
      pendingHistTerm: {},
      sortMthdList: [],

      histList: [],
      pendingHistList: [],
      histIdx: 1,
      selectedHist: null,
    }
  },
  computed: {
    isOnPending() {
      console.log("isOnPending");
      return JSON.stringify(this.histTerm) != JSON.stringify(this.pendingHistTerm)
        || JSON.stringify(this.histList) != JSON.stringify(this.pendingHistList)
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    // console.log("gnrtnSno:", this.gnrtnSno);
    console.log("historyTermDetail created:");

    this.getSortMthdList();

    if(this.newYn == 'Y') {
      this.histTerm = genEmptyHistTerm(this.menuSno);
      this.pendingHistTerm = JSON.parse(JSON.stringify(this.histTerm));
    } 
  },
  mounted() {
    if(this.newYn == 'N') {
      this.getHistoryTerm();
    } 
  },
  methods: {
    async getSortMthdList() {
      this.sortMthdList = await this.$getCmnCdList("sort_mthd");
    },
    gotoList() {
      // this.$router.go(-1)
      // 아래 path 설정 시 select box 에 값 설정이 안됨
      // this.$router.push({path:"preRegistList", query: { confrSno:this.confrSno }});
      this.$router.push({name:"historyTermList", query: {  }});
    },
    getHistoryTerm() {
      console.log("this.termSno(in getHistoryTerm):", this.termSno);
      const params = new FormData();

      params.append("term_sno", this.termSno);

			this.$http.post("/admin/getSoctyHistTerm", params).then((res) => {
        console.log("res.data: ", res.data);
        const histTerm = res.data.result.histTerm;

        this.histTerm = histTerm;
        this.pendingHistTerm = JSON.parse(JSON.stringify(histTerm));

        this.histList = [];

        if(res.data.result.histList != null) {
          res.data.result.histList.forEach((e) => {
            this.histList.push({new_yn:'N', select_yn:false, idx:this.histIdx++, hist_sno:e.hist_sno, term_sno:e.term_sno, odr:e.odr, 
              hist_bgn_dt:e.hist_bgn_dt, hist_end_dt:e.hist_end_dt, hist_cn:e.hist_cn});
          })
        }
        this.pendingHistList = JSON.parse(JSON.stringify(this.histList));
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 연혁 구간명
      if(this.pendingHistTerm.term_nm == null || this.pendingHistTerm.term_nm == "") {
        alert("연혁 구간명은 필수 입력값입니다.");
        return false;
      }

      return true;
    },
    saveNStayBtnClicked() {
      this.saveHistoryTerm("stay");
      // console.log("out of save");
    },
    saveNMoveBtnClicked() {
      this.saveHistoryTerm("move");
      // console.log("out of save");
    },
    saveHistoryTerm(action) {
      // console.log("saveHistoryTerm:");

      // if(confirm("저장하시겠습니까?")) {
        const isValid = this.validateInput();

        if(isValid) {
          const params = new FormData();

          if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
          params.append('new_yn', this.newYn);

          params.append("histTerm", JSON.stringify(this.pendingHistTerm));
          params.append("histList", JSON.stringify(this.pendingHistList));

          this.$http.post("/admin/upsertSoctyHistTerm", params).then((res) => {
            console.log(res.data);
            alert("저장되었습니다.");
            
            if(this.newYn == 'Y') {
              this.termSno = res.data.result.term_sno;
              this.newYn = 'N';
              console.log("this.termSno(in save):", this.termSno);
            }

            // console.log("in save: after alert 1");
            if(action == "stay") {
              this.getHistoryTerm();
            } else if(action == "move") {
              this.gotoList();
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      // }
    },
    deleteHistTermBtnClicked() {
      console.log("deleteHistTerm");

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        // if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
        params.append("histTerm", JSON.stringify(this.pendingHistTerm));

        this.$http.post("/admin/deleteSoctyHistTerm", params).then((res) => {
          console.log(res.data);

          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.gotoList();
          } else {
            alert("삭제할 수 없습니다.");
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },

    // 연혁 추가
    addHistBtnClicked() {
      let maxOdr = 0;

      this.pendingHistList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });
      
      this.pendingHistList.push({new_yn:'Y', select_yn:false, idx:this.histIdx++, hist_sno:null, term_sno:null, odr:maxOdr + 1, 
              hist_bgn_dt:null, hist_end_dt:null, hist_cn:null});
    },
    deleteHistListBtnClicked() {
      console.log("deleteHistListBtnClicked");
      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.pendingHistList.length; i++) {
      //   if(this.pendingHistList[i].select_yn) {
      //     isSelected = true;

      //     if(this.pendingHistList[i].new_yn == 'N') {
      //       deleteList.push(this.pendingHistList[i]);
      //     }
      //   }
      // }
      deleteList = this.pendingHistList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && e.new_yn == 'N';
      });

      // 데이터 검증
      deleteList.forEach((e) => {
        console.log("deleteList:", e);
      });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteSoctyHistList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getHistoryTerm();
            this.pendingHistList = this.pendingHistList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getHistoryTerm();
          this.pendingHistList = this.pendingHistList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
  },
}
</script>

<style>

</style>