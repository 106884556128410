<template>
  <v-container fluid>
      <template>
        <v-row
          class="mt-2 mb-2"
          align="center"
          justify="center"
        >
          <v-col cols="8" class="d-flex align-center">
            <div class="wid-150p mr-2">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                style="font-size: 13px;"
                class="dreamFont4"
                :items="filterList"
                item-value="cmn_cd"
                item-text="cmn_cd_nm"
                v-model="defaultFilter">
              </v-select>
            </div>
            <div class="wid-400p mr-2">
              <v-text-field
                outlined
                flat
                solo
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                class="dreamFont4"
                v-model="keyword"
                @keyup.enter="searchBtnClicked"
              >
              </v-text-field>
            </div>
            <div>
              <v-btn
                outlined
                height="30px" 
                style="font-size: 13px;"
                class="dreamFont4"
                @click="searchBtnClicked"
              >
                검색
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col cols="12" class="pa-0 d-flex align-center">
            <h2 class="mr-3 dreamFont4">검색결과</h2>
            <span class="dreamFont4">({{searchCnt}}건)</span>
          </v-col>
        </v-row>
      </template>

      <template>
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="pa-0 mt-2" style="border-top: 1px solid black;">
            <v-data-table
              :headers="gridHeader"
              class="elevation-0"
              :items="searchList"
              :items-per-page.sync="itemsPerPage"
              :page.sync="page"
              disable-sort
            >
              <template v-slot:no-data>
                <v-row class="pa-0">
                  <v-col cols="12" class="pa-2">
                    <span class="font-weight-bold" style="font-size: 13px;"> 
                      검색된 데이터가 없습니다.
                    </span>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template> 
      
      

  </v-container>
</template>

<script>

export default {
  data() {
    return {
      defaultFilter: 'all',
      filterList: [
        {cmn_cd : 'all', cmn_cd_nm : '전체'},
        {cmn_cd : 'name', cmn_cd_nm : '이름'},
        {cmn_cd : 'blng', cmn_cd_nm : '소속'},
        {cmn_cd : 'email', cmn_cd_nm : '이메일'},
      ],
      descFilterList: [
        {cmn_cd : 0, cmn_cd_nm : '최신등록순'},
      ],
      descFilter: {
        cmn_cd : 0, cmn_cd_nm : '최신등록순'
      },
      gridHeader: [
        { text: '번호', value:'no', width: "10%", align: 'center', },
        { text: '회원명', value:'mber_nm', width: "25%", align: 'center',},
        { text: '소속/부서', value:'blng_nm', width: "25%", align: 'center', },
        { text: '이메일', value:'email', width: "40%", align: 'center', }
      ],
      keyword: '',
      itemsPerPageArray: [10, 20],
      page: 1,
      itemsPerPage: 10,
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      emitData: {},
      searchList: [],
      searchCnt: 0,
    }
  },
  created() {
    const params = new FormData();
    params.append("mber_sno", this.mberSno);
    params.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {

      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push('/');
      }
      // console.log(res.data);
      this.emitData.menu_sno = res.data.menu_sno;
      this.$emit('setData', this.emitData);

    }).catch(
      function (error) {
      console.log(error);
      }
    )
  },
  methods: {
    searchBtnClicked() {
      const params = new FormData();
      params.append("searchType", this.defaultFilter);
      params.append("keyword", this.keyword);

      this.$http.post('/sub/getMberSearchList', params).then((res) => {
        console.log(res.data);
        this.searchList = res.data.searchList;
        this.searchCnt = res.data.searchCnt;
      }).catch(
        function (error) {
        console.log(error);
        }
      )
    }
  }
}
</script>
<style scoped>
  .hoverCheck:hover {
    cursor: pointer !important;
  }

  ::v-deep.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 13px !important;
    font-weight: 900 !important;
    padding: 13px 6px 13px 6px;
    font-family: dreamFont4;
  }

  ::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 13px !important;
    padding: 13px 6px 13px 6px;
    max-width: 100% !important; 
    font-family: dreamFont3;
  }

  ::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td > div > span {
    font-size: 13px !important;
    font-family: dreamFont3 !important;
  }

  ::v-deep .v-list-item__content > .v-list-item__title {
    font-family: dreamFont4 !important;
  }

  label {
  font-size: 13px !important;
  }

  .dreamFont3 {
    font-family: dreamFont3 !important;
  }

  .dreamFont4 {
    font-family: dreamFont4 !important;
  }

  ::v-deep .v-data-table > .v-data-footer {
    font-family: dreamFont3 !important;
  }
</style>