<template>
  <v-card 
    :style="this.windowWidth > 768 ? 'max-width:450px' : 'max-width:370px'"
    class="notice-popup tborder bg-lightgrey-2" 
    v-if="popupShow">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 pr-2 pt-2">
        <div class="hei-30p d-flex justify-end wid-100">
          <v-btn small icon class="" @click="popupClose">
            <v-icon class="color-lightgreen">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <V-col cols="12" class="pa-0">
        <div class="">
          <div style="text-align: center;">
            <span class="fontSize-3 font-weight-bold pl-3 pr-3" style="display:block;">
              {{ popupResData.bbst_ttl }}
            </span>
          </div>
        </div>
      </V-col>
      <v-col cols="12" class="pa-3 tiny">
        <div v-html="popupResData.bbst_cn"></div>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols="12" class="">
        <div class="d-flex justify-center wid-100">
          <v-btn class="elevation-0 bg-lightgreen">
            <span class="fontSize-2 font-weight-bold white--text" @click="goDetailPage">자세히 보기</span>
          </v-btn>
        </div>
      </v-col>

    </v-row>
    <v-row class="ma-0 bg-darkgrey hei-30p" align="center">
      <v-col cols="12" class="pa-0">
        <div class="wid-100 d-flex align-center">
          <div class="d-flex align-center ml-2">
            <span class="white--text mr-1">하루 동안 열지 않기</span>
            <input type="checkbox" class="chk_closeDay wid-15p hei-15p" @click="checkModalToday">
          </div>
          <v-spacer></v-spacer>
          <div class="d-flex align-center" >
            <v-btn small outlined class="color-grey ma-0" @click="popupClose">close</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  // props:['popupResData','cookieKey'],
  props: {popupResData:Object},
  data(){
    return{
      dialog: true,
      popupShow: true,
      basic_path: '',
      nowDate: '',
      windowWidth: window.innerWidth,
    }
  },
  created(){
    console.log("main popup List Res Data " + this.popupResData);

    const today = new Date();
    let year = today.getFullYear(); // 2023
    this.now_year = year;
    let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 06
    
    let day = today.getDate().toString().padStart(2, '0'); // 18
    this.nowDate = year + month + day; // 20230618
    

  },
  mounted() {
    // var checkPopupArr = this.$session.get('popupKeyArr');
    // this.fakeDate = '20220222';
    
    //오늘 체크된것만.
    // if(checkPopupArr) {
    //   checkPopupArr = checkPopupArr.filter((e) => e.hideDate == '20240223');
    //   this.$session.set('popupKeyArr',checkPopupArr);
    //   console.log("che opoi arr ", this.$session.get('popupKeyArr'));
    // }
    


    // if(checkPopupArr){
    //   for (var i = 0; i < checkPopupArr.length; i++) {
    //     let checkPopupKey = checkPopupArr[i].popupKey
    //     let checkPopupHideDate = checkPopupArr[i].hideDate

    //     if(this.popupResData.bbst_sno == checkPopupKey && this.fakeDate == checkPopupHideDate){
    //       this.popupShow = false;
    //       return;
    //     } else if(this.popupResData.bbst_sno == checkPopupKey && this.fakeDate != checkPopupHideDate){
    //       let popupKeyArrEmpty = [{popupKey:'', hideDate:''}];

    //       this.checkPopupArr = checkPopupArr.filter((e) => {e.checkPopupArr = popupKeyArrEmpty});
    //       console.log("hello bye ", this.checkPopupArr);
    //       this.popupShow = true;
    //       return;
    //     }
    //   }
    // }
    this.popupShow = true;
    
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    
  },
  methods: {
    popupClose(){
      this.popupShow = false;
    },
    checkModalToday(){

      var getPopupKeyArr = this.$session.get('popupKeyArr');
      console.log(getPopupKeyArr);

      let popupKeyArr = [];
      // popupKeyArr.push({popupKey:this.popupResData.bbst_sno, hideDate:this.nowDate});
      popupKeyArr.push({popupKey:this.popupResData.bbst_sno, hideDate:this.nowDate});
      if(getPopupKeyArr) {
        let getPopupKeyArrNew = [
          ...getPopupKeyArr,
          ...popupKeyArr
        ]; 
        this.$session.set('popupKeyArr', getPopupKeyArrNew);
      } else {
        this.$session.set('popupKeyArr', popupKeyArr);
      }

      var getPopupKeyArrNew = this.$session.get('popupKeyArr');
      console.log(":::::::: ggggg ::::::::" ,getPopupKeyArrNew);
      
      this.popupShow = false;
    },
    goDetailPage(){
      if(this.popupResData.get_perm_yn == 'Y') {
        this.$router.push(this.popupResData.pop_basic_path+`/detail?bbst_sno=`+this.popupResData.bbst_sno);
      } else {
        alert('권한이 없습니다.');
        return;
      }
    }
  }
  }
</script>

<style scoped>
.notice-popup {
    position: absolute;
    width:100%;
    height: auto;
    background-color:#fff;
}

.notice-popup > .wrapper {
    position:relative;
    margin:15px;
}

.notice-popup > .wrapper > .created{
    margin:25px 0;
}

.notice-popup > .wrapper > .contents{
    margin:25px 0;
}

</style>