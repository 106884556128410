<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center">
          <v-col cols="3">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="searchWord" 
              outlined
              dense
              hide-details
              placeholder="검색어"
              background-color="white">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text" 
              @click="searchBtnClicked">
              검색
            </v-btn>
          </v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <div dense class="pl-3 pr-3">
            <span v-for="(item) in payYearList" :key="item.year" 
              :style="item.year == year ? 'color:blue;' : 'cursor:pointer'" 
              @click="onYearClick(item.year)">{{ item.year }} &nbsp;&nbsp;
            </span>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              :search="search"
              class="elevation-1"
              style="cursor: pointer"
            >
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              
              <template v-slot:[`item.prod_nm`]="{ item }">
                <div @click="payRowClicked(item)">{{ item.prod_nm }}</div>
              </template>

              <template v-slot:[`item.prod_type_nm`]="{ item }">
                <div @click="payRowClicked(item)">{{ item.prod_type_nm }}</div>
              </template>
              <template v-slot:[`item.mber_nm`]="{ item }">
                <div @click="payRowClicked(item)">{{ item.mber_nm }}</div>
              </template>

              <template v-slot:[`item.prod_amt`]="{ item }">
                <div @click="payRowClicked(item)">{{ item.prod_amt }}</div>
              </template>

              <template v-slot:[`item.pay_mthd_nm`]="{ item }">
                <div @click="payRowClicked(item)">{{ item.pay_mthd_nm }}</div>
              </template>

              <template v-slot:[`item.pay_yn`]="{ item }">
                <div @click="payRowClicked(item)">{{ item.pay_yn }}</div>
              </template>

              <template v-slot:[`item.reg_dt`]="{ item }">
                <div >{{ item.reg_dt }}</div>
              </template>
              <!-- <template v-slot:[`item.prod_nm`]="{item}">
                <v-text-field
                  v-model="item.prod_nm"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="payRowClicked(item)"
                ></v-text-field>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.paymentList.radioPayYn"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>결제 여부로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.paymentList.radioProdType"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>상품 유형으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in prodTypeList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      mberChkDialog: false,
      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%", sortable:false },
        { text: '상품명', value: 'prod_nm', width: "25%" },
        { text: '상품유형', value: 'prod_type_nm', width: "15%" },
        { text: '회원', value: 'mber_nm', width: "15%" },
        { text: '결제금액', value: 'prod_amt', width: "7%" },
        { text: '결제방법', value: 'pay_mthd_nm', width: "7%" },
        { text: '결제여부', value: 'pay_yn', width: "7%" },
        { text: '등록일자', value: 'reg_dt', width: "9%" },
        // { text: '청구일련번호', value: 'bill_sno', width: "1%", visible:false },
      ],
      gridList: [],
      prodList: [],
      
      prodTypeList: [],
      siteOptn: {},

      payList: [],
      payYearList: [],
      year: "",

      search: "",
      searchWord: "",
    }
  },
  created() {
  },
  mounted(){
    this.getProdTypeList();
    this.getPaymentYearList();  
    // this.getPaymentList();    
  },
  methods: {
    async getProdTypeList() {
      this.prodTypeList = await this.$getCmnCdList("prod_type");
    },
    getPaymentYearList() {
      this.$http.get("/admin/getPayYearList").then((res) => {
        console.log("res.data.result:", res.data.result);
        this.payYearList = res.data.result.payYearList;
        this.siteOptn = res.data.result.siteOptn;

        this.year = this.payYearList[0].year;
        this.getPaymentList();
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    onYearClick(year) {
      this.year = year;
      this.getPaymentList();
    },
    getPaymentList() {
      let params = new FormData();
      params.append("year", this.year);
      params.append("pg_id", this.siteOptn.pg_id);

      this.$http.post("/admin/getPayList", params).then((res) => {
        // console.log("res.data.result:", res.data.result);

        res.data.result.payList.forEach((e) => {
          e.select_yn = false;
        });

        // this.gridList = res.data.result.prodList;
        this.payList = res.data.result.payList;
        this.filterList();

        // this.siteOptn = res.data.result.siteOptn;
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    payRowClicked(event) {
      // console.log("payRowClicked", event);
      this.$router.push({name:"paymentDetail", query: { prodType:event.prod_type, billSno:event.bill_sno, pgId:this.siteOptn.pg_id }});
    },
    deleteBtnClicked() {
      console.log("deleteProductList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: prod_sno[" + e.prod_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteSoctyProdList", params).then((res) => {
            console.log(res.data);

            if(res.data.code == 0) {
              alert("삭제되었습니다.");
              // this.getSoctyProdList();
              this.gridList = this.gridList.filter((e) => {
                return !(e.select_yn);
              });
            } else if(res.data.code == -9003) {
              let inUseList = [];
              res.data.inUseList.forEach((e) => {
                inUseList.push(e.prod_nm);
              });
              alert("삭제할 수 없습니다. 상품이 사용 중입니다.\n" + inUseList.join());
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    radioFilterChanged() {
      this.filterList();
    },
    filterList() {
      this.gridList = this.payList.filter((e) => {
        // console.log("e:", e);
        if( (this.$store.state.paymentList.radioPayYn == "모두" || e.pay_yn == this.$store.state.paymentList.radioPayYn) &&
            (this.$store.state.paymentList.radioProdType == "모두" || e.prod_type == this.$store.state.paymentList.radioProdType) 
          ) return true;
      });
    },
    searchBtnClicked() {
      this.search = this.searchWord;
    },
  }
}
</script>
  
<style>

</style>