<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="12">
        <v-row class="" align="center">
          <v-col cols="12">
            <div>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                @click="saveBtnClicked"
                >저장 후 유지
              </v-btn>
              <v-btn 
                small 
                color="red lighten-2" 
                class="white--text ml-3 f-r"
                @click="selRemoveClicked"
                >선택 삭제
              </v-btn>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                @click="menuDetailClicked"
                >메뉴 추가
              </v-btn>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text f-r"
                @click="menuTreeMagClicked"
                >메뉴 트리 관리
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="menuHeader"
              :items="menuList"
              class="elevation-1"
              :items-per-page="itemsPerPage"
              hide-default-footer
              style="cursor: pointer"
            >
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <template v-slot:[`item.menu_nm`]="{item}">
                <div class="align-center">
                  <div 
                    style="float:left;"
                    v-if="item.menu_lvl > 1"
                    :class='item.menu_lvl == 2 ? "pa-0 ma-0 ml-6" : "pa-0 ma-0 ml-12"'
                  >ㄴ
                  </div>
                  <v-list-item-title
                    v-model="item.menu_nm"
                    hide-details
                    readonly
                    solo
                    flat
                    class=" ma-0 text-field-transparent fontSize-0"
                    @click="moveMenuDetail(item.menu_sno)"
                  >
                  {{ item.menu_nm }}
                  </v-list-item-title>
                </div>
              </template>
              <template v-slot:[`item.menu_nm_en`]="{item}">
                <v-list-item-title
                  v-model="item.menu_nm_en"
                  hide-details
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                >
                {{ item.menu_nm_en }}
                </v-list-item-title>
              </template>
              <template v-slot:[`item.addChild`]="{item}" >
                <v-btn 
                v-model="item.addChild"
                small
                @click="addChilClicked(item)"
                v-if="item.menu_lvl != 3"
                >Add Child
                </v-btn>
              </template>
              <template v-slot:[`item.resrc_nm`]="{item}">
                <v-list-item-title
                  v-model="item.resrc_nm"
                  hide-details
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                >
                {{ item.resrc_nm }}
                </v-list-item-title>
              </template>
              <!-- <template v-slot:[`item.page_type`]="{item}">
                <v-select
                  class="f-l pa-0 ma-0 wid-100 input-hei-d" 
                  :items="pageTypeList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="item.page_type"
                  background-color="white"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </template> -->
              <template v-slot:[`item.disp_yn`]="{ item }">
                <v-checkbox
                  v-model="item.disp_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                  @change="chgList(item)"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.use_ko_menu_yn`]="{ item }">
                <v-checkbox
                  v-model="item.use_ko_menu_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                  @change="chgList(item)"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.use_en_menu_yn`]="{ item }">
                <v-checkbox
                  v-model="item.use_en_menu_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                  @change="chgList(item)"
                ></v-checkbox>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <template>
			<v-dialog
				v-model="menuTreeDialog"
				max-width="400px"
				max-height="700px"
			>
				<v-card style="text-align:center;">
					<v-app-bar 
						class="fixed-bar" 
						color="blue lighten-2" 
						max-height="60px">
						<v-toolbar-title
							class="white--text">
							메뉴 트리 관리
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn
							color="white"
              @click="menuTreeSaveClicked"
            >
							저장
						</v-btn>
					</v-app-bar>
          <template>
            <vue-nestable
            v-model="menuTreeList"
            :maxDepth="3"

            >
              <vue-nestable-handle
                slot-scope="{ item }"
                :item="item">
                {{ item.menu_nm }}
              </vue-nestable-handle>
            </vue-nestable>
          </template>
				</v-card>
			</v-dialog>
		</template>
	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      menuHeader: [
        { text: '선택', value: 'select_yn', width: "4%" },
        { text: '메뉴명', value: 'menu_nm', width: "20%" },
        { text: '메뉴명(영문)', value: 'menu_nm_en', width: "18%" },
        { text: 'Add Child', value: 'addChild', width: "3%" },
        { text: '페이지ID', value: 'resrc_nm', width: "13%" },
        { text: '페이지유형', value: 'page_type_nm', width: "8%" },
        { text: '노출 여부', value: 'disp_yn', width: "6%" },
        { text: '한국어', value: 'use_ko_menu_yn', width: "5%" },
        { text: '영어', value: 'use_en_menu_yn', width: "5%" },
      ],
      menuList: [],
      pageTypeList: [],
      sameMenuActv: true,
      subMenuActv: true,
      menuTreeList: [],
      chgMenuList: [],
      itemsPerPage: -1,
      mberSno: '',
      menuTreeDialog: false,
    }
  },
  created() {
    //공통코드 조회
    // this.getPageTypeList();
    this.mberSno = this.$session.get("mberSno");
    this.getMenuList();
  },
  mounted(){
    
  },
  methods: {
    menuDetailClicked() {
      this.$router.push({path:"menuDetail"});
    },//menuDetailClicked end
    saveBtnClicked() {
      //저장 버튼 클릭시
      // if(confirm('저장하시겠습니까?')) {
        if(this.chgMenuList.filter((e) => e.use_en_menu_yn && !e.menu_nm_en).length > 0) {
          alert("메뉴명(영문)은 필수 입력값입니다.");
          return;
        }

        this.chgMenuList.forEach((e) => {
          e.disp_yn = e.disp_yn == true ? 'Y' : 'N';
          e.use_ko_menu_yn = e.use_ko_menu_yn == true ? 'Y' : 'N';
          e.use_en_menu_yn = e.use_en_menu_yn == true ? 'Y' : 'N';
        })
        let params = new URLSearchParams();
        params.append("menuList", JSON.stringify(this.chgMenuList));
        params.append("mber_sno", this.mberSno);
        
        this.$http.post("/admin/updateMenuListChk", params).then((res) => {
          if(res.data.code == 0) {
            alert('저장되었습니다.');
            this.$router.go(0);
          }
          
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }

    },//saveBtnClicked end
    getMenuList() {
      this.$http.get("/admin/getMenuList").then((res) => {    
      
        this.menuList = res.data.menuList;
        // console.log(this.menuList);
        this.menuList.forEach((e) => {

          e.disp_yn = e.disp_yn == 'Y' ? true : false;
          e.use_ko_menu_yn = e.use_ko_menu_yn == 'Y' ? true : false;
          e.use_en_menu_yn = e.use_en_menu_yn == 'Y' ? true : false;

        });
        
      }).catch((error) => {
          console.log(error);
        }
      )
    },//getMenuList end
    moveMenuDetail(menuSno) {
      this.$router.push({name:'menuDetail', query: { menuSno: menuSno }});
    },
    chgList(item) {
      const itemLen = this.chgMenuList.filter((e) => e.menu_sno == item.menu_sno).length;
      if(itemLen == 0) {
        this.chgMenuList.push(item);
      }
      // this.chgList
    },//chgList end
    addChilClicked(item) {
      this.$router.push({name:"menuDetail", query: { upperMenuSno: item.menu_sno }, params: {upperMenuNm: item.menu_nm, menuLvl : item.menu_lvl}});
    },//addChilClicked end
    selRemoveClicked() {
      const selLen = this.menuList.filter((e) => e.select_yn == true).length;
      if(selLen > 0) {
        if(confirm('하위 메뉴가 존재하는경우, 하위메뉴까지 삭제됩니다.\n삭제하시겠습니까?')) {
          const selMenuList = this.menuList.filter((e) => e.select_yn == true);

          let params = new URLSearchParams();
          params.append("menuList", JSON.stringify(selMenuList));
          params.append("mber_sno", this.mberSno);
          
          this.$http.post("/admin/updateMenuDel", params).then((res) => {
            if(res.data.code == 0) {
              alert('삭제되었습니다.');
              this.$router.go(0);
            }
            
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      } else {
        alert('선택된 행이 없습니다.');
        return;
      }
      
    },//selRemoveClicked end
    menuTreeMagClicked() {
      
      this.menuTreeList = this.menuList.filter((e) => e.menu_lvl == 1);
      const menu_lvl2 = this.menuList.filter((e) => e.menu_lvl == 2);
      const menu_lvl3 = this.menuList.filter((e) => e.menu_lvl == 3);
      menu_lvl2.forEach((e) => {
        e.children = [];
        menu_lvl3.forEach((f) => {
          if(e.menu_sno == f.upper_menu_sno) {
            f.id = f.menu_sno;
            e.children.push(f);
          }
        })
      });

      this.menuTreeList.forEach((e) => {
        e.id = e.menu_sno;
        e.children = [];
        menu_lvl2.forEach((f) => {
          if(e.menu_sno == f.upper_menu_sno) {
            f.id = f.menu_sno;
            e.children.push(f);
          }
        })
      })  
      
      this.menuTreeDialog = true;
      console.log("menuTreeList :: ",this.menuTreeList);
    },//menuTreeMagClicked end 
    menuTreeSaveClicked() {
      let dupCnt = 0;
      this.menuTreeList.some((e) => {
        let menu1_len = this.menuTreeList.filter((f) => f.resrc_nm == e.resrc_nm).length;
        if(menu1_len > 1) {
          alert('페이지ID가 중복되어 저장할수 없습니다.');
          dupCnt++;
          return true;
        }
        let menu2_list = e.children;
        
        menu2_list.some((g) => {
          
          let menu2_len = menu2_list.filter((h) => h.resrc_nm == g.resrc_nm).length;
          if(menu2_len > 1) {
            dupCnt++;
            return true;
          }
          let menu3_list = g.children;
          menu3_list.some((i) => {
            let menu3_len = menu3_list.filter((j) => j.resrc_nm == i.resrc_nm).length;
            if(menu3_len > 1) {
              dupCnt++;
              return true;
            }
          })
          if(dupCnt > 0) {
            return true;
          }
        })
        if(dupCnt > 0) {
          alert('페이지ID가 중복되어 저장할수 없습니다.');
          return true;
        }
      });
      
      if(dupCnt == 0) {
        let params = new URLSearchParams();
        params.append("menuTreeList", JSON.stringify(this.menuTreeList));
        params.append("mber_sno", this.mberSno);
        
        this.$http.post("/admin/updateMenuOdr", params).then((res) => {
          if(res.data.code == 0) {
            alert('저장되었습니다.');
            this.$router.go(0);
          } else {
            alert('저장오류');
          }

        })
        .catch(function (error) {
            console.log(error);
          }
        );
        
      }//if end

    },//menuTreeSaveClicked end
  }
}
</script>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 13px;
  padding: 2px 16px 0px 16px;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 13px;
  padding: 2px 16px 0px 16px;
}

/* button > span {
  font-size: 13px;
} */

</style>