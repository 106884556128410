<template>
  <v-app>
    <template >
      <div style="margin-top:8%;margin-bottom:1%;">

        <v-container
          style="border: 1px solid black; height:100%; margin: 0 auto; width:35%;" 
          class="" 
          v-if="windowWidth > 1220">
          <v-row justify="center" class="ma-0">

          <v-col cols="6" class="pa-0">
            <v-btn
              color="light gray"
              style="height: 100%; width: 100%; box-shadow: none;"
              class="pa-0 btn-ma-web f-r"
              hide-details
              to="/findId"
            >아이디 찾기</v-btn>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn 
              color="white"
              style="height: 100%; width: 100%; box-shadow: none;"
              class="pa-0 btn-ma-web f-r"
              hide-details
              to="/findPwd">
              비밀번호 찾기
            </v-btn>
          </v-col>

          </v-row>
          <v-row justify="center" class="ma-0 pt-5">
            <v-col cols="7" style="color:red;">
              등록된 이메일로 임시 비밀번호 보내드립니다.
            </v-col>
            <v-col cols="7">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense 
                  outlined
                  label="아이디"
                  placeholder="아이디를 입력해주세요."
                  v-model="loginId"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-col cols="7">
              <v-text-field
                class="wid-100"
                hide-details
                dense 
                outlined
                label="이메일"
                placeholder="이메일을 입력해주세요."
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>

            <v-col cols="7" style="margin-bottom: 30px">
              <v-btn
                color="blue lighten-2"
                class="pa-0 ma-0 white--text f-r wid-100"
                hide-details
                @click="findIdBtnClicked"
              >
                확인
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-container
          style="border: 1px solid rgb(0, 0, 0); height:100%; margin: 0 auto; width:40%;" 
          class="" 
          v-if="windowWidth < 1221 && windowWidth > 768">
          <v-row justify="center" class="ma-0">

          <v-col cols="6" class="pa-0">
            <v-btn
              color="light gray"
              style="height: 100%; width: 100%; border: none; box-shadow: none;"
              class="pa-0 btn-ma-ta f-r"
              hide-details
              to="/findId"
            >아이디 찾기</v-btn>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn 
              color="white"
              style="height: 100%; width: 100%; border:none; box-shadow: none;"
              class="pa-0 btn-ma-ta f-r"
              hide-details
              to="/findPwd">
              비밀번호 찾기
            </v-btn>
          </v-col>

          </v-row>
          <v-row justify="center" class="ma-0 pt-3">
            <v-col cols="7" style="color:red;">
              등록된 이메일로 임시 비밀번호 보내드립니다.
            </v-col>
            <v-col cols="7">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense 
                  outlined
                  label="아이디"
                  placeholder="아이디를 입력해주세요."
                  v-model="loginId"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-col cols="7">
              <v-text-field
                class="wid-100"
                hide-details
                dense 
                outlined
                label="이메일"
                placeholder="이메일을 입력해주세요."
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>

            <v-col cols="7" style="margin-bottom: 25px">
              <v-btn
                color="blue lighten-2"
                class="pa-0 ma-0 white--text f-r wid-100"
                hide-details
                @click="findIdBtnClicked"
              >
                확인
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-container 
          v-if="windowWidth < 767 && windowWidth > 350" 
          style="border: 1px solid rgb(0, 0, 0); height:100%; margin: 0 auto; width:60%;" 
          class="">
          <v-row justify="center" class="ma-0">

          <v-col cols="6" class="pa-0">
            <v-btn
              color="light gray"
              style="width: 100%; border: none; box-shadow: none;"
              class="pa-0 btn-ma-mo f-r"
              hide-details
              to="/findId"
            >아이디 찾기</v-btn>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn 
              color="white"
              style="width: 100%; border:none; box-shadow: none;"
              class="pa-0 btn-ma-mo f-r"
              hide-details
              to="/findPwd">
              비밀번호 찾기
            </v-btn>
          </v-col>

          </v-row>
          <v-row justify="center" class="ma-0">
            <v-col cols="7" style="color:red;">
              등록된 이메일로 임시 비밀번호 보내드립니다.
            </v-col>
            <v-col cols="7">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense 
                  outlined
                  label="아이디"
                  placeholder="아이디를 입력해주세요."
                  v-model="loginId"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-col cols="7" >
              <v-text-field
                class="wid-100"
                hide-details
                dense 
                outlined
                label="이메일"
                placeholder="이메일을 입력해주세요."
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>

            <v-col cols="7" style="margin-bottom: 15px;">
              <v-btn
                color="blue lighten-2"
                class="pa-0 ma-0 white--text f-r wid-100"
                hide-details
                @click="findIdBtnClicked"
              >
                확인
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>

    <div v-if="isLoading" class="loading-container">
      <div class="spinner-loading">
        <Fade-loader />
      </div>
    </div>
  </v-app>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'
export default {
  components: { FadeLoader },
  data() {
    return {
      windowWidth: window.innerWidth,
      activeWidth:{ width:this.windowWidth},
      loginId: '',
      email: '',
      isLoading: false,
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created() {

  }, 
  methods: {
      findIdBtnClicked() {
        this.validationCheck();
      },//findIdBtnClicked end
      validationCheck() {
          if(!this.loginId.trim() ) {
            alert('아이디 입력해주세요.');
            return;
          } else if(!this.email.trim()) {
            alert('이메일 입력해주세요.');
            return;
          }
            
          const formData = new FormData();
          formData.append('login_id', this.loginId.trim());
          formData.append('email', this.email.trim());
          this.isLoading = true;
          this.$http.post("/mber/getMberPwd", formData).then((res) => {
            if(res.data.code == 0) {
              alert("이메일로 임시 비밀번호 발송했습니다.");
              this.$router.push('/login');
            } else {
              alert("일치하는 정보가 없습니다.");
            }
            this.isLoading = false;
          }).catch(function(error) {
              console.log(error);
          });//axios End

      },//validationCheck end
  }
}
</script>

<style scoped>
.spinner-loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

.btn-ma-web {
  margin: 0px 0px 50px 0px;
  border-radius: 0px;
  border: none;
  outline: none;
  background-color: inherit ;
  cursor: pointer;
  color: white;
}

.btn-ma-ta {
  margin: 0px 0px 35px 0px;
  border-radius: 0px;
  border: none;
  outline: none;
  background-color: inherit ;
  cursor: pointer;
  color: white;
}

.btn-ma-mo {
  margin: 0px 0px 20px 0px;
  border-radius: 0px;
  border: none;
  outline: none;
  background-color: inherit ;
  cursor: pointer;
  color: white;
}

.pa-0 > .theme--light .v-btn {
  background-color: white !important;
}

::v-deep .btn-ma-web > .v-btn__content {
  color: black !important;
}
::v-deep .btn-ma-ta > .v-btn__content {
  color: black !important;
}
::v-deep .btn-ma-mo > .v-btn__content {
  color: black !important;
}

</style>