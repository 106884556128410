<template>
  <v-container>
    <v-row class="" align="center">
      <v-spacer></v-spacer>
      <v-col cols="9" class="d-flex justify-start">
        <span class="fontSize-5 font-weight-bold"> {{ ttl+` `+vol }}</span>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <v-btn 
          outlined
          @click="pubPageMoveClicked"
        >
          전체목록
        </v-btn>
      </v-col>
      <v-col 
        cols="12" 
        class="pa-0 mt-0"
        style="border-bottom: 1px solid #000000;"></v-col>
    </v-row>
    <v-row class="pa-0" align="center">
      <v-col cols="3" class="">
        <v-checkbox 
          hide-details 
          class="pa-0 ma-0"
          label="전체"
          v-model="allCheckYn"
          @click="allCheckClicked"
        >
        </v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1" class="d-flex justify-end">
        <v-btn 
          outlined
          @click="detailPageMoveClicked"
        >
          상세보기
        </v-btn>
      </v-col>
      <v-col 
        cols="12" 
        class="pa-0 mt-0"
        style="border-bottom: 1px solid #D8D8D8;"></v-col>
    </v-row>

    <!--여기 수정하기-->
    <!-- [[[[[ data ]]]]] -->
    <v-row 
      class="listHover d-flex" 
      style="height: 100%;"
      align="center"
      v-for="(item, idx) in pubArtclList" :key="`list_`+idx">
      <v-col 
        cols="12" 
        class="d-flex align-center mt-6 mb-6" 
        style="height: 100%; width: 100%; padding: 0px 0px 0px 10px;">
        <div class="d-flex align-center" style="width: 5%; height: 100%; display: flex;">
          <v-checkbox
            hide-details
            class="mt-2 mb-2 mr-2"
            style="height: 100%; padding-left: 14px; padding-right: 15px;"
            v-model="item.select_yn"
          >
          </v-checkbox>
          <!-- <v-text-field style="border-right: 1px solid #D8D8D8; height: 100%;"></v-text-field> -->
        </div>
          <div class="wid-90" style="padding-left: 20px;">
            <v-col style="border-left: 1px solid #D8D8D8; height: 100%; padding: 0px 0px 0px 15px;">
              <div class="" style="height: 100%;">
                <span>p.{{ item.bgn_page }}</span>
              </div>
              <div class="">
                <span class="fontSize-3">{{item.ttl}}</span>
                <a :href="item.file_path" :download="item.orgnl_file_nm"> 
                  <v-icon large color="red" style="cursor: pointer;">mdi-file-pdf-box</v-icon>
                </a>
              </div>
              <div class="" style="height: 100%;">
                <span>{{item.athor ? item.athor+`:` : ``}}{{item.vol}}({{item.publc_dt}})</span>
              </div>
            </v-col>
          </div>
        </v-col>
      <v-col 
        cols="12" 
        class="pa-0 mt-0"
        style="border-bottom: 1px solid #D8D8D8;"></v-col>
    </v-row>

    <!-- <div style="border-left: 1px solid #D8D8D8; height: 100%;"></div> -->

    <v-row class="pa-0" align="center">
      <v-col 
        cols="12" 
        class="pa-0 mt-0"></v-col>
      <v-col cols="3" class="">
        <v-checkbox 
          hide-details 
          class="pa-0 ma-0"
          label="전체"
          v-model="allCheckYn"
          @click="allCheckClicked"
        >
        </v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1" class="d-flex justify-end">
        <v-btn 
          outlined
          @click="detailPageMoveClicked"
        >
          상세보기
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="" align="center">
      <v-col 
        cols="12" 
        class="pa-0 mt-0"
        style="border-bottom: 1px solid #000000;"></v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn 
          outlined
          @click="pubPageMoveClicked"
        >
          전체목록
        </v-btn>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      vol: this.$route.query.vol ? this.$route.query.vol : '',
      yy: this.$route.query.yy ? this.$route.query.yy : '',
      ttl: this.$route.query.ttl ? this.$route.query.ttl : '',
      basicUri: '',
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      emitData: {},
      pubArtclList: [],
      allCheckYn: false,
    }
  },
  created() {
    let path = this.$route.fullPath;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    if(!this.vol || !this.yy || !this.ttl) {
      alert('잘못된 접근입니다.');
      this.$router.push(this.basicUri);
      return;
    }
    this.selectPubList();
  },
  methods: {
    selectPubList() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("non_mber_yn", this.nonMberYn);

      this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
        console.log(res.data);
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.$router.push("/");
        } else {
          console.log("pubArtclList",res.data.pubArtclList);

          const pubArtclList = res.data.pubArtclList;
          pubArtclList.forEach((e) => {
            if(e.artcl_ttl && e.artcl_ttl_en) {
              e.ttl = e.artcl_ttl + ' / '+e.artcl_ttl_en;
            } else if(e.artcl_ttl) {
              e.ttl = e.artcl_ttl;
            } else if(e.artcl_ttl_en) {
              e.ttl = e.artcl_ttl_en;
            }
          });
          this.pubArtclList = pubArtclList;

          this.emitData.menu_sno = res.data.menu_sno;
          this.$emit('setData', this.emitData);
          
        }
      })
        .catch(function (error) {
          console.log(error);
        }
      );
    },//selectPubList end
    allCheckClicked() {
      this.pubArtclList.forEach((e) => {
        e.select_yn = this.allCheckYn;
      })
    },
    pdfDownClicked(item) {
      // window.open(url, "_blank");
      const filename = item.orgnl_file_nm;
      const element = document.createElement('a');
      element.setAttribute('href', 'data: application/force-download,' +encodeURIComponent(item.file_path));
      element.setAttribute('download', filename);
      document.body.appendChild(element);
      element.click();
    },//imgViewClicked end
    pubPageMoveClicked() {
      this.$router.push(this.basicUri);
    },//pubPageMoveClicked end
    detailPageMoveClicked() {
      if(this.pubArtclList.filter((e) => e.select_yn).length == 0 ) {
        alert('상세보기할 항목 선택해주세요.');
        return;
      }

      let moveUrl = this.basicUri+'/detail?yy='+this.yy+'&vol='+this.vol+'&ttl='+this.ttl;
      this.pubArtclList.filter((e) => e.select_yn).forEach((f) => {
        moveUrl += '&sno='+f.artcl_sno;
      })
      this.$router.push(moveUrl);
    },//detailPageMoveClicked end
  }
}
</script>

<style>
  .lis-right-1px {
    border-right: 1px solid #000000;
  }

  .listHover:hover{
    background-color: #e6e6e6;
  }
</style>