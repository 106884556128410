<template>
  <v-app>
    <!--[[[[[[      헤더 및 공통 영역      ]]]]]]-->
    <!-- <v-app-bar app color="#A9E2F3" height="40px" class="elevation-0">
      <v-list-item-title class="text-h5 font-weight-bold" style="height: 26px">학회명</v-list-item-title>
      <v-btn fab x-small class="elevation-0">
        <v-icon>mdi-login</v-icon>
      </v-btn>
      <v-btn fab x-small class="elevation-0 ml-2">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn fab x-small class="elevation-0 ml-2">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-app-bar> -->
    <admin-default-bar/>
    <v-container fluid>
      <v-row class="hei-100vh-auto ma-0 pa-0">
        <v-col cols="1" class="bg-lightblue hei-100vh-auto pa-0">
          
          <v-list nav dense class="bg-lightblue" v-if="!sysAdminYn">
            <!-- <v-list-item
              v-for="item in baseMenuList"
              link
              :key="item.title"
              :to="item.to"
              class="pa-0"
            >
              <v-list-item-content class="pl-5">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-group
              v-for="item in baseMenuList"
              :key="item.lv1Title"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{item.lv1Title}}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in item.lv2Menu "
                link
                :key="child.title"
                :to="child.to"
                @click="onClick(child)"
                class="pa-0">
                <v-list-item-content class="pl-5">
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
          <!-- system admin -->
          <v-list nav dense class="bg-lightblue" v-if="sysAdminYn">
            <v-list-group
              v-for="item in sysAdminMenuList"
              :key="item.lv1Title"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{item.lv1Title}}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in item.lv2Menu "
                link
                :key="child.title"
                :to="child.to"
                @click="onClick(child)"
                class="pa-0">
                <v-list-item-content class="pl-5">
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-divider/>
        </v-col>
        <v-col cols="11">
          <v-row class="pl-6 mb-2 border-b-grey hei-40p" align="center">
            <div class="fontSize-4 font-weight-bold">{{ $store.state.pageTitle }}</div>  
          </v-row>
          <v-main fluid style="padding-top:0px;" >
            <router-view />
          </v-main>
        </v-col>
      </v-row>
    </v-container>




    <!--[[[[[[         메뉴 영역           ]]]]]]-->
    <!-- <v-navigation-drawer app color="#eaf1fb">
      <v-list-item class="px-2">
        <v-list-item-title class="text-h5">SSMS</v-list-item-title>
      </v-list-item>

      <v-divider />
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6" style="height:23px">
            ADMIN
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list nav dense>
        <v-list-group
          v-for="item in baseMenuList"
          :key="item.lv1Title">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{item.lv1Title}}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.lv2Menu "
            link
            :key="child.title"
            :to="child.to"
            class="pa-0">
            <v-list-item-content class="pl-5">
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider />
    </v-navigation-drawer>

    <v-main fluid >
      <router-view />
    </v-main> -->


  </v-app>
</template>

<script>
import adminDefaultBar from '@/layout/admin/appBar.vue'
export default {
  name: "defaultLayout",
  components: {
    adminDefaultBar,
  },
  data: () => ({
    domain: window.location.hostname,
    mini: true,
    drawer: true,
    baseMenuList: [
      {
        lv1Title:"학회",
        lv2Menu:[
          { title: "학회정보 관리", to: "/admin/society" },
          { title: "메인 페이지 관리", to: "/admin/mainPageMng" },
          { title: "역대 임원진 관리", to: "/admin/executiveGenerationList" },
          { title: "연혁 관리", to: "/admin/historyTermList" },
        ]
      },
      {
        lv1Title:"회원",
        lv2Menu:[
          { title: "회원정보 관리", to: "/admin/memberList" },
          { title: "회원정보 항목 관리", to: "/admin/memberSectionItem" },
        ]
      },
      {
        lv1Title:"증명서",
        lv2Menu:[
        { title: "발급내역", to: "/admin/issuanceList" },
        { title: "증명서 설정", to: "/admin/certificationLetter" },
        // { title: "증명서 항목관리", to: "/admin/certificationLetter" },
        ]
      },
      {
        lv1Title:"학술대회",
        lv2Menu:[
          { title: "학술대회 관리", to: "/admin/conferenceList" },
          { title: "학술대회 사전등록", to: "/admin/preRegistList" },
          { title: "학술대회 초록등록", to: "/admin/abstrRegistList" },    
        ]
      },
      {
        lv1Title:"결제",
        lv2Menu:[
          { title: "상품 관리", to: "/admin/productList" },
          { title: "결제 내역", to: "/admin/paymentList" },
        ]
      },
      {
        lv1Title:"메뉴",
        lv2Menu:[
        { title: "메뉴 관리", to: "/admin/menuList" },    
        ]
      },
      {
        lv1Title:"퍼미션",
        lv2Menu:[
          { title: "그룹&퍼미션", to: "/admin/groupNPermission" },    
        ]
      },
      {
        lv1Title:"게시물",
        lv2Menu:[
          { title: "게시물 관리", to: "/admin/boardList" },    
        ]
      },
      {
        lv1Title:"수록기사",
        lv2Menu:[
          { title: "수록기사 관리", to: "/admin/publcMenuList" },    
        ]
      },
      {
        lv1Title:"pdf",
        lv2Menu:[
          { title: "pdf 다운로드", to: "/admin/pdfDownload" },    
        ]
      },
      // {
      //   lv1Title:"학회관리",
      //   lv2Menu:[
      //     { title: "학회 관리 목록", to: "/admin/societyMngList" },    
      //   ]
      // },
    ],
    sysAdminMenuList: [
      {
        lv1Title:"학회관리",
        lv2Menu:[
          { title: "학회 관리 목록", to: "/admin/societyMngList" },    
        ]
      },
    ],
    // pageTitle: "", // store 에서 관리
    sysAdminYn: false,
    
  }),
  created() {
    if(this.domain == 'ssms-admin.bgwell.co.kr') {
      this.sysAdminYn = true;
    }
    
    // this.$checkLogin();

    // const formData = new FormData();
    // formData.append('mber_sno', mberSno);

    // this.$http.post('/mber/getDomnLginChk',formData).then((res) => {
    //   if(res.data.code < 0) {
    //     alert('로그인 정보가 정확하지 않습니다. 다시 로그인해주세요.');
    //     Vue.prototype.$session.clear();
    //     next('/');
    //   } else {
    //     const mberInfo = res.data.mberInfo;
    //     if(mberInfo.mber_priv == 's') {

    //     }
    //   }
      
        
    // }).catch(
    //   function (error) {
    //   console.log(error);
    //   }
    // )
  },
  activated() {
    // this.$checkLogin();
  },
  methods: {
    onClick(menu) {
      console.log("onClick", menu);
      // this.pageTitle = menu.title;
      this.$store.state.pageTitle = menu.title;
    }
  },
};
</script>

<style scoped>
/deep/ .v-application--wrap {
  /* min-height: 90vh !important; */
  min-height: fit-content !important;
}

.v-navigation-drawer >>> .v-navigation-drawer__border {
  display: none;
}

.theme--light.v-application {
  background: rgba(0, 0, 0, 0);
}

</style>
