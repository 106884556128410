<template>
  <v-container>
        <v-row dense align="center">
          <v-col cols="12">
            <div>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                to="/admin/societyMngList"
                >목록으로
              </v-btn>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                :disabled="!isOnPending"
                @click="saveBtnClicked(1)"
                >저장 후 목록
              </v-btn>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                :disabled="!isOnPending"
                @click="saveBtnClicked(2)"
                >저장 후 유지
              </v-btn>
            </div>
          </v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>학회명 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model='soctyMap.socty_nm'
            ></v-text-field>
          </v-col>
        </v-row>  
        <!-- 도메인명 -->
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="pt-0">
              <span>도메인 명 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model='soctyMap.domn_nm'
            ></v-text-field>
          </v-col>
        </v-row>  
        <!-- ga 추적코드 -->
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="pt-0">
              <span>GA 추적코드</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model='soctyMap.ga_track_cd'
            ></v-text-field>
          </v-col>
        </v-row>  
        <!-- 네이버클라이언트id -->
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="pt-0">
              <span>네이버클라이언트ID</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-select
              class="pa-0 ma-0"
              v-model="soctyMap.naver_clnt_id"
              :items="naverIdList"
              item-text="naver_clnt_id"
              item-value="naver_clnt_id"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>  
        <!-- 학회 상태 -->
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="pt-0">
              <span>학회상태</span>
            </div>
          </v-col>
          <v-col cols="3">
            <!-- <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model='soctyMap.socty_stus_nm'
            ></v-text-field> -->
            <v-select
              class="pa-0 ma-0"
              v-model="soctyMap.socty_stus"
              :items="soctyStusList"
              item-text="cmn_cd_nm"
              item-value="cmn_cd"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>  
        <!-- 비고 -->
        <!-- <v-row dense >
          <v-col cols="2" >
            <div class="pt-0">
              <span>비고</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-textarea
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model='soctyMap.socty_nm'
            ></v-textarea>
          </v-col>
        </v-row>   -->
  </v-container>
      
  
</template>

  
<script>
export default {
  data(){
    return {
      soctySno: this.$route.query.soctySno ? this.$route.query.soctySno : '',
      soctyMap: {},
      soctyStusList: [],
      naverIdList: [],
      pendingSoctyMap: {},
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
    }
  },
  computed: {
    isOnPending() {
      if(this.soctySno) {
        return JSON.stringify(this.soctyMap) != JSON.stringify(this.pendingSoctyMap)
      } else {
        return true;
      }
    }
  },
  created() {
    const formData = new FormData();
    formData.append('socty_sno',this.soctySno);
    this.$http.post("/admin/getSoctyMap",formData).then((res) => {

      if(res.data.soctyMap) {
        this.soctyMap = res.data.soctyMap;
        this.pendingSoctyMap = JSON.parse(JSON.stringify(this.soctyMap));
      }
      
      this.soctyStusList = res.data.soctyStusList;
      this.naverIdList = res.data.naverIdList;

    }).catch(
      function (error) {
        console.log(error);
      }
    )
    
    
  },
  mounted(){
  },
  methods: {
    saveBtnClicked(no) {
      if(this.invalidCheck()) {
        const formData = new FormData();
        if(this.soctySno) {
          formData.append('socty_sno',this.soctySno);
        }
        
        formData.append('mber_sno',this.mberSno);
        formData.append('soctyMap',JSON.stringify(this.soctyMap));

        this.$http.post("/admin/upsertSociety",formData).then((res) => {
          console.log(res.data);
          if(res.data.code == '-9004') {
            alert('도메인명이 이미 존재합니다.');
            return;
          }else if(res.data.code < 0) {
            alert(this.$erroCdMsg(res.data.code));
            return;
          } else {
            alert('저장되었습니다.');
            

            if(no == 1) {
              this.$router.push({name: 'societyMngList'});
            } else if(no == 2) {
              const soctySno = res.data.soctySno;
              this.$router.push({name: 'societyMngDetail', query: {soctySno : soctySno}});
              // this.$router.go(0);
            }
            
          }
          
        }).catch(
          function (error) {
            console.log(error);
          }
        )
      }
        
    },//saveBtnClicked end
    invalidCheck() {
      if(!this.soctyMap.socty_nm) {
        alert('학회명은 필수값입니다.');
        return false;
      } else if(!this.soctyMap.domn_nm) {
        alert('도메인 명은 필수값입니다.');
        return false;  
      }
      return true;
    },//invalidCheck end
  }
}
</script>
  
<style>

</style>