<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="">
      <div class="wid-100">
        <v-row dense align="center">

          <v-col cols="2" >
            <div class="pt-0">
              <span>행사개요 상세내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="confr_outl_dtl" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>행사일정 상세내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="confr_scdul_dtl" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>사전등록 상세내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="pre_reg_dtl" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록등록 상세내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="abstr_reg_dtl" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>광고/전시안내 상세내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="ad_disp_dtl" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>이벤트/관광 상세내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="event_tour_dtl" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>교통/숙박안내 상세내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor ref="traf_lodg_dtl_cn" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->

        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';

export default {
  components: { tinyEditor },
  props: ["propParams"],
  data(){
    return{
      mberSno: "",
      confrSno: "",
      newYn: 'N',             // 조회된 값이 있으면 false

      confr_outl_dtl: "",
		  confr_scdul_dtl: "",
		  pre_reg_dtl: "",
		  abstr_reg_dtl: "",
		  ad_disp_dtl: "",
		  event_tour_dtl: "",
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.propParams.confrSno; 

    this.getConfrDtl();
  },
  methods: {
    getConfrDtl() {
      const params = new FormData();

      params.append("confr_sno", this.confrSno);

			this.$http.post("/admin/getConfrDtl", params).then((res) => {
        // console.log("res.data.sult: ", res.data.result);
        const dtl = res.data.result.confrDtl;

        if(dtl == null) {
          this.newYn = 'Y';
          // alert("조회된 정보가 없습니다.");
          return;
        } else {
          this.newYn = 'N';
        }
        
        this.confr_outl_dtl = dtl.confr_outl_dtl;
        this.confr_scdul_dtl = dtl.confr_scdul_dtl;
        this.pre_reg_dtl = dtl.pre_reg_dtl;
        this.abstr_reg_dtl = dtl.abstr_reg_dtl;
        this.ad_disp_dtl = dtl.ad_disp_dtl;
        this.event_tour_dtl = dtl.event_tour_dtl;

        // this.$refs.confr_outl_dtl_cn.content = dtl.confr_outl_dtl;
        // this.$refs.confr_scdul_dtl_cn.content = dtl.confr_scdul_dtl;
        // this.$refs.pre_reg_dtl_cn.content = dtl.pre_reg_dtl;
        // this.$refs.abstr_reg_dtl_cn.content = dtl.abstr_reg_dtl;
        // this.$refs.ad_disp_dtl_cn.content = dtl.ad_disp_dtl;
        // this.$refs.event_tour_dtl_cn.content = dtl.event_tour_dtl;
        // this.$refs.traf_lodg_dtl_cn.content = dtl.traf_lodg_dtl;

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveConfrDtl");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append('new_yn', this.newYn);

        if(this.confr_outl_dtl) {
          this.confr_outl_dtl = this.confr_outl_dtl.replaceAll("../DEV","/DEV");
        }
        if(this.confr_scdul_dtl) {
          this.confr_scdul_dtl = this.confr_scdul_dtl.replaceAll("../DEV","/DEV");
        }
        if(this.confr_outl_dtl) {
          this.confr_outl_dtl = this.confr_outl_dtl.replaceAll("../DEV","/DEV");
        }
        if(this.pre_reg_dtl) {
          this.pre_reg_dtl = this.pre_reg_dtl.replaceAll("../DEV","/DEV");
        }
        if(this.abstr_reg_dtl) {
          this.abstr_reg_dtl = this.abstr_reg_dtl.replaceAll("../DEV","/DEV");
        }
        if(this.event_tour_dtl) {
          this.event_tour_dtl = this.event_tour_dtl.replaceAll("../DEV","/DEV");
        }


        if(this.confr_outl_dtl != null)   params.append("confr_outl_dtl", this.confr_outl_dtl);
        if(this.confr_scdul_dtl != null)  params.append("confr_scdul_dtl", this.confr_scdul_dtl);
        if(this.pre_reg_dtl != null)      params.append("pre_reg_dtl", this.pre_reg_dtl);
        if(this.abstr_reg_dtl != null)    params.append("abstr_reg_dtl", this.abstr_reg_dtl);
        if(this.ad_disp_dtl != null)      params.append("ad_disp_dtl", this.ad_disp_dtl);
        if(this.event_tour_dtl != null)   params.append("event_tour_dtl", this.event_tour_dtl);
        
        // if(this.$refs.confr_outl_dtl_cn.content != null)   params.append("confr_outl_dtl", this.$refs.confr_outl_dtl_cn.content);
        // if(this.$refs.confr_scdul_dtl_cn.content != null)  params.append("confr_scdul_dtl", this.$refs.confr_scdul_dtl_cn.content);
        // if(this.$refs.pre_reg_dtl_cn.content != null)      params.append("pre_reg_dtl", this.$refs.pre_reg_dtl_cn.content);
        // if(this.$refs.abstr_reg_dtl_cn.content != null)    params.append("abstr_reg_dtl", this.$refs.abstr_reg_dtl_cn.content);
        // if(this.$refs.ad_disp_dtl_cn.content != null)      params.append("ad_disp_dtl", this.$refs.ad_disp_dtl_cn.content);
        // if(this.$refs.event_tour_dtl_cn.content != null)   params.append("event_tour_dtl", this.$refs.event_tour_dtl_cn.content);
        // if(this.$refs.traf_lodg_dtl_cn.content != null)   params.append("traf_lodg_dtl", this.$refs.traf_lodg_dtl_cn.content);

        this.$http.post("/admin/upsertConfrDtl", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getConfrDtl();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'conferenceDetailTab');
    },
  },
}
</script>

<style>

</style>