<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center">
          <v-col cols="3">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="searchWord" 
              outlined
              dense
              hide-details
              placeholder="검색어"
              background-color="white"
              @keyup.enter="searchBtnClicked"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text" 
              @click="searchBtnClicked"
              >검색
            </v-btn>
          </v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addGrpMberBtnClicked"
              >단체회원 추가</v-btn>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addIndvMberBtnClicked"
              >개인회원 추가</v-btn>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="setDispItemBtnClicked"
              >표시 항목 설정</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <div dense class="pl-3 pr-3">
            <span v-for="(item) in joinYearList" :key="item.year" 
              :style="item.year == year ? 'color:blue;' : 'cursor:pointer'" 
              @click="onYearClick(item.year)">{{ item.year }} &nbsp;&nbsp;
            </span>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="mainHeader"
              :items="gridList"
              class="elevation-1"
              :search="search"
              :items-per-page="20"
              style="cursor: pointer"
            >
              <!-- @click:row="mberRowClicked($event)" -->
              <!-- <template v-slot:[`item.mber_check`]="{ item }">
                <v-simple-checkbox
                  v-model="item.mber_check"
                  hide-details>
                </v-simple-checkbox>
              </template> -->
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0 wid-40p"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <!-- <template v-slot:[`item.이름`]="{item}">
                <v-text-field
                  v-model="item.이름"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="mberRowClicked(item)"
                ></v-text-field>
              </template> -->
              
              <template v-slot:[`item.아이디`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.아이디 }}</div>
              </template>
              <template v-slot:[`item.이름`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.이름 }}</div>
              </template>
              <template v-slot:[`item.이메일`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.이메일 }}</div>
              </template>
              <template v-slot:[`item.회원상태`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.회원상태 }}</div>
              </template>
              <template v-slot:[`item.전화번호`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.전화번호 }}</div>
              </template>
              <template v-slot:[`item.휴대전화번호`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.휴대전화번호 }}</div>
              </template>
              <template v-slot:[`item.학회가입일자`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.학회가입일자 }}</div>
              </template>
              <template v-slot:[`item.회원종류`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.회원종류 }}</div>
              </template>
              <template v-slot:[`item.회원구분`]="{ item }">
                <div @click="mberRowClicked(item)">{{ item.회원구분 }}</div>
              </template>
              
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.memberList.radioMberPriv"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>회원 권한으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in mberPrivList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
                <!-- <v-radio
                  label="관리자"
                  value="test-2"
                ></v-radio>
                <v-radio
                  label="일반"
                  value="test-3"
                ></v-radio> -->
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.memberList.radioMberStus"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>회원 상태로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in mberStusList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd_nm"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.memberList.radioMberDiv"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>회원 구분으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in mberDivList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd_nm"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.memberList.radioMberKind"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>회원 종류로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in mberKindList"
                  :key="index"
                  :label="item.mber_kind_nm"
                  :value="item.mber_kind_nm"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.memberList.radioAnulMfeeExmptYn"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>연회비 면제 여부로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

		<template>
			<v-dialog
				v-model="mberChkDialog"
				max-width="400px"
				max-height="600px"
			>
				<v-card style="text-align:center;">
					<v-app-bar 
						class="fixed-bar" 
						color="blue lighten-2" 
						max-height="60px">
						<v-toolbar-title
							class="white--text">
							표시 항목 설정
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn
							color="white"
							@click="saveDispItemSetClicked">
							저장
						</v-btn>
					</v-app-bar>
					<div class="pa-3" style="overflow:auto;">
						<v-data-table
							:headers="settingHeader"
							:items="mberItemList"
							class="elevation-1 dTable-1"
							:items-per-page="20"
							hide-default-footer
							disable-pagination
							dense
						>
							<template v-slot:[`item.item_chk`]="{ item }">
								<v-checkbox
									v-model="item.item_chk"
									class="pa-0 ma-0"
									hide-details>
								</v-checkbox>
							</template>
              <template v-slot:[`item.disp_odr`]="{ item }">
                <v-text-field
                  class="pa-0 ma-0"
                  v-model="item.disp_odr"
                  hide-details
                  outlined
                  dense
                  style="background-color: rgba( 255, 255, 255, 0 ) !important;"
                ></v-text-field>
              </template>
						</v-data-table>
					</div>
				</v-card>	
			</v-dialog>
		</template>
	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      columnIndex: 0,
      cnt : 0,
      mberChkDialog: false,
      mainHeader: [
        { text: '선택', value: 'select_yn', width: "100px", }
        // 학회 별 설정에 따라 가변
      ],
      mberList: [],
      gridList: [],
      settingHeader:[
        { text: '관리 항목명', value: 'item_nm', width: "50%"},
        { text: '표시여부', value: 'item_chk', width: "25%"},
        { text: '표시순서', value: 'disp_odr', width: "25%"}
      ],
      mberItemList: [],
      itemInfoList: [],
      redrawCount : 0,  // 화면 다시 그리기
      searchWord: '',	  // 검색어 내용
      search: '',	      // 검색어버튼 클릭시 저장되는곳

      // 라디오 필터 표시 목록
      mberKindList: [], // 회원종류
      mberStusList: [], // 회원상태
      mberDivList: [], // 회원구분
      mberPrivList: [], // 회원권한

      joinYearList: [],
      year: "",
    }
  },
  created() {
    this.getMberPrivList();
    this.getMberStusList();
    this.getMberDivList();
    this.getMberKindList();
    // this.getMberList();
  },
  mounted(){
    this.getMberJoinYearList();
  },
  methods: {
    async getMberPrivList() {
      this.mberPrivList = await this.$getCmnCdList("mber_priv");
    },
    async getMberStusList() {
      this.mberStusList = await this.$getCmnCdList("mber_stus");
    },
    async getMberDivList() {
      this.mberDivList = await this.$getCmnCdList("mber_div");
    },
    getMberKindList() {
      this.$http.get("/admin/getSoctyMberKindList").then((res) => {        
        this.mberKindList = res.data.result.mberKindList;
      }).catch((error) => {
          console.log(error);
        }
      )
    },
    getMberJoinYearList() {
      this.$http.get("/admin/getMberJoinYearList").then((res) => {
        console.log("res.data.result:", res.data.result);
        this.joinYearList = res.data.result.joinYearList;

        this.year = this.joinYearList[0].year;
        this.getMberList();
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    onYearClick(year) {
      this.year = year;
      this.getMberList();
    },
    getMberList() {
      let params = new FormData();
      params.append("year", this.year);

      this.$http.post("/admin/getMberList", params).then((res) => {
        if(res.data.code == -9005) {  // param_fail
          alert("표시 항목 설정이 되지 않았습니다.");
          return;
        }
        // console.log(res.data.result.socty_mber_list_item);
        this.mainHeader = [];
        this.mainHeader.push({ text: '선택', value: 'select_yn', width: "5%" });
        // const mberCol = res.data.result.mberCol;
        res.data.result.mberCol.forEach((e) => {
          this.mainHeader.push({text: e.item_nm, value: e.item_nm, width: "auto"});
        })
                
        res.data.result.mberList.forEach((e) => {
          e.select_yn = false;

          // 회원종류 : 일련번호 -> 값 변환
          if(Object.prototype.hasOwnProperty.call(e, "회원종류")) {
            this.mberKindList.forEach((mber) => {
              if(e.회원종류 == mber.mber_kind) {
                e.회원종류 = mber.mber_kind_nm;
              }
            });
          }

          // 회원상태 : 코드 -> 값 변환
          if(Object.prototype.hasOwnProperty.call(e, "회원상태")) {
            this.mberStusList.forEach((mber) => {
              if(e.회원상태 == mber.cmn_cd) {
                e.회원상태 = mber.cmn_cd_nm;
              }
            });
          }

          // 회원구분 : 코드 -> 값 변환
          if(Object.prototype.hasOwnProperty.call(e, "회원구분")) {
            this.mberDivList.forEach((mber) => {
              if(e.회원구분 == mber.cmn_cd) {
                e.회원구분 = mber.cmn_cd_nm;
              }
            });
          }

          // 학회가입일자 : '-' 추가
          if(Object.prototype.hasOwnProperty.call(e, "학회가입일자")) {
            if(e.학회가입일자 != null && e.학회가입일자.length > 0) {
              e.학회가입일자 = e.학회가입일자.substring(0, 4) + '-' + e.학회가입일자.substring(4, 6) + '-' + e.학회가입일자.substring(6);
            }
          }
        })

        // this.gridList = res.data.result.mberList;
        this.mberList = res.data.result.mberList;
        this.filterList();

        // console.log("this.mainhead",this.mainHeader);
        // console.log("this.gridList",this.gridList);
      //   this.redrawCount++;

      }).catch(function (error) {
          console.log(error);
        }
      ) 
    },
    mberRowClicked(item, columnIndex) {
      console.log("mberRowClicked : ", item, columnIndex);
      this.$router.push({name:"memberDetail", query: { mberSno:item.mber_sno, mberDiv:item.mber_div, newYn:'N' }});
    //   this.$router.push({path:"memberDetail"});
    },
    // 개인회원 추가 버튼 클릭
    addIndvMberBtnClicked() {
      this.$router.push({name:"memberDetail", query: { mberSno:null, mberDiv:'indv', newYn:'Y' }});
    },
    // 단체회원 추가 버튼 클릭
    addGrpMberBtnClicked() {
      this.$router.push({name:"memberDetail", query: { mberSno:null, mberDiv:'grp', newYn:'Y' }});
    },
    // 표시항목 설정 버튼 클릭
    setDispItemBtnClicked() {
      this.$http.get("/admin/getMberListDispItemList").then((res) => {
        this.mberItemList = res.data.result.mber_Item_List;
          this.mberItemList.forEach((e) => {

            if(e.use_yn == 'Y') {
              e.item_chk = true;
            }
          })
          
        this.mberChkDialog = true;
        // console.log("this.settingCon :: ",this.settingCon);
      }).catch(
        function (error) {
          console.log(error);
        }.bind(this)
      )
      
    },
    saveDispItemSetClicked() {
    
    // console.log("this.mberItemList :: ",this.mberItemList);
      const mberItemListFil = this.mberItemList.filter((e) => e.item_chk == true);

      let params = new URLSearchParams();
      params.append("item_info", JSON.stringify(mberItemListFil));

      this.$http.post("/admin/upsertSoctyMberListItem", params).then((res) => {
        console.log(res.data);
        if(res.data.code == 0)
        {
          this.$router.go(0);
        }

        // this.subSectionName = item.mber_sctn_nm;
      }).catch(function (error) {
          console.log(error);
        }.bind(this)
      );

    },
    searchBtnClicked() {
      this.search = this.searchWord;
    },
    deleteBtnClicked() {
      console.log("deleteMemberList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: mber_sno[" + e.mber_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteMberList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getMberList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    radioFilterChanged() {
      // console.log("radioFilterChanged radioMberPriv:", this.radioMberPriv);
      // console.log("radioFilterChanged radioMberStus:", this.radioMberStus);
      // this.getMberList();
      this.filterList();
    },
    filterList() {
      this.gridList = this.mberList.filter((e) => {
        // console.log("e:", e);
        if( (this.$store.state.memberList.radioMberPriv == "모두" || e.mber_priv == this.$store.state.memberList.radioMberPriv) &&
            (this.$store.state.memberList.radioMberStus == "모두" || e.회원상태 == this.$store.state.memberList.radioMberStus) &&
            (this.$store.state.memberList.radioMberDiv == "모두" || e.회원구분 == this.$store.state.memberList.radioMberDiv) &&
            (this.$store.state.memberList.radioMberKind == "모두" || e.회원종류 == this.$store.state.memberList.radioMberKind) &&
            (this.$store.state.memberList.radioAnulMfeeExmptYn == "모두" || e.anul_mfee_exmpt_yn == this.$store.state.memberList.radioAnulMfeeExmptYn) 
          ) return true;
      });
    }
  }
}
</script>
  
<style>
/* .v-text-field input[readonly="readonly"] {
    background-color: yellowgreen
} */
</style>