<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
    <div class="wid-100">
    <v-row dense align="center">
      <!-- <v-col cols="12" class="pa-0 ma-0">
        <div>
          <v-row dense>
            <v-col cols="12">
              <div class="f-r">
                <v-btn
                  small
                  color="blue lighten-2"
                  class="white--text"
                  @click="saveBtnClicked"
                >저장</v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col> -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>상단 메인 로고</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="topMainLogoSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="topMainLogoCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="topMainLogoCurrentFile.file_path" target='_blank'>{{topMainLogoCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(topMainLogoCurrentFile.file_item, topMainLogoCurrentFile.file_path, topMainLogoCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 197×75</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>상단 메인 로고(모바일)</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="mobTopMainLogoSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="mobTopMainLogoCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="mobTopMainLogoCurrentFile.file_path" target='_blank'>{{mobTopMainLogoCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(mobTopMainLogoCurrentFile.file_item, mobTopMainLogoCurrentFile.file_path, mobTopMainLogoCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 65×40</span>
          </v-col>
        </v-row>
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>하단 로고</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="btmLogoSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="btmLogoCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="btmLogoCurrentFile.file_path" target='_blank'>{{btmLogoCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(btmLogoCurrentFile.file_item, btmLogoCurrentFile.file_path, btmLogoCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 193×84</span>
          </v-col>
        </v-row>
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>관리자 페이지 로고</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="adminPageLogoSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="adminPageLogoCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="adminPageLogoCurrentFile.file_path" target='_blank'>{{adminPageLogoCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(adminPageLogoCurrentFile.file_item, adminPageLogoCurrentFile.file_path, adminPageLogoCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 25×25</span>
          </v-col>
        </v-row>
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>관리자 페이지 로그인 상단</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="joinTopLogoSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="joinTopLogoCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="joinTopLogoCurrentFile.file_path" target='_blank'>{{joinTopLogoCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(joinTopLogoCurrentFile.file_item, joinTopLogoCurrentFile.file_path, joinTopLogoCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 200×130</span>
          </v-col>
        </v-row>
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>메인 페이지 상단</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="mainPageTopImgSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="mainPageTopImgCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="mainPageTopImgCurrentFile.file_path" target='_blank'>{{mainPageTopImgCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(mainPageTopImgCurrentFile.file_item, mainPageTopImgCurrentFile.file_path, mainPageTopImgCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 1903×571</span>
          </v-col>
        </v-row>
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>메인 페이지 하단(바로가기 링크 배경)</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="mainPageBtmImgSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="mainPageBtmImgCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="mainPageBtmImgCurrentFile.file_path" target='_blank'>{{mainPageBtmImgCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(mainPageBtmImgCurrentFile.file_item, mainPageBtmImgCurrentFile.file_path, mainPageBtmImgCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="3">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 1903×495</span>
          </v-col>
        </v-row>
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>서브 페이지 상단</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="hdrImgSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="hdrImgCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="hdrImgCurrentFile.file_path" target='_blank'>{{hdrImgCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(hdrImgCurrentFile.file_item, hdrImgCurrentFile.file_path, hdrImgCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="3">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 1903×150</span>
          </v-col>
          <v-col cols="4" class="">
            <v-row dense align="center" class="ml-1">
              <v-col cols="4">
                <span>폰트 색상</span>
              </v-col>
              <v-col cols="4">
                <v-btn small @click="fontColorPickerClicked(1)">색상 선택</v-btn>
              </v-col>
              <v-col cols="4">
                <div 
                  class="ml-2"
                  :style="`width:70px; border:1px solid black; height:30px; background-color:` + fontPointColor.font_color_1">
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>      
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>증명서 로고</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="crtfLogoSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="crtfLogoCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="crtfLogoCurrentFile.file_path" target='_blank'>{{crtfLogoCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(crtfLogoCurrentFile.file_item, crtfLogoCurrentFile.file_path, crtfLogoCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 269×74</span>
          </v-col>
        </v-row> 
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->      

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>학회 직인</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="soctySealSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="soctySealCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="soctySealCurrentFile.file_path" target='_blank'>{{soctySealCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(soctySealCurrentFile.file_item, soctySealCurrentFile.file_path, soctySealCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 118×123</span>
          </v-col>
        </v-row>
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->

      <!-- start -->
      <v-col cols="2" >
        <div class="">
          <span>파비콘</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="faviconSelectedFile"
            @change="itemChanged"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="3" v-if="faviconCurrentFile.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="faviconCurrentFile.file_path" target='_blank'>{{faviconCurrentFile.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteImgBtnClicked(faviconCurrentFile.file_item, faviconCurrentFile.file_path, faviconCurrentFile.file_atch_sno)"
            >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="5" class="">
        <v-row dense align="center" class="ml-2">
          <v-col cols="5">
            <span
              style = "font-size: 11px; color: gray"> 권장 Size: 25×25</span>
          </v-col>
        </v-row>
      </v-col>  
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- end -->
      
      <!-- <v-col cols="12">
        <div class="">
          <v-btn
            small
            color="blue lighten-2"
            class="white--text"
            @click="saveBtnClicked"
          >저장</v-btn>
        </div>
      </v-col> -->
    </v-row>  
  </div>
</v-layout>

  <!-- 컬러 픽커 다이얼로그 -->
  <template>
    <v-dialog
      v-model="fontColorPickerPop"
      width="auto">
      <v-card style="text-align:center;">
        <v-app-bar 
          class="fixed-bar" 
          color="blue lighten-2" 
          max-height="60px">
          <v-toolbar-title
            class="white--text">
            컬러 설정
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            @click="selColorClicked">
            설정
          </v-btn>
        </v-app-bar>
        <template>
          <v-color-picker 
            v-model="fontColor"
            mode="hexa"
            hide-mode-switch>
          </v-color-picker>
        </template>
      </v-card>
    </v-dialog>
  </template>
</v-container>
      

  
</template>

<script>
export default {
  data(){
    return{
      mberSo: "",

      isNew: false,  // 조회된 값이 있으면 false

      topMainLogoCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      topMainLogoSelectedFile: null,
      btmLogoCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      btmLogoSelectedFile: null,
      adminPageLogoCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      adminPageLogoSelectedFile: null,
      joinTopLogoCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      joinTopLogoSelectedFile: null,
      mainPageTopImgCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      mainPageTopImgSelectedFile: null,
      mainPageBtmImgCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      mainPageBtmImgSelectedFile: null,
      hdrImgCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      hdrImgSelectedFile: null,
      crtfLogoCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      crtfLogoSelectedFile: null,
      soctySealCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      soctySealSelectedFile: null,
      faviconCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      faviconSelectedFile: null,
      mobTopMainLogoCurrentFile: {file_item:"", file_path:null, orgnl_file_nm:null, file_atch_sno:null},
      mobTopMainLogoSelectedFile: null,
      fileTableHeader:[
        { text:'파일명', value:'orgnl_file_nm', width:'90%'},
        { text:'삭제', value:'file_delete', width: '10%'}
      ],
      // fileList:[
      //   {file_path:'test', file_atch_sno: '', file_delete:'삭제'}
      // ]
      mode: 'hexa',       //컬러 픽커 모드
      fontColor: '',      //컬러 픽커에서 선택된 값
      fontColorPickNo: '',      //컬러 픽커 번호
      fontColorPickerPop: false,      //컬러 픽커 오픈 여부
      fontPointColor: {
        font_color_1: '',
      },
      pendingData: null,
      mainPageType: '',
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

    this.getSoctyImg();
  },
  computed: {
    isOnPending() {
      const allData = JSON.stringify([
            this.mainPointColor
        ])
      console.log(this.pendingData != allData);
      return this.pendingData != allData;
    }
  },
  methods: {
    // fileChanged(file, item) {
    //   console.log("item[" + item + "] changed.", file);
    // },
    getSoctyImg() {
      // 기존 리스트 초기화
      this.topMainLogoSelectedFile = null;
      this.btmLogoSelectedFile = null;
      this.adminPageLogoSelectedFile = null;
      this.joinTopLogoSelectedFile = null;
      this.mainPageTopImgSelectedFile = null;
      this.mainPageBtmImgSelectedFile = null;
      this.hdrImgSelectedFile = null;
      this.crtfLogoSelectedFile = null;
      this.soctySealSelectedFile = null;
      this.mobTopMainLogoSelectedFile = null;
      this.faviconSelectedFile = null;
      // this.topMainLogoCurrentFile = [];
      // this.btmLogoCurrentFile = [];
      // this.adminPageLogoCurrentFile = [];
      // this.joinTopLogoCurrentFile = [];
      // this.mainPageTopImgCurrentFile = [];
      // this.mainPageBtmImgCurrentFile = [];
      // this.hdrImgCurrentFile = [];
      // this.crtfLogoCurrentFile = [];
      // this.soctySealCurrentFile = [];

      const params = new FormData();

			this.$http.post("/admin/getSoctyImg", params).then((res) => {
        // console.log("res.data: ", res.data.result.length);
        if(res.data.result.length == 0) {
          // alert("조회된 정보가 없습니다.");
          this.isNew = true;

          // this.topMainLogoCurrentFile.push(   {file_item:"top_main_logo",     file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
          // this.btmLogoCurrentFile.push(       {file_item:"btm_logo",          file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
          // this.adminPageLogoCurrentFile.push( {file_item:"admin_page_logo",   file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
          // this.joinTopLogoCurrentFile.push(   {file_item:"join_top_logo",     file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
          // this.mainPageTopImgCurrentFile.push({file_item:"main_page_top_img", file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
          // this.mainPageBtmImgCurrentFile.push({file_item:"main_page_btm_img", file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
          // this.hdrImgCurrentFile.push(        {file_item:"hdr_img",           file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
          // this.crtfLogoCurrentFile.push(      {file_item:"crtf_logo",         file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
          // this.soctySealCurrentFile.push(     {file_item:"socty_seal",        file_path:null,     orgnl_file_nm:null,      file_atch_sno:null,      file_delete:"삭제"});
        } else {
          this.isNew = false;

          const img = res.data.result[0];

          this.topMainLogoCurrentFile.file_item = "top_main_logo";
          this.topMainLogoCurrentFile.file_path = img.top_main_logo_path;
          this.topMainLogoCurrentFile.orgnl_file_nm = img.top_main_logo_orgnl_file_nm;
          this.topMainLogoCurrentFile.file_atch_sno = img.top_main_logo_atch_sno;
          this.btmLogoCurrentFile.file_item = "btm_logo";
          this.btmLogoCurrentFile.file_path = img.btm_logo_path;
          this.btmLogoCurrentFile.orgnl_file_nm = img.btm_logo_orgnl_file_nm;
          this.btmLogoCurrentFile.file_atch_sno = img.btm_logo_atch_sno;
          this.adminPageLogoCurrentFile.file_item = "admin_page_logo";
          this.adminPageLogoCurrentFile.file_path = img.admin_page_logo_path;
          this.adminPageLogoCurrentFile.orgnl_file_nm = img.admin_page_logo_orgnl_file_nm;
          this.adminPageLogoCurrentFile.file_atch_sno = img.admin_page_logo_atch_sno;
          this.joinTopLogoCurrentFile.file_item = "join_top_logo";
          this.joinTopLogoCurrentFile.file_path = img.join_top_logo_path;
          this.joinTopLogoCurrentFile.orgnl_file_nm = img.join_top_logo_orgnl_file_nm;
          this.joinTopLogoCurrentFile.file_atch_sno = img.join_top_logo_atch_sno;
          this.mainPageTopImgCurrentFile.file_item = "main_page_top_img";
          this.mainPageTopImgCurrentFile.file_path = img.main_page_top_img_path;
          this.mainPageTopImgCurrentFile.orgnl_file_nm = img.main_page_top_img_orgnl_file_nm;
          this.mainPageTopImgCurrentFile.file_atch_sno = img.main_page_top_img_atch_sno;    
          this.mainPageBtmImgCurrentFile.file_item = "main_page_btm_img";
          this.mainPageBtmImgCurrentFile.file_path = img.main_page_btm_img_path;
          this.mainPageBtmImgCurrentFile.orgnl_file_nm = img.main_page_btm_img_orgnl_file_nm;
          this.mainPageBtmImgCurrentFile.file_atch_sno = img.main_page_btm_img_atch_sno;   
          this.hdrImgCurrentFile.file_item = "hdr_img";
          this.hdrImgCurrentFile.file_path = img.hdr_img_path;
          this.hdrImgCurrentFile.orgnl_file_nm = img.hdr_img_orgnl_file_nm;
          this.hdrImgCurrentFile.file_atch_sno = img.hdr_img_atch_sno;   
          this.crtfLogoCurrentFile.file_item = "crtf_logo";
          this.crtfLogoCurrentFile.file_path = img.crtf_logo_path;
          this.crtfLogoCurrentFile.orgnl_file_nm = img.crtf_logo_orgnl_file_nm;
          this.crtfLogoCurrentFile.file_atch_sno = img.crtf_logo_atch_sno; 
          this.soctySealCurrentFile.file_item = "socty_seal";
          this.soctySealCurrentFile.file_path = img.socty_seal_path;
          this.soctySealCurrentFile.orgnl_file_nm = img.socty_seal_orgnl_file_nm;
          this.soctySealCurrentFile.file_atch_sno = img.socty_seal_atch_sno;
          this.faviconCurrentFile.file_item = "favicon";
          this.faviconCurrentFile.file_path = img.favicon_path;
          this.faviconCurrentFile.orgnl_file_nm = img.favicon_orgnl_file_nm;
          this.faviconCurrentFile.file_atch_sno = img.favicon_atch_sno; 
          this.mobTopMainLogoCurrentFile.file_item = "mob_top_main_logo";
          this.mobTopMainLogoCurrentFile.file_path = img.mob_top_main_logo_path;
          this.mobTopMainLogoCurrentFile.orgnl_file_nm = img.mob_top_main_logo_orgnl_file_nm;
          this.mobTopMainLogoCurrentFile.file_atch_sno = img.mob_top_main_logo_atch_sno;

          if(res.data.fontColor.font_color_1 != null) {
            this.fontPointColor.font_color_1 = res.data.fontColor.font_color_1;
          } else {
            this.fontPointColor.font_color_1 = '#000000FF';
          }

          console.log(" fontColor " + JSON.stringify([this.fontPointColor]));
          this.pendingData = JSON.stringify([
            this.fontPointColor
          ])
          

          // this.topMainLogoCurrentFile.push(   {file_item:"top_main_logo",     file_path:img.top_main_logo_path,     orgnl_file_nm:img.top_main_logo_orgnl_file_nm,      file_atch_sno:img.top_main_logo_atch_sno,      file_delete:"삭제"});
          // this.btmLogoCurrentFile.push(       {file_item:"btm_logo",          file_path:img.btm_logo_path,          orgnl_file_nm:img.btm_logo_orgnl_file_nm,           file_atch_sno:img.btm_logo_atch_sno,           file_delete:"삭제"});
          // this.adminPageLogoCurrentFile.push( {file_item:"admin_page_logo",   file_path:img.admin_page_logo_path,   orgnl_file_nm:img.admin_page_logo_orgnl_file_nm,    file_atch_sno:img.admin_page_logo_atch_sno,    file_delete:"삭제"});
          // this.joinTopLogoCurrentFile.push(   {file_item:"join_top_logo",     file_path:img.join_top_logo_path,     orgnl_file_nm:img.join_top_logo_orgnl_file_nm,      file_atch_sno:img.join_top_logo_atch_sno,      file_delete:"삭제"});
          // this.mainPageTopImgCurrentFile.push({file_item:"main_page_top_img", file_path:img.main_page_top_img_path, orgnl_file_nm:img.main_page_top_img_orgnl_file_nm,  file_atch_sno:img.main_page_top_img_atch_sno,  file_delete:"삭제"});
          // this.mainPageBtmImgCurrentFile.push({file_item:"main_page_btm_img", file_path:img.main_page_btm_img_path, orgnl_file_nm:img.main_page_btm_img_orgnl_file_nm,  file_atch_sno:img.main_page_btm_img_atch_sno,  file_delete:"삭제"});
          // this.hdrImgCurrentFile.push(        {file_item:"hdr_img",           file_path:img.hdr_img_path,           orgnl_file_nm:img.hdr_img_orgnl_file_nm,            file_atch_sno:img.hdr_img_atch_sno,            file_delete:"삭제"});
          // this.crtfLogoCurrentFile.push(      {file_item:"crtf_logo",         file_path:img.crtf_logo_path,         orgnl_file_nm:img.crtf_logo_orgnl_file_nm,          file_atch_sno:img.crtf_logo_atch_sno,          file_delete:"삭제"});
          // this.soctySealCurrentFile.push(     {file_item:"socty_seal",        file_path:img.socty_seal_path,        orgnl_file_nm:img.socty_seal_orgnl_file_nm,         file_atch_sno:img.socty_seal_atch_sno,         file_delete:"삭제"});
          // console.log("this.topMainLogoCurrentFile", this.topMainLogoCurrentFile);
        }
			}).catch(function (error) {
					console.log(error);
			});

    },
    saveBtnClicked() {
      console.log("saveSocty");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();
        const file_info_list = [];
        // const font_color_list = [];

        params.append("mber_sno", this.mberSno);
        params.append('new_yn', this.isNew ? 'Y' : 'N');

        if(this.topMainLogoSelectedFile != null) {
          params.append('file_list', this.topMainLogoSelectedFile);
          file_info_list.push({item:"top_main_logo"});
        } 
         
        if(this.btmLogoSelectedFile != null) {
          params.append('file_list', this.btmLogoSelectedFile);
          file_info_list.push({item:"btm_logo"});
        } 

        if(this.adminPageLogoSelectedFile != null) {
          params.append('file_list', this.adminPageLogoSelectedFile);
          file_info_list.push({item:"admin_page_logo"});
        } 

        if(this.joinTopLogoSelectedFile != null) {
          params.append('file_list', this.joinTopLogoSelectedFile);
          file_info_list.push({item:"join_top_logo"});
        } 

        if(this.mainPageTopImgSelectedFile != null) {
          params.append('file_list', this.mainPageTopImgSelectedFile);
          file_info_list.push({item:"main_page_top_img"});
        } 

        if(this.mainPageBtmImgSelectedFile != null) {
          params.append('file_list', this.mainPageBtmImgSelectedFile);
          file_info_list.push({item:"main_page_btm_img"});
        } 

        if(this.hdrImgSelectedFile != null) {
          params.append('file_list', this.hdrImgSelectedFile);
          file_info_list.push({item:"hdr_img"});
        } 

        if(this.crtfLogoSelectedFile != null) {
          params.append('file_list', this.crtfLogoSelectedFile);
          file_info_list.push({item:"crtf_logo"});
        } 

        if(this.soctySealSelectedFile != null) {
          params.append('file_list', this.soctySealSelectedFile);
          file_info_list.push({item:"socty_seal"});
        }
        
        if(this.faviconSelectedFile != null) {
          params.append('file_list', this.faviconSelectedFile);
          file_info_list.push({item:"favicon"});
        }

        if(this.mobTopMainLogoSelectedFile != null) {
          params.append('file_list', this.mobTopMainLogoSelectedFile);
          file_info_list.push({item:"mob_top_main_logo"});
        }

        // if(this.fontPointColor != null) {
        //   font_color_list.push({item:"font_color_1"})
        // }
        
        console.log("file_info_list.length:", file_info_list.length);
        console.log("file_info_list:::::::::", file_info_list);

        // if(file_info_list.length == 0) {
        //   alert("변경된 파일이 없습니다.");
        //   return;
        // }

        // if(file_info_list.length == 0 && font_color_list.length == 0) {
        //   alert("변경된 내용이 없습니다.");
        //   return;
        // }
        
        params.append("mainPointColor", JSON.stringify(this.fontPointColor));
        params.append("file_info_list", JSON.stringify(file_info_list));

        this.$http.post("/admin/upsertSoctyImg", params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          this.getSoctyImg();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    deleteImgBtnClicked(fileItem, filePath, fileAtchSno) {
      if(confirm("삭제하시겠습니까?")) {
        // console.log("filePath:", filePath);
        // console.log("atchSno:", fileAtchSno);
        
        if(filePath == null) {
          alert("삭제할 파일이 없습니다.");
          return;
        }

        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("file_item", fileItem);
        params.append("file_path", filePath);
        params.append("file_atch_sno", fileAtchSno);

        this.$http.post("/admin/deleteSoctyImgFile", params).then((res) => {
          console.log(res.data);
          alert("삭제되었습니다.");
          
          this.getSoctyImg();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'imageFileTab');
    },
    fontColorPickerClicked(no){
      this.fontColorPickNo = no;
      this.fontColor = this.fontPointColor[`font_color_`+no];  //눌렀을때 색상위치로 초기화
      this.fontColorPickerPop = true;
    },
    selColorClicked() {
      this.fontPointColor[`font_color_`+this.fontColorPickNo] = this.fontColor;
      console.log(this.fontPointColor);
      this.fontColorPickerPop = false;
      this.itemChanged();
    },//selColorClicked end
  },
}
</script>

<style>
</style>