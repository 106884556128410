<template>
  <div>
    <v-btn class="mb-5" @click="pdfDown(1)">HEB 분과 (부)편집위원장 증명서</v-btn><br>
    <v-btn class="mb-5" @click="pdfDown(2)">HEB 편집위원장 증명서</v-btn><br>
    <v-btn class="mb-5" @click="pdfDown(3)">HST 편집위원 증명서</v-btn><br>
    <v-btn class="mb-5" @click="pdfDown(4)">납부확인서-2023 연회비(정회원)</v-btn><br>
    <v-btn class="mb-5" @click="pdfDown(5)">납부확인서-2023 임시총회 및 제118차 추계학술발표회 사전등록비</v-btn><br>
    <v-btn class="mb-5" @click="pdfDown(6)">임원 증명서</v-btn><br>
    <v-btn class="mb-5" @click="pdfDown(7)">청구서-2023 연회비(학생회원)</v-btn><br>
    <v-btn class="mb-5" @click="pdfDown(8)">회원 증명서(영문)</v-btn><br>
    <v-btn class="mb-5" @click="pdfDown(9)">회원 증명서(한글)</v-btn><br>

  </div>
</template>

<script>

export default {
  data(){
    return {
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      tete: '',
    }
  },
  created() {
    
  },
  methods: {
    pdfDown(pdfNo) {
      // let pdfNo = 2;
      const formData = new FormData();
      formData.append('mber_sno',this.mberSno);
      formData.append('pdfNo',pdfNo);
      formData.append('tete',this.tete);
      this.$http.post("/sub/downloadPdf",formData,{
        responseType: 'blob'
      }).then((res) => {
        console.log("res.data.result:", res.data);
        const url = window.URL.createObjectURL(res.data);
        const aTag = document.createElement('a');
        aTag.href = url;

        let fileNm = '';
        if(pdfNo == 1) {
          fileNm = 'HEB 분과 (부)편집위원장 증명서.pdf';
        } else if(pdfNo == 2) {
          fileNm = 'HEB 편집위원장 증명서.pdf';
        } else if(pdfNo == 3) {
          fileNm = 'HST 편집위원 증명서.pdf';
        } else if(pdfNo == 4) {
          fileNm = '납부확인서-2023 연회비(정회원).pdf';
        } else if(pdfNo == 5) {
          fileNm = '납부확인서-2023 임시총회 및 제118차 추계학술발표회 사전등록비.pdf';
        } else if(pdfNo == 6) {
          fileNm = '임원 증명서.pdf';
        } else if(pdfNo == 7) {
          fileNm = '청구서-2023 연회비(학생회원).pdf';
        } else if(pdfNo == 8) {
          fileNm = '회원 증명서(영문).pdf';
        } else if(pdfNo == 9) {
          fileNm = '회원 증명서(한글).pdf';
        }

        aTag.download = fileNm;
        aTag.click();
        // this.confrList = res.data.result;
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//pdfDown end
  }
}
</script>

<style>

</style>