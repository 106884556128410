<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="">
      <div class="wid-100">
        <v-row dense align="center">

          <v-col cols="2" >
            <div class="pt-0">
              <span>저작권 규정</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="cprgt_rule" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>개인 정보 처리 방침</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="prvcy_polcy" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';

export default {
  components: { tinyEditor },
  props: ["propParams"],
  data(){
    return{
      mberSno: "",
      confrSno: "",

      newYn: 'N',             // 조회된 값이 있으면 false
      cprgt_rule: "",           // 저작권규정
      prvcy_polcy: ""   // 개인정보처리방침
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.propParams.confrSno; 

    this.getConfrPolcy();
  },
  methods: {
    getConfrPolcy() {
      const params = new FormData();

      params.append("confr_sno", this.confrSno);

			this.$http.post("/admin/getConfrPolcy", params).then((res) => {
        // console.log("res.data.sult: ", res.data.result);
        const polcy = res.data.result.confrPolcy;

        if(polcy == null) {
          this.newYn = 'Y';
          // alert("조회된 정보가 없습니다.");
          return;
        } else {
          this.newYn = 'N';
        }

        this.cprgt_rule = polcy.cprgt_rule;
        this.prvcy_polcy = polcy.prvcy_polcy;

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveConfrPolcy");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append('new_yn', this.newYn);

        if(this.cprgt_rule != null) {
          params.append("cprgt_rule", this.cprgt_rule);
        }
        if(this.prvcy_polcy != null) {
          params.append("prvcy_polcy", this.prvcy_polcy);
        }

        this.$http.post("/admin/upsertConfrPolcy", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getConfrPolcy();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'conferencePolicyTab');
    },
  },
}
</script>

<style>

</style>