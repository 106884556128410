<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            @click="saveBtnClicked(1)"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            @click="saveBtnClicked(2)"
            >저장 후 유지
          </v-btn>
          <v-btn 
            small
            class="black--text f-l"
            to="/admin/menuList"
            >목록
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense align="center">
      <v-col cols="2" >
        <div class="pt-0">
          <span>활성 언어</span>
        </div>
      </v-col>
      <v-col cols="3" >
        <v-checkbox
          v-model="use_ko_menu_yn"
          hide-details
          dense
          label="한국어"
          style="float:left;"
        ></v-checkbox>
        <v-checkbox
          v-model="use_en_menu_yn"
          hide-details
          dense
          label="영어"
          class="ml-6 f-l"
        ></v-checkbox>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>메뉴명 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="menu_nm"
          class=" pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>{{use_en_menu_yn ? `메뉴명(영문) *` : `메뉴명(영문)`}}</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="menu_nm_en"
          class=" pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>페이지ID *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="resrc_nm"
          class=" pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>페이지 유형 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          class="pa-0 ma-0 input-hei-d" 
          :items="pageTypeList"
          item-value="cmn_cd"
          item-text="cmn_cd_nm"
          v-model="page_type"
          background-color="white"
          hide-details
          dense
          outlined
        ></v-select>
      </v-col>

      <v-col cols="12" v-show="page_type == 'abstr_reg' || page_type == 'pre_reg' || page_type == 'ad_disp_guid' || page_type == 'trfc_accom_guid' || page_type == 'event_tour' || page_type == 'confr_scdul' || page_type == 'confr_outl'"><v-divider></v-divider></v-col>
      <v-col cols="2" v-show="page_type == 'abstr_reg' || page_type == 'pre_reg' || page_type == 'ad_disp_guid' || page_type == 'trfc_accom_guid' || page_type == 'event_tour' || page_type == 'confr_scdul' || page_type == 'confr_outl'">
        <div class="pt-0">
          <span>학술대회 구분 *</span>
        </div>
      </v-col>
      <v-col cols="3" v-show="page_type == 'abstr_reg' || page_type == 'pre_reg' || page_type == 'ad_disp_guid' || page_type == 'trfc_accom_guid' || page_type == 'event_tour' || page_type == 'confr_scdul' || page_type == 'confr_outl'">
        <v-select
          class="pa-0 ma-0 input-hei-d" 
          :items="confrDivList"
          item-value="cmn_cd"
          item-text="cmn_cd_nm"
          v-model="confr_div"
          background-color="white"
          hide-details
          dense
          outlined
        ></v-select>
      </v-col>

      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2">
        <div class="pt-0">
          <span>상위 메뉴</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="upper_menu_nm"
          class=" pt-0"
          hide-details
          outlined
          readonly
          background-color="grey lighten-2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" v-if="page_type == 'url_link'"><v-divider></v-divider></v-col>
      <v-col cols="2" v-if="page_type == 'url_link'">
        <div class="pt-0">
          <span>링크 URL *</span>
        </div>
      </v-col>
      <v-col cols="3" v-if="page_type == 'url_link'">
        <v-text-field
          class=" pt-0"
          hide-details
          outlined
          v-model="url_link"
          placeholder="https://www.naver.com"
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" class="pb-3">
        <div class="pt-0">
          <span>노출여부</span>
        </div>
      </v-col>
      <v-col cols="3" class="pb-3">
        <v-checkbox
          v-model="disp_yn"
          hide-details
          dense
        ></v-checkbox>
      </v-col>
      <v-col cols="12" v-show="menu_sno && page_type == 'bbs_notc' || page_type == 'bbs_free' || page_type == 'bbs_faq' || page_type == 'bbs_glly' || page_type == 'bbs_qna'"><v-divider></v-divider></v-col>
      <v-col cols="2" v-show="menu_sno && page_type == 'bbs_notc' || page_type == 'bbs_free' || page_type == 'bbs_faq' || page_type == 'bbs_glly' || page_type == 'bbs_qna'">
        <div class="pt-0">
          <span>카테고리</span>
        </div>
      </v-col>
      <v-col cols="3" v-show="menu_sno && page_type == 'bbs_notc' || page_type == 'bbs_free' || page_type == 'bbs_faq' || page_type == 'bbs_glly' || page_type == 'bbs_qna'">
        <v-text-field
          v-model="bbst_catg"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="5" v-show="menu_sno && page_type == 'bbs_notc' || page_type == 'bbs_free' || page_type == 'bbs_faq' || page_type == 'bbs_glly' || page_type == 'bbs_qna'">
        <span class="pl-3 text-grey">카테고리 입력은 " , " 로 구분해주세요.  ( 예시 - 카테고리명, 카테고리명, 카테고리명 ) </span>
      </v-col>
    </v-row>  
    <div v-show="menu_sno && page_type == 'static'">
      <v-col cols="12" class="pa-0 pb-1"><v-divider></v-divider></v-col>
      <h1>한국어</h1>
      <tiny-editor box_height="400" ref="body_ko_cn"></tiny-editor>
      
      <v-col cols="12" class="pa-0 pb-1 pt-4"><v-divider></v-divider></v-col>
      <h1>영어</h1>
      <tiny-editor box_height="400" ref="body_en_cn"></tiny-editor>
    </div>
    <div v-show="menu_sno && page_type == 'publc'">
      <v-col cols="12" class="pa-0 pb-1"><v-divider></v-divider></v-col>
      <h1>한국어</h1>
      <tiny-editor box_height="400" ref="body_ko_cn_pub"></tiny-editor>
      
      <v-col cols="12" class="pa-0 pb-1 pt-4"><v-divider></v-divider></v-col>
      <h1>영어</h1>
      <tiny-editor box_height="400" ref="body_en_cn_pub"></tiny-editor>
    </div>
  </v-container>
  
</template>


<script>
import tinyEditor from '@/components/common/tinyEditor.vue';

export default {
  components: {
    tinyEditor
  },
  data(){
    return{
      menu_nm: '',
      menu_nm_en: '',
      bbst_catg: '',
      resrc_nm: '',
      page_type: '',
      disp_yn: false,
      pageTypeList: [],
      upper_menu_sno: this.$route.query.upperMenuSno ? this.$route.query.upperMenuSno : 0,
      menu_sno: this.$route.query.menuSno,
      mberSno: '',
      upperList: [],
      page_cn: '',
      page_cn_en: '',
      use_ko_menu_yn: true,
      use_en_menu_yn: false,
      upper_menu_nm: this.$route.params.upperMenuNm ? this.$route.params.upperMenuNm : '',
      menuLvl: this.$route.params.menuLvl ? this.$route.params.menuLvl : 0,
      url_link: '',
      confrDivList: [],
      confr_div: '',
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.getPageTypeList();
    if(this.menu_sno) {
      this.getMenuDetail();
    }
    this.getConfrDivList();
  },
  mounted() {
  },
  methods: {
    async getPageTypeList() {
      this.pageTypeList = await this.$getCmnCdList("page_type");
    },//getPageTypeList end
    saveBtnClicked(type) {
      const isValid = this.validateInput();

      if(isValid) {
        const params = new FormData();

        params.append("menu_nm", this.menu_nm);
        params.append("menu_nm_en", this.menu_nm_en);
        params.append("resrc_nm", this.resrc_nm);
        params.append("page_type", this.page_type);
        params.append("disp_yn", this.disp_yn ? 'Y' : 'N');
        params.append("use_ko_menu_yn", this.use_ko_menu_yn ? 'Y' : 'N');
        params.append("use_en_menu_yn", this.use_en_menu_yn ? 'Y' : 'N');
        params.append("upper_menu_sno", this.upper_menu_sno);
        if(!this.menu_sno) {
          this.menu_sno = '';
        }
        params.append("menu_sno", this.menu_sno);
        params.append("mber_sno", this.mberSno);

        if(this.page_type == 'static') {

        let ko_cn_replace = "";
        if(this.$refs.body_ko_cn.content) {
          ko_cn_replace = this.$refs.body_ko_cn.content.replaceAll("../DEV","/DEV");
        }
        params.append("page_cn", ko_cn_replace);

        let en_cn_replace = "";
        if(this.$refs.body_en_cn.content) {
          en_cn_replace = this.$refs.body_en_cn.content.replaceAll("../DEV","/DEV");
        }
        params.append("page_cn_en", en_cn_replace);

      } else if(this.page_type == 'publc') {

        let ko_cn_replace = "";
        if(this.$refs.body_ko_cn_pub.content) {
          ko_cn_replace = this.$refs.body_ko_cn_pub.content.replaceAll("../DEV","/DEV");
        }
        params.append("menu_outl", ko_cn_replace);

        let en_cn_replace = "";
        if(this.$refs.body_en_cn_pub.content) {
          en_cn_replace = this.$refs.body_en_cn_pub.content.replaceAll("../DEV","/DEV");
        }
        params.append("menu_outl_en", en_cn_replace);

      } else if(this.page_type == "abstr_reg" || this.page_type == "pre_reg" || this.page_type == "ad_disp_guid" || this.page_type == "trfc_accom_guid" || this.page_type == "event_tour" || this.page_type == "confr_scdul" || this.page_type == "confr_outl") {
        params.append("confr_div", this.confr_div);
      }

        params.append("bbst_catg", this.bbst_catg);
        params.append("menu_lvl", this.menuLvl+1);

        params.append("url_link", this.url_link);
        
        this.$http.post("/admin/upsertMenu", params).then((res) => {
          if(res.data.code == 0) {
            alert('저장되었습니다.');

            if(type == 1) {
              this.$router.push({name:'menuList'});
            } else if(type == 2) {
              const menuSno = res.data.menu_sno;
              this.$router.push({name:'menuDetail',query: { menuSno: menuSno }});
            }
          } else if(res.data.code == -9004) {
            alert("이미 존재하는 페이지 ID 입니다.");
            return;
          }  else if(res.data.code == -9008) {
            alert("1depth 메뉴는 7개까지 생성가능합니다.");
            return;
          }
          
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
      
    },//saveBtnClicked end
    getMenuDetail() {
      const params = new FormData();
      params.append("menu_sno", this.menu_sno);

      this.$http.post("/admin/getMenuDetail", params).then((res) => {
        const result = res.data.result;
        this.menu_nm = result.menu_nm;
        this.menu_nm_en = result.menu_nm_en;
        this.resrc_nm = result.resrc_nm;
        this.page_type = result.page_type;
        this.disp_yn = result.disp_yn == 'N' ? false : true;
        this.use_ko_menu_yn = result.use_ko_menu_yn == 'N' ? false : true;
        this.use_en_menu_yn = result.use_en_menu_yn == 'N' ? false : true;
        this.upper_menu_sno = result.upper_menu_sno;
        this.url_link = result.url_link;
        
        // console.log("result.page_cn :: ",this.$refs.bodyKoCn);
        // console.log("result.page_cn :: ",result.page_cn_en);
        if(this.page_type == 'static') {
          this.$refs.body_ko_cn.content= result.page_cn;
          this.$refs.body_en_cn.content= result.page_cn_en;
        } else if(this.page_type == 'publc') {
          this.$refs.body_ko_cn_pub.content= result.menu_outl;
          this.$refs.body_en_cn_pub.content= result.menu_outl_en;
        } else if(this.page_type == "abstr_reg" || this.page_type == "pre_reg" || this.page_type == "ad_disp_guid" || this.page_type == "trfc_accom_guid" || this.page_type == "event_tour" || this.page_type == "confr_scdul" || this.page_type == "confr_outl") {
          this.confr_div = result.confr_div;
        }
        
        this.upper_menu_nm = result.upper_menu_nm;
        this.bbst_catg = result.bbst_catg;

      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//getMenuDetail end
    validateInput() {
      // 메뉴명
      if(this.menu_nm == null || this.menu_nm == "") {
        alert("메뉴명은 필수 입력값입니다.");
        return false;
      }

      if(this.use_en_menu_yn && !this.menu_nm_en) {
        alert("메뉴명(영문)은 필수 입력값입니다.");
        return false;
      }
      
      // 페이지ID
      if(this.resrc_nm == null || this.resrc_nm == "") {
        alert('페이지ID는 필수 입력값입니다.');
        return false;
      }
      // 페이지 유형
      if(this.page_type == null || this.page_type == "") {
        alert('페이지 유형은 필수 입력값입니다.');
        return false;
      }

      if(this.page_type == 'url_link' && this.url_link == "") {
        alert('링크 URL은 필수 입력값입니다.');
        return false;
      }
      
      if((this.page_type == "abstr_reg" || this.page_type == "pre_reg" || this.page_type == "ad_disp_guid" || this.page_type == "trfc_accom_guid" || this.page_type == "event_tour" || this.page_type == "confr_scdul" || this.page_type == "confr_outl") && !this.confr_div) {
        alert('학술대회 구분은 필수 입력값입니다.');
        return false;
      }

      return true;
    },//validateInput end
    sendFile(blobInfo, success) {
      console.log(blobInfo,success);

      const formData = new FormData();
      formData.append('fileData', blobInfo.blob(), blobInfo.filename());
      this.$http.post("/common/uploadTinyImg", formData, {
        headers: 
        {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if(res.data.code == 0) {
          const img_path = res.data.img_path;
          success(img_path);
        } else {
          alert("실패");
        }
      }).catch(function(error) {
          console.log(error);
      });//axios End
    },
    async getConfrDivList() {
      this.confrDivList = await this.$getCmnCdList("confr_div");
    },//getConfrDivList end
  },
}
</script>

<style>

</style>