<template>
  <v-container fluid>
    <v-row class="pa-3">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="gotoList"
          >목록</v-btn>
        </div>
        <div style="float:right;">
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn
            small
            color="red lighten-2"
            class="white--text mr-2"
            v-show="newYn == 'N'"
            @click="deleteBtnClicked"
          >초록등록 삭제</v-btn>
        </div>
      </v-col>
    </v-row>
    
    <v-tabs
      v-model="tab"
      style=""
    >
      <v-tab
        v-for="item of tabList"
        :key="item.tabNm"
        exact
        :disabled="!item.enabled"
      >
        {{ item.tabNm }}
        <v-badge 
          v-show="item.changed"
          color="red"
          class="pb-2"
          dot
        ></v-badge>
      </v-tab>
      <v-tabs-items 
        v-model="tab"
      > 
        <v-divider></v-divider>
        <v-tab-item
          v-for="item in tabList"
          :key="item.tabNm"
          class="ma-5"
        >
          <component v-bind:is="item.content" :ref="item.content" class="w-100" v-bind:propParams="propParams" @tabChanged="tabChanged" @abstrRegCreated="abstrRegCreated"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import abstrRegistInfoTab from '@/components/admin/tabs/conference/abstrRegistInfoTab';
import abstrRegistAuthorTab from '@/components/admin/tabs/conference/abstrRegistAuthorTab';
// import abstrRegistAuthorBelongTab from '@/components/admin/tabs/conference/abstrRegistAuthorBelongTab';

export default {
  components: {
    abstrRegistInfoTab, abstrRegistAuthorTab  //, abstrRegistAuthorBelongTab
  },
  data(){
    return {
      tab: null,
      tabList: [
        { tabNm: '초록등록 정보', content: 'abstrRegistInfoTab', tabNo: 0, enabled:true, changed: false},
        { tabNm: '저자', content: 'abstrRegistAuthorTab', tabNo: 1, enabled:false, changed: false},
        // { tabNm: '저자 소속', content: 'abstrRegistAuthorBelongTab', tabNo: 2, enabled:false, changed: false},
      ],
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),

      newYn: this.$route.query.newYn,
      confrSno: this.$route.query.confrSno,
      abstrRegSno: this.$route.query.abstrRegSno,
      propParams: {},
      redrawCount: 0,

      saveBtnEnabled: false,
    }
  },
  created() {
    // 탭 활성화
    if(this.newYn == 'N') {
      this.tabList.forEach((e) => {
        e.enabled = true;
      });
    }

    this.propParams['newYn'] = this.newYn;
    this.propParams['confrSno'] = this.confrSno;
    this.propParams['abstrRegSno'] = this.abstrRegSno;

  },
  computed: {

  },
  methods: {
    updateRouter(item) {
      this.$router.push(item);
    },//updateRouter end
    gotoList() {
      // this.$router.go(-1)
      // 아래 path 설정 시 select box 에 값 설정이 안됨
      // this.$router.push({path:"abstrRegistList", query: { confrSno:this.confrSno }});
      this.$router.push({name:"abstrRegistList", query: { confrSno:this.confrSno }});
    },
    tabChanged(tabId, isChanged) {
      // console.log("preRegistDetail tabChanged:", tabId, isChanged);
      this.tabList.filter((e) => e.content == tabId).map((f) => {
        // f.changed = true;
        f.changed = isChanged;
      })

      this.saveBtnEnabled = this.tabList.some((e) => e.changed);
      // console.log("tabList:", this.tabList);     
    },
    // 저자소속 변경 -> 소속 목록 재조회
    // belongChanged() {
    //   console.log("abstrRegistDetail > belongCreated");
    //   this.$refs.abstrRegistAuthorTab[0].getAthorBlngList();
    // },
    abstrRegCreated(abstrRegSno) {
      // console.log("abstrRegCreated:", abstrRegSno);
      this.propParams['abstrRegSno'] = abstrRegSno;
      this.abstrRegSno = abstrRegSno;
      this.newYn = 'N';
    },
    deleteBtnClicked() {
      console.log("deleteConfr");

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);

        this.$http.post("/admin/deleteConfr", params).then((res) => {
          console.log(res.data);
          alert("삭제되었습니다.");

          this.$router.push({path:"conferenceList"})
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    saveNMoveBtnClicked() {
      console.log("saveNMoveBtnClicked");
      this.saveAbstrRegistnfo();
      // this.$router.push({path:"abstrRegistList"});
      // this.$router.go(-1);
      this.gotoList();
    },
    saveNStayBtnClicked() {
      console.log("saveNStayBtnClicked");
      this.saveAbstrRegistnfo();
    },
    saveAbstrRegistnfo() {
      // console.log("saveAbstrRegistnfo");
      // console.log("refs:", this.$refs);
      // console.log("filtered:", this.tabList.filter(e => e.changed));

      if(confirm("저장하시겠습니까?")) {
        const filtered = this.tabList.filter(e => e.changed);
        // console.log("filtered:", filtered);
        // 입력 데이터 검증
        let isValid = false;
        filtered.forEach((e) => {
          if(e.content == "abstrRegistInfoTab") {
            isValid = this.$refs.abstrRegistInfoTab[0].validateInput();
          } else if(e.content == "abstrRegistAuthorTab") {
            isValid = this.$refs.abstrRegistAuthorTab[0].validateInput();
            // isValid = this.$refs.abstrRegistAuthorTab[0].validateBelongInput();
            // isValid = isValid && this.$refs.abstrRegistAuthorTab[0].validateAuthorInput();
          // } else if(e.content == "abstrRegistAuthorBelongTab") {
          //   isValid = this.$refs.abstrRegistAuthorBelongTab[0].validateInput();
          } 
        });

        if(!isValid) {
          // alert("invalid");
        } else {
          filtered.forEach((e) => {
            if(e.content == "abstrRegistInfoTab") {
              this.$refs.abstrRegistInfoTab[0].saveBtnClicked();
            } else if(e.content == "abstrRegistAuthorTab") {
              // this.$refs.abstrRegistAuthorTab[0].saveBelongBtnClicked();
              this.$refs.abstrRegistAuthorTab[0].saveBtnClicked();
            // } else if(e.content == "abstrRegistAuthorBelongTab") {
            //   this.$refs.abstrRegistAuthorBelongTab[0].saveBtnClicked();
            } 
          });        

          this.tabList.forEach((e) => {
            e.enabled = true;
            e.changed = false;
          });

          this.saveBtnEnabled = false;

          alert("저장되었습니다.");
        }
      }
    },
  }
}
</script>

<style>

</style>