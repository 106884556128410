<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>상단정보(한글)</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="3"
                hide-details
                outlined
                v-model="cmnFormMap.hdr_text"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>상단정보(영문)</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="3"
                hide-details
                outlined
                v-model="cmnFormMap.hdr_text_en"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>학회장(한글)</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model="cmnFormMap.socty_chrmn"
              @change="itemChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>학회장(영문)</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model="cmnFormMap.socty_chrmn_en"
              @change="itemChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- 하단정보 start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>하단정보1</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="3"
                hide-details
                outlined
                v-model="cmnFormMap.bill_footr_text_1"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 하단정보 end -->
          <!-- 하단정보 영문 start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>하단정보2</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="3"
                hide-details
                outlined
                v-model="cmnFormMap.bill_footr_text_2"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 하단정보 영문 end -->
          <v-col cols="2">
            <div class="pt-0">
              <span>학술지별 발간내역 및 금액</span>
            </div>
          </v-col>
          <v-col cols="10" >
            <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text ml-3 f-r"
              @click="jrnlAddClicked"
            >
              항목추가
            </v-btn>
            <v-btn 
              small 
              color="red lighten-2" 
              class="white--text f-r"
              @click="jrnlDelBtnClicked"
            >
              선택삭제
            </v-btn>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="12">
            <!-- [[[[ 추가할 경우 나타나는 항목 start ]]]] -->
            <v-row 
              class="" 
              dense align="center" 
              v-for="(item, idx) in crtfJrnlList"
              :key="`jrnlList_`+idx"
            >
                <v-col cols="2">
                  <v-checkbox
                    hide-details
                    dense
                    label="학술지"
                    v-model="item.select_yn"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12"></v-col>
                <!-- start -->
                <v-col cols="2" >
                  <div class="pt-0">
                    <span>학술지명 *</span>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-select
                    class="wid-100 pt-1" 
                    hide-details
                    outlined
                    dense
                    :items="soctyJrnlList"
                    item-value="jrnl_nm"
                    item-text="jrnl_nm"
                    v-model="item.jrnl_nm"
                    @change="itemChanged"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12"></v-col>
                <!-- end -->
                <!-- start -->
                <v-col cols="2" >
                  <div class="pt-0">
                    <span>발간내역</span>
                  </div>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    class="wid-100 pt-0"
                    hide-details
                    outlined
                    v-model="item.pblc_rec"
                    @change="itemChanged"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12"></v-col>
                <!-- end -->
                <!-- start -->
                <v-col cols="2" >
                  <div class="pt-0">
                    <span>금액(원)</span>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    class="wid-100 pt-0"
                    hide-details
                    outline
                    v-model="item.amt"
                    type="number"
                    @change="itemChanged"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
                <!-- end -->
              </v-row>
            <!-- [[[[ 추가할 경우 나타나는 항목 end ]]]] -->
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      cmnFormMap: {},
      crtfJrnlList: [],
      soctyJrnlList: [],
      jrnlDelList: [],
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
    }
  },
  created() {
    this.$http.get("/admin/getCmnFormSett").then((res) => {
      console.log("res.data: ", res.data);

      this.cmnFormMap = res.data.cmnFormMap;
      const crtfJrnlList = res.data.crtfJrnlList;
      crtfJrnlList.forEach((e) => {
        e.select_yn = false;
        e.new_yn = false;
      });
      this.crtfJrnlList = crtfJrnlList;
      this.soctyJrnlList = res.data.soctyJrnlList;

    }).catch(function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    itemChanged() {
      this.$emit('tabChanged', 'commonCertiTab');
    },
    jrnlAddClicked() {
      let maxOdr = 0;

      this.crtfJrnlList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.crtfJrnlList.push({select_yn: false, new_yn: true, jrnl_nm: '', pblc_rec: '', amt: '', odr: maxOdr})
      this.itemChanged();
    },//jrnlAddClicked end
    jrnlDelBtnClicked() {
      if(this.crtfJrnlList.filter((e) => e.select_yn).length == 0) {
        alert('삭제할 항목 선택해주세요.');
        return;
      }
      if(confirm('삭제하시겠습니까?')) {

        this.jrnlDelList = this.crtfJrnlList.filter((e) => e.select_yn && !e.new_yn);

        if(this.jrnlDelList.length > 0) {
          const params = new FormData();
          params.append('jrnlDelList',JSON.stringify(this.jrnlDelList));

          this.$http.post("/admin/deleteSoctyCrtfJrnl",params).then((res) => {
            console.log("res.data: ", res.data);
            alert('삭제되었습니다.');
            this.getCrtfJrnlList();
          }).catch(function (error) {
              console.log(error);
            }
          );
        } else {
          alert('삭제되었습니다.');
          this.getCrtfJrnlList();
        }
      }
    },//jrnlDelBtnClicked end
    getCrtfJrnlList() {
      this.$http.get("/admin/getCrtfJrnlList").then((res) => {
        console.log("res.data: ", res.data);
        const crtfJrnlList = res.data.crtfJrnlList;
        crtfJrnlList.forEach((e) => {
          e.select_yn = false;
          e.new_yn = false;
        });
        this.crtfJrnlList = crtfJrnlList;
      }).catch(function (error) {
          console.log(error);
        }
      );

    },//getCrtfJrnlList end
    saveBtnClicked() {
      // upsertCmnFormSett
      // mberSno
      const params = new FormData();
      params.append('mber_sno',this.mberSno);
      params.append('cmnFormMap',JSON.stringify(this.cmnFormMap));
      params.append('crtfJrnlList',JSON.stringify(this.crtfJrnlList));

      this.$http.post("/admin/upsertCmnFormSett",params).then((res) => {
        console.log("res.data: ", res.data);
        // if(res.data.code == 0) {
        //   alert('저장되었습니다.');
        //   return;
        // }

      }).catch(function (error) {
          console.log(error);
        }
      );
    },//saveBtnClicked end
    validateInput() {
      if(this.crtfJrnlList.filter((e) => !e.jrnl_nm).length > 0) {
        alert('학술지명은 필수 입력값입니다.');
        return false;
      }
      return true;
    },//validateInput end
  }
  
}
</script>

<style scoped>
.v-input--selection-controls{
  padding-top: 0px !important;
}
.v-input--checkbox.v-input--dense{
  margin-top: 0px !important;
}

/deep/.v-label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>