<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="gotoList"
          >목록</v-btn>
        </div>
        <div>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <!-- <v-btn 
            small 
            color="red lighten-2" 
            class="white--text f-r" 
            @click="deleteBtnClicked"
            v-if="newYn == 'N'"
            >결제 내역 삭제
          </v-btn> -->
        </div>
      </v-col>
    </v-row>
    <v-row dense align="center">
      <v-col cols="2" >
        <div class="pt-0">
          <span>상품 유형</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingPay.prod_type_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>상품명</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingPay.prod_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>수량</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingPay.prod_cnt"
          class="wid-100 pt-0"
          hide-details
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>결제 금액</span>
        </div>
      </v-col>
      <v-col cols="3">
        <numeric-text-field v-model="pendingPay.prod_amt" readonly></numeric-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>통화 구분</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingPay.cur_div_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>회원</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingPay.mber_nm_email"
          class="wid-100 pt-0"
          hide-details
          outlined
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>결제 여부</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="pendingPay.pay_yn"
          hide-details
          dense
          label="결제 완료됨"
        ></v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-btn 
          small 
          color="blue lighten-2" 
          class="white--text ml-3 f-l"
          v-if="!showPayData"
          @click="addPayDataBtnClicked"
          >결제 데이터 추가
        </v-btn>
        <v-btn 
          small 
          color="red lighten-2" 
          class="white--text f-l" 
          v-if="showPayData"
          @click="deletePayDataBtnClicked"
          >결제 데이터 삭제
        </v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
    </v-row>  
    <v-row dense align="center" v-if="showPayData">
      <v-col cols="2" >
        <div class="pt-0">
          <span>결제 방법</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          class="wid-100 pt-1" 
          v-model="pendingPayData.pay_mthd"
          :items="payMthdList"
          item-text="cmn_cd_nm"
          item-value="cmn_cd"
          hide-details
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>결제일자</span>
        </div>
      </v-col>
      <v-col cols="2">
        <v-text-field
          class="bg-white"
          v-model="pendingPayData.pay_dt"
          hide-details
          dense
          outlined
          append-icon="mdi-calendar"
          @click:append="popupDatePicker('pay_dt')"
          placeholder="0000-00-00"
        ></v-text-field>
        <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
        <!-- <datePicker v-model="pendingPayData.pay_dt"></datePicker> -->
      </v-col>
      <v-col cols="1">
        <v-text-field
          v-model="pendingPayData.pay_tm"
          :value="pendingPayData.pay_tm"
          hide-details
          class="pt-1"
          dense
          outlined
          placeholder="00:00"
        ></v-text-field>
        <!-- <timePicker v-model="pendingPayData.pay_tm"></timePicker> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>관리자 확인 여부</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="pendingPayData.admin_cnfm_yn"
          hide-details
          dense
          label="관리자 확인함"
        ></v-checkbox>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>비고</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-textarea
          v-model="pendingPayData.remk"
          class="wid-100 pt-0"
          hide-details
          outlined
          >
        </v-textarea>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
    </v-row>
  </v-container>  
</template>

<script>
import numericTextField from '@/components/common/numericTextField.vue';
// import datePicker from "@/components/common/datePicker.vue";
// import timePicker from "@/components/common/timePicker.vue";
// import dayjs from "dayjs";
import datePickerPopup from '@/components/common/datePickerPopup.vue';

export default {
  components: { numericTextField, datePickerPopup },
  data(){
    return{
      mberSno: "",

      // newYn: this.$route.query.newYn,
      prodType: this.$route.query.prodType,
      billSno: this.$route.query.billSno,
      pgId: this.$route.query.pgId,

      pay: {},
      pendingPay: {},

      showPayData: false,
      isDeletedPayData: false,

      payData: {},
      pendingPayData: {},

      prodTypeList: [],
      payMthdList: [],
      curDate: "",
      datePopup: false,
      dateNm: '',
    }
  },
  computed: {
    isOnPending() {
      return this.pay != JSON.stringify(this.pendingPay) ||
        this.payData != JSON.stringify(this.pendingPayData);
    },
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

    console.log("created prodType / billSno:", this.prodType, this.billSno);

  },
  mounted() {
    this.getProdTypeList();
    this.getPayMthdList();

    this.getPayment();
  },
  methods: {
    async getProdTypeList() {
      this.prodTypeList = await this.$getCmnCdList('prod_type');
      // console.log("prodTypeList:", this.prodTypeList);
    },
    async getPayMthdList() {
      // this.payMthdList = await this.$getCmnCdList('pay_mthd_' + this.pgId);
      this.payMthdList = await this.$getCmnCdList('pay_mthd');
      // console.log("curDivList:", this.curDivList);
    },
    gotoList() {
      // this.$router.go(-1)
      // 아래 path 설정 시 select box 에 값 설정이 안됨
      // this.$router.push({path:"preRegistList", query: { confrSno:this.confrSno }});
      this.$router.push({name:"paymentList", query: {  }});
    },
    getPayment() {
      console.log("getPayment prodType/billSno:", this.prodType, this.billSno);
      const params = new FormData();

      params.append("prod_type", this.prodType);
      params.append("bill_sno", this.billSno);

			this.$http.post("/admin/getPay", params).then((res) => {
        console.log("res.data: ", res.data);
        // 결제 정보
        const pay = res.data.result.pay;
        pay.pay_yn = pay.pay_yn == 'Y' ? true : false;    

        this.pay = JSON.stringify(pay);
        this.pendingPay = JSON.parse(JSON.stringify(pay));     

        // 결제 데이터
        if(res.data.result.payData != null) {
          const payData = res.data.result.payData;

          // 관리자 확인 여부
          payData.admin_cnfm_yn = payData.admin_cnfm_yn == 'Y' ? true : false;

          this.payData = JSON.stringify(payData);
          this.pendingPayData = JSON.parse(JSON.stringify(payData));
          this.showPayData = true;
        } else {
          this.showPayData = false;
        }

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // // 상품 유형
      // if(this.prod_type == null || this.prod_type == "") {
      //   alert("상품 유형은 필수 입력값입니다.");
      //   return false;
      // }
      // // 상품명
      // if(this.prod_nm == null || this.prod_nm == "") {
      //   alert('상품명은 필수 입력값입니다.');
      //   return false;
      // }
      // // 상품 금액
      // this.prod_amt = this.$refs.prod_amt.getValue();
      // if(this.prod_amt == null || this.prod_amt == "") {
      //   alert('상품 금액은 필수 입력값입니다.');
      //   return false;
      // }
      // if(!this.$includeHangle(this.prod_amt) || !this.$includeAlphaChar(this.prod_amt)) {
      //   alert("상품 금액은 숫자만 입력 가능합니다.");
      //   return false;
      // }
      // // 통화 구분
      // if(this.cur_div == null || this.cur_div == "") {
      //   alert('통화 구분은 필수 입력값입니다.');
      //   return false;
      // }

      return true;
    },
    saveNStayBtnClicked() {
      this.savePayment("stay");
      // console.log("out of save");
    },
    saveNMoveBtnClicked() {
      this.savePayment("move");
      // console.log("out of save");
    },
    savePayment(action) {
      // console.log("savePayment");

      if(confirm("저장하시겠습니까?")) {
        const isValid = this.validateInput();

        if(isValid) {
          const params = new FormData();

          params.append("mber_sno", this.mberSno);
          params.append("prod_type", this.prodType);
          params.append("bill_sno", this.billSno);
          params.append("pay_mber_sno", this.pendingPay.mber_sno);
          params.append("payr_nm", this.pendingPay.mber_nm);
          
          if(this.pendingPay.pay_yn != null)  params.append("pay_yn", this.pendingPay.pay_yn ? 'Y' : 'N');

          // payData 처리
          if(this.pendingPayData != null) {
            const payData = JSON.parse(JSON.stringify(this.pendingPayData));
            payData.admin_cnfm_yn = payData.admin_cnfm_yn ? 'Y' : 'N';
            params.append("payData", JSON.stringify(payData));
          }

          this.$http.post("/admin/updatePay", params).then((res) => {
            console.log(res.data);
            alert("저장되었습니다.");
            
            // console.log("in save: after alert 1");
            if(action == "stay") {
              this.pendingPayData.pay_no = res.data.pay_no || null;
              this.getPayment();
            } else if(action == "move") {
              this.$router.push({path:"paymentList"})
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      }
    },
    deletePayDataBtnClicked() {
      console.log("deletePayDataBtnClicked");

      if(this.pendingPayData.pay_no == null) {
        this.showPayData = false;
        return;
      }

      let confirmMsg = this.pendingPay.prod_nm + " 결제 내역이 완전히 삭제됩니다. 삭제하시겠습니까?";

      if(confirm(confirmMsg)) {
        const params = new FormData();

        params.append("prod_type", this.prodType);
        params.append("bill_sno", this.billSno);
        params.append("pay_no", this.pendingPayData.pay_no);

        this.$http.post("/admin/deletePay", params).then((res) => {
          console.log(res.data);

          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            // this.showPayData = false;
            this.getPayment();
          } else {
            alert("삭제에 실패했습니다.");
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    addPayDataBtnClicked() {
      this.pendingPayData.admin_cnfm_yn = false;
      this.pendingPayData.pay_no = null;
      this.pendingPayData.pay_mthd = null;
      this.pendingPayData.pay_dt = null;
      this.pendingPayData.pay_tm = null;
      this.pendingPayData.remk = "* 관리자 수동입력 건입니다.";

      this.showPayData = true;
    },
    setSelectedDate(selected) {
      
      if(this.dateNm == "pay_dt") {
        this.pendingPayData.pay_dt = selected;
      }
      
      this.datePopup = false;
    },//setSelectedDate end
    popupDatePicker(dateNm) {
      this.dateNm = dateNm;
      this.datePopup = true;
    },//popupDatePicker end
    
  },
}
</script>

<style>

</style>