<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">

          <v-col cols="2" >
            <div class="pt-0">
              <span>구글 애널리틱스 추적코드</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              class="wid-100 pt-0"
              v-model="ga_track_cd"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>네이버클라우드 Client ID</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              class="wid-100 pt-0"
              v-model="naver_clnt_id"
              hide-details
              outlined
              append-icon="mdi-magnify"
              @click:append="onSearchNaverClientIdClick"
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>네이버클라우드 Client Secret</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="2">
            <span>key-id</span>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->
        </v-row>  
      </div>
    </v-layout>
    <naver-client-id-popup v-if="naverPopup" @close="closeNaverPopup" @selected="setNaverClientId"></naver-client-id-popup>
  </v-container>
</template>

<script>
import naverClientIdPopup from '@/components/common/naverClientIdPopup.vue';
export default {
  components: { naverClientIdPopup },
  data(){
    return{
      naverPopup: false,
      mberSno: this.$session.get("mberSno") || '',

      ga_track_cd: '',
      naver_clnt_id: '',
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

  },
  mounted() {
    this.getSoctyApiCd();
  },
  methods: {
    getSoctyApiCd() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyApiCd", params).then((res) => {
        console.log("res.data: ", res.data);

        if(res.data.result) {
          this.ga_track_cd = res.data.result.ga_track_cd;
          this.naver_clnt_id = res.data.result.naver_clnt_id;
        }
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveSoctyApiCd");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        if(this.mberSno != null)        params.append("mber_sno", this.mberSno);
        if(this.ga_track_cd != null)    params.append("ga_track_cd", this.ga_track_cd);
        if(this.naver_clnt_id != null)  params.append("naver_clnt_id", this.naver_clnt_id);

        this.$http.post("/admin/upsertSoctyApiCd", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getSoctyApiCd();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    onSearchNaverClientIdClick() {
      this.naverPopup = true;
    },
    closeNaverPopup() {
      this.naverPopup = false;
    },
    setNaverClientId(naverClntId) {
      this.itemChanged();

      this.naver_clnt_id = naverClntId;
      this.naverPopup = false;
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'apiCodeTab');
    },
  }
}
</script>

<style>

</style>