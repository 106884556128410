<template>
  <v-container>
    <v-data-iterator
      :items="notiBbsList"
      hide-default-footer
      v-show="gridViewYn"
    >
      <template v-slot:header>
        <v-row
          class="mt-2"
          align="center"
          justify="center">
          <v-col cols="12" class="d-flex align-center">
            <div class="wid-150p mr-2" v-show="windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                style="font-size: 13px;"
                class="dreamFont4"
                :items="filterList"
                v-model="defaultFilter"
                @change="filterListChanged">
              </v-select>
            </div>
            <div class="wid-400p mr-2" v-show="windowWidth > 767">
              <v-text-field
                outlined
                flat
                solo
                hide-details
                prepend-inner-icon="mdi-magnify"
                style="font-size: 13px !important;"
                class="dreamFont4"
                label="Search"
                v-model="search">
              </v-text-field>
            </div>
            <div class="wid-200p mr-2" v-show="windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="sort"
                style="font-size: 13px;"
                class="dreamFont4"
                :items="sortByList"
                item-text="cmn_cd_nm"
                item-value="cmn_cd"
                v-model="sortType"
                @change="sortChanged">
              </v-select>
            </div>
            <div class="wid-100p mr-2" v-show="windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                style="font-size: 13px;"
                class="dreamFont4"
                v-model="itemsPerPage"
                :items="itemsPerPageArray"
                @change="itemsPerPageChanged">
              </v-select>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              @click="prePageBtnClicked"
              v-show="page != 1">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="mr-4 ml-4 grey--text wid-100p d-flex align-center">
              <div class="wid-60p">
                <v-text-field 
                  hide-details 
                  outlined 
                  class="wid-50p mr-1 dreamFont4" 
                  style="font-size: 13px;"
                  v-model="page"> 
                </v-text-field>
              </div>
              <div class="mr-3">/</div>
              <div class="dreamFont4" style="font-size: 13px;"> {{pageMax}} </div>
            </div>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              @click="nextPageBtnClicked"
              v-show="pageMax != page"
              hide-default-footer>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="pa-0" style="border-top: 1px solid black;"></v-col>
        </v-row>
      </template>

      <template>
        <v-row class="">
          <v-col cols="12" class="pa-0">
            <v-data-table
              :headers="page_type == 'bbs_free' ? gridFreeHeader : gridHeader"
              :items="notiBbsList"
              class="elevation-0"
              :search="search"
              :items-per-page.sync="itemsPerPage"
              :page.sync="page"
              disable-sort
              style="cursor: pointer;"
              hide-default-footer
              mobile-breakpoint="0"
              >
              <template v-slot:[`item.bbst_ttl`]="{item}" >
                <v-list-item-title
                  v-model="item.bbst_ttl"
                  text
                  class="pa-0 ma-0 d-flex float-left"
                  @click="rowClicked(item.bbst_sno)">
                  <span 
                    class="mr-2"
                    :class="windowWidth > 768 ? 'titleWord':'mobTitleWord'">{{ item.bbst_ttl }}</span>
                  <v-icon small v-if="item.atch_cnt > 0" color="blue">mdi-cloud-arrow-down-outline</v-icon>
                </v-list-item-title>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>

      <template v-slot:no-data>
        <v-row class="pa-0 pt-2">
          <v-col cols="12" >
            <v-card class="hei-300p">
              <v-icon class="nodata-icon mr-5">
                mdi-cloud-remove-outline
              </v-icon>
              <span class="font-weight-bold fontSize-3">
                데이터가 존재하지 않습니다.
              </span>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center">
          <v-col cols="12" class="border-t-grey pa-0 mt-1"></v-col>
          <v-col cols="" class="d-flex align-center">
            <div class="wid-150p mr-2">
              <v-btn
                outlined
                style="font-size: 13px;"
                class="elevation-0 bg-lightgrey font-weight-bold dreamFont3"
                @click="writeBtnClicked"
                v-if="page_type == 'bbs_free' && chg_perm_yn == 'Y'"
              >
                글쓰기
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              @click="prePageBtnClicked"
              v-show="page != 1"
            >              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="mr-4 ml-4 grey--text wid-100p d-flex align-center">
              <div class="wid-60p">
                <v-text-field 
                  hide-details 
                  outlined 
                  style="font-size: 13px;"
                  class="wid-50p mr-1 dreamFont3" 
                  v-model="page"
                > 
                </v-text-field>
              </div>
              <div class="mr-3">/</div>
              <div class="dreamFont3" style="font-size: 13px;"> {{pageMax}} </div>
            </div>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              @click="nextPageBtnClicked"
              v-show="pageMax != page"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

    </v-data-iterator>


    
    <!-- <v-row>
      <v-col cols="8" class="">
        <v-data-table
        :headers="gridHeader"
        :items="notiBbsList"
        class="elevation-0"
        :items-per-page="10"
        :footer-props="{
          itemsPerPageOptions: [10, 20, 30, 40, 50], // -> Add this example
        }"
        :search="search"
        > 
          <template slot="no-data"> 데이터가 존재하지 않습니다. </template>
          <template v-slot:[`item.bbst_ttl`]="{item}" >
            <v-text-field
              v-model="item.bbst_ttl"
              hide-details
              dense
              readonly
              solo
              flat
              class="pa-0 ma-0 text-field-transparent"
              @click="rowClicked(item.bbst_sno)"
              
              :append-icon="item.atch_cnt > 0 ? 'mdi-cloud-arrow-down-outline' : ''"
            >
            
            </v-text-field>
          </template>
        </v-data-table>
      </v-col>
    </v-row> -->

    <v-row dense class="ma-0 pa-0">
      <v-col cols="1" class="">
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      defaultFilter: '전체',
      filterList: [
        // {cmn_cd : 1, cmn_cd_nm : '전체1'}
      ],
      descFilterList: [
        {cmn_cd : 0, cmn_cd_nm : '최신등록순'},
      ],
      descFilter: {
        cmn_cd : 0, cmn_cd_nm : '최신등록순'
      },
      gridHeader: [
        { text: '번호', value: 'no', width: "10%", align: 'center', },
        { text: '제목', value: 'bbst_ttl', width: "65%", align: 'center',},
        { text: '등록일', value: 'reg_dtm', align: 'center d-none d-sm-table-cell'},
        { text: '조회', value: 'read_cnt', width: "10%", align: 'center d-none d-sm-table-cell'}
      ],
      gridFreeHeader: [
        { text: '번호', value: 'no', width: "10%", align: 'center', },
        { text: '제목', value: 'bbst_ttl', width: "49%", align: 'center',},
        { text: '등록자', value: 'reg_mber_nm', width: "20%", align: 'center d-none d-sm-table-cell' },
        { text: '등록일', value: 'reg_dtm', width: "11%", align: 'center d-none d-sm-table-cell' },
        { text: '조회', value: 'read_cnt',  width: "10%", align: 'center d-none d-sm-table-cell' }
      ],
      notiBbsList: [],
      notiBbsList_ori: [],
      search: '',
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      chg_perm_yn: 'N',
      basicUri: '',
      menu_sno: '',
      emitData: {},
      testArr: [],
      gridViewYn: false,
      itemsPerPageArray: [10, 20],
      page: 1,
      itemsPerPage: 10,
      totalLen: 0,
      pageMax: 0,
      sortByList: [],
      sortType: 'latest',
      page_type: '',
      pageNo : this.$route.query.pageNo ? this.$route.query.pageNo : '',
    }
  },
  watch: {
    page: function(val) {
      this.$router.push(this.$route.path+'?pageNo='+val).catch(()=>{});
    }
  },
  created() {
    if(this.pageNo) {
      this.page = Number(this.pageNo);
    }
    this.getSortByList();

    let path = this.$route.fullPath;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    this.selectBbsList();
    
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    rowClicked(bbstSno) {
      const moveUrl = this.basicUri+'/detail?bbst_sno='+bbstSno+'&pageNo='+this.pageNo;
      this.$router.push(moveUrl);

    },//rowClicked end
    writeBtnClicked() {
      // alert(this.basicUri);
      const moveUrl = this.basicUri+'/write?menu_sno='+this.menu_sno;
      this.$router.push(moveUrl);
    },//writeBtnClicked end
    filterListChanged() {
      if(this.defaultFilter == '전체') {
        this.notiBbsList = this.notiBbsList_ori;
      } else {
        this.notiBbsList = this.notiBbsList_ori.filter((e) => e.bbst_catg == this.defaultFilter);
      }
      
    },//filterListChanged end
    selectBbsList() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("sortType", this.sortType);
      params.append("non_mber_yn", this.nonMberYn);

      this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
        console.log(res.data);
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.$router.push("/");
        } else {
          // this.menu_sno = res.data.menu_sno;
          // if(!this.menu_sno ) {
            
          // }
          
          this.notiBbsList_ori = res.data.notiBbsList;
          let notiBbsList = res.data.notiBbsList;
          
          notiBbsList.forEach((e) => {
            if(e.bbst_catg) {
              e.bbst_ttl = '['+e.bbst_catg+'] '+e.bbst_ttl;
            }
          })

          this.notiBbsList = notiBbsList;

          this.totalLen = this.notiBbsList.length;
          this.pageMax = Math.ceil(this.totalLen / this.itemsPerPage);

          this.chg_perm_yn = res.data.chg_perm_yn;
          this.menu_sno = res.data.menu_sno;
          const menuDtl = res.data.menuDetail;
          this.page_type = menuDtl.page_type;
            if(menuDtl.bbst_catg) {
              const arrCate = menuDtl.bbst_catg.split(',');
              this.filterList[0] = '전체';
              this.filterList = [
                ...this.filterList,
                ...arrCate
              ];
            } else {
              this.filterList[0] = '전체';
              this.filterList = [
                ...this.filterList
              ];
            }

            this.emitData.menu_sno = this.menu_sno;
            this.$emit('setData', this.emitData);
          
        }
        this.gridViewYn = true;
      })
        .catch(function (error) {
          console.log(error);
        }
      );
    },//selectBbsList end
    itemsPerPageChanged() {
        if(this.totalLen < this.itemsPerPage) {
          this.pageMax = 1;
        } else {
          this.pageMax = Math.ceil(this.totalLen / this.itemsPerPage);
        }
        
      },//itemsPerPageChanged end
      nextPageBtnClicked() {
        this.page++;
        
      },//nextPageBtnClicked end
      prePageBtnClicked() {
        this.page--;
      },//prePageBtnClicked end
      async getSortByList() {
        this.sortByList = await this.$getCmnCdList("sort_by");
      },//getSortByList end
      sortChanged() {
        this.selectBbsList();
      },
  }
}
</script>
<style scoped>
  .hoverCheck:hover {
    cursor: pointer !important;
  }
  .titleWord {
    display:block; 
    overflow: hidden; 
    text-overflow: ellipsis;  
    white-space: nowrap; 
    word-break: break-all;
    max-width: 450px;
  }
  .mobTitleWord {
    display:block; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    word-break: break-all;
    max-width: 200px;
  }

  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 13px !important;
    /* font-weight: bold !important; */
    padding: 13px 6px 13px 6px;
    font-family: dreamFont4;
  }

  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 13px !important;
    padding: 13px 6px 13px 6px;
    max-width: 100% !important;
    font-family: dreamFont3 !important;
  }

  ::v-deep .v-list-item__title > .titleWord {
    font-family: dreamFont3 !important;
  }

  ::v-deep .v-list-item__content > .v-list-item__title {
    font-family: dreamFont4 !important;
  }

  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td > div > span {
    font-size: 13px !important;
  }

  ::v-deep .dreamFont3 > .v-btn__content {
    font-family: dreamFont3 !important;
  }

  label {
    font-size: 13px !important;
  }

  .dreamFont3 {
    font-family: dreamFont3 !important;
  }

  .dreamFont4 {
    font-family: dreamFont4 !important;
  }
</style>