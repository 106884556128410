<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="">
        <v-row dense align="center">
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>사전등록 건수 제한</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="pre_reg_max_cnt"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>사전등록 최대 인원수 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="pre_reg_max_mber_cnt"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>특별행사 참가자 최대 인원수 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="event_max_mber_cnt"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>사전등록 시</span>
            </div>
          </v-col>
          <!-- <v-col cols="10">
            <v-checkbox
              v-model="memo_input_yn"
              hide-details
              dense
              label="사전등록 시 메모 입력 가능"
              @change="itemChanged"
            ></v-checkbox>
          </v-col> -->
          <!-- <v-col cols="2" >
          </v-col> -->
          <!-- <v-col cols="10">
            <v-checkbox
              v-model="site_onln_sel_disp_yn"
              hide-details
              dense
              label="현장참석/온라인참석 선택 표시"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" >
          </v-col> -->
          <v-col cols="10">
            <v-checkbox
              v-model="anul_mfee_chk_yn"
              hide-details
              dense
              label="연회비 납부 검사"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>사전등록 후</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="ptcp_type_upd_psbl_yn"
              hide-details
              dense
              label="참석형태 수정 가능"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
          <v-col cols="2" >
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="list_dwld_incl_mfee_pay_yn"
              hide-details
              dense
              label="사전등록 명단 다운로드 시 회비납부여부 포함"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 수집 항목 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>수집 항목</span>
            </div>
          </v-col>
          <v-col cols="5">
            <v-data-table
              :headers="regItemHeader"
              :items="regItemList"
              class="elevation-1 wid-100"
              item-key="no"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:[`item.use_yn`]="{item}">
                <v-checkbox 
                  v-model="item.use_yn" 
                  class="ma-0 pa-0"
                  hide-details
                  solo 
                  flat
                  @change="itemChanged"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.ess_yn`]="{item}">
                <v-checkbox 
                  v-model="item.ess_yn" 
                  class="ma-0 pa-0"
                  hide-details
                  solo 
                  flat
                  @change="itemChanged"
                ></v-checkbox>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 수집 항목 끝 -->
        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
export default {
  props: ["propParams"],
  data(){
    return{
      confrSno: "",
      regDiv: 'pre',
      newYn: 'N',

      // pre_reg_max_cnt: "",
      pre_reg_max_mber_cnt: "",
      event_max_mber_cnt: "",
      // memo_input_yn: "",
      list_dwld_incl_mfee_pay_yn: "",
      // site_onln_sel_disp_yn: "",
      ptcp_type_upd_psbl_yn: "",
      anul_mfee_chk_yn: "",

      regItemHeader:[
        { text:'영역명', value:'reg_sctn_nm', width:'35%', sortable:false},
        { text:'항목명', value:'reg_item_nm', width:'35%', sortable:false},
        { text:'사용여부', value:'use_yn', width: '15%', sortable:false},
        { text:'필수여부', value:'ess_yn', width: '15%', sortable:false},
        // { text:'순서', value:'disp_odr', disp_odr: '20%', sortable:false}
      ],
      regItemList: [],
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.propParams.confrSno; 
    this.newYn = this.propParams.newYn;
    // console.log("preRegistOptionTab created. confrSno:", this.confrSno);

    this.getConfrPreRegOptn();
  },
  methods: {
    getConfrPreRegOptn() {
      const params = new FormData();

      params.append("confr_sno", this.confrSno);
      params.append("reg_div", this.regDiv);

			this.$http.post("/admin/getConfrPreRegOptn", params).then((res) => {
        // console.log("getConfrPreRegOptn res.data: ", res.data);

        this.regItemList = res.data.result.regItemList;

        if(res.data.result.regOptn == null) {
          // console.log("getConfrPreRegOptn res.data.result is null");
          this.newYn = 'Y';
          return;
        }

        const pre = res.data.result.regOptn;

        // this.pre_reg_max_cnt = pre.pre_reg_max_cnt;
        this.pre_reg_max_mber_cnt = pre.pre_reg_max_mber_cnt;
        this.event_max_mber_cnt = pre.event_max_mber_cnt;
        // this.memo_input_yn = pre.memo_input_yn == 'Y' ? true : false;
        this.list_dwld_incl_mfee_pay_yn = pre.list_dwld_incl_mfee_pay_yn == 'Y' ? true : false;
        // this.site_onln_sel_disp_yn = pre.site_onln_sel_disp_yn == 'Y' ? true : false;
        this.ptcp_type_upd_psbl_yn = pre.ptcp_type_upd_psbl_yn == 'Y' ? true : false;
        this.anul_mfee_chk_yn = pre.anul_mfee_chk_yn == 'Y' ? true : false;

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 사전등록 최대 인원수
      if(this.pre_reg_max_mber_cnt == null || this.pre_reg_max_mber_cnt == "") {
        alert("사전등록 최대 인원수는 필수 입력값입니다.");
        return false;
      }
      if(!this.$includeHangle(this.pre_reg_max_mber_cnt) || !this.$includeAlphaChar(this.pre_reg_max_mber_cnt)) {
        alert("사전등록 최대 인원수는 숫자만 입력 가능합니다.");
        return false;
      }
      // 특별행사 참가자 최대 인원수
      if(this.event_max_mber_cnt == null || this.event_max_mber_cnt == "") {
        alert('특별행사 참가자 최대 인원수는 필수 입력값입니다.');
        return false;
      }
      if(!this.$includeHangle(this.event_max_mber_cnt) || !this.$includeAlphaChar(this.event_max_mber_cnt)) {
        alert("특별행사 참가자 최대 인원수는 숫자만 입력 가능합니다.");
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      // console.log("saveConfrPreRegOptn");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append("reg_div", this.regDiv);
        params.append('new_yn', this.newYn);

        // if(this.pre_reg_max_cnt != null)              params.append("pre_reg_max_cnt", this.pre_reg_max_cnt);
        if(this.pre_reg_max_mber_cnt != null)         params.append("pre_reg_max_mber_cnt", this.pre_reg_max_mber_cnt);
        if(this.event_max_mber_cnt != null)           params.append("event_max_mber_cnt", this.event_max_mber_cnt);
        // if(this.memo_input_yn != null)                params.append("memo_input_yn", this.memo_input_yn ? 'Y' : 'N');        
        if(this.list_dwld_incl_mfee_pay_yn != null)   params.append("list_dwld_incl_mfee_pay_yn", this.list_dwld_incl_mfee_pay_yn ? 'Y' : 'N');
        // if(this.site_onln_sel_disp_yn != null)        params.append("site_onln_sel_disp_yn", this.site_onln_sel_disp_yn ? 'Y' : 'N');
        if(this.ptcp_type_upd_psbl_yn != null)        params.append("ptcp_type_upd_psbl_yn", this.ptcp_type_upd_psbl_yn ? 'Y' : 'N');
        if(this.anul_mfee_chk_yn != null)             params.append("anul_mfee_chk_yn", this.anul_mfee_chk_yn ? 'Y' : 'N');

        // 학술대회 메뉴
        const regItemList = [];

        this.regItemList.forEach((e) => {
          if(e.use_yn) {
            regItemList.push({confr_sno:this.confrSno, reg_item_id:e.reg_item_id, reg_div:this.regDiv, ess_yn:e.ess_yn ? 'Y' : 'N'});
          }
        });
        // console.log("regItemList:", regItemList);

        params.append("reg_item_list", JSON.stringify(regItemList));

        this.$http.post("/admin/upsertConfrPreRegOptn", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");

          this.getConfrPreRegOptn();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'preRegistOptionTab');
    },
  },
}
</script>

<style>

</style>