<template>
  <v-footer class="wid-100" padless>
    <v-container fluid class="minHei-80p-auto " :style="`background-color:`+point_color_4" v-show="appBarYn">
      <v-container class="" :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
        <v-row class="minHei-80p-auto mt-0 border-l-grey ma-0" align="center" v-show="windowWidth > 767">
          <v-col cols="6" class="ma-0 pa-0 minHei-80p-auto" v-show="windowWidth > 767">

            <!-- [[[[[[  web   ]]]]]] -->
            <v-row class="ma-0 hei-80p dreamFont3" v-show="windowWidth > 1220">
              <v-col cols="12" class="d-flex justify-left align-center">
                <v-btn class="" text tile @click="onTermsService" :style="'color:' + font_color_6 + `!important;`">
                  {{lang == 'en' ? 'Terms & Conditions' : '이용약관'}}
                </v-btn>
                <h2 ><v-icon class="" :style="'color:' + font_color_6 + `!important;`">mdi-power-on</v-icon></h2>
                <v-btn class="" text tile @click="onPrivacyPolicy" :style="'color:' + font_color_6 + `!important;`">
                  {{lang == 'en' ? 'Priviacy Policy' : '개인정보처리방침'}}
                </v-btn>
                <h2 ><v-icon class="" :style="'color:' + font_color_6 + `!important;`">mdi-power-on</v-icon></h2>
                <v-btn class="" :style="'color:' + font_color_6 + `!important;`" text tile @click="onEmailColctDeny">
                  {{lang == 'en' ? 'Rejection of Unauthorized E-mail Collection' : '이메일 무단 수집거부'}}
                </v-btn>
                <h2><v-icon class="" :style="'color:' + font_color_6 + `!important;`">mdi-power-on</v-icon></h2>
                <v-btn class="" :style="'color:' + font_color_6 + `!important;`" text tile :to="this.cnttUrl">
                  {{lang == 'en' ? 'Contact Us' : '찾아오시는 길'}}
                </v-btn>
              </v-col>
            </v-row>

            <!-- [[[[[[  tablet   ]]]]]] -->
            <v-row class="ma-0 pl-5" v-show="windowWidth < 1221 && windowWidth > 768">
              <v-col cols="12" class="">
                <v-row class="hei-80p-auto" align="center">
                  <v-col cols="3" class="pa-0 d-flex justify-center">
                    <v-btn class="" :style="'color:' + font_color_6 + `!important;`" text tile small @click="onTermsService">
                      {{lang == 'en' ? 'Terms & Conditions' : '이용약관'}}
                    </v-btn>
                  </v-col>
                  <span>|</span>
                  <v-col cols="4" class="pa-0 d-flex justify-center">
                    <v-btn class="" :style="'color:' + font_color_6 + `!important;`" text tile small @click="onPrivacyPolicy">
                      {{lang == 'en' ? 'Priviacy Policy' : '개인정보처리방침'}}
                    </v-btn>
                  </v-col>
                  <span>|</span>
                  <v-col cols="4" class="pa-0 d-flex justify-center">
                    <v-btn class="" :style="'color:' + font_color_6 + `!important;`" text tile small @click="onEmailColctDeny">
                      {{lang == 'en' ? 'Rejection of Unauthorized E-mail Collection' : '이메일 무단 수집거부'}}
                    </v-btn>
                  </v-col>
                  <span>|</span>
                  <v-col cols="3" class="pa-0 d-flex justify-center">
                    <v-btn class="" :style="'color:' + font_color_6 + `!important;`" text tile small :to="this.cnttUrl">
                      {{lang == 'en' ? 'Contact Us' : '찾아오시는 길'}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" class="ma-0 pa-0">
            <v-row class="hidden-md-and-down ma-0">
              <v-spacer/>
              <v-col cols="4" class="ma-0 pa-0 d-flex minHei-80p-auto border-l-grey align-center">
                <v-menu
                  tile
                  top
                  offset-y
                  content-class="elevation-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="footerMenu_1"
                      x-large block tile text class="ma-0 pa-0 dreamFont4" :style="`background-color:`+point_color_4 + `; color:` + font_color_6 + `!important;`">
                      <div class="wid-100 float-left pl-4 d-flex">
                        Family Site 
                      </div>
                      <v-icon class="mr-4" v-if="footerBtnIcon_1==true">mdi-chevron-up</v-icon>
                      <v-icon class="mr-4" v-if="footerBtnIcon_1==false">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list
                    class=""
                    :style="`background-color:`+point_color_4+`; color:` + font_color_6 + `!important;`">
                    <v-list-item
                      v-for="(item, index) in relSiteList.filter((e) => e.rel_site_type == 'etc_fmly')"
                      :key="`fmly_`+index"
                      class=""
                      link
                      @click="etcFmlyClicked(item.site_url)"
                    >
                      <!-- <a :href="item.site_url" target="_blank" style="text-decoration: none;"> -->
                        <v-list-item-title :style="`color:` + font_color_6 + `!important;`">
                            {{ item.site_nm }}
                        </v-list-item-title>
                      <!-- </a> -->
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="4" class="ma-0 pa-0 d-flex minHei-80p-auto border-l-grey border-r-grey align-center">
                <v-menu
                  tile
                  top
                  offset-y
                  content-class="elevation-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="footerMenu_2"
                      x-large block tile text class="ma-0 pa-0 dreamFont3"
                      :style="`background-color:`+point_color_4+`; color:` + font_color_6 + `!important;`">
                      <div class="wid-100 float-left pl-4 d-flex">
                        유관기관
                      </div>
                      <v-icon class="mr-4" v-if="footerBtnIcon_2==true">mdi-chevron-up</v-icon>
                      <v-icon class="mr-4" v-if="footerBtnIcon_2==false">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list
                    class=""
                    :style="`background-color:`+point_color_4+`; color:` + font_color_6 + `!important;`">
                    <v-list-item
                      v-for="(item, index) in relSiteList.filter((e) => e.rel_site_type == 'rel_org')"
                      :key="`relOrg_`+index"
                      class=""
                      link
                      @click="relOrgClicked(item.site_url)">
                      <v-list-item-title :style="`color:` + font_color_6 + `!important;`">
                        {{ item.site_nm }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <!-- [[[[[[  tablet   ]]]]]] -->
            <v-row class="ma-0" v-if="this.windowWidth < 1024 && this.windowWidth > 768" no-gutters>
              <v-col cols="6" class="pa-1 d-flex minHei-80p-auto border-l-grey align-center">
                <v-menu
                  tile
                  top
                  offset-y
                  content-class="elevation-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="footerMenu_1"
                      x-large block tile text class="ma-0 pa-0" :style="`background-color:`+point_color_4 +`; color:` + font_color_6 + `!important;`">
                      <div class="wid-100 float-left pl-4 d-flex">
                        Family Site 
                      </div>
                      <v-icon class="mr-4" v-if="footerBtnIcon_1==true">mdi-chevron-up</v-icon>
                      <v-icon class="mr-4" v-if="footerBtnIcon_1==false">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list
                    class=""
                    :style="`background-color:`+point_color_4+`; color:` + font_color_6 + `!important;`">
                    <v-list-item
                      v-for="(item, index) in relSiteList.filter((e) => e.rel_site_type == 'etc_fmly')"
                      :key="`fmly_`+index"
                      class=""
                      link>
                      <v-list-item-title><a :href="item.site_url" style="text-decoration: none;" :style="`color:` + font_color_6 + `!important;`" class="" target="_blank">{{ item.site_nm }}</a></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="6" class="ma-0 d-flex minHei-80p-auto border-l-grey border-r-grey align-center">
                <v-menu
                  tile
                  top
                  offset-y
                  content-class="elevation-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="footerMenu_2"
                      x-large block tile text class="ma-0 pa-0"
                      :style="`background-color:`+point_color_4 +`; color:` + font_color_6 + `!important;`">
                      <div class="wid-100 float-left pl-4 d-flex">
                        유관기관
                      </div>
                      <v-icon class="mr-4" v-if="footerBtnIcon_2==true">mdi-chevron-up</v-icon>
                      <v-icon class="mr-4" v-if="footerBtnIcon_2==false">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list
                    class=""
                    :style="`background-color:`+point_color_4+`; color:` + font_color_6 + `!important;`">
                    <v-list-item
                      v-for="(item, index) in relSiteList.filter((e) => e.rel_site_type == 'rel_org')"
                      :key="`relOrg_`+index"
                      class=""
                      link>
                      <v-list-item-title><a :href="item.site_url" style="text-decoration: none;" :style="`color:` + font_color_6 + `!important;`" class="" target="_blank">{{ item.site_nm }}</a></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- [[[[[[  mobile   ]]]]]] -->
        <v-row class="minHei-80p-auto mt-0 ma-0" align="center" v-show="windowWidth < 767">
          <v-col cols="12" class="border-b-grey" v-show="windowWidth < 767 && windowWidth > 350">
            <v-row class="hei-80p-auto" align="center">
              <v-col cols="3" class="pa-0 d-flex justify-center">
                <v-btn class="" text tile small @click="onTermsService" :style="`color:` + font_color_6 + `!important;`">
                  {{lang == 'en' ? 'Terms & Conditions' : '이용약관'}}
                </v-btn>
              </v-col>
              <span>|</span>
              <v-col cols="4" class="pa-0 d-flex justify-center">
                <v-btn class="" text tile small @click="onPrivacyPolicy" :style="`color:` + font_color_6 + `!important;`">
                  {{lang == 'en' ? 'Priviacy Policy' : '개인정보처리방침'}}
                </v-btn>
              </v-col>
              <span>|</span>
              <v-col cols="4" class="pa-0 d-flex justify-center">
                <v-btn class="" text tile small @click="onEmailColctDeny" :style="`color:` + font_color_6 + `!important;`">
                  {{lang == 'en' ? 'Rejection of Unauthorized E-mail Collection' : '이메일 무단 수집거부'}}
                </v-btn>
              </v-col>
              <span>|</span>
              <v-col cols="3" class="pa-0 d-flex justify-center">
                <v-btn class="" text tile small :to="this.cnttUrl" :style="`color:` + font_color_6 + `!important;`">
                  {{lang == 'en' ? 'Contact Us' : '찾아오시는 길'}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="pa-0 " v-show="windowWidth < 767 && windowWidth > 350">
            <v-row class="ma-0">
              <v-col cols="6" class="pa-1 d-flex minHei-80p-auto  align-center">
                <v-menu
                  tile
                  top
                  offset-y
                  content-class="elevation-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="footerMenu_1"
                      x-large block tile text class="ma-0 pa-0" :style="`background-color:`+point_color_4 +`; color:` + font_color_6 + `!important;`">
                      <div class="wid-100 float-left pl-4 d-flex">
                        Family Site 
                      </div>
                      <v-icon class="mr-4" v-if="footerBtnIcon_1==true">mdi-chevron-up</v-icon>
                      <v-icon class="mr-4" v-if="footerBtnIcon_1==false">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list
                    class=""
                    :style="`background-color:`+point_color_4+`; color:` + font_color_6 + `!important;`">
                    <v-list-item
                      v-for="(item, index) in relSiteList.filter((e) => e.rel_site_type == 'etc_fmly')"
                      :key="`fmly_`+index"
                      class=""
                      link>
                      <v-list-item-title><a :href="item.site_url" style="text-decoration: none;" :style="`color:` + font_color_6 + `!important;`" class="" target="_blank">{{ item.site_nm }}</a></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="6" class="ma-0 d-flex minHei-80p-auto border-l-grey align-center">
                <v-menu
                  tile
                  top
                  offset-y
                  content-class="elevation-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="footerMenu_2"
                      x-large block tile text class=" ma-0 pa-0"
                      :style="`background-color:`+point_color_4 +`; color:` + font_color_6 + `!important;`">
                      <div class="wid-100 float-left pl-4 d-flex">
                        유관기관
                      </div>
                      <v-icon class="mr-4" v-if="footerBtnIcon_2==true">mdi-chevron-up</v-icon>
                      <v-icon class="mr-4" v-if="footerBtnIcon_2==false">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list
                    class=""
                    :style="`background-color:`+point_color_4+`; color:` + font_color_6 + `!important;`">
                    <v-list-item
                      v-for="(item, index) in relSiteList.filter((e) => e.rel_site_type == 'rel_org')"
                      :key="`relOrg_`+index"
                      class=""
                      link>
                      <v-list-item-title><a :href="item.site_url" style="text-decoration: none;" :style="`color:` + font_color_6 + `!important;`" class="" target="_blank">{{ item.site_nm }}</a></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container fluid class="hei-180p-auto bg-botgrey">
      <v-container class="pt-5 hei-180p-auto bg-botgrey" :style="windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
        <v-row class="pt-5 pl-3 pr-3 ma-0" v-show="windowWidth > 768">
          <v-col cols="2" class="d-flex pa-0 justify-center align-center mr-4" v-show="footerImg">
            <div class="hei-80p wid-100 d-flex justify-center" >
              <img :src="footerImg.btm_logo_path" class="wid-100 imgDf-h">
            </div>
          </v-col>
          <v-col cols="8" class="d-flex pa-0 align-center dreamFont3" v-if="footerData" >
            <div class="tiny" v-html="footerData.footr_cn"></div>
          </v-col>    
        </v-row>
        <v-row class="pl-3 pr-3 ma-0" v-show="windowWidth < 767 && windowWidth > 350">
          <v-col cols="12" class="pa-0 pb-4" v-show="footerImg">
            <v-row class="ma-0">
              <v-col cols="4" class="d-flex pa-0 justify-center align-center mr-4">
                <div class="hei-80p wid-100 d-flex justify-center" >
                  <img :src="footerImg.btm_logo_path" class="wid-100 imgDf-h">
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="d-flex pa-0 align-center pb-4" v-if="footerData" >
            <div class="tiny" v-html="footerData.footr_cn"></div>
          </v-col>    
        </v-row>
      </v-container>
    </v-container>
    
  </v-footer>
</template>

<script>
export default {
  data(){
    return{
      windowWidth: window.innerWidth,
      items: [
        {
          title: '제목',
          subtitle: '내용',
        },
        {
          title: '제목',
          subtitle: '내용',
        },
        {
          title: '제목',
          subtitle: '내용',
        },
        {
          title: '제목',
          subtitle: '내용',
        },
        {
          title: '제목',
          subtitle: '내용',
        },
      ],
      footerBtnIcon_1:true,
      footerBtnIcon_2:true,
      point_color_4: '#2a325f',
	  font_color_6: '',					   
      footerData: {},
      footerImg: {},
      appBarYn: false,
      relSiteList: [],
      cnttUrl: '',
      lang: this.$session.get("lang") ? this.$session.get("lang") : '',
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    this.getSoctyFootr();
    this.getFooterImg();
    this.getPoinColor();
    this.getRelSite();
    this.getMenuList();
  },
  methods:{
    footerMenu_1(){
      this.footerBtnIcon_1 = !this.footerBtnIcon_1;
    },
    footerMenu_2(){
      this.footerBtnIcon_2 = !this.footerBtnIcon_2;
    },
    getSoctyFootr() {
      this.$http.get("/admin/getSoctyFootr").then((res) => {
        // console.log(res.data.result);
        this.footerData = res.data.result[0];
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getSoctyFootr end
    getFooterImg() {
      this.$http.get("/admin/getSoctyImg").then((res) => {
        if(res.data.result[0]) {
          this.footerImg = res.data.result[0];
        }
        
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getFooterImg end
    getPoinColor() {
      this.$http.get("/common/getMainPointColor").then((res) => {
        const mainPointColor = res.data.mainPointColor;
        if(mainPointColor) {
          this.point_color_4 = mainPointColor.point_color_4;
		  this.font_color_6 = mainPointColor.font_color_6;												  
        }
        this.appBarYn = true;
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getPoinColor end
    getRelSite() {
      this.$http.get("/admin/getRelSite").then((res) => {
        this.relSiteList = res.data.relSiteList;

      }).catch(
        function (error) {
        console.log(error);
        }
      )
    },
    getMenuList() {
      
      const param = new FormData();
      param.append('lang',this.lang);
      this.$http.post("/admin/getMenuList",param).then((res) => {    
      
        this.menuList = res.data.menuList;
        // console.log(this.menuList);
        this.menuList.some((e) => {
          if(e.page_type == 'cntt_us') {
            // console.log("sss :: ",e);
            this.cnttUrl = '/contactus'+e.url_path
            return true;
          }

        });
        
      }).catch((error) => {
          console.log(error);
        }
      )
    },//getMenuList end

    onTermsService(){
      const routeData = this.$router.resolve({name: 'termsService'});
      window.open(routeData.href, "_blank"); 
    },
    onPrivacyPolicy(){
      const routeData = this.$router.resolve({name: 'privacyPolicy'});
      window.open(routeData.href, "_blank"); 
    },
    onEmailColctDeny(){
      const routeData = this.$router.resolve({name: 'emailColctDeny'});
      window.open(routeData.href, "_blank"); 
    },
    etcFmlyClicked(url) {
      window.open(url)
    },//etcFmlyClicked end
    relOrgClicked(url) {
      window.open(url)
    },//relOrgClicked end
  }
}
</script>

<style scoped>
.v-application p{
  margin-bottom: 0px !important;
}

.dreamFont3 {
  font-family: dreamFont3 !important;
}

.dreamFont4 {
  font-family: dreamFont4 !important;
}

.v-list-item__title {
  font-family: dreamFont3 !important;
}
</style>