<template>
	<v-container fluid class="pa-3">
		<v-row  class="" align="center">
			<v-col cols="5">
				<div class="f-l pt-1">
					<h3>회원 정보 영역</h3>
				</div>
				<div>
					<v-btn 
						class="ml-2 f-r" 
						small 
						color="blue lighten-1 white--text" 
						@click="saveMberSctnBtnClicked">
						저장 후 유지
					</v-btn>
				</div>
			</v-col>
			<v-col cols="7" v-show="showSub">
				<div class="f-l pt-1">
					<h3>회원 정보 항목 > {{subSectionName}}</h3>
				</div>
				<div>
					<v-btn 
						small
						class="ml-2 f-r"
						color="blue lighten-1 white--text" 
						@click="saveMberItemBtnClicked">
						저장 후 유지
					</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-row class="">
			<v-col cols="5">
				<v-data-table
					:headers="mainHeader"
					:items="mberSctn"
					class="elevation-1 wid-100"
					item-key="no"
          @click:row="mainRowClicked"
					hide-default-footer
					style="cursor: pointer">
					<!-- <template v-slot:[`item.mber_sctn_id`]="{item}">
						<v-text-field
							v-model="item.mber_sctn_id"
							hide-details
							outlined
							dense>
						</v-text-field>
					</template> -->
					<!-- <template v-slot:[`item.mst_mber_sctn_nm`]="{item}">
						<v-text-field
							v-model="item.mst_mber_sctn_nm"
							hide-details
							outlined
							dense>
						</v-text-field>
					</template> -->
          <template v-slot:[`item.mber_sctn_nm`]="{item}">
						<v-text-field
							v-model="item.mber_sctn_nm"
							hide-details
							outlined
							dense
              :readonly="!item.use_yn"
              :background-color="!item.use_yn ? 'grey lighten-2' : ''"
            >
						</v-text-field>
					</template>
					<template v-slot:[`item.use_yn`]="{item}">
						<v-checkbox 
							v-model="item.use_yn" 
							class="ma-0 pa-0"
							hide-details
							solo 
							flat>
						</v-checkbox>
					</template>
				</v-data-table>
			</v-col>
			<v-col cols="7" v-show="showSub">
				<v-data-table
					:headers="subHeader"
					:items="mberItem"
					item-key="no"
					class="elevation-1 wid-100 ma-0 pa-0"
					:page.sync="page"
					:items-per-page="itemsPerPage"
					:key="redrawCount"
					hide-default-footer>	
					<template v-slot:[`item.mber_item_id`]="{item}">
						<v-text-field
							v-model="item.mber_item_id"
							class="ma-0 pa-0"
							hide-details
							outlined
							dense>
						</v-text-field>
					</template>
					<template v-slot:[`item.mber_item_nm`]="{item}">
						<v-text-field
							v-model="item.mber_item_nm"
							class="ma-0 pa-0"
							hide-details
							outlined
							dense
              :readonly="!item.use_yn"
              :background-color="!item.use_yn ? 'grey lighten-2' : ''"
            >
						</v-text-field>
					</template>
					<!-- <template v-slot:[`item.data_type`]="{item}">
						<v-select 
							label=""
							v-model="item.data_type"
							:items="dataTypeList"
							item-text="cmn_cd"
							item-value="cmn_cd"
						>
						</v-select>
					</template> -->
					<template v-slot:[`item.use_yn`]="{item}">
						<v-checkbox 
							v-model="item.use_yn" 
							@click="subUseClicked(item.use_yn,item)"
							class="ma-0 pa-0"
							hide-details
							solo 
							flat>
						</v-checkbox>
					</template>
					<template v-slot:[`item.join_colct_yn`]="{item}">
						<v-checkbox 
							v-model="item.join_colct_yn" 
							class="ma-0 pa-0"
							hide-details
							solo 
							flat>
						</v-checkbox>
					</template>
					<template v-slot:[`item.ess_yn`]="{item}">
						<v-checkbox 
							v-model="item.ess_yn" 
							class="ma-0 pa-0"
							hide-details
							solo 
							flat>
						</v-checkbox>
					</template>
				</v-data-table>
			</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	data() {
		return {
      mberSno: "",
			redrawCount : 0,
			singleSelect: false,
			pageCount:0,
			page:1,
			itemsPerPage : -1,

      mberSctnLength : 0,
			mberSctn: [],
			mainSectionIdDblClicked : "",

			showSub : false,
			subSectionName:"",
			
      mberItemLength : 0,
			mberItem: [],

			dataTypeList : [],

			mainHeader: [
				// { text: '관리 영역ID', value: 'mber_sctn_id', width: "45%" },
				{ text: '관리 영역명', value: 'mst_mber_sctn_nm', width: "45%" },
        { text: '관리 영역 표시명', value: 'mber_sctn_nm', width: "45%" },
				{ text: '사용', value:'use_yn', width: "10%" },
				// { text: '가입', value:'join_colct_yn', width: "10%" },
			],
			subHeader: [
				// { text: '관리 항목ID', value: 'mber_item_id', width: "35%" },
				{ text: '관리 항목명', value: 'mst_mber_item_nm', width: "35%" },
        { text: '관리 항목 표시명', value: 'mber_item_nm', width: "35%" },
				// { text: '데이터타입', value: 'data_type', width: "20%" },
				{ text: '사용', value: 'use_yn', width: "10%" },
				{ text: '가입', value:'join_colct_yn', width: "10%" },
				{ text: '필수', value: 'ess_yn', width: "10%" },
			],
			rule: [
				v => /^[A-Za-z0-9]*$/.test(v) || '한글입력 불가',
			],
		};
	},
  created() {
    this.mberSno = this.$session.get('mberSno');

		this.getMstMberSctnList();
    // this.getDataTypeList();
  },
  mounted() {

  },
  methods: {
    async getDataTypeList() {
      this.dataTypeList = await this.$getCmnCdList('data_type');
    },
		getMstMberItemList() {
			this.mberItem = [];
			
			const formData = new FormData();
			formData.append("mber_sctn_id", this.mainSectionIdDblClicked);

			this.$http.post("/admin/getMstMberItemList", formData).then((res) => {
				this.mberItem = res.data.result;

				this.redrawCount++;
			}).catch(function (error) {
					console.log(error);
				}.bind(this)
			);
		},
		getMstMberSctnList() {
			this.$http.get("/admin/getMstMberSctnList").then((res) => { // 실제 API를 요청한다/    
        this.mberSctn = res.data.result;
				console.log("this.mberSctn",this.mberSctn[0]);
				if(this.mberSctn) {
					this.onloadAction(this.mberSctn[0]);
				}

			}).catch(function (error) {
					console.log(error);
				}.bind(this)
			);
		},
		onloadAction(item) {
			this.mberItem = [];
      
      this.mainSectionIdDblClicked = item.mber_sctn_id;

      const mber_sctn_id = item.mber_sctn_id;
      const formData = new FormData();
      formData.append("mber_sctn_id", mber_sctn_id);

      this.$http.post("/admin/getMstMberItemList", formData).then((res) => {
        this.mberItem = res.data.result;

        this.mberItem.forEach((element) => {
          if(!element.ess_yn)	{
            element.ess_yn = false;
          }
        });
        this.subSectionName = item.mber_sctn_nm;
        this.redrawCount++;
        this.showSub = true;

        console.log("this.mberItem",this.mberItem);
      })
      .catch(function (error) {
          console.log(error);
        }.bind(this)
      );
		},
    mainRowClicked(event, { item }) {			
      this.mberItem = [];
      
      this.mainSectionIdDblClicked = item.mber_sctn_id;

      const mber_sctn_id = item.mber_sctn_id;
      const formData = new FormData();
      formData.append("mber_sctn_id", mber_sctn_id);

      this.$http.post("/admin/getMstMberItemList", formData).then((res) => {
        this.mberItem = res.data.result;

        this.mberItem.forEach((element) => {
          if(!element.ess_yn)	{
            element.ess_yn = false;
          }
        });
        this.subSectionName = item.mber_sctn_nm;
        this.redrawCount++;
        this.showSub = true;

        console.log("this.mberItem",this.mberItem);
      })
      .catch(function (error) {
          console.log(error);
        }.bind(this)
      );
		},
		saveMberSctnBtnClicked() {
			if(this.mberSctn.length < 1)	{
				alert("저장할 항목이 없습니다.");
				return;
			}

			const mainSctnIdNullCount = this.mberSctn.filter(e=> e.mber_sctn_id == "").length;
			const mainSctnNmNullCount = this.mberSctn.filter(e=> e.mber_sctn_nm == "").length;

			if(mainSctnIdNullCount > 0) {
				alert('관리 영역ID 입력해주세요.');
				return;
			} 
			else if(mainSctnNmNullCount > 0) {
				alert('관리 영역명 입력해주세요.');
				return;
			}

      let hangleYn = false;
      this.mberSctn.forEach((ele) => {
        const mber_sctn_id = ele.mber_sctn_id;
        if(!this.$includeHangle(mber_sctn_id)){
          hangleYn = true;
        }
      });

      if(hangleYn) {
        alert("관리 영역ID에 한글 입력 불가");
        return;
      }

      let useYnChk = false;
      // this.mainContent.filter((e) => {
      // 	if(e.join_colct_yn && !e.use_yn){
      // 		alert("가입 체크시 사용여부가 체크되어있어야 합니다.");
      // 		useYnChk = true;
      // 	}
      // });

      if(useYnChk) {
        return;
      }

      //진행
      let params = new URLSearchParams();
      params.append("mber_sno", this.mberSno);
      params.append("mainCon", JSON.stringify(this.mberSctn));

      this.$http.post("/admin/insertSoctyMberSctn", params).then((res) => {
        console.log(res.data);
        this.mberSctn = [];
        this.getMstMberSctnList();
      })
      .catch(function (error) {
          console.log(error);
        }
      );

		},
		saveMberItemBtnClicked() {
			if(this.mberItem.length < 1) {
				alert("저장할 항목이 없습니다.");
				return;
			}

			const subSctnIdNullCount = this.mberItem.filter(e=> e.mber_item_id == "").length;
			const subSctnNmNullCount = this.mberItem.filter(e=> e.mber_item_nm == "").length;
				// const subDataTypeNullCount = this.subContent.filter(e=> e.data_type == "").length;

			if(subSctnIdNullCount > 0) {
						alert('관리 항목ID 입력해주세요.');
						return;
			}
			else if(subSctnNmNullCount > 0) {
						alert('관리 항목명 입력해주세요.');
						return;
			}
			//   else if(subDataTypeNullCount > 0) {
			// 			alert('데이터 타입 선택해주세요.');
			// 			return;
			// 		}

      let hangleYn = false;
      this.mberItem.forEach((ele) => {
        const mber_item_id = ele.mber_item_id;
        if(!this.$includeHangle(mber_item_id)){
          hangleYn = true;
        }
      });

      if(hangleYn) {
        alert("관리 영역ID에 한글 입력 불가");
        return;
      }
              
      let useYnChk = false;
      this.mberItem.filter((e) => {
        if(e.ess_yn && !e.use_yn) {
          alert("필수 체크시 사용여부가 체크되어있어야 합니다.");
          useYnChk = true;
        }
        else if(e.join_colct_yn && !e.use_yn) {
          alert("가입 체크시 사용여부가 체크되어있어야 합니다.");
          useYnChk = true;
        }
      });

      //필수 체크시 사용여부 확인
      if(useYnChk) {
        return;
      }
              
      //진행
      let params = new URLSearchParams();
      params.append("mber_sno", this.mberSno);
      params.append("mber_sctn_id", this.mainSectionIdDblClicked);
      params.append("subCon", JSON.stringify(this.mberItem));

      this.$http.post("/admin/upsertSoctyMberItem", params).then((res) => {
        console.log(res.data);
        this.getMstMberItemList();
      }).catch(function (error) {
          console.log(error);
        }.bind(this)
      );
		},

		subUseClicked(val,item) {
			if(!val) {
				item.join_colct_yn = false;
			}
		}
  },
}
</script>


<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 13px;
  padding: 2px 16px 0px 16px;
	/* font-weight: bolder; */
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > .text-start {
  font-size: 13px;
	padding: 2px 16px 0px 16px;
  max-width: 100% !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td > .v-input {
  font-size: 13px;
  max-width: 100% !important;
}

</style>
