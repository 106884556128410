<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="">
        <v-row dense align="center">
          <!-- 단체명(한글) -->
          <v-col cols="12" v-if="mberItemSet.grp_nm.use_yn && (newYn == 'N' || mberItemSet.grp_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>단체명(한글)</span><span v-if="mberItemSet.grp_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="grp_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 단체명(영문) -->
          <v-col cols="12" v-if="mberItemSet.grp_nm_en.use_yn && (newYn == 'N' || mberItemSet.grp_nm_en.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>단체명(영문)</span><span v-if="mberItemSet.grp_nm_en.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="grp_nm_en"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 대표자 이름 -->
          <v-col cols="12" v-if="mberItemSet.repr_nm.use_yn && (newYn == 'N' || mberItemSet.repr_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>대표자 이름</span><span v-if="mberItemSet.repr_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="repr_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 소재지 -->
          <v-col cols="12" v-if="mberItemSet.ploc_cd.use_yn && (newYn == 'N' || mberItemSet.ploc_cd.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>소재지</span><span v-if="mberItemSet.ploc_cd.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="plocCdList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="ploc_cd"
                  hide-details
                  dense
                  outlined
                  @change="itemChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 법인 번호 -->
          <v-col cols="12" v-if="mberItemSet.corp_rno.use_yn && (newYn == 'N' || mberItemSet.corp_rno.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>법인 번호</span><span v-if="mberItemSet.corp_rno.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="corp_rno"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 사업자 번호 -->
          <v-col cols="12" v-if="mberItemSet.biz_rno.use_yn && (newYn == 'N' || mberItemSet.biz_rno.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>사업자 번호</span><span v-if="mberItemSet.biz_rno.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="biz_rno"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 홈페이지 -->
          <v-col cols="12" v-if="mberItemSet.homepage.use_yn && (newYn == 'N' || mberItemSet.homepage.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>홈페이지</span><span v-if="mberItemSet.homepage.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="homepage"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 우편번호 -->
          <v-col cols="12" v-if="mberItemSet.zpcd.use_yn && (newYn == 'N' || mberItemSet.zpcd.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>우편번호</span><span v-if="mberItemSet.zpcd.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100 pt-0"
                  hide-details
                  dense
                  outlined
                  v-model="zpcd"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <div class="wid-100">
                  <v-btn
                    small
                    color="blue lighten-2"
                    class="white--text"
                    @click="findZipcodeBtnClicked()"
                  >주소 찾기</v-btn>
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 주소 -->
          <v-col cols="12" v-if="mberItemSet.addr.use_yn && (newYn == 'N' || mberItemSet.addr.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>주소</span><span v-if="mberItemSet.addr.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="addr"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 상세 주소 -->
          <v-col cols="12" v-if="mberItemSet.addr_dtl.use_yn && (newYn == 'N' || mberItemSet.addr_dtl.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>상세 주소</span><span v-if="mberItemSet.addr_dtl.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="addr_dtl"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 참고 항목 -->
          <v-col cols="12" v-if="mberItemSet.addr_ref.use_yn && (newYn == 'N' || mberItemSet.addr_ref.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>참고 항목</span><span v-if="mberItemSet.addr_ref.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="addr_ref"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 우편물 수령 여부 -->
          <v-col cols="12" v-if="mberItemSet.recv_postm_yn.use_yn && (newYn == 'N' || mberItemSet.recv_postm_yn.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <span>우편물 수신</span><span v-if="mberItemSet.recv_postm_yn.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-checkbox 
                  hide-details
                  dense
                  v-model="recv_postm_yn"
                  label="우편물 수신 여부"
                  @change="itemChanged"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>

          <!-- 담당자 이름(한글) -->
          <v-col cols="12" v-if="mberItemSet.chrgr_nm.use_yn && (newYn == 'N' || mberItemSet.chrgr_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>담당자 이름(한글)</span><span v-if="mberItemSet.chrgr_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="chrgr_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 담당자 영문이름(First) -->
          <v-col cols="12" v-if="mberItemSet.chrgr_nm_en_frst.use_yn && (newYn == 'N' || mberItemSet.chrgr_nm_en_frst.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>담당자 영문이름(First)</span><span v-if="mberItemSet.chrgr_nm_en_frst.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="chrgr_nm_en_frst"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 담당자 영문이름(Last) -->
          <v-col cols="12" v-if="mberItemSet.chrgr_nm_en_last.use_yn && (newYn == 'N' || mberItemSet.chrgr_nm_en_last.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>담당자 영문이름(Last)</span><span v-if="mberItemSet.chrgr_nm_en_last.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="chrgr_nm_en_last"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 담당자 부서 -->
          <v-col cols="12" v-if="mberItemSet.chrgr_dept_nm.use_yn && (newYn == 'N' || mberItemSet.chrgr_dept_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>담당자 부서</span><span v-if="mberItemSet.chrgr_dept_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="chrgr_dept_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 담당자 직위 -->
          <v-col cols="12" v-if="mberItemSet.chrgr_pstn_nm.use_yn && (newYn == 'N' || mberItemSet.chrgr_pstn_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>담당자 직위</span><span v-if="mberItemSet.chrgr_pstn_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="chrgr_pstn_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 담당자 전자우편주소 -->
          <v-col cols="12" v-if="mberItemSet.chrgr_email.use_yn && (newYn == 'N' || mberItemSet.chrgr_email.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>담당자 전자우편주소</span><span v-if="mberItemSet.chrgr_email.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="chrgr_email"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 담당자 전화번호 -->
          <v-col cols="12" v-if="mberItemSet.chrgr_tel_no.use_yn && (newYn == 'N' || mberItemSet.chrgr_tel_no.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>담당자 전화번호</span><span v-if="mberItemSet.chrgr_tel_no.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="chrgr_tel_no"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 담당자 팩스번호 -->
          <v-col cols="12" v-if="mberItemSet.chrgr_fax_no.use_yn && (newYn == 'N' || mberItemSet.chrgr_fax_no.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>담당자 팩스번호</span><span v-if="mberItemSet.chrgr_fax_no.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="chrgr_fax_no"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>

        </v-row>
      </div>
    </v-layout>
  </v-container>

</template>

<script>
export default {
  props: ["propParams"],
  data(){
    return {
      mberItemSet: {
        grp_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        grp_nm_en:{use_yn:true, join_colct_yn:true, ess_yn:false},
        repr_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        ploc_cd:{use_yn:true, join_colct_yn:true, ess_yn:false},
        corp_rno:{use_yn:true, join_colct_yn:true, ess_yn:false},
        biz_rno:{use_yn:true, join_colct_yn:true, ess_yn:false},
        homepage:{use_yn:true, join_colct_yn:true, ess_yn:false},
        zpcd:{use_yn:true, join_colct_yn:true, ess_yn:false},
        addr:{use_yn:true, join_colct_yn:true, ess_yn:false},
        addr_dtl:{use_yn:true, join_colct_yn:true, ess_yn:false},
        addr_ref:{use_yn:true, join_colct_yn:true, ess_yn:false},
        recv_postm_yn:{use_yn:true, join_colct_yn:true, ess_yn:false},

        chrgr_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        chrgr_nm_en_frst:{use_yn:true, join_colct_yn:true, ess_yn:false},
        chrgr_nm_en_last:{use_yn:true, join_colct_yn:true, ess_yn:false},
        chrgr_dept_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        chrgr_pstn_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        chrgr_email:{use_yn:true, join_colct_yn:true, ess_yn:false},        
        chrgr_tel_no:{use_yn:true, join_colct_yn:true, ess_yn:false},
        chrgr_fax_no:{use_yn:true, join_colct_yn:true, ess_yn:false},
      },

      mberSctnId: 'mber_grp',

      grp_nm: null,
      grp_nm_en: null,
      repr_nm: null,
      ploc_cd: null,
      corp_rno: null,
      biz_rno: null,
      homepage: null,
      zpcd: null,
      addr: null,
      addr_dtl: null,
      addr_ref: null,
      recv_postm_yn: false,

      chrgr_nm : null,
      chrgr_nm_en_frst : null,
      chrgr_nm_en_last : null,
      chrgr_dept_nm : null,
      chrgr_pstn_nm : null,
      chrgr_email : null,
      chrgr_tel_no : null,
      chrgr_fax_no : null,


      plocCdList: [],

      mberSno: '',
      // redrawCount: 0,

      newYn: 'N',
      loginMberSno: "",   // 로그인 멤버
    }
  },
  created() {
    this.loginMberSno = this.$session.get("mberSno"); // 로그인 멤버
    this.mberSno = this.propParams.mberSno;
    this.newYn = this.propParams.newYn;
    // console.log("mberSno:", this.mberSno);

    this.getPlocCdList();

    this.getMberGrp();
  },
  methods: {
    async getPlocCdList() {
      this.plocCdList = await this.$getCmnCdList("ploc_cd");
    },
    getMberGrp() {
      const formData = new FormData();

      formData.append("mber_sctn_id", this.mberSctnId);
      formData.append("mber_sno", this.mberSno);

      this.$http.post("/admin/getMberGrp", formData).then((res) => {
        console.log("res.data.result:", res.data.result);

        const mberItemList = res.data.result.mberItemList;

        // console.log("mberItemList:", result.mberItemList);

        let mberItemSet = {};
        mberItemList.forEach((e) => {
          // console.log(e);
          mberItemSet[e.mber_item_id] = {use_yn:e.use_yn == 'Y' ? true : false, join_colct_yn:e.join_colct_yn == 'Y' ? true : false, ess_yn:e.ess_yn == 'Y' ? true : false};
        });
        // console.log("mberItemSet:", mberItemSet);
        // console.log("mberItemSet.login_id.ess_yn:", mberItemSet.login_id.ess_yn);
        this.mberItemSet = mberItemSet;

        if(res.data.result.mberGrp == null) {
          this.newYn = 'Y';
          return;
        }

        const mber = res.data.result.mberGrp;
        console.log("mber:", mber);

        this.grp_nm = mber.grp_nm;
        this.grp_nm_en = mber.grp_nm_en;
        this.repr_nm = mber.repr_nm;
        this.ploc_cd = mber.ploc_cd;
        this.corp_rno = mber.corp_rno;
        this.biz_rno = mber.biz_rno;
        this.homepage = mber.homepage;
        this.zpcd = mber.zpcd;
        this.addr = mber.addr;
        this.addr_dtl = mber.addr_dtl;
        this.addr_ref = mber.addr_ref;
        this.recv_postm_yn = mber.recv_postm_yn == 'Y' ? true : false;

        this.chrgr_nm = mber.chrgr_nm;
        this.chrgr_nm_en_frst = mber.chrgr_nm_en_frst;
        this.chrgr_nm_en_last = mber.chrgr_nm_en_last;
        this.chrgr_dept_nm = mber.chrgr_dept_nm;
        this.chrgr_pstn_nm = mber.chrgr_pstn_nm;
        this.chrgr_email = mber.chrgr_email;
        this.chrgr_tel_no = mber.chrgr_tel_no;      
        this.chrgr_fax_no = mber.chrgr_fax_no;

        // this.redrawCount++;
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
      
    },
    validateInput() {
      // 단체명(한글)
      if(this.mberItemSet.grp_nm.ess_yn && (this.grp_nm == null || this.grp_nm =="")) {
        alert("단체명(한글)은 필수 입력값입니다.");
        return false;
      }
      // 단체명(영문)
      if(this.mberItemSet.grp_nm_en.ess_yn && (this.grp_nm_en == null || this.grp_nm_en =="")) {
        alert("단체명(영문)은 필수 입력값입니다.");
        return false;
      }
      // 대표자 이름
      if(this.mberItemSet.repr_nm.ess_yn && (this.repr_nm == null || this.repr_nm =="")) {
        alert("대표자 이름은 필수 입력값입니다.");
        return false;
      }
      // 소재지
      if(this.mberItemSet.ploc_cd.ess_yn && (this.ploc_cd == null || this.ploc_cd =="")) {
        alert("소재지는 필수 입력값입니다.");
        return false;
      }
      // 법인 번호
      if(this.mberItemSet.corp_rno.ess_yn && (this.corp_rno == null || this.corp_rno =="")) {
        alert("법인 번호는 필수 입력값입니다.");
        return false;
      }
      // 사업자 번호
      if(this.mberItemSet.biz_rno.ess_yn && (this.biz_rno == null || this.biz_rno =="")) {
        alert("사업자 번호는 필수 입력값입니다.");
        return false;
      }
      // 홈페이지
      if(this.mberItemSet.homepage.ess_yn && (this.homepage == null || this.homepage =="")) {
        alert("홈페이지는 필수 입력값입니다.");
        return false;
      }
      // 우편번호
      if(this.mberItemSet.zpcd.ess_yn && (this.zpcd == null || this.zpcd =="")) {
        alert("우편번호는 필수 입력값입니다.");
        return false;
      }
      // 주소
      if(this.mberItemSet.addr.ess_yn && (this.addr == null || this.addr =="")) {
        alert("주소는 필수 입력값입니다.");
        return false;
      }
      // 상세 주소
      if(this.mberItemSet.addr_dtl.ess_yn && (this.addr_dtl == null || this.addr_dtl =="")) {
        alert("상세 주소는 필수 입력값입니다.");
        return false;
      }
      // 참고 항목
      if(this.mberItemSet.addr_ref.ess_yn && (this.addr_ref == null || this.addr_ref =="")) {
        alert("참고 항목은 필수 입력값입니다.");
        return false;
      }
      
      // 우편물 수령 여부
      if(this.mberItemSet.recv_postm_yn.ess_yn && !this.recv_postm_yn) {
        alert("우편물 수령 여부는 필수 입력값입니다.");
        return false;
      }

      // 담당자 이름(한글)
      if(this.mberItemSet.chrgr_nm.ess_yn && (this.chrgr_nm == null || this.chrgr_nm =="")) {
        alert("담당자 이름(한글)은 필수 입력값입니다.");
        return false;
      }
      // 담당자 영문이름(First)
      if(this.mberItemSet.chrgr_nm_en_frst.ess_yn && (this.chrgr_nm_en_frst == null || this.chrgr_nm_en_frst =="")) {
        alert("담당자 영문이름(First)은 필수 입력값입니다.");
        return false;
      }
      // 담당자 영문이름(Last)
      if(this.mberItemSet.chrgr_nm_en_last.ess_yn && (this.chrgr_nm_en_last == null || this.chrgr_nm_en_last =="")) {
        alert("담당자 영문이름(Last)은 필수 입력값입니다.");
        return false;
      }
      // 담당자 부서
      if(this.mberItemSet.chrgr_dept_nm.ess_yn && (this.chrgr_dept_nm == null || this.chrgr_dept_nm =="")) {
        alert("담당자 부서는 필수 입력값입니다.");
        return false;
      }
      // 담당자 직위
      if(this.mberItemSet.chrgr_pstn_nm.ess_yn && (this.chrgr_pstn_nm == null || this.chrgr_pstn_nm =="")) {
        alert("담당자 직위는 필수 입력값입니다.");
        return false;
      }
      // 담당자 이메일
      if(this.mberItemSet.chrgr_email.ess_yn && (this.chrgr_email == null || this.chrgr_email =="")) {
        alert("담당자 이메일은 필수 입력값입니다.");
        return false;
      }
      if((this.chrgr_email != null && this.chrgr_email != "") && !this.$validateEmail(this.chrgr_email)) {
        alert('이메일 형식이 아닙니다.');
        return false;
      }
      
      // 담당자 전화번호
      if(this.mberItemSet.chrgr_tel_no.ess_yn && (this.chrgr_tel_no == null || this.chrgr_tel_no =="")) {
        alert("담당자 전화번호는 필수 입력값입니다.");
        return false;
      }
      // 담당자 팩스번호
      if(this.mberItemSet.chrgr_fax_no.ess_yn && (this.chrgr_fax_no == null || this.chrgr_fax_no =="")) {
        alert("담당자 팩스번호는 필수 입력값입니다.");
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      console.log("memberGroup > saveBtnClicked");
      
      const params = new FormData();
      params.append("mber_sctn_id", this.mberSctnId);
      params.append("mber_sno", this.mberSno);
      params.append('new_yn', this.newYn);
      params.append("login_mber_sno", this.loginMberSno);

      if(this.grp_nm != null )            params.append("grp_nm", this.grp_nm);
      if(this.grp_nm_en != null )         params.append("grp_nm_en", this.grp_nm_en);
      if(this.repr_nm != null )           params.append("repr_nm", this.repr_nm);
      if(this.ploc_cd != null )           params.append("ploc_cd", this.ploc_cd);
      if(this.corp_rno != null )          params.append("corp_rno", this.corp_rno);
      if(this.biz_rno != null )           params.append("biz_rno", this.biz_rno);
      if(this.homepage != null )          params.append("homepage", this.homepage);
      if(this.zpcd != null )              params.append("zpcd", this.zpcd);
      if(this.addr != null )              params.append("addr", this.addr);
      if(this.addr_dtl != null )          params.append("addr_dtl", this.addr_dtl);
      if(this.addr_ref != null )          params.append("addr_ref", this.addr_ref);
      if(this.recv_postm_yn != null )     params.append("recv_postm_yn", this.recv_postm_yn == true ? 'Y' : 'N');

      if(this.chrgr_nm != null )          params.append("chrgr_nm", this.chrgr_nm);
      if(this.chrgr_nm_en_frst != null )  params.append("chrgr_nm_en_frst", this.chrgr_nm_en_frst);
      if(this.chrgr_nm_en_last != null )  params.append("chrgr_nm_en_last", this.chrgr_nm_en_last);
      if(this.chrgr_dept_nm != null )     params.append("chrgr_dept_nm", this.chrgr_dept_nm);
      if(this.chrgr_pstn_nm != null )     params.append("chrgr_pstn_nm", this.chrgr_pstn_nm);
      if(this.chrgr_email != null )       params.append("chrgr_email", this.chrgr_email);
      if(this.chrgr_tel_no != null )      params.append("chrgr_tel_no", this.chrgr_tel_no);      
      if(this.chrgr_fax_no != null )      params.append("chrgr_fax_no", this.chrgr_fax_no);      
                
      this.$http.post("/admin/upsertMberGrp", params).then((res) => {
        if(res.data.code == 0) {
          // alert("저장되었습니다.");
          this.getMberGrp();
        } else {
          alert("실패");
        }
      }).catch(function(error) {
          console.log(error);
      });//axios End

    },//saveBtnClicked End
    findZipcodeBtnClicked() {
      new window.daum.Postcode({
				oncomplete: (data) => {
          this.zpcd = data.zonecode;
          this.addr = data.roadAddress;
				},
			}).open();

      this.itemChanged();
    },
    itemChanged() {
      this.$emit('tabChanged', 'memberGroupTab');
    }
  }
}
</script>

<style>
</style>