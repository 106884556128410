<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12">

            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div>
              <v-data-table
                :headers="headers"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.prod_sno`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.prod_sno"
                    :items="confrProdList"
                    item-text="prod_nm"
                    item-value="prod_sno"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.use_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.use_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                    @change="gridItemChanged(item)"
                  ></v-checkbox>
                </template>
                
              </v-data-table>
            </div>
          </v-col>
        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
export default {
  props: ["propParams"],
  data(){
    return{
      mberSno: "",
      confrSno: "",

      newYn: 'N',             // 조회된 값이 있으면 false
      
      headers:[
        { text: '선택', value:'select_yn', width:"10%"},
        { text: '참가 상품', value:'prod_sno', width:"70%"},
        { text: '활성화', value:'use_yn', width:"20%"},
      ],
      gridList: [],
      changedList: [],

      confrProdList: [],
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.propParams.confrSno; 

    this.getSoctyConfrProdList();
    this.getConfrPreRegProdList();
  },
  methods: {
    getSoctyConfrProdList() {
      const params = new FormData();

      params.append("confr_sno", this.confrSno);

			this.$http.post("/admin/getSoctyConfrProdList", params).then((res) => {
        console.log("res.data.sult: ", res.data.result);
        this.confrProdList = res.data.result.confrProdList;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    // 상품 수정됨
    gridItemChanged(item) {
      console.log("gridItemChanged : item.prod_sno : " + item.prod_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.prod_sno == null || item.prod_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.prod_sno == item.prod_sno) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }

      this.itemChanged();
    },
    getConfrPreRegProdList() {
      this.gridList = [];

      const params = new FormData();
      params.append("confr_sno", this.confrSno);

			this.$http.post("/admin/getConfrPreRegProdList", params).then((res) => {
        console.log("res.data.sult: ", res.data.result);
        const prodList = res.data.result.preRegProdList;

        if(prodList.length == 0) {
          this.newYn = 'Y';
          // alert("조회된 정보가 없습니다.");
          return;
        } else {
          this.newYn = 'N';
        }

        prodList.forEach((e) => {
          e.use_yn = e.use_yn == 'Y' ? true : false;
        });

        this.gridList = prodList;
        this.changedList = [];

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    deleteBtnClicked() {
      console.log("deletePreRegProd");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      deleteList.forEach((e) => {
        console.log("deleteList: prod_sno[" + e.prod_sno + "]");
      });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteConfrPreRegProdList", params).then((res) => {
            console.log(res.data);
            // this.getConfrPreRegProdList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getConfrPreRegProdList();
          this.gridList = this.gridList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;

      this.gridList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.gridList.push({new_yn:true, select_yn:false, confr_sno:this.confrSno, prod_sno:"", odr:maxOdr, use_yn:true});
      this.changedList.push(this.gridList[this.gridList.length - 1]);
    },
    saveBtnClicked() {
      console.log("saveConfrPreRegProd");

      if(this.changedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      // true/false -> 'Y'/'N' 변환
      this.changedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
        e.use_yn = e.use_yn == true ? 'Y' : 'N';
      });

      // if(confirm("저장하시겠습니까?")) {
        const params = new URLSearchParams();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append('new_yn', this.newYn);

        params.append("changedList", JSON.stringify(this.changedList));

        this.$http.post("/admin/upsertConfrPreRegProdList", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getConfrPreRegProdList();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'preRegistProductTab');
    },
  },
}
</script>

<style>

</style>