<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <!-- [[[[[ start ]]]]] -->
          <v-col cols="12" class="">
            <h2>임원증명서</h2>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>임원종류</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model="crtfMap.crtf_exctv_kind"
              @change="itemChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model="crtfMap.crtf_exctv_ref_text"
              @change="itemChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- [[[[[ end ]]]]] -->

          <!-- [[[[[ start ]]]]] -->
          <v-col cols="12" class="mt-5">
            <h2>편집위원</h2>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model="crtfMap.crtf_edtr_ref_text"
              @change="itemChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- [[[[[ end ]]]]] -->

          <!-- [[[[[ start ]]]]] -->
          <v-col cols="12" class="mt-5">
            <h2>편집위원장</h2>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model="crtfMap.crtf_chief_edtr_ref_text"
              @change="itemChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- [[[[[ end ]]]]] -->

          <!-- [[[[[ start ]]]]] -->
          <v-col cols="12" class="mt-5">
            <h2>분과 편집위원장</h2>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined
              v-model="crtfMap.crtf_dept_chief_edtr_ref_text"
              @change="itemChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- [[[[[ end ]]]]] -->
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      crtfMap: {},
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
    }
  },
  created() {
    this.$http.get("/admin/getCrtfSett").then((res) => {
      console.log("res.data: ", res.data);

      this.crtfMap = res.data.crtfMap;

    }).catch(function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    itemChanged() {
      this.$emit('tabChanged', 'certificationLetterTab');
    },
    saveBtnClicked() {
      console.log('certi..letter save');

      const params = new FormData();
      params.append('mber_sno', this.mberSno);
      params.append('crtfMap', JSON.stringify(this.crtfMap));

      this.$http.post("/admin/upsertCrtfSett",params).then((res) => {
        console.log("res.data: ", res.data);

      }).catch(function (error) {
          console.log(error);
        }
      );

    },//saveBtnClicked end
  }
}
</script>

<style scoped>
.v-input--selection-controls{
  padding-top: 0px !important;
}
.v-input--checkbox.v-input--dense{
  margin-top: 0px !important;
}

/deep/.v-label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>