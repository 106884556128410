<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center">
          <v-col cols="3">
            <!-- <v-text-field 
              label="검색어" 
              class="f-l pa-0 ma-0 wid-100" 
              v-model="searchWord" 
              solo 
              hide-details>
            </v-text-field> -->
          </v-col>
          <v-col cols="1">
            <!-- <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text f-r" 
              @click="searchBtnClicked">
              검색
            </v-btn> -->
          </v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addProdBtnClicked"
              >상품 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1"
              style="cursor: pointer"
            >
              <!-- <template v-slot:[`item.select_yn`]="{ item }">
                <v-simple-checkbox
                  v-model="item.select_yn"
                  hide-details>
                </v-simple-checkbox>
              </template> -->
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>

              <template v-slot:[`item.prod_nm`]="{ item }" >
                <div @click="prodRowClicked(item)">{{item.prod_nm }}</div>
              </template>

              <template v-slot:[`item.front_prod_nm`]="{ item }" >
                <div @click="prodRowClicked(item)">{{item.front_prod_nm }}</div>
              </template>

              <template v-slot:[`item.prod_amt`]="{ item }" >
                <div @click="prodRowClicked(item)">{{item.prod_amt }}</div>
              </template>

              <template v-slot:[`item.cur_div_nm`]="{ item }" >
                <div @click="prodRowClicked(item)">{{item.cur_div_nm }}</div>
              </template>

              <template v-slot:[`item.prod_type_nm`]="{ item }" >
                <div @click="prodRowClicked(item)">{{item.prod_type_nm }}</div>
              </template>

              <template v-slot:[`item.mber_kind_nm`]="{ item }" >
                <div @click="prodRowClicked(item)">{{item.mber_kind_nm }}</div>
              </template>

              <template v-slot:[`item.use_yn`]="{ item }" >
                <div @click="prodRowClicked(item)">{{item.use_yn }}</div>
              </template>

              <template v-slot:[`item.reg_dt`]="{ item }" >
                <div @click="prodRowClicked(item)">{{item.reg_dt }}</div>
              </template>

              <!-- <template v-slot:[`item.prod_nm`]="{item}">
                <v-text-field
                  v-model="item.prod_nm"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="prodRowClicked(item)"
                ></v-text-field>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.productList.radioUseYn"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>활성으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.productList.radioMberKind"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>회원 종류로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in mberKindList"
                  :key="index"
                  :label="item.mber_kind_nm"
                  :value="item.mber_kind"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.productList.radioProdType"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>상품 유형으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in prodTypeList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      mberChkDialog: false,
      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%", sortable:false },
        { text: '상품명', value: 'prod_nm', width: "25%" },
        { text: '프론트 상품명', value: 'front_prod_nm', width: "20%" },
        { text: '상품금액', value: 'prod_amt', width: "7%" },
        { text: '통화구분', value: 'cur_div_nm', width: "7%" },
        { text: '상품유형', value: 'prod_type_nm', width: "15%" },
        { text: '회원종류', value: 'mber_kind_nm', width: "7%" },
        { text: '활성화', value: 'use_yn', width: "6%" },
        { text: '등록일자', value: 'reg_dt', width: "9%" },
      ],
      gridList: [],
      prodList: [],
      
      prodTypeList: [],
      mberKindList: [],
    }
  },
  created() {
    this.getProdTypeList();
    this.getMberKindList();
    this.getSoctyProdList();
  },
  mounted(){
    
  },
  methods: {
    async getProdTypeList() {
      this.prodTypeList = await this.$getCmnCdList("prod_type");
    },
    getMberKindList() {
      this.$http.get("/admin/getSoctyMberKindList").then((res) => {        
        this.mberKindList = res.data.result.mberKindList;
      }).catch((error) => {
          console.log(error);
        }
      )
    },
    getSoctyProdList() {
      this.$http.get("/admin/getSoctyProdList").then((res) => {
        // console.log("res.data.result:", res.data.result);

        res.data.result.prodList.forEach((e) => {
          e.select_yn = false;
        });

        // this.gridList = res.data.result.prodList;
        this.prodList = res.data.result.prodList;
        this.filterList();
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    prodRowClicked(event) {
      // console.log("prodRowClicked", event);
      this.$router.push({name:"productDetail", query: { prodSno:event.prod_sno, newYn:'N' }});
    },
    // 상품 추가 버튼 클릭
    addProdBtnClicked() {
      this.$router.push({name:"productDetail", query: { prodSno:null, newYn:'Y' }});
    },
    deleteBtnClicked() {
      console.log("deleteProductList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: prod_sno[" + e.prod_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteSoctyProdList", params).then((res) => {
            console.log(res.data);

            if(res.data.code == 0) {
              alert("삭제되었습니다.");
              // this.getSoctyProdList();
              this.gridList = this.gridList.filter((e) => {
                return !(e.select_yn);
              });
            } else if(res.data.code == -9003) {
              let inUseList = [];
              res.data.inUseList.forEach((e) => {
                inUseList.push(e.prod_nm);
              });
              alert("삭제할 수 없습니다. 상품이 사용 중입니다.\n" + inUseList.join());
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    radioFilterChanged() {
      this.filterList();
    },
    filterList() {
      this.gridList = this.prodList.filter((e) => {
        // console.log("e:", e);
        if( (this.$store.state.productList.radioUseYn == "모두" || e.use_yn == this.$store.state.productList.radioUseYn) &&
            (this.$store.state.productList.radioMberKind == "모두" || e.mber_kind == this.$store.state.productList.radioMberKind) &&
            (this.$store.state.productList.radioProdType == "모두" || e.prod_type == this.$store.state.productList.radioProdType) 
          ) return true;
      });
    }
  }
}
</script>
  
<style>

</style>