export default{
  install(Vue){
    Vue.prototype.$erroCdMsg = function(code){
      let msg = "";
      switch (code) 
      {
        case -9001:
          // alert('중복');
          msg = "중복된 아이디 입니다.";
          break;
        case -9002:
          msg = "현재비밀번호가 일치하지 않습니다.";
          break;
        case -9005:
          msg = "잘못된 파라미터 입니다.";
          break;
        case -9006:
          msg = "존재하지 않는 페이지입니다.";
          break;
        case -9007:
          msg = "권한이 없는 페이지입니다.";
          break;
        default:
          break;
      }
      return msg;
    }
    Vue.prototype.$getCmnCdList = async function(classCode) {
      const formData = new FormData();
      formData.append("cls_cd", classCode);

      try {
        const res = await this.$http.post("/common/getCmnCdListByClsCd", formData);
        const filtered = res.data.result.filter(e => e.cmn_cd != '00');
        return filtered;
      } catch (error) {
        console.log(error);
      }
      return [];
    }
    Vue.prototype.$getSoctyCmnCdList = async function(classCode) {
      const formData = new FormData();
      formData.append("cls_cd", classCode);

      try {
        const res = await this.$http.post("/common/getSoctyCmnCdListByClsCd", formData);
        const filtered = res.data.result.filter(e => e.cmn_cd != '00');
        return filtered;
      } catch (error) {
        console.log(error);
      }
      return [];
    }
    Vue.prototype.$includeHangle = function(value) {
			const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
			if (regex.test(value) === true) {
        return false;
      }
			return true;
		}

		Vue.prototype.$includeAlphaChar = function(value) {
			const regex = /[a-zA-Z]/;
			if (regex.test(value) === true) {
        return false;
      }
			return true;
		}

		Vue.prototype.$validateId = function(value) {
			const regex = /^[A-Za-z0-9]*$/;
			if (regex.test(value) === true) {
        return false;
      }
			return true;
		}

    Vue.prototype.$validateNum = function(value) {
			const regex = /^[0-9]*$/;
			if (regex.test(value) === true) {
        return false;
      }
			return true;
		}

		Vue.prototype.$validateEmail = function(value) {
			const reg_email = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

			if(!reg_email.test(value)) {      
				return false;         
			}
			return true;         
		}

    Vue.prototype.$setDateCalendarFormat = function(str) {
      return str.substring(0, 4) + "-" + str.substring(4, 6) + "-" + str.substring(6);
    }

    Vue.prototype.$phoneFomatter = function(num,type) {
      let formatNum = '';
      if(num.length==11){
        if(type==0){
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
        }else{
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
      }else if(num.length==8){
          formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
      }else{
        if(num.indexOf('02')==0){
          if(type==0){
            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
          }else{
            if(num.length == 9) {
              formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
            } else if(num.length == 10) {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
            }
            
          }
        }else{
          if(type==0){
            formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
          }else{
            formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          }
        }
      }
      return formatNum;
    }

    Vue.prototype.$getNumberOnly = function(str) {
      var reg = /[^0-9]/gi;
      if(reg.test(str)){
        return str.replace(reg, "");    
      } else {
        return str;
      } 
    }

    Vue.prototype.$setComma = function(value) {
      // console.log("setComma called.");
      return String(value)
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    Vue.prototype.$getDecodeHtml = function(value) {
      if(value !== undefined && value !== null && value !== '') {
        
        value = value.replaceAll("&gt;", ">");
        value = value.replaceAll("&lt;", "<");
      }
      return value;
    }

    Vue.prototype.$pad = function(number, length) {
      var str = '' + number;
      while (str.length < length) {
        str = '0' + str;
      }
      return str;
    }

    Vue.prototype.$setYYYYMMDDHHMM = function() {
      var nowDate = new Date();
      var yyyy = nowDate.getFullYear().toString();
      var MM = this.$pad(nowDate.getMonth() + 1,2);
      var dd = this.$pad(nowDate.getDate(), 2);
      var hh = this.$pad(nowDate.getHours(), 2);
      var mm = this.$pad(nowDate.getMinutes(), 2)

      let fullDate = yyyy + '-' + MM + '-' + dd;
      let time = hh + ':' + mm;
      // this.pendingPost.reg_dt = fullDate;
      // this.pendingPost.reg_tm = time;

      let pendingPost = { reg_dt : fullDate , reg_tm: time };
      return pendingPost;
    }
    
  }
}
