<template>
  <v-container>
    <!--[[[[[[[[[[[[[[[        날짜 선택 모달        ]]]]]]]]]]]]]]]-->
    <v-dialog
      v-model="showDialog"
      max-width="290px"
      class="pa-5"
      @keydown.esc="$emit('close')"
      @click:outside="$emit('close')"
    >
      <v-card class="mt-4">
        <v-date-picker v-model="selectedDate" no-title scrollable @dblclick:date="setSelectedDate">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$emit('close')">Cancel</v-btn>
          <v-btn text color="primary" @click="setSelectedDate(selectedDate)">OK</v-btn>
        </v-date-picker>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ["p_curDate"],
  data() {
    return {
      showDialog: true,
      selectedDate: this.p_curDate,
      dateCal: false,
    };
  },
  created() {
    // console.log(this.selectedDate);
  },
  methods: {
    setSelectedDate(date) {
      this.$emit('selected', date);
    }
  },
};
</script>