<template>
  <v-container fluid>
    <v-data-iterator
      :items="gllyBbsList"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      hide-default-footer
      no-data-text
      v-show="gridViewYn"
    >
    <!-- hide-default-footer -->
      <template v-slot:header>
        <v-row
          class="mt-2"
          align="center"
          justify="center">
          <v-col cols="" class="d-flex align-center">
            <div class="wid-150p mr-2" v-show="windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                class="dreamFont4"
                label="전체">
              </v-select>
            </div>
            <div class="wid-400p mr-2" v-show="windowWidth > 767">
              <v-text-field
                outlined
                flat
                solo
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                class="dreamFont4"
                v-model="search"
              ></v-text-field>
            </div>
            <div class="wid-200p mr-2" v-show="windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                label="sort"
                style="font-size: 13px;"
                class="dreamFont4"
                :items="sortByList"
                item-text="cmn_cd_nm"
                item-value="cmn_cd"
                v-model="sortType">
              </v-select>
            </div>
            <div class="wid-100p mr-2" v-show="windowWidth > 767">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                style="font-size: 13px;"
                class="dreamFont4"
                v-model="itemsPerPage"
                :items="itemsPerPageArray"
                @change="itemsPerPageChanged"
              >
              </v-select>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              @click="prePageBtnClicked"
              v-show="page != 1"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="mr-4 ml-4 grey--text wid-100p d-flex align-center">
              <div class="wid-60p">
                <v-text-field 
                  hide-details 
                  outlined 
                  class="wid-50p mr-1 dreamFont4" 
                  style="font-size: 13px;"
                  v-model="page" 
                  oninput="javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-z]/g, 1 );"
                > 
                </v-text-field>
              </div>
              <div class="mr-3">/</div>
              <div class="dreamFont4" style="font-size: 13px;"> {{pageMax}} </div>
            </div>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              @click="nextPageBtnClicked"
              v-show="pageMax != page"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="pt-0" style="border-top: 1px solid black;"></v-col>
        </v-row>
      </template>

      <template v-slot:default="props">
        <v-row class="border">
          <v-col
            v-for="(item,idx) in props.items"
            :key="`bbs_`+idx"
            cols="6"
            sm="4"
            md="3"
            class="">
            <v-card class="mt-2 mb-4" style="width: 200px;" flat>
              <div class="d-flex justify-center align-center mb-2" @click="moveDtlPage(item.bbst_sno)" style="cursor: pointer; width: 205px; height: 155px;">
                <div class="d-flex justify-center align-center" style="width: 200px; height: 155px; border: 1px solid rgb(200, 200, 200);">
                  <!-- <img class="imgDf" :src="item.file_url ? item.file_url : `http://localhost:8087/DEV/ATCH/common/noImg.png`"> -->
                  <img 
                    class="imgDf" 
                    style="max-width: 100%; max-height: 100%; display: flex; justify-content: center;"
                    :src="item.file_url ? item.file_url : `/DEV/ATCH/common/noImg.png`">
                </div>
              </div>

              <div class="wid-100 hei-100 d-flex justify-center align-center">
                <div class="hei-100 overflow-hidden ma-2">
                  <span class="" style="font-weight: bold;">
                    {{ item.bbst_ttl }}
                  </span>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
      
      <template v-slot:no-data>
        <v-row class="pa-0 pt-2">
          <v-col cols="12" >
            <v-card class="hei-300p">
              <v-icon class="nodata-icon mr-5">
                mdi-cloud-remove-outline
              </v-icon>
              <span class="font-weight-bold fontSize-3">
                데이터가 존재하지 않습니다.
              </span>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <!-- <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center">
          <v-col cols="12" class="border-t-darkgrey pb-0 mt-2"></v-col>
          <v-col cols="" class="d-flex align-center">
            <div class="wid-150p mr-2">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                label="전체">
              </v-select>
            </div>
            <div class="wid-400p mr-2">
              <v-text-field
                outlined
                flat
                solo
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                v-model="search"
              ></v-text-field>
            </div>
            <div class="wid-200p mr-2">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                label="sort"
                :items="sortByList"
                item-text="cmn_cd_nm"
                item-value="cmn_cd"
                v-model="sortType"
              >
              </v-select>
            </div>
            <div class="wid-100p mr-2">
              <v-select
                outlined
                flat
                solo
                dense
                hide-details
                v-model="itemsPerPage"
                :items="itemsPerPageArray"
                @change="itemsPerPageChanged"
              >
              </v-select>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              @click="prePageBtnClicked"
              v-show="page != 1"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="mr-4 ml-4 grey--text wid-100p d-flex align-center">
              <div class="wid-60p">
                <v-text-field 
                  hide-details 
                  outlined 
                  class="wid-50p mr-1" 
                  v-model="page" 
                  oninput="javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-z]/g, 1 );"
                > 
                </v-text-field>
              </div>
              <div class="mr-3">/</div>
              <div class=""> {{pageMax}} </div>
            </div>
            <v-btn
              small
              outlined
              class="ml-1 hei-30p"
              @click="nextPageBtnClicked"
              v-show="pageMax != page"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template> -->
    </v-data-iterator>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        windowWidth: window.innerWidth,
        mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
        nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
        basicUri: this.$route.fullPath.substring(this.$route.fullPath.lastIndexOf('/'), -1),
        menu_sno: '',
        emitData: {},
        gllyBbsList: [],
        itemsPerPageArray: [12, 24],
        page: 1,
        itemsPerPage: 12,
        search: '',
        totalLen: 0,
        pageMax: 0,
        gridViewYn: false,
        sortByList: [],
        sortType: 'latest',
        pageNo : this.$route.query.pageNo ? this.$route.query.pageNo : '',
      }
    },
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    },
    created() {
      
      this.getSortByList();
      this.selectGllyBbsList();
    },
    watch: {
      page: function(val) {
        // alert(val);
        this.$router.push(this.$route.path+'?pageNo='+val).catch(()=>{});
      }
    },
    computed: {
    },
    methods: {
      selectGllyBbsList() {
        const params = new FormData();
        params.append("mber_sno", this.mberSno);
        params.append("non_mber_yn", this.nonMberYn);

        this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
          if(res.data.code < 0) {
            alert(this.$erroCdMsg(res.data.code));
            this.$router.push("/");
          } else {
            console.log(res.data);
            this.gllyBbsList = res.data.gllyBbsList;
            this.totalLen = this.gllyBbsList.length;
            this.pageMax = Math.ceil(this.totalLen / this.itemsPerPage);
            
            // this.notiBbsList_ori = res.data.notiBbsList;
            // this.notiBbsList = res.data.notiBbsList;
            // this.notiBbsList.forEach((e) => {
            //   if(e.bbst_catg) {
            //     e.bbst_ttl = '['+e.bbst_catg+'] '+e.bbst_ttl;
            //   }
            // })

            // this.chg_perm_yn = res.data.chg_perm_yn;
            this.menu_sno = res.data.menu_sno;
            
            // const menuDtl = res.data.menuDetail;
            //   if(menuDtl.bbst_catg) {
            //     const arrCate = menuDtl.bbst_catg.split(',');
            //     this.filterList[0] = '전체';
            //     this.filterList = [
            //       ...this.filterList,
            //       ...arrCate
            //     ];
            //   } else {
            //     this.filterList[0] = '전체';
            //     this.filterList = [
            //       ...this.filterList
            //     ];
            //   }
            if(this.pageNo) {
              this.page = Number(this.pageNo);
            }
              this.emitData.menu_sno = this.menu_sno;
              this.$emit('setData', this.emitData);
              this.gridViewYn = true;
          }
          
        })
          .catch(function (error) {
            console.log(error);
          }
        );
      },//selectGllyBbsList end
      itemsPerPageChanged() {
        if(this.totalLen < this.itemsPerPage) {
          this.pageMax = 1;
        } else {
          this.pageMax = Math.ceil(this.totalLen / this.itemsPerPage);
        }
        
      },//itemsPerPageChanged end
      nextPageBtnClicked() {
        this.page++;
        
      },//nextPageBtnClicked end
      prePageBtnClicked() {
        this.page--;
      },//prePageBtnClicked end
      async getSortByList() {
        this.sortByList = await this.$getCmnCdList("sort_by");
      },//getSortByList end
      moveDtlPage(bbstSno) {
        const moveUrl = this.basicUri+'/detail?bbst_sno='+bbstSno+'&pageNo='+this.pageNo;
        this.$router.push(moveUrl);
      },//moveDtlPage end
    },
    
  }
</script>

<style scoped>

::v-deep .v-list-item__content > .v-list-item__title {
  font-family: dreamFont3 !important;
}

label {
  font-size: 13px !important;
}

span {
  font-family: dreamFont4 !important;
}

.dreamFont3 {
  font-family: dreamFont3 !important;
}

.dreamFont4 {
  font-family: dreamFont4 !important;
}

</style>