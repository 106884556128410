<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="gotoList"
          >목록</v-btn>
        </div>
        <div>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNAddBtnClicked"
            >저장 후 추가
          </v-btn>
          <v-btn 
            small 
            color="red lighten-2" 
            class="white--text f-r" 
            @click="deleteBtnClicked"
            v-if="newYn == 'N'"
            >수록기사 삭제
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense align="center">
      <v-col cols="2" ><div class="pt-0"><span>게시 여부</span></div></v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="pendingArtcl.disp_tf"
          hide-details
          dense
          label="게시함"
        ></v-checkbox>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>메뉴명</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="menuNm"
          class="wid-100 pt-0"
          hide-details
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>도서명 *</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.book_ttl"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>종류</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.artcl_kind"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>형태</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.artcl_type"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>분야</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.artcl_field"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>언어</span></div></v-col>
      <v-col cols="3">
        <!-- <v-text-field
          v-model="pendingArtcl.lang"
          class="wid-100 pt-0"
          hide-details
          outlined
          disabled
        ></v-text-field> -->
        <v-select
          class="pa-0 ma-0"
          v-model="pendingArtcl.lang"
          :items="langList"
          item-text="cmn_cd_nm"
          item-value="cmn_cd"
          hide-details
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>ISSN</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.issn"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>발행사</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.publcr"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>권호 *</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.vol"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>발행일 *</span></div></v-col>
      <v-col cols="2">
        <div class="wid-70">
          <v-text-field
            class=""
            v-model="pendingArtcl.publc_dt"
            hide-details
            dense
            outlined
            append-icon="mdi-calendar"
            @click:append="popupDatePicker('publc_dt')"
            placeholder="0000-00-00"
          ></v-text-field>
          <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
          <!-- <datePicker v-model="pendingArtcl.publc_dt"></datePicker> -->
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>시작페이지 *</span></div></v-col>
      <v-col cols="1">
        <numeric-text-field v-model="pendingArtcl.bgn_page"></numeric-text-field>
        <!-- <v-text-field
          v-model="pendingArtcl.bgn_page"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field> -->
      </v-col>
      <v-col cols="7" ><div class="pt-0"><span>* 시작페이지는 숫자만 입력 가능합니다.</span></div></v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>종료페이지 *</span></div></v-col>
      <v-col cols="1">
        <numeric-text-field v-model="pendingArtcl.end_page"></numeric-text-field>
        <!-- <v-text-field
          v-model="pendingArtcl.end_page"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field> -->
      </v-col>
      <v-col cols="7" ><div class="pt-0"><span>* 종료페이지는 숫자만 입력 가능합니다.</span></div></v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>논문명</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.artcl_ttl"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>논문명(영어)</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.artcl_ttl_en"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>저자</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.athor"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="7" ><div class="pt-0"><span>* 여러 건을 입력하는 경우 세미콜론( ; )으로 구분합니다.</span></div></v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>저자 소속</span></div></v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingArtcl.athor_blng"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="7" ><div class="pt-0"><span>* 여러 건을 입력하는 경우 세미콜론( ; )으로 구분합니다.</span></div></v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="2" ><div class=""><span>파일 첨부 *</span></div></v-col>
      <v-col cols="2">
        <div class="wid-100 pt-0">
          <v-file-input
            hide-details
            dense
            v-model="pendingArtcl.newAtchFile"
          ></v-file-input>
        </div>
      </v-col>
      <!-- 파일 표시 text-field -->
      <v-col cols="3" v-if="pendingArtcl.orgnl_file_nm">
        <div class="wid-100 pt-0">
          <div class="f-l pt-2 pl-5 font-1" style="color:white;">
            <span><a :href="pendingArtcl.file_path" :download="pendingArtcl.orgnl_file_nm">{{pendingArtcl.orgnl_file_nm}}</a></span>
          </div>
          <v-btn
            small
            color="red lighten-2"
            class="pa-0 ma-0 white--text f-r"
            hide-details
            @click="deleteFileBtnClicked(pendingArtcl.file_path, pendingArtcl.artcl_atch_sno)"
          >삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <!-- 개요 -->
      <v-col cols="2" ><div class="pt-0"><span>개요</span></div></v-col>
      <v-col cols="8">
        <!-- <tiny-editor v-model="pendingArtcl.artcl_outl"></tiny-editor> -->
        <v-textarea
          v-model="pendingArtcl.artcl_outl"
          class="wid-100 pt-0 pr-0"
          rows="10"
          hide-details
          outlined>
        </v-textarea>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>개요(영문)</span></div></v-col>
      <v-col cols="8">
        <!-- <tiny-editor v-model="pendingArtcl.artcl_outl_en"></tiny-editor> -->
        <v-textarea
          v-model="pendingArtcl.artcl_outl_en"
          class="wid-100 pt-0 pr-0"
          rows="10"
          hide-details
          outlined>
        </v-textarea>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>키워드</span></div></v-col>
      <v-col cols="8">
        <v-textarea
          v-model="pendingArtcl.artcl_kword"
          class="wid-100 pt-0 pr-0"
          rows="10"
          hide-details
          outlined>
        </v-textarea>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" ><div class="pt-0"><span>키워드(영문)</span></div></v-col>
      <v-col cols="8">
        <v-textarea
          v-model="pendingArtcl.artcl_kword_en"
          class="wid-100 pt-0 pr-0"
          rows="10"
          hide-details
          outlined>
        </v-textarea>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="2" ><div class="pt-0"><span>열람 횟수</span></div></v-col>
      <v-col cols="1">
        <numeric-text-field v-model="pendingArtcl.read_cnt" :readonly="true"></numeric-text-field>
      </v-col>

    </v-row>  
  </v-container>  
</template>

<script>
// import tinyEditor from '@/components/common/tinyEditor.vue';
import numericTextField from '@/components/common/numericTextField.vue';
// import datePicker from "@/components/common/datePicker.vue";
import datePickerPopup from '@/components/common/datePickerPopup.vue';

const genEmptyArtcl = (menuSno = null) => ({
  artcl_sno: null,
  menu_sno: menuSno,
  book_ttl: "",
  artcl_kind: "",
  artcl_type: "",
  artcl_field: "",
  lang: "",
  issn: "",
  publcr: "",
  vol: "",
  publc_dt: "",
  bgn_page: "",
  end_page: "",
  artcl_ttl: "",
  artcl_ttl_en: "",
  athor: "",
  athor_blng: "",
  newAtchFile: null,
  artcl_atch_sno: null,
  orgnl_file_nm: "",
  file_path: "",
  artcl_outl: "",
  artcl_outl_en: "",
  artcl_kword: "",
  artcl_kword_en: "",
  disp_tf: false,
  disp_yn: "",
  read_cnt: 0,  
});

export default {
  components: { /*tinyEditor,*/ numericTextField, datePickerPopup },
  data(){
    return{
      mberSno: null,

      menuSno: this.$route.query.menuSno,
      menuNm: this.$route.query.menuNm,
      artclSno: this.$route.query.artclSno,
      newYn: this.$route.query.newYn,
      langList: [],
      
      artcl: {},
      pendingArtcl: {},
      datePopup: false,
      curDate: "",
    }
  },
  computed: {
    isOnPending() {
      console.log("isOnPending");
      return JSON.stringify(this.artcl) != JSON.stringify(this.pendingArtcl)
        || JSON.stringify(this.fileList) != JSON.stringify(this.pendingFileList)
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    console.log("artclDetail created > artclSno:", this.artclSno, this.menuSno);

    this.getLangList();

    if(this.newYn == 'Y') {
      this.artcl = genEmptyArtcl(this.menuSno);
      this.pendingArtcl = JSON.parse(JSON.stringify(this.artcl));

      //신규 날짜 오늘날짜
      const now_date = this.$setYYYYMMDDHHMM();
      this.pendingArtcl.publc_dt = now_date.reg_dt;
    } 
    console.log("created this.pendingArtcl : ", this.pendingArtcl);
  },
  mounted() {
    if(this.newYn == 'N') {
      this.getArtcl();
    } 
  },
  methods: {
    gotoList() {
      // this.$router.go(-1)
      // 아래 path 설정 시 select box 에 값 설정이 안됨
      // this.$router.push({path:"preRegistList", query: { confrSno:this.confrSno }});
      this.$router.push({name:"publcArtclList", query: { menuSno:this.menuSno, menuNm: this.menuNm, artclSno:this.artclSno }});
    },
    async getLangList() {
      this.langList = await this.$getCmnCdList("lang");
    },
    getArtcl() {
      console.log("this.artclSno(in getArtcl):", this.artclSno);
      const params = new FormData();

      params.append("artcl_sno", this.artclSno);

			this.$http.post("/admin/getPublcArtcl", params).then((res) => {
        console.log("res.data: ", res.data);
        const artcl = res.data.result.publcArtcl;

        artcl.disp_tf = artcl.disp_yn == 'Y' ? true : false;

        this.artcl = artcl;
        this.pendingArtcl = JSON.parse(JSON.stringify(artcl));

        // this.fileList = [];

        // if(res.data.result.atchFileList != null) {
        //   res.data.result.atchFileList.forEach((e) => {
        //     this.fileList.push({file: {name: e.orgnl_file_nm}, new_yn: 'N', idx: this.fileIdx++, atch_odr: e.atch_odr, file_path: e.save_path + e.save_file_nm});
        //   })
        // }
        // this.pendingFileList = JSON.parse(JSON.stringify(this.fileList));
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 도서명
      if(this.pendingArtcl.book_ttl == null || this.pendingArtcl.book_ttl == "") {
        alert("도서명은 필수 입력값입니다.");
        return false;
      }
      // 권호
      if(this.pendingArtcl.vol == null || this.pendingArtcl.vol == "") {
        alert("권호는 필수 입력값입니다.");
        return false;
      }
      // 발행일
      if(this.pendingArtcl.publc_dt == null || this.pendingArtcl.publc_dt == "") {
        alert("발행일은 필수 입력값입니다.");
        return false;
      }
      // 시작페이지
      if(this.pendingArtcl.bgn_page == null || this.pendingArtcl.bgn_page == "") {
        alert("시작페이지는 필수 입력값입니다.");
        return false;
      }
      // 종료페이지
      if(this.pendingArtcl.end_page == null || this.pendingArtcl.end_page == "") {
        alert("종료페이지는 필수 입력값입니다.");
        return false;
      }
      // 첨부파일
      if(this.pendingArtcl.newAtchFile == null && (this.pendingArtcl.orgnl_file_nm == null || this.pendingArtcl.orgnl_file_nm == "")) {
        alert("첨부파일은 필수 입력값입니다.");
        return false;
      }

      return true;
    },
    saveNAddBtnClicked() {
      this.saveArtcl("add");
      // console.log("out of save");
    },
    saveNStayBtnClicked() {
      this.saveArtcl("stay");
      // console.log("out of save");
    },
    saveNMoveBtnClicked() {
      this.saveArtcl("move");
      // console.log("out of save");
    },
    saveArtcl(action) {
      // console.log("saveArtcl:", this.pendingArtcl.read_cnt);

      // if(confirm("저장하시겠습니까?")) {
        const isValid = this.validateInput();

        if(isValid) {
          const params = new FormData();

          this.pendingArtcl.disp_yn = this.pendingArtcl.disp_tf ? 'Y' : 'N';

          if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
          params.append('new_yn', this.newYn);
          params.append("publcArtcl", JSON.stringify(this.pendingArtcl));

          // 첨부 파일
          if(this.pendingArtcl.newAtchFile != null) {
            params.append('file_list', this.pendingArtcl.newAtchFile);
          } 
          
          this.$http.post("/admin/upsertPublcArtcl", params, {
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then((res) => {
            console.log(res.data);
            alert("저장되었습니다.");
            
            if(this.newYn == 'Y') {
              this.artclSno = res.data.result.artcl_sno;
              this.newYn = 'N';
              console.log("this.artclSno(in save):", this.artclSno);
            }

            // console.log("in save: after alert 1");
            if(action == "stay") {
              this.getArtcl();
            } else if(action == "move") {
              this.gotoList();
            } else if(action == "add") {
              this.newYn = "Y";
              this.artcl = genEmptyArtcl(this.menuSno);
              this.pendingArtcl = JSON.parse(JSON.stringify(this.artcl));
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      // }
    },
    deleteBtnClicked() {
      console.log("deleteArtcl : ", this.pendingArtcl);

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("publcArtcl", JSON.stringify(this.pendingArtcl));

        this.$http.post("/admin/deletePublcArtcl", params).then((res) => {
          console.log(res.data);

          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.gotoList();
          } else {
            alert("삭제할 수 없습니다.");
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    deleteFileBtnClicked(filePath, artclAtchSno) {
      if(filePath == null) {
        alert("삭제할 파일이 없습니다.");
        return;
      }

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("artcl_sno", this.artclSno);
        params.append("file_path", filePath);
        params.append("artcl_atch_sno", artclAtchSno);

        this.$http.post("/admin/deletePublcArtclFile", params).then((res) => {
          console.log(res.data);
          alert("삭제되었습니다.");
          this.getArtcl();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    setSelectedDate(selected) {
      
      if(this.dateNm == "publc_dt") {
        this.pendingArtcl.publc_dt = selected;
      }
      
      this.datePopup = false;
    },//setSelectedDate end
    popupDatePicker( dateNm) {
      this.dateNm = dateNm;
      this.datePopup = true;
    },//popupDatePicker end
  },
}
</script>

<style>

</style>