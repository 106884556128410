<template>
  <v-container>
    <!--[[[[[[[[[[[[[[[        네이버클라우드 ID 검색 모달        ]]]]]]]]]]]]]]]-->
    <v-dialog
      v-model="showDialog"
      max-width="400px"
      max-height="600px"
    >
      <v-card style="text-align:center;">
        <v-app-bar 
          class="fixed-bar" 
          color="blue lighten-2" 
          max-height="60px">
          <v-toolbar-title
            class="white--text">
            네이버클라우드 ID 검색
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="$emit('close')"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <!-- <v-row class="" align="center">
          <v-col cols="3">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="search" 
              outlined
              dense
              hide-details
              background-color="white">
            </v-text-field>
          </v-col>
        </v-row> -->
        <div class="pa-3" style="overflow:auto;">
          <v-data-table
            :headers="gridHeader"
            :items="gridList"
            class="elevation-1 dTable-1"
            hide-default-footer
            disable-pagination
            dense
            @click:row="gridRowClicked($event)"
            @dblclick:row="setSelected"
          >
          </v-data-table>
        </div>
        <v-btn text color="primary" @click="$emit('close')">Cancel</v-btn>
        <v-btn text color="primary" @click="setSelected()">OK</v-btn>
      </v-card>	
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showDialog: true,
      naverClntId: '',

      gridHeader: [
        { text: '네이버클라이언트ID', value: 'naver_clnt_id', width: "70%" },
        { text: '사용학회수', value: 'use_socty_cnt', width: "30%" },
      ],
      gridList: [],
      selectedId: null,

      // search: "",
    };
  },
  created() {
    console.log("naverClientIdhPopup created.");

    this.getNaverClientIdList();
  },
  methods: {
    getNaverClientIdList() {
      this.$http.get("/admin/getNaverClientIdList").then((res) => {
        console.log("getNaverClientIdList:", res.data);
        this.gridList = res.data.result.naverClntIdList;
      }).catch(function (error) {
          console.log(error);
        }
      )
    },
    setSelected() {    
      console.log("setSelected:");
      this.$emit('selected', this.selectedId.naver_clnt_id);
    },
    gridRowClicked(event) {
      console.log("gridRowClicked:", event);
      this.selectedId = event;
    },
    gridRowDblClicked() {
      this.setSelected();
    },
  },
};
</script>