<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <router-view :key="redraw"/>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  data(){
    return{
      drawer: false,
      redraw: 0,
      headerData: {},
      globl_css: '',
      edtr_css: '',
    }
  },
  created(){
    //헤더 파비콘 이미지 정보
    this.getHeaderImg();
    this.getSoctyCss();
    this.getTinyApikey();
    
    // console.log("favicon path ::::::: " +  this.faviconPath);
    //   favicon.href = this.headerData.favicon_path
  },
  watch: {
    '$route' () {
      if(this.$route.path == '/join') {
        this.redraw++;
      }
    }
  },
  methods:{
    getHeaderImg() {
      this.$http.get("/admin/getSoctyImg").then((res) => {
        if(res.data.result[0]) {
          this.headerData = res.data.result[0];
          console.log("data favicon path _ :::::: " + this.headerData.favicon_path);
          const favicon = document.getElementById("favicon");
          favicon.href = this.headerData.favicon_path;
        }
        console.log(res.data.result);
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getHeaderImg end
    getSoctyCss() {
      const params = new FormData();

			this.$http.post("/admin/getMainCss", params).then((res) => {
        console.log("res.data.globl_css::::::::: ", res.data.result.globl_css);
        console.log("res.data.edtr_css::::::::: ", res.data.result.edtr_css);

        if(res.data.result) {
          this.globl_css = res.data.result.globl_css;
          this.edtr_css = res.data.result.edtr_css;

          var sheet = document.createElement('style');
          var sheet_str = this.edtr_css;
          var sheet_globl = this.globl_css;
          var head = document.head || document.getElementsByTagName('head')[0];

          if (sheet.styleSheet) {	
            sheet.styleSheet.cssText=sheet_str;
            sheet.styleSheet.cssText=sheet_globl;
          }
          else {	
            sheet.appendChild(document.createTextNode(sheet_str));
            sheet.appendChild(document.createTextNode(sheet_globl));
          }
          head.appendChild(sheet);
          
        }
			}).catch(function (error) {
					console.log(error);
				}
			);
    },//getSoctyCss end
    getTinyApikey() {
      this.$http.get("/common/getTinyApiKey").then((res) => {
        // alert(res.data.api_key);
        this.$store.state.tinyApiKey = res.data.api_key;
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getTinyApikey end 
  }

}
</script>

<style>
@import "./styles/common.scss";
@import 'swiper/dist/css/swiper.min.css';

</style>