<template>
  <v-container fluid>
    <v-row class="ma-0 pa-0 mb-5">
      <v-col cols="12">
        <div class="wid-100">
          <img :src="url_path" class="imgDf">
        </div>
      </v-col>
    </v-row>
    
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <div class="tiny" v-if="confrYn == 'Y' && confrDtl" v-html="confrDtl"></div>
      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>

export default {
  data() {
    return {
      confrDtl: '',
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      permMap: {},
      emitData: {},
      confrYn: '',
      url_path: '',
    }
  },
  created() {
    const formData = new FormData();
    formData.append('mber_sno', this.mberSno);
    formData.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath,formData).then((res) => {
      console.log(res.data);
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push("/");
        return;
      } else {
        console.log(res.data);
        this.confrYn = res.data.confrYn;
        if(this.confrYn == 'Y') {
          this.confrDtl = res.data.confrDtl;
          this.confrDtl = res.data.confrDtl;
          this.url_path = res.data.url_path;
        }
        
        this.emitData.menu_sno = res.data.menu_sno;
        this.$emit('setData', this.emitData);
      }
      
    }).catch(
      function (error) {
      console.log(error);
      }
    )
  },
  methods: {

  }
}
</script>