<template>
  <v-container>
    <v-row class="pa-0 mb-10">
      <v-col cols="12" class="pa-0 d-flex align-center">
        <div class="" style="border-bottom: 2px solid blue;">
          <h2 class="pb-3">비회원 신청</h2>
        </div>
      </v-col>
      <v-col cols="12" class="pa-0 border-b-darkgrey"></v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="12" class="wid-100 d-flex " style="align-content: center;">
        <v-card class="hei-400p wid-100 bg-lightgrey-2 justify-center align-center" outlined>
          <v-row class="pa-6 mb-10">
            <v-col cols="12" class="pa-0 d-flex align-center">
              <div class="">
                <h3 class="pb-1">비회원 신청 로그인</h3>
              </div>
            </v-col>
            <v-col cols="12" class="pa-0 border-b-grey"></v-col>
          </v-row>
          <div class="d-flex justify-center">
            <div class="wid-60 mt-5">
              <v-row dense class="ma-0">
                <v-col cols="12" class="">
                  <v-text-field
                    class="bg-white"
                    height="40px"
                    prepend-inner-icon="mdi-account"
                    hide-details
                    outlined
                    label="수신 가능한 이메일을 작성해주세요."
                    v-model="email">
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="">
                  <v-text-field
                    class="bg-white"
                    height="40px"
                    prepend-inner-icon="mdi-lock"
                    append-icon="mdi-eye"
                    hide-details
                    outlined
                    v-model="pwd"
                    type="password"
                    @keyup.enter="loginBtnClicked">
                  </v-text-field>
                </v-col>
                <v-col cols="12" class=""></v-col>
                <v-col cols="12" class="hei-40p mb-3">
                  
                  <v-btn
                    block
                    tile
                    outlined
                    @click="loginBtnClicked"
                    height="40px"
                    class="theme-color-1 white--text">
                    로그인
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          
        </v-card>
      </v-col>
    </v-row>
    <div v-if="isLoading" class="loading-container">
      <div class="spinner-loading">
        <Fade-loader />
      </div>
    </div>
  </v-container>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'
export default {
  components: { FadeLoader },
  data() {
    return {
      email: '',
      pwd: '',
      confr_sno: this.$route.query.confr_sno,
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      basicUri: '',
      menu_1_Nm: '',
      isLoading: false,
    }
  },
  created() {

    let path = this.$route.fullPath;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    if(this.mberSno) {
      this.$router.push(this.basicUri+'/list')
    } else if(!this.confr_sno) {
      alert('잘못된 접근입니다.');
      this.$router.push(this.basicUri+'/list')
    }

    this.$http.get("/admin/getMenuList").then((res) => {
      console.log("res.data: ", res.data);
      const menuList = res.data.menuList;
      const menuSno = this.$route.query.menu_sno;
      let urlPath = menuList.filter((a) => a.menu_sno == menuSno)[0].url_path;
      const menu1Lvl = urlPath.split('/')[1];
      this.menu_1_Nm = menuList.filter((a) => a.resrc_nm == menu1Lvl)[0].menu_nm;

    }).catch(function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    loginBtnClicked() {
      if(this.validateInput()) {
        
        const params = new FormData();

        params.append("email", this.email);
        params.append("pwd", this.pwd);
        params.append("confr_sno", this.confr_sno);
        params.append("menu_1_Nm", this.menu_1_Nm);

        this.isLoading = true;
        this.$http.post("/sub/nonMberLogin", params).then((res) => {
          console.log("soctyProd: ", res.data);

          if(res.data.code == 0) {
            const nonMberSno = res.data.non_mber_sno;
            this.$session.set('mberSno', nonMberSno);
            this.$session.set('nonMberYn', "Y");
            this.$session.set('tempPwdYn', 'N');
            if(res.data.new_yn == 'Y') {
              alert('비회원 로그인 정보가 이메일로 발송되었으니 확인 바랍니다.');
            }
            this.isLoading = false;
            this.$router.push(this.basicUri+'/list');
            // this.$router.push(this.basicUri+'/list').catch( () => {
            //   this.$router.push(this.basicUri+'/list')
            // });
          } else if(res.data.code == -9002) {
            alert('비밀번호 일치하지 않습니다.');
            return;
          } else if(res.data.code == -9999) {
            alert('비회원 로그인 실패');
            return;
          }


        }).catch(function (error) {
            console.log(error);
          }
        );
      }
    },//loginBtnClicked end
    validateInput() {
      if(!this.email) {
        alert('아이디 입력해주세요.');
        return false;
      } else if(!this.pwd) {
        alert('비밀번호 입력해주세요.');
        return false;
      } else if(!this.$validateEmail(this.email)) {
        alert('이메일 형식이 아닙니다.');
        return false;
      }
      return true;
    },
  }
}
</script>

<style scoped>
.spinner-loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}
.v-input .v-label{
  font-size:12px;
  font-weight: bold;
}
.v-text-field--enclosed /deep/ .v-input__prepend-inner{
  margin-top: 10px;
}
.v-text-field--enclosed /deep/ .v-input__append-inner{
  margin-top: 10px;
}
.v-input--selection-controls /deep/ {
  margin-top:0px;
}

.v-text-field--outlined /deep/ .v-label  {
  top: 12px;
}
</style>
