<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center">
          <v-col cols="3">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="searchWord" 
              outlined
              dense
              hide-details
              placeholder="검색어"
              background-color="white">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <!-- <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text" 
              @click="searchBtnClicked"
              >검색
            </v-btn> -->
          </v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addPostBtnClicked"
              >게시글 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1"
              :search="searchWord"
            >
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <template v-slot:[`item.bbst_ttl`]="{item}">
                <v-text-field
                  v-model="item.bbst_ttl"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="rowClicked(item)"
                ></v-text-field>
              </template>
              <template v-slot:[`item.upend_fixg_tf`]="{ item }">
                <v-simple-checkbox
                  v-model="item.upend_fixg_tf"
                  hide-details
                  disabled
                  >
                </v-simple-checkbox>
              </template>
              <template v-slot:[`item.main_popup_tf`]="{ item }">
                <v-simple-checkbox
                  v-model="item.main_popup_tf"
                  hide-details
                  disabled
                  >
                </v-simple-checkbox>
              </template>
              <template v-slot:[`item.main_rlng_tf`]="{ item }">
                <v-simple-checkbox
                  v-model="item.main_rlng_tf"
                  hide-details
                  disabled
                  >
                </v-simple-checkbox>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" v-if="boardType != 'bbs_glly'">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.postList.radioUpendFixgYn"
              >
                <template v-slot:label>
                  <div><span>상단 고정으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0" v-if="boardType == 'bbs_notc'">
              <v-radio-group 
                v-model="$store.state.postList.radioMainPopupYn"
              >
                <template v-slot:label>
                  <div><span>메인 팝업으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0" v-if="boardType == 'bbs_notc'">
              <v-radio-group 
                v-model="$store.state.postList.radioMainRlngYn"
              >
                <template v-slot:label>
                  <div><span>메인 롤링으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      // mberChkDialog: false,
      // gridHeader: [
      //   { text: '선택', value: 'select_yn', width: "5%", sortable:false },
      //   { text: '제목', value: 'bbst_ttl', width: "36%" },
      //   { text: '카테고리', value: 'bbst_catg', width: "10%" },
      //   { text: '등록자', value: 'reg_mber_nm', width: "7%" },
      //   { text: '상단고정', value: 'upend_fixg_tf', width: "7%" },
      //   { text: '메인팝업', value: 'main_popup_tf', width: "7%" },
      //   { text: '메인롤링', value: 'main_rlng_tf', width: "7%" },
      //   { text: '열람', value: 'read_cnt', width: "6%" },
      //   { text: '등록일시', value: 'reg_dtm', width: "15%" },
      // ],
      // gridList: [],
      postList: [],

      searchWord: null,
      menuSno: this.$route.query.menuSno,
      boardType: "", //this.$route.query.boardType,
    }
  },
  computed: {
    gridHeader() {
      let header = [
        { text: '선택', value: 'select_yn', width: "5%", sortable:false },
        { text: '제목', value: 'bbst_ttl', width: "36%" },
        { text: '카테고리', value: 'bbst_catg', width: "10%" },
        { text: '등록자', value: 'reg_mber_nm', width: "7%" },
      ];
      
      if(this.boardType != 'bbs_glly') {
        header.push( { text: '상단고정', value: 'upend_fixg_tf', width: "7%" } );
      }

      if(this.boardType == 'bbs_notc') {
        header.push( { text: '메인팝업', value: 'main_popup_tf', width: "7%" } );
        header.push( { text: '메인롤링', value: 'main_rlng_tf', width: "7%" } );
      }

      header.push( { text: '열람', value: 'read_cnt', width: "6%" } );
      header.push( { text: '등록일시', value: 'reg_dtm', width: "15%" } );

      return header;
    },
    gridList() {
      return this.postList.filter((e) => 
        ( (this.$store.state.postList.radioUpendFixgYn == "모두" || e.upend_fixg_yn == this.$store.state.postList.radioUpendFixgYn) &&
          (this.$store.state.postList.radioMainPopupYn == "모두" || e.main_popup_yn == this.$store.state.postList.radioMainPopupYn) &&
          (this.$store.state.postList.radioMainRlngYn == "모두" || e.main_rlng_yn == this.$store.state.postList.radioMainRlngYn)
        )
      );
    }
  },
  created() {
    // this.menuSno = this.$route.query.menuSno;
    console.log("postList created:", this.boardType);
  },
  mounted(){
    this.getPostList();
  },
  methods: {
    getPostList() {
      const params = new FormData();

      params.append("menu_sno", this.menuSno);

      this.$http.post("/admin/getPostList", params).then((res) => {
        // console.log("res.data.result:", res.data.result);
        this.boardType = res.data.result.boardType.page_type;
        this.bbstCatg = res.data.result.boardType.bbst_catg;

        res.data.result.postList.forEach((e) => {
          e.select_yn = false;
          e.upend_fixg_tf = e.upend_fixg_yn == "Y" ? true : false;
          e.main_popup_tf = e.main_popup_yn == "Y" ? true : false;
          e.main_rlng_tf = e.main_rlng_yn == "Y" ? true : false;
        });

        // this.gridList = res.data.result.prodList;
        this.postList = res.data.result.postList;
        // this.filterList();
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    rowClicked(event) {
      console.log("rowClicked", event);
      this.$router.push({name:"postDetail", query: { menuSno:this.menuSno, boardType:this.boardType, bbstCatg:this.bbstCatg, postSno:event.bbst_sno, newYn:'N' }});
    },
    // 게시글 추가 버튼 클릭
    addPostBtnClicked() {
      this.$router.push({name:"postDetail", query: { menuSno:this.menuSno, boardType:this.boardType, bbstCatg:this.bbstCatg, postSno:null, newYn:'Y' }});
    },
    deleteBtnClicked() {
      console.log("deletePostList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: prod_sno[" + e.prod_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          console.log("deleteList:", deleteList);
          let params = new URLSearchParams();
          
          params.append("page_type", this.boardType);
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deletePostList", params).then((res) => {
            console.log(res.data);

            if(res.data.code == 0) {
              alert("삭제되었습니다.");
              this.getPostList();
              // this.gridList = this.gridList.filter((e) => {
              //   return !(e.select_yn);
              // });
            } else {
              alert("삭제할 수 없습니다.");
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    // radioFilterChanged() {
    //   this.filterList();
    // },
    
  }
}
</script>
  
<style>

</style>