<template>
  <v-app>
    <!-- [[[[[[[[ 최상단 북박스 , 광고 부분 ]]]]]]]] -->
    <template>
      <v-container fluid class="pa-0 ma-0 bgImg-cover hei-560p-auto" :style="`background-image:url(`+imgMap.main_page_top_img_path+`);`" v-show="viewYn">
        <v-container grid-list-xs :style="windowWidth > 1220 ? 'width:1200px' : 'width:100%'">
          <v-row row wrap class="ma-0">
            <template>
                <v-row class="ma-0 pa-0 hei-450p-auto" align="end">
                  <v-col 
                    cols="3" 
                    class="pa-0 ma-0 hidden-md-and-down boardBookBox"
                    v-show="windowWidth > 1220"
                    v-for="(item, idx) in bookBoxList"
                    :key="`bookBox_`+idx">
                    <v-row class="ma-0" dense align="center" >
                      <v-col cols="4" class="pa-0" v-show="item.book_img_path">
                        <div class="imgDiv-1">
                          <img class="imgDf-h" :src="item.book_img_path">
                        </div>
                      </v-col>
                      <v-col cols="8" class="pa-2 hei-130p d-flex align-content-space-between flex-wrap">
                        <v-row class="ma-0 wid-100">
                          <v-col cols="12" class="wid-100 pa-2 bookWord"><h4 v-html="item.book_text"></h4></v-col>
                        </v-row>
                        <v-row class="ma-0 wid-100">
                          <v-col cols="12" class="pa-0">
                            <v-btn 
                              x-small 
                              text 
                              color="blue" 
                              @click="newstBtnClicked(item)" 
                              class="pa-0 font-weight-bold bookBoxbtn">
                                최신호 보기
                            </v-btn>
                            <v-btn 
                              x-small 
                              text 
                              color="blue"
                              @click="regiSysBtnClicked(item)" 
                              class="pa-0 font-weight-bold bookBoxbtn" 
                              v-if="item.sbmt_sys_link_use_yn == 'Y'">
                                투고시스템 바로가기
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer/>

                  <v-col cols="6" style="max-width: 580px !important" class="ma-0 pa-0 hidden-md-and-down" v-show="windowWidth > 1220">
                    <v-row class="pa-0 ma-0 mWid-100-hei-auto ">
                      <v-carousel
                        cycle
                        :show-arrows="false"
                        hide-delimiter-background
                        delimiter-icon="mdi-minus"
                        height="400px"
                        class="pt-5 mhei-400p-auto">
                        <v-carousel-item
                          style="cursor: pointer;"
                          class="imgDf-h wid-100"
                          v-for="(item, i) in adList.filter((e) => e.main_ad_yn == 'Y' && e.disp_yn == 'Y')"
                          :key="`ad1_`+i">
                          <a :href="item.ad_url" :target="item.open_as_new_yn =='Y' ? '_blank' : ''">
                            <img class="imgDf-h wid-100" :src="item.ad_img_path" style="cursor: pointer;">
                          </a>
                        </v-carousel-item>
                      </v-carousel>
                    </v-row>
                  </v-col>

                  <!-- [[[[[[  tablet   ]]]]]] -->
                  <v-col cols="6" class=" ma-0" v-show="windowWidth < 1221 && windowWidth > 768">
                    <v-row>
                      <v-col cols="12" 
                        v-for="(item, idx) in bookBoxList"
                        :key="`bookBox_`+idx">
                        <v-row class="ma-0 hei-100 boardBookBox" dense align="center" >
                          <v-col cols="4" class="hei-130p" v-show="item.book_img_path">
                            <div class="wid-100 hei-100">
                              <img class="imgDf-h wid-100" :src="item.book_img_path">
                            </div>
                          </v-col>
                          <v-col cols="8" class="pa-2 hei-130p d-flex align-content-space-between flex-wrap">
                            <v-row class="ma-0 wid-100">
                              <v-col cols="12" class="wid-100 pa-2 bookWord"><h4 v-html="item.book_text"></h4></v-col>
                            </v-row>
                            <v-row class="ma-0 wid-100">
                              <v-col cols="12" class="pa-0">
                                <v-btn 
                                  x-small 
                                  text 
                                  color="blue" 
                                  @click="newstBtnClicked(item)" 
                                  class="pa-0 font-weight-bold bookBoxbtn">최신호 보기</v-btn>
                            <v-btn 
                              x-small 
                              text 
                              color="blue" 
                              @click="regiSysBtnClicked(item)" 
                              class="pa-0 font-weight-bold bookBoxbtn" 
                              v-if="item.sbmt_sys_link_use_yn == 'Y'">투고시스템 바로가기</v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  
                  <v-col cols="6" class="ma-0" v-show="windowWidth < 1221 && windowWidth > 768">
                    <v-row class="ma-0 mWid-100-hei-auto ">
                      <v-carousel
                        cycle
                        :show-arrows="false"
                        hide-delimiter-background
                        delimiter-icon="mdi-minus"
                        class="pt-5 mHei-300p-auto">
                        <v-carousel-item
                          style="cursor: pointer;"
                          class="imgDf-h wid-100"
                          v-for="(item, i) in adList.filter((e) => e.main_ad_yn == 'Y' && e.disp_yn == 'Y')"
                          :key="`ad1_`+i">
                          <a :href="item.ad_url" :target="item.open_as_new_yn =='Y' ? '_blank' : ''">
                            <img class="imgDf-h wid-100" :src="item.ad_img_path" style="cursor: pointer;">
                          </a>
                        </v-carousel-item>
                      </v-carousel>
                    </v-row>
                  </v-col>



                  <!-- [[[[[[  mobile   ]]]]]] -->
                  <v-col cols="12" class="ma-0 mt-10 pt-10 pl-0 pr-0" v-show="windowWidth < 767 && windowWidth > 350">
                    <v-row class="ma-0 wid-100">
                      <v-carousel
                        cycle
                        :show-arrows="false"
                        hide-delimiter-background
                        delimiter-icon="mdi-minus"
                        class="hei-300p">
                        <!-- <v-carousel-item
                          style="cursor: pointer;"
                          class="pa-3"
                          v-for="(item, i) in adList.filter((e) => e.main_ad_yn == 'Y' && e.disp_yn == 'Y')"
                          :key="`ad1_`+i">
                          <a :href="item.ad_url" :target="item.open_as_new_yn =='Y' ? '_blank' : ''" class="">
                            <img class="imgDf-h wid-100" :src="item.ad_img_path" style="cursor: pointer;">
                          </a>
                        </v-carousel-item> -->

                        <v-carousel-item
                          style="cursor: pointer; "
                          class="pa-3 hei-300p"
                          v-for="(item, i) in adList.filter((e) => e.main_ad_yn == 'Y' && e.disp_yn == 'Y')"
                          :key="`ad1_`+i">
                          <a :href="item.ad_url" :target="item.open_as_new_yn =='Y' ? '_blank' : ''" class="d-flex justify-center" style="cursor: pointer;">
                            <img class="imgDf-h wid-100" :src="item.ad_img_path" style="cursor: pointer; height: 300px; object-fit:contain;">
                          </a>
                        </v-carousel-item>
                      </v-carousel>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="ma-0" v-show="windowWidth < 767 && windowWidth > 350">
                    <v-row>
                      <v-col cols="12" 
                        v-for="(item, idx) in bookBoxList"
                        :key="`bookBox_`+idx">

                        <v-row class="ma-0 hei-100 boardBookBox" dense align="center" >
                          <v-col cols="4" class="hei-130p" v-show="item.book_img_path">
                            <div class="wid-100 hei-100">
                              <img class="imgDf-h wid-100" :src="item.book_img_path">
                            </div>
                          </v-col>
                          <v-col cols="8" class="pa-2 hei-130p d-flex align-content-space-between flex-wrap">
                            <v-row class="ma-0 wid-100">
                              <v-col cols="12" class="wid-100 pa-2 bookWord"><h4 v-html="item.book_text"></h4></v-col>
                            </v-row>
                            <v-row class="ma-0 wid-100">
                              <v-col cols="12" class="pa-0">
                                <v-btn 
                                  x-small 
                                  text 
                                  color="blue" 
                                  @click="newstBtnClicked(item)" 
                                  class="pa-0 bookBoxbtn font-weight-bold">최신호 보기</v-btn>
                                <v-btn 
                                  x-small 
                                  text 
                                  color="blue" 
                                  @click="regiSysBtnClicked(item)" 
                                  class="pa-0 bookBoxbtn font-weight-bold" 
                                  v-if="item.sbmt_sys_link_use_yn == 'Y'">투고시스템 바로가기</v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
            </template>
          </v-row>

          <v-row style="min-height: 121px;" class="ma-0" align="end">
            <v-col cols="12" class="d-flex hei-60p" :style="`background-color:`+point_color_4">
              <v-row align="center">
                <v-col cols="1"><v-btn class=""  :style="`color:` + font_color_6 +`;`" icon @click="ntceBbsClicked(1)"><v-icon>mdi-chevron-left</v-icon></v-btn></v-col>
                <v-col cols="10" class="pa-0 overflow-hidden">
                  <div class="hei-40p overflow-hidden">
                    <v-carousel 
                      vertical
                      cycle 
                      height="100px"
                      :show-arrows="false"
                      hide-delimiter-background
                      class="pa-0 ma-0 overflow-hidden"
                      v-model="ntceBbsOdr">
                      <v-carousel-item v-for="(item, idx) in mainImgNtceList" :key="`imgNtce_`+idx">
                        <v-list class="bg-alpha-0 pa-0 ma-0 hei-40p dreamFont4" dense>
                          <v-list-item link class="hei-40p" :style="`color:` + font_color_6 +`;`" @click="ntceRollClicked(item)">
                            <!-- [[[[[[ web & tablet ]]]]]] -->
                            <div class="" v-show="windowWidth > 767">
                              <span class="">{{item.bbst_ttl}} </span>
                            </div>
                            
                            <!-- [[[[[[ mobile ]]]]]] -->
                            <div class="mobRollingWord" v-show="windowWidth < 767 && windowWidth > 350">
                              <span class="">{{item.bbst_ttl}} </span>
                            </div>
                          </v-list-item>
                          
                        </v-list>
                      </v-carousel-item>
                    </v-carousel>
                  </div>
                </v-col>
                <v-col cols="1"><v-btn class=""  :style="`color:` + font_color_6 +`;`" icon style="float:right;" @click="ntceBbsClicked(2)"><v-icon>mdi-chevron-right</v-icon></v-btn></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </template>

    <!-- [[[[[[[[ 게시판 , 알림존 ]]]]]]]] -->
    <template>
      <v-container class="hei-450p-auto notiBox" v-show="viewYn" :style="this.windowWidth > 1220 ? 'width:1200px' : 'width:100%'">
          <v-row class="ma-0">
            <v-container>
              <!-- [[[[[[   web   ]]]]]] -->
              <v-row class="mt-0 ma-0 hei-450p-auto" style="width:100%;" v-show="this.windowWidth > 1220">
                <v-col cols="4" class="hidden-md-and-down">
                  <v-row class="ma-0">
                    <!-- 좌측 박스 -->
                    <v-col cols="10" class="ma-0 pa-0 d-flex btnArea">
                      <div class="d-flex wid-100 " v-show="ntceBoxL1Data || ntceBoxL2Data">
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pa-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_L1 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard1Clicked(1)"
                          v-show="ntceBoxL1Data">
                            <span class="notiWord dreamFont5">{{ntceBoxL1Data ? ntceBoxL1Data.menu_nm : ''}}</span>
                        </v-btn>
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_L2 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard1Clicked(2)"
                          v-show="ntceBoxL2Data">
                          <span class="notiWord dreamFont5">{{ntceBoxL2Data ? ntceBoxL2Data.menu_nm : ''}}</span>
                        </v-btn>
                      </div>
                    </v-col>
                      
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <div class="pl-5" v-show="ntceBoxL1Data || ntceBoxL2Data">
                        <v-btn icon @click="subBoardPlusClicked(1)"><v-icon>mdi-plus</v-icon></v-btn>
                      </div>
                    </v-col>

                    <!-- 게시글 L1 -->
                    <v-col cols="12" class="pa-0 listArea" v-show="ntceBoxL1Data && board_L1">
                      <v-list one-line class="">
                        <template v-for="(item, index) in ntceBoxL1List">
                          <v-list-item
                          :key="`boxL1_` + index"
                          class="pl-1 pr-1">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12" ><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(1, item.bbst_sno)" style="cursor: pointer;"><span><span>{{ item.bbst_ttl }}</span></span></v-list-item-title></v-col>
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                    <!-- 게시글 L2 -->
                    <v-col cols="12" class="pa-0 listArea" v-show="ntceBoxL2Data && board_L2">
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxL2List">
                          <v-list-item
                          :key="`boxL2_` + index"
                          class="pl-1 pr-1">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(2, item.bbst_sno)" style="cursor: pointer;"><span><span>{{ item.bbst_ttl }}</span></span></v-list-item-title></v-col>
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
                
                <v-col cols="4" class="hidden-md-and-down">
                  <v-row class="ma-0">
                    <!-- 우측 박스 -->
                    <v-col cols="10" class="ma-0 pa-0 d-flex btnArea">
                      <div class="d-flex wid-100" v-show="ntceBoxR1Data || ntceBoxR2Data">
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_R1 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard2Clicked(1)"
                          v-show="ntceBoxR1Data">
                          <span class="notiWord dreamFont5">{{ntceBoxR1Data ? ntceBoxR1Data.menu_nm : ''}}</span>
                        </v-btn>
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_R2 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard2Clicked(2)"
                          v-show="ntceBoxR2Data">
                          <span class="notiWord dreamFont5">{{ntceBoxR2Data ? ntceBoxR2Data.menu_nm : ''}}</span>
                        </v-btn>
                      </div>
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <div class="pl-5" v-show="ntceBoxR1Data || ntceBoxR2Data">
                        <v-btn icon @click="subBoardPlusClicked(2)"><v-icon>mdi-plus</v-icon></v-btn>
                      </div>
                    </v-col>

                    <!-- 게시글 R1 -->
                    <v-col cols="12" class="pa-0 listArea" v-show="ntceBoxR1Data && board_R1"> 
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxR1List">
                          <v-list-item
                          :key="`boxR1_` + index"
                          class="pl-1 pr-1">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(3, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                    <!-- 게시글 R2 -->
                    <v-col cols="12" class="pa-0 listArea" v-show="ntceBoxR2Data && board_R2">
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxR2List">
                          <v-list-item
                            :key="`boxR2_` + index"
                            class="pl-1 pr-1">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(4, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="4" class="hidden-md-and-down">
                  <v-row class="mt-0">
                    <v-col cols="3">
                      <div><h3 class="dreamFont4">알림존</h3></div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="5" class="" >
                      <div style="float:right;">
                        <v-btn icon small @click="preNextClicked(1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-btn icon small @click="cycleYn = !cycleYn" v-if="!cycleYn"><v-icon>mdi-play</v-icon></v-btn>
                        <v-btn icon small @click="cycleYn = !cycleYn" v-if="cycleYn"><v-icon>mdi-pause</v-icon></v-btn>
                        <v-btn icon small @click="preNextClicked(2)"><v-icon>mdi-chevron-right</v-icon></v-btn>
                        <span>{{this.adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y').length != 0 ? adNtceOdr+1 : 0}}/{{ adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y').length }}</span>
                      </div>
                    </v-col>
                    
                    <v-col cols="12">
                      <v-carousel
                        :cycle="cycleYn"
                        :show-arrows="false"
                        hide-delimiter-background
                        delimiter-icon="mdi-minus"
                        height="300"
                        v-model="adNtceOdr">
                        <v-carousel-item
                          style="cursor: pointer;"
                          class="imgDf"
                          v-for="(item, i) in adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y')"
                          :key="`ad_noti_` + i"
                          progress
                        >
                        <a :href="item.ad_url" :target="item.open_as_new_yn =='Y' ? '_blank' : ''">
                            <img class="imgDf" :src="item.ad_img_path" style="cursor: pointer;">
                          </a>
                        </v-carousel-item>
                      </v-carousel>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <!-- [[[[[[  tablet   ]]]]]] -->
              <v-row class="mt-0 ma-0" style="width:100%;" v-show="this.windowWidth < 1221 && this.windowWidth > 768">
                <v-col cols="6" class="ma-0">
                  <v-row class="ma-0">
                    <!-- 좌측 박스 -->
                    <v-col cols="10" class="ma-0 pa-0 d-flex btnArea" v-show="ntceBoxL1Data || ntceBoxL2Data">
                      <div class="d-flex wid-100" v-show="ntceBoxL1Data || ntceBoxL2Data">
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_L1 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard1Clicked(1)"
                          v-show="ntceBoxL1Data">
                          <span class="notiWord dreamFont5">{{ntceBoxL1Data ? ntceBoxL1Data.menu_nm : ''}}</span>
                        </v-btn>
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_L2 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard1Clicked(2)"
                          v-show="ntceBoxL2Data">
                          <span class="notiWord dreamFont5">{{ntceBoxL2Data ? ntceBoxL2Data.menu_nm : ''}}</span>
                        </v-btn>
                      </div>
                    </v-col>
                      
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="d-flex justify-center align-center">
                      <div class="" v-show="ntceBoxL1Data || ntceBoxL2Data">
                        <v-btn icon @click="subBoardPlusClicked(1)"><v-icon>mdi-plus</v-icon></v-btn>
                      </div>
                    </v-col>

                    <!-- 게시글 L1 -->
                    <v-col cols="12" class="listArea" v-show="ntceBoxL1Data && board_L1">
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxL1List">
                          <v-list-item
                          :key="`boxL1_` + index"
                          >
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(1, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                    <!-- 게시글 L2 -->
                    <v-col cols="12" class="listArea" v-show="ntceBoxL2Data && board_L2">
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxL2List">
                          <v-list-item
                          :key="`boxL2_` + index"
                          >
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(2, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
                
                <v-col cols="6" class="ma-0" v-show="this.windowWidth < 1024 && this.windowWidth > 768">
                  <v-row class="ma-0" v-if="adList">
                    <v-col cols="3">
                      <div class=""><span class="fontSize-1 font-weight-bold">알림존</span></div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="5" class="ma-0" >
                      <div style="float:right;">
                        <v-btn icon small @click="preNextClicked(1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-btn icon small @click="cycleYn = !cycleYn" v-if="!cycleYn"><v-icon>mdi-play</v-icon></v-btn>
                        <v-btn icon small @click="cycleYn = !cycleYn" v-if="cycleYn"><v-icon>mdi-pause</v-icon></v-btn>
                        <v-btn icon small @click="preNextClicked(2)"><v-icon>mdi-chevron-right</v-icon></v-btn>
                        <span>{{this.adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y').length != 0 ? adNtceOdr+1 : 0}}/{{ adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y').length }}</span>
                      </div>
                    </v-col>
                    
                    <v-col cols="12">
                      <v-carousel
                        :cycle="cycleYn"
                        :show-arrows="false"
                        hide-delimiter-background
                        delimiter-icon="mdi-minus"
                        height="300"
                        v-model="adNtceOdr">
                        <v-carousel-item
                          style="cursor: pointer;"
                          class="imgDf"
                          v-for="(item, i) in adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y')"
                          :key="`ad_noti_` + i"
                          progress>
                          <a :href="item.ad_url" :target="item.open_as_new_yn =='Y' ? '_blank' : ''">
                            <img class="imgDf" :src="item.ad_img_path" style="cursor: pointer;">
                          </a>
                        </v-carousel-item>
                      </v-carousel>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-0 ma-0" style="width:100%;" v-show="ntceBoxR1Data || ntceBoxR2Data && this.windowWidth < 1221 && this.windowWidth > 768">
                <v-col cols="12" class="mb-7 ma-0" v-show="this.windowWidth < 1221 && this.windowWidth > 768">
                  <v-row class="">
                    <!-- 우측 박스 -->
                    <v-col cols="10" class="d-flex btnArea">
                      <div class="d-flex wid-100" v-show="ntceBoxR1Data || ntceBoxR2Data">
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_R1 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard2Clicked(1)"
                          v-show="ntceBoxR1Data">
                          <span class="notiWord dreamFont5">{{ntceBoxR1Data ? ntceBoxR1Data.menu_nm : ''}}</span>
                        </v-btn>
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_R2 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard2Clicked(2)"
                          v-show="ntceBoxR2Data">
                          <span class="notiWord dreamFont5">{{ntceBoxR2Data ? ntceBoxR2Data.menu_nm : ''}}</span>
                        </v-btn>
                      </div>
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col cols="2" class="d-flex justify-center align-center">
                      <div class="" v-show="ntceBoxR1Data || ntceBoxR2Data">
                        <v-btn icon @click="subBoardPlusClicked(2)"><v-icon>mdi-plus</v-icon></v-btn>
                      </div>
                    </v-col>

                    <!-- 게시글 R1 -->
                    <v-col cols="12" class="listArea" v-show="ntceBoxR1Data && board_R1"> 
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxR1List">
                          <v-list-item
                          :key="`boxR1_` + index"
                          >
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(3, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                    <!-- 게시글 R2 -->
                    <v-col cols="12" class="listArea" v-show="ntceBoxR2Data && board_R2">
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxR2List">
                          <v-list-item
                            :key="`boxR2_` + index">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(4, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>

            <v-container>

              <!-- [[[[[[  mobile   ]]]]]] -->
              <v-row class="mt-0 ma-0" style="width:100%;" v-show="ntceBoxL1Data || ntceBoxL2Data">
                <v-col cols="12" class="" v-show="this.windowWidth < 767 && this.windowWidth > 350">
                  <v-row  class="ma-0">
                    <!-- 좌측 박스 -->
                    <v-col cols="10" class="ma-0 pa-0 d-flex btnArea">
                      <div class="d-flex wid-100" v-show="ntceBoxL1Data || ntceBoxL2Data">
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_L1 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard1Clicked(1)"
                          v-show="ntceBoxL1Data">
                          <span class="notiWord dreamFont5">{{ntceBoxL1Data ? ntceBoxL1Data.menu_nm : ''}}</span>
                        </v-btn>
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_L2 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard1Clicked(2)"
                          v-show="ntceBoxL2Data">
                          <span class="notiWord dreamFont5">{{ntceBoxL2Data ? ntceBoxL2Data.menu_nm : ''}}</span>
                        </v-btn>
                      </div>
                    </v-col>
                      
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="d-flex justify-center">
                      <div class="" v-show="ntceBoxL1Data || ntceBoxL2Data">
                        <v-btn icon @click="subBoardPlusClicked(1)"><v-icon>mdi-plus</v-icon></v-btn>
                      </div>
                    </v-col>

                    <!-- 게시글 L1 -->
                    <v-col cols="12" class="pa-0 listArea" v-show="ntceBoxL1Data && board_L1">
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxL1List">
                          <v-list-item
                            class="pa-0 pl-3 pr-3"
                            :key="`boxL1_` + index">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(1, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                    <!-- 게시글 L2 -->
                    <v-col cols="12" class="pa-0 listArea" v-show="ntceBoxL2Data && board_L2">
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxL2List">
                          <v-list-item
                            class="pa-0 pl-3 pr-3"
                            :key="`boxL2_` + index">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(2, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-0 ma-0" style="width:100%;" v-show="ntceBoxR1Data || ntceBoxR2Data">
                <v-col cols="12" class="" v-show="this.windowWidth < 767 && this.windowWidth > 350">
                  <v-row class="ma-0">
                    <!-- 우측 박스 -->
                    <v-col cols="10" class="ma-0 pa-0 d-flex btnArea">
                      <div class="d-flex wid-100" v-show="ntceBoxR1Data || ntceBoxR2Data">
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_R1 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard2Clicked(1)"
                          v-show="ntceBoxR1Data">
                          <span class="notiWord dreamFont5">{{ntceBoxR1Data ? ntceBoxR1Data.menu_nm : ''}}</span>
                        </v-btn>
                        <v-btn tile text outlined x-large class="fontSize-2 ma-0 pl-3 pr-3 wid-50 notiWord"
                          :style="board_R2 ? `background-color:` + point_color_2 + `;color:` + font_color_4 +`;` : ''"
                          @click="subBoard2Clicked(2)"
                          v-show="ntceBoxR2Data">
                          <span class="notiWord dreamFont5">{{ntceBoxR2Data ? ntceBoxR2Data.menu_nm : ''}}</span>
                        </v-btn>
                      </div>
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col cols="2" class="d-flex justify-center">
                      <div class="" v-show="ntceBoxR1Data || ntceBoxR2Data">
                        <v-btn icon @click="subBoardPlusClicked(2)"><v-icon>mdi-plus</v-icon></v-btn>
                      </div>
                    </v-col>

                    <!-- 게시글 R1 -->
                    <v-col cols="12" class="pa-0 listArea" v-show="ntceBoxR1Data && board_R1"> 
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxR1List">
                          <v-list-item
                            class="pa-0 pl-3 pr-3"
                            :key="`boxR1_` + index">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(3, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                    <!-- 게시글 R2 -->
                    <v-col cols="12" class="pa-0 listArea" v-show="ntceBoxR2Data && board_R2">
                      <v-list one-line>
                        <template v-for="(item, index) in ntceBoxR2List">
                          <v-list-item
                            class="pa-0 pl-3 pr-3"
                            :key="`boxR2_` + index">
                            <v-list-item-content class="pa-1 ma-0">
                              <v-row dense>
                                <v-col cols="12"><v-list-item-title class="text-black-2 dreamFont3" @click="subBoardListClicked(4, item.bbst_sno)" style="cursor: pointer;"><span>{{ item.bbst_ttl }}</span></v-list-item-title></v-col>
                                <!-- <v-col cols="8"><v-list-item-subtitle>{{ item.bbst_cn }}</v-list-item-subtitle></v-col> -->
                                <v-col cols="12" class="ma-0 pa-0"><v-divider></v-divider></v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-0 ma-0" style="width:100%;" v-if="adList">
                <v-col cols="12" v-show="this.windowWidth < 767 && this.windowWidth > 350">
                  <v-row class="ma-0 pa-2">
                    <v-col cols="3">
                      <div><h3>알림존</h3></div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="5" class="" >
                      <div style="float:right;">
                        <v-btn icon small @click="preNextClicked(1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-btn icon small @click="cycleYn = !cycleYn" v-if="!cycleYn"><v-icon>mdi-play</v-icon></v-btn>
                        <v-btn icon small @click="cycleYn = !cycleYn" v-if="cycleYn"><v-icon>mdi-pause</v-icon></v-btn>
                        <v-btn icon small @click="preNextClicked(2)"><v-icon>mdi-chevron-right</v-icon></v-btn>
                        <span>{{this.adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y').length != 0 ? adNtceOdr+1 : 0}}/{{ adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y').length }}</span>
                      </div>
                    </v-col>
                    
                    <v-col cols="12">
                      <v-carousel
                        :cycle="cycleYn"
                        :show-arrows="false"
                        hide-delimiter-background
                        delimiter-icon="mdi-minus"
                        height="300"
                        v-model="adNtceOdr">
                        <v-carousel-item
                          style="cursor: pointer;"
                          class="imgDf"
                          v-for="(item, i) in adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y')"
                          :key="`ad_noti_` + i"
                          progress>
                          <a :href="item.ad_url" :target="item.open_as_new_yn =='Y' ? '_blank' : ''">
                            <img class="imgDf" :src="item.ad_img_path" style="cursor: pointer;">
                          </a>
                        </v-carousel-item>
                      </v-carousel>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
      </v-container>
    </template>

    <!-- [[[[[[[[ 학회일정 ]]]]]]]] -->
    <template v-if="mainPageMap && mainPageMap.scdul_menu_sno && this.menuList.filter((e) => e.page_type == 'scdul').length > 0">
      <v-container fluid class="pa-0 ma-0 pb-10 hei-400p-auto" :style="`background-color:`+point_color_2" v-show="viewYn">
        <v-container class="hei-400p-auto" :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
          <v-row row wrap class="padT-90p ma-0">
            <template class="">
              <v-row class="ma-0 pa-0" align="center">
                <v-col cols="12">
                  <v-row class="d-flex" justify="center" :style="`;color:` + font_color_4 +`;`">
                    <v-btn 
                      icon 
                      @click="moveDate(dateMap.preyymm)">
                      <v-icon class="" :style="`color:` + font_color_4 +`!important ;`">mdi-chevron-left</v-icon>
                    </v-btn>
                    <div class="pt-1"><h2 class="" >학회일정</h2></div>
                    <v-btn  
                      icon
                      @click="goPage(mainPageMap.scdul_menu_sno)">
                      <v-icon class="" :style="`color:` + font_color_4 +`!important ;`">mdi-plus</v-icon>
                    </v-btn>
                    <div class="pt-2"><v-icon class="" :style="`color:` + font_color_4 +`!important ;`">mdi-power-on</v-icon></div>
                    <h2 class="pt-1 dreamFont4">{{dateMap.nowyymm}}</h2>
                    <v-btn 
                      icon
                      @click="moveDate(dateMap.nextyymm)">
                      <v-icon class="" :style="`color:` + font_color_4 +`!important ;`">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col cols="12" class="">
                  <div class="hei-80p-auto">
                    <v-btn 
                      tile 
                      text 
                      small 
                      fab 
                      class="mr-3 text-white fontSize-5 dreamFont4" 
                      v-for="m in dateMap.lastdd" :key="`date_`+m" 
                      :style="scdulList.filter((e) => e.scdul_bgn_day_num <= m && e.scdul_end_day_num >= m).length > 0 ? `background-color:white; color:` + font_color_4 +`!important ;` : `caret-color: `+font_color_4 + `!important; color:` + font_color_4 +`!important ;`">
                      <span :style="'color' + font_color_4 +`!important ;`">{{m}}</span>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="wid-100 d-flex flex-wrap">
                  <v-row 
                    class="hidden-md-and-down ma-0 mr-9"
                    style="width:auto; flex: none;"
                    align="center"
                    v-for="(item , idx) in scdulList" 
                    :key="`scdul_`+idx" >
                      <span class="fontSize-6 mr-4 dreamFont4" :style="`color:` + font_color_4 +`!important ;`">{{item.scdul_bgn_dt == item.scdul_end_dt ? item.scdul_bgn_dt : item.scdul_bgn_dt+`~`+item.scdul_end_dt}}</span>
                      <span class="fontSize-1 dreamFont3" :style="`color:` + font_color_4 +`!important ;`">{{item.scdul_ttl}}</span>
                  </v-row>
                  
                  <!-- [[[[[[  tablet   ]]]]]] -->
                  <v-row v-show="this.windowWidth < 1024 && this.windowWidth > 768">
                    <v-col 
                      class="pa-10"
                      cols="6" 
                      v-for="(item , idx) in scdulList" 
                      :key="`scdul_`+idx">
                      <span class="fontSize-2" :style="`color:` + font_color_4 +`!important ;`">{{item.scdul_bgn_dt == item.scdul_end_dt ? item.scdul_bgn_dt : item.scdul_bgn_dt+`~`+item.scdul_end_dt}} {{item.scdul_ttl}}</span>
                    </v-col>
                  </v-row>

                  <!-- [[[[[[  mobile   ]]]]]] -->
                  <v-row v-show="this.windowWidth < 767 && this.windowWidth > 350">
                    <v-col 
                      class="pl-7 pr-7 pb-2"
                      cols="12" 
                      v-for="(item , idx) in scdulList" 
                      :key="`scdul_`+idx">
                      <span class="fontSize-2" :style="`color:` + font_color_4 +`!important ;`">{{item.scdul_bgn_dt == item.scdul_end_dt ? item.scdul_bgn_dt : item.scdul_bgn_dt+`~`+item.scdul_end_dt}} {{item.scdul_ttl}}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-row>
        </v-container>
      </v-container>
    </template>

    <!-- [[[[[[[[ 바로가기 버튼 메뉴 ]]]]]]]] -->
    <template v-if="mainPageStcutList.length > 0">
      <v-container fluid class="pa-0 ma-0 hei-300p-auto bgImg-cover" v-show="viewYn" :style="imgMap.main_page_btm_img_path ? `background-image:url(`+ imgMap.main_page_btm_img_path +`) ;` : 'background-color:#E6E6E6'">
        <v-container class="" :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
          <v-row class="ma-0 d-flex justify-center">
            <template class="">
              <v-row class="ma-0 pa-0 hei-300p-auto hidden-md-and-down" align="center" v-show="this.windowWidth > 1220">
                <v-row class="ma-0 pa-0 d-flex justify-center">
                  <v-col 
                    cols="2" 
                    class="icon-center"
                    v-for="item in mainPageStcutList"
                    :key="`stcut_`+item.odr"
                    >
                    <div class="hei-130p wid-180p d-flex justify-center" :style="`background-color:`+point_color_3 + `;color:`+ font_color_5+`!important;`" style="cursor: pointer;" @click="stcutClicked(item)">
                      <div class="">
                        <div class="d-flex justify-center">
                          <div class="mt-1 hei-80p wid-80p" >
                            <img class="wid-80p hei-80p" :src="item.icon_path">
                          </div>
                        </div>
                        <div class="wid-170p d-flex justify-center">
                          <div class="pl-2 pr-2 btnWord2 dreamFont4" :style="`color:`+ font_color_2+`!important;`">
                            <span class="fontSize-0 font-weight-bold">{{item.stcut_type == 'menu' ? item.menu_nm : item.stcut_nm}}</span>
                          </div>  
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-row>

              <!-- [[[[[[  tablet   ]]]]]] -->
              <v-row class="ma-0 pa-0 hei-300p-auto" align="center" v-show="this.windowWidth < 1221 && this.windowWidth > 768">
                <v-row class="ma-0 pa-0 d-flex justify-center" align="center">
                  <v-col 
                    cols="4" 
                    class="icon-center d-flex"
                    v-for="item in mainPageStcutList"
                    :key="`stcut_`+item.odr">
                    <div class="">
                      <div class="d-felx justify-center">
                        <div class="d-flex pa-5 hei-100p wid-100p" :style="`background-color:`+point_color_3 + `;color:`+ font_color_5+`;`">
                          <img class="wid-100" :src="item.icon_path" @click="stcutClicked(item)"> 
                        </div>
                      </div>
                      <div class="wid-100p d-flex justify-center">
                        <div class="pt-5 btnWord">
                          <span class="fontSize-0 font-weight-bold" :style="`color:`+ font_color_2+`!important;`">{{item.stcut_type == 'menu' ? item.menu_nm : item.stcut_nm}}</span>
                        </div>  
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-row>

              <!-- [[[[[[  mobile   ]]]]]] -->
              <v-row class="ma-0 pa-0 hei-300p-auto " align="center" v-show="this.windowWidth < 767 && this.windowWidth > 350">
                <v-row class="ma-0 pa-0 d-flex justify-center">
                  <v-col 
                    cols="4" 
                    class="icon-center d-flex justify-center"
                    v-for="item in mainPageStcutList"
                    :key="`stcut_`+item.odr">
                    <div class="wid-100">
                      <div class="d-flex justify-center">
                        <div class="d-flex pa-5 hei-70p wid-70p" :style="`background-color:`+point_color_3 + `;color:`+ font_color_5+`;`">
                          <img class="wid-100" :src="item.icon_path" @click="stcutClicked(item)">
                        </div>
                      </div>
                      <div class="wid-100p d-flex justify-center">
                        <div class="pt-5 btnWord">
                          <span class="fontSize-0 font-weight-bold" :style="`color:`+ font_color_2+`!important;`">{{item.stcut_type == 'menu' ? item.menu_nm : item.stcut_nm}}</span>
                        </div>  
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-row>
            </template>
          </v-row>
        </v-container>
      </v-container>
    </template>
    
    <!-- [[[[[[[[ 후원사 ]]]]]]]] -->
    <template v-if="soctySpnsrList.length > 0">
      <v-container fluid class="pa-0 ma-0 hei-500p-auto">
        <v-container class="hei-500p-auto" :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
          <v-row row wrap class="mb-10 ma-0" style="padding-top:90px;">
            <template>
              <v-row class="ma-0 pa-0" align="center">
                <v-col cols="12" style="margin-bottom:50px;">
                  <v-row class="d-flex" justify="center" align="center">
                    <span><h2 class="dreamFont4" >{{mainPageMap.spnsr_disp_ttl ? mainPageMap.spnsr_disp_ttl : '후원사'}}</h2></span>
                    <div class="" v-if="mainPageMap.spnsrYn == 'Y'"><v-btn class="" icon @click="sponsorClicked"><v-icon class="">mdi-plus</v-icon></v-btn></div>
                  </v-row>
                </v-col>
                <v-col cols="12" class="" style="border: 2px solid white !important; border-collapse:collapse !important;">
                  <v-row align="center" class="hidden-md-and-down" style="border: 2px solid white; border-collapse:separate !important;" v-show="this.windowWidth > 1220">
                    <v-col 
                      cols="2" 
                      class="d-flex justify-center text-center"
                      v-for="(item, idx) in soctySpnsrList"
                      :key="`spnsr_`+idx">
                      <div class="">
                        <a :href="item.link_url" target='_blank'>
                          <div class="wid-150p hei-100p d-flex align-center">
                            <img class="imgDf" :src="item.img_atch_path" />
                          </div>
                        </a>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- [[[[[[  tablet   ]]]]]] -->
                  <v-row align="center" class="pb-10" v-show="this.windowWidth < 1221 && this.windowWidth > 768">
                    <v-col 
                      cols="3" 
                      class="d-flex justify-center text-center"
                      v-for="(item, idx) in soctySpnsrList"
                      :key="`spnsr_`+idx">
                      <div class="">
                        <a :href="item.link_url" target='_blank'>
                          <div class="wid-100p hei-60p d-flex align-center">
                            <img class="imgDf" :src="item.img_atch_path" />
                          </div>
                        </a>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- [[[[[[  mobile   ]]]]]] -->
                  <v-row align="center" v-show="this.windowWidth < 767 && this.windowWidth > 350">
                    <v-col 
                      cols="4" 
                      class="d-flex justify-center text-center"
                      v-for="(item, idx) in soctySpnsrList"
                      :key="`spnsr_`+idx">
                      <div class="">
                        <a :href="item.link_url" target='_blank'>
                          <div class="wid-80p hei-40p d-flex align-center">
                            <img class="imgDf" :src="item.img_atch_path" />
                          </div>
                        </a>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </template>
          </v-row>
          
        </v-container>
        
      </v-container>

    </template>
    <template>
      <div>
        <!-- <noti-popup class="popupLocale" :style="{left:popupList.popupLocaleLeft+`px`,top:popupList.popupLocaleTop+`px`}" v-for="popupList in popupList" :key="popupList.bbst_sno" :popupResData="popupList" ></noti-popup> -->
        <noti-popup class="popupLocale" :style="{left:popupList.popupLocaleLeft,top:popupList.popupLocaleTop, 'margin-left':popupList.popupLocaleMarginLeft}" v-for="popupList in popupList" :key="popupList.bbst_sno" :popupResData="popupList" ></noti-popup>
      </div>
    </template>
  </v-app>
</template>

<script>
import NotiPopup from '@/components/common/notiPopup.vue'

export default {
    components:{
      NotiPopup,
    },
    data () {
      return {
        popupList:[],
        popupLocaleLeft:'1500',
        popupLocaleTop:'-140',
        mobPopupLocaleLeft:'0%',
        mobPopupLocaleTop:'5%',
        pop_url_path:'',
        pop_basic_path:'',
        windowWidth: window.innerWidth,
        notiPopup:false,
        tab: null,
        cycle: true,
        mainPageStcutList: [],      //바로가기 리스트
        soctySpnsrList: [],         //후원사 리스트
        soctySpnsrMap: {},
        mainPageMap: '',            //메인 페이지 기본 데이터
        dateMap: '',                //학회 일정 데이터
        menuList: [],               //메뉴리스트
        bookBoxList: [],            //북박스 리스트
        adList: [],
        scdulList: [],
        mainImgNtceList: [],
        ntceBoxL1List: [],
        ntceBoxL2List: [],
        ntceBoxR1List: [],
        ntceBoxR2List: [],
        tempPwdYn: this.$session.get('tempPwdYn'),
        btnActive_1: true,
        btnActive_2: false,
        btnActive_3: true,
        btnActive_4: false,

        board_L1:true,
        board_L2:false,
        board_R1:true,
        board_R2:false,
        footerBtnIcon:true,
        point_color_2: '#4392FF',
        point_color_3: '#1C1C1C',
        point_color_4: '#2a325f',
        font_color_2: '',
        font_color_4: '',
        font_color_5: '',
        font_color_6: '',
        subBoard1_Bol: true,
        subBoard2_Bol: true,
        ntceBoxL1Data: {},
        ntceBoxL1Auth: 'N',
        ntceBoxL2Data: {},
        ntceBoxL2Auth: 'N',
        ntceBoxR1Data: {},
        ntceBoxR1Auth: 'N',
        ntceBoxR2Data: {},
        ntceBoxR2Auth: 'N',
        viewYn: false,
        mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
        nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
        cycleYn: true,
        adNtceOdr: 1,
        mainImgNtceMap: '',
        mainImgNtceAuth: '',
        ntceBbsOdr: 0,
        authList: [],
        test: 1,
        imgMap: {},
        scdulAuth: 'N',
        lang: this.$session.get("lang") ? this.$session.get("lang") : '',
        nowDate: '',
			}
    },
    computed: {
      
    },
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }

    },
    watch: {
      adNtceOdr () {
        return this.adNtceOdr;
      }
    },
		created() {
      //메인 섹션에 필요한 데이터 조회
      // this.$session.set("test","session")
      // this.$store.
      // this.$session.set('mberSno', '1');
      // alert(this.$session.get('mberSno'));
      this.getMainData();
      this.getPoinColor();

      const today = new Date();
      let year = today.getFullYear(); // 2023
      this.now_year = year;
      let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 06
      let day = today.getDate().toString().padStart(2, '0'); // 18
      this.nowDate = year + month + day; // 20230618
      
    },
    methods: {
      getMainData() {
        const formData = new FormData();
        formData.append('mber_sno',this.mberSno);
        formData.append('non_mber_yn',this.nonMberYn);
        formData.append('lang',this.lang);
        
        this.$http.post("/home/getMainData",formData).then((res) => {

          console.log("resss ::: ",res.data);
          this.authList = res.data.authList;
          // console.log("this.authMap ;; ",this.authList);
          
          this.mainPageMap = res.data.mainPageMap;
          this.mainPageStcutList = res.data.mainPageStcutList;
          this.soctySpnsrList = res.data.soctySpnsrList;

          this.dateMap = res.data.dateMap;
          this.bookBoxList = res.data.bookBoxList;

          //여기
          this.bookBoxList.forEach((e) => {
            if(e.book_text) {
              e.book_text = e.book_text.replaceAll(/(\n|\r\n)/g,'<br>');
            }
          })

          this.dateMap.lastdd = Number(this.dateMap.lastdd);
          this.menuList = res.data.menuList;
          // console.log("this.menuList;;;;;",this.menuList);
          this.menuList = this.menuList.filter((e) => e.disp_yn == 'Y');
          this.menuList.forEach((e) => {
            if(e.page_type == 'static') {
              e.url_path = '/static'+e.url_path
            } else if(e.page_type == 'bbs_notc') {
              e.basic_path = '/board/notice'+e.url_path;
              e.url_path = '/board/notice'+e.url_path+'/list';
              this.pop_basic_path = e.basic_path;
              this.pop_url_path = e.url_path;
            } else if(e.page_type == 'bbs_glly') {
              e.basic_path = '/board/gallery'+e.url_path;
              e.url_path = '/board/gallery'+e.url_path+'/list';
            } else if(e.page_type == 'bbs_free') {
              e.basic_path = '/board/free'+e.url_path;
              e.url_path = '/board/free'+e.url_path+'/list';
            } else if(e.page_type == 'bbs_faq') {
              e.basic_path = '/board/faq'+e.url_path;
              e.url_path = '/board/faq'+e.url_path+'/list';
            } else if(e.page_type == 'bbs_qna') {
              e.basic_path = '/board/qna'+e.url_path;
              e.url_path = '/board/qna'+e.url_path+'/list';
            } else if(e.page_type == 'past_exctv' || e.page_type == 'curr_exctv') {
              e.url_path = '/executive'+e.url_path;
            } else if(e.page_type == 'spnsr') {
              e.url_path = '/sponsor'+e.url_path
            } else if(e.page_type == 'socty_hist') {
              e.url_path = '/history'+e.url_path
            } else if(e.page_type == 'scdul') {
              e.url_path = '/schedule'+e.url_path
            } else if(e.page_type == 'cntt_us') {
              e.url_path = '/contactus'+e.url_path
            } else if(e.page_type == 'pay') {
              e.url_path = '/pay'+e.url_path+'/list'
            } else if(e.page_type == 'abstr_reg') {
              e.basic_path = '/abstrReg'+e.url_path;
              e.url_path = '/abstrReg'+e.url_path+'/list'
            } else if(e.page_type == 'pre_reg') {
              e.basic_path = '/preReg'+e.url_path;
              e.url_path = '/preReg'+e.url_path+'/list'
            } else if(e.page_type == 'confr_outl') {
              e.url_path = '/confrOutl'+e.url_path;
            } else if(e.page_type == 'confr_scdul') {
              e.url_path = '/confrScdul'+e.url_path;
            } else if(e.page_type == 'event_tour') {
              e.url_path = '/confrEventTour'+e.url_path;
            } else if(e.page_type == 'trfc_accom_guid') {
              e.url_path = '/confrTrfcAccom'+e.url_path;
            } else if(e.page_type == 'ad_disp_guid') {
              e.url_path = '/confrAdDisp'+e.url_path;
            } else if(e.page_type == 'url_link') {
              e.url_path = e.url_link;
            } else if(e.page_type == 'mber_srch') {
              e.url_path = '/memberSearch'+e.url_path;
            } else if(e.page_type == 'doc_srch') {
              e.url_path = '/docSearch'+e.url_path;
            } else if(e.page_type == 'publc') {
              e.url_path = '/public'+e.url_path;
            }
            
          })

          //후원사 정보
          if(this.menuList.filter((e) => e.page_type == 'spnsr').length > 0) {
            const menuSno = this.menuList.filter((e) => e.page_type == 'spnsr')[0].menu_sno;
            if(this.authList && this.authList.filter((e) => e.menu_sno == menuSno)[0]) {
              this.soctySpnsrMap.get_perm_yn =  this.authList.filter((e) => e.menu_sno == menuSno)[0].get_perm_yn;
            }
            this.soctySpnsrMap.url_path = this.menuList.filter((e) => e.page_type == 'spnsr')[0].url_path;
          }

          this.adList = res.data.adList;
          if(this.adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y').length == 0) {
            this.adNtceOdr = 0;
          }
          
          //학회일정        
          
          this.scdulList = res.data.scdulList;
          if(this.authList && this.mainPageMap.scdul_menu_sno && this.authList.filter((e) => e.menu_sno == this.mainPageMap.scdul_menu_sno)[0]) {
            this.scdulAuth =  this.authList.filter((e) => e.menu_sno == this.mainPageMap.scdul_menu_sno)[0].get_perm_yn;
          }
          //바로가기
          this.mainPageStcutList.forEach((e) => {
            
            if(e.stcut_type == 'menu') {
              if(this.authList && this.authList.filter((g) => g.menu_sno == e.menu_sno)[0]) {
                e.get_perm_yn =  this.authList.filter((g) => g.menu_sno == e.menu_sno)[0].get_perm_yn;
              }
              const menu = this.menuList.filter((f) => e.menu_sno == f.menu_sno)[0];
              if(menu) {
                e.url_path = menu.url_path;
              }
            }
            
            // if(menu && menu.url_path.indexOf('/board/') == 0) {
            //   e.url_path = menu.url_path+'/list';
            // } else {
            //   e.url_path = menu.url_path;
            // }
          })
          
          const click_mon = Number(this.dateMap.yyyymm.substr(4,2));
          
          this.scdulList.forEach((e) => {
            let bgn_mon = Number(e.scdul_bgn_dt.replace('/','').substr(0,2));
            let end_mon = Number(e.scdul_end_dt.replace('/','').substr(0,2));
            let bgn_day = Number(e.scdul_bgn_dt.replace('/','').substr(2,2));
            let end_day = Number(e.scdul_end_dt.replace('/','').substr(2,2));
            if(e.scdul_bgn_dt) {
              let bgn_day = Number(e.scdul_bgn_dt.replace('/','').substr(2,2));
              e.scdul_bgn_day_num = bgn_day;
              
            }
            if(e.scdul_end_dt) {
              e.scdul_end_day_num = Number(e.scdul_end_dt.replace('/','').substr(2,2));
            }

            if(bgn_mon == click_mon) {
              e.scdul_bgn_day_num = bgn_day;
            }

            if(end_mon == click_mon) {
              e.scdul_end_day_num = end_day;
            }

            if(bgn_mon < click_mon) {
              e.scdul_bgn_day_num = 1;
            }

            if(end_mon > click_mon) {
              e.scdul_end_day_num = this.dateMap.lastdd;
            }
            
          })

          this.mainImgNtceList = res.data.mainImgNtceList;
          if(this.mainPageMap && this.mainPageMap.main_img_ntce_menu_sno) {
            const mainImgNtceMenuSno = this.mainPageMap.main_img_ntce_menu_sno;
            this.mainImgNtceMap = this.menuList.filter((e) => e.menu_sno == mainImgNtceMenuSno)[0];
            this.mainImgNtceAuth = res.data.mainImgNtceAuth;
          }
          
          //공지 박스 좌1
          this.ntceBoxL1List = res.data.ntceBoxL1List;
          this.ntceBoxL1Data = this.menuList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_l1_menu_sno)[0];
          
          //공지 박스 좌1 권한 
          if(this.mainPageMap && this.mainPageMap.ntce_box_l1_menu_sno) {
            if(this.authList && this.authList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_l1_menu_sno)[0]) {
              this.ntceBoxL1Auth =  this.authList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_l1_menu_sno)[0].get_perm_yn;
            }
          }

          //공지 박스 좌2
          this.ntceBoxL2List = res.data.ntceBoxL2List;
          this.ntceBoxL2Data = this.menuList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_l2_menu_sno)[0];

          if(!this.ntceBoxL1Data && this.ntceBoxL2Data) {
            this.board_L1 = false;
            this.board_L2 = true;
          }
          //공지 박스 좌2 권한 
          if(this.authList && this.mainPageMap && this.mainPageMap.ntce_box_l2_menu_sno) {
            if(this.authList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_l2_menu_sno)[0]) {
              this.ntceBoxL2Auth =  this.authList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_l2_menu_sno)[0].get_perm_yn;
            }
          }

          //공지 박스 우1
          this.ntceBoxR1List = res.data.ntceBoxR1List;
          this.ntceBoxR1Data = this.menuList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_r1_menu_sno)[0];
          //공지 박스 우1 권한 
          if(this.authList && this.mainPageMap && this.mainPageMap.ntce_box_r1_menu_sno) {
            if(this.authList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_r1_menu_sno)[0]) {
              this.ntceBoxR1Auth =  this.authList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_r1_menu_sno)[0].get_perm_yn;
            }
          }
          console.log("ntceBoxR1List : ", this.ntceBoxR1List);

          //공지 박스 우2
          this.ntceBoxR2List = res.data.ntceBoxR2List;
          this.ntceBoxR2Data = this.menuList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_r2_menu_sno)[0];

          if(!this.ntceBoxR1Data && this.ntceBoxR2Data) {
            this.board_R1 = false;
            this.board_R2 = true;
          }

          //공지 박스 우2 권한 
          if(this.authList && this.mainPageMap && this.mainPageMap.ntce_box_r2_menu_sno) {
            if(this.authList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_r2_menu_sno)[0]) {
              this.ntceBoxR2Auth =  this.authList.filter((e) => e.menu_sno == this.mainPageMap.ntce_box_r2_menu_sno)[0].get_perm_yn;
            }
          }

          // 오늘 그만 보기 리스트 세션 조회
          var checkPopupArr = this.$session.get('popupKeyArr');
          
          //오늘 체크된것만.
          if(checkPopupArr) {
            checkPopupArr = checkPopupArr.filter((e) => e.hideDate == this.nowDate);
            this.$session.set('popupKeyArr',checkPopupArr);
            console.log("che opoi arr ", this.$session.get('popupKeyArr'));
          }

          //팝업 리스트 & 위치 설정
          let popupList = res.data.popupList;
          let cnt = 1;

          popupList.forEach((e) => {
            if(this.windowWidth > 1220){
              e.popupLocaleLeft = Number(this.popupLocaleLeft)+(-50 * cnt)+'px';
              e.popupLocaleTop = Number(this.popupLocaleTop)+(50 * cnt)+'px';
              cnt++;
            }else if(this.windowWidth < 1024 && this.windowWidth > 768){
              e.popupLocaleLeft = '50%';
              e.popupLocaleMarginLeft = '-20%';
            }
            

            e.pop_basic_path = this.pop_basic_path;
            e.pop_url_path = this.pop_url_path;

            if(checkPopupArr){
              if(checkPopupArr.filter((f) => f.popupKey == e.bbst_sno).length > 0) {
                e.dup = 'Y';
              }
            }

          })

          popupList = popupList.filter((e) => !e.dup);
          this.popupList = popupList;
        
        }).catch(
          function (error) {
            console.log(error);
          }
        )
      },//getMainData end
      moveDate(clickDate) {

        const formData = new FormData();
        formData.append("clickDate",clickDate);

        this.$http.post("/home/moveDate",formData).then((res) => {
          this.dateMap = res.data.dateMap;
          this.scdulList = res.data.scdulList;

          this.scdulList.forEach((e) => {
            let click_mon = Number(clickDate.substr(4,2));
            let bgn_mon = Number(e.scdul_bgn_dt.replace('/','').substr(0,2));
            let end_mon = Number(e.scdul_end_dt.replace('/','').substr(0,2));
            let bgn_day = Number(e.scdul_bgn_dt.replace('/','').substr(2,2));
            let end_day = Number(e.scdul_end_dt.replace('/','').substr(2,2));

            if(bgn_mon == click_mon) {
              e.scdul_bgn_day_num = bgn_day;
            }

            if(end_mon == click_mon) {
              e.scdul_end_day_num = end_day;
            }

            if(bgn_mon < click_mon) {
              e.scdul_bgn_day_num = 1;
            }

            if(end_mon > click_mon) {
              e.scdul_end_day_num = this.dateMap.lastdd;
            }
            
          })

          this.dateMap.lastdd = Number(this.dateMap.lastdd);

        }).catch(
          function (error) {
            console.log(error);
          }
        )

      },//moveDate end
      goPage(menuSno) {
        if(this.scdulAuth == 'Y') {
          const url_path = this.menuList.filter((e) => e.menu_sno == menuSno).map((f) => f.url_path);
          this.$router.push({path: url_path[0]});
        } else {
          alert('권한이 없습니다.');
          return;
        }
        
      },
      goPopupDetail(){
        console.log("hello pather");
      },
      
      loginBtnClicked() {
        this.$router.push('/login');
      },
      logoutBtnClicked() {
        if(confirm('로그아웃 하시겠습니까?')) {
          this.$session.set('mberSno','');
          this.$session.set('tempPwdYn','N');
          this.$session.set('nonMberYn','');
          this.$router.go(0);
        }
      },
      subBoard1Clicked(no){
        if(no == 1) {
          this.board_L1 = true;
          this.board_L2 = false;
        } else if(no == 2) {
          this.board_L1 = false;
          this.board_L2 = true;
        }
        this.subBoard1_Bol = !this.subBoard1_Bol;
      },
      subBoard2Clicked(no){
        if(no == 1) {
          this.board_R1 = true;
          this.board_R2 = false;
        } else if(no == 2) {
          this.board_R1 = false;
          this.board_R2 = true;
        }
        this.subBoard2_Bol = !this.subBoard2_Bol;
      },
      getPoinColor() {
        this.$http.get("/common/getMainPointColor").then((res) => {
          const mainPointColor = res.data.mainPointColor;
          if(mainPointColor) {
            this.point_color_2 = mainPointColor.point_color_2;
            this.point_color_3 = mainPointColor.point_color_3;
            this.point_color_4 = mainPointColor.point_color_4;
            this.font_color_2 = mainPointColor.font_color_2;
            this.font_color_4 = mainPointColor.font_color_4;
            this.font_color_5 = mainPointColor.font_color_5;
            this.font_color_6 = mainPointColor.font_color_6;
          }
          
          this.getSoctyImg();
          

        }).catch(
          function (error) {
            console.log(error);
          }
        )
      },
      subBoardPlusClicked(no) {
        if(no == 1) {
          if(this.board_L1) {
            if(this.ntceBoxL1Auth == 'N') {
              alert('권한이 없습니다.');
            } else if(this.ntceBoxL1Data.basic_path) {
              this.$router.push(this.ntceBoxL1Data.basic_path + '/list');
            }
          } else if(this.board_L2) {
            if(this.ntceBoxL2Auth == 'N') {
              alert('권한이 없습니다.');
            } else if(this.ntceBoxL2Data.basic_path) {
              this.$router.push(this.ntceBoxL2Data.basic_path + '/list');
            }

          }
        } else if(no == 2) {
          if(this.board_R1) {
            if(this.ntceBoxR1Auth == 'N') {
              alert('권한이 없습니다.');
            } else if(this.ntceBoxR1Data.basic_path) {
              this.$router.push(this.ntceBoxR1Data.basic_path + '/list');
            }
          } else if(this.board_R2) {
            if(this.ntceBoxR2Auth == 'N') {
              alert('권한이 없습니다.');
            } else if(this.ntceBoxL2Data.basic_path) {
              this.$router.push(this.ntceBoxR2Data.basic_path + '/list');
            }

          }
        }
        
      },//subBoardPlusClicked end
      subBoardListClicked(no, bbst_sno) {
        if(no == 1) {
          if(this.ntceBoxL1Auth == 'N') {
            alert('권한이 없습니다.');
          } else if(this.ntceBoxL1Data.basic_path) {
            this.$router.push(this.ntceBoxL1Data.basic_path+`/detail?bbst_sno=`+bbst_sno);
          }
        } else if(no == 2) {
          if(this.ntceBoxL2Auth == 'N') {
            alert('권한이 없습니다.');
          } else if(this.ntceBoxL2Data.basic_path) {
            this.$router.push(this.ntceBoxL2Data.basic_path+`/detail?bbst_sno=`+bbst_sno);
          }
        } else if(no == 3) {
          if(this.ntceBoxR1Auth == 'N') {
            alert('권한이 없습니다.');
          } else if(this.ntceBoxR1Data.basic_path) {
            this.$router.push(this.ntceBoxR1Data.basic_path+`/detail?bbst_sno=`+bbst_sno);
          }
        } else if(no == 4) {
          if(this.ntceBoxR2Auth == 'N') {
            alert('권한이 없습니다.');
          } else if(this.ntceBoxR2Data.basic_path) {
            this.$router.push(this.ntceBoxR2Data.basic_path+`/detail?bbst_sno=`+bbst_sno);
          }
        }
        
      },//subBoardListClicked END
      preNextClicked(no) {
        const adLen = this.adList.filter((e) => e.main_ad_yn == 'N' && e.disp_yn == 'Y').length;
        if(no == 1) {
          if(this.adNtceOdr == 0) {
            this.adNtceOdr = adLen-1;
          } else {
            this.adNtceOdr = this.adNtceOdr-1;
          }
        } else {
          if(this.adNtceOdr == adLen-1) {
            this.adNtceOdr = 0;
          } else {
            this.adNtceOdr = this.adNtceOdr+1;
          }
        }

      },//preNextClicked end
      stcutClicked(item) {
        if(item.stcut_type == 'url') {
          if(item.stcut_url) {
            window.open(item.stcut_url);
          }
        } else {

          if(item.get_perm_yn == 'Y') {
            if(item.page_type == 'url_link') {
              window.open(item.url_path);
            } else {
              this.$router.push(item.url_path);
            }
            
          } else {
            alert('권한이 없습니다.');
            return;
          }

        }
        
        
      },//stcutClicked end
      ntceRollClicked(item) {
        if(this.mainImgNtceAuth != 'Y') {
          alert('권한이 없습니다.');
          return;
        } else {
          this.$router.push(this.mainImgNtceMap.url_path+`/detail?bbst_sno=`+item.bbst_sno);
        }
        console.log(item);
      },//ntceRollClicked end
      ntceBbsClicked(no) {

        const mainNtceLen = this.mainImgNtceList.length;
        if(no == 1) {
          if(this.ntceBbsOdr == 0) {
            this.ntceBbsOdr = mainNtceLen-1;
          } else {
            this.ntceBbsOdr = this.ntceBbsOdr-1;
          }
        } else {
          if(this.ntceBbsOdr == mainNtceLen-1) {
            this.ntceBbsOdr = 0;
          } else {
            this.ntceBbsOdr = this.ntceBbsOdr+1;
          }
        }
      },
      sponsorClicked() {
        if(this.soctySpnsrMap.get_perm_yn == 'Y') {
          this.$router.push(this.soctySpnsrMap.url_path);
        } else {
          alert('권한이 없습니다.');
          return;
        }
        
      },//sponsorClicked end
      mainAdClicked() {
        console.log('메인광고 이미지 클릭');
        //window.open(this.soctySpnsrMap.url_path, '_blank')
      },//mainAdClicked end
      subAdClicked() {
        console.log('서브 광고 이미지 클릭');
      },//mainAdClicked end
      newstBtnClicked(item) {
        if(item.book_newst_url_mber_only_yn == 'Y' && !this.mberSno) {
          if(confirm('회원 전용입니다. 로그인하시겠습니까?')) {
            this.$router.push('/login');
          }
        } else {
          window.open(item.book_newst_url);
        }
      },//newstBtnClicked end
      regiSysBtnClicked(item) {
        
          window.open(item.sbmt_sys_url);
        
      },//newstBtnClicked end
      getSoctyImg() {
        this.$http.post("/admin/getSoctyImg").then((res) => {
          console.log("이미지스: ",res.data.result);
          if(res.data.result[0]) {
            this.imgMap = res.data.result[0];
          }
          

          this.viewYn = true;
        }).catch(function (error) {
            console.log(error);
          }
        );

      },
    },
  }
</script>

<style scoped>
.hero {
  background-size: cover;
  height: 560px;
}

.clickedColor{ background-color: blue;}

.active::after{
  background-color: #2a325f !important;
  color: #2a325f !important;
  opacity: 0;
  fill-opacity: 1;
}
.v-btn-toggle .v-btn .v-btn{
  color:red !important;
  background-color: red !important;
}

.v-btn-toggle > .v-btn.v-btn--active{
  color: #2a325f !important;
  
}

.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before{
  opacity: 1;
}

.btnTest1{
  background-color:var(bgCode)
}

.bookWord {
  max-height: 75px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
  font-family: dreamFont4;
}

.notiWord {
  display:block; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  word-break: break-all;
}

.btnWord {
  display:block; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  word-break: break-all;
  width:100px;
}

.btnWord2 {
  max-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.mobRollingWord {
  width: 350px; 
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  }

.popupLocale {
  z-index: 999;
    position: absolute;
    top: 1px;
    width: 600px;
    height: auto;
}

.dreamFont2{
  font-family: dreamFont2 !important;
}

.dreamFont3{
  font-family: dreamFont3 !important;
}

.dreamFont4{
  font-family: dreamFont4 !important;
}

.boardBookBox {
  background-color: rgba(255, 255, 255, 0.8);
  border: 10px solid rgba(255, 255, 255, 0);
  border-radius: 20px;
  margin-right: 10px !important;
}

.bookBoxbtn {
  font-family: dreamFont4 !important; 
  margin-left: 8px; 
  padding: 4px !important;  
  background-color: rgb(255, 255, 255) !important;
}

.v-list-item__title > a {
  font-family: dreamFont3 !important; 
}

h2 {
  font-family: dreamFont4 !important;
}
</style>