<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense >
          <v-col cols="12">
            <!-- <div class="f-r pl-1">
              <v-btn
                small
                color="blue lighten-2"
                class="white--text"
                @click="saveBtnClicked"
              >저장</v-btn>
            </div> -->
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div>
              <v-data-table
                :headers="headers"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination>
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.edubg_sno`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.edubg_sno"
                    hide-details
                    outlined
                    dense
                    hidden
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.dgree_div`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.dgree_div"
                    :items="dgreeDivList"
                    item-text="cmn_cd_nm"
                    item-value="cmn_cd"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.schl_nm`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.schl_nm"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.schl_nm_en`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.schl_nm_en"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.major_nm`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.major_nm"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.major_nm_en`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.major_nm_en"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.grdtn_year`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.grdtn_year"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ["propParams"],
  data(){
    return {
      headers:[
        { text: '선택', value:'select_yn', width:"5%"},
        { text: '학위', value:'dgree_div', width:"10%"},
        { text: '출신학교(한글)', value:'schl_nm', width:"15%"},
        { text: '출신학교(영문)', value:'schl_nm_en', width:"20%"},
        { text: '전공(한글)', value:'major_nm', width:"15%"},
        { text: '전공(영문)', value:'major_nm_en', width:"20%"},
        { text: '졸업년도', value:'grdtn_year', width:"10%"},
      ],
      dgreeDivList: [],
      gridList: [],
      changedList: [],
      mberSno: "",
      loginMberSno: "",   // 수정 처리자
    }
  },
  created() {
    this.mberSno = this.propParams.mberSno;
    this.loginMberSno = this.$session.get("mberSno");

    this.getDgreeDivList();
    this.getMberEdubgList();
  },
  methods: {
    async getDgreeDivList() {
      this.dgreeDivList = await this.$getCmnCdList("dgree_div");
      console.log("dgreeDivList: ", this.dgreeDivList);
    },
    // 학력 수정됨
    gridItemChanged(item) {
      this.itemChanged();
      // console.log("gridItemChanged : item.edubg_sno : " + item.edubg_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.edubg_sno == null || item.edubg_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.edubg_sno == item.edubg_sno) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }
    },
    getMberEdubgList() {
      if(this.mberSno == null) {
        return;
      }

      const params = new FormData();
      params.append("mber_sno", this.mberSno);

			this.$http.post("/admin/getMberEdubgList", params).then((res) => {
        this.gridList = res.data.result;
        // console.log("gridList: " + this.gridList);
        this.changedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    deleteBtnClicked() {
      console.log("deleteEdubg");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: edubg_sno[" + e.edubg_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteMberEdubgList", params).then((res) => {
            console.log(res.data);
            // this.getMberEdubgList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getMberEdubgList();
          this.gridList = this.gridList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;

      this.gridList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.gridList.push({new_yn:true, select_yn:false, edubg_sno:"", mber_sno:this.mberSno, odr:maxOdr, dgree_div:"", schl_nm:"", schl_nm_en:"", 
                          major_nm:"", major_nm_en:"", grdtn_year:"", last_dgree_yn:false});
      this.changedList.push(this.gridList[this.gridList.length - 1]);
      // 객체 복사가 필요하면 아래의 방법으로 해야 함
      //this.changedList.push({...this.gridList[this.gridList.length - 1]}); 
      // 객체 안에 또 객체가 있을 경우 아래의 방법으로 해야 함
      // JSON.parse(JSON.stringify(item))

      // 데이터 검증
      // this.changedList.forEach((e) => {
      //   console.log("changedList: addr_sno[" + e.addr_sno + "]");
      // });
    },
    saveBtnClicked() {
      console.log("saveEdubg");

      if(this.changedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      // true/false -> 'Y'/'N' 변환
      this.changedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
        e.last_dgree_yn = e.last_dgree_yn == true ? 'Y' : 'N';
      });

      // if(confirm("저장하시겠습니까?")) {
        let params = new URLSearchParams();

        params.append("login_mber_sno", this.loginMberSno);
        params.append("changedList", JSON.stringify(this.changedList));

        this.$http.post("/admin/upsertMberEdubgList", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          this.getMberEdubgList();
        }).catch(function (error) {
            console.log(error);
        });
      // }
    },
    itemChanged() {
      this.$emit('tabChanged', "educationTab");
    }
  }
}
</script>

<style>

</style>