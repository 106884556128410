<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <!-- start -->
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>하단정보1</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              class="wid-100 pt-0"
              hide-details
              outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->
          <!-- end -->
          <!-- start -->
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>하단정보2</span>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="3"
                hide-details
                outlined>
              </v-textarea>
            </div>
          </v-col> -->
          <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
          <!-- end -->
          <!-- [[[[[ start ]]]]] -->
          <v-col cols="12" class="mt-5">
            <h2>연회비 청구서</h2>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>수신/제목</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="3"
                hide-details
                outlined
                v-model="anulMap.bill_recv_text"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>안내글 1</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="10"
                hide-details
                outlined
                v-model="anulMap.bill_guide_text_1"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2">
            <span>회원별 연회비표시 적용여부</span>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              hide-details
              dense
              label="적용"
              v-model="anulMap.bill_anul_fee_disp_yn"
              @change="itemChanged"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글 1</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="anulMap.bill_ref_text_1"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>안내글 2</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="anulMap.bill_guide_text_2"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글 2</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="anulMap.bill_ref_text_2"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- [[[[[ end ]]]]] -->

          <!-- [[[[[ entr start ]]]]] -->
          <v-col cols="12" class="mt-5">
            <h2>입회비 청구서</h2>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>수신/제목</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="3"
                hide-details
                outlined
                v-model="entrMap.bill_recv_text"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>안내글 1</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="10"
                hide-details
                outlined
                v-model="entrMap.bill_guide_text_1"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2">
            <span>회원별 연회비표시 적용여부</span>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              hide-details
              dense
              label="적용"
              v-model="entrMap.bill_anul_fee_disp_yn"
              @change="itemChanged"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글 1</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="entrMap.bill_ref_text_1"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>안내글 2</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="entrMap.bill_guide_text_2"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글 2</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="entrMap.bill_ref_text_2"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- [[[[[ end ]]]]] -->

          <!-- [[[[[ thes start ]]]]] -->
          <v-col cols="12" class="mt-5">
            <h2>논문비 청구서</h2>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>수신/제목</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="3"
                hide-details
                outlined
                v-model="thesMap.bill_recv_text"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>안내글 1</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="10"
                hide-details
                outlined
                v-model="thesMap.bill_guide_text_1"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2">
            <span>회원별 연회비표시 적용여부</span>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              hide-details
              dense
              label="적용"
              v-model="thesMap.bill_anul_fee_disp_yn"
              @change="itemChanged"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글 1</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="thesMap.bill_ref_text_1"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>안내글 2</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="thesMap.bill_guide_text_2"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          
          <!-- start -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>참고글 2</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100">
              <v-textarea
                class="wid-100 pt-0 pr-0"
                rows="2"
                hide-details
                outlined
                v-model="thesMap.bill_ref_text_2"
                @change="itemChanged"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- end -->
          <!-- [[[[[ end ]]]]] -->
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      entrMap: {},
      anulMap: {},
      thesMap: {},
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
    }
  },
  created() {
    
    this.$http.get("/admin/getSoctybillFormSett").then((res) => {
      console.log("res.data: ", res.data);
      const billList = res.data.billList;
      billList.forEach((e) => {
        e.bill_anul_fee_disp_yn = e.bill_anul_fee_disp_yn == 'Y' ? true : false;
      });
      if(billList) {
        this.entrMap = billList.filter((e) => e.bill_kind == 'entr').length > 0 ? billList.filter((e) => e.bill_kind == 'entr')[0] : {};
        this.anulMap = billList.filter((e) => e.bill_kind == 'anul').length > 0 ? billList.filter((e) => e.bill_kind == 'anul')[0] : {};
        this.thesMap = billList.filter((e) => e.bill_kind == 'thes').length > 0 ? billList.filter((e) => e.bill_kind == 'thes')[0] : {};
      }
      // entr	입회비
      // anul	연회비
      // thes	논문회비
    }).catch(function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    itemChanged() {
      this.$emit('tabChanged', 'billTab');
    },
    saveBtnClicked() {
      console.log('bill save');
      // this.entrMap = {};
      // this.anulMap = {};
      // this.thesMap = {};
      // console.log('billTab save');
      // let billList = [];
      // if(this.entrMap) {
      //   billList.push(this.entrMap);
      // }
      // if(this.anulMap) {
      //   billList.push(this.anulMap);
      // }
      // if(this.thesMap) {
      //   billList.push(this.thesMap);
      // }
      // console.log("tessss :: ",billList);
      const params = new FormData();
      params.append('anulMap' , JSON.stringify(this.anulMap));
      params.append('entrMap' , JSON.stringify(this.entrMap));
      params.append('thesMap' , JSON.stringify(this.thesMap));
      params.append('mber_sno' , this.mberSno);

      this.$http.post("/admin/upsertSoctybillFormSett",params).then((res) => {
        console.log("res.data: ", res.data);
        
      }).catch(function (error) {
          console.log(error);
        }
      );

    },//saveBtnClicked end
  }
}
</script>

<style scoped>
.v-input--selection-controls{
  padding-top: 0px !important;
}
.v-input--checkbox.v-input--dense{
  margin-top: 0px !important;
}

/deep/.v-label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>