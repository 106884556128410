<template>
  <v-container class="pa-3">
    <v-row class="pa-0 mb-10" >
      <v-col cols="12" class="pa-0 d-flex align-center">
        <h1 class="mr-3">청구서</h1>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
      <v-col cols="12" class="pa-0" >
        <v-data-table
          :headers="prod_header"
          :items="mberBillList"
          class="elevation-0"
          :items-per-page="5"
          mobile-breakpoint="0">
          <template v-slot:[`item.reg_dtm`]="{item}">
            <span class="hidden-md-and-down">{{ item.reg_dtm }}</span>
            <span class="hidden-md-and-up">{{ item.mob_reg_dtm }}</span>
          </template>
          <template v-slot:[`item.download`]="{ item }">
            <div> 
              <v-btn class="hidden-xs-only" small @click="pdfDownClicked(item)">내려받기</v-btn>
              <v-btn class="hidden-sm-and-up" color="primary" small icon @click="pdfDownClicked(item)"><v-icon>mdi-cloud-download</v-icon></v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="pa-0 mb-10" >
      <v-col cols="12" class="pa-0 d-flex align-center">
        <h1 class="mr-3">납부확인서</h1>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
      <v-col cols="12" class="pa-0" >
        <v-data-table
          :headers="pay_header"
          class="elevation-0"
          :items="mberPayList"
          :items-per-page="5"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.pay_dt`]="{item}">
            <span class="hidden-md-and-down">{{ item.pay_dt }}</span>
            <span class="hidden-md-and-up">{{ item.mob_pay_dt }}</span>
          </template>
          <template v-slot:[`item.download`]="{ item }">
            <div>
              <v-btn class="hidden-xs-only" small @click="pdfDownClicked(item)">내려받기</v-btn>
              <v-btn class="hidden-sm-and-up" color="primary" small icon @click="pdfDownClicked(item)"><v-icon>mdi-cloud-download</v-icon></v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="pa-0 mb-10" >
      <v-col cols="12" class="pa-0 d-flex align-center">
        <h1 class="mr-3">증명서</h1>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
      <v-col cols="12" class="pa-0" >
        <v-data-table
          :headers="hist_header"
          class="elevation-0"
          :items="mberHistList"
          :items-per-page="5"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.status`]>
            <div>발급가능</div>
          </template>
          <template v-slot:[`item.download`]="{ item }">
            <div>
              <v-btn class="hidden-xs-only" small @click="pdfDownClicked(item)">내려받기</v-btn>
              <v-btn class="hidden-sm-and-up" color="primary" small icon @click="pdfDownClicked(item)"><v-icon>mdi-cloud-download</v-icon></v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      prod_header: [
        { text: '번호', value: 'mber_prod_sno', class: 'my-header-style', align: ' d-none d-sm-table-cell'},
        { text: '항목', value: 'prod_type_nm', class: 'my-header-style'},
        { text: '내역', value: 'hist', class: 'my-header-style', align: ' d-none d-sm-table-cell'},
        { text: '금액(원)', value: 'prod_amt_fmt', class: 'my-header-style'},
        { text: '청구일시', value: 'reg_dtm', class: 'my-header-style', align: ' d-none d-sm-table-cell'},
        { text: '', value: 'download', class: 'my-header-style'},
      ],
      pay_header: [
        { text: '번호', value: 'bill_sno', class: 'my-header-style' , align: ' d-none d-sm-table-cell'},
        { text: '항목', value: 'prod_type_nm', class: 'my-header-style'},
        { text: '내역', value: 'hist', class: 'my-header-style', align: ' d-none d-sm-table-cell'},
        { text: '금액(원)', value: 'prod_amt_fmt', class: 'my-header-style'},
        { text: '발급일시', value: 'pay_dt', class: 'my-header-style', align: ' d-none d-sm-table-cell'},
        { text: '', value: 'download', class: 'my-header-style'},
      ],
      hist_header: [
        { text: '번호', value: 'hist_sno', class: 'my-header-style', align: ' d-none d-sm-table-cell'},
        { text: '상태', value: 'status', class: 'my-header-style', align: ' d-none d-sm-table-cell'},
        { text: '항목', value: 'crtf_form_nm', class: 'my-header-style'},
        { text: '기간', value: 'period', class: 'my-header-style', align: ' d-none d-sm-table-cell'},
        { text: '', value: 'download', class: 'my-header-style'},
      ],
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      mberBillList: [],
      mberPayList: [],
      mberHistList: [],
		
    }
  },
  created() {
    this.getCertificate();
  },
  methods: {
    // mobileFormDt(str){
    //   const date = new Date(str)
    //   const hours = ('0' + date.getHours()).slice(-2)
    //   const minutes = ('0' + date.getMinutes()).slice(-2)
    //   return `${hours}:${minutes}`
    // },  
    getCertificate() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);

      function mobileFormDt(str) {
        const date = new Date(str)
        const years = (date.getFullYear())
        const months = ((date.getMonth() + 1) < 10 ?  '0' +(date.getMonth() + 1) :'' + (date.getMonth() + 1))
        const dates = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
        return `${years}-${months}-${dates} `
      }

      this.$http.post('/mber/getCertificate', params).then((res) => {

        console.log("res :;;",res.data);
        //청구서
        this.mberBillList = res.data.mberBillList;
        this.mberBillList.forEach((e) => {
          e.prod_amt_fmt = e.prod_amt.toLocaleString('ko-KR');
          e.mob_reg_dtm = mobileFormDt(e.reg_dtm);
        })
        //납부확인서
        this.mberPayList = res.data.mberPayList;
        this.mberPayList.forEach((e) => {
          e.prod_amt_fmt = e.prod_amt.toLocaleString('ko-KR');
          e.mob_pay_dt = mobileFormDt(e.pay_dt);
        })
        //증명서
        this.mberHistList = res.data.mberHistList;
        this.mberHistList.forEach((e) => {
          const bgn_dt = e.bgn_dt ? e.bgn_dt : '';
          let end_dt = e.end_dt;
          if(!end_dt) {
            end_dt = '현재';
          }
          e.period = bgn_dt + ' ~ ' + end_dt;
        })

        
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },
    pdfDownClicked(item) {
      // const url = window.URL.createObjectURL(item.file_url);
      const aTag = document.createElement('a');
      aTag.href = item.file_url;
      aTag.download = item.orgnl_file_nm;
      aTag.click();
      
      const params = new FormData();
      params.append("doc_no", item.doc_no);

      this.$http.post('/sub/updateIssueDoc', params).then((res) => {

        console.log("res : ",res.data);
        
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//pdfDownClicked end
  },

}
</script>

<style scoped>


  /* /deep/.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 13px !important;
  } */ 

  .text-start {
    font-size: 13px !important;
    padding: 13px 6px 13px 6px !important;
    height: 100% !important;
  }

</style>