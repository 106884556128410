<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12">
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >광고 추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>

          <v-col cols="12" v-for="item in adList" :key="item.odr">
            <v-row dense align="center">

              <!-- 광고 1 start -->
              <v-col cols="12">
                <v-checkbox
                  v-model="item.select_yn"
                  :label=item.ad_no
                  hide-details
                  dense
                ></v-checkbox>
                <!-- <h3>광고 {{ item.odr }}</h3> -->
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
              <v-col cols="2" >
                <div class="pt-0">
                  <span>메인 광고 여부</span>
                </div>
              </v-col>
              <v-col cols="10" class="pb-3">
                <v-checkbox
                  v-model="item.main_ad_yn_scr"
                  label="메인 광고로 사용함 (체크된 경우 메인 이미지 영역의 메인 광고로, 체크되지 않은 경우 알림존 영역의 광고로 표시됩니다)"
                  hide-details
                  dense
                  @change="listItemChanged(item)"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
              <v-col cols="2" >
                <div class="pt-0">
                  <span>광고 노출</span>
                </div>
              </v-col>
              <v-col cols="10" class="pb-3">
                <v-checkbox
                  v-model="item.disp_yn_scr"
                  label="노출함"
                  hide-details
                  dense
                  @change="listItemChanged(item)"
                ></v-checkbox>
              </v-col>
              <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
              <v-col cols="2">
              </v-col>
              <v-col cols="10">
                <v-checkbox 
                  v-model="item.open_as_new_yn_scr"
                  label="새 창으로 열기"
                  hide-details
                  dense
                  @change="listItemChanged(item)"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
              <!-- <v-col cols="2" >
                <div class="">
                  <span>광고 표시 순서</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="item.disp_odr"
                  class="wid-100"
                  hide-details
                  dense 
                  outlined
                  @change="listItemChanged(item)"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col> -->
              <v-col cols="2">
                <div class="">
                  <span>광고 이미지</span>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="wid-100">
                  <v-file-input
                    v-model="item.adImgSelectedFile"
                    placeholder="File input"
                    hide-details
                    dense
                    @change="listItemChanged(item)"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col cols="3" v-if="item.ad_img_orgnl_file_nm">
                <div class="wid-100 pt-0">
                  <div class="f-l pt-2 pl-5 font-1" style="color:white;">
                    <span><a :href="item.ad_img_path" target="_blank">{{item.ad_img_orgnl_file_nm}}</a></span>
                  </div>
                  <v-btn
                    small
                    color="red lighten-2"
                    class="pa-0 ma-0 white--text f-r"
                    hide-details
                    @click="deleteImgBtnClicked(item.odr, item.ad_img_path, item.ad_img_atch_sno)"
                    >삭제</v-btn>
                </div>
              </v-col>
              <v-col cols="4">
                <span class="pl-2 text-red">이미지 사이즈는 "메인광고"일 경우 825x400 (pixel) "알림존"일 경우 525x300 (pixel) 사이즈를 권장합니다.</span>
              </v-col>
              <!-- <v-col cols="7">
                <div class="">
                  <v-btn
                    small
                    color="red lighten-2"
                    class="white--text"
                  >삭제</v-btn>
                </div>
              </v-col> -->
              <v-col cols="12"><v-divider></v-divider></v-col>
              <v-col cols="2" >
                <div class="">
                  <span>광고 URL</span>
                </div>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="item.ad_url"
                  class="wid-100"
                  hide-details
                  dense 
                  outlined
                  @change="listItemChanged(item)"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
              <!-- 광고 1 end -->

            </v-row>
          </v-col>

          <!-- <v-col cols="12">
            <div class="pt-3">
              <v-btn
                small
                color="blue lighten-2"
                class="white--text"
              >광고 추가</v-btn>
            </div>
          </v-col> -->

        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data(){
    return{
      adList: [],
      changedList: [],
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
    }
  },
  created() {

    this.getSoctyAdList();
  },
  methods: {
    // 광고 수정됨
    listItemChanged(item) {
      this.itemChanged();
      console.log("listItemChanged: ", item);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.socty_sno == null || item.socty_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.socty_sno == item.socty_sno && e.odr == item.odr) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }
    },
    getSoctyAdList() {
      this.$http.get("/admin/getSoctyAdList").then((res) => {
        // console.log("res.data.result:", res.data.result);
        res.data.result.adList.forEach((e, i) => {
          e.select_yn = false;
          e.main_ad_yn_scr = e.main_ad_yn == 'Y' ? true : false;
          e.open_as_new_yn_scr = e.open_as_new_yn == 'Y' ? true : false;
          e.disp_yn_scr = e.disp_yn == 'Y' ? true : false;
          e.idx = i + 1;
          e.ad_no = "광고 " + e.idx;
        });

        this.adList = res.data.result.adList;
        // console.log("adList:", this.adList);
        this.changedList = [];
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },
    deleteBtnClicked() {
      console.log("deleteSoctyAd");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.adList.length; i++) {
      //   if(this.adList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.adList[i].new_yn) {
      //       deleteList.push(this.adList[i]);
      //     }
      //   }
      // }
      deleteList = this.adList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: addr_sno[" + e.addr_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteSoctyAdList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getSoctyAdList();
            this.adList = this.adList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getSoctyAdList();
          this.adList = this.adList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;
      let maxIdx = 0;

      this.adList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }

        if(e.idx > maxIdx) {
          maxIdx = e.idx;
        }
      });

      maxOdr++;
      maxIdx++;

      this.adList.push({new_yn:true, select_yn:false, socty_sno:null, odr:maxOdr, idx:maxIdx, ad_url:null, main_ad_yn_scr:false, disp_yn_scr:false, open_as_new_yn_scr:true, ad_no:"광고 " + maxIdx,
                          ad_img_atch_sno:null, ad_img_path:null, ad_img_orgnl_file_nm:null, adImgSelectedFile: null});
      this.changedList.push(this.adList[this.adList.length - 1]);
    },
    saveBtnClicked() {
      console.log("saveSoctyAd");

      if(this.changedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      const file_info_list = [];

      // if(confirm("저장하시겠습니까?")) {
        let params = new FormData();

        // true/false -> 'Y'/'N' 변환
        this.changedList.forEach((e) => {
          e.new_yn = e.new_yn == true ? 'Y' : 'N';
          e.main_ad_yn = e.main_ad_yn_scr == true ? 'Y' : 'N';
          e.disp_yn = e.disp_yn_scr == true ? 'Y' : 'N';
          e.open_as_new_yn = e.open_as_new_yn_scr == true ? 'Y' : 'N';

          // 첨부 이미지
          if(e.adImgSelectedFile != null) {
            // console.log("file attached:", e.adImgSelectedFile);
            params.append('file_list', e.adImgSelectedFile);
            file_info_list.push({odr:e.odr});
          } 
        });

        params.append("mber_sno", this.mberSno);
        params.append("changedList", JSON.stringify(this.changedList));

        params.append("file_info_list", JSON.stringify(file_info_list));

        this.$http.post("/admin/upsertSoctyAdList", params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getSoctyAdList();
        }).catch(function (error) {
            console.log(error);
        });
      // }
    },
    deleteImgBtnClicked(odr, filePath, fileAtchSno) {
      if(confirm("삭제하시겠습니까?")) {
        // console.log("filePath:", filePath);
        // console.log("atchSno:", fileAtchSno);
        
        if(filePath == null) {
          alert("삭제할 파일이 없습니다.");
          return;
        }

        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("odr", odr);
        params.append("file_path", filePath);
        params.append("file_atch_sno", fileAtchSno);

        this.$http.post("/admin/deleteSoctyAdFile", params).then((res) => {
          console.log(res.data);
          alert("삭제되었습니다.");
          
          this.getSoctyAdList();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'advertiseTab');
    },
  }
}
</script>

<style>

</style>