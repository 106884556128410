<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center">
          <v-col cols="3">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="searchWord" 
              outlined
              dense
              hide-details
              placeholder="검색어"
              background-color="white">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <!-- <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text" 
              @click="searchBtnClicked"
              >검색
            </v-btn> -->
          </v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addPostBtnClicked"
              >수록기사 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1"
              :search="searchWord"
            >
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <template v-slot:[`item.book_ttl`]="{item}">
                <v-text-field
                  v-model="item.book_ttl"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="rowClicked(item)"
                ></v-text-field>
              </template>
              <template v-slot:[`item.artcl_ttl`]="{item}">
                <v-text-field
                  v-model="item.artcl_ttl"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="rowClicked(item)"
                ></v-text-field>
              </template>
              <template v-slot:[`item.vol`]="{item}">
                <v-text-field
                  v-model="item.vol"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="rowClicked(item)"
                ></v-text-field>
              </template>
              <template v-slot:[`item.publc_dt`]="{item}">
                <v-text-field
                  v-model="item.publc_dt"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="rowClicked(item)"
                ></v-text-field>
              </template>
              <template v-slot:[`item.disp_tf`]="{ item }">
                <v-simple-checkbox
                  v-model="item.disp_tf"
                  hide-details
                  disabled
                  >
                </v-simple-checkbox>
              </template>
              
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.publcArtclList.radioLang"
              >
                <template v-slot:label>
                  <div><span>언어로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="한국어"
                  value="ko"
                ></v-radio>
                <v-radio
                  label="영어"
                  value="en"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      // mberChkDialog: false,
      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%", sortable:false },
        { text: '도서명', value: 'book_ttl', width: "23%" },
        { text: '논문명', value: 'artcl_ttl', width: "40%" },
        { text: '권호', value: 'vol', width: "15%" },
        { text: '발생일자', value: 'publc_dt', width: "10%" },
        { text: '게시', value: 'disp_tf', width: "7%" },
      ],
      // gridList: [],
      publcArtclList: [],

      searchWord: null,
      menuSno: this.$route.query.menuSno,
      menuNm: this.$route.query.menuNm,
    }
  },
  computed: {
    gridList() {
      if(!this.publcArtclList)  return [];

      return this.publcArtclList.filter((e) => 
        ( (this.$store.state.publcArtclList.lang == "모두" || e.lang == this.$store.state.publcArtclList.lang) 
        )
      );
    }
  },
  created() {
    // this.menuSno = this.$route.query.menuSno;
    console.log("publcArtclList created:", this.menuSno);
  },
  mounted(){
    this.getPublcArctlList();
  },
  methods: {
    getPublcArctlList() {
      const params = new FormData();

      params.append("menu_sno", this.menuSno);

      this.$http.post("/admin/getPublcArtclList", params).then((res) => {
        console.log("res.data.result:", res.data.result);

        if(res.data.result.publcArtclList) {
          res.data.result.publcArtclList.forEach((e) => {
            e.select_yn = false;
            e.disp_tf = e.disp_yn == "Y" ? true : false;
          });

          this.publcArtclList = res.data.result.publcArtclList;
        }
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    rowClicked(event) {
      console.log("rowClicked", event);
      this.$router.push({name:"publcArtclDetail", query: { menuSno:this.menuSno, menuNm: this.menuNm, artclSno:event.artcl_sno, newYn:'N' }});
    },
    // 게시글 추가 버튼 클릭
    addPostBtnClicked() {
      this.$router.push({name:"publcArtclDetail", query: { menuSno:this.menuSno, menuNm: this.menuNm, artclSno:null, newYn:'Y' }});
    },
    deleteBtnClicked() {
      console.log("deletePublcArtclList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          console.log("deleteList:", deleteList);
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deletePublcArtclList", params).then((res) => {
            console.log(res.data);

            if(res.data.code == 0) {
              alert("삭제되었습니다.");
              this.publcArtclList = this.publcArtclList.filter((e) => {
                return !(e.select_yn);
              });
            } else {
              alert("삭제할 수 없습니다.");
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    
  }
}
</script>
  
<style scoped>

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 13px;
  padding: 2px 16px 0px 16px;  
  max-width: 100% !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > .text-start {
  font-size: 13px;
  padding: 0px 16px 0px 16px;  
  max-width: 100% !important;
}

::v-deep .v-input__slot {
  font-size: 13px; 
  padding: 0px !important;
  max-width: 100% !important;
}

::v-deep .white > .v-text-field__slot {
  font-size: 13px;
  padding-left: 12px; 
}

</style>