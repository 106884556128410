<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="gotoList"
          >목록</v-btn>
        </div>
        <div>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn 
            small 
            color="red lighten-2" 
            class="white--text f-r" 
            @click="deleteBtnClicked"
            v-if="newYn == 'N'"
            >학회 일정 삭제
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense align="center">
      <v-col cols="12"><v-divider></v-divider></v-col>

      <!-- 등록자 -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>등록자 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingScdul.reg_mber_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
          readonly
          dense
          append-icon="mdi-magnify"
          @click="searchMberBtnClicked"
          @click:append="searchMberBtnClicked"
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>제목 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingScdul.scdul_ttl"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>제목(영어)</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingScdul.scdul_ttl_en"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 내용 -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>내용</span>
        </div>
      </v-col>
      <v-col cols="8">
        <v-textarea 
          outlined
          hide-details
          rows="3"
          v-model="pendingScdul.scdul_desc"></v-textarea>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 일정 기간 -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>일정 시작일 *</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-70">
          <v-text-field
            class=""
            v-model="pendingScdul.scdul_bgn_dt"
            hide-details
            dense
            outlined
            append-icon="mdi-calendar"
            @click:append="popupDatePicker('scdul_bgn_dt')"
            placeholder="0000-00-00"
          ></v-text-field>
          <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
          <!-- <datePicker v-model="pendingScdul.scdul_bgn_dt"></datePicker> -->
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>일정 종료일</span>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="wid-70">
          <v-text-field
            class=""
            v-model="pendingScdul.scdul_end_dt"
            hide-details
            dense
            outlined
            append-icon="mdi-calendar"
            @click:append="popupDatePicker('scdul_end_dt')"
            placeholder="0000-00-00"
          ></v-text-field>
          <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
          <!-- <datePicker v-model="pendingScdul.scdul_end_dt"></datePicker> -->
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

    </v-row>  
    <member-search-popup v-if="mberSrchPopup" @close="closeMberSrchPopup" @selected="setRegMberSno"></member-search-popup>
  </v-container>  
</template>

<script>
// import tinyEditor from '@/components/common/tinyEditor.vue';
// import datePicker from "@/components/common/datePicker.vue";
import memberSearchPopup from '@/components/common/memberSearchPopup.vue';
// import dayjs from "dayjs";
import datePickerPopup from '@/components/common/datePickerPopup.vue';

const genEmptyScdul = () => ({
  scdul_sno: null,
  scdul_ttl: "",
  scdul_ttl_en: "",
  scdul_cn: "",
  scdul_bgn_dt: "",
  scdul_end_dt: "",
  reg_mber_sno: null,
  reg_mber_nm: "",
});

export default {
  components: { memberSearchPopup , datePickerPopup},
  data(){
    return{
      mberSno: null,

      scdulSno: this.$route.query.scdulSno,
      newYn: this.$route.query.newYn,
      menuSno: this.$route.query.menuSno,
      
      scdul: {},
      pendingScdul: {},

      // isOnPending: false,
      mberSrchPopup: false,
      datePopup: false,
      dateNm: '',
      curDate: "",
    }
  }, 
  computed: {
    isOnPending() {
      console.log("isOnPending:", this.scdul, this.pendingScdul);
      return JSON.stringify(this.scdul) != JSON.stringify(this.pendingScdul)
    }
  },
  created() {

    this.mberSno = this.$session.get("mberSno");
    // console.log("bbstSno:", this.bbstSno);
    console.log("scheduleDetail created:");

    if(this.newYn == 'Y') {
      this.scdul = genEmptyScdul();
      this.pendingScdul = JSON.parse(JSON.stringify(this.scdul));
      this.getBbsRegMber();

      //신규 날짜 오늘날짜
      const now_date = this.$setYYYYMMDDHHMM();
      this.pendingScdul.scdul_bgn_dt = now_date.reg_dt;
    } 
  },
  mounted() {
    if(this.newYn == 'N') {
      this.getScdul();
    } 
  },
  methods: {
    gotoList() {
      // this.$router.go(-1)
      // 아래 path 설정 시 select box 에 값 설정이 안됨
      // this.$router.push({path:"preRegistList", query: { confrSno:this.confrSno }});
      this.$router.push({name:"scheduleList", query: { menuSno:this.menuSno/*, scdulSno:this.scdulSno*/ }});
    },
    getScdul() {
      console.log("this.postSno(in getScdul):", this.scdulSno);
      const params = new FormData();

      params.append("scdul_sno", this.scdulSno);

			this.$http.post("/admin/getScdul", params).then((res) => {
        console.log("res.data: ", res.data);
        const scdul = res.data.result.scdul;

        this.scdul = JSON.parse(JSON.stringify(scdul, function(k, v) {return v == null ? "" : v}));
        this.pendingScdul = JSON.parse(JSON.stringify(this.scdul));
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 제목
      if(this.pendingScdul.scdul_ttl == null || this.pendingScdul.scdul_ttl == "") {
        alert("제목은 필수 입력값입니다.");
        return false;
      }

      // 일정 시작일
      if(this.pendingScdul.scdul_bgn_dt == null || this.pendingScdul.scdul_bgn_dt == "") {
        alert("일정 시작일은 필수 입력값입니다.");
        return false;
      }

      // 일정 시작일 형식 체크
      if(this.pendingScdul.scdul_bgn_dt && this.pendingScdul.scdul_bgn_dt.length != 10) {
        alert("일정 시작일은 형식이 잘못되었습니다.");
        return false;
      }
      // 일정 종료일 형식 체크
      if(this.pendingScdul.scdul_end_dt && this.pendingScdul.scdul_end_dt.length != 10) {
        alert("일정 종료일 형식이 잘못되었습니다.");
        return false;
      }

      return true;
    },
    saveNStayBtnClicked() {
      this.saveScdul("stay");
      // console.log("out of save");
    },
    saveNMoveBtnClicked() {
      this.saveScdul("move");
      // console.log("out of save");
    },
    saveScdul(action) {
      // console.log("savePost:", this.pendingPost.read_cnt);

      // if(confirm("저장하시겠습니까?")) {
        const isValid = this.validateInput();

        if(isValid) {
          const params = new FormData();

          if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
          params.append('new_yn', this.newYn);

          if(!this.pendingScdul.scdul_end_dt) {
            this.pendingScdul.scdul_end_dt = this.pendingScdul.scdul_bgn_dt;
          }

          params.append("scdul", JSON.stringify(this.pendingScdul));
          params.append("menu_sno", this.menuSno);

          console.log("saveScdul : ", this.pendingScdul.scdul_cn);
          
          this.$http.post("/admin/upsertScdul", params).then((res) => {
            console.log(res.data);
            alert("저장되었습니다.");
            
            if(this.newYn == 'Y') {
              this.scdulSno = res.data.result.scdul_sno;
              this.newYn = 'N';
              console.log("this.scdulSno(in save):", this.scdulSno);
            }

            // console.log("in save: after alert 1");
            if(action == "stay") {
              this.getScdul();
            } else if(action == "move") {
              this.gotoList();
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      // }
    },
    deleteBtnClicked() {
      console.log("deleteScdul");

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("scdul", JSON.stringify(this.pendingScdul));

        this.$http.post("/admin/deleteScdul", params).then((res) => {
          console.log(res.data);

          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.gotoList();
          } else {
            alert("삭제할 수 없습니다.");
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },

    searchMberBtnClicked() {
      this.mberSrchPopup = true;
    },
    closeMberSrchPopup() {
      this.mberSrchPopup = false;
    },
    setRegMberSno(mberSno, mberNm) {
      console.log("setRegMberSno:", mberSno);
      // this.itemChanged();

      this.pendingScdul.reg_mber_sno = mberSno;
      this.pendingScdul.reg_mber_nm = mberNm; //+ "(" + mberSno + ")";
      this.mberSrchPopup = false;
    },
    getBbsRegMber() {
      const params = new FormData();

      params.append("mber_sno", this.mberSno);

      this.$http.post("/admin/getBbsRegMber", params).then((res) => {
        const regMberMap = res.data.regMberMap;
        if(regMberMap) {
          
          this.pendingScdul.reg_mber_sno = this.mberSno;
          this.pendingScdul.reg_mber_nm = regMberMap.reg_mber_nm;
        }
        
      })
      .catch(function (error) {
        console.log(error);
      });
    },//getBbsRegMber end
    setSelectedDate(selected) {
      if(this.dateNm == "scdul_bgn_dt") {
        this.pendingScdul.scdul_bgn_dt = selected;
      } else if(this.dateNm == "scdul_end_dt") {
        this.pendingScdul.scdul_end_dt = selected;
      }
      
      this.datePopup = false;
    },//setSelectedDate end
    popupDatePicker( dateNm) {
      this.dateNm = dateNm;

      this.datePopup = true;
    },
  },
}
</script>

<style>

</style>