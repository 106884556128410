<template>
  <v-container>
    <div v-if="isLoading" class="loading-container">
      <div class="spinner-loading">
        <Fade-loader />
      </div>
    </div>
    <!--[[[[[[[[[[[[[[[        회원 검색 모달        ]]]]]]]]]]]]]]]-->
    <v-dialog
      v-model="showDialog"
      max-width="800px"
      max-height="600px"
      @keydown.esc="$emit('close')"
      @click:outside="$emit('close')"
    >
      <v-card style="text-align:center;">
        <v-app-bar 
          class="fixed-bar" 
          color="blue lighten-2" 
          max-height="60px">
          <v-toolbar-title
            class="white--text">
            회원 검색
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="$emit('close')"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card>
          <div class="pa-3">
            <v-row class="" align="center">
              <v-col cols="3">
                <v-text-field 
                  class="f-l pa-0 ma-0 wid-100 input-hei-d" 
                  v-model="searchWord" 
                  outlined
                  dense
                  hide-details
                  background-color="white"
                  @keyup.enter="searchBtnClicked" 
                >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn 
                  small 
                  color="blue lighten-2" 
                  class="white--text" 
                  @click="searchBtnClicked"
                  >검색
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="pa-3" style="overflow:auto;">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1 dTable-1"
              hide-default-footer
              disable-pagination
              dense
              :search="search"
              @click:row="gridRowClicked($event)"
              @dblclick:row="setSelected"
            >
            </v-data-table>
          </div>
          <v-btn text color="primary" @click="$emit('close')">Cancel</v-btn>
          <v-btn text color="primary" @click="setSelected()">OK</v-btn>
        </v-card>
      </v-card>	
    </v-dialog>

  </v-container>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'
export default {
  components: { FadeLoader },
  props: ["p_soctySno"],
  data() {
    return {
      showDialog: false,
      soctySno: this.p_soctySno,

      gridHeader: [
        { text: '회원번호', value: 'mber_sno', width: "10%" },
        { text: '이름', value: 'mber_nm', width: "20%" },
        { text: '로그인ID', value: 'login_id', width: "20%" },
        { text: '회원종류', value: 'mber_kind_nm', width: "20%" },
      ],
      gridList: [],
      selectedMber: null,

      search: "",
      searchWord: "",
      isLoading: true,
    };
  },
  created() {
    console.log("memberSearchPopup created.");

    this.getMberList();
  },
  methods: {
    getMberList() {
      //시작
      this.isLoading = true;
      this.$http.get("/admin/getMberListForSrch").then((res) => {
        console.log("getMberList:", res.data);
        this.gridList = res.data.result.mberList;
        this.showDialog = true;
        this.isLoading = false;
      }).catch(function (error) {
          console.log(error);
        }
      )
    },
    setSelected() {    
      console.log("setSelected:");
      this.$emit('selected', this.selectedMber.mber_sno, this.selectedMber.mber_nm);
    },
    gridRowClicked(event) {
      console.log("gridRowClicked:", event);
      this.selectedMber = event;
    },
    gridRowDblClicked() {
      // console.log("gridRowDblClicked:", event);
      // this.selectedMber = event;
      this.setSelected();
    },
    // close() {
    //   this.showDialog = false;
    // },
    searchBtnClicked() {
      this.search = this.searchWord;
    },
  },
};
</script>

<style scoped>
.spinner-loading {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
  
}
</style>