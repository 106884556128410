<template>
  <v-container fluid>
    <v-row class="pa-3">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="goBack"
          >목록</v-btn>
        </div>
        <div style="float:right;">
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn
            small
            color="red lighten-2"
            class="white--text mr-2"
            @click="deleteBtnClicked"
          >회원 삭제</v-btn>
        </div>
      </v-col>
    </v-row>
    
    <v-tabs
      v-model="activeTab"
    >      
      <v-tab
        v-for="item of tabList"
        :key="item.content"
        exact
        :disabled="!item.enabled"
      >
        {{ item.tabNm }}
        <v-badge 
          v-if="item.changed"
          color="red"
          class="pb-2"
          dot
        ></v-badge>
      </v-tab>
      <v-tabs-items 
        v-model="activeTab"
      > 
        <v-divider></v-divider>
        <v-tab-item
          v-for="item in tabList"
          :key="item.content"
          class="ma-5"
        >
          <component v-bind:is="item.content" :ref="item.content" class="w-100" v-bind:propParams="propParams" @tabChanged="tabChanged" @mberCreated="mberCreated"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import memberBasicTab from '@/components/admin/tabs/member/memberBasicTab';
import memberIndivTab from '@/components/admin/tabs/member/memberIndivTab';
import memberGroupTab from '@/components/admin/tabs/member/memberGroupTab';
import memberPermissionTab from '@/components/admin/tabs/member/memberPermissionTab';
import conferenceHistoryTab from '@/components/admin/tabs/member/conferenceHistoryTab';
import memberFeeTab from '@/components/admin/tabs/member/memberFeeTab';
import addressTab from '@/components/admin/tabs/member/addressTab';
import educationTab from '@/components/admin/tabs/member/educationTab';
import certificateTab from '@/components/admin/tabs/member/certificateTab';
import memberHistoryTab from '@/components/admin/tabs/member/memberHistoryTab';
import careerTab from '@/components/admin/tabs/member/careerTab';

export default {
  components: {
    memberBasicTab, memberIndivTab, memberGroupTab, memberPermissionTab, conferenceHistoryTab, memberFeeTab, addressTab, educationTab, certificateTab, memberHistoryTab, careerTab
  },
  data(){
    return {
      activeTab: 0,
      tabList: [
        { tabNm: '기본정보', content: 'memberBasicTab', tblNm: 'basic', tabNo: 0, enabled:true, changed: false, sctnId:'mber_basic'},
        { tabNm: '개인회원정보', content: 'memberIndivTab', tblNm: 'indv' , tabNo: 1, enabled:false, changed: false, sctnId:'mber_indv'},
        { tabNm: '단체회원정보', content: 'memberGroupTab', tblNm: 'grp' , tabNo: 2, enabled:false, changed: false, sctnId:'mber_grp'},
        { tabNm: '퍼미션', content: 'memberPermissionTab', tabNo: 3, enabled:false, changed: false },
        { tabNm: '학술대회 등록내역', content: 'conferenceHistoryTab', tabNo: 4, enabled:false, changed: false },
        { tabNm: '회비', content: 'memberFeeTab', tabNo: 5, enabled:false, changed: false },
        { tabNm: '주소', content: 'addressTab', tabNo: 6, enabled:false, changed: false, sctnId:'mber_addr' },
        { tabNm: '학력', content: 'educationTab', tabNo: 7, enabled:false, changed: false, sctnId:'mber_edubg'},
        { tabNm: '자격증', content: 'certificateTab', tabNo: 8, enabled:false, changed: false },
        { tabNm: '이력관리', content: 'memberHistoryTab', tabNo: 9, enabled:false, changed: false },
        { tabNm: '경력관리', content: 'careerTab', tabNo: 10, enabled:false, changed: false }
      ],
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      // menu: false,
      newYn: this.$route.query.newYn,
      // tblNm: "", //'basic',
      loginMberSno: "",   // 로그인 멤버
      mberSno: this.$route.query.mberSno,
      mberDiv: this.$route.query.mberDiv,
      // mberSctnId: '',
      propParams: {},
      redrawCount: 0,

      saveBtnEnabled: false,
    }
  },
  computed: {

  },
  created() {
    // 탭의 이름을 학회별 영역명(sctn_nm)으로 처리
    this.setTabNameBySoctySctnNm();

    // 개인/단체 구분에 따라 불필요한 탭 삭제
    if(this.mberDiv == 'indv') {
      this.tabList.splice(2, 1);
      // this.tblNm = 'indv';
    } else if(this.mberDiv == 'grp') {
      this.tabList.splice(6, 5);  // 우편물 수령 주소, 학력 정보, 자격증, 이력관리, 경력관리 탭
      this.tabList.splice(1, 1);
      // this.tblNm = 'grp';
    } 

    // 탭 활성화
    console.log("newYn:", this.newYn, this.mberDiv, this.tabList);
    if(this.newYn == 'N') {
      this.tabList.forEach((e) => {
        e.enabled = true;
      });

      this.activeTab = 1;   // 개인/단체회원 탭 표시. tabList의 index
    } else {
      this.activeTab = 0;   // 기본정보 탭 표시. tabList의 index
      // this.tblNm = 'basic';
    }

    // const mberComnTab = this.items.filter(e => e.tblNm = 'indv');

    // if(!this.mberSno) {
    //   alert('잘못된 접근입니다.');
    //   this.$router.push({path:"memberList"})
    // }
    // this.mberSctnId = 'mber_' + this.tblNm;

    this.propParams['newYn'] = this.newYn;
    // this.propParams['tblNm'] = this.tblNm;
    // this.propParams['mberSctnId'] = this.mberSctnId;
    this.propParams['mberSno'] = this.mberSno;
    this.propParams['mberDiv'] = this.mberDiv;

    this.loginMberSno = this.$session.get("mberSno"); // 로그인 멤버

    this.redrawCount++;

  },
  mounted() {
    // // 탭 활성화
    // console.log("newYn:", this.newYn, this.tabList);
    // if(this.newYn == 'N') {
    //   this.tabList.forEach((e) => {
    //     e.enabled = true;
    //   });

    //   this.activeTab = 1;   // 개인/단체회원 탭 표시. tabList의 index
    // } else {
    //   this.activeTab = 0;   // 기본정보 탭 표시. tabList의 index
    //   // this.tblNm = 'basic';
    // }

    // // const mberComnTab = this.items.filter(e => e.tblNm = 'indv');

    // // if(!this.mberSno) {
    // //   alert('잘못된 접근입니다.');
    // //   this.$router.push({path:"memberList"})
    // // }
    // // this.mberSctnId = 'mber_' + this.tblNm;

    // this.propParams['newYn'] = this.newYn;
    // // this.propParams['tblNm'] = this.tblNm;
    // // this.propParams['mberSctnId'] = this.mberSctnId;
    // this.propParams['mberSno'] = this.mberSno;
    // this.propParams['mberDiv'] = this.mberDiv;

    // this.loginMberSno = this.$session.get("mberSno"); // 로그인 멤버

    // this.redrawCount++;
  },
  methods: {
    updateRouter(item) {
      this.$router.push(item);
    },//updateRouter end
    goBack() {
      this.$router.go(-1)
    },//goBack end
    // tabItemClicked(tab) {
    //   // console.log(this.tab);
    //   console.log("tab:", tab);

    //   // this.tab = null;
    //   // if(confirm("수정된 데이터 저장하겠습니까?")) {
    //   //   console.log(this.$refs.haha);
    //   //   console.log(this.$refs.haha.ttt);
    //   // }
    //   if(tab.tabNo <= 2)
    //   {
    //     // if(tab.tblNm) {
    //       // this.tblNm = tab.tblNm;
    //       // this.mberSctnId = 'mber_' + this.tblNm;
    //       this.propParams['newYn'] = this.newYn;
    //       // this.propParams['tblNm'] = this.tblNm;
    //       // this.propParams['mberSctnId'] = this.mberSctnId;
    //     // }
    //     //탭 눌렀을시 새로고침 되도록 하는부분 주석처리
    //     // this.redrawCount++;
    //   }
      
    // },//tabItemClicked end
    // chgTabYn(tblNm, chkDisYn) {
    //   this.tabList.filter((e) => e.tblNm == tblNm).map((f) => {
    //     f.changed = chkDisYn;
    //   })
    // },//chgTabYn end
    tabChanged(tabId) {
      console.log("memberDetail tabChanged:", tabId);
      this.tabList.filter((e) => e.content == tabId).map((f) => {
        f.changed = true;
      })

      this.saveBtnEnabled = true;
    },
    // 탭의 이름을 학회별 영역명(sctn_nm)으로 처리
    setTabNameBySoctySctnNm() {
			this.$http.get("/admin/getMstMberSctnList").then((res) => { 
        let mberSctnList = res.data.result;

        this.tabList.forEach((item) => {
          mberSctnList.forEach((sctn) => {
            if(item.sctnId == sctn.mber_sctn_id) {
              item.tabNm = sctn.mber_sctn_nm;
            }
          });
        });
			}).catch(function (error) {
					console.log(error);
				}
			);
		},
    mberCreated(mberSno) {
      console.log("mberCreated:", mberSno);
      this.propParams['mberSno'] = mberSno;
      this.mberSno = mberSno;
      this.newYn = 'N';
    },
    deleteBtnClicked() {
      console.log("deleteMber");

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("login_mber_sno", this.loginMberSno); // => upd_mber_sno
        params.append("mber_sno", this.mberSno);

        this.$http.post("/admin/deleteMberBasic", params).then((res) => {
          console.log(res.data);
          alert("삭제되었습니다.");

          this.$router.push({path:"memberList"})
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    saveNMoveBtnClicked() {
      console.log("saveNMoveBtnClicked");
      this.saveMemberInfo();
      this.$router.push({path:"memberList"})
    },
    saveNStayBtnClicked() {
      console.log("saveNStayBtnClicked");
      this.saveMemberInfo();
    },
    saveMemberInfo() {
      // console.log("saveMemberInfo");
      // console.log("refs:", this.$refs);
      // console.log("filtered:", this.tabList.filter(e => e.changed));

      if(confirm("저장하시겠습니까?")) {
        const filtered = this.tabList.filter(e => e.changed);
        console.log("filtered:", filtered);
        // 입력 데이터 검증
        let isValid = true;
        filtered.forEach((e) => {
          if(e.content == "memberBasicTab") {
            isValid = this.$refs.memberBasicTab[0].validateInput();
          } else if(e.content == "memberIndivTab") {
            isValid = this.$refs.memberIndivTab[0].validateInput();
          } else if(e.content == "memberGroupTab") {
            isValid = this.$refs.memberGroupTab[0].validateInput();
          // } else if(e.content == "memberPermissionTab") {
          //   isValid = this.$refs.memberPermissionTab[0].validateInput();
          // } else if(e.content == "conferenceHistoryTab") {
          //   isValid = this.$refs.conferenceHistoryTab[0].validateInput();
          } else if(e.content == "memberFeeTab") {
            isValid = this.$refs.memberFeeTab[0].validateInput();
          // } else if(e.content == "addressTab") {
          //   isValid = this.$refs.addressTab[0].validateInput();
          // } else if(e.content == "educationTab") {
          //   isValid = this.$refs.educationTab[0].validateInput();
          // } else if(e.content == "certificateTab") {
          //   isValid = this.$refs.certificateTab[0].validateInput();
          // } else if(e.content == "memberHistoryTab") {
          //   isValid = this.$refs.memberHistoryTab[0].validateInput();
          // } else if(e.content == "careerTab") {
          //   isValid = this.$refs.careerTab[0].validateInput();
          } 
        });

        if(!isValid) {
          // alert("invalid");
        } else {
          // alert("valid");
          // 저장
          filtered.forEach((e) => {
            if(e.content == "memberBasicTab") {
              this.$refs.memberBasicTab[0].saveBtnClicked();
            } else if(e.content == "memberIndivTab") {
              this.$refs.memberIndivTab[0].saveBtnClicked();
            } else if(e.content == "memberGroupTab") {
              this.$refs.memberGroupTab[0].saveBtnClicked();
            } else if(e.content == "memberPermissionTab") {
              this.$refs.memberPermissionTab[0].saveBtnClicked();
            } else if(e.content == "conferenceHistoryTab") {
              this.$refs.conferenceHistoryTab[0].saveBtnClicked();
            } else if(e.content == "memberFeeTab") {
              this.$refs.memberFeeTab[0].saveBtnClicked();
            } else if(e.content == "addressTab") {
              this.$refs.addressTab[0].saveBtnClicked();
            } else if(e.content == "educationTab") {
              this.$refs.educationTab[0].saveBtnClicked();
            } else if(e.content == "certificateTab") {
              this.$refs.certificateTab[0].saveBtnClicked();
            } else if(e.content == "memberHistoryTab") {
              this.$refs.memberHistoryTab[0].saveBtnClicked();
            } else if(e.content == "careerTab") {
              this.$refs.careerTab[0].saveBtnClicked();
            } 
          });        

          this.tabList.forEach((e) => {
            e.enabled = true;
            e.changed = false;
          });

          this.saveBtnEnabled = false;

          // 전체 저장되었습니다.나와서 임시 주석
          // alert("저장되었습니다.");

          // this.$router.push({path:"memberList"});
        }
      }
    },
  }
}
</script>

<style>

</style>