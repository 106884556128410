<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center" dense>
          <v-col cols="2">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="search" 
              outlined
              dense
              hide-details
              background-color="white">
            </v-text-field>
          </v-col>
          <v-col cols="1" class="">
          </v-col>
          <v-col cols="1" class="">
            <div class="">
              <span>학술대회</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-select
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              :items="confrList"
              item-value="confr_sno"
              item-text="confr_nm"
              v-model="confrSno"
              background-color="white"
              hide-details
              dense
              outlined
              @change="confrSelChanged"
            ></v-select>
          </v-col>
          <v-col cols="5">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addPreRegistBtnClicked"
              >사전등록 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              :search="search"
              class="elevation-1"
              style="cursor: pointer"
            >
              <!-- @click:row="confrRowClicked($event)" -->
            <!-- <template v-slot:[`item.confr_check`]="{ item }">
              <v-simple-checkbox
                v-model="item.confr_check"
                hide-details>
              </v-simple-checkbox>
            </template> -->
            <template v-slot:[`item.select_yn`]="{ item }">
              <v-checkbox
                v-model="item.select_yn"
                class="pa-0 ma-0"
                hide-details
                dense
              ></v-checkbox>
            </template>
            <template v-slot:[`item.rqstr_disp`]="{ item }" >
              <div @click="gridRowClicked(item)">{{item.rqstr_disp }}</div>
            </template>
            <template v-slot:[`item.rqstr_blng`]="{ item }">
              <div @click="gridRowClicked(item)">{{item.rqstr_blng }}</div>
            </template>
            <template v-slot:[`item.pay_amt`]="{ item }">
              <div @click="gridRowClicked(item)">{{item.pay_amt }}</div>
            </template>
            <template v-slot:[`item.pay_yn`]="{ item }">
              <div @click="gridRowClicked(item)">{{item.pay_yn }}</div>
            </template>
            <template v-slot:[`item.ptcpr_cnt`]="{ item }">
              <div @click="gridRowClicked(item)">{{item.ptcpr_cnt }}</div>
            </template>
            <template v-slot:[`item.ptcp_type_nm`]="{ item }">
              <div @click="gridRowClicked(item)">{{item.ptcp_type_nm }}</div>
            </template>
            <template v-slot:[`item.site_reg_yn`]="{ item }">
              <div @click="gridRowClicked(item)">{{item.site_reg_yn }}</div>
            </template>
            <template v-slot:[`item.prod_nm`]="{ item }">
              <div @click="gridRowClicked(item)">{{item.prod_nm }}</div>
            </template>
            <template v-slot:[`item.reg_dtm`]="{ item }">
              <div @click="gridRowClicked(item)">{{item.reg_dtm }}</div>
            </template>
            <!-- <template v-slot:[`item.rqstr_disp`]="{item}">
              <v-text-field
                v-model="item.rqstr_disp"
                hide-details
                dense
                readonly
                disabled
                solo
                flat
                class="pa-0 ma-0 text-field-transparent"
              >
              </v-text-field>
            </template> -->
          </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.preRegistList.radioSiteRegYn"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>현장등록 여부로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.preRegistList.radioPayYn"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>결제 여부로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  label="예"
                  value="Y"
                ></v-radio>
                <v-radio
                  label="아니오"
                  value="N"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.preRegistList.radioPtcpType"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>참석 형태로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in ptcpTypeList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기

      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%", sortable: false },
        { text: '신청자', value: 'rqstr_disp', width: "10%" },
        { text: '소속', value: 'rqstr_blng', width: "10%" },
        { text: '결제금액', value: 'pay_amt', width: "10%", align: 'right' },
        { text: '결제여부', value: 'pay_yn', width: "7%", align: 'center' },
        { text: '참가인원', value: 'ptcpr_cnt', width: "7%", align: 'right' },
        { text: '참석형태', value: 'ptcp_type_nm', width: "8%", align: 'center' },
        { text: '현장등록', value: 'site_reg_yn', width: "8%", align: 'center' },
        { text: '상품', value: 'prod_nm', width: "15%" },
        { text: '등록일시', value: 'reg_dtm', width: "13%", align: 'center' },
      ],
      gridList: [],
      preRegList: [],

      confrList: [],
      confrSno: this.$route.query.confrSno,

      // 라디오 필터 표시 목록
      ptcpTypeList: [],

      search: "",
    }
  },
  created() {
    console.log("preRegList > this.confrSno:", this.confrSno);

    this.getPtcpTypeList();
    this.getConfrList();

    if(this.confrSno != null && this.confrSno != "") {
      this.getConfrPreRegList();
    }
  },
  mounted(){
    
  },
  methods: {
    async getPtcpTypeList() {
      this.ptcpTypeList = await this.$getCmnCdList('ptcp_type');
    },
    getConfrList() {
      this.$http.get("/admin/getConfrList").then((res) => {
        // console.log("res.data.result:", res.data.result);
        this.confrList = res.data.result;
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },
    getConfrPreRegList() {
      const params = new FormData();
      console.log("this.confrSno:", this.confrSno);
      params.append("confr_sno", this.confrSno);

      this.$http.post("/admin/getConfrPreRegList", params).then((res) => {
        // console.log("res.data.result:", res.data.result);
        res.data.result.forEach((e) => {
          e.select_yn = false;
          e.rqstr_disp = e.rqstr_nm + "(" + e.rqstr_email + ")";
          e.pay_amt = this.$setComma(e.pay_amt);
        });

        // this.gridList = res.data.result;
        this.preRegList = res.data.result;
        this.filterList();
      }).catch((error) => console.log(error));
    },
    confrSelChanged() {
      // console.log("this.confrSno:", this.confrSno);
      this.getConfrPreRegList();
    },
    gridRowClicked(event) {
      // console.log("gridRowClicked", event);
      this.$router.push({name:"preRegistDetail", query: { confrSno:this.confrSno, preRegSno:event.pre_reg_sno, newYn:'N' }});
    },
    // 학술대회 추가 버튼 클릭
    addPreRegistBtnClicked() {
      this.$router.push({name:"preRegistDetail", query: { confrSno:this.confrSno, preRegSno:null, newYn:'Y' }});
    },
    deleteBtnClicked() {
      console.log("deletePreRegistList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: pre_reg_sno[" + e.pre_reg_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteConfrPreRegList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getConfrPreRegList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    radioFilterChanged() {
      this.filterList();
    },
    filterList() {
      this.gridList = this.preRegList.filter((e) => {
        // console.log("e:", e);
        if( (this.$store.state.preRegistList.radioSiteRegYn == "모두" || e.site_reg_yn == this.$store.state.preRegistList.radioSiteRegYn) &&
            // (this.$store.state.preRegistList.radioPayYn == "모두" || e.pay_yn == this.$store.state.preRegistList.radioPayYn) &&
            (this.$store.state.preRegistList.radioPtcpType == "모두" || e.ptcp_type == this.$store.state.preRegistList.radioPtcpType) 
          ) return true;
      });
    }
  }
}
</script>
  
<style>

</style>