<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="">
        <v-row dense align="center">
          <!-- 학술대회 -->
          <v-col cols="12">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>학술대회 *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="confrList"
                  item-value="confr_sno"
                  item-text="confr_nm"
                  v-model="confrSno"
                  hide-details
                  dense
                  outlined
                  @change="confrChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 회원 -->
          <v-col cols="12">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>회원</span><span> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="mberDisp"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  append-icon="mdi-magnify"
                  @click="searchMberBtnClicked"
                  @click:append="searchMberBtnClicked"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 이메일 -->
          <v-col cols="12" v-if="regItemSet.rqstr_email.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>이메일</span><span v-if="regItemSet.rqstr_email.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="rqstr_email"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 이름 -->
          <v-col cols="12" v-if="regItemSet.rqstr_nm.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>이름</span><span v-if="regItemSet.rqstr_nm.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="rqstr_nm"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 소속 -->
          <v-col cols="12" v-if="regItemSet.rqstr_blng.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>소속</span><span v-if="regItemSet.rqstr_blng.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="rqstr_blng"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 부서/학과 -->
          <v-col cols="12" v-if="regItemSet.rqstr_dept.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>부서/학과</span><span v-if="regItemSet.rqstr_dept.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="rqstr_dept"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 휴대전화번호 -->
          <v-col cols="12" v-if="regItemSet.rqstr_cel_no.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>휴대전화번호</span><span v-if="regItemSet.rqstr_cel_no.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="rqstr_cel_no"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 현장등록 여부 -->
          <v-col cols="12" v-if="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>현장등록</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="site_reg_yn"
                  hide-details
                  dense
                  label="현장등록 여부"
                  @change="itemChanged"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 결제 여부 -->
          <v-col cols="12" v-if="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>결제</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="pay_yn"
                  hide-details
                  dense
                  label="결제 여부"
                  @change="itemChanged"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 결제 금액 -->
          <v-col cols="12" v-if="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>결제 금액</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pay_amt"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 참석 형태 -->
          <v-col cols="12" v-if="regItemSet.ptcp_type.use_yn && newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>참석 형태</span><span v-if="regItemSet.ptcp_type.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="ptcpTypeList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="ptcp_type"
                  hide-details
                  dense
                  outlined
                  @change="itemChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 신청자 메모 -->
          <v-col cols="12" v-if="regItemSet.rqstr_memo.use_yn && newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>신청자 메모</span><span v-if="regItemSet.rqstr_memo.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-textarea
                  v-model="rqstr_memo"
                  class="wid-100 pt-0"
                  label="임시영역"
                  hide-details
                  outlined
                  @change="itemChanged"
                ></v-textarea>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 관리자 메모 -->
          <v-col cols="12" v-if="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>관리자 메모</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-textarea
                  v-model="admin_memo"
                  class="wid-100 pt-0"
                  label="임시영역"
                  hide-details
                  outlined
                  @change="itemChanged"
                ></v-textarea>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>

        </v-row>  
      </div>
    </v-layout>
    <member-search-popup v-if="mberSrchPopup" @close="closeMberSrchPopup" @selected="setRqstMberSno"></member-search-popup>
  </v-container>
</template>

<script>
import memberSearchPopup from '@/components/common/memberSearchPopup.vue';
export default {
  components: {memberSearchPopup},
  props: ["propParams"],
  data(){
    return{
      regItemSet: {
        confr_sno: {use_yn:true, ess_yn:false},
        rqst_mber_sno: {use_yn:true, ess_yn:false},
        rqstr_email: {use_yn:true, ess_yn:false},
        rqstr_nm: {use_yn:true, ess_yn:false},
        rqstr_blng: {use_yn:true, ess_yn:false},
        rqstr_dept: {use_yn:true, ess_yn:false},
        rqstr_cel_no: {use_yn:true, ess_yn:false},
        ptcp_type: {use_yn:true, ess_yn:false},
        site_reg_yn: {use_yn:true, ess_yn:false},
        prvcy_polcy_agree_yn: {use_yn:true, ess_yn:false},
        rqstr_memo: {use_yn:true, ess_yn:false},
        admin_memo: {use_yn:true, ess_yn:false},
      },
      mberSno: null,
      confrSno: null,
      preRegSno: null,
      newYn: 'N',

      // confr_sno: "",
      rqst_mber_sno: null,
      rqstr_nm: "",
      rqstr_email: "",
      rqstr_blng: "",
      rqstr_dept: "",
      rqstr_cel_no: "",
      ptcp_type: "",
      pay_amt: null,
      pay_yn: false,
      prvcy_polcy_agree_yn: false,
      site_reg_yn: false,
      rqstr_memo: "",
      admin_memo: "",

      confrList: [],
      ptcpTypeList: [],

      mberSrchPopup: false,
      mberDisp: "",
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.propParams.confrSno; 
    this.preRegSno = this.propParams.preRegSno; 
    this.newYn = this.propParams.newYn;

    console.log("this.newYn:", this.newYn);
    console.log("this.confrSno:", this.confrSno);

    this.getConfrList();
    this.getPtcpTypeList();

    if(this.confrSno != null) {
      this.getRegItemList();
    }

    if(this.newYn == 'N') {
      this.getConfrPreReg();
    }

    // 학술대회 select refresh
    if(this.confrSno != null) {
      this.confrSno++;
      this.confrSno--;
    }
  },
  methods: {
    getConfrList() {
      this.$http.get("/admin/getConfrList").then((res) => {
        // console.log("res.data.result:", res.data.result);
        this.confrList = res.data.result;
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },
    async getPtcpTypeList() {
      this.ptcpTypeList = await this.$getCmnCdList('ptcp_type');
    },
    getRegItemList() {
      const params = new FormData();
      params.append("confr_sno", this.confrSno);
      params.append("reg_div", 'pre');

			this.$http.post("/admin/getConfrRegItemList", params).then((res) => {
        // console.log("res.data: ", res.data);

        let regItemSet = {};
        res.data.result.regItemList.forEach((e) => {
          // console.log(e);
          regItemSet[e.reg_item_id] = {use_yn:e.use_yn, ess_yn:e.ess_yn};
        });
        console.log("regItemSet:", regItemSet);
        this.regItemSet = regItemSet;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    getConfrPreReg() {
      const params = new FormData();
      params.append("pre_reg_sno", this.preRegSno);
      // params.append("confr_sno", this.confrSno);
      // params.append("reg_div", 'pre');

			this.$http.post("/admin/getConfrPreReg", params).then((res) => {
        // console.log("res.data: ", res.data);

        // let regItemSet = {};
        // res.data.result.preRegItemList.forEach((e) => {
        //   // console.log(e);
        //   regItemSet[e.reg_item_id] = {use_yn:e.use_yn, ess_yn:e.ess_yn};
        // });
        // console.log("regItemSet:", regItemSet);
        // this.regItemSet = regItemSet;

        const preReg = res.data.result.preRegInfo;

        if(preReg.rqst_mber_sno != null) {
          this.mberDisp = preReg.rqst_mber_nm + "(" + preReg.rqst_mber_sno + ")"
        }        
        this.rqst_mber_sno = preReg.rqst_mber_sno;
        this.rqstr_nm = preReg.rqstr_nm;
        this.rqstr_email = preReg.rqstr_email;
        this.rqstr_blng = preReg.rqstr_blng;
        this.rqstr_dept = preReg.rqstr_dept;
        this.rqstr_cel_no = preReg.rqstr_cel_no;
        this.ptcp_type = preReg.ptcp_type;
        this.prvcy_polcy_agree_yn = preReg.prvcy_polcy_agree_yn == 'Y' ? true : false;
        this.site_reg_yn = preReg.site_reg_yn == 'Y' ? true : false;
        this.rqstr_memo = preReg.rqstr_memo;
        this.admin_memo = preReg.admin_memo;

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 학술대회
      if(/*this.regItemSet.confr_sno.ess_yn &&*/ (this.confrSno == null || this.confrSno == "")) {
        alert("학술대회는 필수 입력값입니다.");
        return false;
      }
      // 회원
      if(/*this.regItemSet.rqst_mber_sno.ess_yn &&*/ (this.rqst_mber_sno == null || this.rqst_mber_sno == "")) {
        alert('회원은 필수 입력값입니다.');
        return false;
      }
      // 이메일
      if(this.regItemSet.rqstr_email.ess_yn) {
        if(this.rqstr_email == null || this.rqstr_email == "") {
          alert('이메일은 필수 입력값입니다.');
          return false;
        }
        if(!this.$validateEmail(this.rqstr_email)) {
          alert('이메일 형식이 아닙니다.');
          return false;
        }
      }
      // 이름
      if(this.regItemSet.rqstr_nm.ess_yn && (this.rqstr_nm == null || this.rqstr_nm == "")) {
        alert('이름은 필수 입력값입니다.');
        return false;
      }
      // 소속
      if(this.regItemSet.rqstr_blng.ess_yn && (this.rqstr_blng == null || this.rqstr_blng == "")) {
        alert('소속은 필수 입력값입니다.');
        return false;
      }
      // 부서/학과
      if(this.regItemSet.rqstr_dept.ess_yn && (this.rqstr_dept == null || this.rqstr_dept == "")) {
        alert('부서/학과는 필수 입력값입니다.');
        return false;
      }
      // 휴대전화번호
      if(this.regItemSet.rqstr_cel_no.ess_yn && (this.rqstr_cel_no == null || this.rqstr_cel_no == "")) {
        alert('휴대전화번호는 필수 입력값입니다.');
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      // console.log("saveConference");
      if(!this.validateInput()) {
        return;
      }

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append("pre_reg_sno", this.preRegSno);
        params.append('new_yn', this.newYn);

        if(this.rqst_mber_sno != null)        params.append("rqst_mber_sno", this.rqst_mber_sno);
        if(this.rqstr_nm != null)             params.append("rqstr_nm", this.rqstr_nm);
        if(this.rqstr_email != null)          params.append("rqstr_email", this.rqstr_email);        
        if(this.rqstr_blng != null)           params.append("rqstr_blng", this.rqstr_blng);
        if(this.rqstr_dept != null)           params.append("rqstr_dept", this.rqstr_dept);
        if(this.rqstr_cel_no != null)         params.append("rqstr_cel_no", this.rqstr_cel_no);
        if(this.newYn == 'N') {
          if(this.ptcp_type != null)            params.append("ptcp_type", this.ptcp_type);
          if(this.prvcy_polcy_agree_yn != null) params.append("prvcy_polcy_agree_yn", this.prvcy_polcy_agree_yn ? 'Y' : 'N');
          if(this.site_reg_yn != null)          params.append("site_reg_yn", this.site_reg_yn ? 'Y' : 'N');
          if(this.rqstr_memo != null)           params.append("rqstr_memo", this.rqstr_memo);
          if(this.admin_memo != null)           params.append("admin_memo", this.admin_memo);
        }

        this.$http.post("/admin/upsertConfrPreReg", params).then((res) => {
          // console.log(res.data);
          // alert("저장되었습니다.");

          if(this.newYn == 'Y') {
            this.preRegSno = res.data.pre_reg_sno;
            this.$emit('preRegCreated', this.preRegSno);
          }

          this.getConfrPreReg();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    searchMberBtnClicked() {
      this.mberSrchPopup = true;
    },
    closeMberSrchPopup() {
      this.mberSrchPopup = false;
    },
    setRqstMberSno(mberSno, mberNm) {
      this.itemChanged();

      this.rqst_mber_sno = mberSno;
      this.mberDisp = mberNm + "(" + mberSno + ")";
      this.mberSrchPopup = false;

      this.getConfrRegMberInfo(mberSno);
    },
    getConfrRegMberInfo(rqstMberSno) {
      const params = new FormData();

      params.append("mber_sno", rqstMberSno);

      this.$http.post("/admin/getConfrRegMberInfo", params).then((res) => {
        const confrRegMber = res.data.result.confrRegMber;
        console.log("getConfrRegMberInfo : ", confrRegMber);
        
        this.rqstr_email = confrRegMber.email;
        this.rqstr_nm = confrRegMber.mber_nm;
        this.rqstr_blng = confrRegMber.blng_nm;
        this.rqstr_dept = confrRegMber.dept_nm;
        // this.rqstr_pstn = confrRegMber.pstn_nm;
        this.rqstr_cel_no = confrRegMber.cel_no;

        this.redrawCnt++;
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },
    confrChanged() {
      this.getRegItemList();
      this.itemChanged();
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'preRegistInfoTab');
    },
  },
}
</script>

<style>
 .outline-wh .v-text-field--outlined fieldset  {
    color: red !important;
}
</style>