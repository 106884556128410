<template>
  <v-container class="pa-3">
    <v-row class="pa-0">
      <v-col cols="12" class="pa-0 d-flex align-center">
        <h1 class="mr-3">test</h1>
        <div><v-icon class="mr-3">mdi-power-on</v-icon>설명이 들어갑니다.</div>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px mb-5"></v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card class="mb-4 hei-600p tborder " outlined>
          <div class="mt-4 wid-100 hei-450p d-flex justify-center align-center">
            <div class="wid-350p hei-400p d-flex justify-center align-center bg-grey">
              <img class="wid-400p hei-400p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
            </div>
          </div>
          <div class="wid-100 hei-80p mt-4" align="center">
            <div class=" wid-fit-content mb-4 fontSize-3 font-weight-bold">
              제목
            </div>
            <div class="wid-50p border-b-darkgrey-2px mb-4"></div>
            <div class=" wid-fit-content">
              설명이 들어갑니다.
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mb-4 hei-600p tborder " outlined>
          <div class="mt-4 wid-100 hei-450p d-flex justify-center align-center">
            <div class="wid-350p hei-400p d-flex justify-center align-center bg-grey">
              <img class="wid-400p hei-400p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
            </div>
          </div>
          <div class="wid-100 hei-80p mt-4" align="center">
            <div class=" wid-fit-content mb-4 fontSize-3 font-weight-bold">
              제목
            </div>
            <div class="wid-50p border-b-darkgrey-2px mb-4"></div>
            <div class=" wid-fit-content">
              설명이 들어갑니다.
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mb-4 hei-600p tborder " outlined>
          <div class="mt-4 wid-100 hei-450p d-flex justify-center align-center">
            <div class="wid-350p hei-400p d-flex justify-center align-center bg-grey">
              <img class="wid-400p hei-400p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
            </div>
          </div>
          <div class="wid-100 hei-80p mt-4" align="center">
            <div class=" wid-fit-content mb-4 fontSize-3 font-weight-bold">
              제목
            </div>
            <div class="wid-50p border-b-darkgrey-2px mb-4"></div>
            <div class=" wid-fit-content">
              설명이 들어갑니다.
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>


    <v-row class="pa-0 mt-10">
      <v-col cols="12" class="pa-0 d-flex align-center">
        <h1 class="mr-3">test</h1>
        <div><v-icon class="mr-3">mdi-power-on</v-icon>설명이 들어갑니다.</div>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px mb-5"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-card class="mb-4 hei-600p tborder " outlined>
          <div class="mt-4 wid-100 hei-450p d-flex justify-center align-center">
            <div class="wid-250p hei-400p d-flex justify-center align-center bg-grey">
              <img class="wid-250p hei-400p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
            </div>
          </div>
          <div class="wid-100 hei-80p mt-4" align="center">
            <div class=" wid-fit-content mb-4 fontSize-3 font-weight-bold">
              제목
            </div>
            <div class="wid-50p border-b-darkgrey-2px mb-4"></div>
            <div class=" wid-fit-content">
              설명이 들어갑니다.
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mb-4 hei-600p tborder " outlined>
          <div class="mt-4 wid-100 hei-450p d-flex justify-center align-center">
            <div class="wid-250p hei-400p d-flex justify-center align-center bg-grey">
              <img class="wid-250p hei-400p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
            </div>
          </div>
          <div class="wid-100 hei-80p mt-4" align="center">
            <div class=" wid-fit-content mb-4 fontSize-3 font-weight-bold">
              제목
            </div>
            <div class="wid-50p border-b-darkgrey-2px mb-4"></div>
            <div class=" wid-fit-content">
              설명이 들어갑니다.
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mb-4 hei-600p tborder " outlined>
          <div class="mt-4 wid-100 hei-450p d-flex justify-center align-center">
            <div class="wid-250p hei-400p d-flex justify-center align-center bg-grey">
              <img class="wid-250p hei-400p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
            </div>
          </div>
          <div class="wid-100 hei-80p mt-4" align="center">
            <div class=" wid-fit-content mb-4 fontSize-3 font-weight-bold">
              제목
            </div>
            <div class="wid-50p border-b-darkgrey-2px mb-4"></div>
            <div class=" wid-fit-content">
              설명이 들어갑니다.
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mb-4 hei-600p tborder " outlined>
          <div class="mt-4 wid-100 hei-450p d-flex justify-center align-center">
            <div class="wid-250p hei-400p d-flex justify-center align-center bg-grey">
              <img class="wid-250p hei-400p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
            </div>
          </div>
          <div class="wid-100 hei-80p mt-4" align="center">
            <div class=" wid-fit-content mb-4 fontSize-3 font-weight-bold">
              제목
            </div>
            <div class="wid-50p border-b-darkgrey-2px mb-4"></div>
            <div class=" wid-fit-content">
              설명이 들어갑니다.
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>