<template>
	<v-container fluid class="d-flex align-center justify-center">
    <v-layout style="background-color: white;" class="d-flex align-center justify-center">
      <div class="pa-3 wid-100" v-if="showJoinArea">
					<h1 class="">회원가입</h1>
					<div class="wid-100">
						<v-form ref="form" lazy-validation>
							<v-row dense align="center">
								<!-- <v-col cols="10" class="list-20" >
									<h3 style="text-align: left" class="mb-5">정보</h3>
									<v-text-field label="ID(필수)" :value="loginId" @change="v => loginId = v" required hide-details/>
								</v-col>
								<v-col cols="2" class="list-20" style="margin-top:50px;">
									<v-btn color="blue lighten-1 text-capitalize" midium class="white--text  text-capitalize"  @click="loginIdDupChk" hide-details>중복확인</v-btn>
								</v-col>
								<v-col cols="12" class="list-20">
									<v-text-field label="Password(필수)" type="password" :value="password" @change="v => password = v" required hide-details/>
								</v-col>
								<v-col cols="12" class="list-20">
									<v-text-field label="check Password(필수)" type="password" :value="passwordChk" @change="v => passwordChk = v" required hide-details/>
								</v-col> -->
								<v-col cols="12" class="">
									<div v-for="(main,index) in mberSctnList" :key="index">
										<div class="pb-2 pt-5">
											<h2 v-if="mberItemList.filter(e => e.mber_sctn_id == main.mber_sctn_id)">
												{{main.mber_sctn_nm}}
											</h2>
										</div>
										<div v-for="(sub,i) in mberItemList" :key="i">
											<div v-if="main.mber_sctn_id == sub.mber_sctn_id">
												<v-row dense align="center">

													<v-col cols="2" v-if="sub.mber_item_id == 'login_id'">
														<div class="pt-2">
															<span>
																{{ sub.mber_item_nm }} (필수)
															</span>
														</div>
													</v-col>
													<v-col cols="3" v-if="sub.mber_item_id == 'login_id'">
														<v-text-field 
															:placeholder="sub.ess_yn == 'Y' ? sub.mber_item_nm+'(필수)' : sub.mber_item_nm" 
															:value="loginId" 
															@change="v => loginId = v" 
															required
															outlined
															hide-details/>
													</v-col>
													<v-col cols="2" v-if="sub.mber_item_id == 'login_id'">
														<v-btn 
															small	
															color="blue lighten-1 text-capitalize" 
															class="white--text"  
															@click="loginIdDupChk"
															width="90"
															hide-details>
															중복확인
														</v-btn>
													</v-col>
													<v-col cols="5" v-if="sub.mber_item_id == 'login_id'"></v-col>
													<v-col cols="12" v-if="sub.mber_item_id == 'login_id'"><v-divider></v-divider></v-col>
													
													<!-- <v-col cols="12"  v-if="sub.mber_item_id == 'pwd'"> -->
													<v-col cols="2" v-if="sub.data_type == 'password'" class="">
														<div class="pt-2">
															<span>
																{{ sub.mber_item_nm }} (필수)
															</span>
														</div>
													</v-col>
                          <v-col cols="3" v-if="sub.data_type == 'password'">
														<v-text-field 
															:placeholder="sub.ess_yn == 'Y' ? sub.mber_item_nm+'(필수)' : sub.mber_item_nm" 
															type="password" 
															:value="password" 
															@change="v => password = v" 
															required
															outlined
															class="pt-1"
															hide-details/>
													</v-col>
													<v-col cols="7" v-if="sub.data_type == 'password'"></v-col>
													<v-col cols="12" v-if="sub.data_type == 'password'"><v-divider></v-divider></v-col>
													<!-- <v-col cols="12"  v-if="sub.mber_item_id == 'pwd'"> -->
													<v-col cols="2" v-if="sub.data_type == 'password'">
														<div class="pt-1">
															<span>
																{{ sub.mber_item_nm }} 확인 (필수)
															</span>
														</div>
													</v-col>
                          <v-col cols="3" v-if="sub.data_type == 'password'">
														<v-text-field 
															:placeholder="sub.ess_yn == 'Y' ? sub.mber_item_nm+' 확인 (필수)' : sub.mber_item_nm+' 확인'" 
															type="password" :value="passwordChk" 
															@change="v => passwordChk = v" 
															required 
															outlined
															hide-details/>
													</v-col>
													<v-col cols="7" v-if="sub.data_type == 'password'"></v-col>
													<v-col cols="12" v-if="sub.data_type == 'password'"><v-divider></v-divider></v-col>
													<!-- text-field -->

													<!-- <v-col cols="12" v-if="(sub.data_type == 'string' || sub.data_type == 'number') && (sub.mber_item_id != 'login_id' && sub.mber_item_id != 'pwd')" > -->
													<v-col cols="2" v-if="(sub.data_type == 'string' || sub.data_type == 'number') && (sub.mber_item_id != 'login_id')">
														<div class="pt-2">
															<span>
																{{ sub.mber_item_nm }}
															</span>
														</div>
													</v-col>
                          <v-col cols="3" v-if="(sub.data_type == 'string' || sub.data_type == 'number') && (sub.mber_item_id != 'login_id')" >
														<v-text-field 
															:placeholder="sub.ess_yn == 'Y' ? sub.mber_item_nm+'(필수)' : sub.mber_item_nm" 
															v-model="sub.val" 
															required
															outlined
															hide-details
															class="pt-1"/>
													</v-col>
													<v-col cols="7" v-if="(sub.data_type == 'string' || sub.data_type == 'number') && (sub.mber_item_id != 'login_id')"></v-col>
													<v-col cols="12" v-if="(sub.data_type == 'string' || sub.data_type == 'number') && (sub.mber_item_id != 'login_id')"><v-divider></v-divider></v-col>

													<!-- select -->
													<v-col cols="2" v-if="sub.data_type == 'select'">
														<div class="pt-1">
															<span>
																{{ sub.mber_item_nm }} (필수)
															</span>
														</div>
													</v-col>
													<v-col cols="3" v-if="sub.data_type == 'select'">
														<v-select
															:items="cmnCdList.filter(e => e.cls_cd == sub.mber_item_id && e.cmn_cd != '00')"
															item-value="cmn_cd"
															item-text="cmn_cd_nm"
															v-model="sub.val"
															hide-details
															outlined
															dense
															class="pt-2"
														></v-select>
													</v-col>
													<v-col cols="7" v-if="sub.data_type == 'select'"></v-col>
													<v-col cols="12" v-if="sub.data_type == 'select'"><v-divider></v-divider></v-col>

													<!-- check box -->
													<v-col cols="2" v-if="sub.data_type == 'check'">
														<div class="pt-0">
															<span>
																{{ sub.mber_item_nm }}
															</span>
														</div>
													</v-col>
													<v-col cols="3" v-if="sub.data_type == 'check'">
																<!-- <v-checkbox 
																v-for="(a,i) in cmnCdList.filter(e => e.cls_cd == sub.mber_item_id && e.cmn_cd != '00')"
																:key="i"
																:value="a.cmn_cd"
																v-model="sub.val"
																hide-details
																>
																</v-checkbox> -->
																<v-checkbox
																	v-model="sub.val"
																	hide-details
																	dense
																>
																</v-checkbox>
													</v-col>
													<v-col cols="7" v-if="sub.data_type == 'check'"></v-col>
													<v-col cols="12" v-if="sub.data_type == 'check'"><v-divider></v-divider></v-col>
													
													<!-- radio button -->
													<v-col cols="2" v-if="sub.data_type == 'radio'">
														<div class="pt-0">
															<span>
																{{ sub.mber_item_nm }}
															</span>
														</div>
													</v-col>
													<v-col cols="3" v-if="sub.data_type == 'radio'">
														<v-radio-group
															v-model="sub.val"
															row
															dense
															hide-details
															class="mt-0"
														>
															<v-radio
																v-for="(a,i) in cmnCdList.filter(e => e.cls_cd == sub.mber_item_id && e.cmn_cd != '00')"
																:key="i"
																:label="a.cmn_cd_nm"
																:value="a.cmn_cd"
																hide-details
															></v-radio>
															<!-- <v-radio
																:label="cmn_cd"
																:value="cmn_cd_nm"
															></v-radio> -->
														</v-radio-group>
													</v-col>
													<v-col cols="7" v-if="sub.data_type == 'radio'"></v-col>
													<v-col cols="12" v-if="sub.data_type == 'radio'"><v-divider></v-divider></v-col>

													<!-- file input -->
													<v-col cols="2" v-if="sub.data_type == 'file'">
														<div class="pt-0">
															<span>
																{{ sub.mber_item_nm }}
															</span>
														</div>
													</v-col>
													<v-col cols="3" v-if="sub.data_type == 'file'">
														<v-file-input
															label="File input"
															@change="uploadFileChanged($event, sub)"
														></v-file-input>
													</v-col>
													<v-col cols="7" v-if="sub.data_type == 'file'"></v-col>
													<v-col cols="12" v-if="sub.data_type == 'file'"><v-divider></v-divider></v-col>

													<!-- find adress -->
													<v-col cols="2" v-if="sub.data_type == 'zipcode'">
														<div class="pt-0">
															<span>
																{{ sub.mber_item_nm }}
															</span>
														</div>
													</v-col>
													<v-col cols="3" v-if="sub.data_type == 'zipcode'" :key="redrawCount">
															<v-text-field 
																:placeholder="sub.ess_yn == 'Y' ? sub.mber_item_nm+'(필수)' : sub.mber_item_nm" 
																v-model="sub.val" 
																required
																outlined 
																hide-details/>
													</v-col>
													<v-col cols="2" v-if="sub.data_type == 'zipcode'">
														<v-btn 
															color="blue lighten-1" 
															class="white--text text-capitalize" 
															@click="findZipcode(sub)"
															width="90"
															small
															hide-details>우편번호 찾기
														</v-btn></v-col>
													<v-col cols="5" v-if="sub.data_type == 'zipcode'"></v-col>
													<v-col cols="12" v-if="sub.data_type == 'zipcode'"><v-divider></v-divider></v-col>

													<!-- date-picker -->
													<v-col cols="2" v-if="sub.data_type == 'date'">
														<div class="pt-2">
															<span>
																{{ sub.mber_item_nm }}
															</span>
														</div>
													</v-col>
													<v-col cols="3" v-if="sub.data_type == 'date'" >
														<v-menu
															v-model="showDatePicker"
															:close-on-content-click="false"
															:nudge-right="40"
															transition="scale-transition"
															offset-y
															min-width="auto">
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	v-model="sub.val"
																	append-icon="mdi-calendar"
																	readonly
																	v-bind="attrs"
																	v-on="on"
																	hide-details
																	outlined
																	dense
																	class="pt-1"
																></v-text-field>
															</template>
															<v-date-picker
																v-model="sub.val"
																@input="showDatePicker=false"
															></v-date-picker>
														</v-menu>
													</v-col>
													<v-col cols="7" v-if="sub.data_type == 'date'"></v-col>
													<v-col cols="12" v-if="sub.data_type == 'date'"><v-divider></v-divider></v-col>

												</v-row>
											</div>
										</div>
									</div>
								</v-col>
								
								<v-col cols="2" class="mt-10">
									<v-btn 
										color="blue lighten-1 text-capitalize" 
										midium 
										block 
										class="white--text" 
										@click="joinBtnClicked">회원가입</v-btn>
								</v-col> 
							</v-row>
						</v-form>
					</div>
			</div>   
		</v-layout>
	</v-container>
	
</template>

<script>
export default {
	data(){
		return{
      redrawCount : 0,  // 화면 다시 그리기
			cmnCdList : [],
			mberSctnList : [],
			mberItemList : [],
			acctType : '',
			showJoinArea : false,
			password : '',
			passwordChk: '',
			loginId : '',
			loginIdDupYn : false,
			// row : [],
			fileList : [],
			// fileList:null,
			// dd : [],
			fileDataList : [],
			// memtest : '',
			// chkList : [],
			// date: '',
			showDatePicker : false,
			grpDiv:'',
		}
	},
	props : ['grpDivProp'],
	created(){
		const formData = new FormData();

		//테스트
		// this.grpDiv = 'grp';

		if(this.grpDivProp != '' && this.grpDivProp) {
			this.grpDiv = 'grp';
		} else {
			this.grpDiv = 'indv';
		}
		
		formData.append("grpDiv",this.grpDiv);

		this.$http.post("/admin/getJoinMberSctnAndItemList", formData).then((res) => {
				
			this.mberSctnList = res.data.sctnList;
			this.mberItemList = res.data.itemList;

			// this.subMenu = this.subMenu.filter((e) => e.mber_item_id != 'pwd' && e.mber_item_id != 'login_id');

			this.mberItemList.forEach((e) => {
				e.val = '';
				if(e.data_type == 'file')	{
				  e.val = null;
				}

        // 국적 기본값(South Korea)
        if(e.mber_item_id == 'natl') {
          e.val = "KR";
        }
			})
			this.cmnCdList = res.data.cmnCdList;
			this.showJoinArea = true;
			// this.getComnCd();
			// this.acctType = res.data.acct_type;
			this.getAcctType();
		}).catch(
			function (error) {
				console.log(error);
			}
		);

		// this.$http.get("/admin/", formData).then((res) => {
			
		// 	this.mainMenu = res.data.result;
		// 	this.getSubMenu();
		// 	this.getComnCd();
		// 	this.getAcctType();
		// }).catch(
		// 	function (error) {
		// 		console.log(error);
		// 	}.bind(this)
		// );
		
	},
	methods:{
		uploadFileChanged(file, data) {
			// data.val = file;
			// this.fileList.push({files : file, dd : data});
			
			//원본
			// console.log('dd',data);
			const mber_item_id = data.mber_item_id;
			const mber_sctn_id = data.mber_sctn_id;
			
			if(this.fileDataList.filter((e) => e.mber_item_id == mber_item_id && e.mber_sctn_id == mber_sctn_id).length == 0)	{
				// alert("새로"+this.fileList.filter((e)=>e.mber_item_id == mber_item_id && e.mber_sctn_id == mber_sctn_id).length);
				this.fileDataList.push( { mber_item_id: data.mber_item_id, mber_sctn_id: data.mber_sctn_id, ff: null } );
			}
		
			this.fileDataList.forEach((e) => {
				if( e.mber_item_id == mber_item_id && e.mber_sctn_id == mber_sctn_id ) {
					e.ff = file;
				}

			})

			this.fileDataList = this.fileDataList.filter((e) => e.ff != null);			
			this.fileList = this.fileDataList.map((e) => e.ff);

		},
		getAcctType() {
			const formData = new FormData();
			this.$http.post("/admin/getSoctySiteOptn", formData).then((res) => {
				// const result = res.data.result;
				// const acct_type = result.acct_type;
				// this.acctType = acct_type;
				this.acctType = res.data.result[0].acct_type;
        console.log("acctType: " + this.acctType);
			}).catch(
				function (error) {
					console.log(error);
				}
			);
		},
		// getComnCd() {
		// 	const formData = new FormData();
		// 	this.$http.post("/common/getComnCd", formData).then((res) => {
		// 		this.cmnCdList = res.data.result;
		// 		this.showJoinArea = true;
		// 	}).catch(
		// 		function (error) {
		// 			console.log(error);
		// 		}
		// 	);	
		// },
		// getMberItem() {
		// 	const formData = new FormData();
		// 	this.$http.get("/admin/getMemSubMenu", formData).then((res) => {
				
		// 		this.mberItem = res.data.result;
		// 		this.mberItem.forEach((e) => {
		// 			e.val = '';
		// 			if(e.data_type == 'file')	{
		// 				e.val = null;
		// 			}
		// 		})
		// 		// this.data();
		// 	}).catch(function(error) {
		// 			console.log(error);
		// 		}
		// 	);	
		// },

		joinBtnClicked() {
			// console.log("파라미터 테스트");
			// console.log(this.chkList);
			let isValid = true;

			if(this.loginId.trim() == '') {
				alert('필수값 입력되지 않았음. (ID)');
				return;
			}else if(this.acctType == 'id') {
				if(this.$validateId(this.loginId)) {
					alert('대소문자/숫자만 입력 가능합니다.(ID)');
					return;
				}
				
			}else if(this.acctType == 'email') {
				if(!this.$validateEmail(this.loginId)) {
					alert('이메일 형식이 아닙니다.(ID)');
					return;
				}
			
			}else if(!this.loginIdDupYn) {
				alert('아이디 중복체크 해주세요.');
				return;
			}else if(this.password.trim() == '') {
				alert('필수값 입력되지 않았음. (Password)');
				return;
			}else if(this.passwordChk.trim() == '') {
				alert('필수값 입력되지 않았음. (check Password)');
				return;
			}else if(this.password != this.passwordChk) {
				alert('비밀번호가 같지 않습니다.');
				return;
			}

			this.mberItemList.some((e) => {
				if(e.data_type == 'number') {
					if(!this.$includeHangle(e.val) || !this.$includeAlphaChar(e.val)) {
						alert("number타입입니다. (" + e.mber_item_nm + ")");
						isValid = false;
						return true;
					}
				}

				if(e.mber_item_id =='pwd')
				{
					e.val = this.password;
				} else if(e.mber_item_id =='login_id') {
					e.val = this.loginId;
				}
				
				if(e.ess_yn == 'Y' && !e.val) {
					alert('필수값 입력되지 않았음. (' + e.mber_item_nm + ")");
					isValid = false;
					return true;
				}
			})
			
			if(isValid) {
				//유효성 체크 성공
				
				// let params = new URLSearchParams();
				const params = new FormData();
				params.append("mberSctnList", JSON.stringify(this.mberSctnList));

				this.mberItemList.push({mber_sctn_id: 'mber_basic' ,  mber_item_id: "mber_div", val: this.grpDiv, data_type: "string"})

        // 테스트 코드
        console.log("mberItemList:", this.mberItemList);
        // let cond = true;
        // if(cond)  return;

      	params.append("mberItemList", JSON.stringify(this.mberItemList));
				params.append("login_id", this.loginId);
				params.append("pwd", this.password);
				// params.append("file_list",this.fileList);
				this.fileList.forEach((file) => {
					params.append('file_list', file);
				});
				params.append("fileDataList", JSON.stringify(this.fileDataList));
        		this.$http.post("/admin/insertMber", params, {
					headers: 
					{
						'Content-Type': 'multipart/form-data'
					}
				}).then((res) => {
					console.log(res.data);
					if(res.data.code == 0)
					{
						alert("회원가입 성공");
						this.$router.replace('/admin');
					}else
					{
						alert("회원가입 실패");
					}
				}).catch(
					function(error) {
						console.log(error);
					}
				);
			}
		},
		// hanglChk(value) {
		// 	const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
		// 	if (regex.test(value) === true) return false;
		// 	return true;
		// },
		// numberChk(value) {
		// 	const regex = /[a-zA-Z]/;
		// 	if (regex.test(value) === true) return false;
		// 	return true;
		// },
		// idChk(value) {
		// 	const regex = /^[A-Za-z0-9]*$/;
		// 	if (regex.test(value) === true) return false;
		// 	return true;
		// },
		// emailChk(str) {
		// 	var reg_email = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

		// 	if(!reg_email.test(str)) {      
		// 		return false;         
		// 	} else {
		// 		return true;         
		// 	}
		// },
		loginIdDupChk() {
			if(!this.loginId.trim()) {
				alert('아이디를 입력해주세요.');
				return;
			}

			if(!this.$validateEmail(this.loginId)) {
				alert('이메일 형식이 아닙니다.(ID)');
				return;
			}
			
			const formData = new FormData();
			formData.append("login_id", this.loginId);
			this.$http.post("/common/getLoginIdDupChk", formData).then( (res) => {
				if(res.data.code < 0)
				{
					alert(this.$erroCdMsg(res.data.code));
					return;
				}else
				{
					alert('사용가능합니다.');
				}
				this.loginIdDupYn = true;
			}).catch(
				function(error) {
					console.log(error);
				}.bind(this)
			);	

		},
		findZipcode(sub) {
			new window.daum.Postcode({
				oncomplete: (data) => {
					sub.val = data.zonecode;

					this.mberItemList.forEach((e) => {
						
						if(e.mber_item_id == 'addr')	{
							e.val = data.roadAddress;
						}
					
					})

					// sub.roadAddress = data.roadAddress;
					// sub.jibunAddress = data.jibunAddress;
					this.redrawCount++;
					
				},
			}).open();
		}
	}
}

</script>


<style>
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner{
	margin-top:4px !important;
}
</style>
