<template>
  <v-container fluid class="pl-3 pr-3 pt-0" v-if="showYn">
    <v-row class="pa-0 pt-2">
      <v-spacer></v-spacer>
      <v-col cols="6" align="right" class="pa-0" v-show="windowWidth > 767">
        <v-btn
          small
          color="blue lighten-2"
          class="elevation-0 bg-lightgrey font-weight-bold white--text"
          v-show="modifyYn "
          @click="delBtnClicked">
          삭제
        </v-btn>
        <v-btn
          small
          color="blue lighten-2"
          class="elevation-0 bg-lightgrey font-weight-bold ml-2 white--text"
          v-show="modifyYn "
          @click="modifyBtnClicked">
          수정
        </v-btn>
        <v-btn
          small
          outlined
          class="elevation-0 bg-lightgrey font-weight-bold ml-2"
          @click="listPageClicked">
          목록
        </v-btn>
        <v-btn
          small
          outlined
          class="elevation-0 bg-lightgrey font-weight-bold ml-2"
          @click="answerBtnClicked"
          v-show="answerBtnYn ">
          답변
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col cols="12" class="pa-0 pt-1">
        <span
          style="font-size: 1.5em; margin-left: 12px;" 
          class="font-weight-bold dreamFont4" :class="windowWidth > 768 ? 'detailTitleWord':'mobDetailTitleWord'">{{qnaBbsMap.bbst_ttl}}</span>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px pa-0"></v-col>
    </v-row>

    <v-col cols="12"><v-divider></v-divider></v-col>
    <v-row class="mb-3">
      
        <v-col cols="12" md="3" sm="3" class="pt-2 pb-2">
          <span>등록자 : </span><span class="mr-4">{{qnaBbsMap.reg_mber_nm}}</span>
        </v-col>
        <v-col cols="12" md="4" sm="4" class="pt-2 pb-2">
          <span >등록일시 : </span><span class="mr-4">{{qnaBbsMap.reg_dt}}</span>
        </v-col>
        <v-col cols="12" md="2" sm="2" class="pt-2 pb-2">
          <span >조회수 : </span><span class="mr-4">{{qnaBbsMap.read_cnt}}</span>
        </v-col>
        <v-col cols="12" md="3" sm="3" class="pt-2 pb-2" v-if="qnaBbsMap && qnaBbsMap.bbst_catg">
          <span >카테고리 : </span><span class="mr-4">{{qnaBbsMap.bbst_catg}}</span>
        </v-col>
        <div class="wid-100 d-flex border-b-grey"></div>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <!-- html 영역 -->
        <div style="height:500px;overflow:scroll;" class="tiny" v-html="qnaBbsMap.bbst_cn"></div>
      </v-col>
      <v-col cols="12" class="border-b-grey"></v-col>
    </v-row>
    <!-- <v-row class="mt-4 pb-4 border-b-darkgrey" >
      <v-col cols="12" class="ma-0 pa-0">
        file nm
        <a href="" download="">
          <v-btn
            small
            icon
            dense
            color="blue lighten-2"
            class="white--text ma-0 pa-0">
            <v-icon>mdi-cloud-arrow-down-outline</v-icon>
          </v-btn>
        </a>
      </v-col>
    </v-row> -->
    
    <v-row class="mt-4 pb-4" v-if="con_type == 'main'">
      <v-col cols="12" class="ma-0 pa-0" v-for="(item, idx) in fileAtchList" :key="`fileAtch_`+idx">
        {{item.orgnl_file_nm}}
        <a :href="item.save_path+item.save_file_nm" :download="item.orgnl_file_nm">
          <v-btn
            small
            icon
            dense
            color="blue lighten-2"
            class="white--text ma-0 pa-0"
          ><v-icon>mdi-cloud-arrow-down-outline</v-icon></v-btn>
        </a>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" class="ma-0 pa-0 border-b-darkgrey">
      </v-col>
    </v-row>

    <!-- [[[[[[ 답변 영역 ]]]]]] -->
    <v-row class="mt-10" v-show="answerYn">
      <v-row>
        <v-col cols="3">
          <h1>답변</h1>
        </v-col>
      </v-row>
      <v-col cols="12" class="" style="border-bottom: 2px solid #585858;"></v-col>
      <v-col cols="12" class="pl-0">
        <!-- <span><v-icon class="mr-2" color="#B43104">mdi-tooltip-check</v-icon><span>글쓰기 할 때 주의할 내용이 있으면 이 곳에 내용이 들어갑니다.</span></span> -->
      </v-col>
      <v-row>
        <v-col cols="12">
          <v-text-field 
            class="f-l pa-0 ma-0 wid-100 input-hei-d bg-lightblue" 
            outlined
            dense
            hide-details
            placeholder="제목 *"
            v-model="replyMap.reply_ttl"
            ref="reply_ttl"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row v-if="!mberSno">
        <v-col cols="12">
          <v-text-field 
            class="f-l pa-0 ma-0 wid-100 input-hei-d bg-lightblue" 
            outlined
            dense
            hide-details
            placeholder="이름 *">
          </v-text-field>
        </v-col>
      </v-row> -->
      
      <v-row class="mb-3"> 
        <v-col cols="12">
          <tiny-editor box_height="600" ref="tiny" v-model="replyMap.reply_cn"></tiny-editor>
        </v-col>
      </v-row>

      <v-row align="center"> 
        <v-col cols="6">
          <v-btn
            class="white--text wid-100 elevation-0 theme-color-1 font-weight-bold"
            @click="replyBtnClicked"
          >
            글 올리기
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="wid-100 elevation-0 bg-grey-2 white--text font-weight-bold">
            취소
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
  
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';
export default {
    components: {
      tinyEditor
    },
  data() {
    return {
      windowWidth: window.innerWidth,
      answerYn: false,
      con_type: this.$route.query.con_type,
      sno: this.$route.query.sno,
      basicUri: '',
      preUrl: '',
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      qnaBbsMap: [],
      page_type: '',
      emitData: {},
      answerBtnYn: false,
      modifyYn: false,
      mberMap: {},
      replyMap: {},
      showYn: false,
      pageNo: this.$route.query.pageNo ? this.$route.query.pageNo : '',
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    let path = this.$route.path;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    this.preUrl = this.basicUri+'/list?pageNo='+this.pageNo;

    if(!this.con_type || !this.sno) {
      alert('잘못된 접근입니다.');
      this.$router.push(this.preUrl);
    }

    const params = new FormData();
    params.append("mber_sno", this.mberSno);
    params.append("non_mber_yn", this.nonMberYn);
    params.append("con_type", this.con_type);
    params.append("sno", this.sno);

    this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
      console.log(res.data);
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push(this.preUrl);
      } else {
        this.qnaBbsMap = res.data.qnaBbsMap;
        this.page_type = this.qnaBbsMap.page_type;
        const mberMap = res.data.mberMap;
        if(this.con_type == 'main' && mberMap && (mberMap.mber_priv == 'socty_admin' || mberMap.mber_priv == 'sys_admin')) {
          this.answerBtnYn = true;
        }
        
        if(this.qnaBbsMap.bbst_catg) {
          this.qnaBbsMap.bbst_ttl = '['+this.qnaBbsMap.bbst_catg+'] '+this.qnaBbsMap.bbst_ttl;
          this.replyMap.reply_ttl = '[답변]'+this.qnaBbsMap.bbst_ttl;
        } else {
          this.replyMap.reply_ttl = '[답변]'+this.qnaBbsMap.bbst_ttl;
        }

        //작성자와 로그인사람 같은지 확인
        if(this.mberSno == this.qnaBbsMap.reg_mber_sno) {
          this.modifyYn = true;
        }
        this.fileAtchList = res.data.fileAtchList;
        // this.chg_perm_yn = res.data.chg_perm_yn;
 
      }

      this.emitData.menu_sno = res.data.menu_sno;
      this.$emit('setData', this.emitData);
      this.showYn = true;

    })
    .catch(function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    answerBtnClicked() {
      this.answerYn = true;
      // console.log(this.$refs.reply_ttl);
      setTimeout(() => {
        this.$refs.reply_ttl.focus();
      }, 0)
      
    },//answerBtnClicked end
    listPageClicked() {
      this.$router.push(this.preUrl);
    },//listPageClicked end
    replyBtnClicked() {
      console.log(this.replyMap);
      if(this.validateInput()) {
        const params = new FormData();

        this.replyMap.bbst_sno  = this.sno;
        this.replyMap.mber_sno  = this.mberSno;
        this.replyMap.upper_reply_sno  = 0;

        params.append("replyMap", JSON.stringify(this.replyMap));

        this.$http.post('/bbs/insertQnaReply', params).then((res) => {
          console.log(res.data);
          if(res.data.code < 0) {
            alert(this.$erroCdMsg(res.data.code));
            this.$router.push(this.preUrl);
          } else {
            alert('답변 등록되었습니다.');
            this.$router.push(this.preUrl);
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
      // if
    },//replyBtnClicked end
    validateInput() {
      // console.log("this.replyMap",this.replyMap);
      if(!this.replyMap.reply_ttl) {
        alert('답변 제목은 필수 입력값입니다.');
        return false;
      } else if(!this.replyMap.reply_cn) {
        alert('답변 내용은 필수 입력값입니다.');
        return false;
      }
      return true;
    },//validateInput end
    delBtnClicked() {
      if(confirm('정말 삭제하시겠습니까?')) {
        if(this.con_type == 'main') {
          this.bbsDelFnc();
        } else if(this.con_type == 'reply') {
          this.replyDelFnc();
        }
      }
    },//delBtnClicked end
    bbsDelFnc() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("bbst_sno", this.sno);

      this.$http.post('/bbs/updateBbsDelYn', params).then((res) => {
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          return;
        } else {
          alert('삭제되었습니다.');
          this.$router.push(this.preUrl);
        }
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//bbsDelFnc end
    replyDelFnc() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("reply_sno", this.sno);

      this.$http.post('/bbs/deleteQnaReply', params).then((res) => {
        
        console.log(res.data);

        if(res.data.code < 0 ) {
          alert(this.$erroCdMsg(res.data.code));
        } else {
          alert('삭제되었습니다.');
          this.$router.push(this.preUrl);
        }
        
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//replyDelFnc end
    modifyBtnClicked() {
      const moveUrl = this.basicUri+'/modify?sno='+this.sno+'&con_type='+this.con_type;
      this.$router.push(moveUrl);
    },//modifyBtnClicked end
  }
}
</script>

<style scoped>

.detailTitleWord {
  display:inline-block; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  word-break: break-all;
  max-width:850px;
}

.mobDetailTitleWord {
  display:inline-block; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  word-break: break-all;
  max-width:440px;
}

.dreamFont3 {
  font-family: dreamFont3 !important;
}

.dreamFont4 {
  font-family: dreamFont4 !important;
}

::v-deep .v-btn__content {
  font-family: dreamFont3 !important;
}

::v-deep .pt-2 > span {
  font-family: dreamFont3 !important;
}

::v-deep .wid-100 > span {
  font-family: dreamFont3 !important;
}

::v-deep .wid-100 > a > span {
  font-family: dreamFont3 !important;
}
</style>