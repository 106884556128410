<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <!-- 저자 소속 -->
        <v-row dense >
          <v-col cols="12">
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBelongBtnClicked"
              >추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBelongBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div>
              <v-data-table
                :headers="belongGridHeaders"
                :items="pendingBelongList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination>
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.blng_no`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.blng_no"
                    hide-details
                    outlined
                    dense
                    @change="belongGridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.blng_nm`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.blng_nm"
                    hide-details
                    outlined
                    dense
                    @change="belongGridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.blng_nm_en`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.blng_nm_en"
                    hide-details
                    outlined
                    dense
                    @change="belongGridItemChanged(item)"
                  ></v-text-field>
							  </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
        <!-- 저자 -->
        <v-row dense >
          <v-col cols="12">
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >
                추가
              </v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >
                선택 삭제
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div>
              <v-data-table
                :headers="authorGridHeaders"
                :items="pendingAuthorList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.athor_nm`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.athor_nm"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.athor_nm_en_frst`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.athor_nm_en_frst"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.athor_nm_en_last`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.athor_nm_en_last"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.athor_email`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.athor_email"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.athor_tel_no`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.athor_tel_no"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.athor_role`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.athor_role"
                    :items="athorRoleList"
                    item-text="cmn_cd_nm"
                    item-value="cmn_cd"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.ptr_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.ptr_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                    @change="gridPtrYnChanged(item)"
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.corr_athor_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.corr_athor_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                    @change="gridItemChanged(item)"
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.disp_odr`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.disp_odr"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.blng_nos`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.blng_nos"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ["propParams"],
  data(){
    return {
      mberSno: null,
      confrSno: null,
      abstrRegSno: null,
      // newYn: 'N',

      belongGridHeaders:[
        { text: '선택', value:'select_yn', width:"5%"},
        { text: '소속 번호 *', value:'blng_no', width:"10%"},
        { text: '소속명 *', value:'blng_nm', width:"30%"},
        { text: '소속명(영문)', value:'blng_nm_en', width:"55%"},
      ],
      // belongGridList: [],
      belongList: [],
      pendingBelongList: [],
      belongChangedList: [],

      authorGridHeaders:[
        { text: '선택', value:'select_yn', width:"5%"},
        { text: '이름 *', value:'athor_nm', width:"7%"},
        { text: '영문(First)', value:'athor_nm_en_frst', width:"10%"},
        { text: '영문(Last)', value:'athor_nm_en_last', width:"10%"},
        { text: '이메일 *', value:'athor_email', width:"10%"},
        { text: '전화번호', value:'athor_tel_no', width:"10%"},
        { text: '저자 역할 *', value:'athor_role', width:"10%"},
        { text: '발표자', value:'ptr_yn', width:"5%"},
        { text: '교신저자', value:'corr_athor_yn', width:"6%"},
        { text: '표시 순서', value:'disp_odr', width:"7%"},
        { text: '소속', value:'blng_no', width:"7%"},
      ],
      // gridList: [],
      authorList: [],
      pendingAuthorList: [],
      authorChangedList: [],
      athorRoleList: [],
      athorBlngList: [],
    }
  },
  computed: {
    isOnPending() {
      return JSON.stringify(this.belongList) !== JSON.stringify(this.pendingBelongList) ||
        JSON.stringify(this.authorList) !== JSON.stringify(this.pendingAuthorList);
    }
  },
  watch: {
    isOnPending: {
      immediate: true,
      handler: function () {
        console.log("watch isOnPending", this.isOnPending);
        this.$emit('tabChanged', 'abstrRegistAuthorTab', this.isOnPending);
      },
    }
  },
  created() {
    this.mberSno = this.propParams.mberSno;
    this.confrSno = this.propParams.confrSno; 
    this.abstrRegSno = this.propParams.abstrRegSno; 

    this.getConfrAbstrRegAthorBlngList();

    this.getAthorRoleList();
    this.getAthorBlngList();
    this.getConfrAbstrRegAthorList();
  },
  methods: {
    async getAthorRoleList() {
      this.athorRoleList = await this.$getCmnCdList('athor_role');
    },
    getAthorBlngList() {
      console.log("abstrRegistAuthorTab > getAthorBlngList");
      const params = new FormData();
      params.append("confr_sno", this.confrSno);
      params.append("abstr_reg_sno", this.abstrRegSno);

			this.$http.post("/admin/getConfrAbstrRegAthorBlngList", params).then((res) => {
        // console.log("res.data: ", res.data);
        this.athorBlngList = res.data.result.abstrRegAthorBlngList;
        console.log("athorBlngList:" , this.athorBlngList);
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    // 소속 그리드 수정됨
    belongGridItemChanged(item) {
      console.log("belongGridItemChanged : item.abstr_reg_sno : " + item.abstr_reg_sno);
      // 추가된 목록인 경우 -> [추가] 시 authorChangedList에도 추가함
      if(item.abstr_reg_sno == null || item.abstr_reg_sno == "") {
        return;
      }

      let found = false;
      this.belongChangedList.forEach((e) => {
        if(e.abstr_reg_sno == item.abstr_reg_sno && e.odr == item.odr) {
          found = true;
        }
      });

      if(!found) {
        this.belongChangedList.push(item);
      }

      // this.itemChanged();
    },
    // 소속 삭제 버튼 클릭
    deleteBelongBtnClicked() {
      console.log("deleteConfrAbstrRegAthorBlng");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.pendingBelongList.length; i++) {
      //   if(this.pendingBelongList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.pendingBelongList[i].new_yn) {
      //       deleteList.push(this.pendingBelongList[i]);
      //     }
      //   }
      // }

      deleteList = this.pendingBelongList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteConfrAbstrRegAthorBlngList", params).then((res) => {
            console.log(res.data);
            // this.getConfrAbstrRegAthorBlngList();
            // this.$emit('belongChanged');
            this.pendingBelongList = this.pendingBelongList.filter((e) => {
              return !(e.select_yn);
            });

            this.belongChangedList = this.belongChangedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getConfrAbstrRegAthorBlngList();
          this.pendingBelongList = this.pendingBelongList.filter((e) => {
            return !(e.select_yn);
          });

          this.belongChangedList = this.belongChangedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    // 소속 추가 버튼 클릭
    addBelongBtnClicked() {
      let maxOdr = 0;
      let maxBlngNo = 0;

      this.pendingBelongList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }

        if(e.blng_no > maxBlngNo) {
          maxBlngNo = e.odr;
        }
      });

      maxOdr++;
      maxBlngNo++;

      this.pendingBelongList.push({new_yn:true, select_yn:false, odr:maxOdr, abstr_reg_sno:this.abstrRegSno, blng_no:maxBlngNo, blng_nm:"", blng_nm_en:""});
      this.belongChangedList.push(this.pendingBelongList[this.pendingBelongList.length - 1]);
    },
    // 소속 그리드 조회
    getConfrAbstrRegAthorBlngList() {
      const params = new FormData();
      params.append("confr_sno", this.confrSno);
      params.append("abstr_reg_sno", this.abstrRegSno);

			this.$http.post("/admin/getConfrAbstrRegAthorBlngList", params).then((res) => {
        console.log("res.data: ", res.data);

        this.belongList = JSON.parse(JSON.stringify(res.data.result.abstrRegAthorBlngList, function(k, v) {return v == null ? "" : v}));
        this.pendingBelongList = JSON.parse(JSON.stringify(this.belongList));
        this.belongChangedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    // 소속 그리드 입력 데이터 검증
    validateBelongInput() {
      for(let i = 0; i < this.belongChangedList.length; i++) {
        let e = this.belongChangedList[i];
        // console.log("e:", e);

        // 소속번호
        if(this.regItemSet.blng_no.ess_yn && (e.blng_no == null || e.blng_no == "")) {
          alert('소속번호는 필수 입력값입니다.');
          return false;
        }
        if(!this.$includeHangle(e.blng_no) || !this.$includeAlphaChar(e.blng_no)) {
          alert("소속번호는 숫자만 입력 가능합니다.");
          return false;
        }
        // 소속명
        if(this.regItemSet.blng_nm.ess_yn && (e.blng_nm == null || e.blng_nm == "")) {
          alert('소속(한글)은 필수 입력값입니다.');
          return false;
        }
        // 소속명(영문)
        if(this.regItemSet.blng_nm_en.ess_yn && (e.blng_nm_en == null || e.blng_nm_en == "")) {
          alert('소속(영문)은 필수 입력값입니다.');
          return false;
        }
      }

      return true;
    },
    // 소속 그리드 저장
    // saveBelongBtnClicked() {
    //   console.log("saveConfrAbstrRegAthorBlng");

    //   if(this.belongChangedList.length == 0) {
    //     // alert("수정된 내용이 없습니다.");
    //     return;
    //   }

    //   // true/false -> 'Y'/'N' 변환
    //   this.belongChangedList.forEach((e) => {
    //     e.new_yn = e.new_yn == true ? 'Y' : 'N';
    //   });

    //   // if(confirm("저장하시겠습니까?")) {
    //     let params = new URLSearchParams();

    //     params.append("belongChangedList", JSON.stringify(this.belongChangedList));

    //     this.$http.post("/admin/upsertConfrAbstrRegAthorBlngList", params).then((res) => {
    //       console.log(res.data);
    //       // alert("저장되었습니다.");
    //       this.getConfrAbstrRegAthorBlngList();
    //       // this.$emit('belongChanged');
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //       }
    //     );
    //   // }
    // },
    // 저자 그리드 수정됨
    gridItemChanged(item) {
      console.log("gridItemChanged : item.abstr_reg_sno : " + item.abstr_reg_sno);
      // 추가된 목록인 경우 -> [추가] 시 authorChangedList에도 추가함
      if(item.abstr_reg_sno == null || item.abstr_reg_sno == "") {
        return;
      }

      let found = false;
      this.authorChangedList.forEach((e) => {
        if(e.abstr_reg_sno == item.abstr_reg_sno && e.odr == item.odr) {
          found = true;
        }
      });

      if(!found) {
        this.authorChangedList.push(item);
      }

      // this.itemChanged();
    },
    // 저자정보 > 발표자 여부 수정됨 -> isChecked 이면 다른 item.ptr_yn = false 업데이트
    gridPtrYnChanged(item) {
      // console.log("gridPtrYnChanged item : ", item);
      if(item.ptr_yn) {
        this.pendingAuthorList.forEach(e => {
          // console.log("gridPtrYnChanged e : ", e);
          if(e.odr != item.odr && e.ptr_yn) {
            e.ptr_yn = false;
            this.gridItemChanged(e);
          }
        })
      }

      this.gridItemChanged(item);
    },
    deleteBtnClicked() {
      console.log("deleteConfrAbstrRegAthor");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.pendingAuthorList.length; i++) {
      //   if(this.pendingAuthorList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.pendingAuthorList[i].new_yn) {
      //       deleteList.push(this.pendingAuthorList[i]);
      //     }
      //   }
      // }
      deleteList = this.pendingAuthorList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: abstr_reg_sno[" + e.abstr_reg_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteConfrAbstrRegAthorList", params).then((res) => {
            console.log(res.data);
            // this.getConfrAbstrRegAthorList();
            this.pendingAuthorList = this.pendingAuthorList.filter((e) => {
              return !(e.select_yn);
            });

            this.authorChangedList = this.authorChangedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getConfrAbstrRegAthorList();
          this.pendingAuthorList = this.pendingAuthorList.filter((e) => {
            return !(e.select_yn);
          });

          this.authorChangedList = this.authorChangedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;

      this.pendingAuthorList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.pendingAuthorList.push({new_yn:true, select_yn:false, odr:maxOdr, abstr_reg_sno:this.abstrRegSno, athor_nm:"", athor_nm_en_frst:"", athor_nm_en_last:"",
                          athor_email:"", athor_tel_no:"", athor_role:"", ptr_yn:false, corr_athor_yn:false, disp_odr:"", blng_nos:"", blng_nm:""});
      this.authorChangedList.push(this.pendingAuthorList[this.pendingAuthorList.length - 1]);
    },
    getConfrAbstrRegAthorList() {
      const params = new FormData();

      params.append("confr_sno", this.confrSno);
      params.append("abstr_reg_sno", this.abstrRegSno);
      params.append("reg_div", 'abstr');

			this.$http.post("/admin/getConfrAbstrRegAthorList", params).then((res) => {
        console.log("res.data: ", res.data);

        this.regItemList = res.data.result.regItemList;
        let regItemSet = {};

        this.belongGridHeaders = [{text: '선택', value: 'select_yn', width: '20px', fixed: true, sortable: false}];
        this.authorGridHeaders = [{text: '선택', value: 'select_yn', width: '30px', sortable: false}];

        res.data.result.regItemList.forEach((e) => {
          console.log(e);
          regItemSet[e.reg_item_id] = {reg_item_nm: e.reg_item_nm, reg_sctn_nm: e.reg_sctn_nm, use_yn: e.use_yn, ess_yn: e.ess_yn};
          // console.log(e.reg_item_id, e.ess_yn);

          // 저자소속 그리드 헤더 설정
          if(e.reg_sctn_nm == "저자소속") {
            if(e.use_yn) {
              let essStr = e.ess_yn ? " *" : "";
              let width = 10;

              switch(e.reg_item_id) {
                case 'blng_no' :
                  width = '100px';
                  break;
                case 'blng_nm' :
                  width = '300px';
                  break;
                case 'blng_nm_en' :
                  width = '500px';
                  break;
              }  

              this.belongGridHeaders.push({text: e.reg_item_nm + essStr, value: e.reg_item_id, width: width, fixed: true, sortable: false});
            }
          }

          // 저자정보 그리드 헤더 설정
          if(e.reg_sctn_nm == "저자정보") {
            if(e.use_yn) {
              let essStr = e.ess_yn ? " *" : "";
              let width = 10;

              switch(e.reg_item_id) {
                case 'athor_nm' :
                  width = '100px';
                  break;
                case 'athor_nm_en_frst' :
                  width = '120px';
                  break;
                case 'athor_nm_en_last' :
                  width = '110px';
                  break;
                case 'athor_email' :
                  width = '200px';
                  break;
                case 'athor_tel_no' :
                  width = '150px';
                  break;
                case 'athor_role' :
                  width = '140px';
                  break;
                case 'ptr_yn' :
                  width = '65px';
                  break;
                case 'corr_athor_yn' :
                  width = '60px';
                  break;
                case 'disp_odr' :
                  width = '50px';
                  break;
                case 'blng_nos' :
                  width = '80px';
                  break;
              }  

              this.authorGridHeaders.push({text: e.reg_item_nm + essStr, value: e.reg_item_id, width: width, fixed: true, sortable: false});
            }
          }
        });
        // console.log("regItemSet : ", regItemSet);
        this.regItemSet = regItemSet;

        res.data.result.abstrRegAthorList.forEach((e) => {
          e.ptr_yn = e.ptr_yn == 'Y' ? true : false;
          e.corr_athor_yn = e.corr_athor_yn == 'Y' ? true : false;
        });

        this.authorList = JSON.parse(JSON.stringify(res.data.result.abstrRegAthorList, function(k, v) {return v == null ? "" : v}));
        this.pendingAuthorList = JSON.parse(JSON.stringify(this.authorList));
        this.authorChangedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateAuthorInput() {
      for(let i = 0; i < this.authorChangedList.length; i++) {
        let e = this.authorChangedList[i];
        // console.log("e:", e);

        // 이름
        if(this.regItemSet.athor_nm.ess_yn && (e.athor_nm == null || e.athor_nm == "")) {
          alert('이름은 필수 입력값입니다.');
          return false;
        }
        // 영문이름(First)
        if(this.regItemSet.athor_nm_en_frst.ess_yn && (e.athor_nm_en_frst == null || e.athor_nm_en_frst == "")) {
          alert('영문이름(First)은 필수 입력값입니다.');
          return false;
        }
        // 영문이름(Last)
        if(this.regItemSet.athor_nm_en_last.ess_yn && (e.athor_nm_en_last == null || e.athor_nm_en_last == "")) {
          alert('영문이름(Last)은 필수 입력값입니다.');
          return false;
        }
        // 이메일
        if(this.regItemSet.athor_email.ess_yn && (e.athor_email == null || e.athor_email == "")) {
          alert('이메일은 필수 입력값입니다.');
          return false;
        }
        if((e.athor_email != null && e.athor_email != "") && !this.$validateEmail(e.athor_email)) {
          alert('이메일 형식이 아닙니다.');
          return false;
        }
        // 저자 역할
        if(this.regItemSet.athor_role.ess_yn && (e.athor_role == null || e.athor_role == "")) {
          alert('저자 역할은 필수 입력값입니다.');
          return false;
        }
        // 순서
        if(this.regItemSet.disp_odr.ess_yn && (e.disp_odr == null || e.disp_odr == "")) {
          alert('순서는 필수 입력값입니다.');
          return false;
        }
        // 소속번호
        if(this.regItemSet.blng_nos.ess_yn && (e.blng_nos == null || e.blng_nos == "")) {
          alert('소속번호는 필수 입력값입니다.');
          return false;
        }
        if(e.blng_nos != null && e.blng_nos != "") {
          let blngNoList = e.blng_nos.split(",");
          let errorList = [];
          // console.log("blngNoList:", blngNoList);
          
          blngNoList.forEach((blng_no) => {
            let isValid = false;
            this.pendingBelongList.forEach((e) => {
              if(Number(blng_no) == e.blng_no) {
                isValid = true;
              }
            });

            if(!isValid) {
              errorList.push(blng_no);
            }
          });

          // console.log("errorList:", errorList);
          if(errorList.length > 0) {
            alert('저자 소속에 유효하지 않은 소속번호(' + errorList.join() + ')가 있습니다.');
            return false;
          }
        }
      }

      // 발표자, 교신저자 필수 입력 체크
      let ptr_cnt = 0;
      let corr_athor_cnt = 0;

      for(let j = 0; j < this.pendingAuthorList.length; j++) {
        const athor = this.pendingAuthorList[j];          
        console.log("athor : ", athor);
        if(athor.ptr_yn)         ptr_cnt++;
        if(athor.corr_athor_yn)  corr_athor_cnt++;
      }

      if(this.regItemSet['ptr_yn'].ess_yn == true && ptr_cnt <= 0) {
        alert("발표자는 필수 입력값입니다.");
        return false;
      }

      if(this.regItemSet['corr_athor_yn'].ess_yn == true && corr_athor_cnt <= 0) {
        alert("교신저자는 필수 입력값입니다.");
        return false;
      }

      return true;
    },
    validateInput() {
      if(!this.validateBelongInput()) return false;
      if(!this.validateAuthorInput()) return false;

      return true;
    },
    saveBtnClicked() {
      console.log("saveConfrAbstrRegAthor");

      if(this.authorChangedList.length == 0 && this.belongChangedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      // true/false -> 'Y'/'N' 변환
      this.authorChangedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
        e.ptr_yn = e.ptr_yn == true ? 'Y' : 'N';
        e.corr_athor_yn = e.corr_athor_yn == true ? 'Y' : 'N';
        e.disp_odr = e.disp_odr == '' ? null : e.disp_odr;
      });

      this.belongChangedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
      });

      // if(confirm("저장하시겠습니까?")) {
        let params = new URLSearchParams();

        params.append("authorChangedList", JSON.stringify(this.authorChangedList));
        params.append("belongChangedList", JSON.stringify(this.belongChangedList));

        this.$http.post("/admin/upsertConfrAbstrRegAthorList", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          this.getConfrAbstrRegAthorList();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      // console.log("itemChanged");
      // this.$emit('tabChanged', 'abstrRegistAuthorTab', this.isOnPending);
    },
  }
}
</script>

<style>

</style>