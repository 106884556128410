<template>
  <v-container fluid>
    <v-row
      class="mt-2 mb-2"
      align="center"
      justify="center">
      <v-col cols="8" class="d-flex align-center">
        <div class="wid-150p mr-2">
          <v-select
            outlined
            flat
            solo
            dense
            hide-details
            style="font-size: 13px;"
            class="dreamFont4"
            :items="filterList"
            item-value="menu_sno"
            item-text="menu_nm"
            v-model="defaultFilter">
          </v-select>
        </div>
        <div class="wid-400p mr-2">
          <v-text-field
            outlined 
            flat
            solo
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            class="dreamFont4"
            v-model="keyword"
            @keyup.enter="searchBtnClicked"
          >
          </v-text-field>
        </div>
        <div>
          <v-btn
            outlined
            height="30px"
            style="font-size: 13px;"
            class="dreamFont4"
            @click="searchBtnClicked"
          >
            검색
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 d-flex align-center">
        <h2 class="mr-3 dreamFont4">검색결과</h2>
        <span class="dreamFont4">({{pulcCnt}}건)</span>
      </v-col>
    </v-row>

    <template>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="pa-0 mt-2" style="border-top: 1px solid black;">          
          <v-data-table
            :headers="gridHeader"
            class="elevation-0"
            :items-per-page.sync="itemsPerPage"
            :items="pulcList"
            :page.sync="page"
            disable-sort
            style="cursor:"
            @click:row="rowClicked($event)"
          >
            <template v-slot:no-data>
              <v-row class="pa-0">
                <v-col cols="12" class="pa-2">
                  <span class="font-weight-bold" style="font-size: 13px;">
                    검색된 데이터가 없습니다.
                  </span>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
      
    <template v-slot:footer>
      <v-row
        class="mt-2"
        align="center"
        justify="center">
        <v-col cols="12" class="border-t-darkgrey pb-0 mt-2"></v-col>
        <v-col cols="" class="d-flex align-center">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            class="ml-1 hei-30p"
            v-show="page != 1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="mr-4 ml-4 grey--text wid-100p d-flex align-center">
            <div class="wid-60p">
              <v-text-field 
                hide-details 
                outlined 
                class="wid-50p mr-1" 
                v-model="page"> 
              </v-text-field>
            </div>
            <div class="mr-3">/</div>
            <div class=""> {{pageMax}} </div>
          </div>
          <v-btn
            small
            outlined
            class="ml-1 hei-30p"
            v-show="pageMax != page">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </v-container>
</template>

<script>

export default {
  data() {
    return {
      defaultFilter: 0,
      filterList: [
        {menu_nm: '전체' , menu_sno: 0}
      ],
      descFilterList: [
        {cmn_cd : 0, cmn_cd_nm : '최신등록순'},
      ],
      descFilter: {
        cmn_cd : 0, cmn_cd_nm : '최신등록순'
      },
      gridHeader: [
        { text: '번호', value:'no' , width: "10%", align: 'center', },
        { text: '종류', value:'menu_nm' , width: "15.5%", align: 'center',},
        { text: '권호/년도', value:'vol' , width: "15.5%", align: 'center', },
        { text: '제목', value:'artcl_ttl' , width: "59%", align: 'center', }
      ],
      items: [],
      search: '',
      gridViewYn: false,
      itemsPerPageArray: [10, 20],
      page: 1,
      itemsPerPage: 10,
      totalLen: 0,
      pageMax: 0,
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      emitData: {},
      keyword: '',
      pulcList: [],
      pulcCnt: 0,
      menuList: [],
    }
  },
  created() {
    const params = new FormData();
    params.append("mber_sno", this.mberSno);
    params.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
      console.log("res.data",res.data);
      
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push('/');
      }

      //검색 필터
      this.filterList = [
        ...this.filterList,
        ...res.data.filterList
      ]
      // this.filterList = ;
      this.emitData.menu_sno = res.data.menu_sno;
      this.$emit('setData', this.emitData);

    }).catch(
      function (error) {
      console.log(error);
      }
    )
  },
  methods: {
    searchBtnClicked() {
      if(this.validateInput()) {
        const params = new FormData();
        params.append("keyword", this.keyword);
        params.append("menu_sno", this.defaultFilter);

        this.$http.post('/sub/getPulbcSearchList', params).then((res) => {
          console.log("res.data???",res.data);
          this.pulcCnt = res.data.pulcCnt;
          this.pulcList = res.data.pulcList;
          this.menuList = res.data.menuList;

        }).catch(
          function (error) {
          console.log(error);
          }
        )
      }
      // getPulbcSearchList
    },//searchBtnClicked end
    validateInput() {
      if(this.keyword == '' || this.keyword.trim() == '') {
        alert('검색어 입력해주세요.');
        this.pulcList = [];
        return false;
      }
      return true;
    },//validateInput end
    rowClicked(item) {
      console.log(item);
      let url_path = this.menuList.filter((e) => e.menu_sno == item.menu_sno)[0].url_path;
      this.$router.push('/public'+url_path+'/detail?yy='+item.yy+'&vol='+item.vol+'&ttl='+item.book_ttl+'&sno='+item.artcl_sno);
    }
  }
}
</script>
<style scoped>
  .hoverCheck:hover {
  cursor: pointer !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 13px !important;
    font-weight: 900 !important;
    padding: 13px 6px 13px 6px;
    font-family: dreamFont4;
  }

  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 13px !important;
    padding: 13px 6px 13px 6px;
    max-width: 100% !important;
    font-family: dreamFont3 !important;
  }

  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td > div > span {
    font-size: 13px !important;
    font-family: dreamFont3 !important;
  }

  ::v-deep .v-list-item__content > .v-list-item__title {
    font-family: dreamFont4 !important;
  }

  label {
  font-size: 13px !important;
  }

  .dreamFont3 {
    font-family: dreamFont3 !important;
  }

  .dreamFont4 {
    font-family: dreamFont4 !important;
  }

  ::v-deep .v-data-table > .v-data-footer {
    font-family: dreamFont3 !important;
  }
</style>