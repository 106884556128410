<template >
  <v-app class="bg-main">
    <!-- <header-a :key="$route.fullPath"/> -->
    <header-a/>
    
    <v-container fluid class="hei-150p d-flex align-end bgImg-cover" :style="`background-image:url(`+imgMap.hdr_img_path+`);`">
      <template>  
        <v-container class=" hei-130p d-flex align-end" :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
          <!-- [[[[[[  web   ]]]]]] -->
          <v-row class="hei-130p ma-0" :style="`color:`+ this.font_color_1+`;`" v-show="windowWidth > 1220">
            <v-col cols="3" class="bg-alpha-indigo d-flex justify-center align-center wid-250p">
              <div class="pb-2 white--text">
                <span><h1 class="dreamFont5">{{menuLvl_1_Nm}}</h1></span>
              </div>
            </v-col>
            <v-col cols="9">
              <v-row class="hei-130p pa-0 pl-5" align="center">
                <v-col cols="6" class="d-felx align-center ">
                  <div class="pb-2">
                    <span class="dreamFont5"><h1 v-if="menuNm">{{menuNm}}</h1></span>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" class="pa-0 ma-0">
                  <div class="pb-2 float-right dreamFont4" v-show="cateYn">
                    <span>Home / {{ this.lang == 'en' ? menu_1_map.menu_nm_en : menu_1_map.menu_nm }} / {{ this.lang == 'en' ? menu_2_map.menu_nm_en : menu_2_map.menu_nm }} {{ this.lang == 'en' ? menu_3_map.menu_nm_en : menu_3_map.menu_nm }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- [[[[[[  tablet   ]]]]]] -->
          <v-row class="hei-130p ma-0" :style="`color:`+ this.font_color_1+`;`" v-show="windowWidth < 1221 && windowWidth > 768">
            <v-col cols="12" class="pa-0 ma-0">
              <v-row class="hei-130p pa-0 pl-5" align="center" dense>
                <v-col cols="12" class=" pa-0 ma-0">
                  <div class="d-flex justify-center">
                    <span class="dreamFont5"><h1 v-if="menuNm" class="pa-0">{{menuNm}}</h1></span>
                  </div>
                  <div class="d-flex justify-center pt-3" v-show="cateYn">
                    <span>Home / {{ this.lang == 'en' ? menu_1_map.menu_nm_en : menu_1_map.menu_nm }} / {{ this.lang == 'en' ? menu_2_map.menu_nm_en : menu_2_map.menu_nm }} {{ this.lang == 'en' ? menu_3_map.menu_nm_en : menu_3_map.menu_nm }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- [[[[[[  mobile   ]]]]]] -->
          <v-row class="hei-130p ma-0" :style="`color:`+ this.font_color_1+`;`" v-show="windowWidth < 767 && windowWidth > 350">
            <v-col cols="12" class="pa-0 ma-0">
              <v-row class="hei-130p pa-0 pl-3 ma-0 " align="center" dense>
                <v-col cols="12" class="pa-0 ma-0">
                  <div class="">
                    <div class="d-flex justify-center">
                      <div class="">
                        <h1 v-if="menuNm" class="menuWord dreamFont5">{{menuNm}}</h1>
                      </div>
                    </div>
                    <div class="pt-3 d-flex justify-center" v-show="cateYn" >
                      <span class="pa-0 menuWord">Home / {{ this.lang == 'en' ? menu_1_map.menu_nm_en : menu_1_map.menu_nm }} / {{ this.lang == 'en' ? menu_2_map.menu_nm_en : menu_2_map.menu_nm }} {{ this.lang == 'en' ? menu_3_map.menu_nm_en : menu_3_map.menu_nm }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>

    <v-container fluid class="hei-90vh-auto d-flex bg-white" >
      <template>
        <v-container class="d-flex " :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
          <v-row class="hei-90vh-auto mt-0 bg-white ma-0" style="max-width: 1200px !important;" v-show="windowWidth > 1220" >
            <v-col cols="3" class="bg-menugrey pa-0">
              <v-list 
                class="pa-0 ma-0"
                tile>
                <v-list-group
                  v-for="(item,idx) in menuList.filter((e) => e.menu_lvl == 2 && e.upper_menu_sno == menuLvl_1_sno)"
                  :key="`lvl2_`+idx"
                  no-action
                  class="menu-border pa-0"
                  active-class="btn-active-blue"
                  :append-icon="menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == item.menu_sno).length == 0 ? '' : undefined"
                  v-model="item.openYn"
                  @click="menuLvl2Clicked(item)">
                  <template v-slot:activator>
                    <v-list-item-content class="hei-50p pt-0 pb-0" @click="clickTest">
                      <div class="d-flex align-center">
                        <h4 class="dreamFont4">{{ lang == 'en' ? item.menu_nm_en : item.menu_nm }}</h4>
                      </div>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="(item2,idx) in menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == item.menu_sno)"
                    :key="`lvl3_`+idx"
                    style="min-height: 30px;"
                    class="dreamFont4"
                    @click="menuLvl3Clicked(item2)"
                    v-model="item2.openYn">
                    <v-list-item-content class="pt-2 pb-2 pr-0">
                      <div class="d-flex align-center fontSize-0" >
                        <span class="font-weight-bold pr-3" >-</span>{{ lang == 'en' ? item2.menu_nm_en : item2.menu_nm }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-col>
            <v-col cols="9" style="padding: 15px 12px 28px 28px;" v-show="windowWidth > 1222">
              <router-view :key="$route.fullPath" v-if="pageType == 'board' || pageType == 'pay' || pageType == 'abstrReg' || pageType == 'preReg' || pageType == 'public'" @setData="setData"/>
              <!-- <router-view :key="$route.fullPath" v-if="pageType == 'pay'" @setData="setData"/> -->
              <static-page :key="$route.fullPath" v-if="pageType=='static'" @setData="setData"/>
              <executive-page :key="$route.fullPath" v-if="pageType=='executive'" @setData="setData"/>
              <sponsor-page :key="$route.fullPath" v-if="pageType=='sponsor'"  @setData="setData"/>
              <history-page :key="$route.fullPath" v-if="pageType=='history'" @setData="setData"/>
              <!-- <calendar-page :key="$route.fullPath" v-if="pageType=='schedule'" @setData="setData"/> -->
              <schedule-page :key="$route.fullPath" v-if="pageType=='schedule'" @setData="setData"/>
              <contactus-page :key="$route.fullPath" v-if="pageType=='contactus'"  @setData="setData"/>
              <confr-page :key="$route.fullPath" v-if="pageType=='confrOutl' || pageType=='confrScdul' || pageType=='confrEventTour' || pageType=='confrAdDisp'"  @setData="setData"/>
              <accomodation-page :key="$route.fullPath" v-if="pageType=='confrTrfcAccom'" @setData="setData"/>
              <!-- <payment-page :key="$route.fullPath" v-if="pageType=='pay'" @setData="setData"/> -->
              <search-members-page :key="$route.fullPath" v-if="pageType=='memberSearch'" @setData="setData"/>
              <search-literature-page :key="$route.fullPath" v-if="pageType=='docSearch'" @setData="setData"/>
            </v-col>
          </v-row>

          <v-row class="hei-90vh-auto mt-0 bg-white ma-0" style="width:100%" v-show="windowWidth < 1221">
            <v-col cols="12" class="">
              <router-view :key="$route.fullPath" v-if="pageType == 'board' || pageType == 'pay' || pageType == 'abstrReg' || pageType == 'preReg' || pageType == 'public'" @setData="setData"/>
              <!-- <router-view :key="$route.fullPath" v-if="pageType == 'pay'" @setData="setData"/> -->
              <static-page :key="$route.fullPath" v-if="pageType=='static'" @setData="setData"/>
              <executive-page :key="$route.fullPath" v-if="pageType=='executive'" @setData="setData"/>
              <sponsor-page :key="$route.fullPath" v-if="pageType=='sponsor'"  @setData="setData"/>
              <history-page :key="$route.fullPath" v-if="pageType=='history'" @setData="setData"/>
              <!-- <calendar-page :key="$route.fullPath" v-if="pageType=='schedule'" @setData="setData"/> -->
              <schedule-page :key="$route.fullPath" v-if="pageType=='schedule'" @setData="setData"/>
              <contactus-page :key="$route.fullPath" v-if="pageType=='contactus'"  @setData="setData"/>
              <confr-page :key="$route.fullPath" v-if="pageType=='confrOutl' || pageType=='confrScdul' || pageType=='confrEventTour' || pageType=='confrAdDisp'"  @setData="setData"/>
              <accomodation-page :key="$route.fullPath" v-if="pageType=='confrTrfcAccom'" @setData="setData"/>
              <!-- <payment-page :key="$route.fullPath" v-if="pageType=='pay'" @setData="setData"/> -->
              <search-members-page :key="$route.fullPath" v-if="pageType=='memberSearch'" @setData="setData"/>
              <search-literature-page :key="$route.fullPath" v-if="pageType=='docSearch'" @setData="setData"/>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>
    
    <main-footer/>
  </v-app>
</template>

<script>
import mainFooter from '@/components/main/footer/footer'
import headerA from '@/components/main/header/headerA.vue'
import executivePage from "@/components/subPage/executivePage"
import staticPage from '@/components/subPage/staticPage.vue'
import historyPage from "@/components/subPage/historyPage"
import sponsorPage from "@/components/subPage/sponsorPage"
import schedulePage from "@/components/subPage/schedulePage"
// import calendarPage from "@/components/subPage/calendarPage"
import contactusPage from "@/components/subPage/contactusPage"
import confrPage from "@/components/subPage/confrPage"
import accomodationPage from "@/components/subPage/accomodationPage"
import searchMembersPage from "@/components/subPage/searchMembersPage"
import searchLiteraturePage from "@/components/subPage/searchLiteraturePage"

// import paymentPage from "@/components/subPage/paymentPage"

export default {
  name: 'App',
  components:{
    headerA, 
    mainFooter, 
    staticPage,
    executivePage,
    sponsorPage,
    historyPage,
    schedulePage,
    // calendarPage,
    contactusPage,
    // paymentPage,
    confrPage,
    accomodationPage,
    searchMembersPage,
    searchLiteraturePage,
  },
  data(){
    return{
      windowWidth: window.innerWidth,
      activeWidth:{ width:this.windowWidth},
      test:'0',
      items: [
        {
          items: [
            { thirdTitle: '3차메뉴명' },
            { thirdTitle: '3차메뉴명' },
            { thirdTitle: '3차메뉴명' },
            { thirdTitle: '3차메뉴명' },
            { thirdTitle: '3차메뉴명' },
          ],
          secondTitle: '2차 메뉴명',
        },
        {
          items: [
            { thirdTitle: '3차메뉴명' },
            { thirdTitle: '3차메뉴명' },
            { thirdTitle: '3차메뉴명' },
            { thirdTitle: '3차메뉴명' },
            { thirdTitle: '3차메뉴명' },
          ],
          secondTitle: '2차 메뉴명',
        },
      ],
      BtnIcon_1:true,
      menuList: [],
      menuLvl_1_Nm: '',
      menuLvl_1_sno: '',
      menuLvl_1: '',
      menuNm: '',
      menu_1_map: {},
      menu_2_map: {},
      menu_3_map: {},
      cateYn: false,
      pageType: '',
      boardType: '',
      urlArr: this.$route.path.split('/'),
      imgMap: {},
      font_color_1:'',
      lang: this.$session.get("lang") ? this.$session.get("lang") : '',
      menuSno: '',
    }
  },
  watch: {
    '$route' (to,from) {
      let to_url = to.params.pathMatch;
      let from_url = from.params.pathMatch;

      let to_Match = to_url.split('/');
      let from_Match = from_url.split('/');

      // 경로 변경에 반응하여...
      // console.log(to);
      // console.log(from);
      const arr = this.$route.path.split('/');

      this.pageType = arr[1];
      
      if(this.pageType == 'board') {
        this.menuLvl_1 = arr[3];
        this.boardType = arr[arr.length-1];

      } else {
        this.menuLvl_1 = arr[2];
      }
      if(!this.menuLvl_1) {
        this.$router.push('/404')
      } else {
        if(to_Match[0] != from_Match[0]) {
          this.selectMenuList();
        }
        
      }
    },
    
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  computed: {
    
  },
  created() {
    
    this.pageType = this.urlArr[1];
    
    if(this.pageType == 'board') { 
      this.menuLvl_1 = this.urlArr[3];
      this.boardType = this.urlArr[this.urlArr.length-1];
    } else {
      this.menuLvl_1 = this.urlArr[2];
    }

    if(!this.menuLvl_1) {
      this.$router.push('/404')
    } else {
      this.selectMenuList();
    }
    
  },
  methods:{
    handleResize() {
            this.width = window.innerWidth;
            
        },
    clickTest(){
      
      this.BtnIcon_1 = !this.BtnIcon_1;
    },
    selectMenuList() {

      const param = new FormData();
      param.append('lang',this.lang);
      this.$http.post("/admin/getMenuList",param).then((res) => {    
        
        this.menuList = res.data.menuList;
        this.menuList = this.menuList.filter((e) => e.disp_yn == 'Y');
        
        this.menuList.forEach((e) => {
          e.openYn = false;
          if(e.page_type == 'static') {
            e.url_path = '/static'+e.url_path
          } else if(e.page_type == 'bbs_notc') {
            e.url_path = '/board/notice'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_glly') {
            e.url_path = '/board/gallery'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_free') {
            e.url_path = '/board/free'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_faq') {
            e.url_path = '/board/faq'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_qna') {
            e.url_path = '/board/qna'+e.url_path+'/list';
          } else if(e.page_type == 'past_exctv' || e.page_type == 'curr_exctv') {
            e.url_path = '/executive'+e.url_path;
          } else if(e.page_type == 'spnsr') {
            e.url_path = '/sponsor'+e.url_path
          } else if(e.page_type == 'socty_hist') {
            e.url_path = '/history'+e.url_path
          } else if(e.page_type == 'scdul') {
            e.url_path = '/schedule'+e.url_path
          } else if(e.page_type == 'cntt_us') {
            e.url_path = '/contactus'+e.url_path
          } else if(e.page_type == 'pay') {
            e.url_path = '/pay'+e.url_path+'/list'
          } else if(e.page_type == 'abstr_reg') {
            e.url_path = '/abstrReg'+e.url_path+'/list'
          } else if(e.page_type == 'pre_reg') {
            e.url_path = '/preReg'+e.url_path+'/list'
          } else if(e.page_type == 'confr_outl') {
            e.url_path = '/confrOutl'+e.url_path;
          } else if(e.page_type == 'confr_scdul') {
            e.url_path = '/confrScdul'+e.url_path;
          } else if(e.page_type == 'event_tour') {
            e.url_path = '/confrEventTour'+e.url_path;
          } else if(e.page_type == 'trfc_accom_guid') {
            e.url_path = '/confrTrfcAccom'+e.url_path;
          } else if(e.page_type == 'ad_disp_guid') {
            e.url_path = '/confrAdDisp'+e.url_path;
          } else if(e.page_type == 'url_link') {
            e.url_path = e.url_link;
          } else if(e.page_type == 'mber_srch') {
            e.url_path = '/memberSearch'+e.url_path;
          } else if(e.page_type == 'doc_srch') {
            e.url_path = '/docSearch'+e.url_path;
          } else if(e.page_type == 'publc') {
            e.url_path = '/public'+e.url_path;
          }
          
        });
        
        //추후에 주석 제거
        // if(!this.menuList.filter((e) => e.menu_lvl == 1 && e.resrc_nm == this.menuLvl_1)[0]) {
        //   this.$router.push('/404');
        // }
        if(this.lang == 'en') {
          this.menuLvl_1_Nm = this.menuList.filter((e) => e.menu_lvl == 1 && e.resrc_nm == this.menuLvl_1)[0].menu_nm_en;
        } else {
          this.menuLvl_1_Nm = this.menuList.filter((e) => e.menu_lvl == 1 && e.resrc_nm == this.menuLvl_1)[0].menu_nm;
        }
        // this.menuLvl_1_Nm = this.menuList.filter((e) => e.menu_lvl == 1 && e.resrc_nm == this.menuLvl_1)[0].menu_nm;
        this.menuLvl_1_sno = this.menuList.filter((e) => e.menu_lvl == 1 && e.resrc_nm == this.menuLvl_1)[0].menu_sno;
        
        this.getSoctyImg();
      }).catch((error) => {
          console.log(error);
        }
      )
    },//selectMenuList end
    setData(dataMap) {
      this.menu_1_map = {};
      this.menu_2_map = {};
      this.menu_3_map = {};
      
      const menuData = this.menuList.filter((e) => e.menu_sno == dataMap.menu_sno)[0];
      if(!menuData) {
        this.$router.push('/');
      }
      // console.log("menuData::", menuData);
      this.menuNm = this.lang == 'en' ? menuData.menu_nm_en : menuData.menu_nm;
      this.menuSno = menuData.menu_sno;
      // alert(menuData.menu_nm);

      if(menuData.menu_lvl == 1) {

        this.menu_1_map = menuData;

      } else if(menuData.menu_lvl == 2) {

        this.menu_2_map = menuData;
        this.menuList.filter((e) => e.menu_sno == menuData.menu_sno)[0].openYn = true;
        this.menuList.filter((e) => e.menu_sno != menuData.menu_sno).map((f) => f.openYn = false);
        this.menu_1_map = this.menuList.filter((e) => e.menu_sno == menuData.upper_menu_sno)[0];

      } else if(menuData.menu_lvl == 3) {

        this.menu_3_map = menuData;
        this.menu_2_map = this.menuList.filter((e) => e.menu_sno == menuData.upper_menu_sno)[0];
        this.menuList.filter((e) => e.menu_sno == menuData.upper_menu_sno)[0].openYn = true;
        this.menuList.filter((e) => e.menu_sno == dataMap.menu_sno)[0].openYn = true;
        this.menuList.filter((e) => e.menu_lvl == 3 && e.menu_sno != dataMap.menu_sno).map((f) => f.openYn = false);
        const menu_2_upper = this.menuList.filter((e) => e.menu_sno == menuData.upper_menu_sno)[0].upper_menu_sno;
        this.menu_1_map = this.menuList.filter((e) => e.menu_sno == menu_2_upper)[0];

      }
      
      this.cateYn = true;
    },//setData end
    menuLvl2Clicked(item) {
      if(item.url_path == this.$route.fullPath) {
        this.$router.go(0);
      }
      if(!this.menuList.filter((e) => e.upper_menu_sno == item.menu_sno).length > 0) {
        if(item.page_type != 'url_link') {  
          this.$router.push(item.url_path).catch(()=>{});
          // if(this.menuSno == item.menu_sno) {
          //   this.$router.go(0).catch(()=>{});  
          // } else {
          //   this.$router.push(item.url_path).catch(()=>{});
          // }
        } else {
          window.open(item.url_path);
        }
      }
    },//menuLvl2Clicked end
    menuLvl3Clicked(item) {

      console.log(item);
      if(item.page_type != 'url_link') {
        this.$router.push(item.url_path).catch(()=>{});
      } else {
        window.open(item.url_path);
      }
    },//menuLvl3Clicked end
    getSoctyImg() {
      this.$http.post("/admin/getSoctyImg").then((res) => {
        if(res.data.result[0]) {
          this.imgMap = res.data.result[0];
          this.font_color_1 = res.data.fontColor.font_color_1;
        }

      }).catch(function (error) {
          console.log(error);
        }
      );

    },
  }
}
</script>

<style scoped>
::v-deep .col-3{
  max-width: 250px !important;
}

.v-application--is-ltr /deep/ .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}

.v-application--is-ltr /deep/ .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 20px !important;
}
.menuWord {
  max-width: 340px; 
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  }

.dreamFont4{
  font-family: dreamFont4 !important;
}

.dreamFont5{
  font-family: dreamFont5 !important;
}
</style>