<template>
  <div class="tiny" v-if="staticPgMap && staticPgMap.cn" v-html="staticPgMap.cn" @click="handleLineClick"></div>
</template>

<script>

export default {
  data() {
    return {
      staticPgMap: '',
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      permMap: {},
      emitData: {},
      lang: this.$session.get("lang") ? this.$session.get("lang") : '',
    }
  },
  created() {
    const formData = new FormData();
    formData.append('mber_sno', this.mberSno);
    formData.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath,formData).then((res) => {
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push("/");
        return;
      } else {
        
        let staticPgMap = res.data.staticPgMap;
        if(this.lang == 'en') {
          staticPgMap.cn = staticPgMap.page_cn_en;
        } else {
          staticPgMap.cn = staticPgMap.page_cn;
        }
        this.staticPgMap = staticPgMap;
      }
        this.emitData.menu_sno = this.staticPgMap.menu_sno;
        this.$emit('setData', this.emitData);

    }).catch(
      function (error) {
      console.log(error);
      }
    )

  },
  methods: {
    // test() {
    //   alert(2);
    // },
    handleLineClick(e) {
      let clickedElId = e.target.id;
      let clickedUrl = '';
      if(clickedElId == 'pdfLogin') {
        if(!this.mberSno) {
          alert('로그인 이후 사용가능합니다.');
          return;
        } else {
          clickedUrl = e.target.attributes.url.value;
          if(clickedUrl) {
            this.pdfDownClicked(clickedUrl);
          }
          
        }
        
      }

    },//handleLineClick end
    pdfDownClicked(url) {
      // const url = window.URL.createObjectURL(item.file_url);
      const aTag = document.createElement('a');
      aTag.href = this.$store.state.apubImgPath+url;
      let urlArr = url.split('/');
      const fileName = urlArr[urlArr.length-1];
      aTag.target = '_blank';
      aTag.download = fileName;
      aTag.click();

    },//pdfDownClicked end
  } 
}
</script>

<style scoped>
/deep/.v-application ul{
  padding-left: 240px
}
</style>

