import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageTitle: "",  // 관리자 > 페이지 제목
    basicTab: 'N',
    indvTab: 'N',
    grpTab: 'N',
    confrSno: '',
    memberList: {
      radioMberPriv: "모두",
      radioMberStus: "모두",
      radioMberDiv: "모두",
      radioMberKind: "모두",
      radioAnulMfeeExmptYn: "모두",
    },
    conferenceList: {
      radioNtceYn: "모두",
    },
    preRegistList: {
      radioSiteRegYn: "모두",
      radioPayYn: "모두",
      radioPtcpType: "모두",
    },
    abstrRegistList: {
      radioMberKind: "모두",
      radioPtMthd: "모두",
      radioPtField: "모두",
    },
    productList: {
      radioUseYn: "모두",
      radioProdType: "모두",
      radioMberKind: "모두",
    },
    paymentList: {
      radioPayYn: "모두",
      radioProdType: "모두",
    },
    boardList: {
      radioBoardType: "모두",
    },
    postList: {
      radioUpendFixgYn: "모두",
      radioMainPopupYn: "모두",
      radioMainRlngYn: "모두",
    },
    publcArtclList: {
      radioLang: "모두",
    },
    goUrl: '',
    tinyApiKey: '',
    apubImgPath: '//static.apub.kr',
  },
  mutations: {

  },
  actions: {
  },
  getters: {
  }
})