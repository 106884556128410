<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="">
        <v-row dense align="center">
          <!-- <v-col cols="12" class="pa-0 ma-0">
            <div>
              <v-row dense>
                <v-col cols="12">
                  <div class="f-r">
                    <v-btn
                      small
                      color="blue lighten-2"
                      class="white--text"
                      @click="saveBtnClicked"
                    >저장</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col> -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록등록 건수 제한 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="abstr_reg_max_cnt"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>저자 소속 최대수 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="athor_blng_max_cnt"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록 제출 방식 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-select
              class="wid-100 pt-1" 
              v-model="abstr_sbmt_mthd"
              :items="abstrSbmtMthdList"
              item-text="cmn_cd_nm"
              item-value="cmn_cd"
              hide-details
              outlined
              dense
              @change="itemChanged"
            ></v-select>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록등록 시</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="abstr_file_edtr_ess_yn"
              hide-details
              dense
              label="초록 파일/에디터 필수"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
          <v-col cols="2" >
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="mber_only_yn"
              hide-details
              dense
              label="초록등록 회원 전용"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" >
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="anul_mfee_chk_yn"
              hide-details
              dense
              label="연회비 납부 검사"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
          <v-col cols="2" >
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="bef_abstr_pre_ess_yn"
              hide-details
              dense
              label="초록등록 전 사전등록 필수"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
          <!-- <v-col cols="2" >
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="memo_input_yn"
              hide-details
              dense
              label="초록등록 시 메모 입력 가능"
              @change="itemChanged"
            ></v-checkbox>
          </v-col> -->

          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록등록 후</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="abstr_upd_psbl_yn"
              hide-details
              dense
              label="초록 수정 가능"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

          <v-col cols="2" >
            <div class="pt-0">
              <span>발표 관련</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="en_pt_hngl_ttl_imps_yn"
              hide-details
              dense
              label="영문 발표 선택 시 한글 제목 입력 불가"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
          <v-col cols="2" >
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="pt_awad_yn"
              hide-details
              dense
              label="발표상 응모"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
          <v-col cols="2" v-show="pt_awad_yn">
          </v-col>
          <v-col cols="3" v-show="pt_awad_yn">
            <v-text-field
              v-model="pt_awad_nm"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="7" v-show="pt_awad_yn">
            <div class="pt-0">
              <span>홈페이지 화면에 표시할 발표상 제목 입력</span>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

          <!-- 발표 방식 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>발표 방식</span>
            </div>
          </v-col>
          <v-col cols="6">
            <v-row dense align="center">
              <v-col cols="12">
                <div class="f-r pl-1">
                  <v-btn
                    small
                    color="green lighten-2"
                    class="white--text"
                    @click="addPtMthdBtnClicked"
                  >추가</v-btn>
                </div>
                <div class="f-r">
                  <v-btn
                    small
                    color="red lighten-2"
                    class="white--text"
                    @click="deletePtMthdBtnClicked"
                  >선택 삭제</v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="ptMthdHeader"
                  :items="ptMthdList"
                  class="elevation-1 wid-100"
                  item-key="pt_mthd_no"
                  hide-default-footer
                  disable-pagination
                  >
                  <template v-slot:[`item.select_yn`]="{ item }">
                    <v-checkbox
                      v-model="item.select_yn"
                      class="pa-0 ma-0"
                      hide-details
                      dense
                    ></v-checkbox>
                  </template>
                  <template v-slot:[`item.pt_mthd_nm`]="{ item }">
                    <v-text-field
                      class="pa-0 ma-0"
                      v-model="item.pt_mthd_nm"
                      hide-details
                      outlined
                      dense
                      @change="ptMthdGridItemChanged(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.pt_mthd_nm_en`]="{ item }">
                    <v-text-field
                      class="pa-0 ma-0"
                      v-model="item.pt_mthd_nm_en"
                      hide-details
                      outlined
                      dense
                      @change="ptMthdGridItemChanged(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.disp_odr`]="{ item }">
                    <v-text-field
                      class="pa-0 ma-0"
                      v-model="item.disp_odr"
                      hide-details
                      outlined
                      dense
                      @change="ptMthdGridItemChanged(item)"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>      
          </v-col>        
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 발표 방식 끝 -->

          <!-- 발표 분야 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>발표 분야</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-row dense align="center">
              <v-col cols="2">
                <v-select
                  class="wid-100 pt-1" 
                  :items="ptMthdList"
                  item-value="pt_mthd_no"
                  item-text="pt_mthd_nm"
                  v-model="ptMthdNo"
                  hide-details
                  dense
                  outlined
                  @change="ptMthdChanged"
                ></v-select>
              </v-col>
              <v-col cols="10">
                <div class="f-r pl-1">
                  <v-btn
                    small
                    color="green lighten-2"
                    class="white--text"
                    @click="addPtFieldBtnClicked"
                  >추가</v-btn>
                </div>
                <div class="f-r">
                  <v-btn
                    small
                    color="red lighten-2"
                    class="white--text"
                    @click="deletePtFieldBtnClicked"
                  >선택 삭제</v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :key="ptMthdNo"
                  :headers="ptFeildHeader"
                  :items="filteredPtFieldList"
                  class="elevation-1 wid-100"
                  item-key="no"
                  hide-default-footer
                  disable-pagination
                  >
                  <template v-slot:[`item.select_yn`]="{ item }">
                    <v-checkbox
                      v-model="item.select_yn"
                      class="pa-0 ma-0"
                      hide-details
                      dense
                    ></v-checkbox>
                  </template>
                  <template v-slot:[`item.pt_field_nm`]="{ item }">
                    <v-text-field
                      class="pa-0 ma-0"
                      v-model="item.pt_field_nm"
                      hide-details
                      outlined
                      dense
                      @change="ptFieldGridItemChanged(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.pt_field_nm_en`]="{ item }">
                    <v-text-field
                      class="pa-0 ma-0"
                      v-model="item.pt_field_nm_en"
                      hide-details
                      outlined
                      dense
                      @change="ptFieldGridItemChanged(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.pt_field_nm_abbr`]="{ item }">
                    <v-text-field
                      class="pa-0 ma-0"
                      v-model="item.pt_field_nm_abbr"
                      hide-details
                      outlined
                      dense
                      @change="ptFieldGridItemChanged(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.disp_odr`]="{ item }">
                    <v-text-field
                      class="pa-0 ma-0"
                      v-model="item.disp_odr"
                      hide-details
                      outlined
                      dense
                      @change="ptFieldGridItemChanged(item)"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 발표 분야 끝 -->
          <!-- 수집 항목 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>수집 항목</span>
            </div>
          </v-col>
          <v-col cols="4">
            <v-data-table
              :headers="regItemHeader"
              :items="regItemList"
              class="elevation-1 wid-100"
              item-key="no"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:[`item.use_yn`]="{item}">
                <v-checkbox 
                  v-model="item.use_yn" 
                  class="ma-0 pa-0"
                  hide-details
                  solo 
                  flat
                  @change="itemChanged"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.ess_yn`]="{item}">
                <v-checkbox 
                  v-model="item.ess_yn" 
                  class="ma-0 pa-0"
                  hide-details
                  solo 
                  flat
                  @change="itemChanged"
                ></v-checkbox>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 수집 항목 끝 -->

        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
export default {
  props: ["propParams"],
  data(){
    return{
      confrSno: "",
      regDiv: 'abstr',
      newYn: 'N',

      abstr_reg_max_cnt: "",
      athor_blng_max_cnt: "",
      abstr_sbmt_mthd: "",
      abstr_file_edtr_ess_yn: false,
      mber_only_yn: false,
      anul_mfee_chk_yn: false,
      bef_abstr_pre_ess_yn: false,
      en_pt_hngl_ttl_imps_yn: false,
      pt_awad_yn: false,
      pt_awad_nm: "",
      // memo_input_yn: false,
      abstr_upd_psbl_yn: false,
      // athor_blng_en_field_use_yn: false,
      // bef_pt_rqst_abstr_ess_yn: false,
      // pt_field_hide_yn: false,
      // pt_field_multi_sel_yn: false,

      abstrSbmtMthdList: [],

      ptMthdHeader:[
        { text: '선택', value:'select_yn', width:"5%", sortable:false},
        { text:'발표방식명', value:'pt_mthd_nm', width:'30%', sortable:false},
        { text:'발표방식명(영문)', value:'pt_mthd_nm_en', width:'50%', sortable:false},
        { text:'표시순서', value:'disp_odr', disp_odr: '15%', sortable:false}
      ],
      ptMthdList: [],
      // ptMthdChangedList: [],
      // ptMthdDeletedList: [],

      ptFeildHeader:[
        { text: '선택', value:'select_yn', width:"5%", sortable:false},
        { text:'발표분야명', value:'pt_field_nm', width:'25%', sortable:false},
        { text:'발표분야명(영문)', value:'pt_field_nm_en', width:'40%', sortable:false},
        { text:'발표분야명(약어)', value:'pt_field_nm_abbr', width:'20%', sortable:false},
        { text:'표시순서', value:'disp_odr', disp_odr: '10%', sortable:false}
      ],
      ptFieldList: [],
      // filteredPtFieldList: [],
      // ptFieldChangedList: [],
      // ptFieldDeletedList: [],
      ptMthdNo: "",

      regItemHeader:[
        { text:'영역명', value:'reg_sctn_nm', width:'35%', sortable:false},
        { text:'항목명', value:'reg_item_nm', width:'35%', sortable:false},
        { text:'사용여부', value:'use_yn', width: '15%', sortable:false},
        { text:'필수여부', value:'ess_yn', width: '15%', sortable:false},
        // { text:'순서', value:'disp_odr', disp_odr: '20%', sortable:false}
      ],
      regItemList: [],
    }
  },
  computed: {
    filteredPtFieldList() {
      console.log("computed filteredPtFieldList : ", this.ptFieldList);
      return this.ptFieldList.filter(e => e.pt_mthd_no == this.ptMthdNo);
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.propParams.confrSno; 
    this.newYn = this.propParams.newYn;
    // console.log("abstrRegistOptionTab created. confrSno:", this.confrSno);

    this.getAbstrSbmtMthdList();

    this.getConfrAbstrRegOptn();
  },
  watch: {
    // ptMthdNo(newVal, oldVal) {
    //   console.log('ptMthdNo changed from ' + oldVal + ' to ' + newVal);
    //   this.filteredPtFieldList = this.ptFieldList.filter(e => {
    //     return e.pt_mthd_no == this.ptMthdNo;
    //   })
    // }
  },
  methods: {
    async getAbstrSbmtMthdList() {
      this.abstrSbmtMthdList = await this.$getCmnCdList("abstr_sbmt_mthd");
    },
    getConfrAbstrRegOptn() {
      const params = new FormData();

      params.append("confr_sno", this.confrSno);
      params.append("reg_div", this.regDiv);

			this.$http.post("/admin/getConfrAbstrRegOptn", params).then((res) => {
        console.log("getConfrAbstrRegOptn res.data: ", res.data);

        this.regItemList = res.data.result.regItemList;
        // this.ptMthdList = res.data.result.ptMthdList;
        // this.ptFieldList = res.data.result.ptFieldList;

        const ptMthdList = res.data.result.ptMthdList;
        let idx = 1;
        ptMthdList.forEach((e) => {
          e.disp_odr = idx;
          idx++;
        }) 
        this.ptMthdList = ptMthdList;
        this.ptFieldList = res.data.result.ptFieldList;

        // idx = 1;
        // ptFieldList.forEach((e) => {
        //   e.disp_odr = idx;
        //   idx++;
        // }) 
        // this.ptFieldList = ptFieldList;

        // 발표분야 편집 중인 발표방식이 없는 경우
        if(this.ptMthdNo == null || this.ptMthdNo == "") {
          if(this.ptMthdList.length > 0) {
            this.ptMthdNo = this.ptMthdList[0].pt_mthd_no;
          }
          
        }

        this.ptFieldList.forEach(e => {
          e.select_yn = false;
        });
        if(res.data.result.regOptn == null) {
          // console.log("getConfrPreRegOptn res.data.result is null");
          this.newYn = 'Y';
          return;
        }

        const abstr = res.data.result.regOptn;        

        this.abstr_reg_max_cnt = abstr.abstr_reg_max_cnt;
        this.athor_blng_max_cnt = abstr.athor_blng_max_cnt;
        this.abstr_sbmt_mthd = abstr.abstr_sbmt_mthd;
        this.abstr_file_edtr_ess_yn = abstr.abstr_file_edtr_ess_yn == 'Y' ? true : false;
        this.mber_only_yn = abstr.mber_only_yn == 'Y' ? true : false;
        this.anul_mfee_chk_yn = abstr.anul_mfee_chk_yn == 'Y' ? true : false;
        this.bef_abstr_pre_ess_yn = abstr.bef_abstr_pre_ess_yn == 'Y' ? true : false;
        this.en_pt_hngl_ttl_imps_yn = abstr.en_pt_hngl_ttl_imps_yn == 'Y' ? true : false;
        this.pt_awad_yn = abstr.pt_awad_yn == 'Y' ? true : false;
        this.pt_awad_nm = abstr.pt_awad_nm;
        // this.memo_input_yn = abstr.memo_input_yn == 'Y' ? true : false;
        this.abstr_upd_psbl_yn = abstr.abstr_upd_psbl_yn == 'Y' ? true : false;
        // this.athor_blng_en_field_use_yn = abstr.athor_blng_en_field_use_yn == 'Y' ? true : false;
        // this.bef_pt_rqst_abstr_ess_yn = abstr.bef_pt_rqst_abstr_ess_yn == 'Y' ? true : false;
        // this.pt_field_hide_yn = abstr.pt_field_hide_yn == 'Y' ? true : false;
        // this.pt_field_multi_sel_yn = abstr.pt_field_multi_sel_yn == 'Y' ? true : false;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },


    // 발표방식 그리드 수정됨
    ptMthdGridItemChanged(item) {
      console.log("ptMthdGridItemChanged : item : ", item);
      this.itemChanged();
    },
    // 발표방식 삭제 버튼 클릭
    deletePtMthdBtnClicked() {
      if(this.ptMthdList.filter((e) => e.select_yn).length == 0) {
        alert('삭제할 항목 선택해주세요.');
        return;
      }
      if(confirm('삭제 후 목록을 다시 조회하며, 하위 발표분야도 삭제됩니다.\n삭제하시겠습니까?')) {

        const params = new FormData();
        params.append("confr_sno" , this.confrSno);
        params.append("pt_mthd_list", JSON.stringify(this.ptMthdList.filter((e) => e.select_yn)));

        this.$http.post("/admin/deleteConfrPtMthd", params).then((res) => {
          console.log("deleteConfrPtMthd res.data: ", res.data);

          const ptMthdList = res.data.ptMthdList;
          let idx = 1;
          ptMthdList.forEach((e) => {
            e.disp_odr = idx;
            idx++;
          }) 
          this.ptMthdList = ptMthdList;
          this.ptFieldList = res.data.ptFieldList;

          // idx = 1;
          // ptFieldList.forEach((e) => {
          //   e.disp_odr = idx;
          //   idx++;
          // }) 
          // this.ptFieldList = ptFieldList;

          if(this.ptMthdList.length > 0) {
            this.ptMthdNo = this.ptMthdList[0].pt_mthd_no;
          } else {
            this.ptMthdNo = "";
          }


          alert('삭제되었습니다.');

        }).catch(function (error) {
            console.log(error);
          }
        );

      }
      // let isEditing = false; // 발표분야 편집 중인 발표방식인 경우 처리

      // // 선택 체크된 목록을 ptMthdList에서 삭제
      // this.ptMthdList = this.ptMthdList.filter((e) => {
      //   if(e.select_yn) {
      //     // 해당 발표방식으로 설정된 발표분야 삭제
      //     this.ptFieldList = this.ptFieldList.filter(eField => {
      //       return eField.pt_mthd_no != e.pt_mthd_no;
      //     });

      //     if(this.ptMthdNo == e.pt_mthd_no) {
      //       isEditing = true;
      //     }
      //   }

      //   return !(e.select_yn);
      // });

      // if(this.ptMthdList.length > 0 && isEditing) {
      //   this.ptMthdNo = this.ptMthdList[0].pt_mthd_no;
      // }

      // this.itemChanged();
    },
    // 발표방식 추가 버튼 클릭
    addPtMthdBtnClicked() {
      let maxOdr = 0;
      let maxPtMthdNo = 0;

      this.ptMthdList.forEach((e) => {
        if(e.disp_odr > maxOdr) {
          maxOdr = e.disp_odr;
        }

        if(e.pt_mthd_no > maxPtMthdNo) {
          maxPtMthdNo = e.pt_mthd_no;
        }
      });

      maxOdr++;
      maxPtMthdNo++;

      this.ptMthdList.push({select_yn: false, confr_sno: this.confrSno, pt_mthd_no: maxPtMthdNo, pt_mthd_nm: "", pt_mthd_nm_en: "", disp_odr: maxOdr});
      // this.ptMthdChangedList.push(this.ptMthdList[this.ptMthdList.length - 1]);
    },

    // 발표분야 > 발표방식 선택 변경됨
    ptMthdChanged(event) {
      console.log("ptMthdChanged : ", event);
      this.ptMthdNo = event;
    },
    // 발표분야 그리드 수정됨
    ptFieldGridItemChanged(item) {
      console.log("ptFieldGridItemChanged : item : ", item);
      this.itemChanged();
    },
    // 발표분야 삭제 버튼 클릭
    deletePtFieldBtnClicked() {
      if(this.ptFieldList.filter((e) => e.select_yn).length == 0) {
        alert('삭제할 항목 선택해주세요.');
        return;
      }
      if(confirm('삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?')) {
        const params = new FormData();
        params.append("confr_sno" , this.confrSno);
        params.append("pt_field_list", JSON.stringify(this.ptFieldList.filter((e) => e.select_yn)));

        this.$http.post("/admin/deleteConfrPtField", params).then((res) => {
          console.log("deleteConfrPtField res.data: ", res.data);

          this.ptFieldList = res.data.ptFieldList;

          // let idx = 1;
          // ptFieldList.forEach((e) => {
          //   e.disp_odr = idx;
          //   idx++;
          // }) 
          // this.ptFieldList = ptFieldList;

          alert('삭제되었습니다.');

        }).catch(function (error) {
            console.log(error);
          }
        );
      }
      
        
      // 선택 체크된 목록을 ptMthdList에서 삭제
      // this.ptFieldList = this.ptFieldList.filter((e) => {
      //   return !(e.select_yn);
      // });
      
      // this.itemChanged();
    },
    // 발표분야 추가 버튼 클릭
    addPtFieldBtnClicked() {
      let maxOdr = 0;
      let maxPtFieldNo = 0;

      this.ptFieldList.forEach((e) => {
        if(e.disp_odr > maxOdr && e.pt_mthd_no == this.ptMthdNo) {
          maxOdr = e.disp_odr;
        }

        if(e.pt_field_no > maxPtFieldNo) {
          maxPtFieldNo = e.pt_field_no;
        }
      });

      maxOdr++;
      maxPtFieldNo++;

      this.ptFieldList.push({select_yn: false, confr_sno: this.confrSno, pt_mthd_no: this.ptMthdNo, pt_field_no: maxPtFieldNo, pt_field_nm: "", pt_field_nm_en: "", pt_field_nm_abbr: "", disp_odr: maxOdr});

      console.log("addPtFieldBtnClicked : ", this.ptFieldList, this.filteredPtFieldList);
    },


    validateInput() {
      // 초록등록 건수 제한
      if(this.abstr_reg_max_cnt == null || this.abstr_reg_max_cnt == "") {
        alert("초록등록 건수 제한은 필수 입력값입니다.");
        return false;
      }
      if(!this.$includeHangle(this.abstr_reg_max_cnt) || !this.$includeAlphaChar(this.abstr_reg_max_cnt)) {
        alert("초록등록 건수 제한은 숫자만 입력 가능합니다.");
        return false;
      }
      // 저자 소속 최대수
      if(this.athor_blng_max_cnt == null || this.athor_blng_max_cnt == "") {
        alert('저자 소속 최대수는 필수 입력값입니다.');
        return false;
      }
      if(!this.$includeHangle(this.athor_blng_max_cnt) || !this.$includeAlphaChar(this.athor_blng_max_cnt)) {
        alert("저자 소속 최대수는 숫자만 입력 가능합니다.");
        return false;
      }
      // 초록 제출 방식
      if(this.abstr_sbmt_mthd == null || this.abstr_sbmt_mthd == "") {
        alert('초록 제출 방식은 필수 입력값입니다.');
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      // console.log("saveConfrAbstrRegOptn");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append("reg_div", this.regDiv);
        params.append('new_yn', this.newYn);

        if(this.abstr_reg_max_cnt != null)          params.append("abstr_reg_max_cnt", this.abstr_reg_max_cnt);
        if(this.athor_blng_max_cnt != null)         params.append("athor_blng_max_cnt", this.athor_blng_max_cnt);
        if(this.abstr_sbmt_mthd != null)            params.append("abstr_sbmt_mthd", this.abstr_sbmt_mthd);
        if(this.abstr_file_edtr_ess_yn != null)     params.append("abstr_file_edtr_ess_yn", this.abstr_file_edtr_ess_yn ? 'Y' : 'N');        
        if(this.mber_only_yn != null)               params.append("mber_only_yn", this.mber_only_yn ? 'Y' : 'N');    
        if(this.anul_mfee_chk_yn != null)           params.append("anul_mfee_chk_yn", this.anul_mfee_chk_yn ? 'Y' : 'N');
        if(this.bef_abstr_pre_ess_yn != null)       params.append("bef_abstr_pre_ess_yn", this.bef_abstr_pre_ess_yn ? 'Y' : 'N');
        if(this.en_pt_hngl_ttl_imps_yn != null)     params.append("en_pt_hngl_ttl_imps_yn", this.en_pt_hngl_ttl_imps_yn ? 'Y' : 'N');
        if(this.pt_awad_yn != null)                 params.append("pt_awad_yn", this.pt_awad_yn ? 'Y' : 'N');
        if(this.pt_awad_nm != null)                 params.append("pt_awad_nm", this.pt_awad_nm);
        // if(this.memo_input_yn != null)              params.append("memo_input_yn", this.memo_input_yn ? 'Y' : 'N');
        if(this.abstr_upd_psbl_yn != null)          params.append("abstr_upd_psbl_yn", this.abstr_upd_psbl_yn ? 'Y' : 'N');
        // if(this.athor_blng_en_field_use_yn != null) params.append("athor_blng_en_field_use_yn", this.athor_blng_en_field_use_yn ? 'Y' : 'N');
        // if(this.bef_pt_rqst_abstr_ess_yn != null)   params.append("bef_pt_rqst_abstr_ess_yn", this.bef_pt_rqst_abstr_ess_yn ? 'Y' : 'N');
        // if(this.pt_field_hide_yn != null)           params.append("pt_field_hide_yn", this.pt_field_hide_yn ? 'Y' : 'N');
        // if(this.pt_field_multi_sel_yn != null)      params.append("pt_field_multi_sel_yn", this.pt_field_multi_sel_yn ? 'Y' : 'N');

        // 발표 방식
        // console.log("this.ptMthdList : ", this.ptMthdList)
        params.append("pt_mthd_list", JSON.stringify(this.ptMthdList));
        // console.log("pt_mthd_list : ", JSON.stringify(this.ptMthdList));

        // 발표 분야
        console.log("this.ptFieldList : ", this.ptFieldList)
        params.append("pt_field_list", JSON.stringify(this.ptFieldList));
        console.log("pt_field_list : ", JSON.stringify(this.ptFieldList));

        // 학술대회 메뉴
        const regItemList = [];
        this.regItemList.forEach((e) => {
          if(e.use_yn) {
            regItemList.push({confr_sno:this.confrSno, reg_item_id:e.reg_item_id, reg_div:this.regDiv, ess_yn:e.ess_yn ? 'Y' : 'N'});
          }
        });

        params.append("reg_item_list", JSON.stringify(regItemList));

        this.$http.post("/admin/upsertConfrAbstrRegOptn", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");

          this.getConfrAbstrRegOptn();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'abstrRegistOptionTab');
    },
  },
}
</script>

<style>

</style>