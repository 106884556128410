<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="gotoList"
          >목록</v-btn>
        </div>
        <div>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn 
            small 
            color="red lighten-2" 
            class="white--text f-r" 
            @click="deleteExctvGnrtnBtnClicked"
            v-if="newYn == 'N'"
            >역대 삭제
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense align="center">
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>역대명 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingGnrtn.gnrtn_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>역대 기간</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingGnrtn.gnrtn_term"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>  
      <v-col cols="2" >
        <div class="pt-0">
          <span>표시 순서</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingGnrtn.odr"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>  
      <v-col cols="2" >
        <div class="pt-0">
          <span>현역 여부</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="pendingGnrtn.curr_tf"
          hide-details
          dense
          label="현역 임원진임"
        ></v-checkbox>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="2">
        <div class="pt-0">
          <span>임원 목록</span>
        </div>
      </v-col>
      <v-col cols="10">
        <div class="f-r pl-1">
          <v-btn
            small
            color="green lighten-2"
            class="white--text"
            @click="addExctvBtnClicked"
          >추가</v-btn>
        </div>
        <div class="f-r">
          <v-btn
            small
            color="red lighten-2"
            class="white--text"
            @click="deleteExctvListBtnClicked"
          >선택 삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="12" >
        <div>
          <v-data-table
            :headers="gridHeader"
            :items="pendingExctvList"
            class="elevation-1 dTable-1"
            :items-per-page="20"
            hide-default-footer
            disable-pagination>
            <template v-slot:[`item.select_yn`]="{ item }">
              <v-checkbox
                v-model="item.select_yn"
                class="pa-0 ma-0"
                hide-details
                dense
              ></v-checkbox>
            </template>
            <template v-slot:[`item.exctv_mber_nm`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.exctv_mber_nm"
                hide-details
                outlined
                dense
                readonly
                append-icon="mdi-magnify"
                @click="searchMberBtnClicked(item)"
                @click:append="searchMberBtnClicked(item)"
              ></v-text-field>
            </template>
            <template v-slot:[`item.exctv_grd`]="{ item }">
              <v-select
                class="pa-0 ma-0"
                v-model="item.exctv_grd"
                :items="exctvGrdList"
                item-text="cmn_cd_nm"
                item-value="cmn_cd"
                hide-details
                outlined
                dense
              ></v-select>
            </template>
            <template v-slot:[`item.blng_nm`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.blng_nm"
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>
            <template v-slot:[`item.duty_nm`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.duty_nm"
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>
            <template v-slot:[`item.odr`]="{ item }">
              <v-text-field
                class="pa-0 ma-0"
                v-model="item.odr"
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>
          </v-data-table>
        </div>
      </v-col>

    </v-row>  
    <member-search-popup v-if="mberSrchPopup" @close="closeMberSrchPopup" @selected="setExctvMberSno"></member-search-popup>
  </v-container>  
</template>

<script>
import memberSearchPopup from '@/components/common/memberSearchPopup.vue';
// import dayjs from "dayjs";

const genEmptyGnrtn = () => ({
  gnrtn_sno: null,
  gnrtn_nm: "",
  gnrtn_term: "",
  curr_tf: false,
  ucurr_yn: "",
});

export default {
  components: { memberSearchPopup },
  data(){
    return{
      mberSno: null,

      newYn: this.$route.query.newYn,
      gnrtnSno: this.$route.query.gnrtnSno,

      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%", sortable:false },
        { text: '임원 이름', value: 'exctv_mber_nm', width: "15%" },
        { text: '임원 등급', value: 'exctv_grd', width: "10%" },
        { text: '임원 소속', value: 'blng_nm', width: "30%" },
        { text: '임원 직무', value: 'duty_nm', width: "20%" },
        { text: '표시 순서', value: 'odr', width: "10%" },
      ],
      // gridList: [],
      
      gnrtn: {},
      pendingGnrtn: {},

      exctvList: [],
      pendingExctvList: [],
      exctvIdx: 1,
      selectedExctv: null,

      exctvGrdList: [],

      // isOnPending: false,
      mberSrchPopup: false,
    }
  },
  computed: {
    isOnPending() {
      console.log("isOnPending");
      return JSON.stringify(this.gnrtn) != JSON.stringify(this.pendingGnrtn)
        || JSON.stringify(this.exctvList) != JSON.stringify(this.pendingExctvList)
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    // console.log("gnrtnSno:", this.gnrtnSno);
    console.log("executiveGenerationDetail created:");

    this.getExctvGrdList();

    if(this.newYn == 'Y') {
      this.gnrtn = genEmptyGnrtn(this.menuSno);
      this.pendingGnrtn = JSON.parse(JSON.stringify(this.gnrtn));
    } 
  },
  mounted() {
    if(this.newYn == 'N') {
      this.getExctvGnrtn();
    } 
  },
  methods: {
    async getExctvGrdList() {
      this.exctvGrdList = await this.$getCmnCdList("exctv_grd");
      // console.log("exctvGrdList: ", this.exctvGrdList);
    },
    gotoList() {
      // this.$router.go(-1)
      // 아래 path 설정 시 select box 에 값 설정이 안됨
      // this.$router.push({path:"preRegistList", query: { confrSno:this.confrSno }});
      this.$router.push({name:"executiveGenerationList", query: {  }});
    },
    getExctvGnrtn() {
      console.log("this.gnrtnSno(in getExctvGnrtn):", this.gnrtnSno);
      const params = new FormData();

      params.append("gnrtn_sno", this.gnrtnSno);

			this.$http.post("/admin/getExctvGnrtn", params).then((res) => {
        console.log("res.data: ", res.data);
        const gnrtn = res.data.result.gnrtn;

        gnrtn.curr_tf = gnrtn.curr_yn == "Y" ? true : false;

        this.gnrtn = gnrtn;
        this.pendingGnrtn = JSON.parse(JSON.stringify(gnrtn));

        this.exctvList = [];

        if(res.data.result.exctvList != null) {
          res.data.result.exctvList.forEach((e) => {
            this.exctvList.push({new_yn:'N', select_yn:false, idx:this.exctvIdx++, exctv_sno:e.exctv_sno, gnrtn_sno:e.gnrtn_sno, odr:e.odr, 
              exctv_mber_sno:e.exctv_mber_sno, exctv_mber_nm:e.exctv_mber_nm, exctv_grd:e.exctv_grd, blng_nm:e.blng_nm, duty_nm:e.duty_nm});
          })
        }
        this.pendingExctvList = JSON.parse(JSON.stringify(this.exctvList));
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 역대명
      if(this.pendingGnrtn.gnrtn_nm == null || this.pendingGnrtn.gnrtn_nm == "") {
        alert("역대명은 필수 입력값입니다.");
        return false;
      }

      return true;
    },
    saveNStayBtnClicked() {
      this.saveExctvGnrtn("stay");
      // console.log("out of save");
    },
    saveNMoveBtnClicked() {
      this.saveExctvGnrtn("move");
      // console.log("out of save");
    },
    saveExctvGnrtn(action) {
      // console.log("saveGnrtn:", this.pendingGnrtn.read_cnt);

      // if(confirm("저장하시겠습니까?")) {
        const isValid = this.validateInput();

        if(isValid) {
          const params = new FormData();

          if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
          params.append('new_yn', this.newYn);

          this.pendingGnrtn.curr_yn = this.pendingGnrtn.curr_tf ? "Y" : "N";

          params.append("gnrtn", JSON.stringify(this.pendingGnrtn));
          params.append("exctvList", JSON.stringify(this.pendingExctvList));

          this.$http.post("/admin/upsertExctvGnrtn", params).then((res) => {
            console.log(res.data);
            alert("저장되었습니다.");
            
            if(this.newYn == 'Y') {
              this.gnrtnSno = res.data.result.gnrtn_sno;
              this.newYn = 'N';
              console.log("this.gnrtnSno(in save):", this.gnrtnSno);
            }

            // console.log("in save: after alert 1");
            if(action == "stay") {
              this.getExctvGnrtn();
            } else if(action == "move") {
              this.gotoList();
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      // }
    },
    deleteExctvGnrtnBtnClicked() {
      console.log("deleteExctvGnrtn");

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        // if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
        params.append("gnrtn", JSON.stringify(this.pendingGnrtn));

        this.$http.post("/admin/deleteExctvGnrtn", params).then((res) => {
          console.log(res.data);

          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.gotoList();
          } else {
            alert("삭제할 수 없습니다.");
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    // itemChanged() {
    //   this.isOnPending = true;
    //   // console.log("itemChanged:", this.read_cnt);
    // },
    searchMberBtnClicked(item) {
      this.selectedExctv = item;
      this.mberSrchPopup = true;
    },
    closeMberSrchPopup() {
      this.mberSrchPopup = false;
    },
    setExctvMberSno(mberSno, mberNm) {
      console.log("setExctvMberSno:", mberSno);
      // this.itemChanged();

      this.selectedExctv.exctv_mber_sno = mberSno;
      this.selectedExctv.exctv_mber_nm = mberNm; //+ "(" + mberSno + ")";
      this.mberSrchPopup = false;
    },
    
    // 임원 추가
    addExctvBtnClicked() {
      let maxOdr = 0;

      this.pendingExctvList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });
      
      this.pendingExctvList.push({new_yn:'Y', select_yn:false, idx:this.exctvIdx++, exctv_sno:null, gnrtn_sno:null, odr:maxOdr + 1, 
              exctv_mber_sno:null, exctv_mber_nm:null, exctv_grd:null, blng_nm:null, duty_nm:null});
    },
    deleteExctvListBtnClicked() {
      console.log("deleteExctvListBtnClicked");
      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.pendingExctvList.length; i++) {
      //   if(this.pendingExctvList[i].select_yn) {
      //     isSelected = true;

      //     if(this.pendingExctvList[i].new_yn == 'N') {
      //       deleteList.push(this.pendingExctvList[i]);
      //     }
      //   }
      // }
      deleteList = this.pendingExctvList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && e.new_yn == 'N';
      });

      // 데이터 검증
      deleteList.forEach((e) => {
        console.log("deleteList:", e);
      });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteExctvList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getExctvGnrtn();
            this.pendingExctvList = this.pendingExctvList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getExctvGnrtn();
          this.pendingExctvList = this.pendingExctvList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    // getExctvList() {
    //   const params = new FormData();

    //   params.append("gnrtn_sno", this.pendingGnrtn.gnrtn_sno);

    //   this.$http.post("/admin/getExctvList", params).then((res) => {
    //     console.log(res.data);

    //     this.exctvList = [];
    //     this.exctvIdx = 1;

    //     res.data.result.exctvList.forEach((e) => {
    //       this.exctvList.push({new_yn:'N', select_yn:false, idx:this.gllyImgIdx++, bbst_sno:e.bbst_sno, odr:e.odr, img_desc:e.img_desc, dwld_cnt:e.dwld_cnt,
    //         new_file:null, file_atch_sno:e.file_atch_sno, atch_odr:e.atch_odr, orgnl_file_nm:e.orgnl_file_nm, file_path:e.file_path });
    //     });
    //     this.pendingExctvList = JSON.parse(JSON.stringify(this.exctvList));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    // },
  },
}
</script>

<style>

</style>