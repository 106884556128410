<template>
    <div class="ma-2 pa-2">
      <!-- <div class="mt-5">
        <span>dense 적용 (v-data-table 안에 체크박스, 셀렉트 등이 삽입될 경우 dense 무의미 해짐 hide-details 로 컨트롤 해야함) </span>
        <v-card>
          <v-data-table
            dense
            :headers="headers"
            :items="testItems"
            :search="search"
          >
          </v-data-table>
        </v-card>
      </div> -->

      <div class="mt-5">
        <span>dense 적용 (v-data-table 안에 체크박스, 셀렉트 등이 삽입될 경우 dense 무의미 해짐 hide-details 로 컨트롤 해야함) </span>
        <v-card>
          <v-data-table
            dense
            :headers="headers"
            :items="testItems"
            :search="search"
          >
          <template v-slot:[`item.checkBox`]="{ item }">
            <v-simple-checkbox
              v-model="item.checkBox"
              hide-details
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.select`]="{ item }">
            <v-select 
							label=""
							v-model="item.select"
							:items="testSelectList"
              hide-details
						>
						</v-select>
          </template> 
          </v-data-table>
        </v-card>
      </div>
      
      <div class="mt-5">
        <span>dense 미적용 (v-data-table 안에 체크박스, 셀렉트 등이 삽입될 경우 dense 무의미 해짐 hide-details 로 컨트롤 해야함) </span>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="testItems"
            :search="search"
          >
            <template v-slot:[`item.checkBox`]="{ item }">
              <v-simple-checkbox
                v-model="item.checkBox"
                hide-details
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.select`]="{ item }">
              <v-select 
							label=""
							v-model="item.select"
							:items="testSelectList"
              hide-details
						>
						</v-select>
          </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      data () {
        return {
          search: '',
          testSelectList:[],
          headers: [
            {
              text: 'test Title',
              align: 'start',
              filterable: false,
              value: 'title',
            },
            { text: 'test Id', value: 'id' },
            { text: 'test Contents', value: 'contents' },
            { text: 'test checkBox', value: 'checkBox' },
            { text: 'test select', value: 'select' },
          ],
          testItems: [
            {
              title: 'test Title',
              id: 'test Id',
              contents: 'test Contents',
              checkBox: true,
              select: '',
            },
            {
              title: 'test Title',
              id: 'test Id',
              contents: 'test Contents',
              checkBox: true,
              select: '',
            },
            {
              title: 'test Title',
              id: 'test Id',
              contents: 'test Contents',
              checkBox: true,
              select: '',
            },
            {
              title: 'test Title',
              id: 'test Id',
              contents: 'test Contents',
              checkBox: true,
              select: '',
            },
            {
              title: 'test Title',
              id: 'test Id',
              contents: 'test Contents',
              checkBox: true,
              select: '',
            },
            {
              title: 'test Title',
              id: 'test Id',
              contents: 'test Contents',
              checkBox: true,
              select: '',
            },
            {
              title: 'test Title',
              id: 'test Id',
              contents: 'test Contents',
              checkBox: true,
              select: '',
            },
          ],
        }
      },
    }
  </script>
  
  <style>
  
  </style>