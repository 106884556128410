<template>
  <v-container fluid class="">
    <v-row class="pa-0 mb-5 ma-0">
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          color="#000000"
          height="70px"
          class="mb-5 pa-0 fontSize-0 font-weight-bold dreamFont4"
          hide-slider
          show-arrows
          active-class="actHistory">
          <v-tab
            class="tab-border wid-150p fontSize-0"
            v-for="(item,idx) in histTermList"
            :key="`term_`+idx"
            @click="tabClicked(item.term_sno, item.sort_mthd)">
            {{ item.term_nm }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="pt-5">
          <v-tab-item
            v-for="(item,idx) in histTermList"
            :key="`termTab_`+idx"
            class="pa-5">

            <!-- [[[ 연혁 부분 ]]] -->
            <!-- [[[ type 1 ]]] -->
            <v-container>

              <v-row class="pa-0">
                <v-col cols="12" class="pa-0 d-flex align-center">
                  <h1 class="mr-3 pl-1 dreamFont4">{{termNm}}</h1>
                </v-col>
                <v-col cols="12" class="border-b-darkgrey-2px mb-5"></v-col>
              </v-row>

              <v-row class="mb-10" v-if="siteOptnMap && siteOptnMap.socty_hist_type == 'center'">
                <v-col cols="12" v-if="soctyHistList && soctyHistList.length > 0">
                  <v-timeline
                    color="black">
                    <v-timeline-item 
                      color="black"
                      v-for="(item, idx) in soctyHistList"
                      :key="`hist1_`+idx"
                      :class="idx%2 ==1 ? 'text-right' : ''"
                    >
                      <div class="py-4">
                        <h2 class="pb-4 headline">
                          {{item.hist_bgn_dt}}
                        </h2>
                        <div>
                          {{item.hist_cn}}
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>


              <!-- [[[ type 2 ]]] -->
              <v-row class="mb-10" v-if="siteOptnMap && siteOptnMap.socty_hist_type == 'left'">
                <v-col cols="12" v-if="soctyHistList && soctyHistList.length > 0">
                  <v-timeline
                    dense>
                    <v-timeline-item 
                      color="black"
                      v-for="(item, idx) in soctyHistList"
                      :key="`hist2_`+idx"
                    >
                      <div class="py-4">
                        <h2 class="pb-4 headline">
                          {{item.hist_bgn_dt}}
                        </h2>
                        <div>
                          {{item.hist_cn}}
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>

            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>


    
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      windowWidth: window.innerWidth,
      tab: null,
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      emitData: {},
      histTermList: [],
      siteOptnMap: {},
      soctyHistList: [],
      termNm: '',
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    const formData = new FormData();
    formData.append('mber_sno', this.mberSno);
    formData.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath,formData).then((res) => {
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push("/");
        return;
      } else {
        
        this.histTermList = res.data.histTermList;
        if(this.histTermList) {
          this.termNm = this.histTermList[0].term_nm;
        }
        
        this.siteOptnMap = res.data.siteOptnMap;
        this.soctyHistList = res.data.soctyHistList;
      }
        this.emitData.menu_sno = res.data.menu_sno;
        this.$emit('setData', this.emitData);

    }).catch(
      function (error) {
      console.log(error);
      }
    )
  },
  methods: {
    tabClicked(termSno, sortMthd) {
      const formData = new FormData();

      formData.append('term_sno', termSno);
      formData.append('sort_mthd', sortMthd);

      this.$http.post('/sub/getSoctyHistList', formData).then((res) => {
        this.termNm = this.histTermList.filter((e) => e.term_sno == termSno)[0].term_nm;
        this.soctyHistList = res.data.soctyHistList;
      }).catch(
        function (error) {
        console.log(error);
        }
      )
    },//tabClicked end
  },

}
</script>

<style>
.actHistory{
  color: #ffffff !important;
  background-color: #173485 !important;
}

.dreamFont3 {
  font-family: dreamFont3 !important;
}

.dreamFont4 {
  font-family: dreamFont4 !important;
}
</style>