<template>
  <v-app class="bg-main">
    <v-container fluid class=" hei-100vh-auto d-flex bg-white">
      <template>
        <v-container class="hei-80vh-auto d-flex">
          <v-row class="ma-0">
            <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-5 pt-5">
                <div class="wid-100  d-flex justify-center mb-3">
                  <div class="wid-200p hei-130p ">
                    <img class="wid-200p hei-130p imgDf" :src="imgMap.join_top_logo_path">
                  </div>
                </div>
                <v-card class="hei-400p wid-100 bg-lightgrey-2 d-flex justify-center align-center" outlined>
                  <div class="wid-60">
                    <v-row align="center" dense class="">
                      <v-col cols="12" class="d-flex justify-center align-center mb-10">
                        <span class="fontSize-9 font-weight-bold">관리자 페이지</span>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          prepend-inner-icon="mdi-account"
                          hide-details
                          outlined
                          v-model="login_id">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          prepend-inner-icon="mdi-lock"
                          append-icon="mdi-eye"
                          hide-details
                          outlined
                          v-model="pwd"
                          type="password"
                          @keyup.enter="loginBtnClicked">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-row dense class="" align="center">
                          <!-- <v-col cols="5">
                            <v-checkbox
                              hide-details
                              label="로그인 상태 유지">
                            </v-checkbox>
                          </v-col>
                          <v-col cols="7" class="d-flex justify-end">
                            <v-btn
                              x-small
                              text>
                              아이디/비밀번호 찾기
                            </v-btn>
                            <v-icon>mdi-power-on</v-icon>
                            <v-btn
                              x-small
                              text>
                              회원가입
                            </v-btn>
                          </v-col> -->
                          <v-col cols="6">                            
                            <v-btn
                              x-small
                              text
                              @click="mberFindClicked">
                              아이디/비밀번호 찾기
                            </v-btn>
                          </v-col>
                          <v-spacer></v-spacer>
                          <!-- <v-col cols="6" class="d-flex justify-end">                            
                            <v-btn
                              x-small
                              text
                              @click="joinDialLogClicked">
                              회원가입
                            </v-btn>
                          </v-col> -->
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="mb-3">
                        <!-- <span class="body-2 text-red">
                          아이디 혹은 비밀번호가 일치하지 않습니다. 입력한 내용을 다시 확인해주세요.
                        </span> -->
                      </v-col>
                      <v-col cols="12" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          @click="loginBtnClicked"
                          height="40px"
                          class="theme-color-1 white--text">
                          로그인
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center">
                        <!-- <span class="caption">
                          로그인 할 때 주의할 점이나 담당자 연락처 등이 들어갈 수 있습니다.
                        </span> -->
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>

    
    <template>
      <v-dialog v-model="joinDialLog" max-width="500px" max-height="300px" >
        <v-card align-center tile>
          <div class="pa-4">
            <v-form ref="form" lazy-validation>
              <v-row align="center">
                <v-col cols="11" class="">
                  <h2>
                    개인 / 단체
                  </h2>
                </v-col>
                <v-col cols="1" class="d-flex justify-end">
                  <div>
                    <v-btn
                      x-small
                      icon
                      @click="joinDialLog = false">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 border-b-darkgrey-2px"></v-col>
                <v-col cols="12" class="mt-5 mb-5 d-flex justify-center">
                    <v-radio-group
                      v-model="joinType"
                      row
                      hide-details
                      dense
                      class="ma-0 pa-0"
                    >
                      <v-radio
                        label="개인"
                        value="indv"
                      ></v-radio>
                      <v-radio
                        label="단체"
                        value="grp"
                      ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <div class="wid-100">
            <v-btn
              tile
              flat
              class="wid-100 white--text ml-3 f-r bg-indigo hei-50p fontSize-1"
              @click="joinTypeClicked"
            >설정
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      
    </template>
  </v-app>
  
  
</template>

<script>

export default {
  data() {
    return {
      login_id: '',
      pwd: '',
      joinDialLog: false,
      joinType: '',
      imgMap: {},
    }
  },
  created() {
    console.log(this.$route);
    const mberSno = this.$session.get('mberSno');
    if(mberSno) {
      alert('이미 로그인되어있습니다.');
      this.$router.push("/");
      return;
    }
    this.getHeaderImg();
  },
  methods: {
    loginBtnClicked() {
      const formData = new FormData();
      formData.append("login_id", this.login_id);
      formData.append("pwd", this.pwd);
      this.$http.post("/mber/login",formData).then((res) => {
        const code = res.data.code;
        if(code == 0) {
            this.$session.set('mberSno', res.data.mber_sno);
            this.$session.set('nonMberYn', 'N');
            const temp_pwd_yn = res.data.temp_pwd_yn;
            this.$session.set('tempPwdYn', res.data.temp_pwd_yn);
            
            if(temp_pwd_yn == 'Y') {
                alert('임시 비밀번호입니다.\n비밀번호 재설정해주세요.');
                this.$router.push("/pwdReset");
            } else { 
                this.$router.push("/");
            }
            
        } else if(code == -9002) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        } else if(code == -9999) {
            alert("존재하지 않는 아이디입니다.");
            return;
        }

        if(this.$store.state.goUrl) {
          this.$router.push(this.$store.state.goUrl);
        }
        
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//loginBtnClicked end
    joinDialLogClicked() {
      this.joinType = '';
      this.joinDialLog = true;
    },//joinDialLogClicked end
    joinTypeClicked() {
      if(!this.joinType) {
        alert('가입 타입 선택해주세요.');
        return;
      }
      this.$router.push({path:'/join',query:{joinType: this.joinType}})
    },//joinTypeClicked end
    mberFindClicked() {
      this.$router.push('/findId')
    },//mberFindClicked end
    getHeaderImg() {
      this.$http.get("/admin/getSoctyImg").then((res) => {
        if(res.data.result[0]) {
          this.imgMap = res.data.result[0];
        }
        
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getHeaderImg end
  }
}
</script>


<style scoped>
.v-input .v-label{
  font-size:12px;
  font-weight: bold;
}
.v-text-field--enclosed /deep/ .v-input__prepend-inner{
  margin-top: 10px;
}
.v-text-field--enclosed /deep/ .v-input__append-inner{
  margin-top: 10px;
}
.v-input--selection-controls /deep/ {
  margin-top:0px;
}

</style>