<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <!-- <v-col cols="12" class="pa-0 ma-0">
            <div>
              <v-row dense>
                <v-col cols="12">
                  <div class="f-r">
                    <v-btn
                      small
                      color="blue lighten-2"
                      class="white--text"
                      @click="saveBtnClicked"
                    >저장</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col> -->
          
          <v-col cols="2" >
            <div class="pt-0">
              <span>도메인</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="domn_nm"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>학회이름(한글)</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="socty_nm"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>학회이름(영문)</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="socty_nm_en"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>저널명(한글)</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="jrnl_nm"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>저널사이트 URL</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="jrnl_site_url"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>온라인투고시스템 URL</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="onln_sbmt_sys_url"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>학회약자</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="socty_nm_abbr"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
export default {
  data(){
    return{
      mberSno: "",

      domn_nm: "",
      socty_nm: "",
      socty_nm_en: "",
      socty_nm_abbr: "",
      // jrnl_nm: "",
      // jrnl_nm_en: "",
      // jrnl_site_url: "",
      // onln_sbmt_sys_url: "",
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

    this.getSoctyInfo();
  },
  methods: {
    getSoctyInfo() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyBasic", params).then((res) => {
        // console.log("res.data: ", res.data);
        const socty = res.data.result[0];

        this.domn_nm = socty.domn_nm;
        this.socty_nm = socty.socty_nm;
        this.socty_nm_en = socty.socty_nm_en;
        this.socty_nm_abbr = socty.socty_nm_abbr;
        // this.jrnl_nm = socty.jrnl_nm;
        // this.jrnl_nm_en = socty.jrnl_nm_en;
        // this.jrnl_site_url = socty.jrnl_site_url;
        // this.onln_sbmt_sys_url = socty.onln_sbmt_sys_url;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveSocty");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();
        params.append("mber_sno", this.mberSno);
        if(this.domn_nm != null)            params.append("domn_nm", this.domn_nm);
        if(this.socty_nm != null)           params.append("socty_nm", this.socty_nm);
        if(this.socty_nm_en != null)        params.append("socty_nm_en", this.socty_nm_en);
        if(this.socty_nm_abbr != null)      params.append("socty_nm_abbr", this.socty_nm_abbr);        
        // if(this.jrnl_nm != null)            params.append("jrnl_nm", this.jrnl_nm);
        // if(this.jrnl_site_url != null)      params.append("jrnl_site_url", this.jrnl_site_url);
        // if(this.onln_sbmt_sys_url != null)  params.append("onln_sbmt_sys_url", this.onln_sbmt_sys_url);

        this.$http.post("/admin/updateSoctyBasic", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");

          this.getSoctyInfo();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'societyInfoTab');
    },
  },
}
</script>

<style>

</style>