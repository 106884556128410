<template>
  <v-container fluid class="pa-3">
    <v-row class="" align="center">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="gotoList"
          >목록</v-btn>
        </div>
        <div>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!isOnPending"
            @click="saveNAddBtnClicked"
            >저장 후 추가
          </v-btn>
          <v-btn 
            small 
            color="red lighten-2" 
            class="white--text f-r" 
            @click="deleteBtnClicked"
            v-if="newYn == 'N'"
            >게시글 삭제
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense align="center">
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12" v-if="bbstCatgList.length > 0">
        <v-row dense align="center">          
          <v-col cols="2" >
            <div class="pt-0">
              <span>카테고리</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-select
              class="wid-100 pt-1" 
              v-model="pendingPost.bbst_catg"
              :items="bbstCatgList"
              item-text="catg_nm"
              item-value="catg_nm"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="boardType != 'bbs_glly'">
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="pt-0">
              <span>상단 고정</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="pendingPost.upend_fixg_tf"
              hide-details
              dense
              label="페이지 상단에 고정함"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="boardType == 'bbs_notc'">
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="pt-0">
              <span>메인 롤링</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="pendingPost.main_rlng_tf"
              hide-details
              dense
              label="메인 페이지에 롤링 노출함"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>팝업 노출</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="pendingPost.main_popup_tf"
              hide-details
              dense
              label="메인 페이지에 팝업 노출함"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>

      <!-- 팝업 노출 기간 -->
      <v-col cols="12" v-show="pendingPost.main_popup_tf">
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="pt-0">
              <span>팝업 노출 시작일</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class=""
                v-model="pendingPost.popup_bgn_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker(pendingPost.popup_bgn_dt, 'popup_bgn_dt')"
                placeholder="0000-00-00"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker v-model="pendingPost.popup_bgn_dt"></datePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>팝업 노출 종료일</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class=""
                v-model="pendingPost.popup_end_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker(pendingPost.popup_end_dt, 'popup_end_dt')"
                placeholder="0000-00-00"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker v-model="pendingPost.popup_end_dt"></datePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>

      <!-- 등록자 -->
      <v-col cols="2" >
        <div class="pt-0">
          <span>등록자</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingPost.reg_mber_nm"
          class="wid-100 pt-0"
          hide-details
          outlined
          readonly
          dense
          append-icon="mdi-magnify"
          @click="searchMberBtnClicked"
          @click:append="searchMberBtnClicked"
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="2" >
        <div class="pt-0">
          <span>제목 *</span>
        </div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="pendingPost.bbst_ttl"
          class="wid-100 pt-0"
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 내용 -->
      <v-col cols="2" >
        <div class="pt-0">
          <span v-if="boardType != 'bbs_qna'">내용</span>
          <span v-if="boardType == 'bbs_qna'">질문</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor v-model="pendingPost.bbst_cn"></tiny-editor>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <!-- 답변(QNA 유형) -->
      <v-col cols="2" v-if="boardType == 'bbs_qna'">
        <div class="pt-0">
          <span>답변</span>
        </div>
      </v-col>
      <v-col cols="8" v-if="boardType == 'bbs_qna'">
        <tiny-editor v-model="pendingPost.reply_cn"></tiny-editor>
      </v-col>
      <v-col cols="12" v-if="boardType == 'bbs_qna'"><v-divider></v-divider></v-col>

      <v-col cols="12" >
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="pt-0">
              <span>열람 횟수</span>
            </div>
          </v-col>
          <v-col cols="1">
            <numeric-text-field v-model="pendingPost.read_cnt"></numeric-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>등록일시</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class=""
                v-model="pendingPost.reg_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker(pendingPost.reg_dt, 'reg_dt')"
                placeholder="0000-00-00"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker v-model="pendingPost.reg_dt"></datePicker> -->
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                v-model="pendingPost.reg_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
              ></v-text-field>
              <!-- <time-picker-popup v-if="timeModal" @selected="setSelectedTime" @keydown.esc="timeModal = false" @close="timeModal = false" :value="pendingPost.reg_tm"></time-picker-popup> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>

      <v-col v-if="boardType != 'bbs_glly'">
        <v-row dense align="center">
          <v-col cols="2" >
            <div class="">
              <span>파일 첨부</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-100 pt-0">
              <v-file-input
                hide-details
                dense
                multiple
                v-model="atchFiles"
                @change="fileListChanged($event)"
              ></v-file-input>
            </div>
          </v-col>
          <v-col cols="8"></v-col>

          <v-col cols="2"></v-col>
          <v-col>
            <v-row dense align="center" v-for="(item, idx) in pendingFileList" :key="`atch_`+idx"> 
              <v-col cols="2">
                {{item.file.name}}
              </v-col>
              <v-col cols="1">
                <v-btn
                  small
                  :color="item.new_yn == 'Y' ? 'blue lighten-2' :'red lighten-2'"
                  class="white--text mr-5"
                  @click="deleteAtchFileBtnClicked(item)"
                >X</v-btn>
              </v-col>
            </v-row>
          </v-col>   
          <v-col cols="12"><v-divider></v-divider></v-col>       
        </v-row>
      </v-col>

      <!-- 갤러리 이미지 첨부 -->
      <v-col v-if="boardType == 'bbs_glly'">
        <v-row dense>
          <v-col cols="2" >
            <div class="">
              <span>이미지 첨부</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addGllyImgBtnClicked"
              >이미지 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteGllyImgBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          
          <v-col>
            <!-- divider -->
            <v-row dense style="text-align: center;" v-if="pendingGllyImgList.length > 0">
              <v-col cols="2"></v-col>
              <v-col cols="2"><div class=""><v-divider></v-divider></div></v-col>
              <v-col cols="2"><div class=""><v-divider></v-divider></div></v-col>
              <v-col cols="5"><div class=""><v-divider></v-divider></div></v-col>
              <v-col cols="1"><div class=""><v-divider></v-divider></div></v-col>
            </v-row>
            <!-- 갤러리 이미지 헤더 -->
            <v-row dense style="text-align: center;" v-if="pendingGllyImgList.length > 0">
              <v-col cols="2"></v-col>
              <v-col cols="2"><div class=""><span class="">변경 파일</span></div></v-col>
              <v-col cols="2"><div class=""><span class="">현재 파일</span></div></v-col>
              <v-col cols="5"><div class=""><span class="">설명</span></div></v-col>
              <v-col cols="1"><div class=""><span class="">다운로드</span></div></v-col>
            </v-row>
            <!-- divider -->
            <v-row dense style="text-align: center;" v-if="pendingGllyImgList.length > 0">
              <v-col cols="2"></v-col>
              <v-col cols="2"><div class=""><v-divider></v-divider></div></v-col>
              <v-col cols="2"><div class=""><v-divider></v-divider></div></v-col>
              <v-col cols="5"><div class=""><v-divider></v-divider></div></v-col>
              <v-col cols="1"><div class=""><v-divider></v-divider></div></v-col>
            </v-row>
            <!-- 갤러리 이미지 리스트 -->
            <v-row dense v-for="(item, idx) in pendingGllyImgList" :key="idx">              
              <v-col cols="2" >
                <v-checkbox
                  v-model="item.select_yn"
                  hide-details
                  dense
                  :label="'이미지 ' + item.idx"
                ></v-checkbox>
              </v-col>
              <v-col cols="2">
                <div class="wid-100 pt-0">
                  <v-file-input
                    hide-details
                    dense
                    v-model="item.new_file"
                    @change="gllyImgChanged(item)"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="f-l pt-2 pl-5 font-1" style="color:white;">
                  <span><a :href="item.file_path">{{item.orgnl_file_nm}}</a></span>
                  <!-- <v-btn
                    v-if="item.file_path"
                    small
                    color="red lighten-2"
                    class="white--text ml-5"
                    style="min-width:0px; width:30px"
                    @click="deleteGllyImgAtchFileBtnClicked(item.file_path, item.file_atch_sno)"
                  >X</v-btn> -->
                </div>
              </v-col>
              <!-- <v-col cols="1" style="text-align: right;">
                <div class="">
                  <span class="mr-5">설명 :</span>
                </div>
              </v-col> -->
              <v-col cols="5">
                <v-text-field
                  v-model="item.img_desc"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <numeric-text-field v-model="item.dwld_cnt" :readonly="true"></numeric-text-field>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-col>

    </v-row>  
    <member-search-popup v-if="mberSrchPopup" @close="closeMberSrchPopup" @selected="setRegMberSno"></member-search-popup>
  </v-container>  
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';
import numericTextField from '@/components/common/numericTextField.vue';
// import datePicker from "@/components/common/datePicker.vue";
// import timePicker from "@/components/common/timePicker.vue";
// import timePickerPopup from "@/components/common/timePickerPopup.vue";
import memberSearchPopup from '@/components/common/memberSearchPopup.vue';
// import dayjs from "dayjs";
import datePickerPopup from '@/components/common/datePickerPopup.vue';

const genEmptyPost = (menuSno = null) => ({
  bbst_sno: null,
  menu_sno: menuSno,
  bbst_ttl: "",
  bbst_cn: "",
  bbst_catg: "",
  upend_fixg_tf: false,
  main_rlng_tf: false,
  main_popup_tf: false,
  upend_fixg_yn: "",
  main_rlng_yn: "",
  main_popup_yn: "",
  popup_bgn_dt: "",
  popup_end_dt: "",
  file_atch_sno: null,
  reg_mber_sno: null,
  reg_mber_nm: "",
  read_cnt: 0,
  reg_dt: null,
  reg_tm: null,
  
});

export default {
  // timePicker
  components: { tinyEditor, numericTextField,  memberSearchPopup , datePickerPopup },
  data(){
    return{
      mberSno: null,

      menuSno: this.$route.query.menuSno,
      boardType: this.$route.query.boardType,
      postSno: this.$route.query.postSno,
      newYn: this.$route.query.newYn,
      bbstCatg: this.$route.query.bbstCatg,
      bbstCatgList: [],
      post: {},
      pendingPost: {},
      // upend_fixg_yn: false,
      // main_rlng_yn: false,
      // main_popup_yn: false,
      // popup_bgn_dt: "",
      // popup_end_dt: "",
      // reg_mber_sno: null,
      // reg_mber_nm: "",
      // bbst_ttl: "",
      // bbst_cn: "",
      // bbst_catg: "",
      // read_cnt: "",
      // file_atch_sno: null,
      // reg_dt: "",
      // reg_tm: "",

      atchFiles: [],
      fileList: [],
      pendingFileList: [],
      fileIdx: 1,

      gllyImgList: [],
      pendingGllyImgList: [],
      gllyImgIdx: 1,

      // isOnPending: false,
      mberSrchPopup: false,
      curDate: "",
      datePopup: false,
      dateNm: '',
      // timeModal: false,
    }
  },
  watch: {
    '$route' () {
      this.$router.go(0);
    }
  },
  computed: {
    isOnPending() {
      console.log("isOnPending");
      return JSON.stringify(this.post) != JSON.stringify(this.pendingPost)
        || JSON.stringify(this.fileList) != JSON.stringify(this.pendingFileList)
        || JSON.stringify(this.gllyImgList) != JSON.stringify(this.pendingGllyImgList)
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    // console.log("bbstSno:", this.bbstSno);
    console.log("postDetail created:", this.boardType, this.bbstCatg, this.bbstCatgList);

    if(this.newYn == 'Y') {
      this.post = genEmptyPost(this.menuSno);
      this.pendingPost = JSON.parse(JSON.stringify(this.post));
      this.getBbsRegMber();
      this.setYYYYMMDDHHMM();

    }

    if(this.bbstCatg != null) {
      this.bbstCatgList = this.bbstCatg.split(',').reduce((acc, value) => {
        return [...acc, {"catg_nm": value}]
      }, []);
    }

    // if(this.newYn == 'Y' && this.bbstCatgList.length > 0) {
    //   this.pendingPost.bbst_catg = this.bbstCatgList[0].catg_nm;
    // }
  },
  mounted() {
    if(this.newYn == 'N') {
      this.getPost();
    } 
  },
  methods: {
    gotoList() {
      // this.$router.go(-1)
      // 아래 path 설정 시 select box 에 값 설정이 안됨
      // this.$router.push({path:"preRegistList", query: { confrSno:this.confrSno }});
      this.$router.push({name:"postList", query: { menuSno:this.menuSno, postSno:this.postSno }});
    },
    getPost() {
      console.log("this.postSno(in getPost):", this.postSno);
      const params = new FormData();

      params.append("bbst_sno", this.postSno);
      params.append("page_type", this.boardType);

			this.$http.post("/admin/getPost", params).then((res) => {
        console.log("res.data: ", res.data);
        const post = res.data.result.post;

        post.upend_fixg_tf = post.upend_fixg_yn == 'Y' ? true : false;
        post.main_rlng_tf = post.main_rlng_yn == 'Y' ? true : false;
        post.main_popup_tf = post.main_popup_yn == 'Y' ? true : false;

        this.post = post;
        this.pendingPost = JSON.parse(JSON.stringify(post));

        // this.upend_fixg_yn = post.upend_fixg_yn == 'Y' ? true : false;
        // this.main_rlng_yn = post.main_rlng_yn == 'Y' ? true : false;
        // this.main_popup_yn = post.main_popup_yn == 'Y' ? true : false;
        // this.bbst_ttl = post.bbst_ttl;
        // this.bbst_cn = post.bbst_cn;
        // this.bbst_catg = post.bbst_catg;
        // this.popup_bgn_dt = post.popup_bgn_dt;
        // this.popup_end_dt = post.popup_end_dt;
        // this.reg_mber_sno = post.reg_mber_sno;
        // this.reg_mber_nm = post.reg_mber_nm;
        // this.reg_dt = post.reg_dt;
        // this.reg_tm = post.reg_tm;
        // this.read_cnt = post.read_cnt;
        // this.file_atch_sno = post.file_atch_sno;

        this.fileList = [];

        if(res.data.result.atchFileList != null) {
          res.data.result.atchFileList.forEach((e) => {
            this.fileList.push({file: {name: e.orgnl_file_nm}, new_yn: 'N', idx: this.fileIdx++, atch_odr: e.atch_odr, file_path: e.save_path + e.save_file_nm});
          })
        }
        this.pendingFileList = JSON.parse(JSON.stringify(this.fileList));

        this.gllyImgList = [];
        this.gllyImgIdx = 1;

        if(res.data.result.gllyImgList != null) {
          res.data.result.gllyImgList.forEach((e) => {
            this.gllyImgList.push({new_yn:'N', select_yn:false, idx:this.gllyImgIdx++, bbst_sno:e.bbst_sno, odr:e.odr, img_desc:e.img_desc, dwld_cnt:e.dwld_cnt,
              new_file:null, file_atch_sno:e.file_atch_sno, atch_odr:e.atch_odr, orgnl_file_nm:e.orgnl_file_nm, file_path:e.file_path });
          })
        }
        this.pendingGllyImgList = JSON.parse(JSON.stringify(this.gllyImgList));
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 제목
      if(this.pendingPost.bbst_ttl == null || this.pendingPost.bbst_ttl == "") {
        alert("제목은 필수 입력값입니다.");
        return false;
      }

      if(this.pendingPost.reg_dt && this.pendingPost.reg_dt.length != 10) {
        alert('등록일이 유효한 형식 아닙니다.');
        return false;
      }

      if(this.pendingPost.reg_tm && this.pendingPost.reg_tm.length != 5) {
        alert('등록시간이 유효한 형식 아닙니다.');
        return false;
      }
      
      return true;
    },
    saveNAddBtnClicked() {
      this.savePost("add");
      // console.log("out of save");
    },
    saveNStayBtnClicked() {
      this.savePost("stay");
      // console.log("out of save");
    },
    saveNMoveBtnClicked() {
      this.savePost("move");
      // console.log("out of save");
    },
    savePost(action) {
      // console.log("savePost:", this.pendingPost.read_cnt);

      // if(confirm("저장하시겠습니까?")) {
        const isValid = this.validateInput();

        if(isValid) {
          const params = new FormData();

          if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
          params.append('new_yn', this.newYn);
          params.append('page_type', this.boardType);

          this.pendingPost.upend_fixg_yn = this.pendingPost.upend_fixg_tf ? 'Y' : 'N';
          this.pendingPost.main_rlng_yn = this.pendingPost.main_rlng_tf ? 'Y' : 'N';
          this.pendingPost.main_popup_yn = this.pendingPost.main_popup_tf ? 'Y' : 'N';

          this.pendingPost.bbst_cn = this.pendingPost.bbst_cn.replaceAll("../DEV","/DEV");
          params.append("post", JSON.stringify(this.pendingPost));

          // params.append("menu_sno", this.menuSno);
          // params.append("bbst_sno", this.postSno);
          
          // params.append("bbst_ttl", this.bbst_ttl);
          // params.append("bbst_cn", this.bbst_cn);
          // params.append("bbst_catg", this.bbst_catg);
          // params.append("upend_fixg_yn", this.upend_fixg_yn ? 'Y' : 'N');
          // params.append("main_rlng_yn", this.main_rlng_yn ? 'Y' : 'N');
          // params.append("main_popup_yn", this.main_popup_yn ? 'Y' : 'N');
          // params.append("popup_bgn_dt", this.popup_bgn_dt);
          // params.append("popup_end_dt", this.popup_end_dt);
          // params.append("read_cnt", this.read_cnt);
          // if(this.reg_mber_sno != null)  params.append("reg_mber_sno", this.reg_mber_sno);

          // 첨부 파일
          if(this.boardType != "bbs_glly") {
            this.pendingFileList.filter((e) => e.new_yn == 'Y').forEach((e) => {
              console.log(e.file);
              params.append('file_list', e.file);
            });
          }

          // 갤러리 첨부 이미지
          if(this.boardType == "bbs_glly") {
            this.pendingGllyImgList.forEach((e) => {
              console.log("pendingGllyImgList:", e);
              if(e.new_file /*e.new_yn == 'Y'*/) {
                params.append('file_list', e.new_file);
              }
            })

            params.append('glly_img_list', JSON.stringify(this.pendingGllyImgList));
          }
          
          this.$http.post("/admin/upsertPost", params, {
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then((res) => {

            if(res.data.code < 0 ) {
              alert('저장 실패 되었습니다. 다시 입력해주세요.');
              return;
            } else {
              alert("저장되었습니다.");
            }

            if(this.newYn == 'Y' && !this.postSno) {
              this.$router.go(0);
            }
            // this.isOnPending = false;
            if(this.newYn == 'Y') {
              this.postSno = res.data.result.bbst_sno;
              this.newYn = 'N';
            }

            // console.log("in save: after alert 1");
            if(action == "stay") {
              this.getPost();
            } else if(action == "move") {
              this.gotoList();
            } else if(action == "add") {
              this.$router.push({name:"postDetail", query: { menuSno:this.menuSno, boardType:this.boardType, bbstCatg:this.bbstCatg, postSno:null, newYn:'Y' }});
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      // }
    },
    deleteBtnClicked() {
      console.log("deletePost");

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        // if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
        // params.append("bbst_sno", this.postSno);
        // params.append("file_atch_sno", this.file_atch_sno);
        params.append("page_type", this.boardType);
        params.append("post", JSON.stringify(this.pendingPost));

        this.$http.post("/admin/deletePost", params).then((res) => {
          console.log(res.data);

          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.gotoList();
          } else {
            alert("삭제할 수 없습니다.");
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    // itemChanged() {
    //   this.isOnPending = true;
    //   // console.log("itemChanged:", this.read_cnt);
    // },
    searchMberBtnClicked() {
      this.mberSrchPopup = true;
    },
    closeMberSrchPopup() {
      this.mberSrchPopup = false;
    },
    setRegMberSno(mberSno, mberNm) {
      console.log("setRegMberSno:", mberSno);
      // this.itemChanged();

      this.pendingPost.reg_mber_sno = mberSno;
      this.pendingPost.reg_mber_nm = mberNm; //+ "(" + mberSno + ")";
      this.mberSrchPopup = false;
    },
    fileListChanged(event) {            
      for(const a in event) {
        this.pendingFileList.push({file: event[a], new_yn: 'Y', idx: this.fileIdx++});
      }
      this.atchFiles = [];

    },//fileListChanged end
    deleteAtchFileBtnClicked(item) {
      if(confirm('삭제하시겠습니까?')) {
        console.log(item);
        if(item.new_yn == 'Y') {
          this.pendingFileList = this.pendingFileList.filter((e) => e.idx != item.idx);
        } else {
          // this.deleteAtchFile(item.atch_odr);
          const params = new FormData();

          params.append("file_atch_sno", this.pendingPost.file_atch_sno);
          params.append("atch_odr", item.atch_odr);
          params.append("file_path", item.file_path);
          
          this.$http.post("/admin/deletePostFile", params).then((res) => {
            if(res.data.code == 0) {
              alert('삭제되었습니다.');
              this.getAtchFileList();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      }  
    },//atchDelClicked end
    getAtchFileList() {
      const params = new FormData();

      params.append("file_atch_sno", this.pendingPost.file_atch_sno);

      this.$http.post("/common/selectAtchFileList", params).then((res) => {
        console.log(res.data);
        // const fileData = res.data.atchFileList;
        this.fileList = [];
        this.fileIdx = 1;

        res.data.atchFileList.forEach((e) => {
          this.fileList.push({file: {name: e.orgnl_file_nm}, new_yn: 'N', idx: this.fileIdx++, atch_odr: e.atch_odr, file_path: e.save_path + e.save_file_nm});
        });
        this.pendingFileList = JSON.parse(JSON.stringify(this.fileList));
      })
      .catch(function (error) {
        console.log(error);
      });
    },//getAtchFileList end
    // deleteAtchFile(atch_odr) {
    //   const params = new FormData();

    //   params.append("file_atch_sno", this.pendingPost.file_atch_sno);
    //   params.append("atch_odr", atch_odr);
      
    //   this.$http.post("/common/deleteAtchFile", params).then((res) => {
    //     if(res.data.code == 0) {
    //       alert('삭제되었습니다.');
    //       this.selectAtchFileList();
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    // },//deleteAtchFile end
    // 갤러리 이미지 추가
    addGllyImgBtnClicked() {
      let maxOdr = 0;

      this.pendingGllyImgList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });
      
      this.pendingGllyImgList.push({new_yn:'Y', select_yn:false, idx:this.gllyImgIdx++, bbst_sno:this.postSno, odr:maxOdr + 1, img_desc:null, dwld_cnt:0,
        new_file:null, file_atch_sno:null, atch_odr:null, orgnl_file_nm:null, file_path:null });
    },
    gllyImgChanged(item) {
      item.orgnl_file_nm = item.new_file.name;
      console.log("gllyImgChanged:", item);
    },
    deleteGllyImgBtnClicked() {
      console.log("deleteGllyImgBtnClicked");
      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.pendingGllyImgList.length; i++) {
      //   if(this.pendingGllyImgList[i].select_yn) {
      //     isSelected = true;

      //     if(this.pendingGllyImgList[i].new_yn == 'N') {
      //       deleteList.push(this.pendingGllyImgList[i]);
      //     }
      //   }
      // }
      deleteList = this.pendingGllyImgList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && e.new_yn == 'N';
      });

      // 데이터 검증
      deleteList.forEach((e) => {
        console.log("deleteList:", e);
      });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteGllyBbsImgList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getGllyBbsImgList();
            this.pendingGllyImgList = this.pendingGllyImgList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getGllyBbsImgList();
          this.pendingGllyImgList = this.pendingGllyImgList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    // deleteGllyImgAtchFileBtnClicked(filePath, fileAtchSno) {
    //   if(confirm("삭제하시겠습니까?")) {
    //     // console.log("filePath:", filePath);
    //     // console.log("atchSno:", fileAtchSno);
        
    //     if(filePath == null) {
    //       alert("삭제할 파일이 없습니다.");
    //       return;
    //     }

    //     const params = new FormData();

    //     params.append("mber_sno", this.mberSno);
    //     params.append("file_path", filePath);
    //     params.append("file_atch_sno", fileAtchSno);

    //     this.$http.post("/admin/deleteGllyBbsImgAtchFile", params).then((res) => {
    //       console.log(res.data);
    //       alert("삭제되었습니다.");
          
    //       this.getSoctyImg();
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //       }
    //     );
    //   }
    // },
    getGllyBbsImgList() {
      const params = new FormData();

      params.append("bbst_sno", this.pendingPost.bbst_sno);

      this.$http.post("/admin/getGllyBbsImgList", params).then((res) => {
        console.log(res.data);

        this.gllyImgList = [];
        this.gllyImgIdx = 1;

        res.data.result.gllyImgList.forEach((e) => {
          this.gllyImgList.push({new_yn:'N', select_yn:false, idx:this.gllyImgIdx++, bbst_sno:e.bbst_sno, odr:e.odr, img_desc:e.img_desc, dwld_cnt:e.dwld_cnt,
            new_file:null, file_atch_sno:e.file_atch_sno, atch_odr:e.atch_odr, orgnl_file_nm:e.orgnl_file_nm, file_path:e.file_path });
        });
        this.pendingGllyImgList = JSON.parse(JSON.stringify(this.gllyImgList));
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getBbsRegMber() {
      const params = new FormData();

      params.append("mber_sno", this.mberSno);

      this.$http.post("/admin/getBbsRegMber", params).then((res) => {
        console.log(res.data);
        const regMberMap = res.data.regMberMap;
        if(regMberMap) {
          
          this.pendingPost.reg_mber_sno = this.mberSno;
          this.pendingPost.reg_mber_nm = regMberMap.reg_mber_nm;
        }
        
      })
      .catch(function (error) {
        console.log(error);
      });
    },//getBbsRegMber end
    popupDatePicker( curDate, dateNm ) {

      this.curDate = curDate;
      this.dateNm = dateNm;
      this.datePopup = true;
      
    },
    // popupTimePicker() {
    //   this.timeModal = true;
    // },//popupTimePicker end
    setSelectedDate(selected) {
      if(this.dateNm == "reg_dt") {
        this.pendingPost.reg_dt = selected;
      } else if(this.dateNm == "popup_bgn_dt") {
        this.pendingPost.popup_bgn_dt = selected;
      } else if(this.dateNm == "popup_end_dt") {
        this.pendingPost.popup_end_dt = selected;
      }
      
      // console.log('selected',selected);
      
      this.datePopup = false;
    },
    // setSelectedTime(selected) {
    //   // alert(selected);
    //   this.pendingPost.reg_tm = selected;
    // },//setSelectedTime end
    pad(number, length) {
      var str = '' + number;
      while (str.length < length) {
        str = '0' + str;
      }
      return str;
    },//pad end
    setYYYYMMDDHHMM() {
      var nowDate = new Date();
      var yyyy = nowDate.getFullYear().toString();
      var MM = this.pad(nowDate.getMonth() + 1,2);
      var dd = this.pad(nowDate.getDate(), 2);
      var hh = this.pad(nowDate.getHours(), 2);
      var mm = this.pad(nowDate.getMinutes(), 2)

      let fullDate = yyyy + '-' + MM + '-' + dd;
      let time = hh + ':' + mm;
      this.pendingPost.reg_dt = fullDate;
      this.pendingPost.reg_tm = time;
    },//setYYYYMMDDHHMM end
    
  },
}
</script>

<style>

</style>