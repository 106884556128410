<template>
    <v-container class="pa-3" v-if="mberSno != ''">
      <v-row class="pa-0 mb-10" >
        <v-col cols="12" class="pa-0 d-flex align-center">
          <h1 class="mr-3">{{payType == 'list' ? '결제 대기' : '결제 항목'}}</h1>
        </v-col>
        <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
        <!-- list -->
        <v-col cols="12" class="pa-0" v-if="payType=='list'">
          <v-data-table
            :headers="headers"
            :items="toPayList"
            hide-default-footer
            item-key="name"
            class="elevation-0"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.chk`]="{ item }">
              <v-checkbox
                v-model="item.chk"
                @change="chkChanged"
              >
              </v-checkbox>
            </template>
            <template v-slot:[`item.prod_nm`]="{ item }">
              <span class="">{{ item.prod_nm }}</span>
            </template>
            <template v-slot:[`item.reg_dtm`]="{item}">
              <span class="hidden-md-and-down">{{ item.reg_dtm }}</span>
              <span class="hidden-md-and-up">{{ item.mob_reg_dtm }}</span>
            </template>
          </v-data-table>
        </v-col>
        <!-- detail -->
        <v-col cols="12" class="pa-0 mb-5" v-if="payType=='detail'">
          <v-data-table
            :headers="headers_dtl"
            :items="payList"
            hide-default-footer
            item-key="name"
            class="elevation-0"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.prod_nm`]="{ item }">
              <span class="fontSize-2 font-weight-bold text-blue">{{ item.prod_nm }}</span>
            </template>
          </v-data-table>
          <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
          <v-col cols="12">총 결제 금액 : {{totalAmt.toLocaleString('ko-KR')}}원</v-col>
        </v-col>
  
        <template v-if="payType=='detail'">
          <v-row class="mb-3">
            <v-col cols="12">
              <h1 class="mr-3">주문자 정보</h1>
            </v-col>
          </v-row>
            <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
            <v-col cols="6" class="d-flex align-center">
              <div class="wid-100 d-flex align-center">
                <div class="wid-30">
                  <span class="fontSize-0">주문자 명 *</span>
                </div>
                <div class="wid-70">
                  <v-text-field 
                    outlined
                    dense
                    hide-details
                    v-model="orderData.payr_nm"
                    readonly
                    height="30px"
                    class="bg-grey">
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <div class="wid-100 d-flex align-center">
                <div class="wid-30">
                  <span class="fontSize-0 pl-2">이메일 *</span>
                </div>
                <div class="wid-70">
                  <v-text-field 
                    outlined
                    dense
                    hide-details
                    v-model="orderData.payr_email">
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <div class="wid-100 d-flex align-center">
                <div class="wid-30">
                  <span class="fontSize-0">휴대폰 번호 *</span>
                </div>
                <div class="wid-70">
                  <v-text-field 
                    outlined
                    dense
                    hide-details
                    v-model="orderData.payr_cel_no">
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <div class="wid-100 d-flex align-center">
                <div class="wid-30">
                  <span class="fontSize-0 pl-2">전화번호</span>
                </div>
                <div class="wid-70">
                  <v-text-field 
                    outlined
                    dense
                    hide-details
                    v-model="orderData.payr_tel_no">
                  </v-text-field>
                </div>
              </div>
            </v-col>
          <!-- </v-row> -->
      </template>
  
        <v-col cols="12" class="pa-0 mt-5">
          <div class="border-darkgrey-3px hei-150p wid-100 align-center d-flex pa-10">
            <v-row class="ma-0">
              <v-col cols="12" md="6" sm="6">
                <div class="d-flex justify-center">
                  <div>
                    <span class="fontSize-3 mr-4">결제 예정 금액 : </span> 
                    <span class="fontSize-9 text-red font-weight-bold mr-2">{{totalAmt.toLocaleString('ko-KR')}}</span>
                    <span>원</span>
                  </div>
                  <v-spacer class="hidden-xs-only"></v-spacer>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="6" sm="6">
                <div class="d-flex justify-center">
                  <v-spacer class="hidden-xs-only"></v-spacer>
                  <v-btn 
                    x-large 
                    class="elevation-0 white--text fontSize-3" 
                    :color="hasSelectedItems() ? 'blue lighten-2' : 'grey'"
                    width="200px"
                    @click="payClicked"
                  >
                    결제하기
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- <v-col cols="12" class="">
          <div><span style="color:#DEA75C;">결제는<a>마이페이지>결제</a> 페이지에서 진행해주시기 바랍니다.</span></div>
        </v-col> -->
      </v-row>
      
      <v-row class="pa-0 pt-10 mt-10 mb-10" v-if="payType=='list'">
        <v-col cols="12" class="pa-0 d-flex align-center">
          <h1 class="mr-3">결제 완료</h1>
          <v-spacer></v-spacer>
            <!-- <div>
              <v-btn large outlined class="bg-lightblue font-weight-bold" width="120px">
                전체 내역 보기
              </v-btn>
            </div> -->
        </v-col>
        <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
        <v-col cols="12" class="pa-0">
          <v-data-table
            :headers="headers2"
            :items="paidList"
            item-key="name"
            class="elevation-0"
            @click:row="payCmpltRowClicked($event)"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.pay_dtm`]="{item}">
              <span class="hidden-md-and-down">{{ item.pay_dtm }}</span>
              <span class="hidden-md-and-up">{{ item.mob_pay_dtm }}</span>
            </template>
            <!-- <template v-slot:[`item.chk`]="{ item }">
              <v-checkbox
                v-model="item.chk">
              </v-checkbox>
            </template> -->
            <template v-slot:[`item.test_2`]="{ item }">
              <span class="fontSize-2 font-weight-bold text-blue">{{ item.test_2 }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
  
      <form
        id="PayForm"
        target="PayForm"
        method="POST"
        :key="redrawCount"
      >
        <input
          type="hidden"
          name="goodname"
          v-model="goodname"
        >
        <input
          type="hidden"
          name="buyername"
          v-model="buyername"
        >
        <input
          type="hidden"
          name="buyertel"
          v-model="buyertel"
        >
        <input
          type="hidden"
          name="buyeremail"
          v-model="buyeremail"
        >
        <input
          type="hidden"
          name="price"
          v-model="price"
        >
        <input
          type="hidden"
          name="mid"
          v-model="mid"
        >
        
        <input
          type="hidden"
          name="oid"
          v-model="oid"
        >
        
        <input
          type="hidden"
          name="timestamp"
          v-model="timestamp"
        >
        
        <input
          type="hidden"
          name="signature"
          v-model="signature"
        >
        
        <input
          type="hidden"
          name="mKey"
          v-model="mKey"
        >
        
        <input
          type="hidden"
          name="returnUrl"
          v-model="returnUrl"
        >
        
        <input
          type="hidden"
          name="closeUrl"
          v-model="closeUrl"
        >
        
        <input
          type="hidden"
          name="verification"
          v-model="verification"
        >
        
        <input
          type="hidden"
          name="currency"
          value="WON"
        >
        
        <input
          type="hidden"
          name="gopaymethod"
          :value="gopaymethod"
        >
        
        <input
          type="hidden"
          name="use_chkfake"
          value="Y"
        >
        
        <input
          type="hidden"
          name="version"
          value="1.0"
        >
        <input
          type="hidden"
          name="acceptmethod"
          value="HPP(1):below1000:va_receipt:centerCd(Y)"
        >
        <input
          type="hidden"
          name="merchantData"
          v-model="merchantData"
        >
      </form>
      <iframe 
        name="PayForm"  
        style="display:none; width:100vw; height: 100vh"
      />
    </v-container>
  </template>
  
  <script language="javascript" type="text/javascript" src="https://stgstdpay.inicis.com/stdjs/INIStdPay.js" charset="UTF-8"></script>
  <script>
  export default {
    data () {
        return {
          headers: [
            {
              text: '선택',
              value: 'chk',
              class: 'my-header-style'
            },
            { text: '분류', value: 'typeNm', class: 'my-header-style' },
            { text: '결제명', value: 'prod_nm', class: 'my-header-style', align: ' d-none d-sm-table-cell' },
            { text: '금액', value: 'amt_data', class: 'my-header-style' },
            { text: '요청일', value: 'reg_dtm', class: 'my-header-style', align: ' d-none d-sm-table-cell' },
          ],
          headers_dtl: [
            { text: '분류', value: 'typeNm', class: 'my-header-style' },
            { text: '결제명', value: 'prod_nm', class: 'my-header-style', align: ' d-none d-sm-table-cell' },
            { text: '금액', value: 'amt_data', class: 'my-header-style' },
            { text: '요청일', value: 'reg_dtm', class: 'my-header-style' },
          ],
          headers2: [
            // {
            //   text: '선택',
            //   value: 'chk',
            //   class: 'my-header-style'
            // },
            { text: '분류', value: 'typeNm', class: 'my-header-style' },
            { text: '결제명', value: 'prod_nm', class: 'my-header-style', align: ' d-none d-sm-table-cell' },
            { text: '금액', value: 'amt_data', class: 'my-header-style' },
            { text: '결제일', value: 'pay_dtm', class: 'my-header-style' },
          ],
          testItems2: [
            // {
            //   chk: false,
            //   test_1: "연회비",
            //   test_2: "2022년 연회비 (정회원) : 50,000원",
            //   test_3: "50,000원",
            //   test_4: "2023.06.29 15:42",
            // },
          ],
          mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
          emitData: {},
          toPayList: [],
          payList: [],
          totalAmt: 0,
          basicUri: '',
          payType: 'list',
          orderData: {},
          //pay
          goodname: '',
          buyername: '',
          buyertel: '',
          buyeremail: '',
          price: '',
          mid: '',
          oid: '',
          timestamp: '',
          signature: '',
          mKey: '',
          returnUrl: '',
          closeUrl: '',
          verification: '',
          gopaymethod: '',
          redrawCount: 0,
          merchantData: '',
          basicUri: '',
          paidList: [],
          socty_sno: '',
          prodTypeList: [],
        }
      },
      created() {
        // let path = this.$route.path;
        // this.basicUri = path.substring(path.lastIndexOf('/'), -1);
        // this.returnUrl = window.location.href.substring(window.location.href.lastIndexOf('/'), -1);
  
        if(!this.mberSno) {
          // alert('비회원 사용불가능한 화면입니다.');
          if(confirm('회원 전용입니다. 로그인하시겠습니까?')) {
            this.$router.push('/login');
          }
        } else {
  
          const formData = new FormData();
          formData.append("mber_sno", this.mberSno);

          function mobileFormDt(str) {
            const date = new Date(str)
            const years = (date.getFullYear())
            const months = ((date.getMonth() + 1) < 10 ?  '0' +(date.getMonth() + 1) :'' + (date.getMonth() + 1))
            const dates = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
            return `${years}-${months}-${dates} `
          }
  
          this.$http.post('/sub/myPage/payList', formData).then((res) => {
            console.log(res.data);

            this.toPayList = res.data.toPayList;
            this.prodTypeList = res.data.prodTypeList;
            this.paidList = res.data.paidList;
            this.socty_sno = res.data.socty_sno;
            this.toPayList.forEach((e) => {
              e.chk = false;
              e.amt_data = e.amt.toLocaleString('ko-KR') + '원';
              e.mob_reg_dtm =  mobileFormDt(e.reg_dtm);
              e.typeNm = this.prodTypeList.filter((f) => f.cmn_cd == e.type)[0].cmn_cd_nm;
              // if(e.type == 'mber') {
              //   e.typeNm = '입회비';
              // } else if(e.type == 'confr'){
              //   e.typeNm = '학술대회';
              // }
            })
  
            this.paidList.forEach((e) => {
              e.amt_data = e.amt.toLocaleString('ko-KR') + '원';
              e.mob_pay_dtm =  mobileFormDt(e.pay_dtm);
              if(this.prodTypeList.filter((f) => f.cmn_cd == e.type).length > 0) {
                e.typeNm = this.prodTypeList.filter((f) => f.cmn_cd == e.type)[0].cmn_cd_nm;
              } else {
                e.typeNm = "";
              }
              
              // if(e.type == 'mber') {
              //   e.typeNm = '입회비';
              // } else if(e.type == 'confr'){
              //   e.typeNm = '학술대회';
              // }
            })
  
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
        
      },
      methods: {
        hasSelectedItems() {
          return this.toPayList.some(item => item.chk);
        },
        chkChanged() {
          const list = this.toPayList.filter((e) => e.chk == true);
          this.totalAmt = 0;
          list.forEach((e) => {
            this.totalAmt += e.amt;
          })
          
        },//chkChanged end
        payClicked() {
          if(this.payType == 'list') {
            const len = this.toPayList.filter((e) => e.chk == true).length;
            if(len == 0) {
              alert('결제할 항목이 없습니다.');
            } else {
              
              const formData = new FormData(); 
              formData.append('mber_sno',this.mberSno);
  
              this.$http.post("/sub/getPayrData",formData).then((res) => {
                console.log("res.data: ", res.data);
                const payrMap = res.data.payrMap;
                if(payrMap.payr_tel_no) {
                  payrMap.payr_tel_no = this.$getNumberOnly(payrMap.payr_tel_no)
                  // this.$phoneFomatter(payrMap.payr_tel_no)
                }
  
                if(payrMap.payr_cel_no) {
                  payrMap.payr_cel_no = this.$getNumberOnly(payrMap.payr_cel_no)
                }
                this.orderData = payrMap;
                this.payList = this.toPayList.filter((e) => e.chk == true);
                this.payType = 'detail';
              }).catch(function (error) {
                  console.log(error);
                }
              );
              
            }
          } else if(this.payType == 'detail') {
            if(this.payList.length == 0) {
              alert('결제할 항목이 없습니다.');
            } else {
              
              if(this.invalidCheck()) {
  
                const formData = new FormData();
                formData.append('payList',JSON.stringify(this.payList));
                formData.append('orderData',JSON.stringify(this.orderData));
                formData.append('mber_sno',this.mberSno);
                this.$http.post('/sub/getPayValid',formData).then((res) => {
  
                  if(res.data.code < 0) {
                    alert('결제 실패했습니다. 다시 시도해주세요.');
                    this.$router.go(0);
                  } else {
                    this.buyeremail = res.data.buyeremail;
                    this.buyername = res.data.buyername;
                    this.goodname = res.data.goodname;
                    this.price = res.data.price;
                    this.buyertel = res.data.buyertel;
                    this.sendForm();
                  }
                })
                .catch(function (error) {
                    console.log(error);
                  }
                );
              }
            }
          }
          
        },//payClicked end
        invalidCheck() {
          
          if(!this.orderData.payr_nm) {
            alert('주문자 명은 필수입니다.');
            // this.validYn = false;
            return false;
          } else if(!this.orderData.payr_cel_no) {
            alert('휴대전화번호는 필수입니다.');
            // this.validYn = false;
            return false;
          } else if(!this.orderData.payr_email) {
            alert('이메일은 필수입니다.');
            // this.validYn = false;
            return false;
          } else if(!this.$validateEmail(this.orderData.payr_email)) {
            alert('이메일 형식이 아닙니다.');
            return false;
          } else if(this.$validateNum(this.orderData.payr_cel_no)) {
            alert('휴대폰 번호는 숫자만 입력가능합니다.');
            return false;
          } else if(this.$validateNum(this.orderData.payr_tel_no)) {
            alert('전화번호는 숫자만 입력가능합니다.');
            return false;
          }
  
          return true;
        },//invalidCheck end
        sendForm() {
          const formData = new FormData(); 
          // formData.append('data',JSON.stringify(this.data));
          formData.append('price',this.price);
          formData.append('payr_nm',this.orderData.payr_nm);
          formData.append('payr_cel_no',this.orderData.payr_cel_no);
          formData.append('payr_tel_no',this.orderData.payr_tel_no);
          formData.append('payr_email',this.orderData.payr_email);
          formData.append('mber_sno',this.mberSno);
          formData.append('returnUrl',this.returnUrl);
          formData.append('goodname',this.goodname);
  
          this.$http.post("/sub/api/v1/pay/pcPay",formData).then((res) => {
            console.log("res.data: ", res.data);
            this.mid = res.data.mid;
            this.oid = res.data.oid;
            this.mKey = res.data.mKey;
            this.timestamp = res.data.timestamp;
            this.signature = res.data.signature;
            // this.returnUrl = this.returnUrl+'/detail?payNo='+this.oid;
            this.returnUrl = res.data.returnUrl;
            this.closeUrl = window.location.href;
            this.verification = res.data.verification;
            this.gopaymethod = res.data.gopaymethod;
            const merchantList = [];
            this.payList.forEach((e) => {
              merchantList.push({no: e.no, unq_no: e.unq_no,  type: e.type, socty_sno: this.socty_sno});
            })
            this.merchantData = encodeURIComponent(JSON.stringify(merchantList));
            this.redrawCount++;
            INIStdPay.pay('PayForm');
            
          }).catch(function (error) {
              console.log(error);
            }
          );
        },//snedForm end
        payCmpltRowClicked(event) {
          this.$router.push('/mypage/payDetail?payNo='+event.pay_no);
          
        },//testClicked end
      }
    }
  
  </script>
  
  <style scoped>
  .my-header-style {
    background: #ecf7fa;
    font-size: 13px !important;
    padding: 13px 6px 13px 6px !important;
  }

  /* /deep/.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-weight: 900 !important;
  } */

  .text-start {
    font-size: 13px !important;
    padding: 13px 6px 13px 6px !important;
  } 

  </style>