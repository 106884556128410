<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="">
      <div class="wid-100">
        <v-row dense align="center">

          <v-col cols="2" >
            <div class="pt-0">
              <span>주소</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="addr"
              class="wid-100 pt-0"
              hide-details
              outlined
              disabled
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <div class="wid-100">
              <v-btn
                small
                color="blue lighten-2"
                class="white--text"
                @click="findZipcodeBtnClicked()"
              >좌표 찾기</v-btn>
            </div>
          </v-col>
          <v-col cols="6"><span>* 주소는 좌표를 찾기 위해서만 사용되는 참고용 정보입니다.</span></v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>위도</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="lati"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>경도</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="longi"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>본문 내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor ref="body_cn" @input="itemChanged"></tiny-editor>
            <!-- <v-textarea
              v-model="body_cn"
              class="wid-100 pt-0"
              label="임시영역"
              hide-details
              outlined
              @change="itemChanged"
            ></v-textarea> -->
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';

export default {
  components: { tinyEditor },
  props: ["propParams"],
  data(){
    return{
      mberSno: "",
      confrSno: "",

      newYn: 'N',             // 조회된 값이 있으면 false
      
      zpcd: "",
      addr: "",
      lati: "",
      longi: "",
      // body_cn: "",
      naverClntId: '',
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.propParams.confrSno; 

    this.getConfrTrfcGuid();
  },
  mounted() {
    this.getSoctyApiCd();
    // const script = document.createElement("script");
    // script.src =`https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${this.naverClntId}&submodules=drawing,geocoder`;
    // script.async = true;
    // script.defer = true;
    // document.head.appendChild(script);
  },
  methods: {
    getConfrTrfcGuid() {
      const params = new FormData();

      params.append("confr_sno", this.confrSno);

			this.$http.post("/admin/getConfrTrfcGuid", params).then((res) => {
        // console.log("res.data.sult: ", res.data.result);
        const trfc = res.data.result.confrTrfcGuid;

        if(trfc == null) {
          this.newYn = 'Y';
          // alert("조회된 정보가 없습니다.");
          return;
        } else {
          this.newYn = 'N';
        }

        this.addr = trfc.addr;
        this.lati = trfc.lati;
        this.longi = trfc.longi;
        this.$refs.body_cn.content = trfc.body_cn;

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveConfrTrfcGuid");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append('new_yn', this.newYn);

        if(this.addr != null)     params.append("addr", this.addr);
        if(this.lati != null)     params.append("lati", this.lati);
        if(this.longi != null)    params.append("longi", this.longi);
        if(this.$refs.body_cn.content != null)  params.append("body_cn", this.$refs.body_cn.content);

        this.$http.post("/admin/upsertConfrTrfcGuid", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getConfrTrfcGuid();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    findZipcodeBtnClicked() {
      new window.daum.Postcode({
				oncomplete: (data) => {
          this.zpcd = data.zonecode;
          this.addr = data.roadAddress;
          this.getCoord(data.roadAddress);
          
				},
			}).open();
      this.itemChanged();
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'conferenceTrafficGuideTab');
    },
    getCoord(address) {
      const naver = window.naver;

      naver.maps.Service.geocode({
        query: address
      }, (status, response) => {
        console.log("status, response", status, response);
        if(status == naver.maps.Service.Status.OK) {
          this.lati = response.v2.addresses[0].y;
          this.longi = response.v2.addresses[0].x;
          // console.log("lati, longi", this.lati, this.longi);
        } else {
          alert("좌표 조회에 실패했습니다.");
        }
      });
    },//getCoord end
    getSoctyApiCd() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyApiCd", params).then((res) => {
        console.log("res.data: ", res.data);

        if(res.data.result) {
          this.naverClntId = res.data.result.naver_clnt_id;
          const script = document.createElement("script");
          script.src =`https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${this.naverClntId}&submodules=drawing,geocoder`;
          script.async = true;
          script.defer = true;
          document.head.appendChild(script);
        }
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
  },
}
</script>

<style>

</style>