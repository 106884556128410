<template>
    <div>
        <h1>11</h1>
        <v-btn @click="test">test</v-btn>
    </div>
</template>

<script>
export default {
  created() {
    // 로그인 기능 구현하기 전 임시로 사용
    // this.$session.set('mberSno', '1');
  },
  methods: {
    test(){
        this.$http.get("/test").then((response) => { // 실제 API를 요청한다/
            console.log(response.data);
        })
    }
  },
}
</script>