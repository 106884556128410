<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">

          <v-col cols="2">
            <div class="pt-0">
              <span>푸터 내용</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="footr_cn" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>우편번호</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="zpcd"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <div class="wid-100">
              <v-btn
                small               
                color="blue lighten-2"
                class="white--text"
                @click="findZipcodeBtnClicked()"
              >
              우편번호 찾기
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>주소</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="addr"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>상세주소</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="addr_dtl"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>영문주소 시군구</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="addr_en"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>영문주소 상세</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="addr_dtl_en"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>전화번호</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="tel_no"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>팩스번호</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="fax_no"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>이메일</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="email"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>저작권 메시지</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="cprgt_msg"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>사업자번호</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="biz_rno"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->

        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';

export default {
  components: { tinyEditor },

  data(){
    return{
      mberSno: "",

      isNew: false,

      footr_cn: "",

      // zpcd: "",
      // addr: "",
      // addr_dtl: "",
      // addr_en: "",
      // addr_dtl_en: "",
      // tel_no: "",
      // fax_no: "",
      // email: "",
      // cprgt_msg: "",
      // biz_rno: "",
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

    this.getSoctyFootr();
  },
  methods: {
    getSoctyFootr() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyFootr", params).then((res) => {
        // console.log("res.data: ", res.data);

        if(res.data.result.length == 0) {
          this.isNew = true;
          // alert("조회된 정보가 없습니다.");
          return;
        } else {
          this.isNew = false;
        }

        const socty = res.data.result[0];

        this.footr_cn = socty.footr_cn;

        // this.zpcd = socty.zpcd;
        // this.addr = socty.addr;
        // this.addr_dtl = socty.addr_dtl;
        // this.addr_en = socty.addr_en;
        // this.addr_dtl_en = socty.addr_dtl_en;
        // this.tel_no = socty.tel_no;
        // this.fax_no = socty.fax_no;
        // this.email = socty.email;
        // this.cprgt_msg = socty.cprgt_msg;
        // this.biz_rno = socty.biz_rno;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveSoctyFootr");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();
        params.append("new_yn", this.isNew ? 'Y' : 'N');
        params.append("mber_sno", this.mberSno);

        if(this.footr_cn != null)         params.append("footr_cn", this.footr_cn);
        // if(this.zpcd != null)           params.append("zpcd", this.zpcd);
        // if(this.addr != null)           params.append("addr", this.addr);
        // if(this.addr_dtl != null)       params.append("addr_dtl", this.addr_dtl);
        // if(this.addr_en != null)        params.append("addr_en", this.addr_en);
        // if(this.addr_dtl_en != null)    params.append("addr_dtl_en", this.addr_dtl_en);
        // if(this.tel_no != null)          params.append("tel_no", this.tel_no);
        // if(this.fax_no != null)          params.append("fax_no", this.fax_no);
        // if(this.email != null)          params.append("email", this.email);
        // if(this.cprgt_msg != null)      params.append("cprgt_msg", this.cprgt_msg);
        // if(this.biz_rno != null)        params.append("biz_rno", this.biz_rno);

        this.$http.post("/admin/upsertSoctyFootr", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");

          this.getSoctyFootr();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    // findZipcodeBtnClicked() {
    //   new window.daum.Postcode({
		// 		oncomplete: (data) => {
    //       this.zpcd = data.zonecode;
    //       this.addr = data.roadAddress;
		// 		},
		// 	}).open();

    //   this.itemChanged();
    // },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'footerTab');
    },
  },
}
</script>

<style>

</style>