<template>
  <v-app-bar app color="#A9E2F3" height="40px" class="elevation-0">
      <v-list-item-title class="text-h5 font-weight-bold d-flex align-center" style="height: 26px">
        <div class="minWid-25p-auto hei-25p mr-2">
          <img class="imgDf-h" :src="imgMap.admin_page_logo_path">
        </div>
        {{ soctyNm }} 관리자 페이지
      </v-list-item-title>
      <v-btn 
        fab x-small 
        class="elevation-0 ml-2" 
        @click="logoutBtnClicked"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn fab x-small class="elevation-0 ml-2">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-app-bar>
</template>

<script>
export default {
  name: "defaultBar",
  data() {
    return {
      soctyNm: '',
      imgMap: {},
    };
  },
  created() {
    this.getSoctyNm();
    this.getSoctyImg();
  },
  methods: {
    getSoctyNm() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyNm", params).then((res) => {
        this.soctyNm = res.data.result.socty_nm;
			}).catch(function (error) {
					console.log(error);
				}.bind(this)
			);
    },
    logoutBtnClicked() {
      if(confirm('로그아웃 하시겠습니까?')) {
        this.$session.set('mberSno','');
        this.$session.set('tempPwdYn','N');
        this.$session.set('nonMberYn','');
        this.$router.push('/adminLogin');
      }
    },//logoutBtnClicked end
    getSoctyImg() {
        this.$http.post("/admin/getSoctyImg").then((res) => {
          console.log("이미지스: ",res.data.result);
          if(res.data.result[0]) {
            this.imgMap = res.data.result[0];
          }
          

          this.viewYn = true;
        }).catch(function (error) {
            console.log(error);
          }
        );

      },
  },
  computed: {
    
  },
};
</script>

<style scoped>
</style>
