var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('editor',{attrs:{"api-key":_vm.apiKey,"init":{
      height: _vm.boxHeight,
      menubar: true,
      fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
      toolbar_mode: 'sliding',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen table',
        'insertdatetime media table paste code help wordcount image'
      ],
      toolbar1: 
        'fontsizeselect | fontselect | formatselect',
      toolbar2:
        'undo redo | forecolor backcolor bold italic underline subscript superscript | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | link unlink | table image | removeformat code | fullscreen',
      images_reuse_filename: true,
      images_upload_handler: _vm.sendFile,
      relative_urls: false  ,
      remove_script_host: true,
      // content_css: [ '//www.tiny.cloud/css/codepen.min.css' ],
      extended_valid_elements: '*[*]',
      valid_elements: '*[*]',
      font_formats: '굴림=굴림;굴림체=굴림체;궁서=궁서;궁서체=궁서체;돋움=돋움;돋움체=돋움체;바탕=바탕;바탕체=바탕체;나눔고딕=NanumGothic,serif;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;',
    },"disabled":_vm.readOnly},on:{"onChange":_vm.contentChanged},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }