<template>
	<v-container fluid class="pa-3">
    <v-row dense align="center">
      <v-col cols="12">
        <v-row dense class="" align="center">
          <v-col cols="12">
            <div>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                :disabled="!isOnPending"
                @click="saveNStayBtnClicked"
                >저장 후 유지
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="12">
            <v-row dense align="center" v-for="item in pendingDispCntList" :key="item.disp_odr">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>한 줄에 표시할 {{ item.disp_item_nm }} 수</span>
                </div>
              </v-col>
              <v-col cols="1">
                <v-select
                  class="wid-100 pt-0" 
                  :items="item.disp_item_nm == '회장' ? oneCntList:oneLineCntList"
                  item-value="cnt"
                  item-text="cnt"
                  v-model="item.disp_cnt"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="1" >
                <div class="pt-0">
                  <span>표시 순서</span>
                </div>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="item.disp_odr"
                  hide-details
                  dense
                  outlined
                  class="pa-0 ma-0 text-field-transparent"
                ></v-text-field>
              </v-col>
              <v-col cols="1" ></v-col>
              <v-col cols="1" >
                <div class="pt-0">
                  <span>역대 표시 여부</span>
                </div>
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  v-model="item.past_disp_tf"
                  hide-details
                  dense
                  label="역대 화면에 표시함"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="" align="center">
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addGnrtnBtnClicked"
              >역대 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteGnrtnListBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1"
              style="cursor: pointer"
              
            >
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              <template v-slot:[`item.gnrtn_nm`]="{ item }" >
                <div @click="rowClicked(item)">{{item.gnrtn_nm}}</div>
              </template>
              <!-- <template v-slot:[`item.gnrtn_nm`]="{item}">
                <v-text-field
                  v-model="item.gnrtn_nm"
                  hide-details
                  dense
                  readonly
                  solo
                  flat
                  class="pa-0 ma-0 text-field-transparent"
                  @click="rowClicked(item)"
                ></v-text-field>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      // redrawCount : 0,  // 화면 다시 그리기
      gridHeader: [
        { text: '선택', value: 'select_yn', width: "5%", sortable:false },
        { text: '역대명', value: 'gnrtn_nm', width: "25%" },
        { text: '역대 기간', value: 'gnrtn_term', width: "20%" },
        { text: '현역 여부', value: 'curr_yn', width: "7%" },
        { text: '표시 순서', value: 'odr', width: "10%" },
      ],
      gridList: [],

      dispCntList: [],
      pendingDispCntList: [],

      oneLineCntList: [{cnt:1}, {cnt:2}, {cnt:3}, {cnt:4}],
      oneCntList:[{cnt:1}],
    }
  },
  computed: {
    isOnPending() {
      console.log("isOnPending");
      return JSON.stringify(this.dispCntList) != JSON.stringify(this.pendingDispCntList)
        // || JSON.stringify(this.exctvList) != JSON.stringify(this.pendingExctvList);
    }
  },
  created() {

  },
  mounted(){
    this.getExctvGnrtnList();
  },
  methods: {
    getExctvGnrtnList() {
      const params = new FormData();

      this.$http.post("/admin/getExctvGnrtnList", params).then((res) => {
        // console.log("res.data.result:", res.data.result);

        res.data.result.dispCntList.forEach((e) => {
          e.past_disp_tf = e.past_disp_yn == 'Y' ? true : false;
        });

        // 역대 임원 한 줄 표시 항목 수 목록
        this.dispCntList = res.data.result.dispCntList;
        this.pendingDispCntList = JSON.parse(JSON.stringify(this.dispCntList));

        // 역대 목록
        res.data.result.gnrtnList.forEach((e) => {
          e.select_yn = false;
        });

        this.gridList = res.data.result.gnrtnList;
        console.log(this.dispCntList);
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    rowClicked(event) {
      // console.log("rowClicked", event);
      this.$router.push({name:"executiveGenerationDetail", query: { gnrtnSno:event.gnrtn_sno, newYn:'N' }});
    },
    // 역대 추가 버튼 클릭
    addGnrtnBtnClicked() {
      this.$router.push({name:"executiveGenerationDetail", query: { gnrtnSno:null, newYn:'Y' }});
    },
    deleteGnrtnListBtnClicked() {
      console.log("deleteGenerationList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: prod_sno[" + e.prod_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteExctvGnrtnList", params).then((res) => {
            console.log(res.data);

            if(res.data.code == 0) {
              alert("삭제되었습니다.");
              // this.getExctvGnrtnList();
              this.gridList = this.gridList.filter((e) => {
                return !(e.select_yn);
              });
            } else {
              alert("삭제할 수 없습니다.");
            }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    validateInput() {
      return true;
    },
    saveNStayBtnClicked() {
      this.saveDispCntList("stay");
      // console.log("out of save");
    },
    // saveNMoveBtnClicked() {
    //   this.saveDispCntList("move");
    //   // console.log("out of save");
    // },
    saveDispCntList(action) {
      // console.log("saveDispCntList:");

      // if(confirm("저장하시겠습니까?")) {
        const isValid = this.validateInput();

        if(isValid) {
          const params = new FormData();

          if(this.mberSno != null)  params.append("mber_sno", this.mberSno);
          // params.append('new_yn', this.newYn);

          // const filteredDispCntList = this.pendingDispCntList.filter((e) => e.disp_cnt != null);
          const filteredDispCntList = this.pendingDispCntList.map((e) => {
            e.past_disp_yn = e.past_disp_tf ? 'Y' : 'N';

            if(e.disp_cnt != null) {
              return e;
            } else {
              return null;
            }
          });
          console.log("filteredDispCntList:", filteredDispCntList);

          params.append("dispCntList", JSON.stringify(filteredDispCntList));

          this.$http.post("/admin/upsertExctvOneLineDispCntList", params).then((res) => {
            console.log(res.data);
            alert("저장되었습니다.");
            
            // if(this.newYn == 'Y') {
            //   this.gnrtnSno = res.data.result.gnrtn_sno;
            //   this.newYn = 'N';
            //   console.log("this.gnrtnSno(in save):", this.gnrtnSno);
            // }

            // console.log("in save: after alert 1");
            if(action == "stay") {
              this.getExctvGnrtnList();
            } 
            // else if(action == "move") {
            //   this.gotoList();
            // }
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        }
      // }
    },
  }
}
</script>
  
<style>

</style>