<template>
  <v-container fluid class="d-flex align-center justify-center" :key="redrawCnt">
    <v-layout class="d-flex align-center justify-center">
      <div class="">
        <v-row dense align="center">
          <!-- 학술대회 -->
          <v-col cols="12">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>학술대회 *</span>
                </div>
              </v-col>
              <v-col cols="3" >
                <v-select
                  class="wid-100 pt-1" 
                  :items="confrList"
                  item-value="confr_sno"
                  item-text="confr_nm"
                  v-model="confrSno"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 회원 -->
          <v-col cols="12">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>회원</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="mberDisp"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  append-icon="mdi-magnify"
                  @click="searchMberBtnClicked"
                  @click:append="searchMberBtnClicked"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 이메일 -->
          <v-col cols="12" v-if="regItemSet.rqstr_email.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>이메일</span><span v-if="regItemSet.rqstr_email.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pendingAbstrReg.rqstr_email"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 이름 -->
          <v-col cols="12" v-if="regItemSet.rqstr_nm.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>이름</span><span v-if="regItemSet.rqstr_nm.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pendingAbstrReg.rqstr_nm"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 소속 -->
          <v-col cols="12" v-if="regItemSet.rqstr_blng.use_yn">
            <v-row dense align="center">
             <v-col cols="2" >
                <div class="pt-0">
                  <span>소속</span><span v-if="regItemSet.rqstr_blng.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pendingAbstrReg.rqstr_blng"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 소속(영문) -->
          <!-- <v-col cols="12" v-if="regItemSet.rqstr_blng_en.use_yn">
            <v-row dense align="center">
             <v-col cols="2" >
                <div class="pt-0">
                  <span>소속(영문)</span><span v-if="regItemSet.rqstr_blng_en.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pendingAbstrReg.rqstr_blng_en"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col> -->
          <!-- 부서/학과 -->
          <v-col cols="12" v-if="regItemSet.rqstr_dept.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>부서/학과</span><span v-if="regItemSet.rqstr_dept.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pendingAbstrReg.rqstr_dept"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 직위/학년 -->
          <v-col cols="12" v-if="regItemSet.rqstr_pstn.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>직위/학년</span><span v-if="regItemSet.rqstr_pstn.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pendingAbstrReg.rqstr_pstn"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 휴대전화번호 -->
          <v-col cols="12" v-if="regItemSet.rqstr_cel_no.use_yn">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>휴대전화번호</span><span v-if="regItemSet.rqstr_cel_no.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pendingAbstrReg.rqstr_cel_no"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                  readonly
                  background-color="grey lighten-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 발표 방식 -->
          <v-col cols="12" v-if="regItemSet.pt_mthd.use_yn">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>발표 방식</span><span v-if="regItemSet.pt_mthd.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="ptMthdList"
                  item-value="pt_mthd_no"
                  item-text="pt_mthd_nm"
                  v-model="pendingAbstrReg.pt_mthd_no"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 발표 분야 -->
          <v-col cols="12" v-if="regItemSet.pt_field.use_yn">
            <v-row dense align="center">
             <v-col cols="2">
                <div class="pt-0">
                  <span>발표 분야</span><span v-if="regItemSet.pt_field.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="filteredPtFieldList"
                  item-value="pt_field_no"
                  item-text="pt_field_nm"
                  v-model="pendingAbstrReg.pt_field_no"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 발표 분야(2순위) -->
          <v-col cols="12" v-if="regItemSet.pt_field_2nd.use_yn">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>발표 분야(2순위)</span><span v-if="regItemSet.pt_field_2nd.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="filteredPtFieldList"
                  item-value="pt_field_no"
                  item-text="pt_field_nm"
                  v-model="pendingAbstrReg.pt_field_no_2nd"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 발표상 -->
          <v-col cols="12" v-if="abstrRegOptn.pt_awad_yn == 'Y'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>{{abstrRegOptn.pt_awad_nm || `발표상`}}</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="pendingAbstrReg.pt_awad_apply_yn"
                  hide-details
                  dense
                  label="발표상 응모함"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 초록 언어 -->
          <v-col cols="12" v-if="regItemSet.abstr_lang.use_yn">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>초록 언어</span><span v-if="regItemSet.abstr_lang.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="abstrLangList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="pendingAbstrReg.abstr_lang"
                  hide-details
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 초록 제목(국문) -->
          <v-col cols="12" v-if="regItemSet.abstr_ttl.use_yn">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>초록 제목(국문)</span><span v-if="regItemSet.abstr_ttl.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="8">
                <tiny-editor v-model="pendingAbstrReg.abstr_ttl" box_height="120"></tiny-editor>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 초록 제목(영문) -->
          <v-col cols="12" v-if="regItemSet.abstr_ttl_en.use_yn">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>초록 제목(영문)</span><span v-if="regItemSet.abstr_ttl_en.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="8">
                <tiny-editor v-model="pendingAbstrReg.abstr_ttl_en" box_height="120"></tiny-editor>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 초록 파일 -->
          <v-col cols="12" v-show="abstrRegOptn.abstr_sbmt_mthd == 'file'">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="">
                  <span>초록 파일</span><span v-if="abstrRegOptn.abstr_file_edtr_ess_yn == 'Y'"> *</span>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="wid-100 pt-0">
                  <v-file-input
                    hide-details
                    dense
                    v-model="abstrSelectedFile"
                  ></v-file-input>
                </div>
              </v-col>
              <!-- 파일 표시 text-field -->
              <v-col cols="3" v-if="abstrCurrentFile.orgnl_file_nm">
                <div class="wid-100 pt-0">
                  <div class="f-l pt-2 pl-5 font-1" style="color:white;">
                    <span><a :href="abstrCurrentFile.file_path" :download="abstrCurrentFile.orgnl_file_nm">{{abstrCurrentFile.orgnl_file_nm}}</a></span>
                  </div>
                  <v-btn
                    small
                    color="red lighten-2"
                    class="pa-0 ma-0 white--text f-r"
                    hide-details
                    @click="deleteFileBtnClicked('abstr', abstrCurrentFile.file_path, abstrCurrentFile.abstr_atch_sno)"
                  >삭제</v-btn>
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
              <!-- 초록 파일 끝 -->
            </v-row>
          </v-col>
          <!-- 초록 에디터 -->
          <v-col cols="12" v-show="abstrRegOptn.abstr_sbmt_mthd == 'edtr'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>초록 에디터</span><span v-if="abstrRegOptn.abstr_file_edtr_ess_yn == 'Y'"> *</span>
                </div>
              </v-col>
              <v-col cols="8">
                <tiny-editor v-model="pendingAbstrReg.abstr_edtr"></tiny-editor>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>

          <!-- 신청자 메모 -->
          <v-col cols="12" v-if="regItemSet.rqstr_memo.use_yn && newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>신청자 메모</span><span v-if="regItemSet.rqstr_memo.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="5">
                <v-textarea
                  v-model="pendingAbstrReg.rqstr_memo"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 관리자 메모 -->
          <v-col cols="12" v-if="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>관리자 메모</span>
                </div>
              </v-col>
              <v-col cols="5">
                <v-textarea
                  v-model="pendingAbstrReg.admin_memo"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 초록 수정 가능 -->
          <!-- <v-col cols="12" v-if="regItemSet.abstr_upd_psbl.use_yn && newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>초록 수정</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="pendingAbstrReg.abstr_upd_psbl_yn"
                  hide-details
                  dense
                  label="초록 수정 가능"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col> -->
          <!-- 초록 활용 동의 -->
          <v-col cols="12" v-if="regItemSet.abstr_use_agree.use_yn && newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>초록 활용 동의</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="pendingAbstrReg.abstr_use_agree_yn"
                  hide-details
                  dense
                  label="초록 활용 동의함"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 발표 번호 -->
          <v-col cols="12" v-if="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <div class="pt-0">
                  <span>발표 번호</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="pendingAbstrReg.pt_no"
                  class="wid-100 pt-0"
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 발표 일자 -->
          <v-col cols="12" v-show="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>발표 일자</span>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="wid-100">
                  <v-text-field
                    class="bg-white"
                    v-model="pendingAbstrReg.pt_dt"
                    hide-details
                    dense
                    outlined
                    append-icon="mdi-calendar"
                    @click:append="popupDatePicker('pt_dt')"
                    placeholder="0000-00-00"
                  ></v-text-field>
                  <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                  <!-- <datePicker 
                    v-model="pendingAbstrReg.pt_dt"
                  ></datePicker> -->
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 발표 시간 -->
          <v-col cols="12" v-show="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>발표 시간</span>
                </div>
              </v-col>
              <v-col cols="6">
                <v-row dense align="center">
                  <v-col cols="3">
                    <div class="wid-100">
                      <v-text-field
                        v-model="pendingAbstrReg.pt_bgn_tm"
                        hide-details
                        class="pt-1"
                        dense
                        outlined
                        placeholder="00:00"
                      ></v-text-field>
                      <!-- <timePicker 
                        v-model="pendingAbstrReg.pt_bgn_tm" 
                      ></timePicker> -->
                    </div>
                  </v-col>
                  <v-col cols="1" class="cols-1-wid">
                    <div class="pt-0 f-r pr-3">
                      <span>~</span>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <div class="wid-100">
                      <v-text-field
                        v-model="pendingAbstrReg.pt_end_tm"
                        hide-details
                        class="pt-1"
                        dense
                        outlined
                        placeholder="00:00"
                      ></v-text-field>
                      <!-- <timePicker 
                        v-model="pendingAbstrReg.pt_end_tm" 
                      ></timePicker> -->
                    </div>
                  </v-col>
                </v-row>
              </v-col>                 
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>

        </v-row>  
      </div>
    </v-layout>
    <member-search-popup v-if="mberSrchPopup" @close="closeMberSrchPopup" @selected="setRqstMberSno"></member-search-popup>
  </v-container>
      
  
</template>

<script>
import memberSearchPopup from '@/components/common/memberSearchPopup.vue';
// import datePicker from "../../../common/datePicker.vue";
// import timePicker from "../../../common/timePicker.vue";
import tinyEditor from '@/components/common/tinyEditor.vue';
import datePickerPopup from '@/components/common/datePickerPopup.vue';

const genEmptyAbstrReg = () => ({
  confr_sno: "",
  rqst_mber_sno: null,
  rqstr_nm: "",
  rqstr_email: "",
  rqstr_blng: "",
  // rqstr_blng_en: "",
  rqstr_dept: "",
  rqstr_pstn: "",
  rqstr_cel_no: "",
  pt_mthd_no: "",
  pt_field_no: "",
  pt_field_no_2nd: "",
  pt_no: null,
  pt_dt: "",
  pt_bgn_tm: "",
  pt_end_tm: "",
  abstr_lang: "",
  abstr_ttl: "",
  abstr_ttl_en: "",
  abstr_edtr: "",
  abstr_atch_sno: null,
  abstr_upd_psbl_yn: false,
  abstr_use_agree_yn: false,
  pt_awad_apply_yn: false,
  prvcy_polcy_agree_yn: false,
  rqstr_memo: "",
  admin_memo: "",
});

export default {
  components: {memberSearchPopup, datePickerPopup, tinyEditor},
  props: ["propParams"],
  data(){
    return{
      regItemSet: {
        confr_sno: {use_yn:true, ess_yn:false},
        rqst_mber_sno: {use_yn:true, ess_yn:false},
        rqstr_email: {use_yn:true, ess_yn:false},
        rqstr_nm: {use_yn:true, ess_yn:false},
        rqstr_blng: {use_yn:true, ess_yn:false},
        // rqstr_blng_en: {use_yn:true, ess_yn:false},
        rqstr_dept: {use_yn:true, ess_yn:false},
        rqstr_pstn: {use_yn:true, ess_yn:false},
        rqstr_cel_no: {use_yn:true, ess_yn:false},
        pt_mthd: {use_yn:true, ess_yn:false},
        pt_field: {use_yn:true, ess_yn:false},
        pt_field_2nd: {use_yn:true, ess_yn:false},
        pt_awad_apply: {use_yn:true, ess_yn:false},
        abstr_lang: {use_yn:true, ess_yn:false},
        abstr_ttl: {use_yn:true, ess_yn:false},
        abstr_ttl_en: {use_yn:true, ess_yn:false},
        abstr_atch_sno: {use_yn:true, ess_yn:false},
        abstr_edtr: {use_yn:true, ess_yn:false},
        rqstr_memo: {use_yn:true, ess_yn:false},
        admin_memo: {use_yn:true, ess_yn:false},
        abstr_upd_psbl: {use_yn:true, ess_yn:false},
        abstr_use_agree: {use_yn:true, ess_yn:false},
        pt_no: {use_yn:true, ess_yn:false},
        pt_dt: {use_yn:true, ess_yn:false},
        pt_bgn_tm: {use_yn:true, ess_yn:false},
        pt_end_tm: {use_yn:true, ess_yn:false},
      },

      mberSno: null,
      confrSno: null,
      abstrRegSno: null,
      newYn: 'N',

      asbtrReg: {},
      pendingAbstrReg: {},

      abstrCurrentFile: {orgnl_file_nm:null, abstr_atch_sno:null, file_path:null},
      abstrSelectedFile: null,

      confrList: [],
      ptMthdList: [],
      ptFieldList: [],
      abstrLangList: [],
      abstrRegOptn: {},

      mberSrchPopup: false,
      mberDisp: "",

      redrawCnt: 0,
      datePopup: false,
      curDate: "",
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.$route.query.confrSno ? Number(this.$route.query.confrSno) : '';
    this.abstrRegSno = this.$route.query.abstrRegSno ? this.$route.query.abstrRegSno : '';
    this.newYn = this.$route.query.newYn ? this.$route.query.newYn : 'N'; 

    console.log("this.newYn:", this.newYn);
    console.log("this.confrSno:", this.confrSno);

    this.getConfrList();
    // this.getPtMthdList();
    // this.getPtFieldList();
    this.getAbstrLangList();
  
    if(this.confrSno != null) {
      this.getConfrAbstrRegOptn();  // 초록등록 시 옵션 처리를 위해 조회
      // this.getRegItemList();
    }

    if(this.newYn == 'N') {
      this.getConfrAbstrReg();
    } else {
      genEmptyAbstrReg();
    }

  },
  computed: {
    isOnPending() {
      return JSON.stringify(this.abstrReg) !== JSON.stringify(this.pendingAbstrReg) || this.abstrSelectedFile
    },
    filteredPtFieldList() {
      console.log("filteredPtFieldList : ", this.pendingAbstrReg.pt_mthd_no);
      return this.ptFieldList.filter(e => e.pt_mthd_no == this.pendingAbstrReg.pt_mthd_no);
    }
  },
  watch: {
    isOnPending: {
      immediate: true,
      handler: function () {
        // console.log("watch isOnPending", this.isOnPending);
        this.$emit('tabChanged', 'abstrRegistInfoTab', this.isOnPending);
      },
    },
    confrSno: {
      immediate: true,
      handler: function () {
        // console.log("watch confrSno", this.confrSno);
        if(this.confrSno == null || this.confrSno == undefined) {
          return;
        }
        this.getConfrAbstrRegOptn();  // 초록등록 시 옵션 처리를 위해 조회
        // this.getRegItemList();
        // this.itemChanged();
        this.pendingAbstrReg.confr_sno = this.confrSno;
      },
    },
  },
  methods: {
    getConfrList() {
      this.$http.get("/admin/getConfrList").then((res) => {
        //console.log("res.data.result:", res.data.result);
        this.confrList = res.data.result;
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },
    async getAbstrLangList() {
      this.abstrLangList = await this.$getCmnCdList('abstr_lang');
    },
    getConfrAbstrRegOptn() {
      const params = new FormData();

      params.append("confr_sno", this.confrSno);
      params.append("reg_div", 'abstr');

			this.$http.post("/admin/getConfrAbstrRegOptn", params).then((res) => {
        console.log("getConfrAbstrRegOptn res.data: ", res.data);

        let regItemSet = {};
        res.data.result.regItemList.forEach((e) => {
          // console.log(e);
          regItemSet[e.reg_item_id] = {use_yn:e.use_yn, ess_yn:e.ess_yn};
        });
        console.log("regItemSet:", regItemSet);
        this.regItemSet = regItemSet;

        this.ptMthdList = res.data.result.ptMthdList;
        this.ptFieldList = res.data.result.ptFieldList;
        // this.ptMthdList = res.data.result.ptMthdList.filter((e) => e.use_yn);
        // this.ptFieldList = res.data.result.ptFieldList.filter((e) => e.use_yn);

        if(res.data.result.regOptn == null) {
          // console.log("getConfrPreRegOptn res.data.result is null");
          // this.newYn = 'Y';
          return;
        }

        this.abstrRegOptn = res.data.result.regOptn; 

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    // getRegItemList() {
    //   const params = new FormData();
    //   params.append("confr_sno", this.confrSno);
    //   params.append("reg_div", 'abstr');

		// 	this.$http.post("/admin/getConfrRegItemList", params).then((res) => {
    //     // console.log("res.data: ", res.data);

    //     let regItemSet = {};
    //     res.data.result.regItemList.forEach((e) => {
    //       // console.log(e);
    //       regItemSet[e.reg_item_id] = {use_yn:e.use_yn, ess_yn:e.ess_yn};
    //     });
    //     console.log("regItemSet:", regItemSet);
    //     this.regItemSet = regItemSet;
		// 	}).catch(function (error) {
		// 			console.log(error);
		// 		}
		// 	);
    // },
    getConfrAbstrReg() {
      const params = new FormData();
      params.append("abstr_reg_sno", this.abstrRegSno);
      // params.append("confr_sno", this.confrSno);
      // params.append("reg_div", 'abstr');

			this.$http.post("/admin/getConfrAbstrReg", params).then((res) => {
        //console.log("res.data:::::: ", res.data);

        const abstrReg = res.data.result.abstrRegInfo;

        if(abstrReg.rqst_mber_sno != null) {
          this.mberDisp = abstrReg.rqst_mber_nm + "(" + abstrReg.rqst_mber_sno + ")"
        } 

        abstrReg.abstr_upd_psbl_yn = abstrReg.abstr_upd_psbl_yn == 'Y' ? true : false;
        abstrReg.abstr_use_agree_yn = abstrReg.abstr_use_agree_yn == 'Y' ? true : false;
        abstrReg.pt_awad_apply_yn = abstrReg.pt_awad_apply_yn == 'Y' ? true : false;
        abstrReg.prvcy_polcy_agree_yn = abstrReg.prvcy_polcy_agree_yn == 'Y' ? true : false;

        this.abstrReg = JSON.parse(JSON.stringify(abstrReg, function(k, v) {return v == null ? "" : v}));
        this.pendingAbstrReg = JSON.parse(JSON.stringify(this.abstrReg));

        // this.rqst_mber_sno = abstrReg.rqst_mber_sno;
        // this.rqstr_nm = abstrReg.rqstr_nm;
        // this.rqstr_email = abstrReg.rqstr_email;
        // this.rqstr_blng = abstrReg.rqstr_blng;
        // this.rqstr_blng_en = abstrReg.rqstr_blng_en;
        // this.rqstr_dept = abstrReg.rqstr_dept;
        // this.rqstr_pstn = abstrReg.rqstr_pstn;
        // this.rqstr_cel_no = abstrReg.rqstr_cel_no;
        // this.pt_mthd = abstrReg.pt_mthd;
        // this.pt_field = abstrReg.pt_field;
        // this.pt_field_2nd = abstrReg.pt_field_2nd;
        // this.pt_no = abstrReg.pt_no;       

        // this.pt_dt = abstrReg.pt_dt;
        // this.pt_bgn_tm = abstrReg.pt_bgn_tm;
        // this.pt_end_tm = abstrReg.pt_end_tm;
        // // this.$refs.pt_dt.date = abstrReg.pt_dt;
        // // this.$refs.pt_bgn_tm.time = abstrReg.pt_bgn_tm;
        // // this.$refs.pt_end_tm.time = abstrReg.pt_end_tm;

        // this.abstr_lang = abstrReg.abstr_lang;
        // this.abstr_ttl = abstrReg.abstr_ttl;
        // this.abstr_ttl_en = abstrReg.abstr_ttl_en;
        // // this.$refs.abstr_ttl_cn.content = abstrReg.abstr_ttl;
        // // this.$refs.abstr_ttl_en_cn.content = abstrReg.abstr_ttl_en;

        this.abstrCurrentFile.orgnl_file_nm = abstrReg.abstr_orgnl_file_nm;
        this.abstrCurrentFile.abstr_atch_sno = abstrReg.abstr_atch_sno;
        this.abstrCurrentFile.file_path = abstrReg.abstr_path;

        // this.abstr_edtr = abstrReg.abstr_edtr;
        // // this.$refs.abstr_edtr_cn.content = abstrReg.abstr_edtr;

        // this.abstr_upd_psbl_yn = abstrReg.abstr_upd_psbl_yn == 'Y' ? true : false;
        // this.abstr_use_agree_yn = abstrReg.abstr_use_agree_yn == 'Y' ? true : false;
        // this.pt_awad_apply_yn = abstrReg.pt_awad_apply_yn == 'Y' ? true : false;
        // this.prvcy_polcy_agree_yn = abstrReg.prvcy_polcy_agree_yn == 'Y' ? true : false;
        // this.rqstr_memo = abstrReg.rqstr_memo;
        // this.admin_memo = abstrReg.admin_memo;

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {
      // 학술대회
      if(this.confrSno == null || this.confrSno == "") {
        alert("학술대회는 필수 입력값입니다.");
        return false;
      }
      // 이메일
      if((this.regItemSet.rqstr_email.ess_yn) && (this.pendingAbstrReg.rqstr_email == null || this.pendingAbstrReg.rqstr_email == "")) {
        alert('이메일은 필수 입력값입니다.');
        return false;
      }
      // 이메일
      if((this.regItemSet.rqstr_email.ess_yn) && !this.$validateEmail(this.pendingAbstrReg.rqstr_email)) {
        alert('이메일 형식이 아닙니다.');
        return false;
      }
      // 이름
      if((this.regItemSet.rqstr_nm.ess_yn) && (this.pendingAbstrReg.rqstr_nm == null || this.pendingAbstrReg.rqstr_nm == "")) {
        alert('이름은 필수 입력값입니다.');
        return false;
      }
      // 소속
      if((this.regItemSet.rqstr_blng.ess_yn) && (this.pendingAbstrReg.rqstr_blng == null || this.pendingAbstrReg.rqstr_blng == "")) {
        alert('소속은 필수 입력값입니다.');
        return false;
      }
      // 부서/학과
      if((this.regItemSet.rqstr_dept.ess_yn) && (this.pendingAbstrReg.rqstr_dept == null || this.pendingAbstrReg.rqstr_dept == "")) {
        alert('부서/학과는 필수 입력값입니다.');
        return false;
      }
      // 휴대전화번호
      if((this.regItemSet.rqstr_cel_no.ess_yn) && (this.pendingAbstrReg.rqstr_cel_no == null || this.pendingAbstrReg.rqstr_cel_no == "")) {
        alert('휴대전화번호는 필수 입력값입니다.');
        return false;
      }
      // 발표 방식
      if((this.regItemSet.pt_mthd.ess_yn) && (this.pendingAbstrReg.pt_mthd_no == null || this.pendingAbstrReg.pt_mthd_no == "")) {
        alert('발표 방식은 필수 입력값입니다.');
        return false;
      }
      // 발표 분야
      if((this.regItemSet.pt_field.ess_yn) && (this.pendingAbstrReg.pt_field_no == null || this.pendingAbstrReg.pt_field_no == "")) {
        alert('발표 분야는 필수 입력값입니다.');
        return false;
      }
      // 발표 분야(2순위)
      if((this.regItemSet.pt_field_2nd.ess_yn) && (this.pendingAbstrReg.pt_field_no_2nd == null || this.pendingAbstrReg.pt_field_no_2nd == "")) {
        alert('발표 분야(2순위)는 필수 입력값입니다.');
        return false;
      }
      // 초록 언어
      if((this.regItemSet.abstr_lang.ess_yn) && (this.pendingAbstrReg.abstr_lang == null || this.pendingAbstrReg.abstr_lang == "")) {
        alert('초록 언어는 필수 입력값입니다.');
        return false;
      }
      if(this.abstrRegOptn.abstr_file_edtr_ess_yn == 'Y') {
        // 초록 파일
        if(this.abstrRegOptn.abstr_sbmt_mthd == 'file' && ((!this.abstrSelectedFile || this.abstrSelectedFile == undefined) && (this.abstrCurrentFile.abstr_atch_sno == null || this.abstrCurrentFile.abstr_atch_sno == ""))) {
          alert('초록 파일은 필수 입력값입니다.');
          return false;
        }        
        // 초록 에디터
        if(this.abstrRegOptn.abstr_sbmt_mthd == 'edtr' && !this.pendingAbstrReg.abstr_edtr) {
          alert('초록 에디터는 필수 입력값입니다.');
          return false;
        }     
      }
      // 초록 활용 동의
      // if((this.regItemSet.abstr_use_agree.ess_yn) && (this.pendingAbstrReg.abstr_use_agree_yn == null)) {
      //   alert('초록 활용 동의는 필수값입니다.');
      //   return false;
      // }
      // 저작권 규정 동의
      // 개인정보 처리방침 동의

      return true;
    },
    saveBtnClicked() {
      // console.log("saveConfrAbstrReg");
      if(!this.validateInput()) {
        return;
      }

      this.pendingAbstrReg.pt_no = this.pendingAbstrReg.pt_no == '' ? null : this.pendingAbstrReg.pt_no;

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append("abstr_reg_sno", this.abstrRegSno);
        params.append('new_yn', this.newYn);

        if(this.pendingAbstrReg.rqst_mber_sno != null)        params.append("rqst_mber_sno", this.pendingAbstrReg.rqst_mber_sno);
        if(this.pendingAbstrReg.rqstr_nm != null)             params.append("rqstr_nm", this.pendingAbstrReg.rqstr_nm);
        if(this.pendingAbstrReg.rqstr_email != null)          params.append("rqstr_email", this.pendingAbstrReg.rqstr_email);        
        if(this.pendingAbstrReg.rqstr_blng != null)           params.append("rqstr_blng", this.pendingAbstrReg.rqstr_blng);
        // if(this.pendingAbstrReg.rqstr_blng_en != null)        params.append("rqstr_blng_en", this.pendingAbstrReg.rqstr_blng_en);
        if(this.pendingAbstrReg.rqstr_dept != null)           params.append("rqstr_dept", this.pendingAbstrReg.rqstr_dept);
        if(this.pendingAbstrReg.rqstr_pstn != null)           params.append("rqstr_pstn", this.pendingAbstrReg.rqstr_pstn);
        if(this.pendingAbstrReg.rqstr_cel_no != null)         params.append("rqstr_cel_no", this.pendingAbstrReg.rqstr_cel_no);

        if(this.pendingAbstrReg.pt_mthd_no != null)           params.append("pt_mthd_no", this.pendingAbstrReg.pt_mthd_no);
        if(this.pendingAbstrReg.pt_field_no != null)          params.append("pt_field_no", this.pendingAbstrReg.pt_field_no);
        if(this.pendingAbstrReg.pt_field_no_2nd != null)      params.append("pt_field_no_2nd", this.pendingAbstrReg.pt_field_no_2nd);
        if(this.pendingAbstrReg.pt_no != null)                params.append("pt_no", this.pendingAbstrReg.pt_no);

        if(this.pendingAbstrReg.pt_dt != null)                params.append("pt_dt", this.pendingAbstrReg.pt_dt);
        if(this.pendingAbstrReg.pt_bgn_tm != null)            params.append("pt_bgn_tm", this.pendingAbstrReg.pt_bgn_tm);
        if(this.pendingAbstrReg.pt_end_tm != null)            params.append("pt_end_tm", this.pendingAbstrReg.pt_end_tm);        
        // if(this.$refs.pt_dt.date != null)     params.append("pt_dt", this.$refs.pt_dt.date);
        // if(this.$refs.pt_bgn_tm.time != null) params.append("pt_bgn_tm", this.$refs.pt_bgn_tm.time);
        // if(this.$refs.pt_end_tm.time != null) params.append("pt_end_tm", this.$refs.pt_end_tm.time);        

        if(this.pendingAbstrReg.abstr_lang != null)           params.append("abstr_lang", this.pendingAbstrReg.abstr_lang);
        if(this.pendingAbstrReg.abstr_ttl != null)            params.append("abstr_ttl", this.pendingAbstrReg.abstr_ttl);
        if(this.pendingAbstrReg.abstr_ttl_en != null)         params.append("abstr_ttl_en", this.pendingAbstrReg.abstr_ttl_en);
        // if(this.$refs.abstr_ttl_cn.content != null)     params.append("abstr_ttl", this.$refs.abstr_ttl_cn.content);
        // if(this.$refs.abstr_ttl_en_cn.content != null)  params.append("abstr_ttl_en", this.$refs.abstr_ttl_en_cn.content);
        if(this.pendingAbstrReg.pt_awad_apply_yn != null)     params.append("pt_awad_apply_yn", this.pendingAbstrReg.pt_awad_apply_yn ? 'Y' : 'N');

        if(this.pendingAbstrReg.abstr_edtr != null)           params.append("abstr_edtr", this.pendingAbstrReg.abstr_edtr);
        // if(this.$refs.abstr_edtr_cn.content != null)    params.append("abstr_edtr", this.$refs.abstr_edtr_cn.content);

        if(this.newYn == 'N') {
          if(this.pendingAbstrReg.abstr_upd_psbl_yn != null)    params.append("abstr_upd_psbl_yn", this.pendingAbstrReg.abstr_upd_psbl_yn ? 'Y' : 'N');
          if(this.pendingAbstrReg.abstr_use_agree_yn != null)   params.append("abstr_use_agree_yn", this.pendingAbstrReg.abstr_use_agree_yn ? 'Y' : 'N');
          if(this.pendingAbstrReg.prvcy_polcy_agree_yn != null) params.append("prvcy_polcy_agree_yn", this.pendingAbstrReg.prvcy_polcy_agree_yn ? 'Y' : 'N');
          if(this.pendingAbstrReg.rqstr_memo != null)           params.append("rqstr_memo", this.rqstr_memo == undefined ? '' : this.rqstr_memo );
          if(this.pendingAbstrReg.admin_memo != null)           params.append("admin_memo", this.admin_memo == undefined ? '' : this.admin_memo);
        }

        // 첨부 파일
        const file_info_list = [];

        if(this.abstrSelectedFile != null) {
          params.append('file_list', this.abstrSelectedFile);
          file_info_list.push({item:"abstr"});
        } 

        params.append("file_info_list", JSON.stringify(file_info_list));

        console.log("pt_bgn_tm:", this.pendingAbstrReg.pt_bgn_tm);
        console.log("upsertConfrAbstrReg params:", params);

        this.$http.post("/admin/upsertConfrAbstrReg", params).then((res) => {
          // console.log(res.data);
          // alert("저장되었습니다.");
          this.abstrSelectedFile = null;

          if(this.newYn == 'Y') {
            this.abstrRegSno = res.data.abstr_reg_sno;
            this.$emit('abstrRegCreated', this.abstrRegSno);
          }

          this.getConfrAbstrReg();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    deleteFileBtnClicked(fileItem, filePath, fileAtchSno) {
      if(confirm("삭제하시겠습니까?")) {
        // console.log("filePath:", filePath);
        // console.log("atchSno:", fileAtchSno);
        
        if(filePath == null) {
          alert("삭제할 파일이 없습니다.");
          return;
        }

        const params = new FormData();

        params.append("abstr_reg_sno", this.abstrRegSno);
        params.append("confr_sno", this.confrSno);
        params.append("mber_sno", this.mberSno);
        params.append("file_item", fileItem);
        params.append("file_path", filePath);
        params.append("abstr_atch_sno", fileAtchSno);

        this.$http.post("/admin/deleteConfrAbstrRegFile", params).then((res) => {
          console.log(res.data);
          alert("삭제되었습니다.");
          
          this.abstrCurrentFile.orgnl_file_nm = "";
          this.abstrCurrentFile.abstr_atch_sno = "";
          this.abstrCurrentFile.file_path = "";
          this.redrawCnt++;
          // this.getConfrAbstrReg();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    searchMberBtnClicked() {
      this.mberSrchPopup = true;
    },
    closeMberSrchPopup() {
      this.mberSrchPopup = false;
    },
    setRqstMberSno(mberSno, mberNm) {
      // this.itemChanged();

      this.pendingAbstrReg.rqst_mber_sno = mberSno;
      this.mberDisp = mberNm + "(" + mberSno + ")";
      this.mberSrchPopup = false;

      this.getConfrRegMberInfo(mberSno);
    },
    getConfrRegMberInfo(rqstMberSno) {
      const params = new FormData();

      params.append("mber_sno", rqstMberSno);

      this.$http.post("/admin/getConfrRegMberInfo", params).then((res) => {
        const confrRegMber = res.data.result.confrRegMber;
        console.log("getConfrRegMberInfo : ", confrRegMber);
        
        this.pendingAbstrReg.rqstr_email = confrRegMber.email;
        this.pendingAbstrReg.rqstr_nm = confrRegMber.mber_nm;
        this.pendingAbstrReg.rqstr_blng = confrRegMber.blng_nm;
        this.pendingAbstrReg.rqstr_dept = confrRegMber.dept_nm;
        this.pendingAbstrReg.rqstr_pstn = confrRegMber.pstn_nm;
        this.pendingAbstrReg.rqstr_cel_no = confrRegMber.cel_no;

        this.redrawCnt++;
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },
    confrChanged() {
      // this.getRegItemList();
      // this.itemChanged();
    },
    itemChanged() {
      // console.log("itemChanged", this.isOnPending);
      // this.$emit('tabChanged', 'abstrRegistInfoTab', this.isOnPending);
    },
    setSelectedDate(selected) {
      
      if(this.dateNm == "pt_dt") {
        this.pendingAbstrReg.pt_dt = selected;
      }
      
      this.datePopup = false;
    },//setSelectedDate end
    popupDatePicker(dateNm) {
      this.dateNm = dateNm;
      this.datePopup = true;
    },//popupDatePicker end
  },
}
</script>

<style>
 .outline-wh .v-text-field--outlined fieldset  {
    color: red !important;
}
</style>