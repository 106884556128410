<template>
  <div>
    <!-- <h1>한국어</h1> -->
    <!-- 개발기 = > mgaxdc98md9ercxu1rrsljys6m0uadnwreomn2avmn7precu -->
    <editor
      v-model="content"
      @onChange="contentChanged"
      :api-key="apiKey"
      :init="{
        height: boxHeight,
        menubar: true,
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        toolbar_mode: 'sliding',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen table',
          'insertdatetime media table paste code help wordcount image'
        ],
        toolbar1: 
          'fontsizeselect | fontselect | formatselect',
        toolbar2:
          'undo redo | forecolor backcolor bold italic underline subscript superscript | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | link unlink | table image | removeformat code | fullscreen',
        images_reuse_filename: true,
        images_upload_handler: sendFile,
        relative_urls: false  ,
        remove_script_host: true,
        // content_css: [ '//www.tiny.cloud/css/codepen.min.css' ],
        extended_valid_elements: '*[*]',
        valid_elements: '*[*]',
        font_formats: '굴림=굴림;굴림체=굴림체;궁서=궁서;궁서체=궁서체;돋움=돋움;돋움체=돋움체;바탕=바탕;바탕체=바탕체;나눔고딕=NanumGothic,serif;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;',
      }"
      :disabled="readOnly"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'tinyEditor',
  props: ['value', 'box_height', 'show_menu', 'readonly'],
  components: {
    'editor': Editor
  },
  data() {
    return {
      content: this.value,
      boxHeight: this.box_height == null ? 350 : this.box_height,
      showMenu: this.show_menu == null ? false : this.show_menu == 'Y' ? true : false,
      readOnly: this.readonly == null ? false : this.readonly,
      apiKey: this.$store.state.tinyApiKey,
    }
  },
  created() {

    console.log("readonly : ", this.readonly, this.readOnly);
    
  },
  watch: {
    value(newValue) {
      this.content = newValue;
    },
    readonly(newValue) {
      this.readOnly = newValue;
    }
  },
  methods: {
    contentChanged() {
      // console.log("editor content changed:", this.content);
      // this.$emit('changed');
      this.$emit('input', this.content);
    },
    sendFile(blobInfo, success) {
      console.log(blobInfo, success);

      const formData = new FormData();
      formData.append('fileData', blobInfo.blob(), blobInfo.filename());
      this.$http.post("/common/uploadTinyImg", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if(res.data.code == 0) {
          const img_path = res.data.img_path;
          success(img_path);
        } else {
          alert("실패");
        }
      }).catch(function(error) {
          console.log(error);
      });//axios End
    },
  }
}
</script>
