<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense>
          <v-col cols="12">
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div >
              <v-data-table
                :headers="headers"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination> 
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>                
                <template v-slot:[`item.jrnl_nm`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.jrnl_nm"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.jrnl_nm_en`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.jrnl_nm_en"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.jrnl_nm_abbr`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.jrnl_nm_abbr"
                    hide-details
                    outlined
                    dense
                    @change="gridItemChanged(item)"
                  ></v-text-field>
							  </template>
                
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data(){
    return {
      headers:[
        { text: '선택', value:'select_yn', width:"10%"},
        { text: '학술지명(한글)', value:'jrnl_nm', width:"30%"},
        { text: '학술지명(영문)', value:'jrnl_nm_en', width:"40%"},
        { text: '학술지명 약어', value:'jrnl_nm_abbr', width:"20%"},
      ],
      gridList: [],
      changedList: [],    // 추가, 수정된 목록
      mberSno: "", 
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

    this.getSoctyJrnlList();
  },
  methods: {
    // 학술지 수정됨
    gridItemChanged(item) {
      this.itemChanged();

      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.jrnl_sno == null || item.jrnl_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.jrnl_sno == item.jrnl_sno) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }
    },
    getSoctyJrnlList() {
			const params = new FormData();

			this.$http.post("/admin/getSoctyJrnlList", params).then((res) => {
        // console.log("res.data: " + res.data);
        res.data.result.jrnlList.forEach((e) => {
          e.select_yn = false;
          e.new_yn = false;
        });

        this.gridList = JSON.parse(JSON.stringify(res.data.result.jrnlList));
        this.changedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    deleteBtnClicked() {
      console.log("deleteSoctyJrnl");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // console.log("deleteList : ", isSelected, deleteList);

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteSoctyJrnlList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getSoctyMberKindList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getSoctyMberKindList();
          this.gridList = this.gridList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;

      this.gridList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.gridList.push({new_yn:true, select_yn:false, jrnl_sno:null, odr:maxOdr, jrnl_nm:null, jrnl_nm_en:null, jrnl_nm_abbr:null, });
      this.changedList.push(this.gridList[this.gridList.length - 1]);
    },
    saveBtnClicked() {
      console.log("saveSoctyJrnl");

      if(this.changedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      const changedList = JSON.parse(JSON.stringify(this.changedList));
      // true/false -> 'Y'/'N' 변환
      changedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
      });

      // if(confirm("저장하시겠습니까?")) {
        let params = new URLSearchParams();

        params.append("mber_sno", this.mberSno);
        params.append("changedList", JSON.stringify(changedList));

        this.$http.post("/admin/upsertSoctyJrnlList", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getSoctyJrnlList();
        }).catch(function (error) {
            console.log(error);
        });
      // }
    },
    itemChanged() {
      this.$emit('tabChanged', "journalTab");
    }
  }
}
</script>

<style>

</style>