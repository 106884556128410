<template>
  <v-container fluid class="pa-3 mb-15">
    <v-row>
      <v-col cols="12">
        <v-row class="" align="center">
          <v-col cols="12">
            <div>
              <v-btn 
                small 
                color="blue lighten-2" 
                class="white--text ml-3 f-r"
                @click="saveMainPageClicked"
                :disabled="!isOnPending"
                >저장 후 유지
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <v-row dense align="center">
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 메인 테마 -->
      <v-col cols="2" >
        <div class="pt-0">
        <span>메인 테마</span>
        </div>
      </v-col>
      <v-col cols="2" >
        <v-select
          class="f-l pa-0 ma-0 wid-100 input-hei-d" 
          :items="mainPageTypeList"
          item-value="main_page_type"
          item-text="main_page_type_nm"
          v-model="mainPageType"
          background-color="white"
          hide-details
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col cols="1"><v-btn small @click="pointColorGuideClicked()">포인트 색상 가이드</v-btn></v-col>
      <v-col cols="3">* 현재 A타입만 지원합니다.</v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 헤더유형 -->
      <!-- <v-col cols="2" >
        <div class="pt-0">
        <span>헤더 유형</span>
        </div>
      </v-col>
      <v-col cols="7" >
        <v-select
          class="f-l pa-0 ma-0 wid-30 input-hei-d" 
          :items="hdrTypeList"
          item-value="hdr_type"
          item-text="hdr_type_nm"
          v-model="hdrType"
          background-color="white"
          hide-details
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col cols="3"></v-col>
      <v-col cols="12"><v-divider></v-divider></v-col> -->
      <!-- 포인트 색상 -->
      <!-- 포인트 색상1 -->
      <v-col cols="12">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상1</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(1)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="1">
            <div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_1" ></div>
          </v-col>
          <v-col cols="2" class="">
            <v-row dense align="center" class="ml-1">
              <v-col cols="4">
                <span>폰트 색상1</span>
              </v-col>
              <v-col cols="4">
                <v-btn small @click="fontColorPickerClicked(3)">색상 선택</v-btn>
              </v-col>
              <v-col cols="4">
                <div 
                  class="ml-2"
                  :style="`width:70px; border:1px solid black; height:30px; background-color:` + mainPointColor.font_color_3">
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- 포인트 색상2 -->
      <v-col cols="12">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상2</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(2)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="1">
            <div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_2" v-if="mainPointColor"></div>
          </v-col>
          <v-col cols="2" class="">
            <v-row dense align="center" class="ml-1">
              <v-col cols="4">
                <span>폰트 색상2</span>
              </v-col>
              <v-col cols="4">
                <v-btn small @click="fontColorPickerClicked(4)">색상 선택</v-btn>
              </v-col>
              <v-col cols="4">
                <div 
                  class="ml-2"
                  :style="`width:70px; border:1px solid black; height:30px; background-color:` + mainPointColor.font_color_4">
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- 포인트 색상3 -->
      <v-col cols="12">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상3</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(3)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="1">
            <div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_3" v-if="mainPointColor"></div>
          </v-col>
          <v-col cols="2" class="">
            <v-row dense align="center" class="ml-1">
              <v-col cols="4">
                <span>폰트 색상3</span>
              </v-col>
              <v-col cols="4">
                <v-btn small @click="fontColorPickerClicked(5)">색상 선택</v-btn>
              </v-col>
              <v-col cols="4">
                <div 
                  class="ml-2"
                  :style="`width:70px; border:1px solid black; height:30px; background-color:` + mainPointColor.font_color_5">
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- 포인트 색상4 -->
      <v-col cols="12">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상4</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(4)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="1">
            <div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_4" v-if="mainPointColor"></div>
          </v-col>
          <v-col cols="2" class="">
            <v-row dense align="center" class="ml-1">
              <v-col cols="4">
                <span>폰트 색상4</span>
              </v-col>
              <v-col cols="4">
                <v-btn small @click="fontColorPickerClicked(6)">색상 선택</v-btn>
              </v-col>
              <v-col cols="4">
                <div 
                  class="ml-2"
                  :style="`width:70px; border:1px solid black; height:30px; background-color:` + mainPointColor.font_color_6">
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- 포인트 색상5 -->
      <v-col cols="12" v-show="mainPointColorCnt >= 5">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상5</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(5)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="8"><div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_5" v-if="mainPointColor"></div></v-col>
        </v-row>
      </v-col>
      <!-- 포인트 색상6 -->
      <v-col cols="12" v-show="mainPointColorCnt >= 6">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상6</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(6)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="8"><div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_6" v-if="mainPointColor"></div></v-col>
        </v-row>
      </v-col>
      <!-- 포인트 색상7 -->
      <v-col cols="12" v-show="mainPointColorCnt >= 7">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상7</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(7)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="8"><div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_7" v-if="mainPointColor"></div></v-col>
        </v-row>
      </v-col>
      <!-- 포인트 색상8 -->
      <v-col cols="12" v-show="mainPointColorCnt >= 8">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상8</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(8)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="8"><div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_8" v-if="mainPointColor"></div></v-col>
        </v-row>
      </v-col>
      <!-- 포인트 색상9 -->
      <v-col cols="12" v-show="mainPointColorCnt >= 9">
        <v-row dense align="center">"
          <v-col cols="2" >
            <div class="pt-0">
            <span>포인트 색상9</span>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              @click="colorPickerClicked(9)"
            >색상 선택
            </v-btn>
          </v-col>
          <v-col cols="8"><div :style="`width:100px; border:1px solid black;height:30px;background-color:`+mainPointColor.point_color_9" v-if="mainPointColor"></div></v-col>
        </v-row>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 메인 APUB 패밀리 사이트 -->
      <v-col cols="2" >
        <div class="pt-0 font-weight-black">
        <span>[ APUB 패밀리 사이트 ]</span>
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-col cols="2" >
        <div class="pt-0"><span>저널 사이트 이름</span></div>
      </v-col>
      <v-col cols="10">
        <v-text-field
          class="wid-50"
          hide-details
          dense 
          outlined
          v-model="apubFamilySite.jrnl_site_nm"
          placeholder="https://www.naver.com"
        ></v-text-field>
      </v-col>
      <v-col cols="2" >
        <div class="pt-0"><span>저널 사이트 URL</span></div>
      </v-col>
      <v-col cols="10">
        <v-text-field
          class="wid-50"
          hide-details
          dense 
          outlined
          v-model="apubFamilySite.jrnl_site_url"
          placeholder="https://www.naver.com"
        ></v-text-field>
      </v-col>
      <v-col cols="2" >
        <div class="pt-0"><span>투고 시스템 이름</span></div>
      </v-col>
      <v-col cols="10">
        <v-text-field
          class="wid-50"
          hide-details
          dense 
          outlined
          v-model="apubFamilySite.sbmt_sys_nm"
          placeholder="https://www.naver.com"
        ></v-text-field>
      </v-col>
      <v-col cols="2" >
        <div class="pt-0"><span>투고 시스템 URL</span></div>
      </v-col>
      <v-col cols="10">
        <v-text-field
          class="wid-50"
          hide-details
          dense 
          outlined
          v-model="apubFamilySite.sbmt_sys_url"
          placeholder="https://www.naver.com"
        ></v-text-field>
      </v-col>
      <v-col cols="2" >
        <div class="pt-0"><span>학술대회 사이트 이름</span></div>
      </v-col>
      <v-col cols="10">
        <v-text-field
          class="wid-50"
          hide-details
          dense 
          outlined
          v-model="apubFamilySite.confr_site_nm"
          placeholder="https://www.naver.com"
        ></v-text-field>
      </v-col>
      <v-col cols="2" >
        <div class="pt-0"><span>학술대회 사이트 URL</span></div>
      </v-col>
      <v-col cols="10">
        <v-text-field
          class="wid-50"
          hide-details
          dense 
          outlined
          v-model="apubFamilySite.confr_site_url"
          placeholder="https://www.naver.com"
        ></v-text-field>
      </v-col>

      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 메인 이미지 섹션 -->
      <v-col cols="2" >
        <div class="pt-0 font-weight-black">
        <span>[ 메인 이미지 섹션 ]</span>
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <!-- 메인 이미지 파일 -->
      <!-- <v-col cols="2" >
        <div class="pt-0">
        <span>메인 이미지 파일</span>
        </div>
      </v-col>
      <v-col cols="3">
      <div class="wid-70 pt-0">
        <v-file-input
        hide-details
        dense
        accept="image/*"
        v-model="mainImgFile"
        ></v-file-input>
      </div>
      </v-col> -->
      <!-- 파일 표시 text-field -->
      <!-- <v-col cols="7">
      <div class="wid-100 pt-0 ml-10">
        <div class="f-l pt-2 pl-5 font-1" style="color:black;">
        <span><a :href="mainImgAtchPath" target="_blank">{{mainImgAtchNm}}</a></span>
        </div>
        <v-btn
        small
        color="red lighten-2"
        class="pa-0 ml-10 white--text f-l"
        hide-details
        v-if="mainImgAtchNm"
        @click="deleteMainImgAtch"
        >삭제</v-btn>
      </div>
      </v-col> -->

      <!-- 공지 메뉴 연결 -->
      <v-col cols="2" >
        <div class="pt-0">
        <span>공지 메뉴 연결</span>
        </div>
      </v-col>
      <v-col cols="2" >
        <v-select
        class="f-l pa-0 ma-0 wid-100 input-hei-d" 
        :items="menuList.filter((e) => e.page_type == 'bbs_notc' && e.disp_yn == 'Y')"
        item-value="menu_sno"
        item-text="menu_nm"
        v-model="mainImgNtceMenuSno"
        background-color="white"
        hide-details
        dense
        outlined
        ></v-select>
      </v-col>
      <v-col cols="1">
        <v-btn small @click="deleteNtceBoxMenuLink('M0')">삭제</v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 공지 섹션 -->
      <v-col cols="2" >
        <div class="pt-0 font-weight-black">
          <span>[ 공지 섹션 ]</span>
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 공지 박스 좌1 메뉴 연결 -->
      <v-col cols="2" >
        <div class="pt-0"><span>공지 박스 좌1 메뉴 연결</span></div>
      </v-col>
      <v-col cols="2" >
        <v-select
        class="f-l pa-0 ma-0 wid-100 input-hei-d" 
        :items="menuList.filter((e) => e.page_type == 'bbs_notc' && e.disp_yn == 'Y')"
        item-value="menu_sno"
        item-text="menu_nm"
        v-model="ntceBoxL1MenuSno"
        background-color="white"
        hide-details
        dense
        outlined
        ></v-select>
      </v-col>
      <v-col cols="8">
        <v-row dense align="center">
          <v-col cols="1">
            <v-btn small @click="deleteNtceBoxMenuLink('L1')">삭제</v-btn>
          </v-col>
          <v-col cols="11">
            <span>* 공지 박스 메뉴 연결은 좌/우 각 1번부터 설정해야 합니다. 2번만 설정할 경우 정상적으로 표시되지 않을 수 있습니다.</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 공지 박스 좌2 메뉴 연결 -->
      <v-col cols="2" >
        <div class="pt-0"><span>공지 박스 좌2 메뉴 연결</span></div>
      </v-col>
      <v-col cols="2" >
        <v-select
        class="f-l pa-0 ma-0 wid-100 input-hei-d" 
        :items="menuList.filter((e) => e.page_type == 'bbs_notc' && e.disp_yn == 'Y')"
        item-value="menu_sno"
        item-text="menu_nm"
        v-model="ntceBoxL2MenuSno"
        background-color="white"
        hide-details
        dense
        outlined
        :disabled="ntceBoxL1MenuSno == ''"
        ></v-select>
      </v-col>
      <v-col cols="1">
        <v-btn small @click="deleteNtceBoxMenuLink('L2')">삭제</v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 공지 박스 우1 메뉴 연결 -->
      <v-col cols="2" >
        <div class="pt-0"><span>공지 박스 우1 메뉴 연결</span></div>
      </v-col>
      <v-col cols="2" >
        <v-select
        class="f-l pa-0 ma-0 wid-100 input-hei-d" 
        :items="menuList.filter((e) => e.page_type == 'bbs_notc' && e.disp_yn == 'Y')"
        item-value="menu_sno"
        item-text="menu_nm"
        v-model="ntceBoxR1MenuSno"
        background-color="white"
        hide-details
        dense
        outlined
        ></v-select>
      </v-col>
      <v-col cols="8">
        <v-row dense align="center">
          <v-col cols="1">
            <v-btn small @click="deleteNtceBoxMenuLink('R1')">삭제</v-btn>
          </v-col>
          <v-col cols="11">
            <span>* 공지 박스 메뉴 연결은 좌/우 각 1번부터 설정해야 합니다. 2번만 설정할 경우 정상적으로 표시되지 않을 수 있습니다.</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 공지 박스 우2 메뉴 연결 -->
      <v-col cols="2" >
        <div class="pt-0"><span>공지 박스 우2 메뉴 연결</span></div>
      </v-col>
      <v-col cols="2" >
        <v-select
        class="f-l pa-0 ma-0 wid-100 input-hei-d" 
        :items="menuList.filter((e) => e.page_type == 'bbs_notc' && e.disp_yn == 'Y')"
        item-value="menu_sno"
        item-text="menu_nm"
        v-model="ntceBoxR2MenuSno"
        background-color="white"
        hide-details
        dense
        outlined
        :disabled="ntceBoxR1MenuSno == ''"
        ></v-select>
      </v-col>
      <v-col cols="1">
        <v-btn small @click="deleteNtceBoxMenuLink('R2')">삭제</v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 일정 섹션 -->
      <v-col cols="2" >
        <div class="pt-0 font-weight-black">
        <span>[ 일정 섹션 ]</span>
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 일정 메뉴 연결 -->
      <v-col cols="2" >
        <div class="pt-0">
        <span>일정 메뉴 연결</span>
        </div>
      </v-col>
      <v-col cols="7" >
        <v-select
        class="f-l pa-0 ma-0 wid-30 input-hei-d" 
        :items="menuList.filter((e) => e.page_type == 'scdul' && e.disp_yn == 'Y')"
        item-value="menu_sno"
        item-text="menu_nm"
        v-model="scdulMenuSno"
        background-color="white"
        hide-details
        dense
        outlined
        ></v-select>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <!-- 바로가기 링크 섹션 -->
      <v-col cols="4" >
        <div class="pt-0 font-weight-black">
        <span>[ 바로가기 링크 섹션 ]</span>
        </div>
      </v-col>
      <v-col cols="8" >
        <v-btn 
          small 
          color="blue lighten-2" 
          class="white--text ml-3 f-r"
          @click="addLinkClicked"
          >링크추가
        </v-btn>
        <v-btn 
          small 
          color="red lighten-2" 
          class="white--text f-r"
          @click="deleteStcutClicked"
          >선택삭제
        </v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col 
       cols="12" 
       v-for="(item,idx) in mainPageStcutList" 
       :key="`stcut-`+idx" 
      >
        <v-row dense align="center">
          <v-col cols="12"> 
            <v-checkbox
            v-model="item.select_yn"
            :label="`바로가기 `+item.new_odr"
            hide-details
            dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider class="lin"></v-divider></v-col>
          <v-col cols="2" > 
            <span>아이콘</span>
          </v-col>
          <v-col cols="1">
            <v-btn
             @click="iconSelClicked(item.odr)"
            >아이콘 선택
            </v-btn>
          </v-col>
          <v-col cols="9">
            <div style="background-color:rgb(42, 42, 42);width:60px;height:60px;" class="pa-3" v-if="item.icon_path">
              <img style="width:100%" :src="item.icon_path"/>
            </div>
          </v-col>

          <v-col cols="2"> 
            <span>메뉴 연결</span>
          </v-col>
          
            <v-radio-group
              class="ma-0 pt-3 pb-3"
              v-model="item.stcut_type"
              row
              hide-details
            >
                <v-radio
                  label="메뉴 선택"
                  value="menu"
                ></v-radio>
                <v-select
                  class="f-l pa-0 mr-5 input-hei-d" 
                  style="width: 200px;"
                  :items="menuList"
                  item-value="menu_sno"
                  item-text="menu_nm"
                  v-model="item.menu_sno"
                  background-color="white"
                  hide-details
                  dense
                  outlined
                ></v-select>
                <v-radio
                  label="연결 URL"
                  value="url"
                ></v-radio>
                <v-text-field
                  class="f-l ma-0 pa-0"
                  style="width: 200px"
                  hide-details
                  dense 
                  outlined
                  placeholder="메뉴명"
                  v-model="item.stcut_nm"
                ></v-text-field>
                <v-text-field
                  class="f-l ma-0 pa-0"
                  style="width: 500px"
                  hide-details
                  dense 
                  outlined
                  placeholder="연결 URL"
                  v-model="item.stcut_url"
                ></v-text-field>
            </v-radio-group>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>
      <!-- 후원사 안내 섹션 -->
      <v-col cols="4" >
        <div class="pt-0 font-weight-black">
        <span>[ 후원사 안내 섹션 ]</span>
        </div>
      </v-col>
      <v-col cols="8" >
        <v-btn 
          small 
          color="blue lighten-2" 
          class="white--text ml-3 f-r"
          @click="addSpnsrClicked"
          >후원사 추가
        </v-btn>
        <v-btn 
          small 
          color="red lighten-2" 
          class="white--text f-r"
          @click="deleteSpnsrClicked"
          >선택삭제
        </v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2">메인 페이지 후원사 표시제목</v-col>
      <v-col cols="10">
        <v-text-field
          class="wid-20"
          hide-details
          dense 
          outlined
          v-model="spnsr_disp_ttl"
        ></v-text-field>
      </v-col>
      <v-col cols="2">한줄에 표시될 후원사 수 : </v-col>
      <v-col cols="1">
        <v-select
          class="f-l pa-0 ma-0 wid-100 input-hei-d" 
          :items="spnsrDispCntList"
          background-color="white"
          hide-details
          dense
          outlined
          v-model="disp_cnt"
        ></v-select>
      </v-col>
      <v-col cols="9">* 후원사 메뉴 화면에 적용되는 수치입니다. 메인 화면은 고정입니다.</v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12" v-for="(item, idx) in soctySpnsrList" :key="`spnsr-`+idx">
        <v-row dense align="center">
          <v-col cols="12"> 
            <v-checkbox
            v-model="item.select_yn"
            :label="`후원사 `+item.new_odr"
            hide-details
            dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider class="lin"></v-divider></v-col>
          <v-col cols="2" > 
            <span>아이콘</span>
          </v-col>
          <v-col cols="4">
            <div class="wid-70 pt-0">
            <v-file-input
              accept="image/*"
              hide-details
              dense
              v-model="item.file"
            ></v-file-input>
            </div>
          </v-col>
          <!-- 파일 표시 text-field -->
          <v-col cols="6">
            <div class="wid-100 pt-0 ml-10">
              <div class="f-l pt-2 pl-5 font-1" style="color:black;">
                <span><a :href="item.img_atch_path" target="_blank">{{item.img_atch_nm}}</a></span>
              </div>
            </div>
          </v-col>
          <v-col cols="2" > 
            <span>링크</span>
          </v-col>
          <v-col cols="7">
            <v-text-field
            v-model="item.link_url"
            class="wid-50"
            hide-details
            dense 
            outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>
    <!-- </v-row> -->
    <!-- <v-row dense align="center"> -->
      <!-- 패밀리 사이트 -->
      <v-col cols="4" >
        <div class="pt-0 font-weight-black">
        <span>[ 패밀리 사이트 ]</span>
        </div>
      </v-col>
      <v-col cols="8" >
        <v-btn 
          small 
          color="blue lighten-2" 
          class="white--text ml-3 f-r"
          @click="familySiteAddClicked"
          >패밀리 사이트 추가
        </v-btn>
        <v-btn 
          small 
          color="red lighten-2" 
          class="white--text f-r"
          @click="deleteFamliySiteClicked()"
          >선택삭제
        </v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-row 
          dense 
          align="center" 
          v-for="(item, idx) in familySiteList"
          :key='`fmly_`+idx'
        >
          <v-col cols="12"> 
            <v-checkbox
            v-model="item.select_yn"
            :label="`사이트 `+item.new_odr"
            hide-details
            dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider class="lin"></v-divider></v-col>
          <v-col cols="2" > 
            <span>사이트 명</span>
          </v-col>
          <v-col cols="10">
            <v-text-field
            class="wid-40"
            hide-details
            dense 
            outlined
            v-model="item.site_nm"
            ></v-text-field>
          </v-col>
          <v-col cols="2" > 
            <span>사이트 URL</span>
          </v-col>
          <v-col cols="10">
            <v-text-field
            class="wid-40"
            hide-details
            dense 
            outlined
            v-model="item.site_url"
            placeholder="https://www.naver.com"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>
    <!-- </v-row> -->
    <!-- <v-row dense align="center"> -->
      <!-- 유관 기관 -->
      <v-col cols="4" >
        <div class="pt-0 font-weight-black">
        <span>[ 유관 기관 ]</span>
        </div>
      </v-col>
      <v-col cols="8" >
        <v-btn 
          small 
          color="blue lighten-2" 
          class="white--text ml-3 f-r"
          @click="relOrgAddClicked"
          >유관 기관 추가
        </v-btn>
        <v-btn 
          small 
          color="red lighten-2" 
          class="white--text f-r"
          @click="deleteRelOrgClicked"
          >선택삭제
        </v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-row 
          dense 
          align="center" 
          v-for="(item, idx) in relOrgList"
          :key='`rel_`+idx'
        >
          <v-col cols="12"> 
            <v-checkbox
            v-model="item.select_yn"
            :label="`유관 기관 `+item.new_odr"
            hide-details
            dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider class="lin"></v-divider></v-col>
          <v-col cols="2" > 
            <span>사이트 명</span>
          </v-col>
          <v-col cols="10">
            <v-text-field
            class="wid-40"
            hide-details
            dense 
            outlined
            v-model="item.site_nm"
            ></v-text-field>
          </v-col>
          <v-col cols="2" > 
            <span>사이트 URL</span>
          </v-col>
          <v-col cols="10">
            <v-text-field
            class="wid-40"
            hide-details
            dense 
            outlined
            v-model="item.site_url"
            placeholder="https://www.naver.com"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="4" >
        <div class="pt-0 font-weight-black">
        <span>[ 마이페이지 ]</span>
        </div>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2"> 
        <span>결제 메뉴</span>
      </v-col>
      <v-col cols="10" >
        <v-checkbox
          label="사용함"
          hide-details
          dense
          v-model="myPageMap.pay_menu_use_yn"
        ></v-checkbox>
      </v-col>
      <v-col cols="2" > 
        <span>청구서/납부확인서/증명서 메뉴</span>
      </v-col>
      <v-col cols="10">
        <v-checkbox
          label="사용함"
          hide-details
          dense
          v-model="myPageMap.crtf_menu_use_yn"
          @change="mypageChanged"
        ></v-checkbox>
      </v-col> -->
    </v-row>

    <!-- 다이얼로그 -->
    <template>
			<v-dialog
				v-model="colorPickerPop"
        width="auto"
			>
				<v-card style="text-align:center;">
					<v-app-bar 
						class="fixed-bar" 
						color="blue lighten-2" 
						max-height="60px">
						<v-toolbar-title
							class="white--text">
							컬러 설정
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn
							color="white"
              @click="selColorClicked"
              >
							설정
						</v-btn>
					</v-app-bar>
          <template>
            <v-color-picker 
             v-model="color"
             mode="hexa"
             hide-mode-switch
            >
            </v-color-picker>
          </template>
				</v-card>
			</v-dialog>
		</template>

    <template>
			<v-dialog
				v-model="iconSelPop"
        max-width="700px"
			>
				<v-card style="text-align:center;" >
					<v-app-bar 
						class="fixed-bar" 
						color="blue lighten-2" 
						max-height="60px">
						<v-toolbar-title
							class="white--text">
							바로가기 아이콘 설정
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn
							color="white"
              @click="selStcutClicked(selStcutOdr)"
              >
							설정
						</v-btn>
					</v-app-bar>
          <template>
            <v-container fluid class="pa-3">
              <v-row>
                <v-col cols="12">
                </v-col>
              </v-row>
              <v-row dense align="center">
                <v-radio-group
                    row
                    dense
                    hide-details
                    class="mt-0"
                    v-model="selStcutIcon"
                  >
                  <v-col cols="3" v-for="(a,i) in stcutIconList" :key="i">
                    <div style="background-color:rgb(42, 42, 42);width:150px;height:150px;" class="d-flex pa-5" >
                      <img style="width:100%;" :src="a.icon_path"/>
                    </div>
                    <v-radio
                      :value="a.icon_sno"
                      hide-details
                      class="ml-15"
                    ></v-radio>
                  </v-col>
                </v-radio-group>
                 
              </v-row>
            </v-container>
          </template>
				</v-card>
			</v-dialog>
		</template>
    <!-- 다이얼로그 -->

    <!-- 폰트 컬러 픽커 다이얼로그 -->
    <template>
      <v-dialog
        v-model="fontColorPickerPop"
        width="auto">
        <v-card style="text-align:center;">
          <v-app-bar 
            class="fixed-bar" 
            color="blue lighten-2" 
            max-height="60px">
            <v-toolbar-title
              class="white--text">
              컬러 설정
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              @click="selFontColorClicked">
              설정
            </v-btn>
          </v-app-bar>
          <template>
            <v-color-picker 
              v-model="fontColor"
              mode="hexa"
              hide-mode-switch>
            </v-color-picker>
          </template>
        </v-card>
      </v-dialog>
    </template>

  </v-container>
</template>
<script>
export default {
  data() {
    return {
      colorPickerPop: false,      //컬러 픽커 오픈 여부
      iconSelPop: false,        //바로가기 아이콘 팝업 오픈 여부
      mode: 'hexa',           //컬러 픽커 모드
      color: null,            //컬러 픽커에서 선택된 값
      pntColor: '',           //선택된 컬러
      mberSno: '',          //로그인 사번
      hdrTypeList: [],        //헤더 유형 리스트
      hdrType: '',          //헤더 선택값
      mainPageMap: null,        //메인 페이지 데이터
      mainPageStcutList: [],      //바로가기 링크 섹션 리스트
      mainPageTypeList: [],       //메인 페이지 유형 리스트
      mainPageType: '',         //메인 페이지 유형 선택값
      soctySpnsrList: [],       //후원사 리스트
      themeTypeList: [],
      themeType: '',
      menuList: [],
      mainImgNtceMenuSno: '',    //메인이미지공지메뉴번호
      ntceBoxL1MenuSno: '',      //공지박스좌1메뉴번호
      ntceBoxL2MenuSno: '',      //공지박스좌2메뉴번호
      ntceBoxR1MenuSno: '',      //공지박스우1메뉴번호
      ntceBoxR2MenuSno: '',      //공지박스우2메뉴번호
      scdulMenuSno: '',        //일정메뉴번호
      // mainImgFile: null,
      // mainImgAtchNm: '',
      // mainImgAtchPath: '',
      // mainImgAtchSno: '',
      stcutIconList: [],       //바로가기 아이콘 리스트
      selStcutIcon: '',        //선택된 아이콘
      selStcutOdr: '',
      colorPickNo: '',
      mainPointColor: {
        point_color_1: '',
        point_color_2: '',
        point_color_3: '',
        point_color_4: '',
        point_color_5: '',
        point_color_6: '',
        point_color_7: '',
        point_color_8: '',
        point_color_9: '',
        font_color_3: '',
        font_color_4: '',
        font_color_5: '',
        font_color_6: '',
      },
      apubFamilySite: {},
      familySiteList: [],
      relOrgList: [],
      isValid: true,
      spnsrDispCntList: [3,4,6],
      disp_cnt: 3,
      spnsr_disp_ttl: '',
      pendingData: null,

      // myPageMap: {
      //   pay_menu_use_yn: false,
      //   crtf_menu_use_yn: false
      // },

      fontColor: '',      //컬러 픽커에서 선택된 값
      fontColorPickNo: '',      //컬러 픽커 번호
      fontColorPickerPop: false,      //컬러 픽커 오픈 여부
    }
  },
  computed: {
    mainPointColorCnt() {
      // console.log("computed > mainPointColorCnt", this.mainPageType, this.mainPageTypeList);
      if(this.mainPageTypeList.length == 0) {
        return 0;
      }

      if(this.mainPageType) {
        const mainPageType = this.mainPageTypeList.filter((e) => e.main_page_type == this.mainPageType);
        // console.log("mainPageType", mainPageType, mainPageType.point_color_cnt);
        return mainPageType[0].point_color_cnt;
      } else {
        return 0;
      }
    },
    isOnPending() {
      const allData = JSON.stringify([
            this.mainPageType
          , this.mainPointColor
          , this.apubFamilySite
          // , this.mainImgFile
          , this.mainImgNtceMenuSno
          , this.ntceBoxL1MenuSno
          , this.ntceBoxL2MenuSno
          , this.ntceBoxR1MenuSno
          , this.ntceBoxR2MenuSno
          , this.scdulMenuSno
          , this.mainPageStcutList
          , this.spnsr_disp_ttl
          , this.disp_cnt
          , this.soctySpnsrList
          , this.familySiteList
          , this.relOrgList
          // , this.myPageMap
        ])
      console.log(this.pendingData != allData);
      return this.pendingData != allData;
    }
  },
  watch: {
    // mainPointColorCnt: {
    //   immediate: true,
    //   handler: function () {
    //     console.log("watch > mainPointColorCnt", this.mainPointColorCnt);
    //   }, 
    // }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");
    this.getMainPageMgmt();
  },
  methods: {
    addLinkClicked() {  //링크 추가 클릭시
      // let len = this.mainPageStcutList.length;
      // if(len >= 6) {
      //   alert("최대 6개까지 등록 가능합니다.");
      //   return;
      // } else {
        let maxOdr = 0;

        this.mainPageStcutList.forEach((e) => {
          if(e.odr > maxOdr) {
          maxOdr = e.odr;
          }
        });

        maxOdr++;
        let len = this.mainPageStcutList.length+1;

        this.mainPageStcutList.push({odr: maxOdr, menu_sno: '', select_yn: false , new_odr: len, new_yn:'Y' })
      // }
    },//addLinkClicked end
    addSpnsrClicked() { //후원사 추가 클릭시
      let maxOdr = 0;

      this.soctySpnsrList.forEach((e) => {
        if(e.odr > maxOdr) {
        maxOdr = e.odr;
        }
      });

      maxOdr++;
      let len = this.soctySpnsrList.length+1;

      this.soctySpnsrList.push({odr: maxOdr, imgNm: '', select_yn: false, link_url: 'https://', file: null, new_yn : 'Y', new_odr: len})
    },//addSpnsrClicked end
    selColorClicked() {
      this.mainPointColor[`point_color_`+this.colorPickNo] = typeof this.color == 'object' ? this.color.hexa : this.color;
      console.log("selColorClicked : ", this.color, this.mainPointColor);

      this.colorPickerPop = false;
    },//selColorClicked end
    getMainPageMgmt() {
      let params = new URLSearchParams();
      params.append("mber_sno", this.mberSno);
      
      this.$http.post("/admin/getMainPageMgmt", params).then((res) => {
        console.log(res.data);
        this.hdrTypeList = res.data.hdrTypeList;
        
        this.mainPageStcutList = res.data.mainPageStcutList;
        this.mainPageTypeList = res.data.mainPageTypeList;
        this.soctySpnsrList = res.data.soctySpnsrList;
        this.menuList = res.data.menuList;
        this.stcutIconList = res.data.stcutIconList;

        const spnsrMap = res.data.spnsrMap;
        if(spnsrMap) {
          this.disp_cnt = spnsrMap.disp_cnt;
        }

        const mainPageMap = res.data.mainPageMap;
        // this.pntColor = mainPageMap.pnt_color;
        this.hdrType = mainPageMap.hdr_type;
        this.mainPageType = mainPageMap.main_page_type;
        this.spnsr_disp_ttl = mainPageMap.spnsr_disp_ttl;
        if(mainPageMap.spnsr_disp_ttl) {
          this.spnsr_disp_ttl = mainPageMap.spnsr_disp_ttl;
        }

        if(res.data.mainPointColor) {
          this.mainPointColor = res.data.mainPointColor;
        }
        
        if(res.data.apubFamilySiteMap) {
          this.apubFamilySite = res.data.apubFamilySiteMap;
        }
        
        
      
        this.familySiteList = res.data.relSiteList.filter((e) => e.rel_site_type == 'etc_fmly');
        let fmlCnt = 1;
        this.familySiteList.forEach((e) => {
          e.new_odr = fmlCnt;
          e.new_yn = 'N';
          fmlCnt++;
        })
        
        this.relOrgList = res.data.relSiteList.filter((e) => e.rel_site_type == 'rel_org');
        let orgCnt = 1;
        this.relOrgList.forEach((e) => {
          e.new_odr = orgCnt;
          e.new_yn = 'N';
          orgCnt++;
        })

        // console.log(this.familySite);

        this.mainImgNtceMenuSno = mainPageMap.main_img_ntce_menu_sno ? mainPageMap.main_img_ntce_menu_sno : '';
        this.ntceBoxL1MenuSno = mainPageMap.ntce_box_l1_menu_sno ? mainPageMap.ntce_box_l1_menu_sno : '';
        this.ntceBoxL2MenuSno = mainPageMap.ntce_box_l2_menu_sno ? mainPageMap.ntce_box_l2_menu_sno : '';
        this.ntceBoxR1MenuSno = mainPageMap.ntce_box_r1_menu_sno ? mainPageMap.ntce_box_r1_menu_sno : '';
        this.ntceBoxR2MenuSno = mainPageMap.ntce_box_r2_menu_sno ? mainPageMap.ntce_box_r2_menu_sno : '';
        this.scdulMenuSno = mainPageMap.scdul_menu_sno ? mainPageMap.scdul_menu_sno : '';
        // this.mainImgAtchNm = mainPageMap.main_img_atch_nm;
        // this.mainImgAtchPath = mainPageMap.main_img_atch_path;
        // this.mainImgAtchSno = mainPageMap.main_img_atch_sno ? mainPageMap.main_img_atch_sno : '';        

        let spnsrCnt = 1;
        this.soctySpnsrList.forEach((e) => {
          e.new_yn = 'N';
          e.new_odr = spnsrCnt;
          spnsrCnt++;
        })

        let stcutCnt = 1;
        this.mainPageStcutList.forEach((e) => {
          e.new_yn = 'N';
          e.new_odr = stcutCnt;
          stcutCnt++;
        })

        
        // 여기
        // mainPageType 메인테마
        // this.mainPointColor 포인트 색상
        // this.apubFamilySite apub 패밀리 사이트
        // this.mainImgFile 메인 이미지
        // this.mainImgNtceMenuSno  공지메뉴
        // this.ntceBoxL1MenuSno  공지메뉴1
        // this.ntceBoxL2MenuSno  공지메뉴2
        // this.ntceBoxR1MenuSno 공지메뉴3
        // this.ntceBoxR2MenuSno 공지메뉴4
        // this.scdulMenuSno  일정
        // this.mainPageStcutList 바로가기
        // this.soctySpnsrList 후원사
        // this.familySiteList 패밀리 사이트
        // this.relOrgList 유관기관
        
        // const myPageMap = res.data.myPageMap;
        // if(myPageMap) {
        //   this.myPageMap.pay_menu_use_yn = myPageMap.pay_menu_use_yn == 'Y' ? true : false;
        //   this.myPageMap.crtf_menu_use_yn = myPageMap.crtf_menu_use_yn == 'Y' ? true : false;
        // }

        this.pendingData = JSON.stringify([
          this.mainPageType
          , this.mainPointColor
          , this.apubFamilySite
          // , this.mainImgFile
          , this.mainImgNtceMenuSno
          , this.ntceBoxL1MenuSno
          , this.ntceBoxL2MenuSno
          , this.ntceBoxR1MenuSno
          , this.ntceBoxR2MenuSno
          , this.scdulMenuSno
          , this.mainPageStcutList
          , this.spnsr_disp_ttl
          , this.disp_cnt
          , this.soctySpnsrList
          , this.familySiteList
          , this.relOrgList
          // , this.myPageMap
        ])

        if(res.data.mainPointColor.font_color_3 != null) {
            this.mainPointColor.font_color_3 = res.data.mainPointColor.font_color_3;
          } else {
            this.mainPointColor.font_color_3 = '#FFFFFFFF';
          }

          if(res.data.mainPointColor.font_color_4 != null) {
            this.mainPointColor.font_color_4 = res.data.mainPointColor.font_color_4;
          } else {
            this.mainPointColor.font_color_4 = '#FFFFFFFF';
          }

          if(res.data.mainPointColor.font_color_5 != null) {
            this.mainPointColor.font_color_5 = res.data.mainPointColor.font_color_5;
          } else {
            this.mainPointColor.font_color_5 = '#FFFFFFFF';
          }

          if(res.data.mainPointColor.font_color_6 != null) {
            this.mainPointColor.font_color_6 = res.data.mainPointColor.font_color_6;
          } else {
            this.mainPointColor.font_color_6 = '#FFFFFFFF';
          }

      })
      .catch(function (error) {
        console.log(error);
      });
    },//getMainPageMgmt end
    // deleteSpnsrImgClicked(odr) { //후원사 이미지 삭제 클릭시
    //   // const sel_len = this.soctySpnsrList.filter((e) => e.select_yn == true);
    //   // if(sel_len == 0) {
    //   //   alert('선택된 항목이 없습니다.');
    //   // } else {
    //     if(confirm('후원사 이미지 삭제 하시겠습니까?')) {
    //       const formData = new FormData();
    //       formData.append("mber_sno", this.mberSno);
    //       formData.append("odr", odr);

    //       this.$http.post("/admin/deleteSoctySpnsrImg", formData).then((res) => {
    //         console.log(res.data);
    //         alert('삭제되었습니다.');
    //         this.getSoctySpnsr();
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //     }
    //   // }
    // },//deleteSpnsrImgClicked end
    deleteSpnsrClicked() { //후원사 선택삭제 클릭시
      const sel_len = this.soctySpnsrList.filter((e) => e.select_yn == true);
      if(sel_len == 0) {
        alert('선택된 항목이 없습니다.');
      } else {
        if(confirm('선택된 후원사 항목 삭제 하시겠습니까?')) {
          const formData = new FormData();
          formData.append("mber_sno", this.mberSno);
          const selList = this.soctySpnsrList.filter((e) => e.select_yn == true);
          formData.append("soctySpnsrList", JSON.stringify(selList));

          this.$http.post("/admin/deleteSoctySpnsr", formData).then((res) => {
            console.log(res.data);
            alert('후원사 링크 삭제되었습니다.');
            this.getSoctySpnsr();
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      }
    },//deleteSpnsrClicked end
    deleteStcutClicked() { //바로가기 선택삭제 클릭시
      const sel_len = this.mainPageStcutList.filter((e) => e.select_yn == true);
      if(sel_len == 0) {
        alert('선택된 항목이 없습니다.');
      } else {
        if(confirm('선택된 바로가기 항목 삭제 하시겠습니까?')) {
          
          const formData = new FormData();
          formData.append("mber_sno", this.mberSno);
          const selList = this.mainPageStcutList.filter((e) => e.select_yn == true);
          formData.append("mainPageStcutList", JSON.stringify(selList));

          this.$http.post("/admin/deleteMainPageStcut", formData).then((res) => {
            console.log(res.data);
            alert('선택된 바로가기 항목 삭제되었습니다.');
            this.getMainPageStcut();
          })
          .catch(function (error) {
            console.log(error);
          });

        }
      }
    },//deleteLink end
    saveMainPageClicked() { //메인 페이지 관리 저장 클릭시
      // console.log(this.mainPointColor);
      this.validationCheck();
      
      if(this.isValid) {
        
        const formData = new FormData();
        formData.append("mber_sno", this.mberSno);
        // formData.append("pnt_color", this.pntColor);
        formData.append("hdr_type", this.hdrType);
        formData.append("main_page_type", this.mainPageType);
        // console.log("ntceBoxL1MenuSno : ", this.ntceBoxL1MenuSno);
        formData.append("ntce_box_l1_menu_sno", this.ntceBoxL1MenuSno);
        formData.append("ntce_box_l2_menu_sno", this.ntceBoxL2MenuSno);
        formData.append("ntce_box_r1_menu_sno", this.ntceBoxR1MenuSno);
        formData.append("ntce_box_r2_menu_sno", this.ntceBoxR2MenuSno);
        formData.append("scdul_menu_sno", this.scdulMenuSno);
        formData.append("main_img_ntce_menu_sno", this.mainImgNtceMenuSno);

        //후원사 표시갯수 , 표시명
        formData.append("disp_cnt", this.disp_cnt);
        formData.append("spnsr_disp_ttl", this.spnsr_disp_ttl);

        this.soctySpnsrList.forEach((e) => {
          if(!e.link_url) {
            e.link_url = 'https://';
          }
        })

        formData.append("soctySpnsrList", JSON.stringify(this.soctySpnsrList));
        formData.append("mainPageStcutList", JSON.stringify(this.mainPageStcutList));

        // 주석 처리
        const file_info_list = [];
        this.soctySpnsrList.forEach((e) => {
          if(e.file != null) {
            formData.append('soctySpnsrFileList', e.file);
            file_info_list.push({odr:e.odr});
          }
        })

        formData.append("file_info_list", JSON.stringify(file_info_list));
        // formData.append("mainImgFile",this.mainImgFile);
        //메인 포인트 컬러
        formData.append("mainPointColor", JSON.stringify(this.mainPointColor));

        //메인 폰트 컬러
        formData.append("mainPointColor", JSON.stringify(this.mainPointColor));
        
        //apub 패밀리 사이트
        formData.append("apubFamilySite", JSON.stringify(this.apubFamilySite));

        //푸터 패밀리 사이트
        this.familySiteList.forEach((e) => {
          if(!e.site_url) {
            e.site_url = 'https://';
          }
        })
        formData.append("familySiteList", JSON.stringify(this.familySiteList));

        //푸터 유관 기관 사이트
        this.relOrgList.forEach((e) => {
          if(!e.site_url) {
            e.site_url = 'https://';
          }
        })
        formData.append("relOrgList", JSON.stringify(this.relOrgList));
        // formData.append("myPageMap", JSON.stringify(this.myPageMap));

        // console.log("this.mainImgFile :: ",this.mainImgFile);
        this.$http.post("/admin/upsertMainPageMgmt", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          console.log(res.data);
          alert('저장되었습니다.');
          this.getMainPageMgmt();
          // this.$router.go(0);
        })
        .catch(function (error) {
          console.log(error);
        });
      }

    },
    // deleteMainImgAtch() {
    //   if(confirm('메인 이미지 삭제하시겠습니까?')) {
    //     const formData = new FormData();
    //     formData.append("file_atch_sno", this.mainImgAtchSno);

    //     this.$http.post("/admin/updateMainImg",formData).then((res) => {
    //       // console.log(res.data);
    //       if(res.data.code == 0) {
    //         alert('삭제되었습니다.');
    //         this.$router.go(0);
    //       } else {
    //         alert('삭제오류.');
    //       }
          
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    //   }
    // },//deleteMainImgAtch end
    getSoctySpnsr() {
      this.$http.get("/admin/getSoctySpnsr").then((res) => {
        this.soctySpnsrList = res.data.soctySpnsrList;

        let spnsrCnt = 1;
        this.soctySpnsrList.forEach((e) => {
          e.new_yn = 'N';
          e.new_odr = spnsrCnt;
          spnsrCnt++;
        })
        
      }).catch(
        function (error) {
        console.log(error);
        }
      )
    },//getSoctySpnsr end
    getMainPageStcut() {
      this.$http.get("/admin/getMainPageStcut").then((res) => {
        this.mainPageStcutList = res.data.mainPageStcutList;

        let stcutCnt = 1;
        this.mainPageStcutList.forEach((e) => {
          e.new_yn = 'N';
          e.new_odr = stcutCnt;
          stcutCnt++;
        })
        
      }).catch(
        function (error) {
        console.log(error);
        }
      )
    },
    validationCheck() { //저장시 validationCheck
      this.isValid =  true;
      //바로가기 아이콘 선택 필수값 체크
      const stcutIconEmptyLen =  this.mainPageStcutList.filter((e) => !e.icon_sno || e.icon_sno == "").length;
      const stcutMenuEmptyLen =  this.mainPageStcutList.filter((e) => !e.menu_sno || e.menu_sno == "").length;
      if(stcutIconEmptyLen > 0) {
        alert("바로가기 아이콘은 필수입니다.");
        this.isValid =  false;
      } else if(stcutMenuEmptyLen > 0) {
        alert("바로가기 메뉴설정은 필수입니다.");
        this.isValid =  false;
      }

      //후원사 이미지 필수값 체크
      const spnsrEmptyImg = this.soctySpnsrList.filter((e) => !e.file && !e.img_atch_sno).length;
      if(spnsrEmptyImg > 0) {
        alert("후원사 이미지는 필수 입니다.");
        this.isValid =  false;
      }

      //패밀리 사이트 필수값 체크
      this.familySiteList.some((e) => {
        if(!e.site_nm) {
          alert('패밀리 사이트 사이트명은 필수 입니다.');
          this.isValid =  false;
          return true;
        } else if(!e.site_url) {
          alert('패밀리 사이트 URL은 필수 입니다.');
          this.isValid =  false;
          return true;
        }
      })

      //유관 기관 필수값 체크
      this.relOrgList.some((e) => {
        if(!e.site_nm) {
          alert('유관 기관 사이트명은 필수 입니다.');
          this.isValid =  false;
          return true;
        } else if(!e.site_url) {
          alert('유관 기관 URL은 필수 입니다.');
          this.isValid =  false;
          return true;
        }
      })

    },//validationCheck end
    iconSelClicked(odr) { //아이콘 선택 클릭시

      this.selStcutOdr = odr;
      this.selStcutIcon = '';
      this.iconSelPop = true;

    },//iconSelClicked end
    selStcutClicked(odr) {  
      
      let stcutIconList = this.stcutIconList.filter((e) => e.icon_sno == this.selStcutIcon);
      const icon_path = stcutIconList[0].icon_path;

      let list = this.mainPageStcutList.filter((e) => e.odr == odr);
      list[0].icon_sno = this.selStcutIcon;
      list[0].icon_path = icon_path;
      
      this.iconSelPop = false;

    },//selStcutClicked end
    colorPickerClicked(no) {  //컬러 피커 클릭시
      this.colorPickNo = no;
      this.color = this.mainPointColor[`point_color_`+no];  //눌럿을때 색상위치로 초기화
      this.colorPickerPop=true;
    },//colorPickerClicked end
    familySiteAddClicked() {  //패밀리 사이트 추가 버튼 클릭시
      let maxOdr = 0;

      this.familySiteList.forEach((e) => {
        if(e.disp_odr > maxOdr) {
        maxOdr = e.disp_odr;
        }
      });
      maxOdr++;
      let len = this.familySiteList.length+1;
      this.familySiteList.push({disp_odr: maxOdr, site_nm: '', site_url: 'https://', rel_site_type: 'etc_fmly', new_odr: len, new_yn: 'Y', select_yn: false});
    },//familySiteAddClicked end
    deleteFamliySiteClicked() { //패밀리사이트 삭제버튼 클릭시
      if(confirm('선택하신 패밀리사이트 삭제하시겠습니까?')) {
        const selFamily = this.familySiteList.filter((e) => e.select_yn == true);
        if(selFamily.length == 0) {
          alert('선택된 패밀리 사이트가 없습니다.');
          return;
        } else {
          const delDataList = this.familySiteList.filter((e) => e.select_yn == true && e.new_yn == 'N');
          
          console.log("this.delDataList",delDataList);
          console.log("this.familySiteList",this.familySiteList);

          if(delDataList.length > 0) {
            
            const formData = new FormData();
            formData.append("delDataList",JSON.stringify(delDataList))
            this.$http.post("/admin/deleteRelSite",formData).then((res) => {
              console.log(res.data);
              alert('삭제되었습니다.');
              this.getRelSite('etc_fmly');
            }).catch(
              function (error) {
              console.log(error);
              }
            )
            
          } else {
            this.getRelSite('etc_fmly');
          }
        }
      }
    },//deleteFamliySiteClicked end
    getRelSite(rel_site_type) { //푸터 패밀리, 유관기관 조회
      this.$http.get("/admin/getRelSite").then((res) => {
        const list = res.data.relSiteList;
        if(list) {
          if(rel_site_type == 'etc_fmly') { //패밀리 사이트

            this.familySiteList = list.filter((e) => e.rel_site_type == rel_site_type);
            let fmlCnt = 1;
            this.familySiteList.forEach((e) => {
              e.new_odr = fmlCnt;
              fmlCnt++;
            })

          } else if(rel_site_type == 'rel_org'){  //유관 기관
            this.relOrgList = list.filter((e) => e.rel_site_type == rel_site_type);
            let orgCnt = 1;
            this.relOrgList.forEach((e) => {
              e.new_odr = orgCnt;
              orgCnt++;
            })

          }
          
        }
        
      }).catch(
        function (error) {
        console.log(error);
        }
      )
    },//getRelSite end
    relOrgAddClicked() {  //유관 기관 추가 버튼 클릭시
      let maxOdr = 0;

      this.relOrgList.forEach((e) => {
        if(e.disp_odr > maxOdr) {
          maxOdr = e.disp_odr;
        }
      });
      maxOdr++;
      let len = this.relOrgList.length+1;
      this.relOrgList.push({disp_odr: maxOdr, site_nm: '', site_url: 'https://', rel_site_type: 'rel_org', new_odr: len, new_yn: 'Y', select_yn: false});


    },//relOrgAddClicked end
    deleteRelOrgClicked() { //유관 기관 삭제 버튼 클릭시
      if(confirm('선택하신 유관 기관 사이트 삭제하시겠습니까?')) {
        const selFamily = this.relOrgList.filter((e) => e.select_yn == true);
        if(selFamily.length == 0) {
          alert('선택된 유관 기관 사이트가 없습니다.');
          return;
        } else {
          const delDataList = this.relOrgList.filter((e) => e.select_yn == true && e.new_yn == 'N');

          if(delDataList.length > 0) {
            
            const formData = new FormData();
            formData.append("delDataList",JSON.stringify(delDataList))
            this.$http.post("/admin/deleteRelSite",formData).then((res) => {
              console.log(res.data);
              this.getRelSite('rel_org');
            }).catch(
              function (error) {
              console.log(error);
              }
            )
            
          } else {
            this.getRelSite('rel_org');
          }
        }
      }
    },
    pointColorGuideClicked() {
      // alert("포인트 색상 가이드 클릭됨 -> 이미지 새창 띄우기 예정");
      let imageUrl = '';
      if(this.mainPageType == 'A')      imageUrl = require("@/assets/images/point_color_guide_a.png");
      else if(this.mainPageType == 'B') imageUrl = require("@/assets/images/point_color_guide_b.png");
      else if(this.mainPageType == 'C') imageUrl = require("@/assets/images/point_color_guide_c.png");
      else if(this.mainPageType == 'D') imageUrl = require("@/assets/images/point_color_guide_d.png");

      window.open(imageUrl, '_blank');
    },

    // 공지박스 메뉴 연결 삭제
    deleteNtceBoxMenuLink(loc) {
      if(loc == "L1") {
        this.ntceBoxL1MenuSno = '';
      } else if(loc == "L2") {
        this.ntceBoxL2MenuSno = '';
      } else if(loc == "R1") {
        this.ntceBoxR1MenuSno = '';
      } else if(loc == "R2") {
        this.ntceBoxR2MenuSno = '';
      } else if(loc == "M0") {    // 메인 이미지 공지 메뉴
        this.mainImgNtceMenuSno = '';
      }
    },
    mypageChanged() {
      console.log(this.myPageMap);
    },
    fontColorPickerClicked(no){
      this.fontColorPickNo = no;
      this.fontColor = this.mainPointColor[`font_color_`+no];  //눌렀을때 색상위치로 초기화
      this.fontColorPickerPop = true;
    },
    selFontColorClicked() {
      this.mainPointColor[`font_color_`+this.fontColorPickNo] = this.fontColor;
      console.log(this.mainPointColor);
      this.fontColorPickerPop = false;
      this.itemChanged();
    },//selFontColorClicked end
  }
}
</script>