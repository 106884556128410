import { render, staticRenderFns } from "./publcArtclList.vue?vue&type=template&id=6b2a2d60&scoped=true&"
import script from "./publcArtclList.vue?vue&type=script&lang=js&"
export * from "./publcArtclList.vue?vue&type=script&lang=js&"
import style0 from "./publcArtclList.vue?vue&type=style&index=0&id=6b2a2d60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2a2d60",
  null
  
)

export default component.exports