<template>
  <v-container>
    <v-row>
      <v-col cols="8" class="mt-5 mr-0 ml-0 pt-5 pb-5 pr-0 pl-0">
        <span class="fontSize-6 font-weight-bold"> {{ ttl+` `+vol }}</span>
      </v-col>
      <v-col cols="4" class="d-flex justify-end mt-5 pt-5">
        <v-btn 
          outlined 
          class="mr-2"
          @click="pubPageMoveClicked"
        >
          전체목록
        </v-btn>
        <v-btn 
          outlined
          @click="pubListPageMoveClicked"
        >
          Vol. 목록
        </v-btn>
      </v-col>
    </v-row>

    <v-container v-for="(item, idx) in pubArtclList" :key="`list_`+idx">
      <v-row style="">
        <v-col cols="12" class="subTitle pa-0 mt-13" style="height: 100%; margin-bottom: 10px;">
          <span class="fontSize-5 pl-3" style="font-weight: 500;">{{item.ttl}}</span>
          <a :href="item.file_path" :download="item.orgnl_file_nm"> 
            <v-icon 
              size="1.9em"
              color="red" 
              @click="pdfDownClicked(item)"
              style = "margin-left: 6px;  padding-bottom: 3px;" 
            >mdi-file-pdf-box /* 아이콘 크기 나중에 만든다면 width는 50, height은 25*/
            </v-icon>
          </a>
        </v-col>
        <v-col 
          cols="12" 
          class="border-b-grey2 pa-0 mr-0 ml-0 mt-0" 
          style="height: 0px !important; margin-bottom: 8px;"></v-col>
      </v-row>

      <v-row>
        <v-col cols="2" class="pl-0">
          <div class="border-b-grey2 pb-3 pt-2 pr-0 pl-0">
            <span class="text-grey2">저자명</span>
          </div>
        </v-col>
        <v-col cols="10" class="pr-0">
          <div class="border-b-grey pb-3 pt-2">
            <span>{{item.athor ? item.athor : '-'}}</span>
          </div>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="2" class="pl-0">
          <div class="border-b-grey2 pb-3 pt-2">
            <span class="text-grey2">발행사</span>
          </div>
        </v-col>
        <v-col cols="10" class="pr-0">
          <div class="border-b-grey pb-3 pt-2">
            <span>{{item.publcr ? item.publcr : '-'}}</span>
          </div>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="2" class="pl-0">
          <div class="border-b-grey2 pb-3 pt-2">
            <span class="text-grey2">수록사항</span>
          </div>
        </v-col>
        <v-col cols="10" class="pr-0">
          <div class="border-b-grey pb-3 pt-2">
            <span>{{ item.book_ttl ? item.book_ttl : ``}}</span> <span>{{ item.vol+` (`+item.publc_dt+`)`}}</span>
          </div>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="2" class="pl-0">
          <div class="border-b-grey2 pb-3 pt-2">
            <span class="text-grey2">페이지</span>
          </div>
        </v-col>
        <v-col cols="10" class="pr-0">
          <div class="border-b-grey pb-3 pt-2">
            <span>{{item.bgn_page ? `시작페이지(`+item.bgn_page+`)` : '-'}}</span>
          </div>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="2" class="pl-0">
          <div class="border-b-grey2 pb-3 pt-2">
            <span class="text-grey2">ISSN</span>
          </div>
        </v-col>
        <v-col cols="10" class="pr-0">
          <div class="border-b-grey pb-3 pt-2">
            <span>{{item.issn ? item.issn : '-'}}</span>
          </div>
        </v-col>
      </v-row>
      
      <v-row v-if="item.outl">
        <v-col cols="12">
          <span class="fontSize-4 font-weight-bold"> 요약 </span>
        </v-col>
        <v-col cols="12">
          <div class="tiny" v-html="item.outl"></div>
        </v-col>
      </v-row>
    </v-container>

    <v-row>
      <v-col cols="12" class="pa-0 mt-2 mb-11"></v-col>
    </v-row>
    
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      basicUri: '',
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      lang: this.$session.get("lang") ? this.$session.get("lang") : '',
      emitData: {},
      yy: this.$route.query.yy ? this.$route.query.yy : '',
      vol: this.$route.query.vol ? this.$route.query.vol : '',
      sno: this.$route.query.sno ? this.$route.query.sno : [],
      ttl: this.$route.query.ttl ? this.$route.query.ttl : '',
      snoList: [],
      pubArtclList: [],
    }
  },
  created() {
    let path = this.$route.fullPath;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);

    if(!this.yy || !this.vol || !this.sno) {
      alert('잘못된 접근입니다.');
      this.$router.push(this.basicUri)
      return;
    }
    
    console.log(this.sno);
    this.getPubDetailPage();

  },
  methods: {
    pubPageMoveClicked() {
      this.$router.push(this.basicUri);
    },//pubPageMoveClicked end
    pubListPageMoveClicked() {
      this.$router.push(this.basicUri+'/list?yy='+this.yy+'&vol='+this.vol+'&ttl='+this.ttl);
    },//pubPageMoveClicked end
    getPubDetailPage() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("non_mber_yn", this.nonMberYn);
      
      if(typeof this.sno == 'string') {
        this.snoList.push(this.sno);
      } else {
        this.snoList = [
          ...this.sno
        ]
      }
      params.append("snoList", JSON.stringify(this.snoList));
      

      this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
        console.log(res.data);
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.$router.push("/");
        } else {
          console.log(res.data);
          // console.log("pubArtclList",res.data.pubArtclList);

          const pubArtclList = res.data.pubArtclList;
          pubArtclList.forEach((e) => {
            if(e.artcl_ttl && e.artcl_ttl_en) {
              e.ttl = e.artcl_ttl + ' / '+e.artcl_ttl_en;
            } else if(e.artcl_ttl) {
              e.ttl = e.artcl_ttl;
            } else if(e.artcl_ttl_en) {
              e.ttl = e.artcl_ttl_en;
            }

            if(this.lang == 'en') {
              e.outl = e.artcl_outl_en;
            } else {
              e.outl = e.artcl_outl;
            }
          });
          this.pubArtclList = pubArtclList;

          this.emitData.menu_sno = res.data.menu_sno;
          this.$emit('setData', this.emitData);
          
        }
      })
        .catch(function (error) {
          console.log(error);
        }
      );
    },//getPubDetailPage end
    pdfDownClicked(item) {
      const filename = item.orgnl_file_nm;
      const element = document.createElement('a');
      element.setAttribute('href', 'data:image/*;charset=utf-8, ' +encodeURIComponent(item.file_path));
      element.setAttribute('download', filename);
      document.body.appendChild(element);
      element.click();
    },//imgViewClicked end
  }
}
</script>

<style socped>
.subTitle{
  border-left:5px solid #2E9AFE;
}
</style>