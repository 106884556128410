<template>
  <v-container fluid>
    <v-row class="pa-3">
      <v-col cols="12">
        <!-- <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="goBack"
          >뒤로가기</v-btn>
        </div> -->
        <!-- <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn> -->
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNStayBtnClicked"
          >
            저장 후 유지
          </v-btn>
        <!-- <div style="float:right;">
          <v-btn
            small
            color="red lighten-2"
            class="white--text mr-2"
          >학회삭제</v-btn>
        </div> -->
      </v-col>

    </v-row>
    <v-tabs
      v-model="tab"
      height="40px"
      background-color="blue lighten-5">
      <v-tab
        v-for="item of tabList"
        :key="item.tabNm"
        exact
        class="fontSize-0">
        {{ item.tabNm }}
        <v-badge 
          v-show="item.changed"
          color="red"
          class="pb-2"
          dot>
        </v-badge>
      </v-tab>
      <v-tabs-items 
        v-model="tab">
        <v-divider></v-divider>
        <v-tab-item
          v-for="item in tabList"
          :key="item.tabNm"
          class="pa-3"> 
          <component v-bind:is="item.content" :ref="item.content" class="w-100" @tabChanged="tabChanged"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import billTab from '@/components/admin/tabs/certification/billTab.vue';
import certificationLetterTab from '@/components/admin/tabs/certification/certificationLetterTab.vue';
import commonCertiTab from '@/components/admin/tabs/certification/commonCertiTab.vue';


export default {
  components: {
    billTab, certificationLetterTab, commonCertiTab
  },
  data(){
    return {
      tab: null,
      tabList: [
        { tabNm: '공통', content: 'commonCertiTab', changed: false},
        { tabNm: '청구서', content: 'billTab', changed: false},
        { tabNm: '증명서', content: 'certificationLetterTab', changed: false},
      ],
      
      saveBtnEnabled: false,
    }
  },
  created() {
    this.tabList.forEach((e) => {
      e.changed = false;
    })
  },
  methods: {
    // goBack(){
    //   this.$router.go(-1)
    // },
    tabChanged(tabId) {
      console.log("society tabChanged:", tabId);
      this.tabList.filter((e) => e.content == tabId).map((f) => {
        f.changed = true;
      })

      this.saveBtnEnabled = true;
      // console.log("tabList:", this.tabList);     
    },
    saveNStayBtnClicked() {
      if(confirm("저장하시겠습니까?")) {
        const filtered = this.tabList.filter(e => e.changed);
        // console.log("filtered:", filtered);
        // 입력 데이터 검증
        let isValid = true;
        filtered.some((e) => {
          if(e.content == "commonCertiTab") {
            isValid = this.$refs.commonCertiTab[0].validateInput();
          }
          if(!isValid) {
            return true;
          }
        });
        if(!isValid) {
          return;
        } else {
          filtered.forEach((e) => {
            if(e.content == "commonCertiTab") {
              this.$refs.commonCertiTab[0].saveBtnClicked();
            } else if(e.content == "billTab") {
              this.$refs.billTab[0].saveBtnClicked();
            } else if(e.content == "certificationLetterTab") {
              this.$refs.certificationLetterTab[0].saveBtnClicked();
            }
          });     
        }
      }
      this.tabList.forEach((e) => {
        e.enabled = true;
        e.changed = false;
      });

      this.saveBtnEnabled = false;
      alert('저장되었습니다.');
    },
  }
}
</script>

<style>

</style>