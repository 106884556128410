<template >
  <v-app class="bg-main">
    <header-a/>
    
    <v-container fluid class="hei-150p d-flex align-end bgImg-cover" style="background-image:url('https://img.freepik.com/free-vector/unfocussed-winter-background-with-snowflakes_1393-271.jpg');">
      <template>
        <v-container class=" hei-130p d-flex align-end" :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
          <v-row class="hei-130p ma-0" v-show="windowWidth > 1220">
            <v-col cols="2" class="bg-alpha-indigo d-flex justify-center align-center">
              <div class="pb-2 white--text">
                <span><h1>마이페이지</h1></span>
              </div>
            </v-col>
            <v-col cols="10">
              <v-row class="hei-130p pa-0 pl-5" align="center">
                <v-col cols="4" class="d-felx align-center ">
                  <div class="pb-2">
                    <span v-if="$route.name == 'profile'"><h2>프로필</h2></span>
                    <span v-if="$route.name == 'payList'"><h2>결제</h2></span>
                    <span v-if="$route.name == 'certificate'"><h2>청구서/납부확인서/증명서</h2></span>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <div class="pb-2 float-right" v-show="cateYn">
                    <span v-if="$route.name == 'profile'">Home / 마이페이지 / 프로필</span>
                    <span v-if="$route.name == 'payList'">Home / 마이페이지 / 결제</span>
                    <span v-if="$route.name == 'certificate'">Home / 마이페이지 / 청구서/납부확인서/증명서</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- [[[[[[  tablet   ]]]]]] -->
          <v-row class="hei-130p ma-0" v-show="windowWidth < 1221 && windowWidth > 768">
            <v-col cols="12" class="pa-0 ma-0">
              <v-row class="hei-130p pa-0 pl-5" align="center" dense>
                <v-col cols="12" class=" pa-0 ma-0">
                  <div class="d-flex justify-center">
                    <!-- <span><h1 class="pa-0">프로필</h1></span> -->
                    <span v-if="$route.name == 'profile'"><h1>프로필</h1></span>
                    <span v-if="$route.name == 'payList'"><h1>결제</h1></span>
                    <span v-if="$route.name == 'certificate'"><h1>청구서/납부확인서/증명서</h1></span>
                  </div>
                  <div class="d-flex justify-center pt-3" v-show="cateYn">
                    <span v-if="$route.name == 'profile'">Home / 마이페이지 / 프로필</span>
                    <span v-if="$route.name == 'payList'">Home / 마이페이지 / 결제</span>
                    <span v-if="$route.name == 'certificate'">Home / 마이페이지 / 청구서/납부확인서/증명서</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- [[[[[[  mobile   ]]]]]] -->
          <v-row class="hei-130p ma-0" :style="`color:`+ this.font_color_1+`;`" v-show="windowWidth < 767 && windowWidth > 350">
            <v-col cols="12" class="pa-0 ma-0">
              <v-row class="hei-130p pa-0 pl-3 ma-0 " align="center" dense>
                <v-col cols="12" class="pa-0 ma-0">
                  <div class="wid-100">
                    <div class="d-flex justify-center" style="margin: auto;">
                      <h1 v-if="$route.name == 'profile'"  class="pa-0 menuWord" style="text-align: left;">프로필</h1>
                      <h1 v-if="$route.name == 'payList'"  class="pa-0 menuWord" style="text-align: left;">결제</h1>
                      <h1 v-if="$route.name == 'certificate'"  class="pa-0 menuWord" style="text-align: left;">청구서/납부확인서/증명서</h1>
                    </div>
                    <div class="pt-3 d-flex justify-center" style="margin: auto;" v-show="cateYn" >
                      <span v-if="$route.name == 'profile'" class="pa-0 menuWord">Home / 마이페이지 / 프로필</span>
                      <span v-if="$route.name == 'payList'" class="pa-0 menuWord">Home / 마이페이지 / 결제</span>
                      <span v-if="$route.name == 'certificate'" class="pa-0 menuWord">Home / 마이페이지 / 청구서/납부확인서/증명서</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>

    <v-container fluid class=" hei-90vh-auto d-flex bg-white">
      <template>
        <v-container class=" hei-90vh-auto d-flex " :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
          <v-row class="hei-90vh-auto mt-0 bg-white ma-0" style="max-width: 1200px !important;" v-show="windowWidth > 1220" >
            <v-col cols="2" class="bg-menugrey pa-0">
              <v-list 
                class="pa-0 ma-0"
                tile>
                <v-list-group
                  no-action
                  class="menu-border"
                  active-class="btn-active-blue"
                  v-for="(item, idx) in cateList"
                  :key="`cate_`+idx"
                  v-model="item.openYn"
                  @click="clickTitle(item.urlPath)"
                >
                  <template v-slot:activator>
                    <v-list-item-content class="hei-60p" >
                      <div class="d-flex align-center">
                        <h3>{{item.title}}</h3>
                      </div>
                    </v-list-item-content>
                  </template>
                </v-list-group>
              </v-list>
            </v-col>
            <v-col cols="10" class="pl-7 pt-7 pb-7">
              <router-view :key="$route.fullPath"/>
            </v-col>
          </v-row>

          <v-row class="hei-90vh-auto mt-0 bg-white ma-0" style="width:100%" v-show="windowWidth < 1221">
            <v-col cols="12" class="">
              <router-view :key="$route.fullPath"/>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>
    
    <main-footer/>
  </v-app>
</template>

<script>
import mainFooter from '@/components/main/footer/footer'
import headerA from '@/components/main/header/headerA.vue'
export default {
  name: 'App',
  components:{
    mainFooter ,
    headerA
  },
  data(){
    return {
      windowWidth: window.innerWidth,
      BtnIcon_1:true,
      menuList: [],
      cateYn: true,
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      cateList: [
        {title: '프로필', openYn: false, urlPath: '/mypage/profile' , catePath: '/mypage/profile' , odr: 1} ,
        {title: '결제', openYn: false , urlPath: '/mypage/payList' , catePath: '/mypage/payList', odr: 2} ,
        {title: '청구서/납부확인서/증명서', openYn: false , urlPath: '/mypage/certificate'  , catePath: '/mypage/certificate', odr: 3} ,
      ],
    }
  },
  watch: {
    '$route' () {
      this.selectMenuList();
    }
  },
  computed: {
    
  },
  created() {
    if(!this.mberSno) {
      alert('로그인 이후 사용가능합니다.');
      this.$router.push('/login');
      return;
    }

    let catePath = this.$route.matched[1].path;
    // alert(catePath);
    this.cateList.forEach((e) => {
      
      if(e.catePath == catePath) {
        e.openYn = true;
      }
      
    })
    this.getMypageInfo();
    this.selectMenuList();
  },
  methods:{
    clickTest(){
      
      this.BtnIcon_1 = !this.BtnIcon_1;
    },
    selectMenuList() {
      this.$http.get("/admin/getMenuList").then((res) => {    
      console.log('menuList :: ',res.data.menuList);
        this.menuList = res.data.menuList;
        this.menuList = this.menuList.filter((e) => e.disp_yn == 'Y');
        
        this.menuList.forEach((e) => {
          e.openYn = false;
          if(e.page_type == 'static') {
            e.url_path = '/static'+e.url_path
          } else if(e.page_type == 'bbs_notc') {
            e.url_path = '/board/notice'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_glly') {
            e.url_path = '/board/gallery'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_free') {
            e.url_path = '/board/free'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_faq') {
            e.url_path = '/board/faq'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_qna') {
            e.url_path = '/board/qna'+e.url_path+'/list';
          } else if(e.page_type == 'past_exctv' || e.page_type == 'curr_exctv') {
            e.url_path = '/executive'+e.url_path;
          } else if(e.page_type == 'spnsr') {
            e.url_path = '/sponsor'+e.url_path
          } else if(e.page_type == 'socty_hist') {
            e.url_path = '/history'+e.url_path
          } else if(e.page_type == 'scdul') {
            e.url_path = '/schedule'+e.url_path
          } else if(e.page_type == 'cntt_us') {
            e.url_path = '/contactus'+e.url_path
          } else if(e.page_type == 'pay') {
            e.url_path = '/pay'+e.url_path+'/list'
          } else if(e.page_type == 'abstr_reg') {
            e.url_path = '/abstrReg'+e.url_path+'/list'
          } else if(e.page_type == 'pre_reg') {
            e.url_path = '/preReg'+e.url_path+'/list'
          } else if(e.page_type == 'confr_outl') {
            e.url_path = '/confrOutl'+e.url_path;
          } else if(e.page_type == 'confr_scdul') {
            e.url_path = '/confrScdul'+e.url_path;
          } else if(e.page_type == 'event_tour') {
            e.url_path = '/confrEventTour'+e.url_path;
          } else if(e.page_type == 'trfc_accom_guid') {
            e.url_path = '/confrTrfcAccom'+e.url_path;
          } else if(e.page_type == 'ad_disp_guid') {
            e.url_path = '/confrAdDisp'+e.url_path;
          } else if(e.page_type == 'url_link') {
            e.url_path = e.url_link;
          } else if(e.page_type == 'mber_srch') {
            e.url_path = '/memberSearch'+e.url_path;
          } else if(e.page_type == 'doc_srch') {
            e.url_path = '/docSearch'+e.url_path;
          } else if(e.page_type == 'publc') {
            e.url_path = '/public'+e.url_path;
          }
          
        });
        
        // this.menuLvl_1_Nm = this.menuList.filter((e) => e.menu_lvl == 1 && e.resrc_nm == this.menuLvl_1)[0].menu_nm;
        // this.menuLvl_1_sno = this.menuList.filter((e) => e.menu_lvl == 1 && e.resrc_nm == this.menuLvl_1)[0].menu_sno;
        
      }).catch((error) => {
          console.log(error);
        }
      )
    },//selectMenuList end
    // setData(dataMap) {
    //   this.menu_1_map = {};
    //   this.menu_2_map = {};
    //   this.menu_3_map = {};

    //   const menuData = this.menuList.filter((e) => e.menu_sno == dataMap.menu_sno)[0];
      
    //   this.menuNm = menuData.menu_nm;
    //   // alert(menuData.menu_nm);

    //   if(menuData.menu_lvl == 1) {

    //     this.menu_1_map = menuData;

    //   } else if(menuData.menu_lvl == 2) {

    //     this.menu_2_map = menuData;
    //     this.menuList.filter((e) => e.menu_sno == menuData.menu_sno)[0].openYn = true;
    //     this.menu_1_map = this.menuList.filter((e) => e.menu_sno == menuData.upper_menu_sno)[0];

    //   } else if(menuData.menu_lvl == 3) {

    //     this.menu_3_map = menuData;
    //     this.menu_2_map = this.menuList.filter((e) => e.menu_sno == menuData.upper_menu_sno)[0];
    //     this.menuList.filter((e) => e.menu_sno == menuData.upper_menu_sno)[0].openYn = true;
    //     const menu_2_upper = this.menuList.filter((e) => e.menu_sno == menuData.upper_menu_sno)[0].upper_menu_sno;
    //     this.menu_1_map = this.menuList.filter((e) => e.menu_sno == menu_2_upper)[0];

    //   }
      
    //   this.cateYn = true;
    // },//setData end
    menuLvl2Clicked(item) {
      console.log(item);
      if(!this.menuList.filter((e) => e.upper_menu_sno == item.menu_sno).length > 0) {
        this.$router.push(item.url_path);
      }
    },//menuLvl2Clicked end
    clickTitle(url) {
      this.$router.push(url);
    },//clickTitle end
    getMypageInfo() {
      this.$http.get("/mber/getMypageInfo").then((res) => { 
          // alert(JSON.stringify(res.data.myPageMap));
          const myPageMap = res.data.myPageMap;
          if(!myPageMap) {
            this.cateList = this.cateList.filter((e) => e.odr == 1);
          } else {
            if(myPageMap.pay_menu_use_yn == 'N') {
              this.cateList = this.cateList = this.cateList.filter((e) => e.odr != 2);
            }
            if(myPageMap.crtf_menu_use_yn == 'N') {
              this.cateList = this.cateList = this.cateList.filter((e) => e.odr != 3);
            }
          }
          
          
        }).catch((error) => {
            console.log(error);
          }
        )
    },//getMypageInfo
  }
}
</script>

<style scoped>
/deep/ .col-3{
  max-width: 250px !important;
}

.v-application--is-ltr /deep/ .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}

 .v-application--is-ltr /deep/ .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 20px !important;
}
</style>