<template>
  <v-app  class="bg-main wid-100" v-if="viewYn">
    <v-container fluid>
      <header-a class="gloHeaderA"/>
      <body-a class="gloBodyA"/>
      <footer-d class="gloFooterD"/>

    </v-container>
  </v-app>
</template>

<script>
import footerD from '@/components/main/footer/footer'
import headerA from '@/components/main/header/headerA.vue'
import bodyA from '@/components/main/body/bodyA.vue'
export default {
  name: 'App',
  components:{
    headerA, bodyA, footerD
  },
  data(){
    return{
      domain: window.location.hostname,
      viewYn: false,
    }
  },
  created() {
    if(this.domain == "ssms-admin.bgwell.co.kr") {
    // if(this.domain == "localhost") {
      this.$router.push('/admin');
    } else {
      this.viewYn = true;
    }
  },
}
</script>

<style>


</style>