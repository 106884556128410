<template>
  <v-container fluid class="">
    <v-row class="pa-0 mb-5 ma-0">
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          color="#000000"
          height="60px"
          class="mb-5 pa-0 fontSize-0 font-weight-bold"
          hide-slider
          show-arrows
          active-class="actHistory"
          v-if="page_div == 'past_exctv'">
          <v-tab
            v-for="(item,idx) in exctvGnrtnList"
            :key="`tabList_`+idx"
            class="tab-border wid-130p fontSize-0 pa-0 "
            :style="windowWidth > 1220 ? 'width:1200px' : 'width:100%'"
            @click="tabClicked(item.gnrtn_sno)">
            {{ item.gnrtn_nm }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" v-if="page_div == 'past_exctv'" class="pt-2">
          <v-tab-item
            v-for="(item,idx) in exctvGnrtnList"
            :key="`items_`+idx"
            class="pa-3">
            <v-container :key="redrawCount">
              <!-- <div>{{gnrtnTerm}}</div> -->
              <executive-detail
                v-for="(item, idx) in propParams" 
                :propParams="item"
                :key="idx"
              />
            </v-container>
          </v-tab-item>
        </v-tabs-items>

        <v-container :key="redrawCount" v-if="page_div == 'curr_exctv'">
          <!-- <div>{{gnrtnTerm}}</div> -->
          <executive-detail
            v-for="(item, idx) in propParams" 
            :propParams="item"
            :key="idx"
          />
        </v-container>

        
      </v-col>
    </v-row>

    
  </v-container>
</template>

<script>
import executiveDetail from "@/components/subPage/executiveDetail";
export default {
  components:{
    executiveDetail
  },
  data () {
      return {
        windowWidth: window.innerWidth,
        tab: 0,
        exctvGnrtnList : [],
        gnrtnSno: 1,
        mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
        nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
        emitData: {},
        exctvList: [],
        chrmnMap: {},
        vchrmnList: [],
        drctrList: [],
        audtrList: [],
        gnrtnTerm: '',
        propParams: [],
        propDataMap: {},
        redrawCount: 0,
        page_div: '',
      } 
  },
  mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    },
  created() {
    const params = new FormData();
    params.append("mber_sno", this.mberSno);
    params.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
      console.log(res.data);
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push('/');
      } else {
        console.log(res.data);
        this.page_div = res.data.page_div;

        //역대임원진인 경우
        if(this.page_div == 'past_exctv') {

          this.exctvGnrtnList = res.data.exctvGnrtnList;
          if(this.exctvGnrtnList.length > 0) {
            this.gnrtnTerm = this.exctvGnrtnList[0].gnrtn_term;
          } 
          
        }
        
        this.exctvList = res.data.exctvList;
        
        //회장
        // this.chrmnMap = this.exctvList.filter((e) => e.exctv_grd == 'chrmn')[0];
        // //부회장
        // this.vchrmnList = this.exctvList.filter((e) => e.exctv_grd == 'vchrmn');
        // //이사
        // this.drctrList = this.exctvList.filter((e) => e.exctv_grd == 'drctr');
        // //감사
        // this.audtrList = this.exctvList.filter((e) => e.exctv_grd == 'audtr');

        this.propParams = res.data.oneLineDspList;

        // console.log("this.propParams" , this.propParams);
        if(this.exctvGnrtnList.length > 0) {
          this.tabClicked(this.exctvGnrtnList[0].gnrtn_sno);
        }
        this.emitData.menu_sno = res.data.menu_sno;
        this.$emit('setData', this.emitData);
      }
      
    })
    .catch(function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    tabClicked(gnrtnSno) {
      const param = new FormData();
      param.append("gnrtn_sno",gnrtnSno);
      if(this.page_div == 'past_exctv') {
        param.append("past_disp_yn",'Y');
      }

      this.$http.post("/sub/getExecutiveList",param).then((res) => {  
        // this.exctvList = [];     
        this.exctvList = res.data.exctvList;
        console.log("this.exctvList ;;;  ",this.exctvList);

        this.gnrtnTerm = this.exctvGnrtnList.filter((e) => e.gnrtn_sno == gnrtnSno)[0].gnrtn_term;

        this.propParams = res.data.oneLineDspList;
        this.redrawCount++;

      }).catch((error) => {
          console.log(error);
        }
      )
    },//tabClicked end
  },
}
</script>

<style scoped>
.v-tabs-bar {
    border-radius: inherit;
    height: 70px;
}
.v-slide-group__next, .v-slide-group__prev {
  border: #D8D8D8 solid 0.5px !important;
}

.actHistory{
  color: #ffffff !important;
  background-color: #173485 !important;
}
</style>