<template>
  <v-container fluid class="pl-3 pr-3 pt-0">
    <v-row class="pa-0 pt-2">
      <v-spacer></v-spacer>
      <v-col cols="4" align="right" class="pa-0" v-show="windowWidth > 767">
        <v-btn
          small
          color="blue lighten-2"
          class="elevation-0 bg-lightgrey font-weight-bold mr-2 white--text"
          @click="delBtnClicked"
          v-if="chg_perm_yn == 'Y' && mberSno == notiBbsMap.reg_mber_sno && page_type == 'bbs_free'">
          삭제
        </v-btn>
        <v-btn
          small
          color="blue lighten-2"
          class="elevation-0 bg-lightgrey font-weight-bold mr-2 white--text"
          @click="modifyBtnClicked"
          v-if="chg_perm_yn == 'Y' && mberSno == notiBbsMap.reg_mber_sno">
          수정
        </v-btn>
        <v-btn
          small
          outlined
          class="elevation-0 bg-lightgrey font-weight-bold"
          @click="listPageClicked">
          목록
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row class="">
      <v-col cols="12" class="pa-0 pt-1">
        <span
          style="font-size: 1.5em; margin-left: 12px;"
          class="font-weight-bold dreamFont4" :class="windowWidth>768 ? 'detailTitleWord': 'mobDetailTitleWord'">{{notiBbsMap.bbst_ttl}}</span>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px pa-0"></v-col>
    </v-row>
    
    <v-row class="mb-3">
      <v-col cols="12" md="3" sm="3" class="pt-2 pb-2">
        <span class="mr-2 dreamFont3">등록인 :</span><span>{{notiBbsMap.reg_mber_nm}}</span>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="pt-2 pb-2">
        <span class="mr-2">등록일시 :</span><span>{{notiBbsMap.reg_dtm}}</span>
      </v-col>
      <v-col cols="12" md="2" sm="2" class="pt-2 pb-2">
        <span class="mr-2">조회수 :</span><span>{{notiBbsMap.read_cnt}}</span>
      </v-col>
      <!-- [[   카테고리   ]] -->
      <v-col cols="12" md="3" sm="3" class="pt-2 pb-2" v-if="this.notiBbsMap.bbst_catg">
        <span class="mr-2">카테고리 :</span><span>{{notiBbsMap.bbst_catg}}</span>
      </v-col>
      <div class="wid-100 border-b-grey"></div>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <div style="height:500px;overflow:scroll;" class="tiny" v-html="notiBbsMap.bbst_cn"></div>
      </v-col>
      <v-col cols="12" class="border-b-grey"></v-col>
    </v-row>
    <v-row class="mt-4 pb-4" v-for="(item, idx) in fileAtchList" :key="`fileAtch_`+idx">
      <v-col cols="12" class="ma-0 pa-0">
        {{item.orgnl_file_nm}}
        <a :href="item.save_path+item.save_file_nm" :download="item.orgnl_file_nm">
          <v-btn
            small
            icon
            dense
            color="blue lighten-2"
            class="white--text ma-0 pa-0"
          ><v-icon>mdi-cloud-arrow-down-outline</v-icon></v-btn>
        </a>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" class="ma-0 pa-0 border-b-darkgrey">
      </v-col>
    </v-row>
    
    <!-- 댓글영역 -->
    <!-- <v-row class="pa-0 pb-4 border-b-grey" align="center" v-if="replyList.length > 0">
      <v-col 
        cols="12" 
        class="pa-0 ma-0 d-flex " 
        v-for="(item, idx) in replyList"
        :key="`reply_`+idx"
      >
        <div class="wid-100 d-flex" style="align:center !important;" >
          <div class="pr-3">
            <v-icon class="color-lightblue">mdi-arrow-right-bottom</v-icon>
          </div>
          <div 
            class="align-center pt-2" 
          >
            <div class="pb-3 d-flex"><span><h4>{{item.reg_mber_nm}}</h4></span><span class="ml-10">{{item.reg_dtm}}</span></div>
            <span>
              {{item.reply_cn}}
            </span>
          </div>
        </div>
        <div class="wid-100 d-flex ml-3" style="align:center !important;" >
          <div class="pr-3">
            <v-icon class="color-lightblue">mdi-arrow-right-bottom</v-icon>
          </div>
          <div 
            class="align-center pt-2" 
          >
            <div class="pb-3 d-flex"><span><h4>{{item.reg_mber_nm}}</h4></span><span class="ml-10">{{item.reg_dtm}}</span></div>
            <span>
              {{item.reply_cn}}
            </span>
          </div>
        </div>
      </v-col>
    </v-row> -->

    <v-row class="pa-0 pt-5 pb-5 border-b-darkgrey" v-if="mberSno && chg_perm_yn == 'Y' && notiBbsMap.page_type == 'bbs_free'">
      <v-col cols="12" class="pa-0">
        <v-textarea
          hide-details
          outlined
          rows="2"
          label="댓글 달기"
          class="ma-0 pa-0"
          v-model="reply_cn"
        >
        </v-textarea>
      </v-col>
      <v-col cols="12" class="pa-0 pt-2">
        <div class="wid-100 d-flex justify-end">
          <v-btn 
            outlined
            class="bg-lightgrey"
            @click="rplySaveClicked"
          >
            댓글 등록
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- [[[[[[[[[[[ 대댓글 sample]]]]]]]]]]] -->
    <v-row class="pa-0 pb-4 border-b-grey" align="center" :key="redrawConunt" v-if="notiBbsMap.page_type == 'bbs_free'">
      <v-col cols="12" class="pa-0 ma-0 pb-3 pt-3">
        <p style="font-size: 1.5em; margin-left: 12px;" class="font-weight-bold dreamFont4">댓글</p>
      </v-col>
      <v-col 
        cols="12" 
        class="ma-0">
        <v-row>
          <!-- <v-col 
            cols="11" 
            v-for="(item, idx) in replyList"
            :key="`reply_`+idx"
          >
            <div class="wid-100 d-flex" style="align:center !important;" >
              <div class="align-center pt-2">
                <div class="pb-3 d-flex"><span><h4>{{item.reg_mber_nm}}</h4></span><span class="ml-10">{{item.reg_dtm}}</span></div>
                <span>
                  {{item.reply_cn}}
                </span>
              </div>
            </div>
          </v-col> -->
          
          <v-col 
            cols="12" 
            class="pa-0 ma-0 border-t-grey"
            v-for="(item, idx) in replyList"
            :key="`reply_`+idx"
          >
              
            <v-row class="ma-0 pt-3" dense align="center">
              <v-col cols="1" class="pa-0">
                <span class="font-weight-bold">{{item.reg_mber_nm}}</span>
              </v-col>
              <v-col cols="2" class="pa-0">
                등록일 : <span class="">{{item.reg_dtm}}</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col 
                cols="2" 
                class="pa-0"
                v-if="item.reg_mber_sno == mberSno"
              >
                <div class="float-right">
                  <v-btn
                    x-small 
                    outlined
                    fab 
                    class="elevation-0"
                    v-if="!item.upd_yn"
                    @click="rplyModifyBtnClicked(item)"
                  >
                    <V-icon>mdi-pencil-outline</V-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    outlined
                    fab 
                    class="elevation-0 ml-2"
                    v-if="item.upd_yn"
                    @click="rplyModifyClicked(item)"
                  >
                    <V-icon>mdi-content-save-outline</V-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    outlined
                    fab 
                    class="elevation-0 ml-2"
                    v-if="item.upd_yn"
                    @click="rplyCloseClicked"
                  >
                    <V-icon >mdi-close</V-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    outlined
                    fab 
                    class="elevation-0 ml-2"
                    v-if="!item.upd_yn"
                    @click="rplyTrashClicked(item)"
                  >
                    <V-icon>mdi-trash-can-outline</V-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" class="pb-3 pt-2 pa-0" v-if="!item.upd_yn">
                <span>{{item.reply_cn}}</span>
              </v-col>
              <!-- 수정버튼 누르면 보임 -->
              <v-col cols="12" class="border-b-grey pb-3 pt-2 pa-0" v-if="item.upd_yn">
                <v-textarea
                  hide-details
                  :outlined="item.upd_yn"
                  v-model="item.reply_cn"
                  rows="2">
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <!-- <v-row class="pa-0 pb-4 pt-5 border-b-grey" align="center" >
      <v-col cols="12" class="pa-0 pb-3">
        <h2>댓글</h2>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0">
        <v-row class="ma-0" dense align="center">
          <v-col cols="1" class="">
            <span class="font-weight-bold">이름</span>
          </v-col>
          <v-col cols="1" class="">
            <span class="">날짜</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="">
            <div class="float-right">
              <v-btn
                x-small 
                outlined
                fab 
                class="elevation-0"
                >
                <V-icon>mdi-pencil-outline</V-icon>
              </v-btn>
              <v-btn
                x-small
                outlined
                fab 
                class="elevation-0 ml-2">
                <V-icon>mdi-content-save-outline</V-icon>
              </v-btn>
              <v-btn
                x-small
                outlined
                fab 
                class="elevation-0 ml-2"
              >
                <V-icon >mdi-close</V-icon>
              </v-btn>
              <v-btn
                x-small
                outlined
                fab 
                class="elevation-0 ml-2">
                <V-icon>mdi-trash-can-outline</V-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <span>내용</span>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-textarea
              hide-details
              outlined
              value="기존내용"
              rows="2">
            </v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->

    <!-- 이전글, 다음글 영역 -->
    <v-row class="pa-0 border-b-darkgrey mb-1">
      <v-col 
        cols="12" 
        class="pt-5 pb-5 border-b-grey" 
        v-if="nextBbs">
        <div class="wid-100">
          <span style="margin-right: 12px;">다음글</span>
          <a><span class="font-weight-bold" @click="movePage(nextBbs.url_path)">{{nextBbs.bbst_ttl}}</span></a>
        </div>
      </v-col>
      <v-col 
        cols="12" 
        class="pt-5 pb-5" 
        v-if="preBbs">
        <div class="wid-100">
          <span style="margin-right: 12px;">이전글</span>
          <a><span class="font-weight-bold" @click="movePage(preBbs.url_path)">{{preBbs.bbst_ttl}}</span></a>
        </div>
      </v-col>
      
    </v-row>
    <v-row class="pb-2" v-show="windowWidth > 767">
      <v-col cols="12" class="pa-0">
        <div class="wid-100 d-flex justify-end">
          <v-btn 
            outlined
            class="bg-lightgrey"
            @click="listPageClicked"
          >
            목록
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      notiBbsMap: {},
      preUrl: '',
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      fileAtchList: [],
      chg_perm_yn: '',
      basicUri: '',
      bbst_sno: this.$route.query.bbst_sno,
      preBbs: {},
      nextBbs: {},      
      replyList: [],
      redrawConunt: 0,
      reply_cn: '',
      emitData: {},
      page_type: '',
      pageNo: this.$route.query.pageNo ? this.$route.query.pageNo : '',
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    // alert(this.$route.query.bbst_sno);
    let path = this.$route.path;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    this.preUrl = this.basicUri+'/list?pageNo='+this.pageNo;

    const params = new FormData();
    params.append("mber_sno", this.mberSno);
    params.append("bbst_sno", this.bbst_sno);
    params.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
      console.log(res.data);
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push(this.preUrl);
      } else {
        this.notiBbsMap = res.data.notiBbsMap;
        this.page_type = this.notiBbsMap.page_type;
        if(this.notiBbsMap.bbst_catg) {
          this.notiBbsMap.bbst_ttl = '['+this.notiBbsMap.bbst_catg+'] '+this.notiBbsMap.bbst_ttl;
        }

        this.fileAtchList = res.data.fileAtchList;
        this.chg_perm_yn = res.data.chg_perm_yn;

        this.preBbs = res.data.preBbs;
        
        
        if(this.preBbs) {
          this.preBbs.url_path = this.basicUri+'/detail?bbst_sno='+this.preBbs.bbst_sno;
          if(this.preBbs.bbst_catg) {
            this.preBbs.bbst_ttl = '['+this.preBbs.bbst_catg+'] '+this.preBbs.bbst_ttl;
          }
        }
        
        this.nextBbs = res.data.nextBbs;

        if(this.nextBbs) {
          this.nextBbs.url_path = this.basicUri+'/detail?bbst_sno='+this.nextBbs.bbst_sno;
          if(this.nextBbs.bbst_catg) {
            this.nextBbs.bbst_ttl = '['+this.nextBbs.bbst_catg+'] '+this.nextBbs.bbst_ttl;
          }
        }
        if(res.data.replyList) {
          this.replyList = res.data.replyList;
          this.replyList.forEach((e) => {
            e.upd_yn = false;
          })
        }

      }
      this.emitData.menu_sno = this.notiBbsMap.menu_sno;
      this.$emit('setData', this.emitData);

    })
    .catch(function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    listPageClicked() {
      this.$router.push(this.preUrl);
    },//listPageClicked end
    modifyBtnClicked() {
      this.$router.push(this.basicUri+'/modify?menu_sno='+this.notiBbsMap.menu_sno+'&bbst_sno='+this.bbst_sno);
      
    },//modifyBtnClicked end
    movePage(urlPath) {
      this.$router.push(urlPath);
    },//movePage end
    rplyModifyBtnClicked(item) {
      
      item.upd_yn = true;
      this.redrawConunt++;
    },//rplyModifyBtnClicked end
    rplyCloseClicked() {
      this.getBoardReplyList();
    },//rplyCloseClicked end
    rplyTrashClicked(item) {
      if(confirm('선택하신 댓글 삭제하시겠습니까?')) {
        
        const params = new FormData();
        params.append("mber_sno", this.mberSno);
        params.append("bbst_sno", this.bbst_sno);
        params.append("reply_sno", item.reply_sno);

        this.$http.post('/bbs/deleteBbsReply', params).then((res) => {
          
          console.log(res.data);

          if(res.data.code < 0 ) {
            alert(this.$erroCdMsg(res.data.code));
          } else {
            alert('삭제되었습니다.');
          }
          this.getBoardReplyList();
          
        })
        .catch(function (error) {
            console.log(error);
          }
        );

        
        
      }
    },//rplyTrashClicked end
    getBoardReplyList() {

      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("bbst_sno", this.bbst_sno);
      this.$http.post('/bbs/getBoardReplyList', params).then((res) => {
        console.log(res.data);
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.$router.push(this.preUrl);
        } else {
          if(res.data.replyList) {
            this.replyList = res.data.replyList;
            this.replyList.forEach((e) => {
              e.upd_yn = false;
            })
          }
        }
        
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//getBoardReplyList end
    rplyModifyClicked(item) { //댓글 수정
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("bbst_sno", this.bbst_sno);
      params.append("rplyMap", JSON.stringify(item));
      this.$http.post('/bbs/udpateBbsReply', params).then((res) => {
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          return;
        } else {
          alert('댓글 수정되었습니다.');
          this.getBoardReplyList();
        }
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//rplyModifyClicked end
    rplySaveClicked() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("bbst_sno", this.bbst_sno);
      params.append("reply_cn", this.reply_cn);
      this.$http.post('/bbs/insertBbsReply', params).then((res) => {
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          return;
        } else {
          alert('댓글 등록되었습니다.');
          this.reply_cn = '';
          this.getBoardReplyList();
        }
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//rplyModifyClicked end
    delBtnClicked() {
      if(confirm('해당 게시글을 삭제 하시겠습니까?')) {
        const params = new FormData();
        params.append("mber_sno", this.mberSno);
        params.append("bbst_sno", this.bbst_sno);

        this.$http.post('/bbs/updateBbsDelYn', params).then((res) => {
          if(res.data.code < 0) {
            alert(this.$erroCdMsg(res.data.code));
            return;
          } else {
            alert('삭제되었습니다.');
            this.$router.push(this.preUrl);
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },//delBtnClicked end
  }
}
</script>

<style scoped>

.detailTitleWord {
  display:inline-block; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  word-break: break-all;
  max-width:850px;
}

.mobDetailTitleWord {
  display:inline-block; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  word-break: break-all;
  max-width:440px;
}

.dreamFont3 {
  font-family: dreamFont3 !important;
}

.dreamFont4 {
  font-family: dreamFont4 !important;
}

::v-deep .v-btn__content {
  font-family: dreamFont3 !important;
}

::v-deep .pt-2 > span {
  font-family: dreamFont3 !important;
}

::v-deep .wid-100 > span {
  font-family: dreamFont3 !important;
}

::v-deep .wid-100 > a > span {
  font-family: dreamFont3 !important;
}
</style>