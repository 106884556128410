<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">

          <v-col cols="2">
            <span>문자 서비스</span>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="sms_use_yn"
              hide-details
              dense
              label="이용함"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2">
            <span>광고 사용</span>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="ad_use_yn"
              hide-details
              dense
              label="사용함"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2">
            <span>출신학교</span>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="ba_dgree_ess_yn"
              hide-details
              dense
              label="필수 수집함"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>계정(로그인ID) 유형</span>
            </div>
          </v-col>
          <v-col cols="3" v-show="!isNew">
            <div class="f-l wid-100">
              <v-text-field
                v-model="acct_type"
                class="wid-100 pt-0 pr-0"
                hide-details
                outlined
                disabled
              ></v-text-field>
              
            </div>
            <!-- <div class="f-l pt-1 pl-2">
              <span class="text-grey">수정불가</span>
            </div> -->
          </v-col>
          <v-col cols="3" v-show="isNew">
            <div class="f-l wid-100">
              <v-select
                v-model="acct_type"
                :items="acctTypeList"
                item-text="cmn_cd_nm"
                item-value="cmn_cd"
                class="wid-100"
                hide-details
                outlined
                dense
                @change="itemChanged"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="3">
            <span>* 계정 유형은 설정 후 수정할 수 없습니다.</span>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>로그인 승인 방식</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100 radio-pd1">
              <v-radio-group
                v-model="login_aprv_mthd"
                row
                hide-details
                class="ma-0 pa-0"
                @change="itemChanged"
              >
                <v-radio
                  label="가입시 자동"
                  value="join"
                  class="pr-5"
                ></v-radio>
                <v-radio
                  label="관리자 확인"
                  value="admin"
                  class="pr-5"
                ></v-radio>
                <v-radio
                  label="입회비 납부"
                  value="mfee"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>입회비 유형</span>
            </div>
          </v-col>
          <v-col cols="10">
            <div class="wid-100 radio-pd1">
              <v-radio-group
                v-model="join_mfee_type"
                row
                hide-details
                class="ma-0 pa-0"
                @change="itemChanged"
              >
                <v-radio
                  label="없음"
                  value="none"
                  class="pr-5"
                ></v-radio>
                <v-radio
                  label="동일"
                  value="same"
                  class="pr-5"
                ></v-radio>
                <v-radio
                  label="회원 종류에 따라 다름"
                  value="diff"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>결제 PG</span>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="f-l wid-100">
              <v-text-field
              class="wid-100 pt-0 pr-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
            </div>
            <!-- <div class="f-l pt-1 pl-2">
              <span class="text-grey">수정불가</span>
            </div> -->
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

          <v-col cols="2">
            <span>영어 전환</span>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="en_page_use_yn"
              hide-details
              dense
              label="사용함"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

          <v-col cols="2">
            <span>로그인/회원가입</span>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="login_use_yn"
              hide-details
              dense
              label="사용함"
              @change="itemChanged"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

          <v-col cols="4" >
            <div class="pt-0 font-weight-black">
            <span>[ 마이페이지 ]</span>
            </div>
          </v-col>
          <v-col cols="12"></v-col>
          <v-col cols="2"> 
            <span>결제 메뉴</span>
          </v-col>
          <v-col cols="10" >
            <v-checkbox
              label="사용함"
              hide-details
              dense
              @change="itemChanged"
              v-model="pay_menu_use_yn"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" > 
            <span>청구서/납부확인서/증명서 메뉴</span>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              label="사용함"
              hide-details
              dense
              @change="itemChanged"
              v-model="crtf_menu_use_yn"
            ></v-checkbox>
          </v-col>

        </v-row>  
      </div>
    </v-layout>
  </v-container>
        
  
</template>

<script>
export default {
  data(){
    return{
      mberSno : "",

      isNew: false,

      sms_use_yn: false,
      ad_use_yn: false,
      ba_dgree_ess_yn: false,
      acct_type: "email",
      login_aprv_mthd: "",
      join_mfee_type: "",
      acctTypeList: [],
      loginAprvMthdList: [],
      joinMfeeTypeList: [],
      pay_menu_use_yn: false,
      crtf_menu_use_yn: false,
      en_page_use_yn: false,
      login_use_yn: false,
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

    this.getAcctTypeList();
    this.getLoginAprvMthdList();
    this.getJoinMfeeTypeList();
    this.getSoctySiteOptn();
  },
  methods: {
    async getAcctTypeList() {
      this.acctTypeList = await this.$getCmnCdList('acct_type');
    },
    async getLoginAprvMthdList() {
      this.loginAprvMthdList = await this.$getCmnCdList('login_aprv_mthd');
    },
    async getJoinMfeeTypeList() {
      this.joinMfeeTypeList = await this.$getCmnCdList('join_mfee_type');
    },
    getSoctySiteOptn() {
      const params = new FormData();

			this.$http.post("/admin/getSoctySiteOptn", params).then((res) => {
        console.log("res.data: ", res.data);
        if(!res.data.result || res.data.result.length == 0) {
          this.isNew = true;
          // alert("조회된 정보가 없습니다.");
          return;
        } else {
          this.isNew = false;
        }

        const socty = res.data.result;

        this.acct_type = socty.acct_type;
        this.login_aprv_mthd = socty.login_aprv_mthd;
        this.join_mfee_type = socty.join_mfee_type;
        this.sms_use_yn = socty.sms_use_yn == 'Y' ? true : false;
        this.ad_use_yn = socty.ad_use_yn == 'Y' ? true : false;
        this.ba_dgree_ess_yn = socty.ba_dgree_ess_yn == 'Y' ? true : false;
        this.en_page_use_yn = socty.en_page_use_yn == 'Y' ? true : false;
        this.login_use_yn = socty.login_use_yn == 'Y' ? true : false;

        const mypageMap = res.data.mypageMap;
        if(mypageMap) {
          this.pay_menu_use_yn = mypageMap.pay_menu_use_yn == 'Y' ? true : false;
          this.crtf_menu_use_yn = mypageMap.crtf_menu_use_yn == 'Y' ? true : false;
        }

        

			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveSoctySiteOptn");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();
        params.append("new_yn", this.isNew ? 'Y' : 'N');
        params.append("mber_sno", this.mberSno);
        if(this.acct_type != null)        params.append("acct_type", this.acct_type);
        if(this.login_aprv_mthd != null)  params.append("login_aprv_mthd", this.login_aprv_mthd);
        if(this.join_mfee_type != null)   params.append("join_mfee_type", this.join_mfee_type);
        params.append("sms_use_yn", this.sms_use_yn == true ? 'Y' : 'N');        
        params.append("ad_use_yn", this.ad_use_yn == true ? 'Y' : 'N');
        params.append("ba_dgree_ess_yn", this.ba_dgree_ess_yn == true ? 'Y' : 'N');
        params.append("pay_menu_use_yn", this.pay_menu_use_yn == true ? 'Y' : 'N');
        params.append("crtf_menu_use_yn", this.crtf_menu_use_yn == true ? 'Y' : 'N');
        params.append("en_page_use_yn", this.en_page_use_yn == true ? 'Y' : 'N');
        params.append("login_use_yn", this.login_use_yn == true ? 'Y' : 'N');

        console.log("params",params);
        this.$http.post("/admin/upsertSoctySiteOptn", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");

          this.getSoctySiteOptn();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'siteOptionTab');
    },
  },
}
</script>

<style scoped>
.v-input--selection-controls{
  padding-top: 0px !important;
}
.v-input--checkbox.v-input--dense{
  margin-top: 0px !important;
}
/deep/.v-label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>