<template>
  <v-container fluid>
    <v-row class="pa-3">
      <v-col cols="12">
        <!-- <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="goBack"
          >뒤로가기</v-btn>
        </div> -->
        <!-- <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn> -->
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
        <!-- <div style="float:right;">
          <v-btn
            small
            color="red lighten-2"
            class="white--text mr-2"
          >학회삭제</v-btn>
        </div> -->
      </v-col>

    </v-row>
    <v-tabs
      v-model="tab"
      height="40px"
      background-color="blue lighten-5"
      
    >
      <v-tab
        v-for="item of tabList"
        :key="item.tabNm"
        exact
        class="fontSize-0"
      >
        {{ item.tabNm }}
        <v-badge 
          v-show="item.changed"
          color="red"
          class="pb-2"
          dot
        ></v-badge>
      </v-tab>
      <v-tabs-items 
        v-model="tab"
      >
        <v-divider></v-divider>
        <v-tab-item
          v-for="item in tabList"
          :key="item.tabNm"
          class="pa-3"
        > 
          <component v-bind:is="item.content" :ref="item.content" class="w-100" @tabChanged="tabChanged"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import societyInfoTab from '@/components/admin/tabs/society/societyInfoTab';
import siteOptionTab from '@/components/admin/tabs/society/siteOptionTab';
import memberKindTab from '@/components/admin/tabs/society/memberKindTab';
import imageFileTab from '@/components/admin/tabs/society/imageFileTab';
import advertiseTab from '@/components/admin/tabs/society/advertiseTab';
import apiCodeTab from '@/components/admin/tabs/society/apiCodeTab';
import bookBoxTab from '@/components/admin/tabs/society/bookBoxTab';
import journalTab from '@/components/admin/tabs/society/journalTab';
// import mainBoardTab from '@/components/admin/tabs/society/mainBoardTab';
import generalPolicyTab from '@/components/admin/tabs/society/generalPolicyTab';
import cssTab from '@/components/admin/tabs/society/cssTab';
import joinPolicyTab from '@/components/admin/tabs/society/joinPolicyTab';
import footerTab from '@/components/admin/tabs/society/footerTab';
import contactUsTab from '@/components/admin/tabs/society/contactUsTab';


export default {
  components: {
    societyInfoTab, siteOptionTab, memberKindTab, imageFileTab, advertiseTab, apiCodeTab, bookBoxTab, generalPolicyTab, cssTab, joinPolicyTab, footerTab, contactUsTab, journalTab
  },
  data(){
    return {
      tab: null,
      tabList: [
        { tabNm: '학회정보', content: 'societyInfoTab', changed: false},
        { tabNm: '사이트옵션', content: 'siteOptionTab', changed: false},
        { tabNm: '회원종류', content: 'memberKindTab', changed: false},
        { tabNm: '이미지관리', content: 'imageFileTab', changed: false},
        { tabNm: 'CSS', content: 'cssTab', changed: false},
        { tabNm: 'API코드', content: 'apiCodeTab', changed: false},
        { tabNm: '일반정책', content: 'generalPolicyTab', changed: false},
        { tabNm: '회원가입정책', content: 'joinPolicyTab', changed: false},
        { tabNm: '푸터', content: 'footerTab', changed: false},
        { tabNm: '오시는길', content: 'contactUsTab', changed: false},
        { tabNm: '광고', content: 'advertiseTab', changed: false},  
        { tabNm: '북박스', content: 'bookBoxTab', changed: false},
        { tabNm: '학술지', content: 'journalTab', changed: false},
        // { tabNm: '메인게시판', content: 'mainBoardTab', changed: false},
      ],
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      // menu: false,

      saveBtnEnabled: false,
    }
  },
  methods: {
    updateRouter(item){
      this.$router.push(item);
    },
    goBack(){
      this.$router.go(-1)
    },
    tabChanged(tabId) {
      console.log("society tabChanged:", tabId);
      this.tabList.filter((e) => e.content == tabId).map((f) => {
        f.changed = true;
      })

      this.saveBtnEnabled = true;
      // console.log("tabList:", this.tabList);     
    },
    saveNMoveBtnClicked() {
      console.log("saveNMoveBtnClicked");
      this.saveSocietyInfo();
      // this.$router.push({path:"conferenceList"})
    },
    saveNStayBtnClicked() {
      console.log("saveNStayBtnClicked");
      this.saveSocietyInfo();
    },
    saveSocietyInfo() {
      // console.log("saveConferenceInfo");
      // console.log("refs:", this.$refs);
      // console.log("filtered:", this.tabList.filter(e => e.changed));

      const filtered = this.tabList.filter(e => e.changed);
      // console.log("filtered:", filtered);

      // 데이터 검증
      let isValid = true;

      filtered.forEach((e) => {
        // if(e.content == "societyInfoTab") {
        //   this.$refs.societyInfoTab[0].saveBtnClicked();
        // } else 
        if(e.content == "memberKindTab") {
          isValid = this.$refs.memberKindTab[0].validateInput();
        // } else if(e.content == "imageFileTab") {
        //   this.$refs.imageFileTab[0].saveBtnClicked();
        // } else if(e.content == "advertiseTab") {
        //   this.$refs.advertiseTab[0].saveBtnClicked();
        // } else if(e.content == "apiCodeTab") {
        //   this.$refs.apiCodeTab[0].saveBtnClicked();
        // } else if(e.content == "bookBoxTab") {
        //   this.$refs.bookBoxTab[0].saveBtnClicked();
        // } else if(e.content == "generalPolicyTab") {
        //   this.$refs.generalPolicyTab[0].saveBtnClicked();
        // } else if(e.content == "cssTab") {
        //   this.$refs.cssTab[0].saveBtnClicked();
        // } else if(e.content == "joinPolicyTab") {
        //   this.$refs.joinPolicyTab[0].saveBtnClicked();
        // } else if(e.content == "siteOptionTab") {
        //   this.$refs.siteOptionTab[0].saveBtnClicked();
        // } else if(e.content == "footerTab") {
        //   this.$refs.footerTab[0].saveBtnClicked();
        // } else if(e.content == "contactUsTab") {
        //   this.$refs.contactUsTab[0].saveBtnClicked();
        // } else if(e.content == "journalTab") {
        //   this.$refs.journalTab[0].saveBtnClicked();
        }
      });   

      if(!isValid) {
        return;
      }

      if(confirm("저장하시겠습니까?")) {
        // const filtered = this.tabList.filter(e => e.changed);
        // console.log("filtered:", filtered);
        filtered.forEach((e) => {
          if(e.content == "societyInfoTab") {
            this.$refs.societyInfoTab[0].saveBtnClicked();
          } else if(e.content == "memberKindTab") {
            this.$refs.memberKindTab[0].saveBtnClicked();
          } else if(e.content == "imageFileTab") {
            this.$refs.imageFileTab[0].saveBtnClicked();
          } else if(e.content == "advertiseTab") {
            this.$refs.advertiseTab[0].saveBtnClicked();
          } else if(e.content == "apiCodeTab") {
            this.$refs.apiCodeTab[0].saveBtnClicked();
          } else if(e.content == "bookBoxTab") {
            this.$refs.bookBoxTab[0].saveBtnClicked();
          // } else if(e.content == "mainBoardTab") {
          //   this.$refs.mainBoardTab[0].saveBtnClicked();
          } else if(e.content == "generalPolicyTab") {
            this.$refs.generalPolicyTab[0].saveBtnClicked();
          } else if(e.content == "cssTab") {
            this.$refs.cssTab[0].saveBtnClicked();
          } else if(e.content == "joinPolicyTab") {
            this.$refs.joinPolicyTab[0].saveBtnClicked();
          } else if(e.content == "siteOptionTab") {
            this.$refs.siteOptionTab[0].saveBtnClicked();
          } else if(e.content == "footerTab") {
            this.$refs.footerTab[0].saveBtnClicked();
          } else if(e.content == "contactUsTab") {
            this.$refs.contactUsTab[0].saveBtnClicked();
          } else if(e.content == "journalTab") {
            this.$refs.journalTab[0].saveBtnClicked();
          }
        });        

        this.tabList.forEach((e) => {
          e.enabled = true;
          e.changed = false;
        });

        this.saveBtnEnabled = false;

        alert("저장되었습니다.");
      }
    },
  }
}
</script>

<style>

</style>