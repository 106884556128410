<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
    <div class="wid-100">
    <v-row dense align="center">

      <v-col cols="12">
        <h3>일반회원</h3>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>회원가입 이용약관</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor ref="terms_join_indv_cn" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="terms_join_indv"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>개인정보 수집 및 이용</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor ref="prvcy_polcy_join_indv_cn" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="prvcy_polcy_join_indv"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>회원가입 이용약관(영문)</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor ref="terms_join_indv_en_cn" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="terms_join_indv_en"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>개인정보 수집 및 이용(영문)</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor ref="prvcy_polcy_join_indv_en_cn" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="prvcy_polcy_join_indv_en"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <h3>단체회원</h3>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>회원가입 이용약관</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor ref="terms_join_grp_cn" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="terms_join_grp"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>개인정보 수집 및 이용</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor ref="prvcy_polcy_join_grp_cn" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="prvcy_polcy_join_grp"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>회원가입 이용약관(영문)</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor ref="terms_join_grp_en_cn" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="terms_join_grp_en"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="2" >
        <div class="pt-0">
          <span>개인정보 수집 및 이용(영문)</span>
        </div>
      </v-col>
      <v-col cols="8">
        <tiny-editor ref="prvcy_polcy_join_grp_en_cn" @input="itemChanged"></tiny-editor>
        <!-- <v-textarea
          v-model="prvcy_polcy_join_grp_en"
          class="wid-100 pt-0"
          label="임시영역"
          hide-details
          outlined
          @change="itemChanged"
        ></v-textarea> -->
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
    </v-row>  
  </div>
</v-layout>
</v-container>
      
  
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';

export default {
  components: { tinyEditor },
  data(){
    return{
      mberSno: "",
      
      isNew: false,                      // 조회된 값이 있으면 false
      // terms_join_indv: "",              // 개인회원 이용약관
      // prvcy_polcy_join_indv: "",        // 개인회원 개인정보처리방침
      // terms_join_indv_en: "",           // 개인회원 이용약관(영문)
      // prvcy_polcy_join_indv_en: "",     // 개인회원 개인정보처리방침(영문)
      // terms_join_grp: "",               // 단체회원 이용약관
      // prvcy_polcy_join_grp: "",         // 단체회원 개인정보처리방침
      // terms_join_grp_en: "",            // 단체회원 이용약관(영문)
      // prvcy_polcy_join_grp_en: "",      // 단체회원 개인정보처리방침(영문)
    }
  },
  created() {
    this.mberSno = this.$session.get("mberSno");

    this.getSoctyJoinPolcy();
  },
  methods: {
    getSoctyJoinPolcy() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyJoinPolcy", params).then((res) => {
        // console.log("res.data: ", res.data.result.length);
        if(res.data.result.length == 0) {
          this.isNew = true;
          // alert("조회된 정보가 없습니다.");
          return;
        } else {
          this.isNew = false;
        }

        const polcy = res.data.result[0];

        this.$refs.terms_join_indv_cn.content = polcy.terms_join_indv;
        this.$refs.prvcy_polcy_join_indv_cn.content = polcy.prvcy_polcy_join_indv;
        this.$refs.terms_join_indv_en_cn.content = polcy.terms_join_indv_en;
        this.$refs.prvcy_polcy_join_indv_en_cn.content = polcy.prvcy_polcy_join_indv_en;
        this.$refs.terms_join_grp_cn.content = polcy.terms_join_grp;
        this.$refs.prvcy_polcy_join_grp_cn.content = polcy.prvcy_polcy_join_grp;
        this.$refs.terms_join_grp_en_cn.content = polcy.terms_join_grp_en;
        this.$refs.prvcy_polcy_join_grp_en_cn.content = polcy.prvcy_polcy_join_grp_en;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveSoctJoinPolcy");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append('new_yn', this.isNew ? 'Y' : 'N');

        if(this.$refs.terms_join_indv_cn.content != null) {
          params.append("terms_join_indv", this.$refs.terms_join_indv_cn.content);
        }

        if(this.$refs.prvcy_polcy_join_indv_cn.content != null) {
          params.append("prvcy_polcy_join_indv", this.$refs.prvcy_polcy_join_indv_cn.content);
        }

        if(this.$refs.terms_join_indv_en_cn.content != null) {
          params.append("terms_join_indv_en", this.$refs.terms_join_indv_en_cn.content);
        }

        if(this.$refs.prvcy_polcy_join_indv_en_cn.content != null) {
          params.append("prvcy_polcy_join_indv_en", this.$refs.prvcy_polcy_join_indv_en_cn.content);
        }

        if(this.$refs.terms_join_grp_cn.content != null) {
          params.append("terms_join_grp", this.$refs.terms_join_grp_cn.content);
        }

        if(this.$refs.prvcy_polcy_join_grp_cn.content != null) {
          params.append("prvcy_polcy_join_grp", this.$refs.prvcy_polcy_join_grp_cn.content);
        }

        if(this.$refs.terms_join_grp_en_cn.content != null) {
          params.append("terms_join_grp_en", this.$refs.terms_join_grp_en_cn.content);
        }

        if(this.$refs.prvcy_polcy_join_grp_en_cn.content != null) {
          params.append("prvcy_polcy_join_grp_en", this.$refs.prvcy_polcy_join_grp_en_cn.content);
        }

        this.$http.post("/admin/upsertSoctyJoinPolcy", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getSoctyJoinPolcy();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'joinPolicyTab');
    },
  },
}
</script>

<style>

</style>