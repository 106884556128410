<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
    <div class="wid-100">
        <v-row dense >
          <v-col cols="12">
            <div class="f-r pl-1">
              <v-btn
                small
                color="green lighten-2"
                class="white--text"
                @click="addBtnClicked"
              >추가</v-btn>
            </div>
            <div class="f-r">
              <v-btn
                small
                color="red lighten-2"
                class="white--text"
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12" >
            <div >
              <v-data-table
                :headers="gridHeader"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination>
                <template v-slot:[`item.select_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.select_yn"
                    class="pa-0 ma-0"
                    hide-details
                    dense
                  ></v-checkbox>
                </template>
                <template v-slot:[`item.mber_kind`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.mber_kind"
                    :items="mberKindList"
                    item-text="mber_kind_nm"
                    item-value="mber_kind"
                    hide-details
                    outlined
                    dense
                    @change="gridSelectItemChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.prod_type`]="{ item }">
                  <v-select
                    class="pa-0 ma-0"
                    v-model="item.prod_type"
                    :items="prodTypeList"
                    item-text="cmn_cd_nm"
                    item-value="cmn_cd"
                    hide-details
                    outlined
                    dense
                    @change="gridSelectItemChanged(item)"
                  ></v-select>
							  </template>
                <template v-slot:[`item.bgn_dt`]="{ item }">
                  <v-text-field
                  class=""
                  v-model="item.bgn_dt"
                  hide-details
                  dense
                  outlined
                  append-icon="mdi-calendar"
                  @click:append="popupDatePicker(item, item.bgn_dt, 'bgn_dt')"
                  placeholder="0000-00-00"
                  ></v-text-field>
                  <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                </template>
                <template v-slot:[`item.end_dt`]="{ item }">
                  <v-text-field
                  class=""
                  v-model="item.end_dt"
                  hide-details
                  dense
                  outlined
                  append-icon="mdi-calendar"
                  @click:append="popupDatePicker(item, item.end_dt, 'end_dt')"
                  placeholder="0000-00-00"
                  ></v-text-field>
                  <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                </template>
                <template v-slot:[`item.prod_amt`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.prod_amt"
                    hide-details
                    outlined
                    dense
                    readonly
                    background-color="grey lighten-2"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.pay_amt`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.pay_amt"
                    hide-details
                    outlined
                    dense
                    readonly
                    background-color="grey lighten-2"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.pay_yn`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.pay_yn"
                    hide-details
                    outlined
                    dense
                    readonly
                    background-color="grey lighten-2"
                  ></v-text-field>
							  </template>
                <template v-slot:[`item.bill_desc`]="{ item }">
                  <v-text-field
                    class="pa-0 ma-0"
                    v-model="item.bill_desc"
                    hide-details
                    outlined
                    dense
                    readonly
                    background-color="grey lighten-2"
                  ></v-text-field>
							  </template>

              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import datePickerPopup from '@/components/common/datePickerPopup.vue';
export default {
  components: { datePickerPopup },
  props: ["propParams"],
  data(){
    return {
      gridHeader: [
        { text: '선택', value:'select_yn', width:"5%"},
        { text: '회원 종류', value:'mber_kind', width:"10%"},
        { text: '회비 유형 *', value:'prod_type', width:"10%"},
        { text: '시작일자', value:'bgn_dt', width:"11%"},
        { text: '종료일자', value:'end_dt', width:"11%"},
        { text: '상품 금액', value:'prod_amt', width:"7%"},
        { text: '납부 금액', value:'pay_amt', width:"7%"},
        { text: '납부 완료', value:'pay_yn', width:"6%"},
        { text: '청구 내역', value:'bill_desc', width:"33%"},
      ],
      gridList: [],
      mberKindList: [],
      prodTypeList: [],
      changedList: [],    // 추가, 수정된 목록
      mberSno: "",
      loginMberSno: "",
      
      datePopup: false,
      curItem: null,
      curDate: "",
      dateNm: "",       
    }
  },
  created() {
    this.mberSno = this.propParams.mberSno;
    this.loginMberSno = this.$session.get("mberSno");

    this.getMberKindList();
    this.getProdTypeList();

    this.getMberProdList();
  },
  methods: {
    async getProdTypeList() {
      const prodTypeList = await this.$getCmnCdList("prod_type");
      this.prodTypeList = prodTypeList.filter(e => e.cmn_cd == 'anul' || e.cmn_cd == 'entr' || e.cmn_cd == 'thes');
      console.log("prodTypeList:", this.prodTypeList);
    },
    getMberKindList() {
      this.$http.get("/admin/getSoctyMberKindList").then((res) => {        
        this.mberKindList = res.data.result.mberKindList;
      }).catch((error) => {
          console.log(error);
        }
      )
    },
    // 회비 수정됨
    gridItemChanged(item) {
      this.itemChanged();
      // console.log("gridItemChanged : item.mber_prod_sno : ", item.mber_prod_sno);
      // 추가된 목록인 경우 -> [추가] 시 changedList에도 추가함
      if(item.mber_prod_sno == null || item.mber_prod_sno == "") {
        return;
      }

      let found = false;
      this.changedList.forEach((e) => {
        if(e.mber_prod_sno == item.mber_prod_sno) {
          found = true;
        }
      });

      if(!found) {
        this.changedList.push(item);
      }
    },
    gridSelectItemChanged(item) {
      // console.log("gridSelectItemChanged: ", item);
      if(item.mber_kind != null && item.prod_type != null) {
        const params = new FormData();

        params.append("mber_kind", item.mber_kind);
        params.append("prod_type", item.prod_type);

        this.$http.post("/admin/getSoctyProdByMberKindNProdType", params).then((res) => {
          console.log("soctyProd: ", res.data);

          if(res.data.result.prod == null) {
            alert("조건에 맞는 상품이 없습니다.");
          } else {
            item.prod_sno = res.data.result.prod.prod_sno;
            item.prod_amt = res.data.result.prod.prod_amt;
            item.bill_desc = res.data.result.prod.bill_desc;
          }

        }).catch(function (error) {
            console.log(error);
          }
        );
      }

      this.gridItemChanged(item);
    },
    getMberProdList() {
			const params = new FormData();
      params.append("mber_sno", this.mberSno);

			this.$http.post("/admin/getMberProdList", params).then((res) => {
        console.log("gridList: ", res.data);
        this.gridList = res.data.result;

        this.gridList.forEach((e) => {
          e.select_yn = false;
          e.new_yn = false;
        });

        this.changedList = [];
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    deleteBtnClicked() {
      console.log("deleteMberProd");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      // for(let i = 0; i < this.gridList.length; i++) {
      //   if(this.gridList[i].select_yn) {
      //     isSelected = true;

      //     if(!this.gridList[i].new_yn) {
      //       deleteList.push(this.gridList[i]);
      //     }
      //   }
      // }
      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 데이터 검증
      // deleteList.forEach((e) => {
      //   console.log("deleteList: hist_sno[" + e.hist_sno + "]");
      // });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();

          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteMberProdList", params).then((res) => {
            console.log(res.data);
            // this.getMberProdList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });

            this.changedList = this.changedList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
        // 추가된 객체만 선택된 경우
        else {
          // this.getMberProdList();
          this.gridList = this.gridList.filter((e) => {
            return !(e.select_yn);
          });

          this.changedList = this.changedList.filter((e) => {
            return !(e.select_yn);
          });
        }
      }
    },
    addBtnClicked() {
      let maxOdr = 0;

      this.gridList.forEach((e) => {
        if(e.odr > maxOdr) {
          maxOdr = e.odr;
        }
      });

      maxOdr++;

      this.gridList.push({new_yn:true, select_yn:false, mber_prod_sno:"", mber_sno:this.mberSno, odr:maxOdr, mber_kind:null, prod_type:null, 
                          bgn_dt:null, end_dt:null, prod_amt:null, pay_amt:null, pay_yn:'N', bill_desc:null});
      this.changedList.push(this.gridList[this.gridList.length - 1]);
      // 객체 복사가 필요하면 아래의 방법으로 해야 함
      //this.changedList.push({...this.gridList[this.gridList.length - 1]}); 
      // 객체 안에 또 객체가 있을 경우 아래의 방법으로 해야 함
      // JSON.parse(JSON.stringify(item))

      // 데이터 검증
      // this.changedList.forEach((e) => {
      //   console.log("changedList: hist_sno[" + e.hist_sno + "]");
      // });
    },
    validateInput() {
      const errLen = this.changedList.filter((e) => !e.prod_type).length;
      if(errLen > 0) {
        alert('회비유형은 필수값입니다.');
        return false;
      }

      this.changedList.forEach((e) => {
        if(e.prod_sno == null) {
          alert("상품이 선택되지 않았습니다.");
          return false;
        }
      });

      return true;
    },
    saveBtnClicked() {
      console.log("saveMberPord");

      if(this.changedList.length == 0) {
        // alert("수정된 내용이 없습니다.");
        return;
      }

      // true/false -> 'Y'/'N' 변환
      this.changedList.forEach((e) => {
        e.new_yn = e.new_yn == true ? 'Y' : 'N';
      });

      // if(confirm("저장하시겠습니까?")) {
        let params = new URLSearchParams();

        params.append("login_mber_sno", this.loginMberSno);
        params.append("changedList", JSON.stringify(this.changedList));

        this.$http.post("/admin/upsertMberProdList", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          this.getMberProdList();
        }).catch(function (error) {
            console.log(error);
        });
      // }
    },
    popupDatePicker(item, curDate, dateNm) {
      // console.log("popupDatePicker");
      // console.log("hist_sno: ", item.hist_sno);
      this.curItem = item;
      this.dateNm = dateNm;
      this.curDate = curDate;

      this.datePopup = true;
    },
    setSelectedDate(selected) {
      if(this.dateNm == "bgn_dt") {
        this.curItem.bgn_dt = selected;
      } else if(this.dateNm == "end_dt") {
        this.curItem.end_dt = selected;
      }
      
      this.gridItemChanged(this.curItem);
      this.datePopup = false;
    },
    itemChanged() {
      this.$emit('tabChanged', 'memberFeeTab');
    }
  }
}
</script>

<style>

</style>