<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <!-- <v-col cols="12" class="pa-0 ma-0">
            <div>
              <v-row dense>
                <v-col cols="12">
                  <div class="f-r">
                    <v-btn
                      small
                      color="blue lighten-2"
                      class="white--text"
                      @click="saveBtnClicked"
                    >저장</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col> -->
          <v-col cols="4">
            <div class="wid-100">
              <v-data-table
                :headers="headers"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination
                >
                <template v-slot:[`item.perm_yn`]="{ item }">
                  <v-checkbox
                    v-model="item.perm_yn"
                    class="pa-0 ma-0"
                    hide-details
                    @change="itemChanged"
                  ></v-checkbox>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ["propParams"],
  data(){
    return {
      headers:[
        { text: '퍼미션 그룹명', value:'perm_grp_nm'},
        { text: '퍼미션 그룹설명', value:'perm_grp_desc'},
        { text: '부여', value:'perm_yn'},
      ],
      gridList: [],
      mberSno: "",
    }
  },
  created() {
    this.mberSno = this.propParams.mberSno;
    console.log("mberSno: " + this.mberSno);

    this.getMberPermList();
  },
  methods: {
    getMberPermList() {
			const params = new FormData();
      params.append("mber_sno", this.mberSno);

			this.$http.post("/admin/getMberPermList", params).then((res) => {
        // console.log("gridList: " + this.gridList);
        this.gridList = res.data.result;

        this.gridList.forEach((e) => {
          e.perm_yn = e.perm_yn == 'Y' ? true : false;
        });
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("savePerm");

      // if(confirm("저장하시겠습니까?")) {
        // 체크된 목록만 적재
        const insertList = this.gridList.filter((e) => e.perm_yn);

        // 검증
        // insertList.forEach(e => {
        //   console.log("e.perm_grp_nm: ", e.perm_grp_nm);
        // })

        let params = new URLSearchParams();
        params.append("mber_sno", this.mberSno);
        params.append("insertList", JSON.stringify(insertList));

        this.$http.post("/admin/upsertMberPermList", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          this.getMberPermList();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'memberPermissionTab');
    },
  }
}
</script>

<style>

</style>