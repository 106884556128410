<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center">

          <v-col cols="3">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="searchWord" 
              outlined
              dense
              hide-details
              placeholder="검색어"
              background-color="white">
            </v-text-field>
          </v-col>
          <!-- <v-col cols="1">
            <v-btn 
              small 
              color="blue lighten-2" 
              class="white--text" 
              @click="searchBtnClicked"
              >검색
            </v-btn>
          </v-col> -->
        </v-row>
        
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              class="elevation-1"
              :search="searchWord"
              @click:row="rowClicked"
              style="cursor: pointer"
              >
              <template v-slot:[`item.disp_tf`]="{ item }">
                <v-simple-checkbox
                  v-model="item.disp_tf"
                  hide-details
                  disabled
                  >
                </v-simple-checkbox>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
          
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.boardList.radioBoardType"
              >
                <template v-slot:label>
                  <div><span>게시판 유형으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in boardTypeList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col> -->
    </v-row>

	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      // mberChkDialog: false,
      gridHeader: [
        { text: '수록기사 메뉴명', value: 'menu_nm', width: "60%" },
        { text: '메뉴 URL', value: 'resrc_nm', width: "30%" },
        { text: '노출 여부', value: 'disp_tf', width: "10%" },
      ],
      // gridList: [],
      publcMenuList: [],

      searchWord: null,
    }
  },
  computed: {
    gridList() {
      return this.publcMenuList.filter((e) => {
        return e;  //(this.$store.state.boardList.radioBoardType == "모두" || e.page_type == this.$store.state.boardList.radioBoardType);
      });
    }
  },
  created() {
    // this.getBoardTypeList();
  },
  mounted(){
    this.getPublcMenuList();
  },
  methods: {
    getPublcMenuList() {
      const params = new FormData();

      this.$http.post("/admin/getPublcMenuList", params).then((res) => {
        console.log("res.data.result:", res.data.result);

        if(res.data.result.publcMenuList) {
          res.data.result.publcMenuList.forEach((e) => {
            e.disp_tf = e.disp_yn == "Y" ? true : false;
          });

          this.publcMenuList = res.data.result.publcMenuList;
        }
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    rowClicked(event) {
      // console.log("rowClicked", event);

      // 페이지 제목
      this.$store.state.pageTitle += (" > " + event.menu_nm);

      this.$router.push({name:"publcArtclList", query: { menuSno:event.menu_sno, menuNm:event.menu_nm }});
    },

  }
}
</script>
  
<style>

</style>