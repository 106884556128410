<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="">
        <v-row dense align="center">

          <v-col cols="2" >
            <div class="pt-0">
              <span>홈페이지 게시</span>
            </div>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="ntce_yn_scr"
              hide-details
              dense
              @change="itemChanged"
              label="게시함"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>학술대회 구분 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-select
              class="pa-0 ma-0"
              v-model="confr_div"
              :items="confrDivList"
              item-text="cmn_cd_nm"
              item-value="cmn_cd"
              hide-details
              outlined
              dense
              @change="itemChanged"
            ></v-select>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>행사명 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="confr_nm"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>장소 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="confr_plc"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>행사명(영문)</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="confr_nm_en"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>장소(영문)</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="confr_plc_en"
              class="wid-100 pt-0"
              hide-details
              outlined
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2" >
            <div class="pt-0">
              <span>참가 최대 인원수 *</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="max_mber_cnt"
              class="wid-100 pt-0"
              hide-details
              outlined
              type="number"
              @change="itemChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 행사 시작일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>행사 시작일 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="confr_bgn_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('confr_bgn_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="confr_bgn_dt" 
                ref="confr_bgn_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 행사 종료일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>행사 종료일 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="confr_end_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('confr_end_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="confr_end_dt" 
                ref="confr_end_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 초록등록 시작일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록등록 시작일 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="abstr_reg_bgn_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('abstr_reg_bgn_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="abstr_reg_bgn_dt" 
                ref="abstr_reg_bgn_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="1" class="cols-1-wid">
            <div class="pt-0 f-r pr-5">
              <span>시각 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <!-- <timePicker 
                p_timeName="abstr_reg_bgn_tm" 
                ref="abstr_reg_bgn_tm"
                @input="itemChanged"
              ></timePicker> -->
              <v-text-field
                v-model="abstr_reg_bgn_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
                @input="itemChanged"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 초록등록 종료일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록등록 종료일 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="abstr_reg_end_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('abstr_reg_end_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="abstr_reg_end_dt" 
                ref="abstr_reg_end_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="1" class="cols-1-wid">
            <div class="pt-0 f-r pr-5">
              <span>시각 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                v-model="abstr_reg_end_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
                @input="itemChanged"
              ></v-text-field>
              <!-- <timePicker 
                p_timeName="abstr_reg_end_tm" 
                ref="abstr_reg_end_tm"
                @input="itemChanged"
              ></timePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 초록파일 등록 시작일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록파일 등록 시작일</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="abstr_file_reg_bgn_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('abstr_file_reg_bgn_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="abstr_file_reg_bgn_dt" 
                ref="abstr_file_reg_bgn_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="1" class="cols-1-wid">
            <div class="pt-0 f-r pr-5">
              <span>시각</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                v-model="abstr_file_reg_bgn_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
                @input="itemChanged"
              ></v-text-field>
              <!-- <timePicker 
                p_timeName="abstr_file_reg_bgn_tm" 
                ref="abstr_file_reg_bgn_tm"
                @input="itemChanged"
              ></timePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 초록파일 등록 종료일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록파일 등록 종료일</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="abstr_file_reg_end_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('abstr_file_reg_end_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="abstr_file_reg_end_dt" 
                ref="abstr_file_reg_end_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="1" class="cols-1-wid">
            <div class="pt-0 f-r pr-5">
              <span>시각</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                v-model="abstr_file_reg_end_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
                @input="itemChanged"
              ></v-text-field>
              <!-- <timePicker 
                p_timeName="abstr_file_reg_end_tm" 
                ref="abstr_file_reg_end_tm"
                @input="itemChanged"
              ></timePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 초록파일 수정 시작일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록파일 수정 시작일</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="abstr_file_upd_bgn_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('abstr_file_upd_bgn_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="abstr_file_upd_bgn_dt" 
                ref="abstr_file_upd_bgn_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="1" class="cols-1-wid">
            <div class="pt-0 f-r pr-5">
              <span>시각</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                v-model="abstr_file_upd_bgn_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
                @input="itemChanged"
              ></v-text-field>
              <!-- <timePicker 
                p_timeName="abstr_file_upd_bgn_tm" 
                ref="abstr_file_upd_bgn_tm"
                @input="itemChanged"
              ></timePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 초록파일 수정 종료일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>초록파일 수정 종료일</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="abstr_file_upd_end_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('abstr_file_upd_end_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="abstr_file_upd_end_dt" 
                ref="abstr_file_upd_end_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="1" class="cols-1-wid">
            <div class="pt-0 f-r pr-5">
              <span>시각</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                v-model="abstr_file_upd_end_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
                @input="itemChanged"
              ></v-text-field>
              <!-- <timePicker 
                p_timeName="abstr_file_upd_end_tm" 
                ref="abstr_file_upd_end_tm"
                @input="itemChanged"
              ></timePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 사전등록 시작일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>사전등록 시작일 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="pre_reg_bgn_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('pre_reg_bgn_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="pre_reg_bgn_dt" 
                ref="pre_reg_bgn_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="1" class="cols-1-wid">
            <div class="pt-0 f-r pr-5">
              <span>시각 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                v-model="pre_reg_bgn_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
                @input="itemChanged"
              ></v-text-field>
              <!-- <timePicker 
                p_timeName="pre_reg_bgn_tm" 
                ref="pre_reg_bgn_tm"
                @input="itemChanged"
              ></timePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 사전등록 종료일 -->
          <v-col cols="2" >
            <div class="pt-0">
              <span>사전등록 종료일 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                class="bg-white"
                v-model="pre_reg_end_dt"
                hide-details
                dense
                outlined
                append-icon="mdi-calendar"
                @click:append="popupDatePicker('pre_reg_end_dt')"
                placeholder="0000-00-00"
                @change="itemChanged"
              ></v-text-field>
              <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
              <!-- <datePicker 
                p_dateName="pre_reg_end_dt" 
                ref="pre_reg_end_dt"
                @input="itemChanged"
              ></datePicker> -->
            </div>
          </v-col>
          <v-col cols="1" class="cols-1-wid">
            <div class="pt-0 f-r pr-5">
              <span>시각 *</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-70">
              <v-text-field
                v-model="pre_reg_end_tm"
                hide-details
                class="pt-1"
                dense
                outlined
                placeholder="00:00"
                @input="itemChanged"
              ></v-text-field>
              <!-- <timePicker 
                p_timeName="pre_reg_end_tm" 
                ref="pre_reg_end_tm"
                @input="itemChanged"
              ></timePicker> -->
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 학술발표 상단 이미지 -->
          <v-col cols="2" >
            <div class="">
              <span>학술발표 상단 이미지</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-100 pt-0">
              <v-file-input
                hide-details
                dense
                v-model="bnrImgSelectedFile"
                @change="itemChanged"
              ></v-file-input>
            </div>
          </v-col>
          <!-- 파일 표시 text-field -->
          <v-col cols="3" v-if="bnrImgCurrentFile.orgnl_file_nm">
            <div class="wid-100 pt-0">
              <div class="f-l pt-2 pl-5 font-1" style="color:white;">
                <span><a :href="bnrImgCurrentFile.file_path">{{bnrImgCurrentFile.orgnl_file_nm}}</a></span>
              </div>
              <v-btn
                small
                color="red lighten-2"
                class="pa-0 ma-0 white--text f-r"
                hide-details
                @click="deleteFileBtnClicked('bnr_img', bnrImgCurrentFile.file_path, bnrImgCurrentFile.file_atch_sno)"
              >삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 배너 이미지 끝 -->
          <!-- 개최 공문 -->
          <v-col cols="2" >
            <div class="">
              <span>학술대회 개최 공문</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="wid-100 pt-0">
              <v-file-input
                hide-details
                dense
                v-model="ofdocSelectedFile"
                @change="itemChanged"
              ></v-file-input>
            </div>
          </v-col>
          <!-- 파일 표시 text-field -->
          <v-col cols="3" v-if="ofdocCurrentFile.orgnl_file_nm">
            <div class="wid-100 pt-0">
              <div class="f-l pt-2 pl-5 font-1" style="color:white;">
                <span><a :href="ofdocCurrentFile.file_path" :download="ofdocCurrentFile.orgnl_file_nm">{{ofdocCurrentFile.orgnl_file_nm}}</a></span>
              </div>
              <v-btn
                small
                color="red lighten-2"
                class="pa-0 ma-0 white--text f-r"
                hide-details
                @click="deleteFileBtnClicked('bnr_img', ofdocCurrentFile.file_path, ofdocCurrentFile.file_atch_sno)"
              >삭제</v-btn>
            </div>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <!-- 개최 공문 끝 -->
          <!-- 학술대회 메뉴 -->
          <!-- <v-col cols="2" >
            <div class="pt-0">
              <span>학술대회 메뉴</span>
            </div>
          </v-col>
          <v-col cols="4">
            <v-data-table
              :headers="confrMenuHeader"
              :items="confrMenuList"
              class="elevation-1 wid-100"
              item-key="no"
              hide-default-footer
            >
              <template v-slot:[`item.use_yn`]="{item}">
                <v-checkbox 
                  v-model="item.use_yn" 
                  class="ma-0 pa-0"
                  hide-details
                  solo 
                  flat>
                </v-checkbox>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col> -->
          <!-- 학술대회 메뉴 끝 -->

          <!-- <v-col cols="12">
            <div class="pt-0">
              <v-btn
                small
                color="blue lighten-2"
                class="white--text"
                @click="saveBtnClicked"
              >저장</v-btn>
            </div>
          </v-col> -->
        </v-row>  
      </div>
    </v-layout>
  </v-container>
      
  
</template>

<script>
// import datePicker from "../../../common/datePicker.vue";
// import timePicker from "../../../common/timePicker.vue";
import datePickerPopup from '@/components/common/datePickerPopup.vue';

export default {
  props: ["propParams"],
  components: {datePickerPopup},
  data(){
    return{
      mberSno: "",
      confrSno: "",

      ntce_yn_scr: false,
      confr_div: "",
      confr_nm: "",
      confr_plc: "",
      confr_nm_en: "",
      confr_plc_en: "",
      max_mber_cnt: "",

      bnrImgCurrentFile: {orgnl_file_nm:null, file_atch_sno:null, file_path:null},
      bnrImgSelectedFile: null,
      ofdocCurrentFile: {orgnl_file_nm:null, file_atch_sno:null, file_path:null},
      ofdocSelectedFile: null,

      // confrMenuHeader:[
      //   { text:'메뉴명', value:'cmn_cd_nm', width:'70%', sortable:false},
      //   { text:'사용여부', value:'use_yn', width: '30%', sortable:false},
      //   // { text:'순서', value:'disp_odr', disp_odr: '20%', sortable:false}
      // ],
      // confrMenuList: [],

      confrDivList: [],

      newYn: 'N',
      datePopup: false,
      curDate: "",

      confr_bgn_dt: '',          
      confr_end_dt: '',          
      abstr_reg_bgn_dt: '',      
      abstr_reg_end_dt: '',      
      abstr_file_reg_bgn_dt: '', 
      abstr_file_reg_end_dt: '', 
      abstr_file_upd_bgn_dt: '', 
      abstr_file_upd_end_dt: '', 
      pre_reg_bgn_dt: '',        
      pre_reg_end_dt: '',        


      abstr_reg_bgn_tm: '',     
      abstr_reg_end_tm: '',     
      abstr_file_reg_bgn_tm: '',
      abstr_file_reg_end_tm: '',
      abstr_file_upd_bgn_tm: '',
      abstr_file_upd_end_tm: '',
      pre_reg_bgn_tm: '',       
      pre_reg_end_tm: '',       

    }
  },
  created() {
    // console.log("conferenceInfoTab created.");

    this.mberSno = this.$session.get("mberSno");
    this.confrSno = this.propParams.confrSno; 
    this.newYn = this.propParams.newYn;

    // console.log("this.newYn:", this.newYn);
    // console.log("this.confrSno:", this.confrSno);

    this.getConfrDivList();

    if(this.newYn == 'Y') {
      // console.log('this.newYn is true');
      // this.getConfrMenuList();
    } else {
      // console.log('this.newYn is false');
      this.getConfr();
    }

    // if(this.newYn == 'N') {
    //   this.getConfr();
    // }
  },
  methods: {
    async getConfrDivList() {
      this.confrDivList = await this.$getCmnCdList("confr_div");
    },
    // async getConfrMenuList() {
    //   this.confrMenuList = await this.$getCmnCdList('confr_menu');
    //   // console.log("confrMenuList:", this.confrMenuList);
    //   this.confrMenuList.forEach((e) => {
    //     e.use_yn = false;
    //   });
    // },
    getConfr() {
      // 기존 파일 리스트 초기화
      this.bnrImgSelectedFile = null;
      this.ofdocSelectedFile = null;

      const params = new FormData();
      params.append("confr_sno", this.confrSno);

			this.$http.post("/admin/getConfr", params).then((res) => {
        console.log("res.data1111: ", res.data);
        const confr = res.data.result.confrInfo;
        // this.confrMenuList = res.data.result.confrMenuList;

        // if(confr.length == 0) {
        //   alert("조회된 정보가 없습니다.");
        //   this.newYn = 'Y';
        // }

        this.bnrImgCurrentFile.orgnl_file_nm = confr.bnr_img_orgnl_file_nm;
        this.bnrImgCurrentFile.file_atch_sno = confr.bnr_img_atch_sno;
        this.bnrImgCurrentFile.file_path = confr.bnr_img_path;

        this.ofdocCurrentFile.orgnl_file_nm = confr.ofdoc_orgnl_file_nm;
        this.ofdocCurrentFile.file_atch_sno = confr.ofdoc_atch_sno;
        this.ofdocCurrentFile.file_path = confr.ofdoc_path;

        this.ntce_yn_scr = confr.ntce_yn == 'Y' ? true : false;
        this.confr_div = confr.confr_div;
        this.confr_nm = confr.confr_nm;
        this.confr_plc = confr.confr_plc;
        this.confr_nm_en = confr.confr_nm_en;
        this.confr_plc_en = confr.confr_plc_en;
        this.max_mber_cnt = confr.max_mber_cnt;

        // this.$refs.confr_bgn_dt.date = confr.confr_bgn_dt;
        // this.$refs.confr_end_dt.date = confr.confr_end_dt;
        // this.$refs.abstr_reg_bgn_dt.date = confr.abstr_reg_bgn_dt;
        // this.$refs.abstr_reg_end_dt.date = confr.abstr_reg_end_dt;
        // this.$refs.abstr_file_reg_bgn_dt.date = confr.abstr_file_reg_bgn_dt;
        // this.$refs.abstr_file_reg_end_dt.date = confr.abstr_file_reg_end_dt;
        // this.$refs.abstr_file_upd_bgn_dt.date = confr.abstr_file_upd_bgn_dt;
        // this.$refs.abstr_file_upd_end_dt.date = confr.abstr_file_upd_end_dt;
        // this.$refs.pre_reg_bgn_dt.date = confr.pre_reg_bgn_dt;
        // this.$refs.pre_reg_end_dt.date = confr.pre_reg_end_dt;

        this.confr_bgn_dt = confr.confr_bgn_dt;
        this.confr_end_dt = confr.confr_end_dt;
        this.abstr_reg_bgn_dt = confr.abstr_reg_bgn_dt;
        this.abstr_reg_end_dt = confr.abstr_reg_end_dt;
        this.abstr_file_reg_bgn_dt = confr.abstr_file_reg_bgn_dt;
        this.abstr_file_reg_end_dt = confr.abstr_file_reg_end_dt;
        this.abstr_file_upd_bgn_dt = confr.abstr_file_upd_bgn_dt;
        this.abstr_file_upd_end_dt = confr.abstr_file_upd_end_dt;
        this.pre_reg_bgn_dt = confr.pre_reg_bgn_dt;
        this.pre_reg_end_dt = confr.pre_reg_end_dt;
        
        this.abstr_reg_bgn_tm = confr.abstr_reg_bgn_tm;
        this.abstr_reg_end_tm = confr.abstr_reg_end_tm;
        this.abstr_file_reg_bgn_tm = confr.abstr_file_reg_bgn_tm;
        this.abstr_file_reg_end_tm = confr.abstr_file_reg_end_tm;
        this.abstr_file_upd_bgn_tm = confr.abstr_file_upd_bgn_tm;
        this.abstr_file_upd_end_tm = confr.abstr_file_upd_end_tm;
        this.pre_reg_bgn_tm = confr.pre_reg_bgn_tm;
        this.pre_reg_end_tm = confr.pre_reg_end_tm;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    validateInput() {

      // 학술대회 구분
      if(this.confr_div == null || this.confr_div == "") {
        alert("학술대회 구분은 필수 입력값입니다.");
        return false;
      }

      // 행사명
      if(this.confr_nm == null || this.confr_nm == "") {
        alert("행사명은 필수 입력값입니다.");
        return false;
      }
      // 장소
      if(this.confr_plc == null || this.confr_plc == "") {
        alert('장소는 필수 입력값입니다.');
        return false;
      }

      //참가 최대 인원수
      if(this.max_mber_cnt <= 0) {
        alert('참가 최대 인원수는 1명 이상 입력하셔야합니다.');
        return false;
      }

      // 행사 시작일
      if(this.confr_bgn_dt == null || this.confr_bgn_dt == "") {
        alert('행사 시작일은 필수 입력값입니다.');
        return false;
      }
      // 행사 종료일
      if(this.confr_end_dt == null || this.confr_end_dt == "") {
        alert('행사 종료일은 필수 입력값입니다.');
        return false;
      }
      // 초록등록 시작일, 시각
      if(this.abstr_reg_bgn_dt == null || this.abstr_reg_bgn_dt == "") {
        alert('초록등록 시작일은 필수 입력값입니다.');
        return false;
      }
      if(this.abstr_reg_bgn_tm == null || this.abstr_reg_bgn_tm == "") {
        alert('초록등록 시작 시각은 필수 입력값입니다.');
        return false;
      }
      // 초록등록 종료일, 시각
      if(this.abstr_reg_end_dt == null || this.abstr_reg_end_dt == "") {
        alert('초록등록 종료일은 필수 입력값입니다.');
        return false;
      }
      if(this.abstr_reg_end_tm == null || this.abstr_reg_end_tm == "") {
        alert('초록등록 종료 시각은 필수 입력값입니다.');
        return false;
      }
      // 사전등록 시작일, 시각
      if(this.pre_reg_bgn_dt == null || this.pre_reg_bgn_dt == "") {
        alert('사전등록 시작일은 필수 입력값입니다.');
        return false;
      }
      if(this.pre_reg_bgn_tm == null || this.pre_reg_bgn_tm == "") {
        alert('사전등록 시작 시각은 필수 입력값입니다.');
        return false;
      }
      // 사전등록 종료일, 시각
      if(this.pre_reg_end_dt == null || this.pre_reg_end_dt == "") {
        alert('사전등록 종료일은 필수 입력값입니다.');
        return false;
      }
      if(this.pre_reg_end_tm == null || this.pre_reg_end_tm == "") {
        alert('사전등록 종료 시각은 필수 입력값입니다.');
        return false;
      }

      

      return true;
    },
    saveBtnClicked() {
      // console.log("saveConference");
      // if(this.validateInput()) {

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);
        params.append('new_yn', this.newYn);

        if(this.ntce_yn_scr != null)      params.append("ntce_yn", this.ntce_yn_scr ? 'Y' : 'N');
        if(this.confr_div != null)         params.append("confr_div", this.confr_div);
        if(this.confr_nm != null)         params.append("confr_nm", this.confr_nm);
        if(this.confr_plc != null)        params.append("confr_plc", this.confr_plc);
        if(this.confr_nm_en != null)      params.append("confr_nm_en", this.confr_nm_en);        
        if(this.confr_plc_en != null)     params.append("confr_plc_en", this.confr_plc_en);
        if(this.max_mber_cnt != null)     params.append("max_mber_cnt", this.max_mber_cnt);

        // if(this.$refs.confr_bgn_dt.date != null)     params.append("confr_bgn_dt", this.$refs.confr_bgn_dt.date);
        // if(this.$refs.confr_end_dt.date != null)     params.append("confr_end_dt", this.$refs.confr_end_dt.date);
        // if(this.$refs.abstr_reg_bgn_dt.date != null) params.append("abstr_reg_bgn_dt", this.$refs.abstr_reg_bgn_dt.date);
        // if(this.$refs.abstr_reg_end_dt.date != null) params.append("abstr_reg_end_dt", this.$refs.abstr_reg_end_dt.date);
        // if(this.$refs.abstr_file_reg_bgn_dt.date != null) params.append("abstr_file_reg_bgn_dt", this.$refs.abstr_file_reg_bgn_dt.date);
        // if(this.$refs.abstr_file_reg_end_dt.date != null) params.append("abstr_file_reg_end_dt", this.$refs.abstr_file_reg_end_dt.date);
        // if(this.$refs.abstr_file_upd_bgn_dt.date != null) params.append("abstr_file_upd_bgn_dt", this.$refs.abstr_file_upd_bgn_dt.date);
        // if(this.$refs.abstr_file_upd_end_dt.date != null) params.append("abstr_file_upd_end_dt", this.$refs.abstr_file_upd_end_dt.date);
        // if(this.$refs.pre_reg_bgn_dt.date != null)     params.append("pre_reg_bgn_dt", this.$refs.pre_reg_bgn_dt.date);
        // if(this.$refs.pre_reg_end_dt.date != null)     params.append("pre_reg_end_dt", this.$refs.pre_reg_end_dt.date);

        // if(this.$refs.abstr_reg_bgn_tm.time != null) params.append("abstr_reg_bgn_tm", this.$refs.abstr_reg_bgn_tm.time);
        // if(this.$refs.abstr_reg_end_tm.time != null) params.append("abstr_reg_end_tm", this.$refs.abstr_reg_end_tm.time);
        // if(this.$refs.abstr_file_reg_bgn_tm.time != null) params.append("abstr_file_reg_bgn_tm", this.$refs.abstr_file_reg_bgn_tm.time);
        // if(this.$refs.abstr_file_reg_end_tm.time != null) params.append("abstr_file_reg_end_tm", this.$refs.abstr_file_reg_end_tm.time);
        // if(this.$refs.abstr_file_upd_bgn_tm.time != null) params.append("abstr_file_upd_bgn_tm", this.$refs.abstr_file_upd_bgn_tm.time);
        // if(this.$refs.abstr_file_upd_end_tm.time != null) params.append("abstr_file_upd_end_tm", this.$refs.abstr_file_upd_end_tm.time);
        // if(this.$refs.pre_reg_bgn_tm.time != null)     params.append("pre_reg_bgn_tm", this.$refs.pre_reg_bgn_tm.time);
        // if(this.$refs.pre_reg_end_tm.time != null)     params.append("pre_reg_end_tm", this.$refs.pre_reg_end_tm.time);
        if(this.confr_bgn_dt != null)     params.append("confr_bgn_dt", this.confr_bgn_dt);
        if(this.confr_end_dt != null)     params.append("confr_end_dt", this.confr_end_dt);
        if(this.abstr_reg_bgn_dt != null) params.append("abstr_reg_bgn_dt", this.abstr_reg_bgn_dt);
        if(this.abstr_reg_end_dt != null) params.append("abstr_reg_end_dt", this.abstr_reg_end_dt);
        if(this.abstr_file_reg_bgn_dt != null) params.append("abstr_file_reg_bgn_dt", this.abstr_file_reg_bgn_dt);
        if(this.abstr_file_reg_end_dt != null) params.append("abstr_file_reg_end_dt", this.abstr_file_reg_end_dt);
        if(this.abstr_file_upd_bgn_dt != null) params.append("abstr_file_upd_bgn_dt", this.abstr_file_upd_bgn_dt);
        if(this.abstr_file_upd_end_dt != null) params.append("abstr_file_upd_end_dt", this.abstr_file_upd_end_dt);
        if(this.pre_reg_bgn_dt != null)     params.append("pre_reg_bgn_dt", this.pre_reg_bgn_dt);
        if(this.pre_reg_end_dt != null)     params.append("pre_reg_end_dt", this.pre_reg_end_dt);

        if(this.abstr_reg_bgn_tm != null) params.append("abstr_reg_bgn_tm", this.abstr_reg_bgn_tm);
        if(this.abstr_reg_end_tm != null) params.append("abstr_reg_end_tm", this.abstr_reg_end_tm);
        if(this.abstr_file_reg_bgn_tm != null) params.append("abstr_file_reg_bgn_tm", this.abstr_file_reg_bgn_tm);
        if(this.abstr_file_reg_end_tm != null) params.append("abstr_file_reg_end_tm", this.abstr_file_reg_end_tm);
        if(this.abstr_file_upd_bgn_tm != null) params.append("abstr_file_upd_bgn_tm", this.abstr_file_upd_bgn_tm);
        if(this.abstr_file_upd_end_tm != null) params.append("abstr_file_upd_end_tm", this.abstr_file_upd_end_tm);
        if(this.pre_reg_bgn_tm != null)     params.append("pre_reg_bgn_tm", this.pre_reg_bgn_tm);
        if(this.pre_reg_end_tm != null)     params.append("pre_reg_end_tm", this.pre_reg_end_tm);

        // 첨부 파일
        const file_info_list = [];

        if(this.bnrImgSelectedFile != null) {
          params.append('file_list', this.bnrImgSelectedFile);
          file_info_list.push({item:"bnr_img"});
        } 

        if(this.ofdocSelectedFile != null) {
          params.append('file_list', this.ofdocSelectedFile);
          file_info_list.push({item:"ofdoc"});
        } 

        params.append("file_info_list", JSON.stringify(file_info_list));

        // 학술대회 메뉴
        // const confrMenuList = [];

        // this.confrMenuList.forEach((e) => {
        //   if(e.use_yn) {
        //     confrMenuList.push({confr_sno:this.confrSno, confr_menu:e.cmn_cd, disp_odr:e.disp_odr});
        //   }
        // });
        // console.log("confrMenuList:", confrMenuList);
        // let ret = true;
        // if(ret) return;

        // params.append("confr_menu_list", JSON.stringify(confrMenuList));

        this.$http.post("/admin/upsertConfr", params).then((res) => {
          // console.log(res.data);
          // alert("저장되었습니다.");

          if(this.newYn == 'Y') {
            this.confrSno = res.data.confr_sno;
            this.$emit('confrCreated', this.confrSno);
          }

          this.getConfr();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
      // }
    },
    // fileChanged(file, item) {
    //   console.log("item[" + item + "] changed.", file);
    // },
    deleteFileBtnClicked(fileItem, filePath, fileAtchSno) {
      if(confirm("삭제하시겠습니까?")) {
        // console.log("filePath:", filePath);
        // console.log("atchSno:", fileAtchSno);
        
        if(filePath == null) {
          alert("삭제할 파일이 없습니다.");
          return;
        }

        const params = new FormData();

        params.append("confr_sno", this.confrSno);
        params.append("mber_sno", this.mberSno);
        params.append("file_item", fileItem);
        params.append("file_path", filePath);
        params.append("file_atch_sno", fileAtchSno);

        this.$http.post("/admin/deleteConfrFile", params).then((res) => {
          console.log(res.data);
          alert("삭제되었습니다.");
          
          this.getConfr();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'conferenceInfoTab');
    },
    setSelectedDate(selected) {
      this.itemChanged();
      if(this.dateNm == "confr_bgn_dt") {
        this.confr_bgn_dt = selected;
      } else if(this.dateNm == "confr_end_dt") {
        this.confr_end_dt = selected;
      } else if(this.dateNm == "abstr_reg_bgn_dt") {
        this.abstr_reg_bgn_dt = selected;
      } else if(this.dateNm == "abstr_reg_end_dt") {
        this.abstr_reg_end_dt = selected;
      } else if(this.dateNm == "abstr_file_reg_bgn_dt") {
        this.abstr_file_reg_bgn_dt = selected;
      } else if(this.dateNm == "abstr_file_reg_end_dt") {
        this.abstr_file_reg_end_dt = selected;
      } else if(this.dateNm == "abstr_file_upd_bgn_dt") {
        this.abstr_file_upd_bgn_dt = selected;
      } else if(this.dateNm == "abstr_file_upd_end_dt") {
        this.abstr_file_upd_end_dt = selected;
      } else if(this.dateNm == "pre_reg_bgn_dt") {
        this.pre_reg_bgn_dt = selected;
      } else if(this.dateNm == "pre_reg_end_dt") {
        this.pre_reg_end_dt = selected;
      }
        
      this.datePopup = false;
    },//setSelectedDate end
    popupDatePicker(dateNm) {
      this.dateNm = dateNm;
      this.datePopup = true;
    },//popupDatePicker end
  },
}
</script>

<style>
 .outline-wh .v-text-field--outlined fieldset  {
    color: red !important;
}
</style>