<template>
  <v-container fluid class="">
    <!-- <v-row class="pa-0 mb-10">
      <v-col cols="12" class="pa-0 d-flex align-center">
        <h1 class="mr-3">test</h1>
        <div><v-icon class="mr-3">mdi-power-on</v-icon>설명이 들어갑니다.</div>
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px mb-5"></v-col>
      <v-col cols="12" class="mb-5">
        <div>
          <span>
            <v-icon class="mr-2" color="#B43104">mdi-tooltip-check</v-icon>
            ?무슨 내용?
          </span>
        </div>
      </v-col>
    </v-row> -->

    <!-- 한줄에 3개 -->
    <v-row class="ma-0 pa-0 mb-10" v-if="dispMap && (dispMap.disp_cnt == 0 || dispMap.disp_cnt == 3)">
      <v-col cols="12" class="d-flex align-center">
        <h1 class="mr-3">후원사</h1>
        <!-- <div><v-icon class="mr-3">mdi-power-on</v-icon>설명이 들어갑니다.</div> -->
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px mb-5"></v-col>
    </v-row>
    <v-row class="mt-10 mb-10" style="" v-if="dispMap && (dispMap.disp_cnt == 0 || dispMap.disp_cnt == 3)">
      <v-col 
        cols="4" 
        class="spnGridThree hei-200p d-flex justify-center align-center" 
        v-for="(item, idx) in spnsrList"
        :key="`spnsrList3_`+idx"
      >
        <div class=" wid-200p hei-100p d-flex justify-center">
          <a :href="item.link_url" target="_blank"><img class="" style="height:100px; object-fit:cover !important;" :src="item.img_atch_path"></a>
        </div>
      </v-col>
    </v-row>
    
    <!-- 한줄에 4개 -->
    <v-row class="ma-0 pa-0 mb-10" v-if="dispMap &&  dispMap.disp_cnt == 4">
      <v-col cols="12" class="d-flex align-center">
        <h1 class="mr-3">후원사</h1>
        <!-- <div><v-icon class="mr-3">mdi-power-on</v-icon>설명이 들어갑니다.</div> -->
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px mb-5"></v-col>
    </v-row>
    <v-row class="mt-10 mb-10 pb-10" style="" v-if="dispMap &&  dispMap.disp_cnt == 4">
      <v-col 
        cols="3" 
        class="spnGridFour hei-200p d-flex justify-center align-center" 
        v-for="(item, idx) in spnsrList"
        :key="`spnsrList4_`+idx"
      >
        <div class=" wid-200p hei-100p d-flex justify-center">
          <a :href="item.link_url" target="_blank"><img class="" style="height:100px; object-fit:cover !important;" :src="item.img_atch_path"></a>
        </div>
      </v-col>
    </v-row>

    <!-- 한줄에 6개 -->
    <v-row class="ma-0 pa-0 mb-10" v-if="dispMap && dispMap.disp_cnt == 6">
      <v-col cols="12" class="d-flex align-center">
        <h1 class="mr-3">후원사</h1>
        <!-- <div><v-icon class="mr-3">mdi-power-on</v-icon>설명이 들어갑니다.</div> -->
      </v-col>
      <v-col cols="12" class="border-b-darkgrey-2px mb-5"></v-col>
    </v-row>
    <v-row class="mt-10 mb-10" v-if="dispMap && dispMap.disp_cnt == 6">
      <v-col 
        cols="2" 
        class="spnGridSix hei-200p d-flex justify-center align-center" 
        v-for="(item, idx) in spnsrList"
        :key="`spnsrList6_`+idx"
      >
        <div class="pa-2">
          <a :href="item.link_url" target="_blank">
            <div class="wid-150p hei-80p">
              <img class="imgDf" :src="item.img_atch_path">
            </div>
          </a>
        </div>
      </v-col>
    </v-row>    

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      dispMap: {},
      spnsrList: [],
      emitData: {},
    }
  },
  created() {
    const formData = new FormData();
    formData.append('mber_sno', this.mberSno);
    formData.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath,formData).then((res) => {
      console.log(res.data);

      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push("/");
        return;
      } else {
        this.dispMap = res.data.dispMap;
        this.spnsrList = res.data.spnsrList;
      }
        this.emitData.menu_sno = res.data.menu_sno;
        this.$emit('setData', this.emitData);

    }).catch(
      function (error) {
      console.log(error);
      }
    )
  },
}
</script>

<style scoped>
.spnGridSix:nth-child(6n-5) {
  border-left:none !important;
}
.spnGridSix{
  border-left:1px dashed #A4A4A4; border-bottom: 1px dashed #A4A4A4;
}
.spnGridFour:nth-child(4n-3) {
  border-left:none !important;
}
.spnGridFour{
  border-left:1px dashed #A4A4A4; border-bottom: 1px dashed #A4A4A4;
}
.spnGridThree:nth-child(3n-2) {
  border-left:none !important;
}
.spnGridThree{
  border-left:1px dashed #A4A4A4; border-bottom: 1px dashed #A4A4A4;
}
</style>