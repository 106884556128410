<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="">
        <v-row dense align="center">
          <!-- 회원 종류 -->
          <v-col cols="12" v-if="mberItemSet.mber_kind.use_yn && (newYn == 'N' || mberItemSet.mber_kind.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>회원 종류</span><span v-if="mberItemSet.mber_kind.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="filteredMberKindList"
                  item-value="mber_kind"
                  item-text="mber_kind_nm"
                  v-model="mber_kind"
                  hide-details
                  dense
                  outlined
                  @change="itemChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 로그인ID -->
          <v-col cols="12" v-if="mberItemSet.login_id.use_yn && (newYn == 'N' || mberItemSet.login_id.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>로그인ID</span><span v-if="mberItemSet.login_id.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="login_id"
                  :readonly="newYn == 'N'"
                  :background-color="newYn == 'N' ? 'grey lighten-2' : ''"
                  @change="loginIdChanged"
                ></v-text-field>
              </v-col>
              <v-btn
                v-show="newYn == 'Y'"
                small
                color="blue lighten-2"
                class="white--text f-r"
                hide-details
                @click="loginIdDupCheckClicked()"
              >중복체크</v-btn>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 비밀번호 -->
          <v-col cols="12">
            <v-row dense align="center">
              <v-col cols="2">
                <span>비밀번호</span><span v-if="this.newYn == 'Y'"> *</span>
              </v-col>
              <v-col cols="3" v-if="newYn == 'N'">
                <div class="wid-100">
                  <v-btn
                    small
                    color="blue lighten-2"
                    class="white--text"
                    @click="pwdChgPopupClicked"
                  >
                  비밀번호 변경
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="3" v-if="newYn == 'Y'">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="pwd"
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 비밀번호 확인 -->
          <v-col cols="12" v-if="newYn == 'Y'">
            <v-row dense align="center">
              <v-col cols="2">
                <span>비밀번호 확인 *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="pwdChk"
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 회원상태 -->
          <v-col cols="12" v-if="mberItemSet.mber_stus.use_yn && (newYn == 'N' || mberItemSet.mber_stus.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>회원 상태</span><span v-if="mberItemSet.mber_stus.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="mberStusList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="mber_stus"
                  hide-details
                  dense
                  outlined
                  @change="itemChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 회원권한 -->
          <v-col cols="12" v-if="mberItemSet.mber_priv.use_yn && (newYn == 'N' || mberItemSet.mber_priv.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>회원 권한</span><span v-if="mberItemSet.mber_priv.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-select
                  class="wid-100 pt-1" 
                  :items="mberPrivList"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  v-model="mber_priv"
                  hide-details
                  dense
                  outlined
                  @change="itemChanged"
                ></v-select>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 학회 가입일자 -->
          <v-col cols="12" v-if="mberItemSet.socty_join_dt.use_yn && (newYn == 'N' || mberItemSet.socty_join_dt.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <div class="pt-0">
                  <span>학회 가입일자</span>
                  <span v-if="mberItemSet.socty_join_dt.ess_yn"> *</span>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="wid-70">
                  <v-text-field
                    class="bg-white"
                    v-model="socty_join_dt"
                    hide-details
                    dense
                    outlined
                    append-icon="mdi-calendar"
                    @click:append="popupDatePicker('socty_join_dt')"
                    placeholder="0000-00-00"
                  ></v-text-field>
                  <date-picker-popup v-if="datePopup" :p_curDate="curDate" @close="datePopup = false" @selected="setSelectedDate" @keydown.esc="datePopup = false"></date-picker-popup>
                  <!-- 원본 -->
                  <!-- <datePicker v-model="socty_join_dt"></datePicker> -->
                  <!-- 원본 end -->
                  <!-- <datePicker 
                    p_dateName="socty_join_dt" 
                    ref="socty_join_dt"
                    @input="itemChanged"
                  ></datePicker> -->
                </div>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 홈페이지 가입일자 -->
          <v-col cols="12">
            <v-row dense align="center">
              <v-col cols="2">
                <span>홈페이지 가입일자</span>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  class="wid-70"
                  hide-details
                  dense
                  outlined
                  readonly
                  background-color="grey lighten-2"
                  v-model="reg_dt"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 정권/복권일자 -->
          <!-- 회원번호 -->
          <v-col cols="12" v-if="newYn == 'N'">
            <v-row dense align="center">
              <v-col cols="2">
                <span>회원 번호</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  readonly
                  background-color="grey lighten-2"
                  v-model="mberSno"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 추천인 이름 -->
          <v-col cols="12" v-if="mberItemSet.rcmdr_nm.use_yn && (newYn == 'N' || mberItemSet.rcmdr_nm.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>추천인 이름</span><span v-if="mberItemSet.rcmdr_nm.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="rcmdr_nm"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 추천인 소속 -->
          <v-col cols="12" v-if="mberItemSet.rcmdr_blng.use_yn && (newYn == 'N' || mberItemSet.rcmdr_blng.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>추천인 소속</span><span v-if="mberItemSet.rcmdr_blng.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="rcmdr_blng"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 추천인 직위 -->
          <v-col cols="12" v-if="mberItemSet.rcmdr_pstn.use_yn && (newYn == 'N' || mberItemSet.rcmdr_pstn.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2">
                <span>추천인 직위</span><span v-if="mberItemSet.rcmdr_pstn.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="wid-100"
                  hide-details
                  dense
                  outlined
                  v-model="rcmdr_pstn"
                  @change="itemChanged"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>  
            </v-row>
          </v-col>
          <!-- 연회비 면제 여부 -->
          <v-col cols="12" v-if="mberItemSet.anul_mfee_exmpt_yn.use_yn && (newYn == 'N' || mberItemSet.anul_mfee_exmpt_yn.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <span>연회비 면제</span>
              </v-col>
              <v-col cols="3">
                <v-checkbox 
                  hide-details
                  dense
                  v-model="anul_mfee_exmpt_yn"
                  label="면제함"
                  @change="itemChanged"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 이용약관 동의 여부 -->
          <v-col cols="12" v-if="mberItemSet.terms_agree_yn.use_yn && (newYn == 'N' || mberItemSet.terms_agree_yn.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <span>이용약관 동의</span><span v-if="mberItemSet.terms_agree_yn.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-checkbox 
                  hide-details
                  dense
                  v-model="terms_agree_yn"
                  label="동의함"
                  @change="itemChanged"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 개인정보처리방침 동의 여부 -->
          <v-col cols="12" v-if="mberItemSet.prvcy_polcy_agree_yn.use_yn && (newYn == 'N' || mberItemSet.prvcy_polcy_agree_yn.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <span>개인정보처리방침 동의</span><span v-if="mberItemSet.prvcy_polcy_agree_yn.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-checkbox 
                  hide-details
                  dense
                  v-model="prvcy_polcy_agree_yn"
                  label="동의함"
                  @change="itemChanged"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 이메일 수신 여부 -->
          <v-col cols="12" v-if="mberItemSet.email_recv_agree_yn.use_yn && (newYn == 'N' || mberItemSet.email_recv_agree_yn.join_colct_yn)">
            <v-row dense align="center">
              <v-col cols="2" >
                <span>이메일수신 동의</span><span v-if="mberItemSet.email_recv_agree_yn.ess_yn"> *</span>
              </v-col>
              <v-col cols="3">
                <v-checkbox 
                  hide-details
                  dense
                  v-model="email_recv_agree_yn"
                  label="동의함"
                  @change="itemChanged"
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
            </v-row>
          </v-col>
          <!-- 우편물 수신 여부 -->

        </v-row>
      </div>
    </v-layout>
    
    <template>
      <v-dialog v-model="pwdChangeDialog" max-width="500px" max-height="300px">
        <v-card>
          <v-app-bar class="fixed-bar" color="blue lighten-2" max-height="50px">
            <v-toolbar-title class="white--text pa-0 ma-0"> 비밀번호 변경 </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              @click="pwdChangeDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-card align-center>
            <div class="pa-4">
              <v-form ref="form" lazy-validation>
                <v-row class="pl-4 pr-4" align="center">
                  <!-- <v-col cols="4" class="pa-0 ma-0">
                    <div class="pt-3">
                      <span>현재 비밀번호 입력</span>
                    </div>
                  </v-col>
                  <v-col cols="8" class="">
                    <v-text-field outlined hide-details dense type="password" v-model="currentPwd"/>
                  </v-col> -->
                  <v-col cols="4" class="pa-0 ma-0">
                    <div class="pt-3">
                      <span>변경 비밀번호 입력</span>
                    </div>
                  </v-col>
                  <v-col cols="8" class="">
                    <v-text-field outlined hide-details dense type="password" v-model="chgPwd"/>
                  </v-col>
                  <v-col cols="4" class="pa-0 ma-0">
                    <div class="pt-3">
                      <span>변경 비밀번호 확인</span>
                    </div>
                  </v-col>
                  <v-col cols="8" class="">
                    <v-text-field outlined hide-details dense type="password" v-model="chgPwdChk"/>
                  </v-col>
                  <v-col cols="6" class="btn-20" style="text-align:center;">
                    <v-btn small block class="mr-5 text-capitalize white--text" color="red lighten-1" @click="pwdChangeDialog=false">취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="btn-20" style="text-align:center;">
                    <v-btn small block class="text-capitalize white--text" color="blue lighten-1" @click="pwdChgBtnClicked" >비밀번호 변경</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card>
        </v-card>
      </v-dialog>
    </template>
  </v-container>

</template>

<script>
// import datePicker from "../../../common/datePicker.vue";
import datePickerPopup from '@/components/common/datePickerPopup.vue';

export default {
  props: ["propParams"],
  components: {datePickerPopup, },
  data(){
    return {
      mberItemSet: {
        login_id:{use_yn:true, join_colct_yn:true, ess_yn:false},
        pwd:{use_yn:true, join_colct_yn:true, ess_yn:false},
        mber_div:{use_yn:true, join_colct_yn:true, ess_yn:false},
        mber_kind:{use_yn:true, join_colct_yn:true, ess_yn:false},
        mber_stus:{use_yn:true, join_colct_yn:true, ess_yn:false},
        mber_priv:{use_yn:true, join_colct_yn:true, ess_yn:false},
        socty_join_dt:{use_yn:true, join_colct_yn:true, ess_yn:false},
        rcmdr_nm:{use_yn:true, join_colct_yn:true, ess_yn:false},
        rcmdr_blng:{use_yn:true, join_colct_yn:true, ess_yn:false},
        rcmdr_pstn:{use_yn:true, join_colct_yn:true, ess_yn:false},
        anul_mfee_exmpt_yn:{use_yn:true, join_colct_yn:true, ess_yn:false},
        terms_agree_yn:{use_yn:true, join_colct_yn:true, ess_yn:false},
        prvcy_polcy_agree_yn:{use_yn:true, join_colct_yn:true, ess_yn:false},
        email_recv_agree_yn:{use_yn:true, join_colct_yn:true, ess_yn:false},
      },
      // mberBasic: null,
      mberSctnId: 'mber_basic',

      mber_kind: null,
      login_id: null,
      pwd: null,
      mber_div: null,
      mber_stus: null,
      mber_priv: null,
      socty_join_dt: null,
      rcmdr_nm: null,
      rcmdr_blng: null,
      rcmdr_pstn: null,
      anul_mfee_exmpt_yn: false,
      terms_agree_yn: false,
      prvcy_polcy_agree_yn: false,
      email_recv_agree_yn: false,
      reg_dt:null,

      mberKindList: [],
      filteredMberKindList: [],     // 필터된 회원종류 리스트
      mberStusList: [],
      mberPrivList: [],

      mberSno: '',
      pwdChangeDialog: false,
      redrawCount: 0,
      currentPwd: '',
      chgPwd: '',
      chgPwdChk: '',

      newYn: 'N',
      pwdChk: '',
      loginMberSno: "",   // 로그인 멤버

      loginIdDupYn: false,
      datePopup: false,
      curDate: "",
    }
  },
  created() {
    this.loginMberSno = this.$session.get("mberSno"); // 로그인 멤버
    this.mberSno = this.propParams.mberSno;
    // this.mberSctnId = this.propParams.mberSctnId;
    this.newYn = this.propParams.newYn;
    
    this.mber_div = this.propParams.mberDiv;
    console.log("mber_div:", this.mber_div, this.newYn);
    
    this.getMberKindList();
    this.getMberStusList();
    this.getMberPrivList();

    this.getMberBasic();
  },
  watch: {
    socty_join_dt(newVal, oldVal) {
      console.log('Message changed from ' + oldVal + ' to ' + newVal);
      if(this.mberMap.socty_join_dt != this.socty_join_dt) {
        this.itemChanged();
      }
      
    }
  },
  methods: {
    async getMberStusList() {
      this.mberStusList = await this.$getCmnCdList("mber_stus");
    },
    async getMberPrivList() {
      this.mberPrivList = await this.$getCmnCdList("mber_priv");
    },
    getMberKindList() {
      this.$http.get("/admin/getSoctyMberKindList").then((res) => {        
        this.mberKindList = res.data.result.mberKindList;
        this.filteredMberKindList = this.mberKindList.filter((e) => {
          return e.join_disp_yn && ((this.mber_div == 'indv' && e.indv_mber_kind_yn == 'Y') || (this.mber_div == 'grp' && e.grp_mber_kind_yn == 'Y'));
        });
      }).catch((error) => {
          console.log(error);
        }
      )
    },
    getMberBasic() {
      // if(this.mberSno == null) {
      //   return;
      // }

      const formData = new FormData();

      formData.append("mber_sctn_id", this.mberSctnId);
      formData.append("mber_sno", this.mberSno);

      this.$http.post("/admin/getMberBasic", formData).then((res) => {
        const result = res.data.result;

        // console.log("mberItemList:", result.mberItemList);

        let mberItemSet = {};
        result.mberItemList.forEach((e) => {
          // console.log(e);
          mberItemSet[e.mber_item_id] = {use_yn:e.use_yn == 'Y' ? true : false, join_colct_yn:e.join_colct_yn == 'Y' ? true : false, ess_yn:e.ess_yn == 'Y' ? true : false};
        });
        // console.log("mberItemSet:", mberItemSet);
        // console.log("mberItemSet.login_id.ess_yn:", mberItemSet.login_id.ess_yn);
        this.mberItemSet = mberItemSet;

        this.siteOptn = result.siteOptn;

        if(this.newYn == 'Y') {
          return;
        }


        this.mberMap = result.mberBasic;
        const mber = result.mberBasic;
        console.log("mber:", mber);

        this.mber_kind = mber.mber_kind;
        this.login_id = mber.login_id;
        this.mber_div = mber.mber_div;
        this.mber_stus = mber.mber_stus;
        this.mber_priv = mber.mber_priv;
        this.socty_join_dt = mber.socty_join_dt;
        this.rcmdr_nm = mber.rcmdr_nm;
        this.rcmdr_blng = mber.rcmdr_blng;
        this.rcmdr_pstn = mber.rcmdr_pstn;
        this.anul_mfee_exmpt_yn = mber.anul_mfee_exmpt_yn == 'Y' ? true : false;
        this.terms_agree_yn = mber.terms_agree_yn == 'Y' ? true : false;
        this.prvcy_polcy_agree_yn = mber.prvcy_polcy_agree_yn == 'Y' ? true : false;
        this.email_recv_agree_yn = mber.email_recv_agree_yn == 'Y' ? true : false;

        this.reg_dt = mber.reg_dt;

        // this.redrawCount++;
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
      
    },
    validateInput() {
      if(this.newYn == 'Y' && !this.loginIdDupYn) {
        alert('로그인ID 중복체크 되지 않았습니다.');
        return false;
      }
      // 회원 종류
      if(this.mberItemSet.mber_kind.ess_yn && (this.mber_kind == null || this.mber_kind =="")) {
        alert("회원 종류는 필수 입력값입니다.");
        return false;
      }
      // 로그인ID
      if(this.mberItemSet.login_id.ess_yn && (this.login_id == null || this.login_id =="")) {
        alert("로그인ID는 필수 입력값입니다.");
        return false;
      }
      // 비밀번호
      if(this.newYn == 'Y' && this.mberItemSet.pwd.ess_yn) {
        if(this.pwd == null || this.pwd =="") {
          alert("비밀번호는 필수 입력값입니다.");
          return false;
        }
        if(this.pwdChk == null || this.pwdChk =="") {
          alert("비밀번호 확인은 필수 입력값입니다.");
          return false;
        }
        if(this.pwd != this.pwdChk) {
          alert('비밀번호와 비밀번호 확인 값이 일치하지 않습니다.');
          return false;
        }
      }
      // 학회가입일자
      if(this.mberItemSet.socty_join_dt.ess_yn && (this.socty_join_dt == null || this.socty_join_dt =="")) {
        alert("학회 가입일자는 필수 입력값입니다.");
        return false;
      }
      // 추천인 이름
      if(this.mberItemSet.rcmdr_nm.ess_yn && (this.rcmdr_nm == null || this.rcmdr_nm =="")) {
        alert("추천인 이름은 필수 입력값입니다.");
        return false;
      }
      // 추천인 소속
      if(this.mberItemSet.rcmdr_blng.ess_yn && (this.rcmdr_blng == null || this.rcmdr_blng =="")) {
        alert("추천인 소속은 필수 입력값입니다.");
        return false;
      }
      // 추천인 직위
      if(this.mberItemSet.rcmdr_pstn.ess_yn && (this.rcmdr_pstn == null || this.rcmdr_pstn =="")) {
        alert("추천인 직위는 필수 입력값입니다.");
        return false;
      }
      // 이용약관 동의 여부
      if(this.mberItemSet.terms_agree_yn.ess_yn && !this.terms_agree_yn) {
        alert("이용약관 동의 여부는 필수 입력값입니다.");
        return false;
      }
      // 개인정보처리방침 동의 여부
      if(this.mberItemSet.prvcy_polcy_agree_yn.ess_yn && !this.prvcy_polcy_agree_yn) {
        alert("개인정보처리방침 동의 여부는 필수 입력값입니다.");
        return false;
      }
      // 이메일수신 동의 여부
      if(this.mberItemSet.email_recv_agree_yn.ess_yn && !this.email_recv_agree_yn) {
        alert("이메일수신 동의 여부는 필수 입력값입니다.");
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      console.log("memberBasic > saveBtnClicked");
      console.log("mber_div:", this.mber_div, this.newYn);
      
      const params = new FormData();
      params.append("mber_sctn_id", this.mberSctnId);
      params.append("mber_sno", this.mberSno);
      params.append('new_yn', this.newYn);
      params.append("login_mber_sno", this.loginMberSno);

      if(this.mber_kind != null )   params.append("mber_kind", this.mber_kind);
      if(this.login_id != null )    params.append("login_id", this.login_id);
      if(this.pwd != null )         params.append("pwd", this.pwd);
      if(this.mber_div != null )    params.append("mber_div", this.mber_div);
      if(this.mber_stus != null )   params.append("mber_stus", this.mber_stus);
      if(this.mber_priv != null )   params.append("mber_priv", this.mber_priv);
      if(this.socty_join_dt != null ) params.append("socty_join_dt", this.socty_join_dt);
      if(this.rcmdr_nm != null )    params.append("rcmdr_nm", this.rcmdr_nm);
      if(this.rcmdr_blng != null )  params.append("rcmdr_blng", this.rcmdr_blng);
      if(this.rcmdr_pstn != null )  params.append("rcmdr_pstn", this.rcmdr_pstn);
      if(this.anul_mfee_exmpt_yn != null )   params.append("anul_mfee_exmpt_yn", this.anul_mfee_exmpt_yn == true ? 'Y' : 'N');
      if(this.terms_agree_yn != null )  params.append("terms_agree_yn", this.terms_agree_yn == true ? 'Y' : 'N');
      if(this.prvcy_polcy_agree_yn != null )  params.append("prvcy_polcy_agree_yn", this.prvcy_polcy_agree_yn == true ? 'Y' : 'N');
      if(this.email_recv_agree_yn != null )  params.append("email_recv_agree_yn", this.email_recv_agree_yn == true ? 'Y' : 'N');
                
      this.$http.post("/admin/upsertMberBasic", params).then((res) => {
        if(res.data.code == 0) {
          alert("저장되었습니다.");
          // this.chgYnCheck('Y');
          if(this.newYn == 'Y') {
            this.mberSno = res.data.mber_sno;
            this.newYn = 'N';
            this.$emit('mberCreated', this.mberSno);
          }

          this.getMberBasic();
          // this.$router.go(0)
        } else {
          alert("실패");
        }
      }).catch(function(error) {
          console.log(error);
      });//axios End

    },//saveBtnClicked End
    
    pwdChgPopupClicked() {
      this.pwdChangeDialog = true;
    },//pwdChgPopupClicked end
    pwdChgBtnClicked() {
      // if(this.currentPwd == '' || !this.currentPwd) {
      //   alert("현재비밀번호 입력해주세요.");
      //   return;
      // } 
      if((this.chgPwd == '' || !this.chgPwd) || (this.chgPwdChk == '' || !this.chgPwdChk)) {
        alert("변경할 비밀번호 입력해주세요.");
        return;
      } 
      if(this.chgPwd != this.chgPwdChk) {
        alert("변경할 두 비밀번호가 일치하지 않습니다.");
        return;
      }

      const formData = new FormData();

      // formData.append("pwd",this.currentPwd);
      formData.append("chg_pwd",this.chgPwd);
      formData.append("mber_sno",this.mberSno);
      formData.append("login_mber_sno", this.loginMberSno);

      this.$http.post("/admin/updateMberPwd", formData).then((res) => {
        if(res.data.code < 0)
				{
					alert(this.$erroCdMsg(res.data.code));
					return;
				}
        else
				{
					alert('비밀번호 변경되었습니다.');
          //새로고침 할필요없어 수정
          this.pwdChangeDialog = false;
          this.chgPwd = '';
          this.chgPwdChk = '';
          // this.$router.go(0);

          // this.$session.set('mberSno','');
          // this.$session.set('tempPwdYn','N');
          // this.$router.push("/");
          //주석처리
          //다이얼로그팝업 hide
          // this.pwdChangeDialog = false;
          // //비밀번호 변수 초기화
          // this.currentPwd = '';
          // this.chgPwd = '';
          // this.chgPwdChk = '';
				}
      }).catch(
        function (error) {
          console.log(error);
        }
      );

    },//pwdChgBtnClicked end
    itemChanged() {
      this.$emit('tabChanged', 'memberBasicTab');
    },
    loginIdDupCheckClicked() {
      if(!this.login_id ||  this.login_id.trim() == '') {
        alert('로그인ID는 필수 입력값입니다.');
        return;
      }

      if(this.siteOptn.acct_type == "email"){
        if(this.login_id){
          if(!this.$validateEmail(this.login_id)){
            alert("이메일 형식이 올바르지 않습니다.");
            return;
          }
        }
      }else if(this.siteOptn.acct_type == "id"){
        if(this.login_id){
          if(this.$validateId(this.login_id)){
            alert('아이디는 영문 대소문자와 숫자만 입력 가능합니다.')
            return;
          }
        }
      }
      
      const formData = new FormData();
            
      formData.append("login_id", this.login_id);
      this.$http.post("/common/getLoginIdDupChk", formData).then( (res) => {
        if(res.data.code < 0)
        {
          alert(this.$erroCdMsg(res.data.code));
          return;
        }else
        {
          alert('사용가능합니다.');
        }
        this.loginIdDupYn = true;
      }).catch(
        function(error) {
          console.log(error);
        }.bind(this)
      );	
    },
    loginIdChanged() {
      this.loginIdDupYn = false;
    },
    setSelectedDate(selected) {
      
      if(this.dateNm == "socty_join_dt") {
        this.socty_join_dt = selected;
      }
      
      this.datePopup = false;
    },//setSelectedDate end
    popupDatePicker(dateNm) {
      this.dateNm = dateNm;
      this.datePopup = true;
    },//popupDatePicker end
  }
}
</script>
<style scoped>
/deep/ .v-icon.v-icon{
  margin-top:0px;
  padding-bottom:8px;
}
</style>