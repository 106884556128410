<template >
  <v-container fluid class="wid-100">
    <template>
      <v-app-bar height="40" class="elevation-0 z-index ma-0" :style="`background-color:`+point_color_1" v-show="appBarYn">
        <v-container class="hei-40p" style="width: 100px !important;" :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
          <v-row class="ma-0 hei-40p">
            <v-col cols="5" sm="7" xs="5" class="pa-0 pl-2" v-show="windowWidth < 1220" style="width: 100px !important;">
              <div class="d-flex align-center hei-40p" style="width: 100% !important;">
                <img :src="headerData.mob_top_main_logo_path"  @click="mainPageMove" class="mHei-40p imgDf" style="cursor: pointer">
              </div> 
            </v-col>
            
            <v-col cols="5" class="pa-0 hidden-sm-and-down dreamFont3" >
              <div class="float-left wid-100">
              <v-btn 
                text 
                tile 
                height="40" 
                class="ma-0 pa-0 fontSize-0 pl-1 pr-1"
                :style="`background-color:`+point_color_4 + '; color:' + font_color_6 + `!important;`"
                v-show="soctyYn">
                학회사이트
              </v-btn>
              <v-btn 
                text
                tile 
                height="40" 
                class="ma-0 pa-0 fontSize-0 pl-1 pr-1"
                :style="'color:' + font_color_3 + `!important;`"
                v-show="apubFamilySiteMap.jrnl_site_url" 
                :href="apubFamilySiteMap.jrnl_site_url" 
                target="_blank">
                {{ apubFamilySiteMap.jrnl_site_nm }}
              </v-btn>
              <v-btn 
                text  
                tile 
                height="40" 
                class="ma-0 pa-0 fontSize-0 pl-1 pr-1" 
                :style="'color:' + font_color_3 + `!important;`"
                v-show="apubFamilySiteMap.sbmt_sys_url" 
                :href="apubFamilySiteMap.sbmt_sys_url" 
                target="_blank">
                {{ apubFamilySiteMap.sbmt_sys_nm }}
              </v-btn>
              <v-btn 
                text  
                tile 
                height="40" 
                class="ma-0 pa-0 fontSize-0 pl-1 pr-1" 
                :style="'color:' + font_color_3 + `!important;`"
                v-show="apubFamilySiteMap.confr_site_url" 
                :href="apubFamilySiteMap.confr_site_url" 
                target="_blank">
                {{ apubFamilySiteMap.confr_site_nm }}
              </v-btn>
            </div>
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="4" md="6" sm="8" xs="3" class="hidden-sm-and-down pa-0 dreamFont3">
              <div class="float-right">
                <v-btn 
                  text 
                  tile 
                  height="40"
                  class="ma-0 pa-0 fontSize-0 pl-2 pr-2" 
                  :style="'color:' + font_color_3 + `!important;`"
                  v-if="mberSno && nonMberYn == 'N'"
                  to="/mypage/profile"> 
                  <span class="d-none d-sm-flex">{{lang == 'en' ? 'My Info.' : '마이페이지'}}</span>
                  <v-icon class="ml-2">mdi-account</v-icon>
                </v-btn>
                <v-btn 
                  text 
                  tile 
                  height="40"
                  class="ma-0 pa-0 fontSize-0 pl-2 pr-2" 
                  :style="'color:' + font_color_3 + `!important;`"
                  v-if="!mberSno && login_use_yn"
                  @click="loginBtnClicked"> 
                <span class="d-none d-sm-flex">{{lang == 'en' ? 'Login' : '로그인'}}</span>
                  <v-icon class="ml-2">mdi-login-variant</v-icon>
                </v-btn>
                <v-btn 
                  text 
                  tile 
                  height="40"
                  class="ma-0 pa-0 fontSize-0 pl-2 pr-2" 
                  :style="'color:' + font_color_3 + `!important;`"
                  v-if="mberSno"
                  @click="logoutBtnClicked"> 
                  <span class="d-none d-sm-flex">{{lang == 'en' ? 'Logout' : '로그아웃'}}</span>
                  <v-icon class="ml-2">mdi-logout-variant</v-icon>
                </v-btn>
                <v-btn
                  v-if="!mberSno && login_use_yn" 
                  text 
                  tile 
                  height="40"
                  class="ma-0 pa-0 fontSize-0 pl-2 pr-2" 
                  :style="'color:' + font_color_3 + `!important;`"
                  @click="joinDialLogClicked">
                  <span class="d-none d-sm-flex">{{lang == 'en' ? 'Signup' : '회원가입'}}</span>
                  <v-icon class="ml-2">mdi-account-plus</v-icon>
                </v-btn>
                <v-btn 
                  text 
                  tile 
                  height="40"
                  class="ma-0 pa-0 fontSize-0 pl-2 pr-2"
                  :style="'color:' + font_color_3 + `!important;`"
                  @click="langBtnClicked"
                  v-if="en_page_use_yn">
                  <span class="d-none d-sm-flex">{{ lang == 'en' ? '한글' : 'English' }}</span>
                  <v-icon class="ml-2">mdi-web</v-icon>
                </v-btn>
                <v-btn
                  text 
                  tile
                  @click="hMenuShow"
                  height="40"
                  :class="this.hmenuShow == true ? 'bg-darkgrey' : ''"
				          class="ma-0 pa-0 fontSize-0 pl-2 pr-2"
                  :style="'color:' + font_color_3 + `!important;`">
                  <span class="d-none d-sm-flex">{{lang == 'en' ? 'menu' : '전체메뉴'}}</span>
                  <v-icon class="ml-2" v-if="this.hmenuShow==false">mdi-menu</v-icon>
                  <v-icon class="ml-2" v-if="this.hmenuShow==true">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>

            <!-- [[[[[[[ mobile break point ]]]]]]] -->
            
            <v-col cols="7" md="5" sm="5" xs="8" class="hidden-md-and-up pa-0">
              <div class="float-right">
                <!-- <v-btn 
                  text 
                  tile 
                  height="40"
                  class="ma-0 pa-0 white--text fontSize-0 pl-2 pr-2" 
                  v-if="mberSno && nonMberYn == 'N'"
                  to="/mypage/profile"> 
                  <v-icon class="ml-2">mdi-account</v-icon>
                </v-btn> -->
                <v-btn
                  text 
                  tile
                  small
                  height="40"
                  class="ma-0 pa-0 white--text fontSize-0" 
                  v-if="mberSno && nonMberYn == 'N'"
                  :style="`color:` + font_color_3 + `!important;`"
                  @click="mobMyMenuShow"
                  :class="this.mobileMyMenuShow == true ? 'bg-darkgrey' : ''">
                  <v-icon class="" v-if="this.mobileMyMenuShow==false">mdi-account</v-icon>
                  <v-icon class="" v-if="this.mobileMyMenuShow==true">mdi-close</v-icon>
                </v-btn>
                <v-btn 
                  text
                  tile
                  small 
                  height="40"
                  :style="`color:` + font_color_3 + `!important;`"
                  class="ma-0 pa-0 white--text fontSize-0 " 
                  v-if="!mberSno && login_use_yn"
                  @click="loginBtnClicked"> 
                  <v-icon class="">mdi-login-variant</v-icon>
                </v-btn>
                <v-btn 
                  text
                  tile
                  small
                  height="40"
                  :style="`color:` + font_color_3 + `!important;`"
                  class="ma-0 pa-0 white--text fontSize-0" 
                  v-if="mberSno"
                  @click="logoutBtnClicked"> 
                  <v-icon class="">mdi-logout-variant</v-icon>
                </v-btn>
                <v-btn
                  v-if="!mberSno && login_use_yn" 
                  text 
                  tile
                  small
                  height="40"
                  :style="`color:` + font_color_3 + `!important;`"
                  class="ma-0 pa-0 white--text fontSize-0" 
                  @click="joinDialLogClicked">
                  <v-icon class="">mdi-account-plus</v-icon>
                </v-btn>
                <v-btn 
                  text 
                  tile
                  small
                  height="40"
                  v-if="en_page_use_yn"
                  :style="`color:` + font_color_3 + `!important;`"
                  :class="this.mobileLangMenuShow == true ? 'bg-darkgrey' : ''"
                  @click="mobLangMenuShow"
                  class="ma-0 pa-0 white--text fontSize-0">
				          <v-icon class="">mdi-web</v-icon>
                </v-btn>
                <v-btn
                  text
                  tile
                  small 
                  @click="hMenuShow"
                  height="40"
                  :class="this.hmenuShow == true ? 'bg-darkgrey' : ''"
                  :style="`color:` + font_color_3 + `!important;`"
                  class="ma-0 pa-0 white--text fontSize-0">
                  <v-icon class="" v-if="this.hmenuShow==false">mdi-menu</v-icon>
                  <v-icon class="" v-if="this.hmenuShow==true">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>
    </template>
    
    <!-- menu pull down -->
    <template>
      <v-container v-if="hmenuShow"  fluid class="hei-100vh bg-darkgrey z-index-9999 position-absolute">
        <v-container class="hidden-sm-and-down" v-show="this.windowWidth > 960" >
          <v-row class="mt-0 pt-3">
            <template class="">
              <v-row class="ma-0 pa-0 hei-90vh wid-100 scroll-y" row wrap >
                <div class="wid-100 hei-90vh d-flex pt-4 scrollbar scroll-x justify-center">

                  <div class="wid-12 hei-90vh-auto border-lr-darkgrey" 
                    v-for="(menu1 , idx1) in menuList.filter((e) => e.menu_lvl == 1)" 
                    :key="`menu1_`+idx1">
                    <v-list
                      class="bg-darkgrey"
                      dense>
                      <v-list-item-title class="pl-3 pb-2 fontSize-6 font-weight-bold text-blue font-">
                        {{lang == 'en' ? menu1.menu_nm_en : menu1.menu_nm}}
                      </v-list-item-title>
                      <v-list
                        class="bg-darkgrey"
                        dense
                        v-for="(menu2 , idx2) in menuList.filter((e) => e.menu_lvl == 2 && e.upper_menu_sno == menu1.menu_sno)" 
                        :key="`menu2_`+idx2"
                      >
                        <v-list-item 
                          class="pl-3 fontSize-1 text-white-blue-hover pr-0" 
                          @click="allMenuClicked(menu2)"
                        >
                          {{lang == 'en' ? menu2.menu_nm_en : menu2.menu_nm}}
                        </v-list-item>
                        <v-list
                          class="bg-darkgrey "
                          dense
                          v-if="menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == menu2.menu_sno).length > 0"
                        >
                          <v-list-item 
                            dense 
                            class="pl-3 fontSize-0 text-white-blue-hover " 
                            @click="allMenuClicked(menu3)"
                            v-for="(menu3 , idx3) in menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == menu2.menu_sno)" 
                            :key="`menu3_`+idx3"
                          >
                            <div class="slash-title-w mr-3"></div>
                            {{lang == 'en' ? menu3.menu_nm_en : menu3.menu_nm}}
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </v-list>
                  </div>
                  
                </div>
              </v-row>
            </template>
          </v-row>
        </v-container>


        <!-- mobile menu pull down -->
        <v-container class="hidden-md-and-up" >
          <v-row class="mt-0 pt-3 ma-0">
            <template class="">
              <v-row class="ma-0 pa-0 hei-90vh wid-100 scroll-y" row wrap>
                <div class="wid-100">
                  <v-list class="bg-darkgrey white--text">
                    <v-list-group
                      class="pl-3 white--text"
                      v-for="(menu1 , idx1) in menuList.filter((e) => e.menu_lvl == 1)" 
                      :key="`menu1_`+idx1">
                      <template v-slot:activator>
                        <v-list-item-title class="white--text">
                          {{lang == 'en' ? menu1.menu_nm_en : menu1.menu_nm}}
                        </v-list-item-title>
                      </template>

                      <template v-slot:appendIcon>
                        <v-icon slot="appendIcon" color="white"> 
                          mdi-chevron-down
                        </v-icon>
                      </template>

                      <!-- [[[[[[[  level 2  ]]]]]]] -->
                      <!-- <div>
                        <v-list-item
                          v-for="(menu2 , idx2) in menuList.filter((e) => e.menu_lvl == 2 && e.upper_menu_sno == menu1.menu_sno)"
                          :key="`menu2_`+idx2">
                          <v-list-item-content>
                            <v-list-item-title>{{menu2.menu_nm}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </div> -->

                      <!-- [[[[[[[  level 3  ]]]]]]] -->
                      <div v-if="menuList.filter((e) => e.menu_lvl == 2 && e.upper_menu_sno == menu1.menu_sno).length > 0">
                        <v-list-group
                          v-for="(menu2 , idx2) in menuList.filter((e) => e.menu_lvl == 2 && e.upper_menu_sno == menu1.menu_sno)" 
                          :key="`menu2_`+idx2"
                          no-action
                          sub-group
                          prepend-icon=''
                          :append-icon="menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == menu2.menu_sno).length == 0 ? '' : undefined"
                          color="white"
                          class="white--text">

                          <template v-slot:appendIcon>
                            <v-icon slot="appendIcon" color="white" v-show="menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == menu2.menu_sno).length == 0 ? undefined : 'true'">
                              mdi-menu-down
                            </v-icon>
                          </template>

                          <template v-slot:activator>
                            <v-list-item-content class="white--text">
                              <v-list-item-title @click="menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == menu2.menu_sno).length == 0 ? allMenuClicked(menu2) : '' ">
                                {{lang == 'en' ? menu2.menu_nm_en : menu2.menu_nm}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                          

                          <v-list
                            class="bg-darkgrey white--text"
                            dense
                            no-action
                            v-if="menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == menu2.menu_sno).length > 0">
                            <v-list-item
                              v-for="(menu3 , idx3) in menuList.filter((e) => e.menu_lvl == 3 && e.upper_menu_sno == menu2.menu_sno)" 
                              :key="`menu3_`+idx3"
                              class="white--text"
                              style="padding-left:50px;"
                              @click="allMenuClicked(menu3)"
                              link>
                                <div class="slash-title-w mr-3"></div>
                                {{lang == 'en' ? menu3.menu_nm_en : menu3.menu_nm}}
                            </v-list-item>
                          </v-list>

                        </v-list-group>
                      </div>
                      
                    </v-list-group>
                  </v-list>
                </div>
              </v-row>
            </template>
          </v-row>
        </v-container>


      </v-container>
    </template>
    
    <template>
        <v-app-bar height="90" class="elevation-0 z-index hidden-sm-and-down" color="white">
          <v-container class="hei-90p navBtnArea" :style="this.windowWidth > 1319 ? 'width:1200px' : 'width:100%'">
            <v-row class="ma-0 hei-90p pa-0" align="center" v-show="this.windowWidth > 1220">
              <v-col cols="2" class="pa-0">
                <img :src="headerData.top_main_logo_path" class="mHei-70p" @click="mainPageMove" style="cursor: pointer">
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="10" class="hei-90p pa-0">
                <div class="wid-100 d-flex justify-center" >
                  <!-- <v-row align="center" class="justify-center">
                    <v-col cols="1" class="pa-0 justify-center" v-for="(item,idx) in menuList.filter((e) => e.menu_lvl == 1)" :key="`menuLvl1_`+idx">
                      <v-btn text block tile height="100" class="ma-0 pa-0 fontSize-2" @click="gnbMenuShow"><h3>{{item.menu_nm}}</h3></v-btn>
                    </v-col>    
                  </v-row> -->
                  <div v-for="(item,idx) in menuList.filter((e) => e.menu_lvl == 1)" :key="`menuLvl1_`+idx" class="align-center">
                    <v-btn text tile small class="pb-1 align-center hei-90p fontSize-0 wid-110p" @click="gnbMenuShow">
                      <span class="dreamFont4">{{lang == 'en' ? item.menu_nm_en : item.menu_nm}}</span>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>

            <!-- [[[ tablet / mobile ]]] -->
            <v-row class="ma-0 hei-90p pa-0" align="center" v-show="1221 > this.windowWidth">
              <v-col cols="2" class="pa-0">
                <img :src="headerData.top_main_logo_path" class="mHei-70p" @click="mainPageMove" style="cursor: pointer">
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" class="hei-90p pa-0 d-flex justify-end" >
                <v-btn
                  text 
                  tile
                  large
                  height="90"
                  width="90"
                  @click="hMenuShow"
                  :class="this.hmenuShow == true ? 'bg-darkgrey' : ''"
                  class="">
                  <v-icon large class="" v-if="this.hmenuShow==false">mdi-menu</v-icon>
                  <v-icon large class="" v-if="this.hmenuShow==true">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-app-bar>
    </template>

    <!-- [[[[[ mobile mypage menu button ]]]]] -->
    <template>
      <v-container v-if="mobileMyMenuShow"  fluid class="hei-100vh bg-darkgrey z-index-9999 position-absolute hidden-md-and-up">
        <template class="">
          <v-row class="pt-3 pl-3 ma-0 hei-90vh wid-100 scroll-y" row wrap>
            <div class="wid-100">
              <v-list class="bg-darkgrey white--text">
                <v-list-item
                  v-for="(item, idx) in cateList"
                  :key="`cate_`+idx"
                  v-model="item.openYn"
                  @click="clickTitle(item.urlPath)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <h3 class="white--text">{{item.title}}</h3>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-row>
        </template>
      </v-container>
    </template>

    <!-- [[[[[ mobile language button ]]]]] -->
    <template>
      <v-container v-if="mobileLangMenuShow"  fluid class="hei-100vh bg-darkgrey z-index-9999 position-absolute hidden-md-and-up">
        <template class="">
          <v-row class="pt-3 pl-3 ma-0 hei-90vh wid-100 scroll-y" row wrap>
            <div class="wid-100">
              <v-list class="bg-darkgrey white--text">
                <v-list-item
                  @click="clickLangMenu('ko')">
                  <v-list-item-content>
                    <v-list-item-title>
                      <h3 class="white--text">{{ lang == 'en' ? '한글' : 'KOREAN' }}</h3>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="clickLangMenu('en')">
                  <v-list-item-content>
                    <v-list-item-title>
                      <h3 class="white--text">{{ lang == 'en' ? '영어' : 'ENGLISH' }}</h3>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-row>
        </template>
      </v-container>
    </template>

    <template v-if="gnbShow" >
      <v-container fluid class=" z-index-999 position-absolute bg-alpha hei-100" >
        <v-container class="wid-100">
          <v-row class="ma-0">
            <template>
              <v-row class="ma-0 pa-0 wid-100 scroll-y scroll-x" row wrap v-click-outside="gnbMenuClickOutside">
                <v-col cols="12" class="d-flex pa-0 ma-0">
                  <div class="wid-100 d-flex justify-center">
                    <div class="wid-12 bg-lightgrey border-l-grey menuArea" v-for="(item,idx) in menuList.filter((e) => e.menu_lvl == 1)" :key="`menuLvl1_sub_`+idx">
                      <v-list
                        class="wid-12 pt-4 pb-4 pr-0 pl-0 bg-alpha-0">
                        <v-list-item-title class="pb-2 pt-2 d-flex justify-center align-center pr-0"><h2> {{lang == 'en' ? item.menu_nm_en : item.menu_nm}} </h2></v-list-item-title>
                        <v-list-item
                          v-for="(item2,idx2) in menuList.filter((f) => f.menu_lvl == 2 && f.upper_menu_sno == item.menu_sno)"
                          :key="`menuLvl2_sub_`+idx2"
                          class="text-blue-hover pr-0"
                          style="min-height:35px"
                          link
                          @click="subMenuClicked(item2)">
                          <div class="slash-title-b mr-3 hei-10p"></div>
                          {{lang == 'en' ? item2.menu_nm_en : item2.menu_nm}}
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-row>
        </v-container>
      </v-container>
    </template>
    
    <template>
      <v-dialog v-model="joinDialLog" max-width="500px" max-height="300px" >
        <v-card align-center tile>
          <div class="pa-4">
            <v-form ref="form" lazy-validation>
              <v-row align="center">
                <v-col cols="11" class="">
                  <h2>
                    개인 / 단체
                  </h2>
                </v-col>
                <v-col cols="1" class="d-flex justify-end">
                  <div>
                    <v-btn
                      x-small
                      icon
                      @click="joinDialLog = false">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 border-b-darkgrey-2px"></v-col>
                <v-col cols="12" class="mt-5 mb-5 d-flex justify-center">
                    <v-radio-group
                      v-model="joinType"
                      row
                      hide-details
                      dense
                      class="ma-0 pa-0"
                    >
                      <v-radio
                        label="개인"
                        value="indv"
                      ></v-radio>
                      <v-radio
                        label="단체"
                        value="grp"
                      ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <div class="wid-100">
            <v-btn
              tile
              class="wid-100 white--text ml-3 f-r bg-indigo hei-50p fontSize-1"
              @click="joinTypeClicked"
            >설정
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      
    </template>
    </v-container>
</template>

<script>
export default {
  data() {
    return{
      windowWidth: window.innerWidth,
      mobileMyMenuShow: false,
      mobileLangMenuShow: false,
      hmenuShow: false,
      gnbShow:false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      drawer: null,
      point_color_1: '#4392ff',
      point_color_4: '#2a325f',
      font_color_3: '',
      font_color_6: '',
      menuList: [],
      headerData: {},
      appBarYn: false,
      joinDialLog: false,
      joinType: '',
      apubFamilySiteMap: {},
      soctyYn: false,
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      lang: this.$session.get("lang") ? this.$session.get("lang") : '',
      en_page_use_yn: false,
      login_use_yn: false,
      cateList: [
        {title: '프로필', openYn: false, urlPath: '/mypage/profile' , catePath: '/mypage/profile' , odr: 1} ,
        {title: '결제', openYn: false , urlPath: '/mypage/payList' , catePath: '/mypage/payList', odr: 2} ,
        {title: '청구서/납부확인서/증명서', openYn: false , urlPath: '/mypage/certificate'  , catePath: '/mypage/certificate', odr: 3} ,
      ],
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
    //영어 전환 여부
    this.getSiteOptn();
    //메뉴 리스트
    this.selectMenuList();
    //헤더 이미지 정보
    this.getHeaderImg();
    //포인트 컬러
    this.getPoinColor();
    //apub패밀리 사이트
    this.getApubFamilySite();
    //마이페이지 메뉴 사용여부
    this.getMypageInfo();
  },
  methods:{
    hMenuShow(){
      // console.log("a값"+this.hmenuShow);
      this.hmenuShow = !this.hmenuShow;
    },
    gnbMenuShow(){
      // console.log("a값"+this.gnbShow);
      this.gnbShow = !this.gnbShow;
    },
    mobMyMenuShow(){
      // console.log("a값"+this.hmenuShow);
      this.mobileMyMenuShow = !this.mobileMyMenuShow;
    },
    mobLangMenuShow(){
      // console.log("a값"+this.hmenuShow);
      this.mobileLangMenuShow = !this.mobileLangMenuShow;
    },
    clickTitle(url) {
      this.$router.push(url);
      this.mobileMyMenuShow = !this.mobileMyMenuShow;
    },
    hMenuClickOutside(){
      //전체 메뉴 외부 클릭시 메뉴 닫힘
      this.hmenuShow = false;
    }, 
    gnbMenuClickOutside(){
      //드롭다운 메뉴 외부 클릭시 메뉴 닫힘
      this.gnbShow = false;
    },
    loginBtnClicked() {
      this.$router.push('/login');
    },
    logoutBtnClicked() {
      if(confirm('로그아웃 하시겠습니까?')) {
        this.$session.set('mberSno','');
        this.$session.set('tempPwdYn','N');
        this.$session.set('nonMberYn','');
        if(this.$route.path == '/') {
          this.$router.go(0);
        } else {
          this.$router.push('/');
        }
        
      }
    },
    allMenuClicked(item){

      const len = this.menuList.filter((e) => e.upper_menu_sno == item.menu_sno).length;
      if(len > 0) {
        const url = this.menuList.filter((e) => e.upper_menu_sno == item.menu_sno && e.disp_odr == 1)[0].url_path;
        this.hmenuShow = false;
        if(this.$route.fullPath != url) {
          if(item.page_type == 'url_link') {
            window.open(url);
          } else {
            this.$router.push(url);
          }

        }
        
      } else {
        this.hmenuShow = false;
        if(this.$route.fullPath != item.url_path) {
          if(item.page_type == 'url_link') {
            window.open(item.url_path);
          } else {
            this.$router.push(item.url_path);
          }
        }
      }
      
    },
    selectMenuList() {

      const param = new FormData();
      param.append('lang' , this.lang);

      this.$http.post("/admin/getMenuList", param).then((res) => {    
      
        this.menuList = res.data.menuList;
        this.menuList = this.menuList.filter((e) => e.disp_yn == 'Y');
        this.menuList.forEach((e) => {

          if(e.page_type == 'static') {
            e.url_path = '/static'+e.url_path
          } else if(e.page_type == 'bbs_notc') {
            e.url_path = '/board/notice'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_glly') {
            e.url_path = '/board/gallery'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_free') {
            e.url_path = '/board/free'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_faq') {
            e.url_path = '/board/faq'+e.url_path+'/list';
          } else if(e.page_type == 'bbs_qna') {
            e.url_path = '/board/qna'+e.url_path+'/list';
          } else if(e.page_type == 'past_exctv' || e.page_type == 'curr_exctv') {
            e.url_path = '/executive'+e.url_path;
          } else if(e.page_type == 'spnsr') {
            e.url_path = '/sponsor'+e.url_path
          } else if(e.page_type == 'socty_hist') {
            e.url_path = '/history'+e.url_path
          } else if(e.page_type == 'scdul') {
            e.url_path = '/schedule'+e.url_path
          } else if(e.page_type == 'cntt_us') {
            e.url_path = '/contactus'+e.url_path
          } else if(e.page_type == 'pay') {
            e.url_path = '/pay'+e.url_path+'/list'
          } else if(e.page_type == 'abstr_reg') {
            e.url_path = '/abstrReg'+e.url_path+'/list'
          } else if(e.page_type == 'pre_reg') {
            e.url_path = '/preReg'+e.url_path+'/list'
          } else if(e.page_type == 'confr_outl') {
            e.url_path = '/confrOutl'+e.url_path;
          } else if(e.page_type == 'confr_scdul') {
            e.url_path = '/confrScdul'+e.url_path;
          } else if(e.page_type == 'event_tour') {
            e.url_path = '/confrEventTour'+e.url_path;
          } else if(e.page_type == 'trfc_accom_guid') {
            e.url_path = '/confrTrfcAccom'+e.url_path;
          } else if(e.page_type == 'ad_disp_guid') {
            e.url_path = '/confrAdDisp'+e.url_path;
          } else if(e.page_type == 'url_link') {
            e.url_path = e.url_link;
          } else if(e.page_type == 'mber_srch') {
            e.url_path = '/memberSearch'+e.url_path;
          } else if(e.page_type == 'doc_srch') {
            e.url_path = '/docSearch'+e.url_path;
          } else if(e.page_type == 'publc') {
            e.url_path = '/public'+e.url_path;
          }
          
        });

        
      }).catch((error) => {
          console.log(error);
        }
      )
    },//selectMenuList end
    getHeaderImg() {
      this.$http.get("/admin/getSoctyImg").then((res) => {
        if(res.data.result[0]) {
          this.headerData = res.data.result[0];
        }
        console.log(res.data.result);
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getHeaderImg end
    getPoinColor() {
      this.$http.get("/common/getMainPointColor").then((res) => {
        const mainPointColor = res.data.mainPointColor;
        if(mainPointColor) {
          this.point_color_1 = mainPointColor.point_color_1;
          this.point_color_4 = mainPointColor.point_color_4;
          this.font_color_3 = mainPointColor.font_color_3;
          this.font_color_6 = mainPointColor.font_color_6;
        }
        this.appBarYn = true;
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getPoinColor end
    joinTypeClicked() {
      if(!this.joinType) {
        alert('가입 타입 선택해주세요.');
        return;
      }
      this.$router.push({path:'/join',query:{joinType: this.joinType}})
    },//joinTypeClicked end
    joinDialLogClicked() {
      this.joinType = '';
      this.joinDialLog = true;
    },//joinDialLogClicked end
    getApubFamilySite() {
      this.$http.get("/home/getApubFamilySite").then((res) => {
        this.apubFamilySiteMap = res.data.apubFamilySiteMap;

        if(!this.apubFamilySiteMap || (!this.apubFamilySiteMap.jrnl_site_url && !this.apubFamilySiteMap.sbmt_sys_url && !this.apubFamilySiteMap.confr_site_url)) {
          this.soctyYn = false;
        } else {
          this.soctyYn = true;
        }
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getFamilySite end
    subMenuClicked(item) {
      // console.log("sss :: ",item);
      if(item.page_type == 'url_link') {
        this.gnbShow = false;
        window.open(item.url_path);
      } else {
        const len = this.menuList.filter((e) => e.upper_menu_sno == item.menu_sno).length;
        if(len > 0) {
          const url = this.menuList.filter((e) => e.upper_menu_sno == item.menu_sno)[0].url_path;
          // const url = this.menuList.filter((e) => e.upper_menu_sno == item.menu_sno && e.disp_odr == 1)[0].url_path;
          this.gnbShow = false;
          if(this.$route.fullPath != url) {
            this.$router.push(url);
          }
          
        } else {
          this.gnbShow = false;
          if(this.$route.fullPath != item.url_path) {
            this.$router.push(item.url_path);
          }
          
        }
      }
      
    },//subMenuClicked end
    mainPageMove() {
      this.$router.push("/");
    },//mainPageMove end
    langBtnClicked() {
      // alert(this.lang);
      if(this.lang == 'ko' || !this.lang) {
        this.$session.set('lang', 'en');
      } else if(this.lang == 'en') {
        this.$session.set('lang', 'ko');
      }

      this.$router.go(0);
    },//langBtnClicked end
    clickLangMenu(selLang){
      if(selLang=='ko'){
        this.$session.set('lang', 'ko');
      }else if(selLang=='en'){
        this.$session.set('lang', 'en');
      }
      this.$router.go(0);
    },
    getSiteOptn() {
      this.$http.get("/home/getSiteOptn").then((res) => { 
        console.log(res.data.siteOptnMap);
        if(res.data.siteOptnMap) {
          const siteOptnMap = res.data.siteOptnMap;
          this.en_page_use_yn = siteOptnMap.en_page_use_yn == 'Y' ? true : false;
          this.login_use_yn = siteOptnMap.login_use_yn == 'Y' ? true : false;
        }

      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },//getSiteOptn end
    getMypageInfo() {
      this.$http.get("/mber/getMypageInfo").then((res) => { 
          // alert(JSON.stringify(res.data.myPageMap));
          const myPageMap = res.data.myPageMap;
          if(!myPageMap) {
            this.cateList = this.cateList.filter((e) => e.odr == 1);
          } else {
            if(myPageMap.pay_menu_use_yn == 'N') {
              this.cateList = this.cateList = this.cateList.filter((e) => e.odr != 2);
            }
            if(myPageMap.crtf_menu_use_yn == 'N') {
              this.cateList = this.cateList = this.cateList.filter((e) => e.odr != 3);
            }
          }
        }).catch((error) => {
            console.log(error);
          }
        )
    },//getMypageInfo
  }
}
</script>

<style scoped>
::v-deep .v-toolbar__content{
  padding:0px;
}
.v-list /deep/.v-list-item--active .v-icon{
  color:white !important;
}
/* /deep/ .theme--light .v-icon::before{
  color:white !important;
} */
.v-list__group__header__prepend-icon .v-icon /deep/{
  color: white !important;
}

.notVisible {
  visibility: hidden;
}

.dreamFont3{
  font-family: dreamFont3 !important;
}

.dreamFont4{
  font-family: dreamFont4 !important;
  font-size:18px !important;
}

.v-list-item__title > h2 {
  font-family: dreamFont4 !important;
}

.text-blue-hover {
  font-family: dreamFont4 !important;
}

.v-list > .v-list-item__title {
  font-family: dreamFont4 !important;
}

::v-deep .v-list > .v-list--dense > .v-list-item--link {
  font-family: dreamFont4 !important;
}
</style>