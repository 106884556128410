<template>
  <v-container fluid>
    <div v-if="confrYn == 'Y'">
      <v-row class="ma-0 pa-0 mb-5">
        <v-col cols="12">
          <!-- <div class="tborder wid-100 hei-100p" style="background-image:url('https://img.freepik.com/free-vector/unfocussed-winter-background-with-snowflakes_1393-271.jpg');"></div> -->
          <div class="wid-100">
            <img :src="ongoingConfrInfo.url_path" class="imgDf">
          </div>
        </v-col>
      </v-row>

      <v-row class="ma-0 pa-0">
        <v-col cols="12">
          <v-card outlined>
            <v-row class="">
              <v-col cols="12">
                <div class="tiny" v-html="pre_reg_dtl"></div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- 메시지 -->
      <v-row dense class="ma-0">
        <v-col cols="12" class="pt-5 pb-5 ma-0">
          <div class="bg-lightblue pa-4" v-if="!mberSno && ongoingConfrInfo.pre_reg_psbl_yn == 'Y'">
            <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
            <span class="fontSize-2 pl-3">회원 로그인 후 사전 등록하시거나, 회원이 아니시면 비회원 등록 후 사전 등록하실 수 있습니다.</span>
          </div>
          <div class="bg-lightblue pa-4" v-if="!mberSno && ongoingConfrInfo.pre_reg_psbl_yn == 'N'">
            <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
            <span class="fontSize-2 pl-3">사전 등록이 마감되었으니 현장등록하시기 바랍니다.</span>
          </div>

          <div class="bg-lightblue pa-4" v-if="mberSno && ongoingConfrInfo.pre_reg_psbl_yn == 'N' && preRegList.length == 0">
            <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
            <span class="fontSize-2 pl-3">사전 등록이 마감되었으니 현장등록하시기 바랍니다.</span>
          </div>
          <div class="bg-lightblue pa-4" v-if="mberSno && ongoingConfrInfo.pre_reg_psbl_yn == 'N' && preRegList.length > 0">
            <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
            <span class="fontSize-2 pl-3">사전 등록이 마감되었습니다.</span>
          </div>

          <div class="bg-lightblue pa-4" v-if="ongoingConfrInfo.pre_reg_psbl_yn == 'Y' && ongoingConfrInfo.reg_ptcpr_cnt >= ongoingConfrInfo.max_mber_cnt">
            <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
            <span class="fontSize-2 pl-3">최대 참가 인원수({{ ongoingConfrInfo.max_mber_cnt }})를 초과하여 등록할 수 없습니다.</span>
          </div>
        </v-col>
      </v-row>

      <!-- 사전 등록 목록 -->
      <v-row class="ma-0" v-if="mberSno && preRegList.length > 0">
        <v-col cols="12" class="">
          <v-data-table
            :headers="gridHeader"
            :items="preRegList"
            class="elevation-0"
            disable-sort
            hide-default-footer
            style="cursor: pointer"
            @click:row="gridRowClicked($event)"
          >
          <template v-slot:[`item.rqstr_nm`]="{ item }">
            <div >{{ item.rqstr_nm }}</div>
          </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- 버튼 -->
      <v-row v-show="preRegList.length == 0 && ongoingConfrInfo.pre_reg_psbl_yn == 'Y'">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn large class="elevation-0 fontSize-2 wid-200p hei-60p mr-3" outlined v-if="!mberSno" @click="nonMberLoginClicked">
            비회원 신청
          </v-btn>
          <v-btn large class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" outlined v-if="!mberSno" @click="mberApplyClicked">
            회원 신청
          </v-btn>
          <v-btn large class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" outlined @click="regBtnClicked" v-if="mberSno && ongoingConfrInfo.reg_ptcpr_cnt < ongoingConfrInfo.max_mber_cnt">
            사전등록
          </v-btn>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="confrYn == 'N'">
      <v-row>
        <v-col cols="12">
          진행중인 학술 대회가 없습니다.
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      gridHeader: [
        { text: '번호', value: 'pre_reg_sno', width: "10%", align: 'center', },
        { text: '신청자', value: 'rqstr_nm', width: "12%", align: 'center',},
        { text: '소속', value: 'rqstr_blng', width: "20%", align: 'center',},
        { text: '부서/학과', value: 'rqstr_dept', width: "20%", align: 'center',},
        { text: '결제 여부', value: 'pay_yn', width: "10%", align: 'center',},
        { text: '등록일시', value: 'reg_dtm', width: "20%", align: 'center', }
      ],
      regList: [],
      page: 1,
      itemsPerPage: 5,
      // abstrList: [],
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      basicUri: '',
      ongoingConfrInfo: {},
      emitData: {},
      chg_perm_yn: 'N',
      confr_sno: '',
      pre_reg_dtl: '',
      preRegList: [],
      confrYn: 'N',
      // 체크 옵션 처리
      regOptn: {},
      confrRegPsblYnByAnulFee: {},
    }
  },
  created() {
    let path = this.$route.fullPath;
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    this.getPreAbstrListPage();
  },
  methods: {
    regBtnClicked() { //사전등록 클릭시 
      // 연회비 미납 체크 옵션에 따른 사전등록 제한
      if(this.regOptn.anul_mfee_chk_yn == 'Y' && this.confrRegPsblYnByAnulFee.confr_reg_psbl_yn == 'N') {
        alert("연회비가 미납인 경우 등록할 수 없습니다. 연회비 결제 후 등록해주세요.");
        return;
      }

      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("confr_sno", this.ongoingConfrInfo.confr_sno);
      params.append("non_mber_yn", this.nonMberYn);

      this.$http.post('/sub/getPrgsConfPreRegYn', params).then((res) => {

        if(this.chg_perm_yn != 'Y') {
          alert('권한이 없습니다.');
        } else {
          if(res.data.code == 0) {
            // this.$router.push(this.basicUri+'/write');
            this.$router.push({path: this.basicUri+'/write' , query: {confr_sno: this.confr_sno}});
          } else if(res.data.code == -9006) {
            alert('다른 학술대회 로그인이 필요합니다. 비회원 로그인 후 등록해주세요');
            this.$session.set('mberSno','');
            this.$session.set('tempPwdYn','N');
            this.$session.set('nonMberYn','');
            this.$router.push(this.basicUri+'/nonMberLogin?confr_sno='+this.confr_sno+'&menu_sno='+this.emitData.menu_sno)

          } else {
            alert('이미 사전등록되어있습니다.');
          }
        }

      })
      .catch(function (error) {
          console.log(error);
        }
      );
      
    },//regBtnClicked end
    getPreAbstrListPage() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("non_mber_yn", this.nonMberYn);

      this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {
        console.log("getPreAbstrListPage res.data : ", res.data);
      
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.$router.push('/');
        }

        this.confrYn = res.data.result.confrYn;
        if(this.confrYn == 'Y') {
          if(res.data.result.resultMap) {
            this.chg_perm_yn = res.data.result.resultMap.chg_perm_yn;
          }
          this.ongoingConfrInfo = res.data.result.ongoingConfrInfo;
          console.log("this.ongoingConfrInfo",this.ongoingConfrInfo);
          this.confr_sno = this.ongoingConfrInfo.confr_sno;
          if(this.mberSno) {
            this.preRegList = res.data.result.preRegList;
            
          }
          this.getConfrDtl();
        }

        // 사전등록 옵션
        this.regOptn = res.data.result.regOptn;

        // 연회비 납부 체크 옵션이 있는 경우 사전등록 가능 여부 조회
        if(this.regOptn.anul_mfee_chk_yn == 'Y') {
          this.confrRegPsblYnByAnulFee = res.data.result.confrRegPsblYnByAnulFee;
        }

        this.emitData.menu_sno = res.data.menu_sno;
        this.$emit('setData', this.emitData);
        
      })
      .catch(function (error) {
          console.log(error);
        }
      );
      
      
    },//getAbstrRegPage end
    getConfrDtl() {
      const params = new FormData();
      params.append("confr_sno", this.confr_sno);
			this.$http.post("/admin/getConfrDtl", params).then((res) => {
        console.log("res.data.sult: ", res.data);
        
        if(res.data.result.confrDtl.pre_reg_dtl) {
          this.pre_reg_dtl = res.data.result.confrDtl.pre_reg_dtl;
        }

			}).catch(function (error) {
					console.log(error);
				}
			);
    },//getConfrDtl end
    gridRowClicked(item) {
      console.log(item.pre_reg_sno);
      const pre_reg_sno = item.pre_reg_sno;
      this.$router.push(this.basicUri+'/write?confr_sno='+this.confr_sno+'&pre_reg_sno='+pre_reg_sno);
    },
    mberApplyClicked() {
      this.$router.push('/login');      
    },
    nonMberLoginClicked() {
      this.$router.push(this.basicUri+'/nonMberLogin?confr_sno='+this.confr_sno+'&menu_sno='+this.emitData.menu_sno);
    },//nonMberLoginClicked end
  }
}
</script>
<style scoped>
 .hoverCheck:hover {
  cursor: pointer !important;
 }
</style>