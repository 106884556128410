
<template>
    <v-app>
        <template >
            <div style="margin-top:8%;margin-bottom:1%;">
                <v-container >
                    <v-row justify="center" align="center">
                        <v-col cols="auto">
                            <h1>비밀번호 재설정</h1>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <!-- email (홍길동)님의 본인확인이 완료되었습니다.\n사용하실 비밀번호 입력해주세요. -->
            <div style="border: 1px solid black; height:30%; margin: 0 auto; width:30%;" class="pt-10">
                <v-container>
                    <v-row justify="center" >
                        <v-col cols="7" style="color:red;">
                          {{email}} ({{nm}})님의 본인확인이 완료되었습니다.<br/>사용하실 비밀번호 입력해주세요.
                        </v-col>
                        <v-col cols="7" >
                            <v-text-field
                            class="wid-100"
                            hide-details
                            dense 
                            outlined
                            label="현재 비밀번호"
                            placeholder="현재 비밀번호"
                            v-model="currentPwd"
                            type="password"
                            ></v-text-field> 
                        </v-col>
                    </v-row>
                    <v-row justify="center" >
                        <v-col cols="7" >
                            <v-text-field
                            class="wid-100"
                            hide-details
                            dense 
                            outlined
                            label="비밀번호"
                            placeholder="비밀번호"
                            v-model="chgPwd"
                            type="password"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row justify="center" >
                        <v-col cols="7" >
                            <v-text-field
                            class="wid-100"
                            hide-details
                            dense 
                            outlined
                            label="비밀번호 확인"
                            placeholder="비밀번호 확인"
                            v-model="chgPwdChk"
                            type="password"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"><v-divider></v-divider></v-col>
                        <v-col cols="3" >
                            <v-btn
                                color="white"
                                class="pa-0 ma-0 black--text f-r wid-100"
                                hide-details
                                @click="pwdChgClicked"
                            >
                                비밀번호 재설정
                            </v-btn>
                        </v-col>
                        <v-col cols="3" >
                            <v-btn
                                color="blue lighten-2"
                                class="pa-0 ma-0 white--text f-r wid-100"
                                hide-details
                                to="/"
                            >
                            취소
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </template>
        <div v-if="isLoading" class="loading-container">
            <div class="spinner-loading">
                <Fade-loader />
            </div>
        </div>
    </v-app>
</template>

<script>
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'
export default {
    components: { FadeLoader },
    data() {
        return {
            currentPwd: '',
            chgPwd: '',
            chgPwdChk: '',
            isValid: true,
            mberSno: '',
            isLoading: false,
            nm: '',
            email: '',
        }
    },
    created() {
        this.mberSno = this.$session.get("mberSno"); // 로그인 멤버
        
        const formData = new FormData();
        formData.append("mber_sno",this.mberSno);

        this.$http.post("/mber/getPwdResetInfo", formData).then((res) => {
            console.log(res.data);
            this.email = res.data.mberInfo.email;
            this.nm = res.data.mberInfo.nm;
        }).catch(
            function (error) {
            console.log(error);
            
            }
        );

    }, 
    methods: {
        pwdChgClicked() {
            this.isValid = true;
            this.validationCheck();
            if(this.isValid) {
                this.pwdChgBtnClicked();
            }
            
        },
        validationCheck() {
            if(!this.currentPwd) {
                alert('현재 비밀번호 입력해주세요.');
                this.isValid = false;
            } else if(!this.chgPwd) {
                alert('비밀번호 입력해주세요.');
                this.isValid = false;
            } else if(!this.chgPwdChk) {
                alert('비밀번호 확인 입력해주세요.');
                this.isValid = false;
            } else if(this.chgPwd != this.chgPwdChk) {
                alert('새로운 비밀번호가 일치하지 않습니다.');
                this.isValid = false;
            }
        },
        pwdChgBtnClicked() {

            const formData = new FormData();

            formData.append("pwd",this.currentPwd);
            formData.append("chg_pwd",this.chgPwd);
            formData.append("mber_sno",this.mberSno);
            formData.append("login_mber_sno", this.mberSno);

            this.isLoading = true;
            this.$http.post("/admin/updateMberPwd", formData).then((res) => {
                if(res.data.code < 0)
                {
                    this.isLoading = false;
                    alert(this.$erroCdMsg(res.data.code));
                    return;
                }else
                {
                    this.isLoading = false;
                    alert('비밀번호 변경되었습니다.\n변경된 비밀번호로 재로그인 해주세요.');
                    this.$session.set('mberSno','');
                    this.$session.set('tempPwdYn','N');
                    this.$session.set('nonMberYn','');
                    this.$router.push("/");
                }
            }).catch(
                function (error) {
                console.log(error);
                this.isLoading = false;
                
                }
            );

        },//pwdChgBtnClicked end
    }
}
</script>

<style scoped>
.spinner-loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
  
}
</style>