<template >
    <v-container class="pa-3" v-if="mberSno != ''" :key="redrawCount">
      <v-row class="pa-0 mb-10" >
        <v-col cols="11" class="pa-0 d-flex align-center">
          <h1 class="mr-3">결제 정보</h1>
        </v-col>
        <v-col cols="1" class="pa-0 d-flex align-center">
          <v-btn @click="toListClicked">목록</v-btn>
        </v-col>
        <v-col cols="12" class="border-b-darkgrey-2px"></v-col>
      </v-row>
      <!-- 주문번호 -->
      <v-row class="pa-0 mb-10">
        <v-col cols="1">
          <h4>주문번호</h4>
        </v-col>
        <v-col cols="3">
          {{detailMap.pay_no}}
        </v-col>
        <v-col cols="12" style="border-bottom: 1px solid black;"></v-col>
      </v-row>
      <!-- 상풍명 -->
      <v-row class="pa-0 mb-10" >
        <v-col cols="1">
          <h4>상품명</h4>
        </v-col>
        <v-col cols="3">
          {{detailMap.pay_prod_nm}}
        </v-col>
        <v-col cols="12" style="border-bottom: 1px solid black;"></v-col>
      </v-row>
      <!-- 금액 -->
      <v-row class="pa-0 mb-10" >
        <v-col cols="1">
          <h4>금액</h4>
        </v-col>
        <v-col cols="3">
          {{detailMap.pay_amt}}
        </v-col>
        <v-col cols="12" style="border-bottom: 1px solid black;"></v-col>
      </v-row>
      <!-- 주문자 -->
      <v-row class="pa-0 mb-10" >
        <v-col cols="1">
          <h4>주문자</h4>
        </v-col>
        <v-col cols="3">
          {{detailMap.payr_nm}}
        </v-col>
        <v-col cols="12" style="border-bottom: 1px solid black;"></v-col>
      </v-row>
      <!-- 이메일 -->
      <v-row class="pa-0 mb-10" >
        <v-col cols="1">
          <h4>이메일</h4>
        </v-col>
        <v-col cols="3">
          {{detailMap.payr_email}}
        </v-col>
        <v-col cols="12" style="border-bottom: 1px solid black;"></v-col>
      </v-row>
      <!-- 전화번호 -->
      <v-row class="pa-0 mb-10" >
        <v-col cols="1">
          <h4>전화번호</h4>
        </v-col>
        <v-col cols="3">
          {{detailMap.payr_tel_no}}
        </v-col>
        <v-col cols="12" style="border-bottom: 1px solid black;"></v-col>
      </v-row>
      <!-- 휴대폰번호 -->
      <v-row class="pa-0 mb-10" >
        <v-col cols="1">
          <h4>휴대폰번호</h4>
        </v-col>
        <v-col cols="3">
          {{detailMap.payr_cel_no}}
        </v-col>
        <v-col cols="12" style="border-bottom: 1px solid black;"></v-col>
      </v-row>
      <!-- 결제방법 -->
      <v-row class="pa-0 mb-10">
        <v-col cols="1">
          <h4>결제 방법</h4>
        </v-col>
        <v-col cols="3">
          {{detailMap.pay_mthd_nm}}
        </v-col>
        <v-col cols="12" style="border-bottom: 1px solid black;"></v-col>
      </v-row>
    </v-container>
  </template>
          
  
  <script>
  
  export default {
    name: 'paymentPageDetail',
    data () {
        return {
          mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
          detailMap: {},
          redrawCount: 0,
          basicUri: '',
        }
      },
      created() {
        
        if(!this.mberSno) {
          // alert('비회원 사용불가능한 화면입니다.');
          if(confirm('회원 전용입니다. 로그인하시겠습니까?')) {
            this.$router.push('/login');
          }
        }
  
        let path = this.$route.path;
        this.basicUri = path.substring(path.lastIndexOf('/'), -1);
  
        const formData = new FormData();
        formData.append("mber_sno", this.mberSno);
        formData.append("payNo", this.$route.query.payNo);
  
        this.$http.post('/sub/myPage/payDetail', formData).then((res) => {
          console.log("detail :: ",res.data);
          // const detail = res.data.detailMap;
          this.detailMap = res.data.detailMap;
          if(this.detailMap.pg_id == 'inicis') {
            this.cmnCdList(); 
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      },
      methods: {
        async cmnCdList() {
          const cdList = await this.$getCmnCdList('pay_mthd');
          this.detailMap.pay_mthd_nm = cdList.filter((e) => e.cmn_cd == this.detailMap.pay_mthd)[0].cmn_cd_nm;
          this.redrawCount++;
        },//cmnCdList end
        toListClicked() {
          this.$router.push('/mypage/payList');
        },//toListClicked end
      }
    }
  
  </script>
  
  <style>
  /deep/.col{
    padding: 0;
  }
  </style>