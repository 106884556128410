<template>
  <v-container fluid>
    <v-row class="ma-0 pa-0 mb-5">
      <v-col cols="12">
        <div class="wid-100">
          <img :src="bnr_img_path" class="imgDf" v-if="bnr_img_path">
        </div>
      </v-col>
    </v-row>

    <v-row class="ma-0 mb-10">
      <!-- html -->
      <v-col cols="12" class="pa-0 wid-100">
        <div class="tiny" v-html="confrTrfcMap.body_cn"></div>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <!-- map -->
      <v-col cols="12" class="pa-0">
        <naver-maps class="wid-100 hei-500p" :mapOptions="mapOptions" v-if="mapYn">
          <naver-marker :lat="mapOptions.lat" :lng="mapOptions.lng" v-if="markerYn"></naver-marker>
        </naver-maps>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import naver from 'vue-naver-maps';
export default {
  data() {
    return {
      confrDtl: '',
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      permMap: {},
      emitData: {},
      confrYn: '',
      confrTrfcMap: {},
      bnr_img_path: '',
      mapOptions: {
        zoom: 18,
        lat: 37.5666612,
        lng: 126.9783785,
        zoomControl: false,
      },
      viewYn: false,
      markerYn: false,
      mapYn: false,
      apiCdMap: {},
    }
  },
  created() {
    const formData = new FormData();
    formData.append('mber_sno', this.mberSno);
    formData.append("non_mber_yn", this.nonMberYn);

    this.$http.post('/sub'+this.$route.fullPath,formData).then((res) => {
      console.log(res.data);
      if(res.data.code < 0) {
        alert(this.$erroCdMsg(res.data.code));
        this.$router.push("/");
        return;
      } else {
        console.log(res.data);

        this.confrYn = res.data.confrYn;
        if(this.confrYn == 'Y') {

          this.apiCdMap = res.data.apiCdMap;

          if(this.apiCdMap) {
            Vue.use(naver, {
              clientID: this.apiCdMap.naver_clnt_id,
              useGovAPI: false, //공공 클라우드 API 사용 (선택)
              subModules:'' // 서브모듈 (선택)
            });
            this.mapYn = true;
          }


          this.confrDtl = res.data.confrDtl;
          this.confrTrfcMap = res.data.confrTrfcMap;
          if(this.confrTrfcMap && this.confrTrfcMap.bnr_img_path) {
            this.bnr_img_path = this.confrTrfcMap.bnr_img_path;
          }

          if(this.confrTrfcMap) {
            const lati = this.confrTrfcMap.lati;
            // alert(lati);
            const longi = this.confrTrfcMap.longi;
            // alert(this.mapOptions.lat);
            if(lati && longi) {
              this.mapOptions.lat = Number(lati);
              this.mapOptions.lng = Number(longi);
              this.markerYn = true;
            }
          }
        }
        
        this.emitData.menu_sno = res.data.menu_sno;
        this.$emit('setData', this.emitData);
      }
      
    }).catch(
      function (error) {
      console.log(error);
      }
    )
  },
  methods: {

  }
}
</script>

<style>

</style>