<template>
  <v-container fluid>
    <div v-if="confrYn == 'Y'">
      <v-row class="ma-0 pa-0 mb-5">
        <v-col cols="12">
          <div class="wid-100">
            <img :src="ongoingConfrInfo.url_path" class="imgDf">
          </div>
        </v-col>
      </v-row>

      <v-row class="ma-0 pa-0">
        <v-col cols="12">
          <v-card outlined>
            <v-row class="ma-0" align="center">
              <div class="tiny" v-html="abstr_reg_dtl"></div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <template>
        <v-row dense class="ma-0">
          <v-col cols="12" class="pt-5 pb-5 ma-0">
            <!-- 로그인하지 않음 && 초록등록 기간 && 비회원 등록 가능 -->
            <div class="bg-lightblue pa-4" v-if="!mberSno && ongoingConfrInfo.abstr_reg_psbl_yn == 'Y' && regOptn.mber_only_yn != 'Y'">
              <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
              <span class="fontSize-2 pl-3">회원 로그인 후 초록 등록하시거나, 회원이 아니시면 비회원 등록 후 초록 등록하실 수 있습니다.</span>
            </div>
            <!-- 로그인하지 않음 && 초록등록 기간 && 비회원 등록 불가 -->
            <div class="bg-lightblue pa-4" v-if="!mberSno && ongoingConfrInfo.abstr_reg_psbl_yn == 'Y' && regOptn.mber_only_yn == 'Y'">
              <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
              <span class="fontSize-2 pl-3">회원 로그인 후 초록 등록하실 수 있습니다.</span>
            </div>
            <!-- 로그인하지 않음 && 초록등록 기간 아님 -->
            <div class="bg-lightblue pa-4" v-if="!mberSno && ongoingConfrInfo.abstr_reg_psbl_yn == 'N'">
              <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
              <span class="fontSize-2 pl-3">초록 등록이 마감되었습니다.</span>
            </div>
            <!-- 로그인 && 초록등록 기간 아님 -->
            <div class="bg-lightblue pa-4" v-if="mberSno && ongoingConfrInfo.abstr_reg_psbl_yn == 'N'">
              <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
              <span class="fontSize-2 pl-3">초록 등록이 마감되었습니다.</span>
            </div>
            <!-- 초록 등록 가능 건수 초과 -->
            <div class="bg-lightblue pa-4" v-if="isExceedRegCnt">
              <v-icon x-large color="blue">mdi-alert-circle-outline</v-icon>
              <span class="fontSize-2 pl-3">초록 등록 가능 건수({{ regOptn.abstr_reg_max_cnt }})를 초과하여 등록할 수 없습니다.</span>
            </div>
          </v-col>
        </v-row>

        <!-- 초록 등록 내역 -->
        <v-row class="ma-0" v-if="mberSno && abstrRegList.length > 0">
          <v-col cols="12">
            <v-icon color="blue lighten-2">mdi-record-circle</v-icon>
            <span class="fontSize-3 pl-1">초록 제출 내역</span>
          </v-col>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="abstrRegList"
              class="elevation-0"
              :items-per-page.sync="itemsPerPage"
              :page.sync="page"
              disable-sort
              hide-default-footer
              style="cursor: pointer"
              @click:row="gridRowClicked($event)"
            >
              <template v-slot:[`item.rqstr_nm`]="{item}">
                <div>{{ item.rqstr_nm }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- 버튼 -->
        <v-row dense class="ma-0 pa-0" v-if="ongoingConfrInfo.abstr_reg_psbl_yn == 'Y' && !isExceedRegCnt">
          <v-col cols="12" class="d-flex justify-center">
            <!-- <v-btn large class="elevation-0 fontSize-2 wid-200p hei-60p mr-3" outlined >비회원 신청</v-btn>
            <v-btn large class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" outlined @click="regBtnClicked">초록등록</v-btn> -->
            <v-btn large class="elevation-0 fontSize-2 wid-200p hei-60p mr-3" outlined v-if="!mberSno && regOptn.mber_only_yn != 'Y'" @click="nonMberLoginClicked">
              비회원 신청
            </v-btn>
            <v-btn large class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" outlined v-if="!mberSno" @click="mberApplyClicked">
              회원 신청
            </v-btn>
            <v-btn large class="elevation-0 white--text fontSize-2 bg-indigo wid-200p hei-60p" outlined @click="regBtnClicked" v-if="mberSno">
              초록등록
            </v-btn>
          </v-col>
        </v-row>
        <!-- </div> -->
      </template>
    </div>
    <div v-if="confrYn == 'N'">
      <v-row>
        <v-col cols="12">
          진행중인 학술 대회가 없습니다.
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      gridHeader: [
        { text: '번호', value: 'abstr_reg_sno', width: "6%", align: 'center', sortable: false },
        { text: '성명', value: 'rqstr_nm', width: "9%", align: 'center', sortable: false },
        { text: '발표방식/분야', value: 'abstr_pt', width: "21%", align: 'center', sortable: false },
        { text: '초록제목', value: 'abstr_ttl', width: "30%", align: 'center', sortable: false },
        { text: '발표번호', value: 'pt_no', width: "9%", align: 'center', sortable: false },
        { text: '발표시간', value: 'pt_time', width: "25%", align: 'center', sortable: false }
      ],
      page: 1,
      itemsPerPage: 5,
      abstrRegList: [],
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',
      nonMberYn: this.$session.get("nonMberYn") ? this.$session.get("nonMberYn") : '',
      basicUri: '',
      emitData: {},
      chg_perm_yn: 'N',
      ongoingConfrInfo: {},
      abstr_reg_dtl: '',
      confrYn: 'N',
      // 체크 옵션 처리
      regOptn: {},
      confrRegPsblYnByAnulFee: {},
      confrPreRegYn: {}
    }
  },
  computed: {
    isExceedRegCnt() {
      
      return this.abstrRegList.length >= this.regOptn.abstr_reg_max_cnt;
      
    }
  },
  created() {
    let path = this.$route.fullPath;
    console.log("path :::::::::::::::::: " + path);
    this.basicUri = path.substring(path.lastIndexOf('/'), -1);
    this.getAbstrRegPage();
  },
  methods: {
    getAbstrRegPage() {
      const params = new FormData();
      params.append("mber_sno", this.mberSno);
      params.append("non_mber_yn", this.nonMberYn);
      
      this.$http.post('/sub'+this.$route.fullPath, params).then((res) => {

        console.log("res :;;",res.data);
        if(res.data.code < 0) {
          alert(this.$erroCdMsg(res.data.code));
          this.$router.push('/');
        }
        // alert(res.data.result.confrYn);
        this.confrYn = res.data.result.confrYn;
        if(this.confrYn == 'Y') {
          if(res.data.result.resultMap) {
            this.chg_perm_yn = res.data.result.resultMap.chg_perm_yn;
          }

          if(res.data.result.abstrList) {
            this.abstrRegList = res.data.result.abstrList;
            this.abstrRegList.forEach((e) => {
              if(e.abstr_ttl) {
                const abstr_ttl = e.abstr_ttl
                e.abstr_ttl = abstr_ttl.replace(/<[^>]*>?/g, '');
                e.abstr_ttl = this.$getDecodeHtml(e.abstr_ttl);
              }
              
              e.abstr_pt = e.pt_mthd_nm + ' / '+e.pt_field_nm;

            })
          }
          
          // alert(res.data.menu_sno);
          this.ongoingConfrInfo = res.data.result.ongoingConfrInfo;

          

          this.getConfrDtl();
        }
        
        // 초록등록 옵션
        this.regOptn = res.data.result.regOptn;

        if(this.regOptn) {
          // 연회비 납부 체크 옵션이 있는 경우 초록등록 가능 여부 조회
          if(this.regOptn.anul_mfee_chk_yn == 'Y') {
            this.confrRegPsblYnByAnulFee = res.data.result.confrRegPsblYnByAnulFee;
          }

          // 사전등록 여부(초록등록 시 체크 옵션)
          if(this.regOptn.bef_abstr_pre_ess_yn == 'Y') {
            this.confrPreRegYn = res.data.result.confrPreRegYn;
          }
        }
        

        this.emitData.menu_sno = res.data.menu_sno;
        this.$emit('setData', this.emitData);
        
      })
      .catch(function (error) {
          console.log(error);
        }
      );
    },//getAbstrRegPage end
    regBtnClicked() {
      if(this.chg_perm_yn != 'Y') {
        alert('권한이 없습니다.');
        return;
      } else if(this.regOptn.anul_mfee_chk_yn == 'Y' && this.confrRegPsblYnByAnulFee.confr_reg_psbl_yn == 'N') {
        // 연회비 미납 체크 옵션에 따른 초록등록 제한
        alert("연회비가 미납인 경우 등록할 수 없습니다. 연회비 결제 후 등록해 주세요.");
        return;
      } else if(this.regOptn.bef_abstr_pre_ess_yn == 'Y' && this.confrPreRegYn.pre_reg_yn == 'N') {
        // 사전등록 여부(초록등록 시 체크 옵션)
        alert("사전등록이 완료되지 않았습니다. 사전등록 후 초록등록 진행해 주세요.");
        return;
      } else if(this.nonMberYn == 'Y') {

        const params = new FormData();
        params.append("confr_sno", this.ongoingConfrInfo.confr_sno);
        params.append("mber_sno", this.mberSno);
        this.$http.post("/sub/getConfrMberCheck", params).then((res) => {
          console.log("res.data.sult: ", res.data);
          
          if(res.data.code == 0) {
            this.$router.push({path: this.basicUri+'/write', query: {menuSno: this.emitData.menu_sno}});
          } else {
            alert('다른 학술대회 로그인이 필요합니다. 비회원 로그인 후 등록해주세요');
            this.$session.set('mberSno','');
            this.$session.set('tempPwdYn','N');
            this.$session.set('nonMberYn','');
            this.$router.push(this.basicUri+'/nonMberLogin?confr_sno='+this.ongoingConfrInfo.confr_sno+'&menu_sno='+this.emitData.menu_sno)
          }

        }).catch(function (error) {
            console.log(error);
          }
        );
      } else {
        this.$router.push(this.basicUri+'/write');
      }
      // this.$router.push(this.basicUri+'/write');
      
    },//regBtnClicked end
    getConfrDtl() {
      const params = new FormData();
      params.append("confr_sno", this.ongoingConfrInfo.confr_sno);
			this.$http.post("/admin/getConfrDtl", params).then((res) => {
        console.log("res.data.sult: ", res.data);
        
        if(res.data.result.confrDtl.pre_reg_dtl) {
          this.abstr_reg_dtl = res.data.result.confrDtl.abstr_reg_dtl;
        }

			}).catch(function (error) {
					console.log(error);
				}
			);
    },//getConfrDtl end
    gridRowClicked(item) {
      console.log(item.abstr_reg_sno);
      const abstr_reg_sno = item.abstr_reg_sno;
      this.$router.push(this.basicUri+'/write?menuSno='+this.emitData.menu_sno+'&confr_sno='+this.ongoingConfrInfo.confr_sno+'&abstr_reg_sno='+abstr_reg_sno);
    },//gridRowClicked end
    mberApplyClicked() {
      this.$router.push('/login');      
    },
    nonMberLoginClicked() {
      this.$router.push(this.basicUri+'/nonMberLogin?confr_sno='+this.ongoingConfrInfo.confr_sno+'&menu_sno='+this.emitData.menu_sno);
    },//nonMberLoginClicked end
  }
}
</script>
<style scoped>
 .hoverCheck:hover {
  cursor: pointer !important;
 }
</style>