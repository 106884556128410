<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
    <div class="wid-100">
        <v-row dense >
          <!-- <v-col cols="12" class="pa-0 ma-0">
            <div>
              <v-row dense>
                <v-col cols="12">
                  <div class="f-r">
                    <v-btn
                      small
                      color="blue lighten-2"
                      class="white--text"
                    >저장</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col> -->
          <v-col cols="12" >
            <div >
              <v-data-table
                :headers="gridHeader"
                :items="gridList"
                class="elevation-1 dTable-1"
                :items-per-page="20"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.pre_reg_dtl`]="{item}">
                  <v-text-field
                    v-model="item.pre_reg_dtl"
                    hide-details
                    dense
                    readonly
                    solo
                    flat
                    class="pa-0 ma-0 text-field-transparent"
                    @click="preRegDtlRowClicked(item)"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.abstr_reg_list`]="{item}">
                  <v-text-field
                    v-model="item.abstr_reg_list"
                    hide-details
                    dense
                    readonly
                    solo
                    flat
                    class="pa-0 ma-0 text-field-transparent"
                    @click="abstrRegListRowClicked(item)"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.mber_pay_list`]="{item}">
                  <v-text-field
                    v-model="item.mber_pay_list"
                    hide-details
                    dense
                    readonly
                    solo
                    flat
                    class="pa-0 ma-0 text-field-transparent"
                    @click="mberPayListRowClicked(item)"
                  ></v-text-field>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-layout>
    <!-- <WinPopup ref="winPopup"  @onClose="val=>evtCloseWinPopup(val)"  @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" > -->
    <window-open ref="windowOpen"></window-open>
  </v-container>
</template>

<script>
import windowOpen from '@/components/common/windowOpen.vue';
export default {
  components: { windowOpen },
  props: ["propParams"],
  data(){
    return {
      gridHeader:[
        { text: '학술발표', value:'confr_nm', width: "15%"},
        { text: '성명', value:'mber_nm', width: "6%"},
        { text: '소속', value:'rqstr_blng', width: "10%"},
        { text: '휴대전화', value:'rqstr_cel_no', width: "10%"},
        { text: '총 결제할 금액', value:'tot_amt', width: "10%"},
        { text: '결제 여부', value:'pay_yn', width: "7%"},
        { text: '등록일시', value:'reg_dtm', width: "13%"},
        { text: '상세정보', value:'pre_reg_dtl', width: "12%"},
        { text: '초록등록', value:'abstr_reg_list', width: "7%"},
        { text: '청구/결제내역', value:'mber_pay_list', width: "10%"},
      ],
      gridList:[],
      mberSno: "",
    }
  },
  created() {
    this.mberSno = this.propParams.mberSno;
    console.log("mberSno: " + this.mberSno);

    this.getMberConfrHist();
  },
  methods: {
    getMberConfrHist() {
			const params = new FormData();
      params.append("mber_sno", this.mberSno);

			this.$http.post("/admin/getMberConfrHist", params).then((res) => {
        // console.log("gridList: " + this.gridList);
        res.data.result.forEach((e) => {
          // e.pay_yn = e.pay_yn == 'Y' ? true : false;
          e.pre_reg_dtl = "사전등록 상세정보";
          e.abstr_reg_list = "초록등록";
          e.mber_pay_list = "청구/결제내역";
        });

        this.gridList = res.data.result;
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    preRegDtlRowClicked(event) {
      // console.log("row click:", event);
      // this.$router.push({name:"preRegistDetail", query: { confrSno:event.confr_sno, preRegSno:event.pre_reg_sno, newYn:'N' }});
      const uri = '/admin/preRegistDetail?confrSno=' + event.confr_sno + '&preRegSno=' + event.pre_reg_sno + '&newYn=N';
      this.$refs.windowOpen.openAsNewTab( uri );
    },
    abstrRegListRowClicked(event) {
      console.log("row click:", event);
      // this.$router.push({name:"abstrRegistList", query: { confrSno:event.confr_sno }});

      const uri = '/admin/abstrRegistList?confrSno=' + event.confr_sno;
      // this.$refs.windowOpen.openWinPop( uri , 1560, 700 );
      this.$refs.windowOpen.openAsNewTab( uri );
    },
    mberPayListRowClicked(event) {
      console.log("row click:", event);
      alert("회원 결제 내역 화면 링크 예정");
      // 학술대회 등록비 결제 내역만 필터링
      // this.$router.push({name:"memberPayList", query: { confrSno:event.confr_sno, prodType:'confr' }});
    },
  }
}
</script>

<style>

</style>