<template>
  <v-container fluid>
    <v-row class="pa-3">
      <v-col cols="12">
        <div style="float:left;">
          <v-btn
            small
            color="grey lighten-2"
            class="mr-2"
            @click="goBack"
          >목록</v-btn>
        </div>
        <div style="float:right;">
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNMoveBtnClicked"
            >저장 후 목록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="!saveBtnEnabled"
            @click="saveNStayBtnClicked"
            >저장 후 유지
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="newYn == 'Y'"
            @click="preRegBtnClicked"
            >사전 등록
          </v-btn>
          <v-btn 
            small 
            color="blue lighten-2" 
            class="white--text ml-3 f-r"
            :disabled="newYn == 'Y'"
            @click="abstrRegBtnClicked"
            >초록 등록
          </v-btn>
          <v-btn
            small
            color="red lighten-2"
            class="white--text mr-2"
            v-show="newYn == 'N'"
            @click="deleteBtnClicked"
          >학술대회 삭제</v-btn>
        </div>
      </v-col>
    </v-row>
    
    <v-tabs
      v-model="tab"
      style=""      
    >      
      <v-tab
        v-for="item of tabList"
        :key="item.tabNm"
        exact
        :disabled="!item.enabled"
      >
        {{ item.tabNm }}
        <v-badge 
          v-show="item.changed"
          color="red"
          class="pb-2"
          dot
        ></v-badge>
      </v-tab>
      <v-tabs-items 
        v-model="tab"
      > 
        <v-divider></v-divider>
        <v-tab-item
          v-for="item in tabList"
          :key="item.tabNm"
          class="ma-5"
        >
          <component v-bind:is="item.content" :ref="item.content" class="w-100" v-bind:propParams="propParams" @tabChanged="tabChanged" @confrCreated="confrCreated"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import conferenceInfoTab from '@/components/admin/tabs/conference/conferenceInfoTab';
import preRegistOptionTab from '@/components/admin/tabs/conference/preRegistOptionTab';
import abstrRegistOptionTab from '@/components/admin/tabs/conference/abstrRegistOptionTab';
import conferencePolicyTab from '@/components/admin/tabs/conference/conferencePolicyTab';
import conferenceDetailTab from '@/components/admin/tabs/conference/conferenceDetailTab';
import conferenceTrafficGuideTab from '@/components/admin/tabs/conference/conferenceTrafficGuideTab';
import preRegistProductTab from '@/components/admin/tabs/conference/preRegistProductTab';

export default {
  components: {
    conferenceInfoTab, preRegistOptionTab, abstrRegistOptionTab, conferencePolicyTab, conferenceDetailTab, conferenceTrafficGuideTab, preRegistProductTab
  },
  data(){
    return {
      tab: null,
      tabList: [
        { tabNm: '행사정보', content: 'conferenceInfoTab', tabNo: 0 , enabled:true, changed: false},
        { tabNm: '사전등록 옵션', content: 'preRegistOptionTab', tabNo: 1, enabled:false, changed: false},
        { tabNm: '초록등록 옵션', content: 'abstrRegistOptionTab', tabNo: 2, enabled:false, changed: false},
        { tabNm: '정책', content: 'conferencePolicyTab', tabNo: 3, enabled:false, changed: false},
        { tabNm: '행사 상세정보', content: 'conferenceDetailTab', tabNo: 4, enabled:false, changed: false},
        { tabNm: '교통/숙박 안내', content: 'conferenceTrafficGuideTab', tabNo: 5, enabled:false, changed: false},
        { tabNm: '사전등록 상품', content: 'preRegistProductTab', tabNo: 6, enabled:false, changed: false},
      ],
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),

      newYn: this.$route.query.newYn,
      confrSno: this.$route.query.confrSno,
      propParams: {},
      redrawCount: 0,

      saveBtnEnabled: false,
      goYn: false,
    }
  },
  created() {
    console.log("conferenceDetail created.");

    // this.newYn = this.$route.query.newYn;
    // this.confrSno = this.$route.query.confrSno;

    // 탭 활성화
    // console.log("newYn:", this.newYn);
    if(this.newYn == 'N') {
      this.tabList.forEach((e) => {
        e.enabled = true;
      });
    }

    this.propParams['newYn'] = this.newYn;
    this.propParams['confrSno'] = this.confrSno;

  },
  computed: {
    // tabList() {
    //   const items = [
    //     { tabNm: '행사정보', content: 'conferenceInfoTab', tabNo: 0 , changed: false},
    //   ];

    //   if(!this.newYn) {
    //     items.push(
    //       { tabNm: '사전등록 옵션', content: 'preRegistOptionTab', tabNo: 1, changed: false},
    //       { tabNm: '초록등록 옵션', content: 'abstrRegistOptionTab', tabNo: 2, changed: false},
    //       { tabNm: '정책', content: 'conferencePolicyTab', tabNo: 3, changed: false},
    //       { tabNm: '행사 상세정보', content: 'conferenceDetailTab', tabNo: 4, changed: false},
    //       { tabNm: '오시는 길', content: 'conferenceTrafficGuideTab', tabNo: 5, changed: false},
    //       { tabNm: '사전등록 상품', content: 'preRegistProductTab', tabNo: 6, changed: false},
    //     );
    //   }

    //   return items;
    // }
  },
  methods: {
    updateRouter(item) {
      this.$router.push(item);
    },//updateRouter end
    goBack() {
      this.$router.go(-1)
    },//goBack end
    tabChanged(tabId) {
      console.log("conferenceDetail tabChanged:", tabId);
      this.tabList.filter((e) => e.content == tabId).map((f) => {
        f.changed = true;
      })

      this.saveBtnEnabled = true;
      // console.log("tabList:", this.tabList);     
    },//chgTabYn end
    confrCreated(confrSno) {
      // console.log("confrCreated:", confrSno);
      this.propParams['confrSno'] = confrSno;
      this.confrSno = confrSno;
      this.newYn = 'N';
    },
    deleteBtnClicked() {
      console.log("deleteConfr");

      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("mber_sno", this.mberSno);
        params.append("confr_sno", this.confrSno);

        this.$http.post("/admin/deleteConfr", params).then((res) => {
          console.log(res.data);

          if(res.data.code == 0) {
            alert("삭제되었습니다.");
            this.$router.push({path:"conferenceList"});
          } else if(res.data.code == -9003) {
            alert("삭제할 수 없습니다. 학술대회가 사용 중입니다.");
          }
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      }
    },
    saveNMoveBtnClicked() {
      console.log("saveNMoveBtnClicked");
      this.goYn = true;
      this.saveConferenceInfo();
      // this.$router.push({path:"conferenceList"})
    },
    saveNStayBtnClicked() {
      console.log("saveNStayBtnClicked");
      this.goYn = false;
      this.saveConferenceInfo();
    },
    saveConferenceInfo() {
      // console.log("saveConferenceInfo");
      // console.log("refs:", this.$refs);
      // console.log("filtered:", this.tabList.filter(e => e.changed));

      if(confirm("저장하시겠습니까?")) {
        const filtered = this.tabList.filter(e => e.changed);
        // console.log("filtered:", filtered);
        // 입력 데이터 검증
        let isValid = true;
        filtered.forEach((e) => {
          if(e.content == "conferenceInfoTab") {
            isValid = this.$refs.conferenceInfoTab[0].validateInput();
          } else if(e.content == "preRegistOptionTab") {
            isValid = this.$refs.preRegistOptionTab[0].validateInput();
          } else if(e.content == "abstrRegistOptionTab") {
            isValid = this.$refs.abstrRegistOptionTab[0].validateInput();
          // } else if(e.content == "conferencePolicyTab") {
          //   this.$refs.conferencePolicyTab[0].saveBtnClicked();
          // } else if(e.content == "conferenceDetailTab") {
          //   this.$refs.conferenceDetailTab[0].saveBtnClicked();
          // } else if(e.content == "conferenceTrafficGuideTab") {
          //   this.$refs.conferenceTrafficGuideTab[0].saveBtnClicked();
          // } else if(e.content == "preRegistProductTab") {
          //   this.$refs.preRegistProductTab[0].saveBtnClicked();
          } 
        });

        if(!isValid) {
          // alert("invalid");
        } else {
          // 저장
          filtered.forEach((e) => {
            if(e.content == "conferenceInfoTab") {
              this.$refs.conferenceInfoTab[0].saveBtnClicked();
            } else if(e.content == "preRegistOptionTab") {
              this.$refs.preRegistOptionTab[0].saveBtnClicked();
            } else if(e.content == "abstrRegistOptionTab") {
              this.$refs.abstrRegistOptionTab[0].saveBtnClicked();
            } else if(e.content == "conferencePolicyTab") {
              this.$refs.conferencePolicyTab[0].saveBtnClicked();
            } else if(e.content == "conferenceDetailTab") {
              this.$refs.conferenceDetailTab[0].saveBtnClicked();
            } else if(e.content == "conferenceTrafficGuideTab") {
              this.$refs.conferenceTrafficGuideTab[0].saveBtnClicked();
            } else if(e.content == "preRegistProductTab") {
              this.$refs.preRegistProductTab[0].saveBtnClicked();
            } 
          });        

          this.tabList.forEach((e) => {
            e.enabled = true;
            e.changed = false;
          });

          this.saveBtnEnabled = false;

          alert("저장되었습니다.");
          if(this.goYn) {
            this.$router.push({path:"conferenceList"});
          }
        }
      }
    },
    // 사전 등록 버튼 클릭
    preRegBtnClicked() {
      // this.$router.push({path:"preRegDetail"})
      this.$router.push({name:"preRegistList", query: { confrSno:this.confrSno }});
    },
    // 초록 등록 버튼 클릭
    abstrRegBtnClicked() {
      this.$router.push({name:"abstrRegistList", query: { confrSno:this.confrSno }});
    },
  }
}
</script>

<style>

</style>