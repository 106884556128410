<template>
	<v-container fluid class="pa-3">
    <v-row>
      <v-col cols="10">
        <v-row class="" align="center" dense>
          <v-col cols="2">
            <v-text-field 
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              v-model="search" 
              outlined
              dense
              hide-details
              background-color="white">
            </v-text-field>
          </v-col>
          <v-col cols="1" class="">
          </v-col>
          <v-col cols="1" class="">
            <div class="">
              <span>학술대회</span>
            </div>
          </v-col>
          <v-col cols="3">
            <v-select
              class="f-l pa-0 ma-0 wid-100 input-hei-d" 
              :items="confrList"
              item-value="confr_sno"
              item-text="confr_nm"
              v-model="confrSno"
              background-color="white"
              hide-details
              dense
              outlined
              @change="confrSelChanged"
            ></v-select>
          </v-col>
          <v-col cols="5">
            <div>
              <v-btn 
                small 
                class="white--text ml-3 f-r"
                color="blue lighten-2" 
                @click="addAbstrRegistBtnClicked"
              >초록등록 추가</v-btn>
              <v-btn 
                small 
                class="white--text f-r" 
                color="red lighten-2" 
                @click="deleteBtnClicked"
              >선택 삭제</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-data-table
              :headers="gridHeader"
              :items="gridList"
              :search="search"
              class="elevation-1"
              style="cursor: pointer"
              >
              <template v-slot:[`item.select_yn`]="{ item }">
                <v-checkbox
                  v-model="item.select_yn"
                  class="pa-0 ma-0"
                  hide-details
                  dense
                ></v-checkbox>
              </template>
              
              <template v-slot:[`item.mber_kind_nm`]="{item}">
                <div v-dompurify-html="item.mber_kind_nm" @click="gridRowClicked(item)"></div>
              </template>
              
              <template v-slot:[`item.rqstr_nm`]="{ item }" >
                <div @click="gridRowClicked(item)">{{item.rqstr_nm }}</div>
              </template>

              <template v-slot:[`item.abstr_ttl`]="{item}">
                <div v-dompurify-html="item.abstr_ttl" @click="gridRowClicked(item)"></div>
              </template>

              <template v-slot:[`item.pt_no`]="{ item }" >
                <div @click="gridRowClicked(item)">{{item.pt_no }}</div>
              </template>

              <template v-slot:[`item.pt_time`]="{ item }" >
                <div @click="gridRowClicked(item)">{{item.pt_time }}</div>
              </template>

              <template v-slot:[`item.abstr_lang`]="{ item }" >
                <div @click="gridRowClicked(item)">{{item.abstr_lang }}</div>
              </template>

              <template v-slot:[`item.pt_mthd_nm`]="{ item }" >
                <div @click="gridRowClicked(item)">{{item.pt_mthd_nm }}</div>
              </template>

              <template v-slot:[`item.pt_field_nm`]="{ item }" >
                <div @click="gridRowClicked(item)">{{item.pt_field_nm }}</div>
              </template>

              <template v-slot:[`item.athor_cnt`]="{ item }" >
                <div @click="gridRowClicked(item)">{{item.athor_cnt }}</div>
              </template>

              <template v-slot:[`item.reg_dtm`]="{ item }" >
                <div @click="gridRowClicked(item)">{{item.reg_dtm }}</div>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-card class="pt-3">
          <v-row class="pr-3 pl-3" dense>
            <v-col cols="12" class="pb-3"><h3>필터</h3></v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.abstrRegistList.radioMberKind"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>회원 종류로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in mberKindList"
                  :key="index"
                  :label="item.mber_kind_nm"
                  :value="item.mber_kind_nm"
                ></v-radio>
                <v-radio
                  label="비회원"
                  value="비회원"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.abstrRegistList.radioPtMthd"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>발표 방식으로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in ptMthdList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-radio-group 
                v-model="$store.state.abstrRegistList.radioPtField"
                @change="radioFilterChanged"
              >
                <template v-slot:label>
                  <div><span>발표 분야로</span></div>
                </template>
                <v-radio
                  label="모두"
                  value="모두"
                ></v-radio>
                <v-radio
                  v-for="(item, index) in ptFieldList"
                  :key="index"
                  :label="item.cmn_cd_nm"
                  :value="item.cmn_cd"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
	</v-container>
</template>
  
<script>
export default {
  data(){
    return {
      redrawCount : 0,  // 화면 다시 그리기
      mberSno: this.$session.get("mberSno") ? this.$session.get("mberSno") : '',

      gridHeader: [
        { text: '선택', value: 'select_yn', width: "4%", sortable: false },
        { text: '회원종류', value: 'mber_kind_nm', width: "7%" },
        { text: '신청자', value: 'rqstr_nm', width: "7%" },
        { text: '논문제목', value: 'abstr_ttl', width: "16%" },
        { text: '발표번호', value: 'pt_no', width: "7%" },
        { text: '발표시간', value: 'pt_time', width: "14%" },
        { text: '언어', value: 'abstr_lang', width: "5%" },
        { text: '발표방식', value: 'pt_mthd_nm', width: "7%" },
        { text: '발표분야', value: 'pt_field_nm', width: "7%" },
        { text: '공동저자수', value: 'athor_cnt', width: "7%", sortable: false },
        // { text: '초록수정가능', value: 'abstr_upd_psbl_yn', width: "8%" },
        { text: '등록일시', value: 'reg_dtm', width: "12%" },
      ],
      gridList: [],
      abstrRegList: [],

      confrList: [],
      confrSno: this.$route.query.confrSno,

      // 라디오 필터 표시 목록
      mberKindList: [],
      ptMthdList: [],
      ptFieldList: [],

      search: "",
    }
  },
  computed: {

  },
  created() {
    // console.log("abstrRegList > this.confrSno:", this.confrSno);

    this.getConfrList();
    this.getMberKindList();
    this.getPtMthdList();
    this.getPtFieldList();

    if(this.confrSno != null && this.confrSno != "") {
      this.getConfrAbstrRegList();
    }

    // 학술대회 select refresh
    if(this.confrSno != null) {
      this.confrSno++;
      this.confrSno--;
    }
  },
  mounted(){
    
  },
  methods: {
    async getPtMthdList() {
      this.ptMthdList = await this.$getCmnCdList("pt_mthd");
    },
    async getPtFieldList() {
      this.ptFieldList = await this.$getSoctyCmnCdList("pt_field");
      this.ptFieldList.map((e) => {
        e.cmn_cd_nm = e.cmn_cd_nm_abbr || e.cmn_cd_nm;
      });
    },
    getMberKindList() {
      this.$http.get("/admin/getSoctyMberKindList").then((res) => {        
        this.mberKindList = res.data.result.mberKindList;
      }).catch((error) => {
          console.log(error);
        }
      )
    },
    getConfrList() {
      this.$http.get("/admin/getConfrList").then((res) => {
        // console.log("res.data.result:", res.data.result);
        this.confrList = res.data.result;
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },
    getConfrAbstrRegList() {
      const params = new FormData();
      console.log("this.confrSno:", this.confrSno);
      params.append("confr_sno", this.confrSno);
      params.append("mber_sno", this.mberSno);

      this.$http.post("/admin/getConfrAbstrRegList", params).then((res) => {
        // console.log("res.data.result:", res.data.result);
        res.data.result.forEach((e) => {
          e.select_yn = false;
          e.rqstr_disp = e.rqstr_nm + "(" + e.rqstr_email + ")";
        });

        // this.gridList = res.data.result;
        this.abstrRegList = res.data.result;
        this.filterList();
      }).catch((error) => console.log(error));
    },
    confrSelChanged() {
      // console.log("this.confrSno:", this.confrSno);
      this.getConfrAbstrRegList();
    },
    gridRowClicked(event) {
      // console.log("gridRowClicked", event);
      this.$router.push({name:"abstrRegistDetail", query: { confrSno:this.confrSno, abstrRegSno:event.abstr_reg_sno, newYn:'N' }});
    },
    // 학술대회 추가 버튼 클릭
    addAbstrRegistBtnClicked() {
      this.$router.push({name:"abstrRegistDetail", query: { confrSno:this.confrSno, abstrRegSno:null, newYn:'Y' }});
    },
    deleteBtnClicked() {
      console.log("deleteAbstrRegistList");

      // 선택 체크된 목록 확인 -> deleteList 에 추가
      let deleteList = [];
      let isSelected = false;

      deleteList = this.gridList.filter((e) => {
        if(e.select_yn) isSelected = true;
        return e.select_yn && !e.new_yn;
      });

      // 선택된 항목이 없는 경우
      if(!isSelected) {
        alert("선택된 목록이 없습니다.");
        return;
      }

      if(confirm("삭제 후 목록을 다시 조회합니다.\n삭제하시겠습니까?")) {
        if(deleteList.length > 0) {
          let params = new URLSearchParams();
          
          params.append("deleteList", JSON.stringify(deleteList));

          this.$http.post("/admin/deleteConfrAbstrRegList", params).then((res) => {
            console.log(res.data);
            alert("삭제되었습니다.");
            // this.getConfrAbstrRegList();
            this.gridList = this.gridList.filter((e) => {
              return !(e.select_yn);
            });
          })
          .catch(function (error) {
              console.log(error);
            }
          );
        } 
      }
    },
    radioFilterChanged() {
      this.filterList();
    },
    filterList() {
      this.gridList = this.abstrRegList.filter((e) => {
        // console.log("e:", e);
        if( (this.$store.state.abstrRegistList.radioMberKind == "모두" || e.mber_kind_nm == this.$store.state.abstrRegistList.radioMberKind) &&
            (this.$store.state.abstrRegistList.radioPtMthd == "모두" || e.pt_mthd == this.$store.state.abstrRegistList.radioPtMthd) &&
            (this.$store.state.abstrRegistList.radioPtField == "모두" || e.pt_field == this.$store.state.abstrRegistList.radioPtField) 
          ) return true;
      });
    }
  }
}
</script>
  
<style>

</style>