<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">

          <v-col cols="2">
            <div class="pt-0">
              <span>Global CSS</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="globl_css" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="2">
            <div class="pt-0">
              <span>Editor CSS</span>
            </div>
          </v-col>
          <v-col cols="8">
            <tiny-editor v-model="edtr_css" @input="itemChanged"></tiny-editor>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>

        </v-row>  
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import tinyEditor from '@/components/common/tinyEditor.vue';

export default {
  components: { tinyEditor },
  data(){
    return{
      mberSno: this.$session.get("mberSno") || '',

      globl_css: '',
      edtr_css: '',
    }
  },
  created() {
    this.getSoctyCss();
  },
  methods: {
    getSoctyCss() {
      const params = new FormData();

			this.$http.post("/admin/getSoctyCss", params).then((res) => {
        console.log("res.data: ", res.data);

        if(res.data.result) {
          this.globl_css = res.data.result.globl_css;
          this.edtr_css = res.data.result.edtr_css;
        }
			}).catch(function (error) {
					console.log(error);
				}
			);
    },
    saveBtnClicked() {
      console.log("saveSoctyCss");

      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        if(this.mberSno != null)    params.append("mber_sno", this.mberSno);
        if(this.globl_css != null)  params.append("globl_css", this.globl_css);
        if(this.edtr_css != null)   params.append("edtr_css", this.edtr_css);

        this.$http.post("/admin/upsertSoctyCss", params).then((res) => {
          console.log(res.data);
          // alert("저장되었습니다.");
          
          this.getSoctyCss();
        })
        .catch(function (error) {
            console.log(error);
          }
        );
      // }
    },
    itemChanged() {
      console.log("itemChanged");
      this.$emit('tabChanged', 'cssTab');
    },
  }
}
</script>

<style>

</style>