import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import common from '@/common/common.js';
import store from './store/store'
import VueSession from 'vue-session'
import VueNestable from 'vue-nestable'
import './assets/vue-nestable.css' // Ensure you are using css
import VueDOMPurifyHTML from 'vue-dompurify-html';

// import naver from 'vue-naver-maps';

// Vue.use(naver, {
//   clientID: 'kcfxsulnzu',
//   useGovAPI: false, //공공 클라우드 API 사용 (선택)
//   subModules:'' // 서브모듈 (선택)
// });

var sessionOptions = {
  persist: true
}
Vue.use(VueSession, sessionOptions)
Vue.use(VueNestable);

Vue.use(common);
Vue.use(VueDOMPurifyHTML);

if (document.location.hostname == 'localhost') {
  axios.defaults.baseURL = 'http://localhost:8087';
} else {
  axios.defaults.baseURL = document.location.origin;
}

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

Vue.prototype.$http = axios;
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
